import { useQuery } from 'react-query';
import { useCurrentBot } from '../../../utils/CurrentBot';
import { HttpClient } from '../../../utils/HttpClient';
import { StatisticsSettings } from '../../types';
import { statisticsSettingsCacheKey } from './cacheConfig';

export function useGetStatisticsSettings() {
    const currentBot = useCurrentBot();
    const { data: statisticsSettings, isLoading } = useQuery(statisticsSettingsCacheKey, () => HttpClient.get({
        path: `/bots/${currentBot.id}/statistics/settings`,
    }).then(({ data }) => data) as unknown as Promise<StatisticsSettings>);

    return { statisticsSettings, isLoading };
}