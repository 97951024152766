import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box } from '../../components/Box/Box';
import { Input } from '../../components/Input';
import { TextAreaWithVariables } from '../../components/TextArea/TextAreaWithVariables';
import { useSurveyCreator } from '../../surveyCreator/useSurveyCreator';
import { useCurrentBot } from '../../utils/CurrentBot';
import { AnyStep, OnStepChangeType, SmsStepConfig } from '../schedule-model';

import { CollapsedStep } from './CollapsedStep';
import styles from './SmsStep.pcss';

interface SmsStepProps {
    step: AnyStep<SmsStepConfig>;
    isCollapsed: boolean;
    onStepChange: OnStepChangeType;
}

export const SmsStep = ({ step, isCollapsed, onStepChange }: SmsStepProps) => {
    const currentBot = useCurrentBot();
    const { definition } = useSurveyCreator(currentBot);
    const intl = useIntl();

    return (
        isCollapsed ? <CollapsedStep messageId={'scheduler.steps.smsCollapsed'}/> : <Box border>
            <div className={styles.senderNameBlock}>
                <div className={styles.label}>
                    <FormattedMessage id={'scheduler.step.smsSenderNameLabel'} />
                </div>
                <Input
                    placeholder={intl.messages['survey-creator.smsStep.senderNamePlaceholder'] as string}
                    dataTest={'sms-sender-name'}
                    className={styles.senderInput}
                    value={step.stepConfig.senderName || ''}
                    onChange={(value) => onStepChange({ ...step, stepConfig: { ...step.stepConfig, senderName: value } })}
                />
            </div>
            <div>
                <div className={styles.label}>
                    <FormattedMessage id={'scheduler.step.smsTemplateLabel'} />
                    <span className={styles.limit}
                        data-test={'sms-template-length'}>{`${step.stepConfig.messageTemplate ? step.stepConfig.messageTemplate.length : 0}`}</span>
                </div>
                <TextAreaWithVariables
                    dataTest={'sms-template'}
                    availableVariables={definition.variables.getVariablesForPlanner()}
                    value={step.stepConfig.messageTemplate}
                    onChange={(value: string) => {
                        const stepConfig: SmsStepConfig = { ...step.stepConfig, messageTemplate: value };
                        onStepChange({
                            ...step,
                            stepConfig
                        });
                    }}
                />
            </div>
        </Box>
    );
};
