import * as React from 'react';

import {ElementWithLabel} from './ElementWithLabel';

type Props = {
    labelId: string;
    children: JSX.Element;
    className?: string;
};

export const BorderedElementWithLabel = (props: Props) => {
    return (
        <ElementWithLabel labelId={props.labelId} borderEnabled className={props.className}>
          {props.children}
        </ElementWithLabel>
    );
}
