import * as React from 'react';
import {useState} from 'react';

import {ConditionalMessage, Message} from '../../../model';
import {AddButton, Props as ButtonProps} from '../../AddButton';
import {useFixedPosition} from '../../useFixedPosition';
import {useAvailableConditionalMessage} from '../useAvailableConditionalMessages';

import {ConditionalMessageVariableSelector} from './ConditionalMessageVariableSelect';

type Props = ButtonProps & {
    message: Message,
    onChange: (message: Message) => void;
}

export const AddConditionalMessage = (props: Props) => {
    const [showSelector, toggleSelector] = useState(false);
    const sourcesForConditionalMessage = useAvailableConditionalMessage();
    const {contentRef, style} = useFixedPosition([], {top: -54, left: -131});
    return (
        <>
            <AddButton {...props} onClick={() => toggleSelector(true)} ref={contentRef}/>
            {
                showSelector &&
                <ConditionalMessageVariableSelector
                    availableConditionalSources={sourcesForConditionalMessage}
                    dataTestPrefix={props.dataTest}
                    labelId={props.labelId}
                    message={props.message as ConditionalMessage}
                    onChange={props.onChange}
                    outsideClickCallback={() => toggleSelector(false)}
                    positionStyle={style}
                />

            }
        </>
    );
}
