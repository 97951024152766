import React from 'react';

import styles from './ModalContent.pcss';

export const ModalFooter: React.FC = ({ children }) => {
    return (
        <div className={styles.modalFooter}>
            {children}
        </div>
    );
};