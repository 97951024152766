import React from 'react';

import {Box} from '../../components/Box/Box';
import {CheckboxComponent, CheckboxComponentProps} from '../../components/CheckBox/CheckBoxComponent';
import {IconButton} from '../../components/IconButton/IconButton';
import {DeleteIcon} from '../../icons';
import {InspectorTrainIcon} from '../../icons/InspectorTrainIcon';

import styles from './TableControls.pcss';

export type TableControlsProps = {
  onSelectAll?: CheckboxComponentProps['onChange'];
  isAllSelected?: boolean;
  isAnySelected?: boolean;
  isTrainable?: boolean;
  onTrain?: React.MouseEventHandler;
  onDelete?: React.MouseEventHandler;
}

export const TableControls: React.FC<TableControlsProps> = ({
  onSelectAll,
  isAllSelected = false,
  isAnySelected = false,
  isTrainable = false,
  onTrain,
  onDelete
}) => <Box
  className={styles.tableControls}
  border
>
    <CheckboxComponent
      dataTest="select-all-checkbox"
      value={isAllSelected}
      onChange={onSelectAll}
    />
    {
      isAnySelected &&
      <IconButton
        data-test="delete-selected"
        animationClass={styles.icon}
        Icon={DeleteIcon}
        onClick={onDelete}
      />
    }
    {
      isAnySelected &&
      <IconButton
        data-test="train-selected"
        animationClass={styles.icon}
        Icon={InspectorTrainIcon}
        onClick={onTrain}
      />
    }
  </Box>
