import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { useAdminRole, useCancelChanges, usePatchConfig, useSurveyConfig } from '../Settings.hooks'
import { updateModifiedConfig } from '../store/actions'

export const usePhoneTabProps = () => {
  const dispatch = useDispatch()
  const { savedConfig, modifiedConfig, displayedConfig, hasChanges, ...meta } = useSurveyConfig()
  const isAdmin = useAdminRole();

  const setCountryCallingCode = useCallback((countryCallingCode: string) =>
    dispatch(updateModifiedConfig({ countryCallingCode })),
    [dispatch])

  const addPhoneConfig = useCallback(() => {
    let updatedPhoneConfigs;
    const newPhoneConfig = { phoneNumber: '', serverAddress: '' }
    if (isEmpty(modifiedConfig?.phoneConfigs)) {
      updatedPhoneConfigs = [newPhoneConfig];
    } else {
      updatedPhoneConfigs = [...modifiedConfig.phoneConfigs, newPhoneConfig]
    }

    dispatch(updateModifiedConfig({ phoneConfigs: updatedPhoneConfigs }))
  }, [dispatch, modifiedConfig])

  const deletePhoneConfig = useCallback((index: number) => {
    dispatch(updateModifiedConfig({
      phoneConfigs: modifiedConfig.phoneConfigs.filter((v, i) => i !== index)
    }));
  }, [dispatch, modifiedConfig])

  const updatePhoneConfig = useCallback((index: number, property: string, value: string) => {
    const updatedPhoneConfigs = cloneDeep(modifiedConfig.phoneConfigs);
    updatedPhoneConfigs[index][property] = value;
    dispatch(updateModifiedConfig({ phoneConfigs: updatedPhoneConfigs }));
  }, [dispatch, modifiedConfig])

  const onSave = usePatchConfig();
  const onCancel = useCancelChanges();

  return {
    displayedConfig,
    phoneConfigs: displayedConfig?.phoneConfigs || [],
    countryCallingCode: displayedConfig?.countryCallingCode,
    setCountryCallingCode,
    addPhoneConfig,
    deletePhoneConfig,
    updatePhoneConfig,
    onSave,
    onCancel,
    isAdmin,
    hasChanges,
    ...meta
  }
}