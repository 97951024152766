import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';

import { Button } from '../../components/Button/Button';
import { Loader } from '../../components/Loader';
import { Scrollbar } from '../../components/Scrollbar/Scrollbar';
import { parseConversationsOrdering, toggleConversationOrdering } from '../Conversations';
import { Call } from '../models/Call';
import { RefreshIcon } from '../../icons';

import { ConversationRow } from './ConversationRow';
import styles from './ConversationsList.pcss';

interface Props {
    readonly conversations: ReadonlyArray<Call>;
    readonly orderable?: boolean;
    readonly itemsCount?: number;
    readonly hasMore?: boolean;
    readonly loading?: boolean;
    isSurveyConfigLoading?: boolean;
    timezone?: string;
    currentConversation?: Call;

    fetchNextPage(lastItemId: string): Promise<void>;
    refresh(): void;

    onSelect(conversation: Call);
}

export const ConversationsList = withRouter(
    ({ orderable = true, isSurveyConfigLoading, timezone, currentConversation, ...props }: Props & RouteComponentProps<{ callId: string }>) => {
        const [eventsColumnWidth, setEventsColumnWidth] = useState(0);
        const eventsColumnRef = useRef<HTMLDivElement>(null);
        const ordering = useMemo(() => parseConversationsOrdering(props.location.search), [props.location.search]);
        useEffect(() => {
            setEventsColumnWidth(eventsColumnRef.current.getBoundingClientRect().width);
            const resizeListener = () => setEventsColumnWidth(eventsColumnRef.current.getBoundingClientRect().width);
            window.addEventListener('resize', resizeListener);
            return function cleanup() {
                window.removeEventListener('resize', resizeListener);
            };
        }, [eventsColumnRef.current]);

        const toggleStartedAtOrder = useCallback(
            () => toggleConversationOrdering(props.history, props.location, ordering, 'startedAt'),
            [props.history, props.location, ordering]
        );
        const toggleDurationOrder = useCallback(
            () => toggleConversationOrdering(props.history, props.location, ordering, 'duration'),
            [props.history, props.location, ordering]
        );
        const listIsEmpty = (!props.conversations || props.conversations.length === 0) && !props.loading;
        const [scrolled, setScrolled] = useState(false);
        const onScroll = useCallback((position: number) => setScrolled(position > 0), []);

        return (
            <div data-test="conversations-list" className={styles.list}>
                <div
                    data-test="conversation-list-headers"
                    className={classNames(styles.row, styles.headers, scrolled && styles.withShadow)}
                >
                    <button
                        className={classNames(orderable ? styles.orderableHeader : undefined, styles.headerButton)}
                        onClick={toggleStartedAtOrder}
                    >
                        <FormattedMessage id="callsList.timestamp" />
                        {orderable && ordering.property === 'startedAt' && (
                            <i className={classNames(styles[ordering.direction.toLowerCase()])} />
                        )}
                    </button>
                    <button
                        className={classNames(orderable ? styles.orderableHeader : undefined, styles.headerButton)}
                        onClick={toggleDurationOrder}
                    >
                        <FormattedMessage id="callsList.duration" />
                        {orderable && ordering.property === 'duration' && (
                            <i className={classNames(styles[ordering.direction.toLowerCase()])} />
                        )}
                    </button>
                    <div>
                        <FormattedMessage id="callsList.phoneNumber" />
                    </div>
                    <div ref={eventsColumnRef} className={styles.eventsColumnHeader}>
                        <span className={styles.eventsLabel}>
                            <FormattedMessage id="callsList.events" />
                        </span>
                        {props.itemsCount !== undefined && (
                            <span data-test="conversations-list-count" className={styles.count}>
                                <FormattedMessage id="callsList.count" values={{ count: props.itemsCount }} />
                            </span>
                        )}
                        {!props.loading && (
                            <button
                                data-test="refresh-conversations-button"
                                className={styles.refresh}
                                onClick={props.refresh}
                            >
                              <RefreshIcon />
                            </button>
                        )}
                    </div>
                </div>
                {listIsEmpty && <EmptyList />}
                {!listIsEmpty && (
                    <Scrollbar onScroll={onScroll}>
                        <div>
                            {
                                props.conversations
                                    .map(conversation => (
                                        <ConversationRow
                                            key={conversation.id}
                                            eventsColumnWidth={eventsColumnWidth}
                                            className={classNames(
                                                styles.row,
                                                styles.content,
                                                conversation.id === props.match.params.callId && styles.activeRow
                                            )}
                                            onClick={() => props.onSelect(conversation)}
                                            conversation={conversation}
                                            isLoading={isSurveyConfigLoading}
                                            timezone={timezone}
                                        />
                                    ))}
                            {(props.hasMore || props.loading) && (
                                <div className={styles.loadMoreRow}>
                                    {props.hasMore && !props.loading && (
                                        <Button
                                            dataTest="load-more-conversations-button"
                                            onClick={() =>
                                                props.fetchNextPage(
                                                    props.conversations.length
                                                        ? props.conversations[props.conversations.length - 1].id
                                                        : undefined
                                                )
                                            }
                                            className={props.loading ? styles.loadingButton : styles.loadMoreButton}
                                        >
                                            <FormattedMessage id="callsList.loadMore" />
                                        </Button>
                                    )}
                                    {props.loading && <Loader />}
                                </div>
                            )}
                        </div>
                    </Scrollbar>
                )}
            </div>
        );
    }
);

function EmptyList() {
    return (
        <div className={styles.emptyList}>
            <FormattedMessage id="callsList.noData" />
        </div>
    );
}
