import cx from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';


import { HelpPopupDescriptionIcon } from '../../icons/HelpPopupDescriptionIcon';
import popupStyles from '../Popups.pcss';
import { StandardInitialProps } from '../types';

import styles from './ErrorPopup.pcss';

export type ErrorPopupProps = StandardInitialProps & {
  messageId?: string;
}

export const ErrorPopup: React.FC<ErrorPopupProps> = ({ messageId, className, dataTest }) => <div
  data-test={dataTest}
  className={cx(popupStyles.popup, styles.errorPopup, className)}
>
  <FormattedMessage
    id={messageId}
    values={{
      linebreak: <br />,
      HelpIcon: <HelpPopupDescriptionIcon animationClass={styles.icon} />
    }}
  />
</div>