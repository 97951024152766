import * as React from 'react';

import styles from './IntentGroupOptions.pcss';
import {OptionsComponent} from './optionsMenu/Options';
import {RemoveGroupOption} from './optionsMenu/RemoveGroupOption';
import {RenderOptionsParams} from './pill/Pill';

type Props = {
  onDelete: () => any;
} & RenderOptionsParams;

export const IntentGroupOptionsComponent = ({onDelete}: Props) => {
  const options = [
    <RemoveGroupOption key={'remove-group-option'} onDelete={onDelete} />,
  ];

  return (
    <OptionsComponent
      containerPositionClassName={styles.optionsPosition}
      options={options} />
  );
};
