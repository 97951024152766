import React from 'react'

import { IndentedStepContent } from '../../../components/layouts/IndentedStepContent/IndentedStepContent'

import { IgnorableIntentsContainer } from './IgnorableIntents/IgnorableIntentsContainer'
import { LocalIntentsContainer } from './LocalIntents/LocalIntentsContainer'
import { IntentsTabProps } from './types'

export const IntentsTab: React.FC<IntentsTabProps> = (props) => <IndentedStepContent>
    <LocalIntentsContainer {...props} />
    <IgnorableIntentsContainer {...props} />
</IndentedStepContent>