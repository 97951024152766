import _ from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { AddButton } from '../components/AddButton/AddButton';
import { Scrollbar } from '../components/Scrollbar/Scrollbar';
import { interpretationApiLocale } from '../language/botLanguage';
import { NoBotsPage } from '../layout/NoBotsPage';
import { RootState } from '../reducers/reducers';
import { currentUserRoles, useBots } from '../selectors';
import { Bot } from '../surveyCreator/model';
import { getBotUrlId } from '../utils/CurrentBot';
import { UserRole } from '../utils/CurrentUser';
import { HttpClient } from '../utils/HttpClient';

import { AddBotRequest, BotAddPopup } from './BotAddPopup';
import { BotComponent } from './BotComponent';
import styles from './Dashboard.pcss';
import { DashboardHeader } from './DashboardHeader';
import { useBotTags } from './hooks';
import TagFilter from './TagFilter';
import { HorizontalSeparator } from '../components/HorizontalSeparator/HorizontalSeparator';
import { FormattedMessage } from 'react-intl';
import { Button } from '../components/Button/Button';

export type BotFilter = {
  query: string;
  tags: string[];
  showCustomerInvisibleBots: boolean;
};

type BotDashboardProps = {
  onBotsUpdate: () => void;
  botFilter: BotFilter;
  isAdmin: boolean;
  setBotFilter: (
    value:
      | null
      | BotFilter
      | ((prevState: null | BotFilter) => null | BotFilter)
  ) => void;
};

const BotDashboard = ({
  onBotsUpdate,
  botFilter,
  setBotFilter,
  isAdmin,
}: BotDashboardProps) => {
  const bots: Bot[] = useBots();
  const location = useLocation();
  const history = useHistory();
  const predefinedTags = useBotTags();

  const [showAddBotPopup, setShowAddBotPopup] = useState(false);

  const selectBot = (bot: Bot) => () => {
    history.push({
      ...location,
      pathname: `/${getBotUrlId(bot)}`,
    });
  };

  const addNewBot = (request: AddBotRequest) =>
    HttpClient.post({
      path: `/bots`,
      body: request,
    })
      .then(onBotsUpdate)
      .then(() => setShowAddBotPopup(false));

  const allOrganizations = _.uniqBy(
    bots.map((bot) => ({ name: bot.organization, id: bot.organizationId })),
    JSON.stringify
  );
  const allLanguages = _.uniq(_.keys(interpretationApiLocale));

  function filterBots(bot: Bot) {
    if (
      !botFilter.query &&
      botFilter.tags.length === 0 &&
      botFilter.showCustomerInvisibleBots
    ) {
      return true;
    }

    if (!bot.customerVisible && !botFilter.showCustomerInvisibleBots) {
      return false;
    }

    if (
      botFilter.query &&
      !bot.name.toLowerCase().includes(botFilter.query.toLowerCase()) &&
      !bot.organization.toLowerCase().includes(botFilter.query.toLowerCase())
    ) {
      return false;
    }

    if (
      botFilter.tags.length > 0 &&
      _.difference(botFilter.tags, bot.tags).length > 0
    ) {
      return false;
    }

    return true;
  }

  const toggleShowCustomerInvisibleBots = () =>
    setBotFilter((state) => ({
      ...state,
      showCustomerInvisibleBots: !state.showCustomerInvisibleBots,
    }));

  const filteredBots = bots.filter(filterBots)
  const groupedBots = _.groupBy(
    filteredBots,
    (bot) => bot.organization
  );

  return (
    <div className={styles.container} data-test={'bots-dashboard'}>
      <Scrollbar>
        <div className={styles.scrollBody}>
          <div className={styles.addBotButtonContainer}>
            <div className={styles.filterContainer}>
              {isAdmin && (
                <TagFilter botFilter={botFilter} setBotFilter={setBotFilter} />
              )}
              {isAdmin && (
                <Button
                  buttonType="link"
                  onClick={toggleShowCustomerInvisibleBots}
                >
                  <FormattedMessage
                    id={
                      botFilter.showCustomerInvisibleBots
                        ? 'dashboard.botsView.customerInvisibleBots.show'
                        : 'dashboard.botsView.customerInvisibleBots.hide'
                    }
                  />
                </Button>
              )}
            </div>
            <div className={styles.addBotButton}>
              <AddButton
                textId={'dashboard.botsView.addBot.button'}
                dataTest={'bot-add-button'}
                onClick={() => setShowAddBotPopup(true)}
              />
            </div>
          </div>
          <div className={styles.botsListContainer}>
            {isAdmin ? (
              Object.entries(groupedBots).map(
                ([organization, organizationBots]) => (
                  <div key={organization} data-test="organization">
                    <div className={styles.organizationHeaderContainer}>
                      <p
                        className={styles.organizationName}
                        data-test="organization-name"
                      >
                        {organization}
                      </p>
                      {isAdmin && (
                        <Link
                          to={`/${organization}`}
                          className={styles.manageOrganizationLink}
                        >
                          <FormattedMessage id="dashboard.botsView.manageOrganization.button" />
                        </Link>
                      )}
                    </div>
                    <HorizontalSeparator
                      className={styles.organizationSeparator}
                    />
                    <div className={styles.botsList}>
                      {organizationBots.map((bot: Bot) => (
                        <BotComponent
                          onBotsUpdate={onBotsUpdate}
                          bot={bot}
                          onClick={selectBot(bot)}
                          key={bot.id}
                          isAdmin={isAdmin}
                          allOrganizations={allOrganizations}
                          predefinedTags={predefinedTags}
                        />
                      ))}
                    </div>
                  </div>
                )
              )
            ) : (
              <div className={styles.botsList}>
                {filteredBots.map((bot: Bot) => (
                  <BotComponent
                    onBotsUpdate={onBotsUpdate}
                    bot={bot}
                    onClick={selectBot(bot)}
                    key={bot.id}
                    isAdmin={isAdmin}
                    allOrganizations={allOrganizations}
                    predefinedTags={predefinedTags}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </Scrollbar>
      {showAddBotPopup && (
        <BotAddPopup
          organizations={allOrganizations}
          onSave={addNewBot}
          onCancel={() => setShowAddBotPopup(false)}
          languages={allLanguages}
        />
      )}
    </div>
  );
};

type DashboardProps = {
  onBotsUpdate: () => void;
  hasBots: boolean;
};

type StateProps = {
  hasBots: boolean;
};
const mapStateToProps = (state: RootState): StateProps => ({
  hasBots: _.size(state.bots.bots) > 0,
});

export const Dashboard = connect(mapStateToProps)(
  ({ onBotsUpdate, hasBots }: DashboardProps) => {
    const isAdmin = useSelector(currentUserRoles).includes(UserRole.ADMIN);
    const [botFilter, setBotFilter] = useState<BotFilter>({
      query: '',
      tags: [],
      showCustomerInvisibleBots: true,
    });

    return (
      <>
        <DashboardHeader
          botFilter={botFilter}
          onBotFilterChange={setBotFilter}
        />
        {!hasBots ? (
          <NoBotsPage />
        ) : (
          <BotDashboard
            onBotsUpdate={onBotsUpdate}
            botFilter={botFilter}
            setBotFilter={setBotFilter}
            isAdmin={isAdmin}
          />
        )}
      </>
    );
  }
);
