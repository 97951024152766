import classNames from 'classnames';
import _ from 'lodash';
import * as React from 'react';
import { CSSProperties, useState, useContext, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { BasicMessage, QuestionError } from '../../../model';
import { DeleteOption } from '../../optionsMenu/DeleteOption';
import { useFixedPosition } from '../../useFixedPosition';
import { MessageContext } from '../../StepDetail';
import { SingleMessageComponent } from '../SingleMessage';

import {
    ConditionalMessageProps,
    ConditionalMessageSource,
    findSource,
    getConditionalAnswerLabel,
    MultipleConditionalMessageProps,
    withDefaultAtEnd
} from './conditional-messages-utils';
import styles from './ConditionalMessages.pcss';
import {
    ConditionalMessageVariableSelector,
} from './ConditionalMessageVariableSelect';

type ConditionalMessagesProps = ConditionalMessageProps & {
    referencedSource: ConditionalMessageSource;
    errors?: QuestionError[]
};

export const ConditionalMessageComponent = (props: ConditionalMessageProps) => {
    const referencedSource = findSource(props.availableConditionalSources, props.message.name);
    const { contentRef, style } = useFixedPosition([], { top: -46, left: 231 });
    return (
        <div data-test={`conditional-${props.dataTestPrefix}-message`}
            className={classNames(styles.messageContainer, props.nested ? styles.nested : '')}>
            <div className={classNames(styles.header, {[styles.localIntent]: props.isLocalIntent})} ref={contentRef}>
                <div className={classNames(styles.label, {[styles.localIntent]: props.isLocalIntent})}>
                    {
                        props.title
                            ? props.title
                            : <FormattedMessage id={props.labelId} />
                    }
                    {
            props.onDelete &&
            <DeleteOption key={'delete-option'} onDelete={props.onDelete} alignToTop/>
          }
                </div>
                <div className={styles.condition}>
                    <Condition {...props} selectorPositionStyle={style} referencedSource={referencedSource}/>
                </div>
            </div>
            {referencedSource && <ConditionalMessages {...props} referencedSource={referencedSource} />}
        </div>
    );
}

const ConditionalMessages = (props: ConditionalMessagesProps) => {
    const intl = useIntl();
    const { onConditionalMessageChange } = useContext(MessageContext);
    const handleChangeWithContext = useCallback((changedMessage: BasicMessage, answerId: string) =>
      onConditionalMessageChange({
        [answerId]: changedMessage,
      }, props.path), []);

    const handleChange = (changedMessage: BasicMessage, answerId: string) =>
      props.onChange({
        ...props.message,
        values: {
          ...props.message.values,
          [answerId]: changedMessage
        }
      });

    const errorFor = (answerId: string) => {
        const errors = props.errors && props.errors.filter(({field}) => field.includes(`message.values.${answerId}.text`)).map((({error}) => error));
        return errors?.length > 0 ? errors[0] : undefined;
    };

    return (
        <div>
            {_.orderBy(Object.entries(props.message.values), withDefaultAtEnd).map(([answerId, messageValue]) => (
                <div data-test="conditional-message-element" key={answerId} className={styles.messageElement}>
                    <SingleMessageComponent
                        answerId={answerId}
                        label={getConditionalAnswerLabel(intl.messages, props.referencedSource.possibleValues, answerId)}
                        message={messageValue}
                        dataTestPrefix={props.dataTestPrefix}
                        // If bypassOnChangeFromContext === true use the handler based on the onChange function coming from props.
                        // Else, use the handler from the MessageContext which is defined in the StepDetail. This option was introduced as an optimization for UI performance issues.
                        // More details in the issue TLK-3116
                        onChange={props.bypassOnChangeFromContext ? handleChange : handleChangeWithContext}
                        standalone={false}
                        errorMessage={errorFor(answerId)}
                    />
                </div>
            ))}
        </div>
    );
};

export const Condition = (props: (MultipleConditionalMessageProps | ConditionalMessageProps) & { valueMapper?: (value: any) => any; selectorPositionStyle: CSSProperties } & {referencedSource: ConditionalMessageSource}) => {
    const [showSelector, toggleSelector] = useState(false);
    const conditionName = props.referencedSource?.variable.key || props.message.name;
    return (
        <>
            <div onClick={() => toggleSelector(true)} data-test={`conditional-message-variable-condition`}>
                <span><FormattedMessage id={'survey-creator.conditional.conditionLabel'} />:</span> <span
                    data-test={`conditional-message-variable-name`}>{conditionName}</span>
            </div>
            {
                showSelector &&
                <ConditionalMessageVariableSelector {...props}
                    outsideClickCallback={() => toggleSelector(false)}
                    positionStyle={props.selectorPositionStyle} />
            }
        </>
    );
}

