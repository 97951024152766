import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useCurrentBot } from '../../utils/CurrentBot';
import { EventData } from '../models/Events';

import { Event } from './Event';
import styles from './Events.pcss';
import { countVisibleTags } from './eventsVisibilityCalculator';

const DEFAULT_NUMBER_OF_VISIBLE_ROWS = 2;

interface Props {
    readonly eventsAvailableSpace: number;
    readonly events: ReadonlyArray<EventData>;
    readonly stepEvents: ReadonlyArray<EventData>;
}

export function Events({ events, stepEvents, eventsAvailableSpace }: Props) {
    const [expanded, setExpanded] = useState(false);
    const intl = useIntl();
    const bot = useCurrentBot();
    const visibleTagsCount = countVisibleTags(stepEvents, eventsAvailableSpace, DEFAULT_NUMBER_OF_VISIBLE_ROWS, restCount =>
        intl.formatMessage({ id: 'callsList.events.showMore' }, { hiddenAmount: restCount })
    );
    const getLabelFor = (event: EventData) => {
        return bot.type === 'survey'
            ? intl.messages[`conversations.filter.${event.key}`] as string || event.label
            : event.label;
    };

    return (
        <div data-test="events-cell" className={styles.events}>
            <div className={styles.eventGroup}>
                {
                    events.map(event => (
                        <Event
                            key={event.key}
                            name={event.label}
                            type={event.type}
                        >
                            {
                                Boolean(event.icon) && event.icon
                            }
                            {getLabelFor(event)}
                        </Event>
                    ))
                }
            </div>

            {
                events.length > 0 && stepEvents.length > 0 &&
                <hr />
            }

            <div className={styles.eventGroup}>
                {stepEvents
                    .filter((event, index) => expanded || index < visibleTagsCount)
                    .map(event => (
                        <Event
                            key={event.key}
                            name={event.label}
                            type={event.type}
                        >
                            {getLabelFor(event)}
                        </Event>
                    ))}

                {!expanded && stepEvents.length - visibleTagsCount > 0 && (
                    <Event
                        data-test="show-more"
                        key="show-more"
                        type="results"
                        onClick={e => {
                            e.stopPropagation();
                            setExpanded(true);
                        }}
                        className={styles.tagButton}
                        dataTest="expand-events"
                    >
                        <FormattedMessage
                            id="callsList.events.showMore"
                            values={{ hiddenAmount: stepEvents.length - visibleTagsCount }}
                        />
                    </Event>
                )}
                {expanded && (
                    <Event
                        data-test="hide-more"
                        type="results"
                        onClick={e => {
                            e.stopPropagation();
                            setExpanded(false);
                        }}
                        className={styles.tagButton}
                        dataTest="hide-events"
                    >
                        <FormattedMessage id="callsList.events.hideMore" />
                    </Event>
                )}
            </div>
        </div>
    );
}
