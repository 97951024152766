import React from 'react';

import { IndentedStepContent } from '../../../components/layouts/IndentedStepContent/IndentedStepContent';
import { DatetimeQuestion } from '../../model';
import {AnonymizeAnswerToggle} from '../AnonymizeAnswerToggle';
import {DatetimeMessages} from '../DatetimeMessages';
import {DuplexToggle} from '../DuplexToggle';
import {GoToComponent} from '../GoToComponent';
import { IntentsTab } from '../IntentsTab/IntentsTab';
import {IsPostponeRequestToggle} from '../IsPostponeRequestToggle';
import {RequiredAnswer} from '../RequiredAnswer';
import {SaveAs} from '../SaveAs';
import {PostSpeechTimeoutField} from '../PostSpeechTimeoutField/PostSpeechTimeoutField';
import { PreSpeechTimeoutField } from '../PreSpeechTimeoutField/PreSpeechTimeoutField';
import { RecognizerField } from '../RecognizerField/RecognizerField';

import {TabFactory} from './question-component-factory-model';
import { StepTabType } from './types';

export const datetimeQuestionFactory: TabFactory<DatetimeQuestion> = {
    [StepTabType.Question]: ({question, onChange}) => {
        return (
            <>
                <DatetimeMessages question={question} onChange={onChange}/>
            </>
        );
    },
    [StepTabType.General]: ({question, onChange}) => {
        return (
            <>
                <RecognizerField question={question} onChange={onChange} />
                <SaveAs question={question} onChange={onChange}/>
                <RequiredAnswer question={question} onChange={onChange}/>
                <DuplexToggle question={question} onChange={onChange}/>
                <AnonymizeAnswerToggle question={question} onChange={onChange} />
                <IsPostponeRequestToggle question={question} onChange={onChange}/>
                <PostSpeechTimeoutField question={question} onChange={onChange} />
                <PreSpeechTimeoutField question={question} onChange={onChange} />
            </>
        );
    },
    [StepTabType.Answer]: ({question, onChange}) => {
        return (
            <IndentedStepContent>
                <GoToComponent question={question} onChange={onChange} availableOptions={['false']}/>
                <GoToComponent question={question} availableOptions={['repeated']} onChange={onChange}/>
            </IndentedStepContent>
        );
    },
    [StepTabType.Intents]: (props) => <IntentsTab {...props} />
};
