import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from '../../../components/Box/Box';
import { Button } from '../../../components/Button/Button';
import { InputWithLabel } from '../../../components/InputWithLabel/InputWithLabel';
import { Row } from '../../../components/Row/Row';
import { FormProps } from '../../../forms/types';
import loginStyles from '../components/Login.pcss';

export type ResetPasswordFormProps = FormProps & {
  resetPasswordDescription: string;
  onBackToLoginClick?: React.MouseEventHandler
};

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  fieldsProps,
  onSubmit,
  onFormSubmit,
  isSubmitting,

  resetPasswordDescription,
  onBackToLoginClick
}) => <Box noPadding>
    <form onSubmit={onFormSubmit}>
      <p className={loginStyles.loginDescription}>{resetPasswordDescription}</p>
      <InputWithLabel
        className={loginStyles.loginInput}
        {...fieldsProps.email}
      />
      <Row className={loginStyles.row}>
        <Button
          type="button"
          onClick={onBackToLoginClick}
          buttonType="link"
          translateText="auth.signIn.backToLoginButtonLabel"
        />
        <Button
          buttonType={isSubmitting ? 'loading' : 'primary'}
          dataTest="resetPasswordButton"
          disabled={!Boolean(fieldsProps.email.value)}
          type="submit"
        >
          {!isSubmitting && <FormattedMessage id="auth.signIn.resetPasswordButtonLabel" />}
        </Button>
      </Row>
    </form>
  </Box>;