import { ActionType, getType } from 'typesafe-actions';
import { Auth } from 'aws-amplify';

import { fetchCurrentUser } from '../actions/actions';
import * as actions from '../actions/currentUserActions';
import { User } from '../utils/CurrentUser';
import { postHog } from '../utils/postHog';

export type CurrentUserAction = ActionType<typeof actions>;

export interface CurrentUserState {
    user?: User;
    fetched: boolean;
}

const defaultState: CurrentUserState = { fetched: false, user: undefined };

export const currentUser = (state = defaultState, action: CurrentUserAction) => {
    switch (action.type) {
        case getType(fetchCurrentUser.success):
            return {
                fetched: true,
                user: action.payload
            };
        case getType(fetchCurrentUser.failure):
            postHog?.opt_out_capturing();
            postHog?.reset();
            Auth.signOut().then(() => {
                window.location.replace('/login');
            });
            return {
                fetched: true,
                user: null,
            };
        default:
            return state;
    }
};
