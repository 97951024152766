import _ from 'lodash';
import * as React from 'react';

import {ElementWithLabel} from '../../components/ElementWithLabel/ElementWithLabel';
import {IconButton} from '../../components/IconButton/IconButton';
import {Input} from '../../components/Input';
import {TextAreaWithVariables} from '../../components/TextArea/TextAreaWithVariables';
import {DeleteIcon} from '../../icons';
import {AddButton} from '../../surveyCreator/components/AddButton';
import {HorizontalSeparator} from '../../components/HorizontalSeparator/HorizontalSeparator';
import { VariableName } from '../../surveyCreator/model';
import {HttpStepHeader} from '../schedule-model';

import styles from './HttpHeadersComponent.pcss';

const HttpHeaderComponent = (props: { header: HttpStepHeader, onChange: (header: HttpStepHeader) => void; variables: VariableName[]}) => {
    const handleChange = (key: string) => (value: string) => {
        props.onChange({
            ...props.header,
            [key]: value,
        });
    };

    return (
        <div data-test="http-header" className={styles.headerRow}>
            <ElementWithLabel inner noPadding labelId={'survey-creator.httpStep.headerName'}>
                <Input dataTest="name" value={_.isNil(props.header.name) ? '' : props.header.name}
                    onChange={handleChange('name')} className={styles.input} />
            </ElementWithLabel>
            <ElementWithLabel inner noPadding labelId={'survey-creator.httpStep.headerValue'}>
                <TextAreaWithVariables dataTest="value" value={_.isNil(props.header.value) ? '' : props.header.value}
                    onChange={handleChange('value')} asInput availableVariables={props.variables} />
            </ElementWithLabel>
        </div>
    );
};


type Props = { headers: HttpStepHeader[], onChange: (headers: HttpStepHeader[]) => void, variables: VariableName[] };
export const HttpHeadersComponent = (props: Props) => {

    const onHeaderChange = (index: number) => (changeHeader: HttpStepHeader) => {
        const newHeaders = props.headers.map((header, id) => id === index ? changeHeader : header);
        props.onChange(newHeaders);
    };

    const addHeader = () => props.onChange([...props.headers, { name: '', value: '' }]);

    const removeHeader = (index: number) => {
        props.onChange(props.headers.filter((value, id) => id !== index))
    };

    return (
        <div>
            {
                props.headers.map((header, index) => (
                    <>
                    <div key={index} data-test="http-header-container" className={styles.headerContainer}>
                        <HttpHeaderComponent header={header} onChange={onHeaderChange(index)} variables={props.variables} />
                        <IconButton className={styles.deleteIcon} Icon={DeleteIcon} onClick={() => removeHeader(index)} />
                    </div>
                    {index < props.headers.length - 1 && <HorizontalSeparator/>}
                    </>
                ))
            }
            <AddButton className={styles.addButton} onClick={addHeader} dataTest={'add-http-header-button'} labelId={'survey-creator.httpStep.addHeaderButton'} />
        </div>
    );
};
