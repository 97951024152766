import cx from 'classnames';
import React from 'react'
import { FormattedMessage } from 'react-intl';

import { FormProps } from '../../forms/types';
import { Modal } from '../Modal/Modal'
import { ModalHeader } from '../Modal/ModalHeader';

import { ChangePasswordConfirmation } from './ChangePasswordConfirmation';
import { ChangePasswordForm } from './ChangePasswordForm';
import styles from './ChangePasswordModal.pcss';

export type ModalState = 'form' | 'confirmation';
export type ChangePasswordModalProps = Partial<FormProps> & {
  closeModal?: React.MouseEventHandler;
  popupMessageId?: string;
  confirmationDescriptionId?: string;
  modalState?: ModalState;
}

export const ChangePasswordModal: React.FC<ChangePasswordModalProps> = (props) => {
  return <Modal className={cx(styles.content, styles.changePasswordModal, {
    [styles.confirmation]: props.modalState === 'confirmation'
  })}>
    <ModalHeader>
      <FormattedMessage id={props.modalState === 'form' ? 'header.changePasswordModal.formHeader' : 'header.changePasswordModal.confirmationHeader'} />
    </ModalHeader>
    {
      props.modalState === 'form' &&
      <ChangePasswordForm {...props} />
    }

    {
      props.modalState === 'confirmation' &&
      <ChangePasswordConfirmation>
        <FormattedMessage id={props.confirmationDescriptionId}/>
        </ChangePasswordConfirmation>
    }

  </Modal>
}