import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { IconButton } from '../components/IconButton/IconButton';
import { SettingsIcon } from '../icons/SettingsIcon';
import { TooltipComponent } from '../components/Tooltip/Tooltip';
import { showErrorToast, showSuccessToast } from '../components/Toast/Toast';
import { MarkdownContent } from '../components/MarkdownContent/MarkdownContent';
import { useCurrentBot } from '../utils/CurrentBot';
import { ReportConfigModal } from './components/ReportConfigModal';
import { useGetReportConfig } from './hooks/useGetReportConfig';
import { useUpdateReportConfig } from './hooks/useUpdateReportConfig';

import styles from './ReportConfig.pcss';

interface ReportConfigProps {
  report: string;
}

const onError = () => {
  showErrorToast(<MarkdownContent contentKey={'generalError'} />);
};

export const ReportConfig = ({ report }: ReportConfigProps) => {
  const intl = useIntl();

  const [isOpen, setOpen] = useState(false);

  const { id } = useCurrentBot();
  const { data, isLoading } = useGetReportConfig(id, report, {
    enabled: isOpen,
    onError,
  });
  const { mutate: updateReportConfig } = useUpdateReportConfig(id, report);

  return (
    <>
      <TooltipComponent
        className={styles.iconButtonContainer}
        tooltipText={`${intl.messages['reportConfig.tooltip']}`}
      >
        <IconButton
          Icon={SettingsIcon}
          dataTest="report-config-button"
          onClick={() => setOpen(true)}
          className={styles.iconButton}
        />
      </TooltipComponent>
      {isOpen && (
        <ReportConfigModal
          data={data}
          report={report}
          onSubmit={(values) => {
            updateReportConfig(values, {
              onSuccess: () => {
                showSuccessToast(<MarkdownContent contentKey={'reportConfig.successToast'} />);
                setOpen(false)
              },
              onError: () => {
                showErrorToast(<MarkdownContent contentKey={'reportConfig.errorToast'} />)
              },
            });
          }}
          onClose={() => setOpen(false)}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
