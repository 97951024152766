import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { FormattedMessage } from 'react-intl';
import { OrganizationSMSSettings, OrganizationSettings } from './SMSSettings';
import { HttpClient } from '../../utils/HttpClient';
import { showErrorToast, showSuccessToast } from '../../components/Toast/Toast';
import { getOrganizationSettingsCacheKey } from './useGetOrganizationSettings';

export function useUpdateOrganizationSMSSettings(organizationName: string) {
    const queryClient = useQueryClient();
    const organizationSettingsCacheKey = getOrganizationSettingsCacheKey(organizationName);

    return useMutation((data: OrganizationSMSSettings) => HttpClient.put({
        path: `/organizations/${organizationName}/settings/sms`,
        body: data,
    }), {
        onMutate: (newOrganizationSMSSettings) => {
            const oldOrganizationSettings = queryClient.getQueryData<OrganizationSettings>(organizationSettingsCacheKey);

            if (oldOrganizationSettings) {
                queryClient.setQueryData(organizationSettingsCacheKey, () => ({
                    ...oldOrganizationSettings,
                    sms: newOrganizationSMSSettings
                }));
            }

            return oldOrganizationSettings
        },
        onSuccess: () => {
            showSuccessToast(
                <FormattedMessage id="organization.sms.update.successToast" />
            );
        },
        onError: (_error, _newOrganizationSMSSettings, oldOrganizationSettings) => {
            showErrorToast(
                <FormattedMessage id="organization.sms.update.errorToast" />
            );
            queryClient.setQueryData(organizationSettingsCacheKey, oldOrganizationSettings)
        },
        onSettled: () => {
            queryClient.invalidateQueries(organizationSettingsCacheKey);
        },
    });
}
