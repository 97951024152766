import React, { useCallback, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { scroller } from 'react-scroll';

import { DeleteIcon } from '../../../../../icons';
import { Box } from '../../../../../components/Box/Box';
import { IconButton } from '../../../../../components/IconButton/IconButton';
import { ElementWithLabel } from '../../../../../components/ElementWithLabel/ElementWithLabel';
import { InputWithLabel } from '../../../../../components/InputWithLabel/InputWithLabel';
import { SelectGoToBlock } from '../../../../components/SelectGoTo';
import { SingleMessageComponent } from '../../../../components/messages/SingleMessage';
import { SynonymsChipsComponent } from '../../../../../components/chips/SynonymsChipsComponent';
import { SynonymsChipsListComponent } from '../../../../../components/chips/SynonymsChipsListComponent';

import { Synonym } from '../../../../model';
import { AnswerType, GlobalReadOrderType, ItemReadOrderType, ReactionType } from '../../../types';

import { BlocksContext, DisambiguationContext } from '../../../containers';
import { useGlobalSynonyms } from '../../../hooks';
import { ModelToViewMapper } from '../../../services';
import { getReactionFromDropdownOption, specialReactions } from '../utils';

import { AnswerBaseProps } from './types';
import styles from './AnswerBase.pcss';

import { ItemReadOrder } from './ItemReadOrder';
import _ from 'lodash';
import { CurrentBot } from '../../../../../utils/CurrentBot';
import { lemmatizeSynonym } from '../../../../../utils/lemmatizeSynonym';

const AnswerBase: React.FC<AnswerBaseProps> = ({
    value,
    onRemove,
    onChange,
}) => {
    const { messages } = useIntl();
    const currentBot = useContext(CurrentBot).getCurrentBot();
    // uses GlobalSynonymsContext
    const globalSynonyms = useGlobalSynonyms(value.key);
    const { blocks } = useContext(BlocksContext);
    const { disambiguation } = useContext(DisambiguationContext);

    const disambiguationItem = useMemo(() => {
        return disambiguation.find((item) => {
            return item.answers.find((answer) => {
                if (answer.type === AnswerType.DICTIONARY) {
                    return answer.id === value.id;
                }
                return answer.id === value.id && answer.key === value.key;
            });
        });
    }, [disambiguation, value.id, value.key]);

    const getCurrentReactionOptionId = useCallback(() => {
        if (!value.reaction) {
            return specialReactions.continue;
        }

        if (value.reaction.type === ReactionType.GOTO) {
            return value.reaction.moduleId;
        }

        return value.reaction.type;
    }, [value.reaction?.type]);

    const handleReadOrderMessageChange = useCallback((message) => {
        // To be removed, once we support entity editing
        if (value.type === AnswerType.ENTITY) {
            return;
        }
        onChange({
            ...value,
            readMessage: message,
        })
    }, [value, onChange])

    const handleSynonymsChange = useCallback((synonyms: Synonym[]) => {
        // To be removed, once we support entity editing
        if (value.type === AnswerType.ENTITY) {
            return;
        }
        onChange({
            ...value,
            synonyms,
        });
    }, [value, onChange]);

    const handleKeyChange = useCallback((key: string) => {
        onChange({ ...value, key });
    }, [value, onChange]);

    const handleReactionChange = useCallback((id) => {
        const reaction = getReactionFromDropdownOption(id);
        onChange({ ...value, reaction });
    }, [value, onChange]);

    const handleOrderChange = useCallback((order: ItemReadOrderType) => {
        // To be removed, once we support entity editing
        if (value.type === AnswerType.ENTITY) {
            return;
        }

        onChange({
            ...value,
            order: {
                ...value.order,
                item: order,
            },
        });
    }, [value, onChange]);

    const handleScrollTo = () => {
        // @TODO: leftover for debugging purposes (scrolling does not work)
        // const closestScroll = containerRef.current.closest('div[style*="overflow: scroll"]')
        const id = ModelToViewMapper.getDisambiguationIdentifier(disambiguationItem.answers);
        scroller.scrollTo(id, {
            duration: 600,
            smooth: true,
            // containerId: closestScroll && closestScroll.id,
        });
    }

    const renderDisambiguationInfo = () => {
        if (!disambiguationItem) {
            return null;
        }
        return <Box className={styles.disambiguationDescription}>
            <span>
                {messages['survey-creator.word.notPreciseLinkDescription']}
                <button
                    data-test={'not-precise-answer-link'}
                    className={styles.disambiguationLink}
                    onClick={handleScrollTo}
                >{messages['survey-creator.word.notPreciseLink']}</button>
            </span>
        </Box>;
    }

    const renderGlobalSynonyms = () => {
        if (globalSynonyms.length === 0) {
            return null;
        }

        return <Box className={styles.globalSynonymsContainer}>
            <ElementWithLabel
                inner
                noPadding
                labelId={'survey-creator.dictionary.chipsGlobalLabel'}
            >
                <SynonymsChipsListComponent items={globalSynonyms} showElementsNumber={4} />
            </ElementWithLabel>
        </Box>;
    }

    const renderReadOrderMessage = () => {
        // To be removed, once we support entity items ordering
        if (
            value.type === AnswerType.ENTITY ||
            value.order.item === ItemReadOrderType.NONE ||
            value.order.global === GlobalReadOrderType.NONE
        ) {
            return null;
        }

        return <Box className={styles.messageRow}>
            <SingleMessageComponent
                dataTestPrefix={`answer`}
                labelId={'survey-creator.word.answerMessageLabel'}
                message={value.readMessage || { text: '' }}
                onChange={handleReadOrderMessageChange}
            />
        </Box>
    }

    const renderRemove = () => {
        if (typeof onRemove !== 'function') {
            return null;
        }

        return <IconButton
            Icon={DeleteIcon}
            hoverType={'warning'}
            onClick={onRemove}
            dataTest={'remove-answer-button'}
            className={styles.deleteAnswerButton}
        />;
    };

    const renderOrder = () => {
        if (value.type === AnswerType.ENTITY || value?.order?.global === GlobalReadOrderType.NONE) {
            return null;
        }
        return <ItemReadOrder value={value.order} onChange={handleOrderChange} />
    };

    const renderControls = () => {
        return <div className={styles.answerBaseControls}>
            {renderOrder()}
            <div className={styles.answerBaseButtons}>
                {renderRemove()}
            </div>
        </div>;
    };

    return <Box
        className={styles.answerBase}
        dataTest="answer"
        border
        noPadding
    >
        {renderControls()}

        <div className={styles.answerKeyAndReaction}>
            <InputWithLabel
                dataTest={'answer-text'}
                labelId={'survey-creator.word.answerIdLabel'}
                className={styles.answerKeyInput}
                value={value.key}
                onChange={handleKeyChange}
            />

            <ElementWithLabel
                inner
                labelId={'survey-creator.wordAnswerGoToLabel'}
                className={styles.answerReaction}
            >
                <div data-test="answer-go-to">
                    <SelectGoToBlock
                        onChange={handleReactionChange}
                        currentBlockId={getCurrentReactionOptionId()}
                        blocks={blocks}
                        includeContinue
                        specialOptionsIds={specialReactions}
                    />
                </div>
            </ElementWithLabel>
        </div>

        {renderReadOrderMessage()}

        {renderGlobalSynonyms()}

        <ElementWithLabel
            inner
            labelId={'survey-creator.dictionary.chipsLabel'}
        >
            <SynonymsChipsComponent
                data-test="synonyms"
                items={value.synonyms}
                onChange={handleSynonymsChange}
                i18nKey="survey-creator.dictionaryPlaceholder"
            />
        </ElementWithLabel>

        {renderDisambiguationInfo()}
    </Box>;
};

export default AnswerBase;
