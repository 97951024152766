import { UseQueryOptions, useQuery } from 'react-query';
import { HttpClient } from '../../utils/HttpClient';

export type ReportConfigResponse = {
  emails: string[];
  password: string;
  notificationEnabled: boolean;
}

export function useGetReportConfig(
  botId: string,
  reportName: string,
  options?: UseQueryOptions<ReportConfigResponse>
) {
  return useQuery(
    [botId, 'data-collection', reportName, 'config'],
    () =>
      HttpClient.get({
        path: `/bots/${botId}/data-collection/${encodeURIComponent(reportName)}/config`,
      }).then((resp) => resp.data as ReportConfigResponse),
    options
  );
}