import cx from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from '../Box/Box';

import styles from './ElementWithLabel.pcss';

export type ElementWithLabelProps = Partial<Record<'borderEnabled' | 'noPadding' | 'inner' | 'disabled', boolean>> &
    Partial<Record<'className' | 'dataTest', string>> & {
        labelId: string;
        labelSuffix?: string;
        inline?: boolean;
    };

export const ElementWithLabel: React.FC<ElementWithLabelProps> = ({
    borderEnabled,
    className,
    dataTest,
    labelId,
    labelSuffix,
    noPadding,
    inner,
    children,
    disabled,
    inline
}) => <Box
    className={cx(className, { [styles.inline]: inline })}
    dataTest={dataTest}
    border={borderEnabled}
    noPadding={noPadding}
>
        <div className={cx(styles.label, { [styles.inner]: inner, [styles.disabled]: disabled })}>
            <FormattedMessage id={labelId}>
                {text => labelSuffix ? `${text} ${labelSuffix}` : text}
            </FormattedMessage>
        </div>
        {children}
    </Box>
