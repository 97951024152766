import cx from 'classnames';
import React from 'react';

import { Box } from '../../../components/Box/Box';
import { StandardInitialProps } from '../../../components/types';
import { IconProps } from '../../../icons/iconTypes';
import useBranding from '../../../hooks/useBranding/useBranding';

import styles from './LoginBox.pcss';
import { LoginMessage } from './LoginMessage';

export type LoginBoxProps = StandardInitialProps & {
  header: string;
  text?: string;
  message?: React.ReactNode;
  MessageIcon?: React.FC<IconProps>;
}

export const LoginBox: React.FC<LoginBoxProps> = ({
  className,
  text,
  header,
  message,
  MessageIcon,
  children
}) => {
    const { logoWide, name } = useBranding();
    return <Box className={cx(styles.loginBox, className)} border>
      {message &&
        <LoginMessage
          message={message}
          Icon={MessageIcon} />}
      <img src={logoWide} alt={name} width={140} />
      {header && <h1>{header}</h1>}
      {text && <h2>{text}</h2>}
      {children}
    </Box>;
  }