import React from 'react';

import { IconProps } from '../iconTypes';

export function StatisticsSeparatorIcon(props: IconProps) {
  return (
    <svg
      width="2"
      height="94"
      viewBox="0 0 2 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.animationClass}
    >
      <rect width="2" height="94" rx="1" fill="#DCDCE5" />
    </svg>
  );
}
