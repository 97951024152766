import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import { currentUserRoles } from '../../selectors'
import {
    isDirtySelector,
    isSurveyInProgressSelector,
    modifiedConfig as modifiedConfigSelector,
    savedConfig as savedConfigSelector
} from '../../selectors/surveyConfig'
import { useCurrentBot } from '../../utils/CurrentBot'
import { UserRole } from '../../utils/CurrentUser'

import { deleteModifiedConfig, fetchSurveyConfig, fetchSurveyScheduleStatus, patchSurveyConfig } from './store/actions'
import { SurveyConfigType } from './store/types'

export const useSurveyConfig = () => {
    const dispatch = useDispatch()
    const currentBot = useCurrentBot()
    useEffect(() => {
        dispatch(fetchSurveyConfig.request(currentBot.id))
    }, [currentBot])

    const savedConfig = useSelector(savedConfigSelector)
    const modifiedConfig = useSelector(modifiedConfigSelector)
    const isDirty = useSelector(isDirtySelector);

    const displayedConfig = isDirty ? modifiedConfig : savedConfig.data;

    return {
        savedConfig,
        modifiedConfig,
        displayedConfig,

        hasChanges: isDirty,
        isLoading: savedConfig.isLoading,
        isReady: savedConfig.isReady,
        hasError: savedConfig.hasError
    }
}

export const useFetchBotConfig = () => {
    const dispatch = useDispatch()
    const currentBot = useCurrentBot()
    const savedConfig = useSelector(savedConfigSelector)

    useEffect(() => {
        if (currentBot && !savedConfig.data && !savedConfig.isLoading) {
            dispatch(fetchSurveyConfig.request(currentBot.id))
        }
    }, [currentBot])

    return savedConfig
}

export const useSurveyScheduleStatus = () => {
    const dispatch = useDispatch()
    const currentBot = useCurrentBot()
    useEffect(() => {
        dispatch(fetchSurveyScheduleStatus.request(currentBot.id))
    }, [currentBot]);

    const isSurveyInProgress = useSelector(isSurveyInProgressSelector);
    return { isSurveyInProgress }
}

export const usePatchConfig = (override?: boolean) => {
    const dispatch = useDispatch();
    const currentBot = useCurrentBot();
    const modifiedConfig = useSelector(modifiedConfigSelector);
    const roles = useSelector(currentUserRoles);
    const isAdmin = roles.includes(UserRole.ADMIN);
    const hasSettingsPermission = roles.includes(UserRole.SETTINGS);

    const patchConfig = useCallback((overrides?: Partial<SurveyConfigType>) => {
        const config = override ? { ...modifiedConfig, ...overrides } : modifiedConfig;

        let payload = {}
        if (isAdmin) {
            payload = config;
        } else if (hasSettingsPermission) {
            payload = _.omit(config, 'phoneConfigs', 'countryCallingCode');
        }

        dispatch(patchSurveyConfig.request({
            id: currentBot.id,
            config: payload
        }))
    }, [dispatch, currentBot, modifiedConfig])

    return patchConfig;
}

export const useCancelChanges = () => {
    const dispatch = useDispatch();
    const cancelChanges = useCallback(() => dispatch(deleteModifiedConfig()),
        [dispatch, deleteModifiedConfig])
    return cancelChanges
}

export const useAdminRole = () => {
    const roles = useSelector(currentUserRoles);
    return roles.includes(UserRole.ADMIN);
}
