import { EventData } from '../models/Events';

import { getTagWidth } from './eventsMeasurer';

const ELEMENT_PADDING = 8;
const ROW_MARGINS = 24;

export function countVisibleTags(
    events: ReadonlyArray<EventData>,
    rowWidth: number,
    maxNumberOfRows: number,
    getShowRestText: (restCount: number) => string
) {
    const getRestButtonWidth = (index: number) => getTagWidth(getShowRestText(events.length - index)) + ELEMENT_PADDING;
    let currentRowWidth = 0;
    let currentRow = 1;
    let i = 0;
    for (; i < events.length; i++) {
        const tagWidth = getTagWidth(events[i].label.toString()) + ELEMENT_PADDING;
        if (currentRowWidth + tagWidth <= rowWidth - ROW_MARGINS) {
            currentRowWidth += tagWidth;
        } else if (currentRow >= maxNumberOfRows) {
            break;
        } else {
            currentRowWidth = tagWidth;
            currentRow++;
        }
    }
    if (i < events.length && currentRowWidth + getRestButtonWidth(i) > rowWidth - ROW_MARGINS) {
        let recoveredSpace = rowWidth - ROW_MARGINS - currentRowWidth;
        while (recoveredSpace < getRestButtonWidth(i) && i > 0) {
            i--;
            recoveredSpace += getTagWidth(events[i].label.toString()) + ELEMENT_PADDING;
        }
    }

    return i;
}
