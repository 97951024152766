import { useMutation, useQueryClient } from 'react-query';
import { HttpClient } from '../../utils/HttpClient';

export type UpdateScheduledReportConfigPayload = {
  emails: string[];
  password: string;
  notificationEnabled: boolean;
};

export const useUpdateReportConfig = (
  botId: string,
  reportName: string,

) => {
  const queryClient = useQueryClient();

  return useMutation((payload: UpdateScheduledReportConfigPayload) =>
    HttpClient.put({
      path: `/bots/${botId}/data-collection/${encodeURIComponent(reportName)}/config`,
      body: payload,
    }),
    {
      onSettled() {
        queryClient.invalidateQueries([botId, 'data-collection']);
        queryClient.invalidateQueries([botId, 'data-collection', reportName, 'config']);
      },
    }
  );
}
