import { useQuery } from 'react-query';
import { Tag } from './BotComponent';
import { HttpClient } from '../utils/HttpClient';
import _ from 'lodash';

export const useBotTags = () => {
  const { data: predefinedTags } = useQuery<Tag[]>(
    'bats-tags',
    () => HttpClient.get({ path: `/tags` }).then((res) => res.data),
    { initialData: [] }
  );

  return _.sortBy(predefinedTags, ['tag']);
};
