import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { interpretationApiLocale } from '../../../../language/botLanguage';
import { globalWordsForLanguageSelector } from '../../../../selectors/globalWordsSelectors';
import { useCurrentBot } from '../../../../utils/CurrentBot';
import { fetchGlobalWords } from '../../../store/globalSynonyms/actions';

export const useGlobalWords = () => {
  const currentBot = useCurrentBot();
  const dispatch = useDispatch();

  const languageCode = currentBot?.voiceConfig?.languageCode;
  const language = interpretationApiLocale[languageCode];
  const globalWords = useSelector(globalWordsForLanguageSelector(language));

  useEffect(() => {
    dispatch(fetchGlobalWords.request());
  }, [currentBot]);

  return {
    globalWords
  }
}