import * as React from 'react';

import {ToggleWithLabel} from './ToggleWithLabel';

type QuestionProps = { preSpeechSilenceTimeout?: number };
type Props = { question: QuestionProps, onChange: (question: QuestionProps) => void };

export const WaitForRedirectionToggle = ({question, onChange}: Props) => {
    const setWaitingForRedirection = () => onChange({...question, preSpeechSilenceTimeout: question.preSpeechSilenceTimeout ? undefined : 60_000});
    return (
        <ToggleWithLabel dataTest={'wait-for-redirection-toggle'} labelId={'survey-creator.waitForRedirectionLabel'} value={!!question.preSpeechSilenceTimeout} onToggle={setWaitingForRedirection} />
    );
};
