import { uniqBy } from 'lodash';
import { default as React } from 'react';

import { SelectComponent } from '../../../components/Select/Select';
import { ConditionOperator, WordsDictionaryItem } from '../../model';
import { MultiSelectComponent } from '../MultiSelect';
import { VariableType } from '../variables/Variables.utils';

import { ConditionSource } from './ConditionSource';

type DictionaryConditionValueProps = {
  conditionKey: string;
  onChange: (operand: string | string[]) => void;
  value: any;
  referencedSource: DictionaryConditionSource;
  error?: string;
};
export const DictionaryConditionValue = ({
  conditionKey,
  onChange,
  value,
  referencedSource,
  error,
}: DictionaryConditionValueProps) => {
  const mapMultiSelectValue = (el: string) => {
    const referencedEntry = referencedSource.possibleValues.find(item => item.id === el);
    return {id: el, name: referencedEntry ? referencedEntry.key : el};
  };
  const mapToOption = (el: WordsDictionaryItem) => ({id: el.id, name: el.key});
  if (conditionKey === ConditionOperator.In || conditionKey === ConditionOperator.Includes) {
    return <MultiSelectComponent
      value={((value as string[]) || []).map(mapMultiSelectValue)}
      options={referencedSource.possibleValues.map(mapToOption)}
      onChange={(newValues) => onChange(newValues.map(v => v.id))}
      placeholderMessage={'survey-creator.conditional.multiSelectPlaceholder'}
    />;
  }
  const referencedValue = referencedSource.possibleValues.find(item => item.id === value);
  const selectValue = !!value && referencedValue ? {id: value, name: referencedValue.key} : undefined;
  return <SelectComponent
    value={selectValue}
    options={uniqBy(referencedSource.possibleValues, 'id').map(mapToOption)}
    onChange={(newValue) => onChange(newValue.id)}
    invalid={!!error}
    error={error}
  />;
};

export interface DictionaryConditionSource extends ConditionSource {
  type: VariableType.DICTIONARY;
  possibleValues: { id: string; key: string }[];
}
