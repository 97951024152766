import cx from 'classnames';
import React from 'react';

import { IconProps } from '../../icons/iconTypes';
import { Box } from '../Box/Box';

import styles from './DropdownMenu.pcss';
import { NotificationBadge } from '../NotificationBadge/NotificationBadge';

export type DropdownMenuOptionProps = {
  classes?: Partial<{
    option: string;
    activeOption: string;
    content: string;
    name: string;
    description: string;
  }>;
  isActive?: boolean;
  id?: string;
  name?: string;
  description?: string;
  Icon?: React.FC<IconProps>;
  onClick?: React.MouseEventHandler;
  dataTest?: string;
  badgeContent?: string | number;
}

export type DropdownMenuProps = {
  isOpen?: boolean;
  options: DropdownMenuOptionProps[];
  activeOptionId?: string;
  classes?: DropdownMenuOptionProps['classes'] & Partial<{
    menu: string;
    options: string;
  }>;
  dataTest?: string;
}

export const DropdownMenuOption: React.FC<DropdownMenuOptionProps> = ({
  classes,
  name,
  description,
  Icon,
  onClick,
  dataTest,
  badgeContent,
  isActive,
}) => <button
  className={cx(
    styles.option,
    classes?.option,
    {
      [styles.activeOption]: isActive,
      [classes?.activeOption]: isActive
    }
  )}
  onClick={onClick}
  data-test={dataTest}
>
    {
      Icon &&
      <div className={styles.icon}>
        <Icon animationClass={styles.iconPath} />
      </div>
    }

    <div className={cx(styles.content, classes?.content)}>
      <div className={cx(styles.name, classes?.name)}>
        {name}
      </div>
      {
        description &&
        <div className={cx(styles.description, classes?.description)}>
          {description}
        </div>
      }
    </div>

    {badgeContent && <NotificationBadge className={styles.badge}>{badgeContent}</NotificationBadge>}
  </button>;

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  isOpen = false,
  options,
  activeOptionId,
  classes,
  dataTest
}) => isOpen &&
  <div className={cx(styles.dropdownMenu, classes?.menu)} data-test={dataTest}>
    <Box border className={cx(styles.options, classes?.options)}>
      {options.map(option => <DropdownMenuOption isActive={activeOptionId && activeOptionId === option?.id} key={option.name} classes={classes} {...option} />)}
    </Box>
  </div>;