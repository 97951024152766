import * as React from 'react';
import { useIntl } from 'react-intl';
import { TooltipComponent } from '../../components/Tooltip/Tooltip';
import { IconButton } from '../../components/IconButton/IconButton';
import { HelpIcon } from '../../icons';

import styles from './KnowledgeBaseIcon.pcss'

const openHelpInNewTab = () => {
  const newWindow = window.open(
    'https://help.talkie.ai',
    '_blank',
    'noopener,noreferrer'
  );
  if (newWindow) {
    newWindow.opener = null;
  }
};

export function KnowledgeBaseIcon() {
  const intl = useIntl();

  return (
    <TooltipComponent
      tooltipText={intl.messages['header.help'] as string}
      tooltipPlacement="bottom-end"
    >
      <IconButton 
        Icon={HelpIcon} 
        onClick={openHelpInNewTab} 
        className={styles.iconButton}
      />
    </TooltipComponent>
  );
}
