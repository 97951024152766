import React, { useState } from 'react';
import { TranscriptItemSMS } from '../models/Transcript';
import { SmsStep } from '../../icons/conversations/SMSStep';
import { SMSStepLineDetails } from './SMSStepLineDetails';
import styles from './StepLine.pcss';

type Props = {
  line: TranscriptItemSMS;
  moduleLink: string;
  isStepLinkExpired: boolean | undefined;
};

export function SMSStepLine({ line, moduleLink, isStepLinkExpired }: Props) {
  const [isModalOpened, setIsModalOpened] = useState(false);

  return (
    <div className={styles.wrapper}>
      <button
        data-test="sms-step-line"
        className={styles.button}
        onClick={() => setIsModalOpened(true)}
      >
        <SmsStep animationClass={styles.icon} />
        <span className={styles.text}>{line.stepName}</span>
      </button>
      {isModalOpened && (
        <SMSStepLineDetails
          {...{ line, moduleLink }}
          onClose={() => setIsModalOpened(false)}
          isStepLinkExpired={isStepLinkExpired}
        />
      )}
    </div>
  );
}
