import { SurveyDefinitionVariable } from '../../../surveyCreator/model';
import { HttpClient } from '../../../utils/HttpClient';

export class VariablesService {
  static async fetch(botId: string): Promise<SurveyDefinitionVariable[]> {
    const response = await HttpClient.get({path: `/bots/${botId}/variables`});
    return response.data;
  }

  static async update(botId: string, variables: SurveyDefinitionVariable[]): Promise<void> {
    await HttpClient.put({path: `/bots/${botId}/variables`, body: variables});
  }
}
