import React from 'react'

import { ElementWithLabel, ElementWithLabelProps } from '../ElementWithLabel/ElementWithLabel'
import { SelectComponent as Select, SelectProps } from '../Select/Select'

export type SelectWithLabelProps = SelectProps & ElementWithLabelProps

export const SelectWithLabel: React.FC<SelectWithLabelProps> = ({
  labelId,
  borderEnabled,
  noPadding,
  className,
  dataTest,
  ...rest
}) => <ElementWithLabel
  labelId={labelId}
  borderEnabled={borderEnabled}
  noPadding={noPadding}
  className={className}
  dataTest={dataTest}
  inner
>
    <Select {...rest} />
  </ElementWithLabel>