import _ from 'lodash';
import * as React from 'react';

import {BasicMessage, Message, MultipleConditionalMessages, MultipleMessages, QuestionError} from '../../model';

import {
    MultipleConditionalMessageComponent
} from './conditional/MultipleConditionalMessages';
import {populateValue} from './messages-utils';
import {MultipleBasicMessages} from './MultipleBasicMessages';
import {useAvailableConditionalMessage} from './useAvailableConditionalMessages';

type Props = {
    messages: MultipleMessages;
    maxRepeatQuestionCount?: number,
    dataTestPrefix: string;
    onChange: (message: MultipleMessages) => void,
    onMaxRepeatQuestionCountChange?: (value: number) => void,
    nested?: boolean,
    noRepeatedQuestionCount?: boolean,
    alternativeLabelId?: string;
    mainLabelId?: string;
    errors?: QuestionError[];
};

export const MultipleMessagesComponent = ((props: Props) => {
    const sourcesForConditionalMessage = useAvailableConditionalMessage();
    const isConditional = _.has(props.messages, 'values');

    const disableConditional = () => props.onChange([{text: Object.values(_.get(props.messages, 'values'))[0][0].text}]);
    const enableConditionalForVariable = (variableName: string) => {
        const question = sourcesForConditionalMessage.find(s => s.variable.id === variableName);
        const text = _.get(props.messages, '[0].text');
        const values = _.mapValues(populateValue(question.defaultValues, text), (value) => [value]);
        props.onChange({
            name: question.variable.id,
            values
        });
    }

    const onMessageChange = (message: Message) => {
        const variableName = _.get(message, 'name');
        const firstConditionSelection = !isConditional && _.get(message, 'values');
        if (variableName) {
            // FIXME: find proper type instead 'message as unknown'
            firstConditionSelection ? enableConditionalForVariable(variableName) : props.onChange(message as unknown as MultipleMessages);
        } else {
            disableConditional()
        }
    }

    const onMultiMessageChange = (message: MultipleConditionalMessages) => {
        const variableName = _.get(message, 'name');
        const firstConditionSelection = !isConditional && _.get(message, 'values');
        if (variableName) {
            // FIXME: find proper type instead 'message as unknown'
            firstConditionSelection ? enableConditionalForVariable(variableName) : props.onChange(message as unknown as MultipleMessages);
        } else {
            disableConditional()
        }
    }

    return (
      <div>
          {isConditional ? (
            <MultipleConditionalMessageComponent
              {...props}
              message={props.messages as MultipleConditionalMessages}
              availableConditionalSources={sourcesForConditionalMessage}
              onChange={onMultiMessageChange}
            />
          ) : (
            <MultipleBasicMessages {...props} messages={props.messages as BasicMessage[]}
                                   onConditionChange={onMessageChange}/>
          )}
      </div>
    )
});
