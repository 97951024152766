import React from 'react';

import {IconProps} from './iconTypes';

export function ToTopIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <defs>
          <filter id="prefix__a">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"/>
          </filter>
        </defs>
        <g fill="none" fillRule="evenodd" filter="url(#prefix__a)" transform="translate(-3 -3)">
          <g>
            <path fill="#000" d="M16.277 6.753c-.07.035-.148.055-.232.055h-.844c-.33 0-.6.269-.6.6 0 .331.27.6.6.6h.844c.32 0 .619-.087.875-.24.504-.3.843-.852.843-1.483v-.516c0-.331-.27-.6-.6-.6-.332 0-.6.269-.6.6v.516c0 .192-.103.36-.256.452l-.03.016zm-14.649 0c.07.035.149.055.233.055h.843c.331 0 .6.269.6.6 0 .331-.268.6-.6.6H1.86c-.319 0-.618-.087-.874-.24-.504-.3-.843-.852-.843-1.483v-.516c0-.331.27-.6.6-.6.331 0 .6.269.6.6v.516c0 .192.103.36.256.452l.03.016zm8.609.055c-.332 0-.6.269-.6.6 0 .331.268.6.6.6h2.615c.33 0 .6-.269.6-.6 0-.331-.27-.6-.6-.6h-2.615zm-2.503 0c.33 0 .6.269.6.6 0 .331-.27.6-.6.6H5.052c-.331 0-.6-.269-.6-.6 0-.331.269-.6.6-.6h2.682zm8.543-5.553c-.07-.035-.148-.055-.232-.055h-.844c-.33 0-.6-.269-.6-.6 0-.331.27-.6.6-.6h.844c.32 0 .619.088.875.24.504.3.843.852.843 1.483v.516c0 .332-.27.6-.6.6-.332 0-.6-.268-.6-.6v-.516c0-.192-.103-.36-.256-.452l-.03-.016zm-14.649 0c.07-.035.149-.055.233-.055h.843c.331 0 .6-.269.6-.6 0-.331-.268-.6-.6-.6H1.86c-.319 0-.618.088-.874.24-.504.3-.843.852-.843 1.483v.516c0 .332.27.6.6.6.331 0 .6-.268.6-.6v-.516c0-.192.103-.36.256-.452l.03-.016zm8.609-.055c-.332 0-.6-.269-.6-.6 0-.331.268-.6.6-.6h2.615c.33 0 .6.269.6.6 0 .331-.27.6-.6.6h-2.615zm-2.503 0c.33 0 .6-.269.6-.6 0-.331-.27-.6-.6-.6H5.052c-.331 0-.6.269-.6.6 0 .331.269.6.6.6h2.682zM1.717 9.467C.77 9.467 0 10.237 0 11.188v5.034c0 .951.77 1.721 1.717 1.721H16.24c.948 0 1.717-.77 1.717-1.721v-5.034c0-.951-.77-1.721-1.717-1.721H1.717zm0 1.2H16.24c.286 0 .517.234.517.521v5.034c0 .287-.231.521-.517.521H1.717c-.286 0-.517-.234-.517-.521v-5.034c0-.287.23-.521.517-.521z" transform="rotate(-180 10.5 10.5)"/>
          </g>
        </g>
      </svg>
    );
}
