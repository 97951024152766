export enum SurveyCreator {
  FETCH_ENTITIES = 'FETCH_ENTITIES',
  FETCH_ENTITIES_SUCCESS = 'FETCH_ENTITIES_SUCCESS',
  FETCH_ENTITIES_FAILURE = 'FETCH_ENTITIES_FAILURE',

  UPDATE_ENTITIES = 'UPDATE_ENTITIES',
  UPDATE_PENDING_ENTITY = 'UPDATE_PENDING_ENTITY',

  FETCH_ENTITY_DETAILS = 'FETCH_ENTITY_DETAILS',
  FETCH_ENTITY_DETAILS_SUCCESS = 'FETCH_ENTITY_DETAILS_SUCCESS',
  FETCH_ENTITY_DETAILS_FAILURE = 'FETCH_ENTITY_DETAILS_FAILURE',

  FETCH_ENTITIES_DETAILS = 'FETCH_ENTITIES_DETAILS',
  FETCH_ENTITIES_DETAILS_SUCCESS = 'FETCH_ENTITIES_DETAILS_SUCCESS',
  FETCH_ENTITIES_DETAILS_FAILURE = 'FETCH_ENTITIES_DETAILS_FAILURE',

  UPDATE_ENTITY_DETAILS = 'UPDATE_ENTITY_DETAILS',
  DELETE_MODIFIED_ENTITY_DETAILS = 'DELETE_MODIFIED_ENTITY_DETAILS',

  SAVE_ENTITY_DETAILS = 'SAVE_ENTITY_DETAILS',
  SAVE_ENTITY_DETAILS_SUCCESS = 'SAVE_ENTITY_DETAILS_SUCCESS',
  SAVE_ENTITY_DETAILS_FAILURE = 'SAVE_ENTITY_DETAILS_FAILURE',

  FETCH_GLOBAL_WORDS = 'FETCH_GLOBAL_WORDS',
  FETCH_GLOBAL_WORDS_SUCCESS = 'FETCH_GLOBAL_WORDS_SUCCESS',
  FETCH_GLOBAL_WORDS_FAILURE = 'FETCH_GLOBAL_WORDS_FAILURE',

  FETCH_SYNONYMS_FOR_WORD = 'FETCH_SYNONYMS_FOR_WORD',
  FETCH_SYNONYMS_FOR_WORD_SUCCESS = 'FETCH_SYNONYMS_FOR_WORD_SUCCESS',
  FETCH_SYNONYMS_FOR_WORD_FAILURE = 'FETCH_SYNONYMS_FOR_WORD_FAILURE',
}