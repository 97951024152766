import * as React from 'react';
import classNames from 'classnames';

import styles from './HeaderContainer.pcss';

type HeaderContainerProps = {
  children: React.ReactNode;
  className?: string;
};

export function HeaderContainer({ children, className }: HeaderContainerProps) {
  return (
    <div
      className={classNames(styles.header, className)}
      data-test="app-header"
    >
      {children}
    </div>
  );
}
