import * as React from 'react';
import _ from 'lodash';
import { useLocation, useRouteMatch } from 'react-router';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { OrganizationHeader } from './OrganizationHeader';
import { ContentWrapper } from '../layout/ContentWrapper';
import { VerticalSeparator } from '../surveyCreator/components/VerticalSeparator';
import { Box } from '../components/Box/Box';

import styles from './OrganizationSettingsLayout.pcss';

const organizationSettingsTabs = ['sms'];

type OrganizationRouterProps = {
  children: React.ReactNode;
};

export function OrganizationSettingsLayout({ children }: OrganizationRouterProps) {
  const intl = useIntl();
  const match = useRouteMatch();
  const location = useLocation();

  const organizationName = _.get(match.params, 'orgId');

  const tabs = organizationSettingsTabs.map((key) => ({
    key,
    label: intl.messages[`organization.tabs.${key}`] as string,
    url: `/${organizationName}/${key}`,
  }));

  return (
    <>
      <OrganizationHeader organizationName={organizationName} />
      <ContentWrapper>
        <div className={styles.container}>
          <nav>
            <ul className={styles.navigationList}>
              {tabs.map((tab) => (
                <li key={tab.key} className={styles.navigationItem}>
                  <Link
                    className={styles.navLink}
                    to={tab.url}
                    data-test={`${tab.key}-tab-link`}
                  >
                    <Box
                      selected={location.pathname === tab.url}
                      border
                      selectable
                    >
                      {tab.label}
                    </Box>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <VerticalSeparator noMargin />
          <div className={styles.content}>{children}</div>
        </div>
      </ContentWrapper>
    </>
  );
}
