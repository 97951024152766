import {Action} from 'redux';
import {combineEpics, Epic} from 'redux-observable';

import {RootState} from '../reducers/reducers';
import {fetchEntitiesEpic} from '../surveyCreator/store/entities/epics';
import {fetchEntitiesDetailsEpic, fetchEntityDetailsEpic, putEntityDetailsEpic} from '../surveyCreator/store/entityDetails/epics';
import { fetchGlobalWordsEpic, fetchSynonymsForWordEpic } from '../surveyCreator/store/globalSynonyms/epics';
import {
  fetchSurveyConfigEpic,
  fetchSurveyScheduleStatusEpic,
  patchSurveyConfigEpic
} from '../views/Settings/store/epics';

import {fetchBotsEpic, fetchNamesDictionaryEpic} from './botsEpic';
import {fetchCurrentUserEpic} from './currentUserEpic';
import {addNamesDictionaryEpic, deleteNamesDictionaryEpic} from './namesDictionaryEpic';

export const rootEpic: Epic<Action, Action, RootState> = combineEpics(
  fetchNamesDictionaryEpic,
  fetchCurrentUserEpic,
  fetchBotsEpic,
  fetchSurveyConfigEpic,
  patchSurveyConfigEpic,
  fetchSurveyScheduleStatusEpic,
  addNamesDictionaryEpic,
  deleteNamesDictionaryEpic,
  fetchEntitiesEpic,
  fetchEntityDetailsEpic,
  fetchEntitiesDetailsEpic,
  putEntityDetailsEpic,
  fetchGlobalWordsEpic,
  fetchSynonymsForWordEpic,
);
