import { ActionType, getType } from 'typesafe-actions';

import * as actions from '../actions/botsActions';
import { fetchBots } from '../actions/botsActions';
import { Bot } from '../surveyCreator/model';

export type BotsAction = ActionType<typeof actions>;

export type BotsState = {
    bots: Bot[];
    fetched: boolean;
};

const defaultState: BotsState = {bots: [], fetched: false};

export const bots = (state = defaultState, action: BotsAction) => {
    switch (action.type) {
        case getType(fetchBots.success):
            if (action.payload.length === 0) {
                return {bots: [], current: undefined, fetched: true};
            }
            return {bots: action.payload, fetched: true};
        default:
            return state;
    }
};
