import * as React from 'react';
import { InputHTMLAttributes, useState } from 'react';
import { useIntl } from 'react-intl';

import { Input } from '../Input';
import { ShowPasswordIcon } from '../../icons/ShowPasswordIcon';
import { HidePasswordIcon } from '../../icons/HidePasswordIcon';
import { TooltipComponent } from '../Tooltip/Tooltip';

import styles from './PasswordInput.pcss';

type Props = {
  value?: string | number;
  onChange: (value?: string) => void;
  error?: string;
  dataTest?: string;
};

type PasswordInput = Props & InputHTMLAttributes<HTMLInputElement>;

export const PasswordInput = React.forwardRef(
  ({ dataTest = 'password-input' , ...props }: PasswordInput, ref: any) => {
    const intl = useIntl();

    const [showPassword, setShowPassword] = useState(false);

    return (
      <div className={styles.wrapper}>
        <Input
          ref={ref}
          {...props}
          type={showPassword ? 'text' : 'password'}
          dataTest={dataTest}
          className={styles.inputContainer}
        />
        <div
          className={styles.icon}
          data-test={`${dataTest}-icon`}
          onClick={() => setShowPassword((prev) => !prev)}
        >
          {showPassword ? (
            <TooltipComponent
              tooltipText={`${intl.messages['passwordInput.passwordTooltip.hide']}`}
            >
              <HidePasswordIcon />
            </TooltipComponent>
          ) : (
            <TooltipComponent
              tooltipText={`${intl.messages['passwordInput.passwordTooltip.show']}`}
            >
              <ShowPasswordIcon />
            </TooltipComponent>
          )}
        </div>
      </div>
    );
  }
);
