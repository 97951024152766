import React from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { PaletteItemsHook } from './types';
import { useMemo } from 'react';
import { linkElementAltAction, mapStepTypeToIcon } from './utils';
import { useHistory } from 'react-router';
import { useAllSteps } from './useAllSteps';

export const useStepItems: PaletteItemsHook = () => {
  const intl = useIntl();
  const history = useHistory();

  const category = intl.formatMessage({ id: `survey-creator.command-palette.categories.steps` });
  const steps = useAllSteps();

  return useMemo(() => steps?.map(step => {
    const IconComponent = mapStepTypeToIcon(step.type);

    return ({
      id: step.id,
      name: step.name,
      category,
      onAction: () => { history.push(step.href); },
      onAltAction: () => linkElementAltAction(step.href),
      data: step,
      Icon: IconComponent && (({ className }) => <IconComponent animationClass={className} />),
    });
  }), [steps]);
}