import React from 'react';
import cx from 'classnames';

import { IconProps } from './iconTypes';
import styles from './icons.pcss';

export function StartpointIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
          <path d="M0 0H24V24H0z"/>
          <path className={cx(styles.iconFillColor, styles.iconStrokeColor, props.animationClass)} strokeWidth="0.5" d="M14.81 4c.875.042 1.56.71 1.595 1.502v3.316c0 .317-.272.574-.607.574-.301 0-.55-.208-.598-.481l-.008-.093V5.609c.011-.215-.144-.402-.337-.449l-.074-.01-9.127-.002c-.227.011-.408.175-.437.4l-.003.087-.001 12.756c-.01.215.145.402.337.449l.074.01 9.127.002c.227-.011.409-.175.438-.4l.003-.087v-3.183c0-.317.272-.574.606-.574.302 0 .552.208.599.481l.008.093v3.294c-.033.766-.643 1.403-1.467 1.51L14.78 20H5.594c-.874-.043-1.558-.71-1.593-1.502V5.662c-.04-.83.597-1.54 1.475-1.65L5.624 4h9.187zm4.13 4.878l.085.067 2.797 2.652c.026.023.049.05.069.077l-.069-.077c.03.028.056.058.078.09.015.02.028.043.04.065l.016.036c.008.018.014.036.02.055l.01.033c.004.02.008.042.01.064l.003.028.001.035-.001.035-.003.027.004-.062c0 .044-.005.087-.015.128l-.009.032c-.006.02-.013.038-.02.056l-.016.034c-.011.022-.024.042-.037.062l-.01.015-.01.012-.06.067-2.798 2.652c-.237.224-.62.224-.858 0-.21-.2-.233-.509-.07-.733l.07-.08 1.762-1.671H9.643c-.335 0-.606-.257-.606-.574 0-.286.22-.523.508-.567l.098-.008H19.93l-1.763-1.67c-.21-.2-.233-.51-.07-.734l.07-.08c.21-.199.538-.221.774-.066z"/>
        </g>
      </svg>
    );
}
