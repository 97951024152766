import { History, Location } from 'history';
import _ from 'lodash';
import qs from 'qs';

type Direction = 'ASC' | 'DESC';

export interface Order {
    readonly property: string;
    readonly direction: Direction;
}

interface UrlOrderingStateOptions {
    readonly allowedProperties: ReadonlyArray<string | RegExp>;
    readonly defaultSort: string;
}

export function createUrlOrderingState({ allowedProperties, defaultSort }: UrlOrderingStateOptions) {
    const stringify = (order: Order, currentLocation: Location<{}>): Location<{}> => {
        const { 'order.property': oldProp, 'order.dir': oldDir, ...currentSearch } = qs.parse(currentLocation.search, {
            ignoreQueryPrefix: true
        });
        return {
            ...currentLocation,
            search: qs.stringify(
                {
                    ...currentSearch,
                    ...(order.property !== defaultSort ? { 'order.property': order.property } : {}),
                    ...(order.direction !== 'DESC' ? { 'order.dir': order.direction } : {})
                },
                { addQueryPrefix: true }
            )
        };
    };
    return {
        parse: (locationSearch: string): Order => {
            const query = qs.parse(locationSearch, { ignoreQueryPrefix: true });
            const property = query['order.property'] as string;
            return {
                property: allowedProperties.some(match =>
                    _.isRegExp(match) ? property && property.match(match) : property === match
                )
                    ? property
                    : defaultSort,
                direction: query['order.dir'] === 'ASC' ? 'ASC' : 'DESC'
            };
        },
        stringify,
        toggleOrdering: (history: History, location: Location<{}>, currentOrdering: Order, property: string): void => {
            if (currentOrdering.property === property) {
                history.push(
                    stringify(
                        {
                            ...currentOrdering,
                            direction: currentOrdering.direction === 'ASC' ? 'DESC' : 'ASC'
                        },
                        location
                    )
                );
            } else {
                history.push(
                    stringify(
                        {
                            property,
                            direction: 'DESC'
                        },
                        location
                    )
                );
            }
        }
    };
}
