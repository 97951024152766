import * as React from 'react';

import { FilterInputElement } from '../FilterInputElement/FilterInputElement';
import { PhoneIcon } from '../../icons';

interface Props {
    readonly className?: string;
    readonly phoneNumber?: string;
    readonly withDebounce?: boolean;

    onChange(phoneNumber?: string): void;
}

export function PhoneNumberFilter(props: Props) {
    return (
      <FilterInputElement onChange={props.onChange} className={props.className} dataTestPrefix={'phone-number-filter'}
                          icon={<PhoneIcon />} placeholderId={'conversations.filters.phone.label'}
                          value={props.phoneNumber} withDebounce={props.withDebounce}
      />
    );
}
