import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { InformationIcon } from '../../../icons/InformationIcon';
import styles from './SilenceFallbackEmptyState.pcss';

export const SilenceFallbackEmptyState = () => {
  return <div className={styles.silenceFallbackEmptyState}>
    <InformationIcon />

    <span className={styles.message}>
      <FormattedMessage
        id={'survey-creator.silenceFallback.emptyState'}
        values={{ linebreak: <br /> }}
      />
    </span>
  </div>
}