import * as React from 'react';
import {useIntl} from 'react-intl';

import {BorderedElementWithLabel} from '../../../../components/ElementWithLabel/BorderedElementWithLabel';
import {SelectComponent} from '../../../../components/Select/Select';
import {ReactionType} from '../../../model';

type Props = {
    options: ReactionType[],
    selected: ReactionType,
    onChange: (reactionType: ReactionType) => void
};

export const ReactionTypeSelect = ({selected, options, onChange}: Props) => {
    const intl = useIntl();
    const toValue = (reactionType: ReactionType) => ({
        id: reactionType,
        name: intl.messages[`survey-creator.reactionTypeSelect.${reactionType}OptionLabel`] as string
    });
    return (
        <BorderedElementWithLabel labelId={'survey-creator.intentDetails.reactionType'}>
            <SelectComponent
                data-test="intent-reaction-type-select"
                options={options.map(toValue)}
                onChange={value => onChange(value.id)}
                value={toValue(selected)}
            />
        </BorderedElementWithLabel>
    );
};
