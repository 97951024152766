import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../components/Button/Button';
import styles from './ScenarioStepsDashboardEmptyState.pcss';

type Props = {
  openEditView: () => void;
};

export function ScenarioStepsDashboardEmptyState({ openEditView }: Props) {
  return (
    <div
      className={styles.container}
      data-test="scenario-steps-dashboard-empty-state"
    >
      <h2 className={styles.header}>
        <FormattedMessage id="statistics.scenarioStepsDashboard.emptyState.header" />
      </h2>
      <div className={styles.descriptionContainer}>
        <p className={styles.text}>
          <FormattedMessage id="statistics.scenarioStepsDashboard.emptyState.description" />
        </p>
        <p className={styles.text}>
          <FormattedMessage id="statistics.scenarioStepsDashboard.emptyState.callToAction" />
        </p>
      </div>
      <Button
        onClick={openEditView}
        dataTest="scenario-steps-dashboard-empty-state-edit-button"
      >
        <FormattedMessage id="statistics.scenarioStepsDashboard.emptyState.editView" />
      </Button>
    </div>
  );
}
