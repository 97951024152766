import { useQuery } from 'react-query';
import { HttpClient } from '../../utils/HttpClient';
import { queryClient } from '../../AppWrapper';
import { useState } from 'react';

const cacheKey = 'is-history-modal-open';

export function setIsHistoryModalOpen(isOpen: boolean) {
  queryClient.setQueryData(cacheKey, isOpen);
}

const useScenarioHistory = (botId: string) => {
  const [isUpdatingAnnotation, setIsUpdatingAnnotation] = useState(false);

  const { data: isHistoryModalOpen } = useQuery(cacheKey, () => false, {
    initialData: false,
    cacheTime: Infinity,
  });

  const { data, isLoading } = useQuery(['history', botId], async () => {
    const response = await HttpClient.get({
      path: `/bots/${botId}/creator/snapshots`,
    })
    return response.data;
  }, { enabled: isHistoryModalOpen });

  const rollbackById = async (snapshotId: string) => HttpClient
    .post({
      path: `/bots/${botId}/creator/snapshots/${snapshotId}/rollback`,
      body: {},
    });

  const annotateById = async (snapshotId: string, annotation: string) => {
    setIsUpdatingAnnotation(true);
    await HttpClient
      .patch({
        path: `/bots/${botId}/creator/snapshots/${snapshotId}`,
        body: { annotation },
      });
    queryClient.setQueryData(['history', botId], (d: any) => {
      return d.map((snapshot: any) => {
        if (snapshot.id === snapshotId) {
          return { ...snapshot, annotation };
        }
        return snapshot;
      });
    });
    setIsUpdatingAnnotation(false);
  }

  return {
    isHistoryModalOpen,
    closeHistoryModal: () => setIsHistoryModalOpen(false),
    history: data,
    isLoading,
    rollbackById,
    annotateById,
    isUpdatingAnnotation,
  };
};

export default useScenarioHistory;
