import _ from 'lodash';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import classNames from 'classnames';

import { Button } from '../../../components/Button/Button';
import { useCurrentBot } from '../../../utils/CurrentBot';
import {
  useGetContactStatistics,
  useGetScheduleStatus,
  useSchedule
} from '../../../scheduler/scheduler-api';
import {useSurveyConfig} from '../../../views/Settings/Settings.hooks';
import { TooltipComponent } from '../../../components/Tooltip/Tooltip';
import {useDialerContext} from '../../context/DialerContext';

import styles from './SchedulerStatus.pcss';

export type SchedulerState = 'running' | 'paused';

export type Status = {
  state: SchedulerState
};

export const SchedulerStatus = () => {
  const intl = useIntl();

  const currentBot = useCurrentBot();
  const { savedConfig } = useSurveyConfig();
  const {hasSchedule} = useDialerContext()

  const {mutate} = useSchedule(currentBot.id);

  const {data: status} = useGetScheduleStatus(currentBot.id);

  const {data: contactStatisticsData } = useGetContactStatistics(currentBot.id, { refetchInterval: 1000 * 60 });
  const contactStatistics = contactStatisticsData?.stats;

  const hasPhoneNumbers = Boolean(savedConfig.data?.phoneConfigs?.length)

  const handleStatusAction = () => {
    const action = status.state === 'running' ? 'pause' : 'resume';
    mutate({action})
  };

  const disabled = !hasSchedule || !hasPhoneNumbers;
  const currentState = disabled ? 'no-schedule' : _.get(status, 'state', 'no-schedule');

  const contactsInProgress = contactStatistics?.in_progress;
  const contactsInQueue = contactStatistics?.created;
  const contactsCalledCompleted = contactStatistics?.survey_complete;
  const contactsCalledIncomplete = contactStatistics?.survey_incomplete;
  const contactsCalled = contactsCalledCompleted + contactsCalledIncomplete;

  const statusNotReady = !hasSchedule || !hasPhoneNumbers;

  const getBotStatus = () => {
    if (!hasSchedule && !hasPhoneNumbers) { return intl.messages['scheduler.botStatus.noScheduleAndPhoneNumber'] }
    if (!hasSchedule) { return intl.messages['scheduler.botStatus.noSchedule'] }
    if (!hasPhoneNumbers) { return intl.messages['scheduler.botStatus.noPhoneNumber'] }

    if (status?.state === 'paused') { return intl.messages['scheduler.botStatus.paused'] }
    if (contactsInProgress === 0) { return intl.messages['scheduler.botStatus.readyToCall'] }
    if (contactsInProgress > 0) { return intl.messages['scheduler.botStatus.calling'] }

    return '-';
  }

  const getTooltipText = () => {
    if (!hasSchedule && !hasPhoneNumbers) { return `${intl.messages['scheduler.button.tooltip.noScheduleAndPhoneNumber']}` }
    if (!hasPhoneNumbers) { return `${intl.messages['scheduler.button.tooltip.noPhoneNumber']}` }
    if (!hasSchedule) { return `${intl.messages['scheduler.button.tooltip.noSchedule']}` }

    return ''
  }

  const renderNumber = (num: number) => Number.isInteger(num) ? num : '-';

  return (
    <div data-test={'scheduler-status'} className={styles.container}>
      <div className={styles.statsWrapper}>
        <div>
          <div className={styles.label}>{intl.messages['scheduler.botStatus.label']}</div>
          <div className={classNames({
            [styles.ready]: !statusNotReady && status?.state === 'running',
            [styles.notReady]: statusNotReady,
          })} data-test="scheduler-bot-status">{getBotStatus()}</div>
        </div>
        <TooltipComponent tooltipText={`${intl.messages['scheduler.status.tooltip.inQueue']}`} tooltipPlacement="bottom-start">
          <div className={styles.label}>{intl.messages['scheduler.contactStatuses.inQueue']}</div>
          <div data-test="scheduler-contact-status-in-queue">{renderNumber(contactsInQueue)}</div>
        </TooltipComponent>
        <TooltipComponent tooltipText={`${intl.messages['scheduler.status.tooltip.inProgress']}`} tooltipPlacement="bottom-start">
          <div className={styles.label}>{intl.messages['scheduler.contactStatuses.inProgress']}</div>
          <div data-test="scheduler-contact-status-in-progress">{renderNumber(contactsInProgress)}</div>
        </TooltipComponent>
        <TooltipComponent tooltipText={`${intl.messages['scheduler.status.tooltip.called']}`} tooltipPlacement="bottom-start">
          <div className={styles.label}>{intl.messages['scheduler.contactStatuses.called']}</div>
          <div>
            <span className={styles.ready} data-test="scheduler-contact-status-called-completed">{renderNumber(contactsCalledCompleted)}</span>
            <span className={styles.gray}>+</span>
            <span className={styles.warning}  data-test="scheduler-contact-status-called-incomplete">{renderNumber(contactsCalledIncomplete)}</span>
            <span className={styles.gray}>=</span>
            <span data-test="scheduler-contact-status-called">{renderNumber(contactsCalled)}</span>
          </div>
        </TooltipComponent>
      </div>

      <TooltipComponent
          tooltipText={getTooltipText()}
          trigger={disabled ? 'hover' : 'none'}
          tooltipPlacement="bottom-end"
      >
        <Button buttonType={currentState === 'running' ? 'warning' : 'ready'}
          dataTest={`scheduler-status-button`}
          disabled={disabled}
          onClick={handleStatusAction}
          className={styles.button}
        >
          {currentState === 'running' ? <FormattedMessage id={`scheduler.button.pause`} /> : <FormattedMessage id={`scheduler.button.resume`} />}
        </Button>
      </TooltipComponent>
    </div>
  );
};
