import React from 'react';
import { IconProps } from '../iconTypes';

export function HttpStep(props: IconProps) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.animationClass}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8825 1H1.12152C0.502494 1.00182 0.0019062 1.47943 0 2.06916V3.46377V9.98551C0 10.5458 0.476052 11 1.06329 11H10.8785C11.4975 10.9982 11.9981 10.5206 12 9.93085L12 3.46421V3.46377V3.46333V2.06618C11.9981 1.4777 11.4993 1.00182 10.8825 1ZM0.607666 9.98551V3.75362H11.3925L11.3925 9.92995C11.3916 10.2 11.1616 10.4195 10.8776 10.4203L1.06336 10.4203C0.811688 10.4203 0.607666 10.2256 0.607666 9.98551ZM0.607666 3.17391H11.3925V2.06708C11.3916 1.79826 11.1634 1.58054 10.8826 1.57971L1.12253 1.57971C0.838539 1.58055 0.60854 1.79999 0.607666 2.07005V3.17391ZM8.45115 5.39918C8.33275 5.32092 8.16905 5.3321 8.06359 5.43272L8.02844 5.47286C7.94641 5.58583 7.95813 5.74202 8.06359 5.84264L9.36752 7.08695L8.06359 8.33127L8.02844 8.37142C7.94641 8.48438 7.95813 8.64057 8.06359 8.74119C8.18223 8.85438 8.37458 8.85438 8.49322 8.74119L10.0122 7.29191L10.0474 7.25177C10.1294 7.1388 10.1177 6.98261 10.0122 6.882L8.49322 5.43272L8.45115 5.39918ZM3.50665 5.43272C3.62529 5.31953 3.81764 5.31953 3.93628 5.43272C4.04174 5.53334 4.05346 5.68953 3.97144 5.80249L3.93628 5.84264L2.63235 7.08695L3.93628 8.33127C4.04174 8.43189 4.05346 8.58808 3.97144 8.70105L3.93628 8.74119C3.83083 8.84181 3.66712 8.85299 3.54872 8.77473L3.50665 8.74119L1.98766 7.29191C1.8822 7.1913 1.87049 7.03511 1.95251 6.92214L1.98766 6.882L3.50665 5.43272ZM6.89524 5.37843C6.74517 5.30684 6.56269 5.36487 6.48765 5.50805L4.96866 8.40661L4.95018 8.45035C4.90669 8.58358 4.96946 8.73105 5.10453 8.79549C5.25459 8.86708 5.43708 8.80904 5.51211 8.66586L7.0311 5.76731L7.04958 5.72356C7.09308 5.59033 7.0303 5.44286 6.89524 5.37843Z"
        fill="#919AAF"
      />
    </svg>
  );
}
