import React from 'react';
import { IconProps } from './iconTypes';

export function FunctionStepIcon(props: IconProps) {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4260_274475)">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.50039 3.15234C7.83176 3.15234 8.10039 3.42097 8.10039 3.75234V9.75234C8.10039 10.0837 7.83176 10.3523 7.50039 10.3523C7.16902 10.3523 6.90039 10.0837 6.90039 9.75234V3.75234C6.90039 3.42097 7.16902 3.15234 7.50039 3.15234Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3.90039 6.75234C3.90039 6.42097 4.16902 6.15234 4.50039 6.15234H10.5004C10.8318 6.15234 11.1004 6.42097 11.1004 6.75234C11.1004 7.08371 10.8318 7.35234 10.5004 7.35234H4.50039C4.16902 7.35234 3.90039 7.08371 3.90039 6.75234Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.1747 10.0781C19.409 10.3124 19.409 10.6923 19.1747 10.9266L14.6747 15.4266C14.4403 15.6609 14.0604 15.6609 13.8261 15.4266C13.5918 15.1923 13.5918 14.8124 13.8261 14.5781L18.3261 10.0781C18.5604 9.84377 18.9403 9.84377 19.1747 10.0781Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.8261 10.0781C14.0604 9.84377 14.4403 9.84377 14.6747 10.0781L19.1747 14.5781C19.409 14.8124 19.409 15.1923 19.1747 15.4266C18.9403 15.6609 18.5604 15.6609 18.3261 15.4266L13.8261 10.9266C13.5918 10.6923 13.5918 10.3124 13.8261 10.0781Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.65039 17.2523C4.65039 16.921 4.91902 16.6523 5.25039 16.6523H10.5004C10.8318 16.6523 11.1004 16.921 11.1004 17.2523C11.1004 17.5837 10.8318 17.8523 10.5004 17.8523H5.25039C4.91902 17.8523 4.65039 17.5837 4.65039 17.2523Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.27539 14.2523C7.27539 13.921 7.54402 13.6523 7.87539 13.6523C8.13398 13.6523 8.38197 13.7551 8.56482 13.9379C8.74767 14.1208 8.85039 14.3688 8.85039 14.6273C8.85039 14.9587 8.58176 15.2273 8.25039 15.2273C7.98662 15.2273 7.7626 15.0571 7.68218 14.8206C7.4456 14.7401 7.27539 14.5161 7.27539 14.2523Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.0686 14.8206C8.30518 14.7401 8.47539 14.5161 8.47539 14.2523C8.47539 13.921 8.20676 13.6523 7.87539 13.6523C7.61681 13.6523 7.36881 13.7551 7.18596 13.9379C7.00311 14.1208 6.90039 14.3688 6.90039 14.6273C6.90039 14.9587 7.16902 15.2273 7.50039 15.2273C7.76416 15.2273 7.98818 15.0571 8.0686 14.8206Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.0686 14.4302C7.98837 14.1942 7.76521 14.0241 7.502 14.0234C7.17063 14.0226 6.90128 14.2905 6.90039 14.6218C6.90005 14.7502 6.92508 14.8773 6.97403 14.996C7.02299 15.1146 7.09492 15.2224 7.18567 15.3132C7.27642 15.4039 7.38422 15.4758 7.50286 15.5248C7.6215 15.5738 7.74865 15.5988 7.877 15.5984C8.20837 15.5975 8.47628 15.3282 8.47539 14.9968C8.47469 14.7336 8.30461 14.5105 8.0686 14.4302Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.25039 14.0273C8.58176 14.0273 8.85039 14.296 8.85039 14.6273C8.85039 14.7555 8.82511 14.8823 8.776 15.0007C8.72689 15.119 8.65491 15.2265 8.56419 15.317C8.47348 15.4075 8.3658 15.4792 8.24732 15.5279C8.12884 15.5767 8.00191 15.6017 7.87379 15.6013C7.54242 15.6005 7.27451 15.3311 7.27539 14.9997C7.2761 14.7364 7.44636 14.5131 7.68256 14.433C7.76331 14.197 7.98704 14.0273 8.25039 14.0273Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.27539 19.4984C7.27539 19.1671 7.54402 18.8984 7.87539 18.8984C8.13398 18.8984 8.38197 19.0012 8.56482 19.184C8.74767 19.3669 8.85039 19.6149 8.85039 19.8734C8.85039 20.2048 8.58176 20.4734 8.25039 20.4734C7.98662 20.4734 7.7626 20.3032 7.68218 20.0666C7.4456 19.9862 7.27539 19.7622 7.27539 19.4984Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.0686 20.0706C8.30518 19.9901 8.47539 19.7661 8.47539 19.5023C8.47539 19.171 8.20676 18.9023 7.87539 18.9023C7.61681 18.9023 7.36881 19.0051 7.18596 19.1879C7.00311 19.3708 6.90039 19.6188 6.90039 19.8773C6.90039 20.2087 7.16902 20.4773 7.50039 20.4773C7.76416 20.4773 7.98818 20.3071 8.0686 20.0706Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.0686 19.6841C7.98837 19.4481 7.76521 19.2781 7.502 19.2773C7.17063 19.2765 6.90128 19.5444 6.90039 19.8757C6.90005 20.0041 6.92508 20.1312 6.97403 20.2499C7.02299 20.3685 7.09492 20.4763 7.18567 20.5671C7.27642 20.6578 7.38422 20.7297 7.50286 20.7787C7.6215 20.8277 7.74865 20.8527 7.877 20.8523C8.20837 20.8515 8.47628 20.5821 8.47539 20.2507C8.47469 19.9875 8.30461 19.7644 8.0686 19.6841Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.24878 19.2773C8.58015 19.2765 8.8495 19.5444 8.85039 19.8757C8.85073 20.0041 8.82571 20.1312 8.77675 20.2499C8.72779 20.3685 8.65586 20.4763 8.56511 20.5671C8.47436 20.6578 8.36657 20.7297 8.24792 20.7787C8.12928 20.8277 8.00213 20.8527 7.87378 20.8523C7.54241 20.8515 7.27451 20.5821 7.27539 20.2507C7.2761 19.9875 7.44618 19.7644 7.68218 19.6841C7.76241 19.4481 7.98558 19.2781 8.24878 19.2773Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M18.6661 1.60854C18.4979 1.44418 18.2719 1.35221 18.0367 1.35234L3.00039 1.35234C2.7617 1.35234 2.53278 1.44717 2.36399 1.61595C2.19521 1.78473 2.10039 2.01365 2.10039 2.25234V21.7523C2.10039 21.991 2.19521 22.22 2.36399 22.3887C2.53278 22.5575 2.76169 22.6523 3.00039 22.6523H21.0004C21.2391 22.6523 21.468 22.5575 21.6368 22.3887C21.8056 22.22 21.9004 21.991 21.9004 21.7523V5.1354C21.9004 5.13538 21.9004 5.13542 21.9004 5.1354C21.9004 5.01531 21.8763 4.89638 21.8296 4.78572C21.783 4.67507 21.7146 4.57488 21.6286 4.49106C21.6286 4.49104 21.6287 4.49108 21.6286 4.49106L18.667 1.60938L18.6661 1.60854ZM19.504 0.749492C19.1115 0.366455 18.5848 0.152129 18.0364 0.152344C18.0365 0.152344 18.0363 0.152344 18.0364 0.152344H3.00039C2.44344 0.152344 1.90929 0.373593 1.51547 0.76742C1.12164 1.16125 0.900391 1.69539 0.900391 2.25234V21.7523C0.900391 22.3093 1.12164 22.8434 1.51547 23.2373C1.90929 23.6311 2.44344 23.8523 3.00039 23.8523H21.0004C21.5573 23.8523 22.0915 23.6311 22.4853 23.2373C22.8791 22.8434 23.1004 22.3093 23.1004 21.7523V5.13534C23.1004 4.85508 23.0442 4.5776 22.9353 4.31936C22.8264 4.06113 22.6669 3.82731 22.4662 3.63169L19.504 0.749492C19.5042 0.749709 19.5038 0.749275 19.504 0.749492Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_4260_274475">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>

}