import * as React from 'react';

import styles from './ContentWrapper.pcss';

type ContentWrapperProps = {
  children: React.ReactNode;
};

export function ContentWrapper({ children }: ContentWrapperProps) {
  return (
    <main className={styles.background}>
      <div className={styles.content}>{children}</div>
    </main>
  );
}
