export type SupervoipSMSConfiguration = {
    login: string;
    password: string;
    apiKey: string;
};
export type VonageSMSConfiguration = {
    apiKey: string;
    apiSecret: string;
};
export type TwilioSMSConfiguration = {
    accountSid: string;
    authToken: string;
};
export type ParlanceSMSConfiguration = {
    applicationGuid: string;
    apiKey: string;
    baseUrl?: string;
};
export type TalkieVonageSMSConfiguration = {};

export type TalkieSupervoipSMSConfiguration = {};

export enum SMSProvider {
    TalkieSupervoip = 'talkie-supervoip',
    TalkieVonage = 'talkie-vonage',
    Vonage = 'vonage',
    Supervoip = 'supervoip',
    Twilio = 'twilio',
    ParlanceHelpdesk = 'parlance-helpdesk',
    ParlancePatientAccess = 'parlance-patient-access',
}

export type SMSProviderConfiguration =
    | SupervoipSMSConfiguration
    | VonageSMSConfiguration
    | TwilioSMSConfiguration
    | ParlanceSMSConfiguration
    | TalkieVonageSMSConfiguration
    | TalkieSupervoipSMSConfiguration;

export type SMSProviderConfigurationOverride = {
    botId: string;
    provider: SMSProvider;
    phoneNumber: string;
    providerConfiguration: SMSProviderConfiguration;
}

export type OrganizationSMSSettings = {
    provider: SMSProvider;
    phoneNumber: string;
    providerConfiguration: SMSProviderConfiguration;
    overrides: SMSProviderConfigurationOverride[];
};

export type OrganizationSettings = {
    sms?: OrganizationSMSSettings,
}

export const providerOptions = [
    {
        id: SMSProvider.TalkieSupervoip,
        name: 'Talkie SuperVoIP',
    },
    {
        id: SMSProvider.TalkieVonage,
        name: 'Talkie Vonage',
    },
    {
        id: SMSProvider.Supervoip,
        name: 'SuperVoIP',
    },
    {
        id: SMSProvider.Vonage,
        name: 'Vonage',
    },
    {
        id: SMSProvider.Twilio,
        name: 'Twilio',
    },
    {
        id: SMSProvider.ParlanceHelpdesk,
        name: 'Parlance Helpdesk',
    },
    {
        id: SMSProvider.ParlancePatientAccess,
        name: 'Parlance Patient Access',
    },
] 