import * as React from 'react';

import { IndentedStepContent } from '../../components/layouts/IndentedStepContent/IndentedStepContent';
import { DateQuestionValidations } from '../model';

import styles from './DateQuestionValidationsComponent.pcss';
import { StepElementProps } from './StepElementProps';
import { ToggleWithLabel } from './ToggleWithLabel';

type Props = { validations?: DateQuestionValidations };

export const DateQuestionValidationsComponent = ({ question, onChange }: StepElementProps<Props>) => {
    const validations = question.validations || {};
    const toggleForbidDatesInTheFuture = () => onChange({
        ...question,
        validations: { ...validations, forbidDatesInTheFuture: !validations.forbidDatesInTheFuture }
    });
    const toggleForbidDatesInThePast = () => onChange({
        ...question,
        validations: { ...validations, forbidDatesInThePast: !validations.forbidDatesInThePast }
    });

    return (
        <div data-test="date-question-validations">
            <IndentedStepContent>
                <ToggleWithLabel dataTest="forbid-dates-in-the-future" value={validations.forbidDatesInTheFuture} onToggle={toggleForbidDatesInTheFuture}
                    labelId={'survey-creator.date.forbidDatesInTheFuture'} />
                <div className={styles.forbidDatesInThePast}>
                    <ToggleWithLabel dataTest="forbid-dates-in-the-past" value={validations.forbidDatesInThePast} onToggle={toggleForbidDatesInThePast}
                        labelId={'survey-creator.date.forbidDatesInThePast'} />
                </div>
            </IndentedStepContent>
        </div>
    );
};
