import React from 'react';
import { v4 } from 'uuid';
import { DraggableItems } from '../../../../components/DraggableItems/DraggableItems';

import { Loader } from '../../../../components/Loader';
import { Scrollbar } from '../../../../components/Scrollbar/Scrollbar';
import {AssociatedAnswer, AssociatedEntity, SurveyIntent} from '../../../model';
import { AddItemBar } from '../../AddItemBar/AddItemBar';
import { AddItemModal } from '../../AddItemModal/AddItemModal';
import {
  AssociatedAnswerComponent,
} from '../../AssociatedAnswer/AssociatedAnswerComponent';
import styles from './IntentEntities.pcss';
import {useEntitiesWithSystemAndNerEntities} from '../../EntityDetails/hooks/useEntitiesWithSystemAndNerEntities';

type IntentEntitiesProps = {
  currentIntent: SurveyIntent;
  onChange: (intent: SurveyIntent) => void;
}

export const IntentEntities: React.FC<IntentEntitiesProps> = ({
  currentIntent,
  onChange
}) => {
  const { isLoading, isReady, entities, systemAndNerEntitiesKeys } = useEntitiesWithSystemAndNerEntities();

  const updateAssociatedEntities = (newAssociatedEntities: AssociatedEntity[]) => onChange({ ...currentIntent, entities: newAssociatedEntities });

  const onDelete = (id: string) => updateAssociatedEntities(currentIntent.entities.filter(entity => entity.id !== id));
  const onChangeEntity = (newAssociatedEntity: AssociatedEntity) => updateAssociatedEntities(currentIntent.entities.map(associatedEntity => associatedEntity.id === newAssociatedEntity.id
    ? newAssociatedEntity
    : associatedEntity
  ));

  return <>
    {(isLoading || !isReady) && <Loader />}
    {
      isReady &&
      <AddItemModal
        addLabelId="survey-creator.intentDetails.entities.addEntity"
        dataTest="add-entity-modal"
        items={entities}
        onSelect={(item) => {
          const selectedEntity = entities.find(entity => entity.key === item);
          updateAssociatedEntities([
            ...(currentIntent.entities ?? []),
            { id: v4(), entity: selectedEntity.entityKey }
          ])
        }}
        renderTrigger={({ open }) => <AddItemBar
          dataTest="add-entity-button"
          addButtonLabelId="survey-creator.intentDetails.entities.addEntity"
          descriptionId="survey-creator.intentDetails.entities.addEntityDescription"
          onClick={open}
        />}
        forbidAddingItems
      />
    }
    <div className={styles.intentEntities}>
      <Scrollbar>
        <DraggableItems<AssociatedAnswer>
          onDragEnd={updateAssociatedEntities}
          items={currentIntent.entities}
          renderItem={
            (associatedEntity) =>
              <AssociatedAnswerComponent
                key={associatedEntity.id}
                associatedAnswer={associatedEntity}
                onDelete={onDelete}
                onChange={onChangeEntity}
                hasReaction={false}
                systemAndNerEntitiesKeys={systemAndNerEntitiesKeys}
              />
          }

        />
      </Scrollbar>
    </div>
  </>;
}