import _ from 'lodash';
import { useIntl } from 'react-intl';
import { PaletteItemsHook } from './types';
import { useContext, useMemo } from 'react';
import { SurveyContext } from '../../surveyCreator/SurveyCreator';
import { useHistory } from 'react-router';
import { PaletteStepSubtitle } from '../components/PaletteStepSubtitle';
import { fromDBFormatToName, linkElementAltAction } from './utils';
import { HttpStep, QuestionType } from '../../surveyCreator/model';
import React from 'react';
import { HttpStepIcon } from '../../icons/HttpStepIcon';
import { useAllSteps } from './useAllSteps';

export const useHttpStepURLItems: PaletteItemsHook = () => {
  const intl = useIntl();
  const { variables } = useContext(SurveyContext);
  const history = useHistory();
  const category = intl.formatMessage({ id: `survey-creator.command-palette.categories.urls` });

  const steps = useAllSteps()
  const httpStepUrls = useMemo(() => {
    const variableNames = fromDBFormatToName(variables);
    const variableKeys = Object.keys(variableNames);
    return steps.filter(step => step.type === QuestionType.HTTP)
      .map((step: (HttpStep & { href: string; moduleName: string; })) => {
        const url = step.url;
        const urlWithVariables = variableKeys.reduce((acc, curr) => acc.replace(curr, variableNames[curr]), url);
        return {
          ...step,
          url: urlWithVariables,
        }
      });
  }, [steps, variables]);

  const hookResult = useMemo(() => httpStepUrls?.map(httpStep => ({
    id: `${httpStep.id}-url`,
    name: httpStep.url,
    subtitle: <PaletteStepSubtitle moduleName={httpStep.moduleName} stepName={httpStep.name} />,
    category,
    onAction: () => history.push(httpStep.href),
    onAltAction: () => linkElementAltAction(httpStep.href),
    data: httpStep,
    Icon: ({ className }) => <HttpStepIcon animationClass={className} />
  })), [httpStepUrls]);

  return hookResult;
};

