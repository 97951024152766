import React, { ReactNode } from 'react';

import styles from './MainPage.pcss';

interface Props {
    readonly children: ReactNode | ReactNode[];
}

export function MainPage(props: Props) {
    return <main className={styles.main}>{props.children}</main>;
}
