import React from 'react';
import { IconProps } from '../iconTypes';

export function RedirectStep(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-381 -414) translate(365 402) translate(16 12)">
              <path d="M0 0H24V24H0z" />
              <path
                className={props.animationClass}
                d="M12.077 23.081l.031.022c2.018 1.284 4.657.995 6.347-.697l.629-.629c.394-.393.615-.928.615-1.485s-.221-1.092-.615-1.485l-2.653-2.651c-.394-.394-.928-.616-1.485-.616-.558 0-1.092.222-1.486.616-.351.351-.92.351-1.272 0l-4.242-4.243c-.351-.352-.351-.921 0-1.273.394-.394.615-.928.615-1.485S8.34 8.063 7.946 7.67L5.294 5.019c-.82-.82-2.15-.82-2.97 0l-.63.629C.004 7.338-.286 9.978 1 11.994l-.009-.013.024.037c2.949 4.363 6.707 8.12 11.07 11.07l-.007-.007zm.684-.985c1.541.976 3.555.753 4.845-.538l.63-.63c.168-.168.263-.397.263-.636s-.095-.468-.263-.637l-2.654-2.65c-.169-.17-.398-.265-.636-.265-.24 0-.468.095-.637.264-.82.82-2.15.82-2.97 0l-4.242-4.243c-.82-.82-.82-2.15 0-2.97.17-.168.264-.397.264-.636s-.095-.468-.263-.637l-2.653-2.65c-.351-.352-.92-.352-1.272 0l-.63.628C1.25 7.79 1.028 9.807 2.01 11.35l.004.006c2.862 4.232 6.508 7.877 10.742 10.737l.004.003zM11.27.2L11.3.2h3.75c.332 0 .6.269.6.6 0 .331-.268.6-.6.6h-2.152l4.798 4.198c.13.114.205.279.205.452v6c0 .331-.27.6-.6.6-.331 0-.6-.269-.6-.6V6.322l-4.8-4.2V4.55c0 .331-.27.6-.6.6-.331 0-.6-.269-.6-.6V.77l.003-.031.004-.03.005-.03.007-.029.008-.028.009-.028.01-.028.005-.01.008-.016.005-.01.008-.016.006-.01.009-.015.006-.01.01-.014.007-.012.009-.012.01-.012.008-.01.011-.014.008-.009.013-.014.006-.006.016-.015.022-.02.024-.02.024-.017.025-.016.028-.016.024-.012c.067-.033.142-.053.22-.058L11.27.2zM21.852 1.4h-2.301c-.331 0-.6-.269-.6-.6 0-.331.269-.6.6-.6h3.75c.059 0 .116.008.17.024l.008.003.028.01.027.01.02.009.007.003.019.01.007.003.019.011.025.016.024.017.024.019.024.02.022.02.021.023.02.023.015.02.004.004.013.02.004.005.012.019.004.006.01.019.004.007.01.019.012.026.01.028.01.028.002.008c.016.054.025.111.025.17v3.75c0 .331-.27.6-.6.6-.331 0-.6-.269-.6-.6V2.249l-2.726 2.725c-.234.234-.614.234-.849 0-.234-.234-.234-.614 0-.848L21.852 1.4z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
