import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from '../../components/Box/Box';

import styles from './CollapsedStep.pcss';

export const CollapsedStep = ({ messageId }: { messageId: string }) =>
    <Box border dataTest="collapsed-step" className={styles.collapsedStep}>
        <FormattedMessage id={messageId} />
    </Box>
