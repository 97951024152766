import React from 'react';

import {IconProps} from './iconTypes';

export function DownloadIcon({animationClass, title}: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      {title && <title>{title}</title>}
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z"/>
        <path className={animationClass} fill={'#919AAF'}
              d="M12 3C7.03283 3 3 7.03283 3 12C3 16.9672 7.03283 20.999 12 20.999C16.9672 20.999 21 16.9672 21 12C21 7.03283 16.9672 3 12 3ZM12 4.20073C16.3042 4.20073 19.7983 7.69578 19.7983 12C19.7983 16.3032 16.3042 19.7983 12 19.7983C7.69578 19.7983 4.20174 16.3032 4.20174 12C4.20174 7.69578 7.69578 4.20073 12 4.20073ZM11.3991 15.0284L9.62457 13.2548C9.39023 13.0205 9.00968 13.0205 8.77534 13.2548C8.541 13.4892 8.541 13.8697 8.77534 14.104L11.5754 16.9041C11.6475 16.9762 11.7336 17.0263 11.8247 17.0533L11.8268 17.0543C11.8768 17.0693 11.9299 17.0783 11.985 17.0793H12.014C12.0691 17.0783 12.1232 17.0693 12.1733 17.0543L12.1753 17.0533C12.2664 17.0263 12.3525 16.9762 12.4246 16.9041L15.2247 14.104C15.459 13.8697 15.459 13.4892 15.2247 13.2548C14.9903 13.0205 14.6088 13.0205 14.3744 13.2548L12.6009 15.0284V8.08034C12.6009 7.74886 12.3315 7.47947 12 7.47947C11.6685 7.47947 11.3991 7.74886 11.3991 8.08034V15.0284Z"/>
      </g>
    </svg>
  );
}
