import _ from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { components as selectComponents } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import MultiValue from 'react-select/src/components/MultiValue';
import cx from 'classnames';

import { CrossIcon } from '../../icons';

import styles from './ChipsComponent.pcss';
import { useChipsClipboard } from './useChipsClipboard';

type OverrideComponents = ({
    MultiValue: typeof selectComponents.MultiValue,
})

const components = (overrideComponents: OverrideComponents) => ({
    DropdownIndicator: null,
    ClearIndicator: null,
    MultiValueRemove: (props: any) => (
        <selectComponents.MultiValueRemove {...props} data-test={'multi-select-remove'}>
            <div className={styles.removeIcon}>
                <CrossIcon animationClass={styles.removeIcon} />
            </div>
        </selectComponents.MultiValueRemove>
    ),
    MultiValue: (props: any) => (
        <div
            data-test-chip
            onMouseDown={(e) => e.stopPropagation()}>
            <selectComponents.MultiValue {...props}>
                {props.children}
            </selectComponents.MultiValue>
        </div>
    ),
    ..._.omitBy(overrideComponents, _.isNil),
});

export type Option<T> = {
    label: string;
    value: T;
}

type Props<T> = {
    items: ReadonlyArray<T>;
    MultiValueChipComponent?: React.FC<
        React.ComponentProps<typeof MultiValue>
    >;
    i18nKey: string;
    onChange: (items: T[]) => void;
    onCopy?: (event: React.ClipboardEvent) => void;
    getDataFromPaste?: (event: React.ClipboardEvent) => Promise<T[]>;
    mergePastedItems?: (pasted: T[], existing: readonly T[]) => T[];
    createOption?: (item: T) => Option<T>;
    createItem?: (item: string) => Promise<T>;
    isEqual?: (a: T, b: T) => boolean;
    className?: string;
    error?: string;
};

export const StringChipsComponent = <T extends string>(props: Props<T>) => (
    <ChipsComponent
        {...props}
        createOption={(item: string) => ({
            label: item,
            value: item,
        })}
        createItem={(item: string) => Promise.resolve(item)}
    />
)

export const ChipsComponent = <T, >({
    className,
    items,
    i18nKey,
    onChange,
    getDataFromPaste,
    mergePastedItems,
    onCopy,
    createOption,
    createItem,
    isEqual = _.isEqual,
    MultiValueChipComponent,
    error,
}: Props<T>) => {
    const intl = useIntl();
    const [inputValue, setInputValue] = useState('');
    const resolvedOnCopy = onCopy ?? useChipsClipboard();

    const handleChange = (newItems: Option<T>[]) => {
        const nonNullItems = newItems || [];
        onChange(nonNullItems.map(item => item.value));
    };

    const handlePasteItems = (pasted: T[]) => {
        const newItems = mergePastedItems
            ? mergePastedItems(pasted, items)
            : [
                ...items,
                ...pasted.filter(item => !items.includes(item)),
            ];
        onChange(newItems);
    };

    const handleInputChange = (newInputValue: string) => {
        setInputValue(newInputValue);
    };

    const handleKeyDown = async (event: any) => {
        if (!inputValue) {
            return;
        }
        if (['Enter', 'Tab'].includes(event.key)) {
            const value = inputValue.trim();
            setInputValue('');
            const item = await createItem(value);
            if (!items.find(i => isEqual(i, item))) {
                onChange([...items, item]);
            }
            event.preventDefault();
        }
    };

    const handlePaste = async (e) => {
        if (getDataFromPaste) {
            const data = await getDataFromPaste(e);
            if (data) {
                handlePasteItems(data);
            }
        } else {
            const data = e.clipboardData.getData('text/chips-data');
            if (data) {
                e.preventDefault();
                handlePasteItems(JSON.parse(data));
            }
        }
    };

    return (
        <div
            data-test="chips-component"
            className={cx(styles.container, { [styles.error]: error })}
            onCopy={resolvedOnCopy}
            onPaste={handlePaste}
        >
            <CreatableSelect
                className={className}
                classNamePrefix={'chips'}
                components={components({
                    MultiValue: MultiValueChipComponent
                })}
                inputValue={inputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={handleChange}
                backspaceRemovesValue={false}
                onInputChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder={intl.messages[i18nKey] as string}
                value={items.map(item => createOption(item))}
                styles={{}}
            />
            {!!error && <div className={styles.tooltip} role="tooltip">{error}</div> }
        </div>
    );
};
