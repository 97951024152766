import React from 'react';

import {IconProps} from './iconTypes';

export function CallIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h18v18H0z" />
                <path
                    className={props.animationClass}
                    fill="#3C38BC"
                    d="M4.538 2.912L6.567 4.94a1.748 1.748 0 0 1 .132 2.327l-.175.194a.548.548 0 0 0 .043.726l3.245 3.246c.19.19.485.211.716.047l.205-.178a1.748 1.748 0 0 1 2.325.13l2.03 2.03a1.748 1.748 0 0 1 0 2.472l-.481.48a4.049 4.049 0 0 1-5.053.539l-.525-.363a31.689 31.689 0 0 1-7.991-8.158 4.046 4.046 0 0 1 .547-5.039l.482-.481a1.747 1.747 0 0 1 2.47 0zm-1.623.849l-.482.48-.13.139a2.842 2.842 0 0 0-.262 3.394 30.482 30.482 0 0 0 8.188 8.187c1.123.71 2.59.546 3.53-.395l.48-.481a.548.548 0 0 0 0-.775l-2.03-2.029a.548.548 0 0 0-.387-.16.54.54 0 0 0-.315.097l-.072.064a1.748 1.748 0 0 1-2.472 0L5.718 9.035c-.629-.629-.683-1.627-.113-2.346l.113-.125a.548.548 0 0 0 0-.775L3.69 3.76a.548.548 0 0 0-.774 0zm13.556.408a.6.6 0 1 1 .88.816l-2.635 2.837.766.001a.6.6 0 0 1 .592.503l.008.097a.6.6 0 0 1-.6.6H13.34a.612.612 0 0 1-.304-.082.599.599 0 0 1-.104-.078l.072.057a.602.602 0 0 1-.057-.044l-.015-.013a.612.612 0 0 1-.176-.304.598.598 0 0 1-.016-.136l.007.09a.603.603 0 0 1-.006-.055V6.115a.6.6 0 0 1 1.2 0l-.001.78zM13.196.592a.612.612 0 0 1 .31.086c.034.021.068.046.099.075l-.075-.06c.021.015.041.03.06.046l.015.014a.61.61 0 0 1 .175.298c.01.045.016.093.016.141l-.006-.088a.603.603 0 0 1 .005.054l.001.034V3.5a.6.6 0 0 1-1.2 0v-.78l-2.531 2.727a.6.6 0 0 1-.763.097l-.085-.066a.6.6 0 0 1-.032-.848l2.634-2.838h-.765a.6.6 0 0 1-.593-.502l-.007-.098a.6.6 0 0 1 .6-.6z"
                />
            </g>
        </svg>
    );
}
