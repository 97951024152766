import React from 'react';

import { PasswordConfirmIcon } from '../../icons/PasswordConfirmIcon';
import { Column } from '../Column/Column';
import { Loader } from '../Loader';
import { ModalParagraph } from '../Modal/ModalParagraph';

import styles from './ChangePasswordModal.pcss';

export const ChangePasswordConfirmation: React.FC = ({
  children
}) => <Column className={styles.confirmationColumn}>
  <PasswordConfirmIcon />
  <ModalParagraph>
    {children}
  </ModalParagraph>
  <Loader />
</Column>;