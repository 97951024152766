import React from 'react';

import {IconProps} from './iconTypes';

export function CancelIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H24V24H0z"/>
                <path className={props.animationClass}
                      d="M12 2.962c4.991 0 9.038 4.046 9.038 9.037 0 4.992-4.047 9.038-9.038 9.038-4.991 0-9.037-4.046-9.037-9.038 0-4.991 4.046-9.037 9.037-9.037zm0 1.2c-4.329 0-7.838 3.509-7.838 7.837 0 4.329 3.51 7.838 7.838 7.838 4.329 0 7.837-3.51 7.837-7.838S16.33 4.162 12 4.162zM8.966 8.13l.083.069L12 11.15l2.952-2.95c.234-.234.614-.234.848 0 .209.208.232.532.07.765l-.07.084-2.951 2.95 2.951 2.951c.235.234.235.614 0 .849-.208.208-.531.23-.765.069l-.083-.07-2.952-2.951-2.95 2.952c-.234.234-.614.234-.848 0-.209-.209-.232-.532-.07-.766l.07-.083 2.95-2.951-2.95-2.95c-.235-.235-.235-.615 0-.849.208-.208.531-.231.765-.07z"/>
            </g>
        </svg>
    );
}
