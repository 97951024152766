import _ from 'lodash';
import moment from 'moment-timezone';
import 'moment/locale/pl';

const localeLanguageMap = {
    'pl-PL': 'pl',
    'en-US': 'en',
    'en-GB': 'en'
};

export const setLocale = (language: string) => moment.locale(_.get(localeLanguageMap, language, 'en'));
