import _ from 'lodash';
import * as React from 'react';
import {FormattedMessage} from 'react-intl';

import {Bot} from '../surveyCreator/model';
import {Intent} from '../surveyCreator/SurveyCreator';
import {withCurrentBot} from '../utils/CurrentBot';
import {HttpClient} from '../utils/HttpClient';

import styles from './LearningView.pcss';

type Props = {
    history: any;
    currentBot: Bot;
};

interface State {
    intents: Intent[];
}

export const getIntents = (currentBotId: string) => {
    return HttpClient.get({path: `/bots/${currentBotId}/intents`}).then(response => response.data);
};

class IntentsList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            intents: []
        };
    }

    componentDidMount() {
        getIntents(this.props.currentBot.id).then((intents: Intent[]) =>
            this.setState({intents: intents.map(intent => ({name: intent.name, displayName: intent.displayName}))})
        );
    }

    componentDidUpdate(prevProps: Props): void {
        if (_.get(prevProps, 'currentBot.id') !== this.props.currentBot.id) {
            getIntents(this.props.currentBot.id).then((intents: Intent[]) =>
                this.setState({intents: intents.map(intent => ({name: intent.name, displayName: intent.displayName}))})
            );
        }
    }

    render() {
        const {intents} = this.state;
        const {history} = this.props;

        return (
            <div className={styles.intentsContainer}>
                <FormattedMessage id={'learning.view.intents'}/>
                {intents.map(intent => (
                    <div data-test="intent-entry"
                         key={intent.name}
                         className={`${styles.entitiesButton} ${
                             window.location.pathname === '/' ? styles.activeButton : ''
                         }`}
                         onClick={() => history.push(`intents/${encodeURIComponent(intent.name)}`)}
                    >
                        {intent.displayName ? intent.displayName : intent.name}
                    </div>
                ))}
            </div>
        );
    }
}

export default withCurrentBot<{ history: any }>(IntentsList);
