import React, { useState } from 'react';
import { TranscriptItemHttpStep } from '../models/Transcript';
import { HttpStepLineDetails } from './HttpStepLineDetails';
import { HttpStep } from '../../icons/conversations/HttpStep';
import styles from './StepLine.pcss';

type Props = {
  stepLink: string;
  line: TranscriptItemHttpStep;
  isStepLinkExpired: boolean | undefined;
};

export function HttpStepLine({ line, stepLink, isStepLinkExpired }: Props) {
  const [isModalOpened, setIsModalOpened] = useState(false);

  return (
    <>
      <div className={styles.wrapper}>
        <button
          data-test="http-step-line"
          className={styles.button}
          onClick={() => setIsModalOpened(true)}
        >
          <HttpStep animationClass={styles.icon} />
          <span className={styles.text}>{line.stepName}</span>
        </button>
      </div>
      {isModalOpened && (
        <HttpStepLineDetails
          line={line}
          stepLink={stepLink}
          onClose={() => setIsModalOpened(false)}
          isStepLinkExpired={isStepLinkExpired}
        />
      )}
    </>
  );
}
