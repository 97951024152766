import styles from './Event.pcss';

const TAG_WIDTH_CACHE: { [label: string]: number } = {};
let tagWrapper: HTMLDivElement;

export function getTagWidth(tagLabel: string): number {
    if (!tagWrapper) {
        tagWrapper = createTagWrapper();
    }
    if (!TAG_WIDTH_CACHE[tagLabel]) {
        tagWrapper.textContent = tagLabel;
        TAG_WIDTH_CACHE[tagLabel] = tagWrapper.getBoundingClientRect().width;
    }
    return TAG_WIDTH_CACHE[tagLabel];
}

function createTagWrapper() {
    const wrapper = document.createElement('div');
    wrapper.style.position = 'absolute';
    wrapper.style.top = '0';
    wrapper.style.visibility = 'hidden';
    document.body.appendChild(wrapper);

    const tag = document.createElement('div');
    tag.classList.add(styles.event);
    wrapper.appendChild(tag);
    return tag;
}
