import { IntlShape } from 'react-intl';

import {EditIcon} from '../../../icons';
import {ChangeVariableIcon} from '../../../icons/ChangeVariableIcon';
import { SurveyDefinitionVariable} from '../../model';

type VariablesMenuOptionsArgs = {
  onVariableChange: React.MouseEventHandler;
  onRename: React.MouseEventHandler;
}

export const variablesMenuOptionsFactory = ({
                                              onVariableChange,
                                              onRename
                                            }: VariablesMenuOptionsArgs) => {
  return [{
    key: 'change-variable-option',
    dataTest: 'change-variable-option',
    onClick: onVariableChange,
    icon: ChangeVariableIcon,
    tooltipLabelId: 'survey-creator.saveAs.changeVariableOptionTooltip'
  }, {
    key: 'rename-variable-option',
    dataTest: 'rename-variable-option',
    onClick: onRename,
    icon: EditIcon,
    tooltipLabelId: 'survey-creator.saveAs.renameVariableOptionTooltip'
  }];
}

const optionFor = (it: string) => ({id: it, name: it});

export enum VariableType {
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  DICTIONARY = 'dictionary',
  STRING = 'string'
}
export type PrimitiveVariableType = VariableType.NUMBER | VariableType.STRING | VariableType.BOOLEAN;

export const typeOptions = Object.values(VariableType).map(optionFor);

export const systemVariableEntryName = (intl: IntlShape, variableId: string, entry: string): string => (intl.messages[`survey-creator.system_variable.${variableId}.${entry}`] || entry) as string;
