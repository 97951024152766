import _ from 'lodash';
import {ClipboardEvent} from 'react';

export function useChipsClipboard() {
    return function onCopy(event: ClipboardEvent): void {
        const selectedText = window.getSelection().toString();
        const data = selectedText ? selectedText.split('\n').filter(_.negate(_.isEmpty)) : undefined;
        if (data) {
            event.clipboardData.setData('text/chips-data', JSON.stringify(data));
        }
        event.clipboardData.setData('text/plain', selectedText);
        event.preventDefault();
    }
}
