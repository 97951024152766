import React from 'react';

import { IconProps } from './iconTypes';

export const HelpPopupIcon: React.FC<IconProps> = ({ animationClass }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
        <g>
            <g transform="translate(-790.000000, -323.000000) translate(790.000000, 323.000000)">
                <path d="M0 0H24V24H0z"/>
                <path className={animationClass} fill="#919aaf" fillRule="nonzero" d="M12 3c4.97 0 9 4.03 9 9s-4.03 9-9 9-9-4.03-9-9 4.03-9 9-9zm0 1.195c-4.31 0-7.805 3.494-7.805 7.805 0 4.31 3.494 7.805 7.805 7.805 4.31 0 7.805-3.494 7.805-7.805 0-4.31-3.494-7.805-7.805-7.805zm0 11.129l.11.006c.433.055.768.424.768.871 0 .485-.393.878-.878.878s-.878-.393-.878-.878c0-.447.335-.816.768-.87l.11-.007zm2.766-6.202c.324 1.407-.46 2.833-1.82 3.314-.18.063-.307.217-.34.4l-.008.093v.751c0 .33-.268.598-.598.598-.297 0-.543-.217-.59-.5l-.008-.098v-.75c0-.68.4-1.291 1.011-1.567l.135-.054c.788-.279 1.24-1.104 1.053-1.918-.187-.814-.956-1.358-1.787-1.264-.83.094-1.457.797-1.457 1.632 0 .33-.268.598-.598.598-.33 0-.597-.268-.597-.598 0-1.443 1.083-2.656 2.518-2.82 1.434-.162 2.762.777 3.086 2.183z"/>
            </g>
        </g>
    </g>
</svg>

)