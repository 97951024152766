import classNames from 'classnames';
import styles from './BotTags.pcss';
import {TextLabel} from '../components/Label';
import {TooltipComponent} from '../components/Tooltip/Tooltip';
import {InformationIcon} from '../icons';
import {default as React, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {Tag} from './BotComponent';
import {MultiSelectComponent} from '../surveyCreator/components/MultiSelect';
import cx from 'classnames';


const MAX_NUM_OF_TAGS = 7;

type Props = {
    tags: string[];
    setTags: React.Dispatch<React.SetStateAction<string[]>>;
    tagError: string;
    setTagError: (message: string) => void;
    predefinedTags: Tag[];
};

enum TagError {
    TOO_MANY_TAGS = 'dashboard.editBotPopup.tooManyTags',
}


const validateTags = (tags: string[]): TagError[] => {
    const errors: TagError[] = [];

    if (tags.length > MAX_NUM_OF_TAGS) {
        errors.push(TagError.TOO_MANY_TAGS);
    }

    return errors;
};

const createOption = (tag: Tag) => ({id: tag.tag, name: tag.tag});

export const BotTags = ({tags, setTags, tagError, setTagError, predefinedTags}: Props) => {
    const intl = useIntl();

    useEffect(() => {
        const errors = validateTags(tags);
        if (errors.length > 0) {
            setTagError(intl.formatMessage({id: errors[0]}));
        } else {
            setTagError(undefined);
        }
    }, [tags]);


    const options = predefinedTags
        .sort((a, b) => a.tag.localeCompare(b.tag))
        .map(createOption)


    return (
        <div
            className={classNames(styles.tagWrapper, {
                [styles.error]: Boolean(tagError),
            })}
        >
            {Boolean(tagError) && (
                <div
                    className={styles.tagErrorValidation}
                    role="tooltip"
                    data-test="tag-validation-error"
                >
                    {tagError}
                </div>
            )}

            <div className={styles.flex}>
                <TextLabel
                    label={intl.formatMessage({
                        id: 'dashboard.editBotPopup.botTags',
                    })}
                />
                <TooltipComponent
                    tooltipText={intl.formatMessage({
                        id: 'dashboard.editBotPopup.botTagsTooltip',
                    })}
                >
                    <div style={{padding: '5px'}}>
                        <InformationIcon/>
                    </div>
                </TooltipComponent>
            </div>
            <div data-test={'custom-multi-select-container'}>
                <MultiSelectComponent
                    value={tags.map((tag) => ({name: tag, id: tag}))}
                    onChange={e => {
                        setTags(e.map((tag) => tag.name))
                    }}
                    options={options}
                    styleClass={cx(styles.select, {
                        [styles.error]: Boolean(tagError),
                    })}
                />

            </div>
        </div>
    );
};
