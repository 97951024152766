import classnames from 'classnames';
import React from 'react';

import styles from './DeleteIcon.pcss'
import {IconProps} from './iconTypes';

export function DeleteIcon({ animationClass, title, fill }: IconProps & { fill?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className={styles.icon}>
      {title && <title>{title}</title>}
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path className={classnames(styles.path, animationClass)} fill={fill || '#919aaf'}
          d="M14.025 3.4c1.077 0 1.95.873 1.95 1.95v.749l4.8.001c.331 0 .6.269.6.6 0 .298-.218.546-.503.592l-.097.008-2.1-.001V18.85c0 1.028-.795 1.87-1.804 1.945l-.146.005h-9.45c-1.077 0-1.95-.873-1.95-1.95V7.299l-2.1.001c-.331 0-.6-.269-.6-.6 0-.298.218-.546.503-.592l.097-.008 4.8-.001V5.35c0-1.028.795-1.87 1.804-1.945l.146-.005zm3.45 3.9H6.525v11.55c0 .38.282.693.648.743l.102.007h9.45c.414 0 .75-.336.75-.75V7.3zm-7.5 2.85c.298 0 .546.218.592.503l.008.097v5.4c0 .331-.269.6-.6.6-.298 0-.546-.218-.592-.503l-.008-.097v-5.4c0-.331.269-.6.6-.6zm4.05 0c.298 0 .546.218.592.503l.008.097v5.4c0 .331-.269.6-.6.6-.298 0-.546-.218-.592-.503l-.008-.097v-5.4c0-.331.269-.6.6-.6zm0-5.55h-4.05c-.414 0-.75.336-.75.75v.75h5.55v-.75c0-.38-.282-.693-.648-.743l-.102-.007z" />
      </g>
    </svg>
  );
}
