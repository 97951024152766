import React from 'react';

import styles from './icons.pcss';

export function PhoneIcon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <path className={styles.iconFillColor} fillRule="nonzero" d="M5.35 14.816l.107.006a.85.85 0 1 1-.107-.006zm3.75 0l.107.006a.85.85 0 1 1-.107-.006zm3.75 0l.107.006a.85.85 0 1 1-.107-.006zm-7.5-3l.107.006a.85.85 0 1 1-.107-.006zm3.75 0l.107.006a.85.85 0 1 1-.107-.006zm3.75 0l.107.006a.85.85 0 1 1-.107-.006zm-7.5-3l.107.006a.85.85 0 1 1-.107-.006zm3.75 0l.107.006a.85.85 0 1 1-.107-.006zm3.75 0l.107.006a.85.85 0 1 1-.107-.006zm1.523-7.008A3.599 3.599 0 0 1 17.2 5.322v.594a1.6 1.6 0 0 1-1.6 1.6h-2.5c-.835 0-1.52-.64-1.593-1.426l-.01-.222a.4.4 0 0 0-.397-.352h-4a.401.401 0 0 0-.394.352l-.017.233A1.6 1.6 0 0 1 5.1 7.516H2.6a1.6 1.6 0 0 1-1.6-1.6v-.593a3.6 3.6 0 0 1 2.82-3.517l.023-.003a27.691 27.691 0 0 1 10.53.005zM4.658 2.874l-.598.108a2.4 2.4 0 0 0-1.86 2.34v.594a.4.4 0 0 0 .4.4h2.5c.203 0 .374-.153.394-.318l.013-.228A1.6 1.6 0 0 1 7.1 4.316h4c.814 0 1.496.61 1.592 1.448l.008.152c0 .22.18.4.4.4h2.5a.4.4 0 0 0 .4-.4v-.594a2.395 2.395 0 0 0-1.87-2.34 26.475 26.475 0 0 0-9.472-.108z"/>
      </svg>
    );
}
