import * as React from 'react';
import {useContext} from 'react';

import {ElementWithLabel} from '../../components/ElementWithLabel/ElementWithLabel';
import {Input} from '../../components/Input';
import {TextAreaWithVariables} from '../../components/TextArea/TextAreaWithVariables';
import {SurveyContext} from '../SurveyCreator';

import styles from './SmsConfigTab.pcss';
import {StepElementProps} from './StepElementProps';

type Props = { message: string, from: string, to?: string };

export const SmsConfigTab = (props: StepElementProps<Props>) => {
  const {variables} = useContext(SurveyContext)
  const handleMessageChange = (value: string) => props.onChange({
    ...props.question,
    message: value
  });
  const handleFromNumberChange = (value: string) => props.onChange({
    ...props.question,
    from: value
  });
  const handleToNumberChange = (value: string) => props.onChange({
    ...props.question,
    to: value
  });
  return (
    <div className={styles.container}>
      <ElementWithLabel labelId={'survey-creator.smsStep.fromNumber'}>
        <Input dataTest={'sms-from-number'} value={props.question.from} pattern="[0-9]*" onChange={handleFromNumberChange} width={'100%'} />
      </ElementWithLabel>

      <ElementWithLabel labelId={'survey-creator.smsStep.toNumber'}>
        <TextAreaWithVariables
          dataTest={'sms-to-number'}
          value={props.question.to || ''}
          onChange={handleToNumberChange}
          availableVariables={variables.getVariablesForCreator()}
          asInput
        />
      </ElementWithLabel>

      <ElementWithLabel labelId={'survey-creator.smsStep.message'}>
        <TextAreaWithVariables
          dataTest={'sms-message-text'}
          value={props.question.message}
          onChange={handleMessageChange}
          availableVariables={variables.getVariablesForCreator()}
        />
      </ElementWithLabel>
    </div>
  );
};
