import * as React from 'react';

import styles from './NoContacts.pcss';
import { FormattedMessage, useIntl } from 'react-intl';
import { EmptyStateBagIcon } from '../../icons/EmptyStateBagIcon';
import { HorizontalSeparator } from '../../components/HorizontalSeparator/HorizontalSeparator';

type Props = {
  openAddContactsModal: () => void;
};

export function NoContacts({ openAddContactsModal }: Props) {
  const intl = useIntl();

  return (
    <div className={styles.wrapper} data-test="empty-state-no-contacts">
      <div className={styles.contentWrapper}>
        <EmptyStateBagIcon />
        <div className={styles.message}>
          <p className={styles.text}>
            <FormattedMessage id="contacts.noContacts.importFileExtensions" />
          </p>
          <p className={styles.text}>
            <FormattedMessage
              id="contacts.noContacts.addContactFile"
              values={{
                addContacts: (
                  <button
                    className={styles.linkButton}
                    onClick={openAddContactsModal}
                    data-test="add-contacts-button"
                  >
                    <FormattedMessage id="contacts.noContacts.addContacts" />
                  </button>
                ),
              }}
            />
          </p>
        </div>
        <HorizontalSeparator className={styles.separator} />
        <div className={styles.message}>
          <p className={styles.text}>
            <FormattedMessage
              id="contacts.noContacts.readMoreInKnowledgeBase"
              values={{
                readMore: (
                  <a
                    href={intl.formatMessage({
                      id: 'contacts.noContacts.readMoreUrl',
                    })}
                    target="_blank"
                    data-test="read-more-link"
                  >
                    <FormattedMessage id="contacts.noContacts.readMore" />
                  </a>
                ),
              }}
            />
          </p>
          <p className={styles.text}>
            <FormattedMessage
              id="contacts.noContacts.addContactsViaAPI"
              values={{
                referToDocs: (
                  <a
                    href="https://docs.talkie.ai/"
                    target="_blank"
                    data-test="refer-to-docs-link"
                  >
                    <FormattedMessage id="contacts.noContacts.referToDocs" />
                  </a>
                ),
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
}
