export interface CancellablePromise<T> extends Promise<T> {
    cancel(): void;
}

export const CancellablePromise = Promise;

export function isCancellablePromise<T>(promise: Promise<T>): promise is CancellablePromise<T> {
    return (promise as any).cancel !== undefined;
}

export function cancellablePromise<T>(promise: Promise<T>, onCancel: () => void = () => null): CancellablePromise<T> {
    let canceled = false;
    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            val => (canceled ? null : resolve(val)),
            error => (canceled ? null : reject(error))
        );
    });
    (wrappedPromise as CancellablePromise<T>).cancel = () => {
        canceled = true;
        onCancel();
    };
    return wrappedPromise as CancellablePromise<T>;
}
