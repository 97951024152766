import { useVariables } from './Variables.hook';
import { useEffect, useState } from 'react';
import { Variable } from '../../../surveyCreator/model';
import uuid from 'uuid';
import { VariableType } from '../../../surveyCreator/components/variables/Variables.utils';

export interface Mapping {
  id: string;
  variableId: string;
  sipHeaderName: string;
}

export function useVariablesWithSipHeaderMappings() {
  const {
    variables,
    isReady: variablesReady,
    hasChanges,
    editVariable,
    createVariable,
    removeVariable,
    onSave,
    onCancel,
  } = useVariables();

  const [sipHeaderMappings, setMappings] = useState<Mapping[]>([]);
  const [mappingsInitialized, setMappingsInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (variablesReady && !mappingsInitialized) {
      initializeMappings();
      setMappingsInitialized(true);
    }
  }, [variables, mappingsInitialized]);

  function resetChanges() {
    setMappingsInitialized(false);
    onCancel();
  }

  function initializeMappings() {
    setMappings(variables
      .filter(v => v.origin === 'external')
      .map((v: Variable) => ({
        id: uuid.v4(),
        variableId: v.id,
        sipHeaderName: v.sipHeaderName,
      })),
    );
  }

  function addSipHeaderMapping() {
    setMappings(prevMappings => [...prevMappings, {
      id: uuid.v4(),
      variableId: null,
      sipHeaderName: '',
    }]);
  }


  function createVariableForMapping(mappingId: string, name: string) {
    const sipHeaderName = findMapping(mappingId).sipHeaderName
    const id = uuid.v4();
    createVariable({
      id: id,
      name,
      origin: 'external',
      sipHeaderName: sipHeaderName,
      type: VariableType.STRING,
    });
    updateMapping(mappingId, {variableId: id});
  }

  function findMapping(mappingId: string) {
    return sipHeaderMappings.find(m => m.id === mappingId);
  }

  function assignVariableToMapping(mappingId: string, variableId: string) {
    const mapping = findMapping(mappingId)

    if (mapping.variableId) {
      editVariable(variableId)({origin: 'external', sipHeaderName: mapping.sipHeaderName});
      editVariable(mapping.variableId)({origin: 'step', sipHeaderName: undefined});
    } else {
      editVariable(variableId)({origin: 'external', sipHeaderName: mapping.sipHeaderName});
    }

    updateMapping(mappingId, {variableId});
  }

  function updateMapping(mappingId: string, partial: Partial<Mapping>) {
    setMappings(previousMappings => {
      return previousMappings.map((previousMapping) => {
        if (previousMapping.id !== mappingId) {
          return previousMapping;
        }

        return {
          ...previousMapping,
          ...partial,
          id: mappingId,
        };
      });
    });
  }

  function changeVariableSipHeaderName(mappingId: string, sipHeaderName: string) {
    const mapping = findMapping(mappingId);
    if (mapping.variableId) {
      editVariable(mapping.variableId)({sipHeaderName});
    }
    updateMapping(mappingId, {sipHeaderName});
  }

  function changeVariableValues(variableId: string, values: string[]) {
    editVariable(variableId)({values});
  }

  function changeVariableType(variableId: string, type: VariableType) {
    editVariable(variableId)({type});
  }

  function changeVariableName(variableId: string, name: string) {
    editVariable(variableId)({name});
  }

  function removeSipHeaderMapping(mappingId: string) {
    const mapping = findMapping(mappingId)
    if (mapping.variableId) {
      editVariable(mapping.variableId)({origin: 'step', sipHeaderName: undefined});
    }
    setMappings(previousMappings => previousMappings.filter(previousMapping => previousMapping.id !== mappingId));
  }

  return {
    variables,
    createVariableForMapping,
    assignVariableToMapping,
    changeVariableValues,
    changeVariableType,
    changeVariableName,
    changeVariableSipHeaderName,
    editVariable,
    createVariable,
    removeVariable,

    sipHeaderMappings,
    addSipHeaderMapping,
    removeSipHeaderMapping,

    isReady: mappingsInitialized,
    hasChanges,
    onSave,
    resetChanges,
  }
}