import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import * as Yup from 'yup'

import { ChangePasswordModal, ModalState } from '../../components/ChangePasswordModal/ChangePasswordModal';
import { FormProps } from '../../forms/types';
import { useForm } from '../../forms/useForm';
import { eraseErrorsOnFocus } from '../../forms/utils';
import {passwordPolicyValidationRegexp} from '../../utils/passwordPolicy';

const TIMEOUT_AFTER_CONFIRMATION = 1500;

const initialFields = {
  oldPassword: {
    name: 'oldPassword',
    labelId: 'header.changePasswordModal.oldPasswordLabel'
  },
  newPassword: {
    name: 'newPassword',
    labelId: 'header.changePasswordModal.newPasswordLabel',
  },
  repeatNewPassword: {
    name: 'repeatNewPassword',
    labelId: 'header.changePasswordModal.repeatNewPasswordLabel'
  },
}

export type ChangePasswordModalContainerProps = {
  closeModal?: React.MouseEventHandler;
}

export const ChangePasswordModalContainer: React.FC<ChangePasswordModalContainerProps> = ({
  closeModal
}) => {
  const [modalState, setModalState] = useState<ModalState>('form');
  const validationSchema = Yup.object().shape({
    newPassword: Yup.string().matches(passwordPolicyValidationRegexp, 'auth.errors.complexity'),
    repeatNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'auth.errors.repeat')
  });

  let formProps: FormProps = useForm({
    initialFields,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      // tslint:disable-next-line: no-empty
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, values.oldPassword, values.newPassword);
        setModalState('confirmation');
        await Auth.signOut();
        await new Promise<void>(() => {
          setTimeout(async () => {
            await Auth.signIn(user.username, values.newPassword);
            window.location.reload();
          }, TIMEOUT_AFTER_CONFIRMATION);

        });
      } catch (e) {
        // tslint:disable-next-line: no-console
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    }
  });

  formProps = eraseErrorsOnFocus(formProps);

  return <ChangePasswordModal
    {...formProps}
    popupMessageId="auth.goBack.signIn.setNewPasswordHelp"
    confirmationDescriptionId="header.changePasswordModal.confirmationDescription"
    modalState={modalState}
    closeModal={closeModal}
  />
}
