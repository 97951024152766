import * as React from 'react';
import { TooltipComponent } from '../../components/Tooltip/Tooltip';
import styles from './ChartTile.pcss';

type Props = {
  title: string;
  tooltip: string;
  value: string | number;
};

export function ChartTile({ title, tooltip, value }: Props) {
  return (
    <div className={styles.tile} data-test="statistics-chart-tile">
      <TooltipComponent tooltipText={tooltip}>
        <div className={styles.title}>{title}</div>
        <div className={styles.value}>{value}</div>
      </TooltipComponent>
    </div>
  );
}
