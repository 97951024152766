import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {useCurrentBot} from '../../../../utils/CurrentBot';
import {EntityAnswer} from '../../../model';
import {saveEntityDetails} from '../../../store/entityDetails/actions';

export const useSaveChanges = (entity: string, values: EntityAnswer[]) => {
  const dispatch = useDispatch();
  const currentBot = useCurrentBot();
  return useCallback(() => dispatch(saveEntityDetails.request({body: {values}, id: currentBot.id, entity})), [entity, values]);
};