import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from '../../components/Modal/Modal';
import { ModalContent } from '../../components/ModalContent/ModalContent';
import { Button } from '../../components/Button/Button';
import { Checkbox } from '../../components/Checkbox';
import styles from './ConversationOptionsModal.pcss';

type Props = {
  onClose: () => void;
} & ConversationOptionsProps;

export type ConversationOptionsProps = {
  debugMode: boolean;
  showSSMLTags: boolean;
  onSave: (props: {
    showDebugMode: boolean;
    showSSMLTags: boolean;
  }) => void;
};

export function ConversationOptionsModal({
  onClose,
  onSave,
  showSSMLTags,
  debugMode,
}: Props) {
  const [SSMLTagsChecked, setSSMLTagsChecked] = useState(showSSMLTags);
  const [debugModeChecked, setDebugModeChecked] = useState(debugMode);

  return (
    <Modal
      modalSize="small"
      dataTest="conversation-options-modal"
      noPadding
      noFixedHeight
    >
      <ModalContent
        headerContent={
          <FormattedMessage id="conversations.options.modal.title" />
        }
        footerContent={
          <Button
            className={styles.saveButton}
            buttonType="link"
            dataTest="conversation-options-modal-save"
            onClick={() => {
              onSave({
                showDebugMode: debugModeChecked,
                showSSMLTags: SSMLTagsChecked,
              });
              onClose();
            }}
          >
            <FormattedMessage id="conversations.options.modal.save" />
          </Button>
        }
        onClose={onClose}
        closeButtonDataTest="conversation-options-modal-close"
      >
        <div className={styles.contentContainer}>
          <p className={styles.message}>
            <FormattedMessage id="conversations.options.modal.description" />
          </p>
          <div className={styles.checkboxContainer}>
            <label
              htmlFor="ssml-tags"
              data-test="conversation-options-modal-ssml-tags-checkbox"
            >
              <Checkbox
                id="ssml-tags"
                type="checkbox"
                checked={SSMLTagsChecked}
                onChange={() => setSSMLTagsChecked((prev) => !prev)}
              />
              <span className={styles.label}>
                <FormattedMessage id="conversations.options.modal.ssmlTags" />
              </span>
            </label>
            <label
              htmlFor="debug-mode"
              data-test="conversation-options-modal-debug-mode-checkbox"
            >
              <Checkbox
                id="debug-mode"
                type="checkbox"
                checked={debugModeChecked}
                onChange={() => setDebugModeChecked((prev) => !prev)}
              />
              <span className={styles.label}>
                <FormattedMessage id="conversations.options.modal.debugMode" />
              </span>
            </label>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
