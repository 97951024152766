import * as React from 'react';
import pick from 'lodash/pick';

import { ConversationsFilters } from '../reports/useReports';

import { EventsBar } from './EventsBar';

export const EventsContainer = ({ filters, setFilters }: {
  filters: ConversationsFilters,
  setFilters: (filters: Partial<ConversationsFilters>) => void,
}) => {
    const events = pick(filters, [
      'information',
      'recognizedIntents',
      'results',
      'surveyQuestions',
      'technicalInformation',
    ]);

    return <EventsBar events={events} onChange={setFilters} />;
};
