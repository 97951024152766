import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
    isConditionalStep,
    isHttpStep,
    isQuestionWithAnswer,
    isSetVariableStep,
    isStepVariable,
    isSystemVariable,
} from '../../model';
import { SurveyContext, SurveyContextType } from '../../SurveyCreator';
import { useVariableResolver } from '../variables/variableResolver/useVariableResolver';
import { systemVariableEntryName, VariableType } from '../variables/Variables.utils';
import {
    ConditionalMessageSource,
    isConditionalMessageQuestion
} from './conditional/conditional-messages-utils';
import {
    isVariableDictionaryType,
    ResolvedVariableDictionaryType,
    ResolvedVariableType
} from '../variables/variableResolver/variableResolver';

export type ConditionalSourceDictionary = Record<string, { text: string }> & { default: { text: string } };

const defaultValueForBoolean = (): ConditionalSourceDictionary => ({
    true: { text: '' },
    false: { text: '' },
    default: { text: '' }
});

const defaultValuesForArray = (possibleValues: ResolvedVariableDictionaryType): ConditionalSourceDictionary =>
    possibleValues?.reduce?.(
        (acc, current) => ({
            ...acc,
            [current?.id]: { text: '' }
        }),
        { default: { text: '' } }
    );

const getPossibleValues = (type: ResolvedVariableType) => {
    if (isVariableDictionaryType(type)) {
        return type.map(value => ({ id: value?.id, key: value?.key }));
    }
    // If not array (dictionary) then it must be a boolean
    return [
        { id: 'true', key: 'YES' },
        { id: 'false', key: 'NO' }
    ];
}

const getDefaultValues = (type: ResolvedVariableType) => {
    if (isVariableDictionaryType(type)) {
        return defaultValuesForArray(type);
    }
    // If not array (dictionary) then it must be a boolean
    return defaultValueForBoolean();
}

export const useAvailableConditionalMessage = (): ConditionalMessageSource[] => {
    const intl = useIntl();
    const { blocks, variables, fallback, silenceFallback } = useContext<SurveyContextType>(SurveyContext);

    const findStep = (variableId: string) => {
        const allQuestions = [...blocks.get(), fallback.getAsBlock(), silenceFallback.getAsBlock()]
            .flatMap(b => b.questions)
            .map(q => isConditionalStep(q) ? q.question : q);
        
        return allQuestions
            .filter(step => {
                if (isSetVariableStep(step)) {
                    return step.variable === variableId;
                }
                if (isHttpStep(step)) {
                    return step.mappings.map(m => m.variable).includes(variableId);
                }
                if (isQuestionWithAnswer(step)) {
                    return step.saveTo === variableId;
                }
                return false
            })[0];
    }

    const { resolvedVariablesTypes } = useVariableResolver({ shouldProcessEntities: false });

    const conditionalMessageSources: ConditionalMessageSource[] = useMemo(() => {
        return Object.values(resolvedVariablesTypes).length > 0 ? variables.get()
            .map((variable) => {
                const step = findStep(variable.id);
                const resolvedVariableType = resolvedVariablesTypes[variable.id]
                
                if (
                    !resolvedVariableType
                    || (isStepVariable(variable) && step && !isConditionalMessageQuestion(step))
                    || (!isVariableDictionaryType(resolvedVariableType) && (resolvedVariableType.type === VariableType.STRING || resolvedVariableType.type === VariableType.NUMBER))
                ) {
                    return undefined;
                }

                const possibleValues = getPossibleValues(resolvedVariablesTypes[variable.id])
                const possibleValuesWithTranslations = isSystemVariable(variable) ? possibleValues.map(value => ({
                    id: value.id,
                    key: systemVariableEntryName(intl, variable.id, value.key),
                })) : possibleValues

                return {
                    variable: { id: variable.id, key: variable.name },
                    possibleValues: possibleValuesWithTranslations,
                    defaultValues: getDefaultValues(resolvedVariablesTypes[variable.id]),
                }
            }).filter(s => !!s) : [];
    }, [resolvedVariablesTypes]);

    return conditionalMessageSources;
};
