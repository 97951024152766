import _ from 'lodash';
import * as React from 'react';
import {useContext, useState} from 'react';
import { useIntl } from 'react-intl';
import { SavableLayout } from '../../../components/layouts/SavableLayout/SavableLayout';

import { SurveyIntent } from '../../model';
import { SurveyContext } from '../../SurveyCreator';
import { VerticalSeparator } from '../VerticalSeparator';
import { IntentEntities } from './entities/IntentEntities';

import styles from './IntentDetails.pcss';
import { IntentDetailsHeader } from './IntentDetailsHeader';
import { IntentReaction } from './reaction/IntentReaction';
import { IntentTraining } from './training/IntentTraining';

export const IntentDetails = () => {
    const intl = useIntl();
    const { intents, blocks, variables } = useContext(SurveyContext);
    const [currentIntent, setCurrentIntent] = useState<SurveyIntent>(intents.current ? _.omit(intents.current, 'group') as SurveyIntent : undefined);
    const [activeTab, setActiveTab] = useState<IntentDetailsTab>(IntentDetailsTab.Reaction);
    const isDirty = !!intents.new || !_.isEqual(_.omit(intents.current, 'group'), currentIntent)

    function handleCancel() {
        intents.close();
        setCurrentIntent(intents.current ? _.omit(intents.current, 'group') as SurveyIntent : undefined);
    }

    const onSave = async () => {
        await variables.savePendingVariables();
        await intents.save(currentIntent);
    }
    const onCurrentIntentChange = value => setCurrentIntent({ ...currentIntent, name: value })

    const tabs = Object.keys(IntentDetailsTab)
        .map(key => ({
            id: IntentDetailsTab[key],
            label: String(intl.messages[`survey-creator.intentDetails.${IntentDetailsTab[key]}`]),
            dataTest: `${IntentDetailsTab[key]}-tab`
        }));

    const TabToComponentMapper = (tab: IntentDetailsTab) => ({
        [IntentDetailsTab.Reaction]: <IntentReaction
            onChange={setCurrentIntent}
            intent={currentIntent}
            blocks={blocks.get()} />,
        [IntentDetailsTab.Training]: <IntentTraining
            data-test="intent-training"
            intent={currentIntent} />,
        [IntentDetailsTab.Entities]: <IntentEntities
            currentIntent={currentIntent}
            onChange={setCurrentIntent}
        />
    })[tab];

    return !currentIntent
        ? null
        : <div
            className={styles.intentDetailsContainer}
            data-test="survey-intent"
        >
            <VerticalSeparator />
            <div
                className={styles.outer}
                data-test={intents.new ? 'new-survey-intent' : 'survey-intent-details'}
            >
                <IntentDetailsHeader
                    isNew={!!intents.new}
                    currentIntent={currentIntent}
                    onChange={onCurrentIntentChange} />
                <SavableLayout
                    classes={{
                        root: styles.savableLayout,
                        childrenContainer: styles.childrenContainer
                    }}
                    hasChanges={isDirty}
                    onSave={onSave}
                    onCancel={handleCancel}
                    currentTab={activeTab}
                    tabs={tabs}
                    onTabClick={(tabId: IntentDetailsTab) => setActiveTab(tabId)}
                    getComponentForTab={TabToComponentMapper}
                    noChildrenPadding
                >
                    {TabToComponentMapper(activeTab)}
                </SavableLayout>
            </div>
        </div>
}

export enum IntentDetailsTab {
    Training = 'training',
    Reaction = 'reaction',
    Entities = 'entities'
}
