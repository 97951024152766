import React from 'react';

import {CityOrCommunityQuestion, Message} from '../model';

import styles from './CityOrCommunityStep.pcss'
import {MessageComponent} from './messages/Message';

type Props = {
  question: CityOrCommunityQuestion,
  onChange: (question: CityOrCommunityQuestion) => void
}

export const CityOrCommunityStep = (props: Props) => {
  const changeMessage = (key: string, type: string) => (message: Message) => {
    props.onChange({
      ...props.question,
      [key]: {
        ...props.question[key],
        [type]: message
      }
    })
  }

  return (
    <div>
      <div className={styles.container}>
        <div>{'Miasto czy wieś'}</div>
        <MessageComponent dataTestPrefix={'city-or-community'} message={props.question.miastoWies.message} onChange={changeMessage('miastoWies', 'message')}
                          labelId={'survey-creator.messageLabel'} path="miastoWies.message" />
        <MessageComponent dataTestPrefix={'city-or-community-repeat'} message={props.question.miastoWies.repeatMessage} onChange={changeMessage('miastoWies', 'repeatMessage')}
                          labelId={'survey-creator.repeatMessageLabel'} path="miastoWies.repeatMessage" />
      </div>
      <div className={styles.container}>
        <div>{'Województwo'}</div>
        <MessageComponent dataTestPrefix={'city-or-community'} message={props.question.wojewodztwo.message} onChange={changeMessage('wojewodztwo', 'message')}
                          labelId={'survey-creator.messageLabel'} path="wojewodztwo.message" />
        <MessageComponent dataTestPrefix={'city-or-community-repeat'} message={props.question.wojewodztwo.repeatMessage} onChange={changeMessage('wojewodztwo', 'repeatMessage')}
                          labelId={'survey-creator.repeatMessageLabel'} path="wojewodztwo.repeatMessage" />
      </div>
      <div className={styles.container}>
        <div>{'Miasto'}</div>
        <MessageComponent dataTestPrefix={'city-or-community'} message={props.question.miasto.message} onChange={changeMessage('miasto', 'message')}
                          labelId={'survey-creator.messageLabel'} path="miasto.message" />
        <MessageComponent dataTestPrefix={'city-or-community-repeat'} message={props.question.miasto.repeatMessage} onChange={changeMessage('miasto', 'repeatMessage')}
                          labelId={'survey-creator.repeatMessageLabel'} path="miasto.repeatMessage" />
      </div>
      <div className={styles.container}>
        <div>{'Gmina'}</div>
        <MessageComponent dataTestPrefix={'city-or-community'} message={props.question.gmina.message} onChange={changeMessage('gmina', 'message')}
                          labelId={'survey-creator.messageLabel'} path="gmina.message" />
        <MessageComponent dataTestPrefix={'city-or-community-repeat'} message={props.question.gmina.repeatMessage} onChange={changeMessage('gmina', 'repeatMessage')}
                          labelId={'survey-creator.repeatMessageLabel'} path="gmina.repeatMessage" />
      </div>
      <div className={styles.container}>
        <div>{'Powiat'}</div>
        <MessageComponent dataTestPrefix={'city-or-community'} message={props.question.powiat.message} onChange={changeMessage('powiat', 'message')}
                          labelId={'survey-creator.messageLabel'} path="powiat.message" />
        <MessageComponent dataTestPrefix={'city-or-community-repeat'} message={props.question.powiat.repeatMessage} onChange={changeMessage('powiat', 'repeatMessage')}
                          labelId={'survey-creator.repeatMessageLabel'} path="powiat.repeatMessage" />
      </div>
    </div>
  );
};
