import { Dictionary, keys } from 'lodash';
import { IntlShape } from 'react-intl';

import { isDictionaryWordAnswer } from './components/DictionaryAnswers';
import { Block, BlockQuestion, Callbacks, ConditionalQuestion, DictionaryWordAnswer, Fallback, GenericOrderQuestion, isConditionalStep, isGenericOrderStep, isSynonymsArray, isWordStep, isWordSurveyIntent, Product, Property, SurveyIntent, Synonym, WordQuestion } from './model';

export const callbackName = (intl: IntlShape) => (callbackId: string) => (intl.messages[`survey-creator.callbacks.${callbackId}`] as string);

export const buildCallbackBlocks = (intl: IntlShape) => (callbacksFromApi?: Dictionary<{ steps: BlockQuestion[] }>): Callbacks => {
  const newCallbacks = callbacksFromApi || {};
  return keys(newCallbacks)
    .filter(key => !!newCallbacks[key])
    .reduce((acc, curr) => ({
      ...acc,
      [curr]: {
        id: curr,
        name: callbackName(intl)(curr),
        questions: newCallbacks[curr].steps
      }
    }), {});
};

