import React, { ReactNode } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/cjs/styles/prism';

export function Highlighter({
  children,
  language = 'json',
  wrapLines = true,
  dataTest,
  dataSensitive,
}: {
  children: ReactNode;
  language?: string;
  wrapLines?: boolean;
  dataTest?: string;
  dataSensitive?: string;
}) {
  return (
    <div data-test={dataTest} data-sensitive={dataSensitive}>
      <SyntaxHighlighter {...{ language, wrapLines }} style={prism}>
        {children}
      </SyntaxHighlighter>
    </div>
  );
}

export function prettyPrintJson(json: Record<string, any>) {
  return JSON.stringify(json, null, 2);
}