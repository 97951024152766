import React from 'react';

import {IconProps} from './iconTypes';

export function DontReadIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <defs>
          <filter id="prefix__a">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"/>
          </filter>
        </defs>
        <g fill="none" fillRule="evenodd" filter="url(#prefix__a)" transform="translate(-2 -2)">
          <g fill="#000" fillRule="nonzero">
            <path d="M9-.037C4.009-.037-.037 4.009-.037 9c0 4.991 4.046 9.038 9.037 9.038 4.991 0 9.038-4.047 9.038-9.038C18.038 4.009 13.99-.037 9-.037zm0 1.2c4.329 0 7.837 3.508 7.837 7.837S13.33 16.837 9 16.837 1.162 13.33 1.162 9 4.672 1.162 9 1.162z" transform="translate(3 3)"/>
            <path d="M14.542 2.61c.234-.235.614-.235.849 0 .208.208.23.531.069.765l-.07.083L3.459 15.391c-.234.234-.614.234-.849 0-.208-.209-.23-.532-.069-.766l.07-.083L14.541 2.609z" transform="translate(3 3)"/>
          </g>
        </g>
      </svg>
    );
}
