import * as React from 'react';
import { OrganizationSettingsLayout } from '../OrganizationSettingsLayout';
import { SMSSettingsForm } from './SmsSettingsForm';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/reducers';
import { useRouteMatch } from 'react-router';
import { BotRouteMatch } from '../../utils/CurrentBot';
import { useGetOrganizationSettings } from './useGetOrganizationSettings';
import { Loader } from '../../components/Loader';

export function SMSSettingsTab() {
  const match = useRouteMatch<BotRouteMatch>();
  const organizationName = match.params.orgId;

  const bots = useSelector((state: RootState) => state.bots.bots);
  const organizationBots = bots
    .filter((bot) => bot.organization === organizationName)
    .map((bot) => ({ id: bot.id, name: bot.name }));

  const { isLoading, data: organizationSettings } =
    useGetOrganizationSettings(organizationName);

  return (
    <OrganizationSettingsLayout>
      {isLoading ? (
        <Loader />
      ) : (
        <SMSSettingsForm
          organizationName={organizationName}
          organizationBots={organizationBots}
          organizationSMSSettings={organizationSettings?.sms}
        />
      )}
    </OrganizationSettingsLayout>
  );
}
