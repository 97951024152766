import React from 'react';

import {IconProps} from './iconTypes';

export function DuplicateIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H24V24H0z"/>
                <path className={props.animationClass} d="M3.598 5.24c.297 0 .543.217.59.501l.007.097v13.444c0 .257.185.47.429.515l.094.008h13.444c.33 0 .597.267.597.597 0 .297-.216.544-.5.59l-.097.008H4.718c-.899 0-1.637-.69-1.712-1.57L3 19.282V5.838c0-.33.268-.597.598-.597zM19.282 3C20.231 3 21 3.77 21 4.718V17.04c0 .95-.77 1.718-1.718 1.718H6.96c-.95 0-1.718-.769-1.718-1.718V4.718C5.24 3.769 6.01 3 6.959 3zm0 1.195H6.96c-.29 0-.523.234-.523.523V17.04c0 .29.234.523.523.523h12.323c.289 0 .523-.234.523-.523V4.718c0-.289-.234-.523-.523-.523zM13.68 6.361c.297 0 .544.217.59.5l.008.098V9.72l2.763.001c.33 0 .598.268.598.598 0 .296-.217.543-.5.59l-.098.007h-2.763v2.763c0 .33-.268.598-.598.598-.296 0-.543-.217-.59-.5l-.007-.098-.001-2.763H10.32c-.33 0-.598-.268-.598-.597 0-.297.217-.544.5-.59l.098-.008 2.762-.001.001-2.762c0-.33.268-.598.597-.598z"/>
            </g>
        </svg>
    );
}
