const timeZones = [
    'America/Puerto_Rico',
    'America/Panama',
    'America/Chicago',
    'America/Denver',
    'America/Detroit',
    'America/Havana',
    'America/Los_Angeles',
    'America/Toronto',
    'America/New_York',
    'America/Santiago',
    'America/Santo_Domingo',
    'America/Sao_Paulo',
    'America/St_Johns',
    'America/Vancouver',
    'Pacific/Auckland',
    'Europe/Berlin',
    'Australia/Sydney',
    'Europe/Brussels',
    'Pacific/Easter',
    'Europe/Dublin',
    'Etc/GMT-1',
    'Pacific/Guadalcanal',
    'Pacific/Tarawa',
    'Etc/GMT-13',
    'Etc/GMT-14',
    'Etc/GMT-2',
    'Indian/Maldives',
    'Pacific/Palau',
    'Etc/GMT+1',
    'Pacific/Tahiti',
    'Etc/GMT+11',
    'Etc/GMT+12',
    'Etc/GMT+5',
    'Etc/GMT+6',
    'Etc/GMT+7',
    'Etc/GMT+8',
    'Pacific/Gambier',
    'Etc/UTC',
    'Europe/Andorra',
    'Europe/Astrakhan',
    'Europe/Athens',
    'Europe/London',
    'Europe/Belgrade',
    'Europe/Prague',
    'Europe/Bucharest',
    'Europe/Budapest',
    'Europe/Zurich',
    'Europe/Chisinau',
    'Europe/Gibraltar',
    'Europe/Helsinki',
    'Europe/Kaliningrad',
    'Europe/Kiev',
    'Europe/Kirov',
    'Europe/Lisbon',
    'Europe/Madrid',
    'Europe/Malta',
    'Europe/Minsk',
    'Europe/Paris',
    'Europe/Moscow',
    'Europe/Riga',
    'Europe/Rome',
    'Europe/Samara',
    'Europe/Sofia',
    'Europe/Tallinn',
    'Europe/Vienna',
    'Europe/Vilnius',
    'Europe/Volgograd',
    'Europe/Warsaw',
    'Europe/Zaporozhye',
    'Pacific/Honolulu',
    'GMT'
] as const;

export const getTimeZones = () => [...timeZones].sort();