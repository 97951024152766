import classNames from 'classnames';
import * as React from 'react';
import {useIntl} from 'react-intl';
import Select, {components} from 'react-select';

import { ArrowUpIcon, CrossIcon, DropdownIcon } from '../../icons';

import styles from './MultiSelect.pcss';

type Value = { id: string; name: string };

type Props = {
    value: Value[];
    options: Value[];
    onChange: (value: Value[]) => void;
    isDisabled?: boolean;
    placeholderMessage?: string;
    styleClass?: any;
}

const toSelectFormat = (el: any) => ({ value: el.id, label: el.name });
const toExternalFormat = (el: any) => ({ id: el.value, name: el.label });

export const MultiSelectComponent = ({ styleClass, value, options, onChange, isDisabled, placeholderMessage }: Props) => {
    const intl = useIntl();
    const classes = styleClass || styles.select;
    return (
        <Select
            className={classes}
            data-test={`custom-multi-select`}
            classNamePrefix="custom-multi-select"
            value={value && value.map(toSelectFormat)}
            isMulti={true}
            options={options.map(toSelectFormat)}
            isSearchable={true}
            isClearable={false}
            isDisabled={isDisabled}
            onChange={(res: any) => onChange((res || []).map(toExternalFormat))}
            noOptionsMessage={() => intl.messages['select.noOptions'] as string}
            placeholder={!!placeholderMessage ? intl.messages[placeholderMessage] as string : intl.messages['select.placeholder'] as string}
            components={{
                Option: ({ innerProps, data, isSelected }: any) => (
                    <div {...innerProps}
                         className={classNames(styles.option, {[styles.selectedOption]: isSelected})}>
                        <div className={styles.selectedLabel} data-test={`select-option`}>{data.label}</div>
                    </div>
                ),
                ClearIndicator: null,
                NoOptionsMessage: () => null,
                MultiValueRemove: (props: any) => {
                    return (
                        <components.MultiValueRemove {...props} data-test={'multi-select-remove'}>
                            <div className={styles.removeIcon}>
                                <CrossIcon animationClass={styles.removeIcon}/>
                            </div>
                        </components.MultiValueRemove>
                    );
                },
              DropdownIndicator: () => {
                return (
                  <>
                    <span className={styles.customIndicatorClosed}><DropdownIcon /></span>
                    <span className={styles.customIndicatorOpen}><ArrowUpIcon /></span>
                  </>
                );
              }
            }}
        />
    );
};
