import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../components/Button/Button';
import { MarkdownContent } from '../components/MarkdownContent/MarkdownContent';
import { Modal } from '../components/Modal/Modal';
import { ModalContent } from '../components/ModalContent/ModalContent';
import styles from './CancelContactModal.pcss'

type CancelContactModalProps = {
  onConfirm: React.MouseEventHandler;
  onClose: React.MouseEventHandler;
}

export const CancelContactModal = ({ onConfirm, onClose }: CancelContactModalProps) => {
  return <Modal
    position="center"
    dataTest="cancel-confirmation-modal"
    noFixedHeight
    noPadding
    modalSize="medium"
  >
    <ModalContent
      headerContent={<FormattedMessage id="contacts.cancel.modal.header" />}
      footerContent={
        <>
          <Button
            dataTest="cancel-contact-modal-cancel"
            buttonType="normal"
            onClick={onClose}
          >
            <FormattedMessage id="contacts.cancel.modal.cancel" />
          </Button>
          <Button
            dataTest="cancel-contact-modal-confirm"
            buttonType="warning"
            onClick={onConfirm}
          >
            <FormattedMessage id="contacts.cancel.modal.confirm" />
          </Button>
        </>
      }
    >
      <div data-test="cancel-contact-modal-message">
        <MarkdownContent
            contentKey="contacts.cancel.modal.body"
            className={styles.content}
        />
      </div>
    </ModalContent>
  </Modal>
}