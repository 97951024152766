import React, {useEffect, useState} from 'react';

import {IconButton} from '../components/IconButton/IconButton';
import {MinusIcon} from '../icons/MinusIcon';
import {CancelSmallIcon} from '../icons/CancelSmallIcon';
import {ConversationDetails} from '../conversations/ConversationDetails';

import styles from './ConversationPreview.pcss';

export const ConversationPreview = ({ callId, onClose, currentBotId, timeZone }: { callId?: string, onClose: () => void, currentBotId: string, timeZone: string }) => {
  const [isConversationPreviewMinimized, setIsConversationPreviewMinimized] = useState(false);

  useEffect(() => {
    setIsConversationPreviewMinimized(false);
  }, [callId]);

  if (!callId) {
    return null;
  }

  return (
    <div className={styles.conversationPreviewContainer} data-test="reports-conversation-preview">
      <div className={styles.conversationPreviewControlsContainer}>
        <IconButton
          dataTest="minimize-reports-conversation-preview"
          className={styles.iconButton}
          Icon={MinusIcon}
          onClick={() => setIsConversationPreviewMinimized(state => !state)}
        />
        <IconButton
          dataTest="close-reports-conversation-preview"
          className={styles.iconButton}
          Icon={CancelSmallIcon}
          onClick={onClose}
        />
      </div>
      <div className={styles.conversationDetailsContainer} data-variant={isConversationPreviewMinimized ? 'minimized' : 'maximized'}>
        <ConversationDetails
          minimized={isConversationPreviewMinimized}
          callId={callId}
          botId={currentBotId}
          timezone={timeZone}
        />
      </div>
    </div>
  )
};
