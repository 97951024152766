import _ from 'lodash';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router';
import { v4 } from 'uuid';

import { SavableLayout } from '../../../components/layouts/SavableLayout/SavableLayout';
import { useCurrentBot } from '../../../utils/CurrentBot';
import { isLemmatizationEnabled } from '../../../utils/featureFlags';
import { HttpClient } from '../../../utils/HttpClient';
import { Synonym } from '../../model';
import { AddItemBar } from '../AddItemBar/AddItemBar';
import { AddItemModal } from '../AddItemModal/AddItemModal';
import { VerticalSeparator } from '../VerticalSeparator';

import { EntityAnswersList } from './EntityAnswersList/EntityAnswersList';
import styles from './EntityDetails.pcss';
import { useEntityDetails } from './hooks/useEntityDetails';
import { useGlobalWords } from './hooks/useGlobalWords';

export enum EntityDetailsTabs {
  Answers = 'answers'
}

export const EntityDetails: React.FC = () => {
  const match = useRouteMatch<{
    entity?: string;
    entityDetailsTab?: string;
  }>();
  const history = useHistory();
  const intl = useIntl();
  const {
    entityAnswers,
    isReady,
    isLoading,
    hasError,
    hasChanges,
    onCancel,
    onSave,
    updateEntityDetails,
  } = useEntityDetails(match.params.entity);

  const tabs = Object.keys(EntityDetailsTabs)
    .map(key => ({
      id: EntityDetailsTabs[key],
      label: String(intl.messages[`survey-creator.entities-tabs.${EntityDetailsTabs[key]}`]),
      dataTest: `${EntityDetailsTabs[key]}-tab`
    }));

  const onTabClick = useCallback((id: string) => history.push(`${id}`), [history]);

  const currentBot = useCurrentBot();
  const { globalWords } = useGlobalWords();

  const onAdd = async (key: string) => {
    let firstSynonym: Synonym = {verbatim: key};
    if (isLemmatizationEnabled()) {
      const response = await HttpClient.post({ path: `/bots/${currentBot.id}/lemma`, body: { text: key } });
      firstSynonym.lemma = response.data.lemma;
  }
    
    return updateEntityDetails([
      ...entityAnswers, {
        id: v4(),
        key: key,
        synonyms: [firstSynonym]
      }
    ]);
  };

  const addItemModalItems = _.map(globalWords, globalWord => ({
    key: globalWord.data.word,
    name: globalWord.data.word
  }));


  return <div className={styles.entityDetails}>
    <VerticalSeparator noMargin />
    <div className={styles.savableLayoutContainer}>
      {
        Boolean(match.params.entity) &&
        <SavableLayout
          isLoading={!isReady}
          hasChanges={hasChanges}
          onSave={onSave}
          onCancel={onCancel}
          noChildrenPadding
          tabs={tabs}
          currentTab={match.params.entityDetailsTab}
          onTabClick={onTabClick}
          classes={{ root: styles.savableLayout}}
        >
          <AddItemModal
            addLabelId={'survey-creator.word.addAnswerLabel'}
            dataTest={'add-answer-modal'}
            onCreate={onAdd}
            onSelect={onAdd}
            items={addItemModalItems}
            renderTrigger={({ open }) => (
              <AddItemBar
                dataTest="add-answer-button"
                addButtonLabelId="survey-creator.entities-tabs.answers.addAnswerButton"
                descriptionId="survey-creator.entities-tabs.answers.addAnswerDescription"
                onClick={open}
              />
            )}
          />
          <EntityAnswersList
            answers={entityAnswers}
            onChange={updateEntityDetails}
            globalWords={globalWords}
          />
        </SavableLayout>
      }
    </div>
  </div>;
};