import React, { useCallback, useEffect, useState } from 'react';

import { CancelSmallIcon } from '../../icons/CancelSmallIcon';
import { Box } from '../Box/Box';
import { IconButton } from '../IconButton/IconButton';

import styles from './GuideBox.pcss';

type GuideBoxStepProps = {
  header: string;
  content?: string;
}

export type GuideBoxProps = {
  steps: GuideBoxStepProps[];
  currentBotId: string;
}

const GuideBoxStep: React.FC<GuideBoxStepProps> = ({
  header,
  content
}) => <div className={styles.guideBoxStep}>
    <h2>{header}</h2>
    {content && <p>{content}</p>}
  </div>

export const GuideBox: React.FC<GuideBoxProps> = ({
  steps,
  currentBotId
}) => {
  const [isClosed, setClosed] = useState(false);
  useEffect(() => {
    const guideBoxClosedBotIds: string[] = JSON.parse(localStorage.getItem('guideBoxClosedBotIds'));

    setClosed(guideBoxClosedBotIds && guideBoxClosedBotIds.includes(currentBotId));
  }, [localStorage]);

  const onClose = useCallback(() => {
    let guideBoxClosedBotIds: string[] = JSON.parse(localStorage.getItem('guideBoxClosedBotIds'));
    if (Array.isArray(guideBoxClosedBotIds)) {
      guideBoxClosedBotIds.push(currentBotId);
    } else {
      guideBoxClosedBotIds = [currentBotId];
    }

    localStorage.setItem('guideBoxClosedBotIds', JSON.stringify(guideBoxClosedBotIds));
    setClosed(true);
  }, [localStorage]);

  return !isClosed && <Box
    data-test="guide-box"
    className={styles.guideBox}
    border
  >
    <IconButton
      data-test="close"
      className={styles.closeButton}
      animationClass={styles.closeIcon}
      Icon={CancelSmallIcon}
      onClick={onClose}
    />
    {
      steps.map(step => <GuideBoxStep key={step.header} {...step} />)
    }
  </Box>;
}