import cx from 'classnames';
import * as React from 'react';
import {
  DraggableProvidedDragHandleProps,
  DragDropContext,
  Draggable,
  Droppable,
} from 'react-beautiful-dnd';

import { DragAnswerIcon } from '../../icons/DragAnswerIcon';
import { reorder } from '../../utils/drag-drop';

import styles from './DraggableItems.pcss';

export interface DragHandleProps extends DraggableProvidedDragHandleProps {
  className?: string;
}

type Props<T> = {
  onDragEnd: (newItems: T[]) => void,
  items: T[],
  dataTest?: string,
  idGetter?: (item: T) => string,
  renderItem: (item: T, index: number) => JSX.Element;
  draggableContainerClass?: string;
  renderHandle?: (props: DragHandleProps, item: T) => JSX.Element;
}

export const DraggableItems =  <T extends { id?: string }>({
  items, 
  onDragEnd,
  dataTest,
  idGetter = (item) => item.id,
  renderItem,
  renderHandle: customRenderHandle,
  draggableContainerClass
}: Props<T>) => {
  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const newItems = reorder(items, result.source.index, result.destination.index);
    onDragEnd(newItems);
  }

  const renderHandle = (props: DraggableProvidedDragHandleProps, item) => {
    if (typeof customRenderHandle === 'function') {
      return customRenderHandle({ ...props, className: styles.dragHandle }, item);
    }

    return <div {...props} className={styles.dragHandle}>
      <DragAnswerIcon/>
    </div>;
  }

  const renderDroppableItem = (item, index) => {
    const id = idGetter(item);

    return (
      <Draggable key={id} draggableId={id} index={index}>
        {(providedDraggable) => (
          <div
            ref={providedDraggable.innerRef}
            {...providedDraggable.draggableProps}
            key={id}
            id={id}
            className={cx(styles.draggableContainer, draggableContainerClass)}
          >
            {renderHandle(providedDraggable.dragHandleProps, item)}
            {renderItem(item, index)}
          </div>
        )}
      </Draggable>
    );
  }

  return (
    <DragDropContext data-test={dataTest} onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable-answers">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {
              items?.map(renderDroppableItem)
            }
            <div className={styles.placeholder}>
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
