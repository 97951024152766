import * as React from 'react';
import { Handle, Position } from 'reactflow';
import cn from 'classnames';
import _ from 'lodash';

import { BlockQuestion } from '../model';
import { BlockQuestionComponent } from '../components/BlockQuestionComponent';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { SurveyContext, SurveyContextType } from '../SurveyCreator';

import styles from './ModuleNode.pcss';

// Keep in sync with moduleContainer class sizing from ModuleNode.pcss
const nodeWidth = 340;
const horizontalPadding = 12;
export const visualEditorModuleWidth = nodeWidth + 2 * horizontalPadding;

// Values below are some rough estimates to make the auto layouting algorithm work
const questionHeight = 90; // for 2 lines long question name
const horizontalModulePadding = 8;
export const visualEditorQuestionHeight =
  questionHeight + horizontalModulePadding; // estimation
export const moduleTitleHeight = 40;
export const visualEditorModulePadding = 16 * 2 + moduleTitleHeight;

export function ModuleNode({
  data,
}: {
  data: {
    id: string;
    label: string;
    questions: BlockQuestion[];
    setSelectedQuestionId: (question: string) => void;
  };
}) {
  const { questions } = React.useContext<SurveyContextType>(SurveyContext);

  return (
    <>
      <div className={cn('react-flow__node-default', styles.moduleContainer)}>
        <Handle type="target" position={Position.Left} id={data.id} />
        <span className={styles.moduleTitle}>{data.label}</span>
        <div>
          {/* 
            DragDropContext is meant to use here to enable drag and drop functionality to reorder steps in modules.
            It's is not yet implemented yet, however it's a good starting point to enabled it here and verify how it cooperates with reactflow functionalities. 
          */}
          <DragDropContext data-test="drag-drop-container" onDragEnd={_.noop}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {data.questions.map((question, index) => {
                    return (
                      <div key={question.id} className={styles.blockContainer}>
                        <div
                          onClick={() => {
                            data.setSelectedQuestionId(question.id);
                          }}
                        >
                          <BlockQuestionComponent
                            blockId={data.id}
                            scope={'block'}
                            question={question}
                            key={question.id}
                            index={index}
                            selected={
                              questions.current &&
                              questions.current.id === question.id
                            }
                            disabledDrag={true}
                          />
                        </div>
                        <Handle
                          type="source"
                          position={Position.Right}
                          id={question.id}
                          className={styles.handle}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </>
  );
}
