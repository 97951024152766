import * as React from 'react';

import styles from './StatisticTile.pcss';

type Props = {
    title: string;
    value: string | number;
};

export const StatisticTile = (props: Props) => (
    <div className={styles.statisticTile} data-test={'statistic-tile'}>
        <div className={styles.title} data-test="statistic-title">{props.title}</div>
        <div className={styles.value} data-test="statistic-value">{props.value}</div>
    </div>
);
