import * as React from 'react';
import {useState} from 'react';
import {FormattedMessage} from 'react-intl';

import {ConfirmationModal} from '../../../components/ConfirmationModal/ConfirmationModal';
import {DeleteIcon} from '../../../icons';

import {MenuOption} from './OptionsMenu';

type DeleteOptionProps = {
    onDelete: (event: any) => void,
    tooltip?: string,
    alignToTop?: boolean
}

export const DeleteOption: React.FC<DeleteOptionProps> = ({
    onDelete,
    alignToTop,
    tooltip = 'menuOption.delete.tooltip'
}) => {
    const [showModal, toggleModal] = useState<boolean>(false);
    return (
        <>
                <MenuOption dataTest="delete-option" onClick={() => toggleModal(true)} icon={DeleteIcon}
                            hoverType={'warning'} alignToTop={alignToTop}  tooltipLabelId={tooltip} />
            {
                showModal && (
                    <ConfirmationModal
                        onConfirm={(event) => {
                            onDelete(event);
                            toggleModal(false);
                        }}
                        onCancel={() => toggleModal(false)}
                        renderText={() => <FormattedMessage id={'pillRemoveModal.text'}/>}
                    />
                )
            }
        </>
    );
}
