import _ from 'lodash';
import * as React from 'react';
import { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { produce } from 'immer';

import { HorizontalTabsLayout } from '../../components/layouts/HorizontalTabsLayout/HorizontalTabsLayout';
import { IndentedStepContent } from '../../components/layouts/IndentedStepContent/IndentedStepContent';
import { Scrollbar } from '../../components/Scrollbar/Scrollbar';
import {
  BlockQuestion,
  ConditionalMessage,
  ConditionalQuestion,
  Message,
  QuestionType,
} from '../model';
import { SurveyContext, SurveyContextType } from '../SurveyCreator';

import { AssociatedAnswers } from './AssociatedAnswers/AssociatedAnswers';
import { ConditionalQuestionForm } from './conditional/ConditionalQuestionForm';
import { questionComponentsFactory } from './factory/question-component-factory';
import { ComponentPart } from './factory/question-component-factory-model';
import { StepTabType } from './factory/types';
import { NameInput } from './NameInput';
import styles from './StepDetail.pcss';
import { StepHeader } from './StepHeader';
import { useUnsavedChanges } from './useUnsavedChanges';
import { useCreatorRouter } from '../useCreatorRouter';

enum StepDetailInnerTabs {
  Primary = 'primary',
  Associated = 'associated'
}

// tslint:disable-next-line:no-empty
export const MessageContext = React.createContext({ onConditionalMessageChange: (changedMessage: Partial<ConditionalMessage>, path?: string) => {} });

type Props = { question: BlockQuestion, close: any, onQuestionUpdated: (question: BlockQuestion) => void };

export const StepDetail = ({ question, close, onQuestionUpdated }: Props) => {
  const intl = useIntl();
  const { questions, variables} = useContext<SurveyContextType>(SurveyContext);
  const {withError, resetValidation} = questions
  const [questionState, setQuestion] = useState<BlockQuestion>(question);
  const noChanges = _.isEqual(questionState, question);
  const isNewQuestion = _.get(questions.new, 'id') === questionState.id;
  const changesToSave = !noChanges || isNewQuestion;
  const {
    containerRef,
    showModal,
    renderUnsavedChangesComponents
  } = useUnsavedChanges(changesToSave, intl.messages['unsavedChangesModal.text'] as string);
  const [activeQuestionTab, setActiveQuestionTab] = useState<StepTabType>(isNewQuestion ? StepTabType.General : StepTabType.Question);
  const [activeInnerTab, setActiveInnerTab] = useState<StepDetailInnerTabs>(StepDetailInnerTabs.Primary);

  useEffect(() => {
    if (question.isDisabled !== questionState.isDisabled) {
      setQuestion(state => ({ ...state, isDisabled: question.isDisabled }));
    }
  }, [question]);

  const isConditionalQuestion = (q): q is ConditionalMessage => !!q.question;

  const onConditionalMessageChange = useCallback((changedMessage: Partial<ConditionalMessage & Message>, path: string = 'message') => {
    setQuestion(produce((draft) => {
      if (isConditionalQuestion(draft)) {
        _.set(draft, `question.${path}.values`, {
          ..._.get(draft, `question.${path}.values`),
          ...changedMessage,
        });
      } else {
        _.set(draft, `${path}.values`, {
          ..._.get(draft, `${path}.values`),
          ...changedMessage,
        });
      }
    }));
  }, []);

  const handleClose = (hasChanges: boolean) => () => hasChanges ? showModal() : closeOrRevert();
  const closeOrRevert = () => {
    variables.clearPendingVariables()
    isNewQuestion ? close() : revertChanges();
    resetValidation();
  };
  const save = async () => onQuestionUpdated(questionState);
  const changeName = (value: string) => setQuestion({ ...questionState, name: value });
  const revertChanges = () => setQuestion(question);

  const tabContents = useMemo(() => questionComponentsFactory(questionState.type), [questionState.type]);
  const availableTabs = useMemo(
    () => Object.entries(tabContents)
      .filter(([key]) => [StepTabType.Answer, StepTabType.Question, StepTabType.General, StepTabType.Intents, StepTabType.SpecialCharacters].includes(key as StepTabType))
      .filter(([, content]: [string, ComponentPart<any>]) => !!content({
        question: questionState,
        onChange: setQuestion
      }))
      .map(([key]) => key),
    [tabContents]
  );

  if (_.isEmpty(tabContents)) {
    return null;
  }

  const AnswerTabComponent = tabContents[StepTabType.Answer];
  const QuestionTabComponent = tabContents[StepTabType.Question];
  const GeneralTabComponent = tabContents[StepTabType.General];
  const IntentsTabComponent = tabContents[StepTabType.Intents];
  const SpecialCharactersTabComponent = tabContents[StepTabType.SpecialCharacters];
  const type = questionState.type === QuestionType.CONDITIONAL ? (questionState as ConditionalQuestion).question.type : questionState.type;

  const { activeTab } = useCreatorRouter();
  const handleSetActiveQuestionTab = (tab: StepTabType) => changesToSave ? setActiveQuestionTab(tab) : activeTab.set(tab)
  useEffect(() => {
    const value = (activeTab.value as StepTabType) ?? (isNewQuestion ? StepTabType.General : StepTabType.Question)
    setActiveQuestionTab(availableTabs.includes(value) ? value : StepTabType.General);
  }, [activeTab.value])

  const hasErrorsInQuestionTab = () => {
    const errors = [];
    errors.push(...withError(question.id, 'message'))
    errors.push(...withError(question.id, 'repeatMessages'))
    errors.push(...withError(question.id, 'hour'))
    errors.push(...withError(question.id, 'date'))
    errors.push(...withError(question.id, 'helpNeededMessage'))
    errors.push(...withError(question.id, 'followupMessage'))
    return errors.length > 0;
  };

  const hasErrorsInGeneralTab = () => {
    const errors = [];

    errors.push(...withError(question.id, 'conditions'));

    return errors.length > 0;
  };

  const nameError = withError(question.id, 'name')?.[0]?.error

  const questionLabelId = (tabContents.questionLabelId && tabContents.questionLabelId(type)) || 'step.tabs.question';

  const tabName = intl.formatMessage({id: questionLabelId});

  const tabsErrors = {
    ...(hasErrorsInQuestionTab() && { [StepTabType.Question]: `${intl.formatMessage({id: 'step.tabs.error'}, {tab: tabName})}` }),
    ...(hasErrorsInGeneralTab() && { [StepTabType.General]: intl.formatMessage({ id: 'step.tabs.conditions.error' }) }),
  };

  return (
    // MessageContext was introduced as an optimisation for UI performance issues - More details can be found in TLK-3116
    <MessageContext.Provider value={{ onConditionalMessageChange }}>
      <div data-test="question-detail" key={question.id} className={styles.question} data-question-id={question.id}
        data-question-type={type} ref={containerRef}>
        <StepHeader activeTab={activeQuestionTab} changeTab={handleSetActiveQuestionTab} availableTabs={availableTabs}
          onClose={handleClose(changesToSave)} onSave={save}
          disabledSave={!changesToSave}
          questionLabelId={questionLabelId}
          errors={tabsErrors}
        />

        <div className={styles.content}>
          {activeQuestionTab === StepTabType.Question && QuestionTabComponent && (
              <Scrollbar className={styles.tabScrollbar}>
                <IndentedStepContent>

                <QuestionTabComponent question={questionState} onChange={setQuestion} />
                </IndentedStepContent>
              </Scrollbar>
          )}
          {activeQuestionTab === StepTabType.Answer && AnswerTabComponent && (
            <>
              <div className={styles.innerTabs}>
                <HorizontalTabsLayout
                  classes={{
                    root: styles.horizontalTabs,
                    tab: styles.horizontalLayoutTab
                  }}
                  currentTab={activeInnerTab}
                  tabs={[{
                    id: StepDetailInnerTabs.Primary,
                    label: intl.messages['survey-creator.primary-answers'] as string,
                    dataTest: 'primary-tab'
                  }, {
                    id: StepDetailInnerTabs.Associated,
                    label: intl.messages['survey-creator.associated-answers'] as string,
                    dataTest: 'associated-tab'
                  }]}
                  onTabClick={(tabId: StepDetailInnerTabs) => setActiveInnerTab(tabId)}
                  getComponentForTab={() => null}
                  noSeparator
                />
              </div>
                {activeInnerTab === StepDetailInnerTabs.Primary && (
                    <AnswerTabComponent question={questionState} onChange={setQuestion} />
                )}
                {activeInnerTab === StepDetailInnerTabs.Associated && (
                  <AssociatedAnswers question={questionState} onChange={setQuestion} />
                )}
            </>
          )}
          {activeQuestionTab === StepTabType.General && (
            <Scrollbar className={styles.tabScrollbar}>
              <IndentedStepContent>
                <NameInput 
                  name={questionState.name || ''} 
                  dataTest="step-details-name" 
                  onChange={changeName}
                  key={question.name} 
                  error={nameError && `step.name.error.${nameError}`}
                />
                <ConditionalQuestionForm question={questionState} onChange={setQuestion} />
                {GeneralTabComponent && <GeneralTabComponent question={questionState} onChange={setQuestion} />}
              </IndentedStepContent>
            </Scrollbar>
          )}
          {activeQuestionTab === StepTabType.Intents && <IntentsTabComponent question={questionState} onChange={setQuestion} />}
          {activeQuestionTab === StepTabType.SpecialCharacters && <SpecialCharactersTabComponent question={questionState} onChange={setQuestion} />}
        </div>
        {renderUnsavedChangesComponents(() => closeOrRevert())}
      </div>
    </MessageContext.Provider>
  );
};
