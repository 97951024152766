import range from 'lodash/range'
import uniq from 'lodash/uniq'
import React, { useCallback, useState } from 'react';

export type WithSelectableRowsProps = {
  onSelectAllRows: (totalRows: number) => void;
  getAllSelected: (totalRows: number) => boolean;
  onSelectRow: (n: number) => void;
  onDeselectRow: (n: number) => void;
  onDeselectAll: () => void;
  selectedRows: number[];
}

export const withSelectableRows =
  <P extends object>(Component: React.ComponentType<P>):
    React.ComponentType<P & WithSelectableRowsProps> =>
    ({ ...props }) => {
      const [selectedRows, setSelectedRows] = useState<number[]>([]);
      const onDeselectRow = useCallback((n: number) => {
        setSelectedRows(selectedRows.filter(item => item !== n));
      }, [selectedRows, setSelectedRows]);
      const onSelectRow = useCallback((n: number) => {
        if (selectedRows.includes(n)) {
          onDeselectRow(n);
        } else {
          setSelectedRows(uniq([
            ...selectedRows,
            n
          ]));
        }
      }, [selectedRows, setSelectedRows]);


      const getAllSelected = useCallback((totalRows: number) => selectedRows.length === totalRows, [
        selectedRows
      ]);

      const onSelectAllRows = useCallback((totalRows: number) => {
        if (selectedRows.length === totalRows) {
          setSelectedRows([]);
        } else {
          setSelectedRows(range(0, totalRows));
        }
      }, [selectedRows, setSelectedRows]);

      const onDeselectAll = useCallback(() => setSelectedRows([]), [setSelectedRows]);

      return <Component
        {...props}
        onSelectRow={onSelectRow}
        onDeselectRow={onDeselectRow}
        onSelectAllRows={onSelectAllRows}
        selectedRows={selectedRows}
        getAllSelected={getAllSelected}
        onDeselectAll={onDeselectAll}
      />
    }


export type WithTrainingModalProps = {
  isTrainingModalOpen?: boolean;
  onOpenTrainingModal: () => void;
  onCloseTrainingModal: () => void;
}

export const withTrainingModal =
  <P extends object>(Component: React.ComponentType<P>):
    React.ComponentType<P & WithSelectableRowsProps> =>
    ({ ...props }) => {
      const [isOpen, setOpen] = useState(false);
      const [text, setText] = useState('test');
      const [selectedAnswerId, setSelectedAnswerId] = useState('');

      const onOpenTrainingModal = useCallback(() => setOpen(true), [setOpen]);
      const onCloseTrainingModal = useCallback(() => setOpen(false), [setOpen]);

      return <Component
        {...props}
        isTrainingModalOpen={isOpen}
        onOpenTrainingModal={onOpenTrainingModal}
        onCloseTrainingModal={onCloseTrainingModal}
      />;
    }