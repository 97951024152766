import React from 'react';

import {IconProps} from './iconTypes';

export function AddAudioIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H24V24H0z"/>
                <g className={props.animationClass} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" transform="translate(3 3)">
                    <circle cx="12.938" cy="12.931" r="4.5"/>
                    <path
                        d="M12.938 10.681L12.938 15.181M10.688 12.931L15.188 12.931M6.188 15.194h-4.5c-.622 0-1.125-.503-1.125-1.125V1.694c0-.621.503-1.125 1.125-1.125h7.971c.299 0 .584.12.795.33l2.154 2.154c.211.21.33.497.33.795v2.346"/>
                    <ellipse cx="4.5" cy="9.569" rx="1.688" ry="1.125"/>
                    <path d="M6.188 9.57l-.222-4.448.797-.083C7.54 4.955 8.296 5.33 8.7 6"/>
                </g>
            </g>
        </svg>
    );
}
