import {Reaction, ReactionType} from '../../types';

export const getReactionFromDropdownOption = (id: string): Reaction => {
    if (id === ReactionType.CONTINUE) {
        return { type: ReactionType.CONTINUE };
    }
    return { type: ReactionType.GOTO, moduleId: id };
};

export const specialReactions = {
    continue: ReactionType.CONTINUE,
    unknown: 'unknown',
}
