import classNames from 'classnames';
import _ from 'lodash';
import { default as React, useMemo, useState } from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';

import {Button} from '../components/Button/Button';
import {Input} from '../components/Input';
import {Modal} from '../components/Modal/Modal';
import {SelectComponent} from '../components/Select/Select';
import {LanguageCode, VoiceConfig} from '../language/botLanguage';
import {currentUserRoles} from '../selectors';
import {ToggleWithLabel} from '../surveyCreator/components/ToggleWithLabel';
import {UserRole} from '../utils/CurrentUser';

import styles from './BotAddPopup.pcss';

type Props = {
  organizations: { name: string, id: string }[];
  languages: string[];
  onSave: (request: AddBotRequest) => void;
  onCancel: () => void;
};

export type AddBotRequest = {
  name: string;
  organization: string;
  organizationId?: string;
  visibleForClient: boolean;
  voiceConfig: VoiceConfig;
  anonymized: boolean
  intentSets: IntentSet[]
};

type IntentSet = 'default' | 'medical'
type IntentSetsOption = 'default' | 'default-medical';

function mapIntentSetOptionToIntentSet(option: IntentSetsOption): IntentSet[] {
  if (option === 'default-medical') {
    return ['default', 'medical']
  }

  return ['default']
}

export const BotAddPopup = ({organizations, languages, onSave, onCancel}: Props) => {
  const isAdmin = useSelector(currentUserRoles).includes(UserRole.ADMIN);
  const intl = useIntl();
  const [botName, setBotName] = useState(undefined);
  const [organization, setOrganization] = useState<{ name: string, id: string }>(undefined);
  const [anonymized, toggleAnonymized] = useState(false);
  const [languageCode, setLanguageCode] = useState<LanguageCode>('en-US');
  const [intentSets, setIntentSets] = useState<IntentSetsOption>('default');
  const [visibleForClient, toggleVisibleForClient] = useState(false);

  const languagesMapper = el => ({
    id: el,
    name: intl.messages[`dashboard.addBotPopup.languages.${el}`] as string
  });

  const intentsSetMapper = el => ({
    id: el,
    name: intl.messages[`dashboard.addBotPopup.intentSet.${el}`] as string
  });

  const intentSetsNames = useMemo(() => (['default', 'default-medical'].map(el => intentsSetMapper(el))), []);

  const organizationOptions = _.sortBy(organizations, el => el.name.toLowerCase());
  const languagesOptions = _.chain(languages).map(languagesMapper).sortBy(el => el.name).value();

  const performAddRequest = () => onSave({
    name: botName,
    organization: organization.name,
    organizationId: organization.id !== 'NEW_ORGANIZATION' ? organization.id : undefined,
    voiceConfig: {languageCode},
    visibleForClient: !isAdmin ? true : visibleForClient,
    anonymized,
    intentSets: mapIntentSetOptionToIntentSet(intentSets)
  });

  return (
      <Modal position={'top'} dataTest={'add-bot-popup'}
             className={isAdmin ? styles.modal__admin : styles.modal__notAdmin}>
        <div className={styles.container}>
          <div className={styles.title}>
            <FormattedMessage id={'dashboard.addBotPopup.title'}/>
          </div>
          <div className={styles.body}>
            <div className={styles.bodyRow}>
              <div className={styles.fieldWrapper}>
                <div className={styles.fieldLabel}>
                  <FormattedMessage id={'dashboard.addBotPopup.newBotName'}/>
                </div>
                <Input
                    dataTest="bot-name-input"
                    className={styles.fieldInput}
                    value={botName}
                    onChange={setBotName}
                />
              </div>

              <div className={styles.fieldWrapper}>
                <div className={styles.fieldLabel}>
                  <FormattedMessage id={'dashboard.addBotPopup.newBotLanguage'}/>
                </div>
                <div className={styles.fieldSelect}>
                  <SelectComponent
                      isClearable={false}
                      options={languagesOptions}
                      value={languageCode && languagesMapper(languageCode)}
                      onChange={newValue => setLanguageCode(newValue.id)}
                      data-test={'language-select'}
                      placeholderMessage={'dashboard.addBotPopup.language.placeholder'}
                  />
                </div>
              </div>
            </div>
            <div className={styles.bodyRow}>
              <div className={styles.fieldWrapper}>
                <div className={styles.fieldLabel}>
                  <FormattedMessage id={'dashboard.addBotPopup.client'}/>
                </div>
                <div className={classNames(styles.clientSelect, styles.fieldSelect)}>
                  {
                    isAdmin ? (
                        <SelectComponent
                            isCreatable
                            isClearable={false}
                            options={organizationOptions}
                            value={organization}
                            onCreate={newValue => setOrganization({ name: newValue, id: 'NEW_ORGANIZATION' })}
                            onChange={newValue => setOrganization(newValue)}
                            createOptionMessageId={'dashboard.clonePopup.addNewClient'}
                            data-test={'organization-select'}
                            placeholderMessage={'dashboard.addBotPopup.client.placeholder'}
                        />
                    ) : (
                        <SelectComponent
                            isClearable={false}
                            options={organizationOptions}
                            value={organization}
                            onChange={newValue => setOrganization(newValue)}
                            data-test={'organization-select'}
                            placeholderMessage={'dashboard.addBotPopup.client.placeholder'}
                        />
                    )
                  }
                </div>
              </div>

              <div className={styles.fieldWrapper}>
                <div className={styles.fieldLabel}>
                  <FormattedMessage id={'dashboard.addBotPopup.intentSet'}/>
                </div>
                <div className={styles.fieldSelect}>
                  <SelectComponent
                    isClearable={false}
                    options={intentSetsNames}
                    value={intentSets && intentsSetMapper(intentSets)}
                    onChange={newValue => setIntentSets(newValue.id)}
                    data-test="intent-model-database-select"
                  />
                </div>
              </div>
            </div>
            <div className={styles.togglesRow}>
              {
                isAdmin && (
                    <div className={styles.toggle}>
                      <ToggleWithLabel value={visibleForClient}
                                       onToggle={() => toggleVisibleForClient(!visibleForClient)}
                                       labelId={'dashboard.addBotPopup.visibleForClient'}
                                       dataTest={'visible-for-client-toggle'}
                      />
                    </div>
                )}
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={classNames(styles.buttons, isAdmin ? undefined : styles.buttons__notAdmin)}>
              <Button buttonType="normal" onClick={onCancel} translateText="dashboard.addBotPopup.cancel"
                      dataTest={'cancel-add-bot-button'}/>
              <Button
                  onClick={performAddRequest}
                  dataTest={'send-add-bot-request-button'}
                  translateText="dashboard.addBotPopup.save"
              />
            </div>
          </div>
        </div>
      </Modal>
  );
};
