import 'reactflow/dist/style.css';
import * as React from 'react';
import { VisualEditor } from './VisualEditor';
import { SurveyContext, SurveyContextType } from '../SurveyCreator';
import { ReactFlowProvider } from 'reactflow';
import { Loader } from '../../components/Loader';

export function VisualEditorContainer() {
  const { blocks } = React.useContext<SurveyContextType>(SurveyContext);
  const modules = blocks.get();

  return (
    <>
      {modules.length > 0 ? (
        <ReactFlowProvider>
          <VisualEditor modules={modules} savePosition={blocks.savePosition} />
        </ReactFlowProvider>
      ) : (
        <Loader />
      )}
    </>
  );
}
