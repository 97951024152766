import React from 'react';
import { useIntl } from 'react-intl';

import { AnswerComponentProps } from '../../../../components/AnswersList/AnswersList';
import { DefaultAnswerComponent } from '../../../../components/AnswersList/DefaultAnswerComponent';

import { SpecialCharacterDefinition } from './SpecialCharacterDefinition';
import { mapGlobalWordToCharacter, SpecialCharacter } from './SpecialCharacters.hooks';

export const SpecialCharactersAnswerComponent: React.FC<AnswerComponentProps> = (props) => {
  const intl = useIntl();
  return <DefaultAnswerComponent {...props}>
    <SpecialCharacterDefinition
      character={mapGlobalWordToCharacter(props.answer.key as SpecialCharacter, intl)}
    />
  </DefaultAnswerComponent>
}