import * as React from 'react';
import _ from 'lodash';
import styles from './StatisticsSummarySection.pcss';
import { StatisticsPlusIcon } from '../icons/statistics/StatisticsPlusIcon';
import { StatisticsSeparatorIcon } from '../icons/statistics/StatisticsSeparatorIcon';
import { StatisticsSectionItem } from './StatisticsSectionItem';

type Metric = {
  name: string;
  value: number;
};

type MetricWithPercentage = Metric & { percentage: number };

type Props = {
  metrics: Metric[];
  withPercentage?: boolean;
  withSummary?: boolean;
  dataTest?: string;
};

function calculateMetricPercentage(metrics: Metric[]): MetricWithPercentage[] {
  const metricSum = metrics.reduce((acc, metric) => acc + metric.value, 0);

  let metricPercentageSum = 100;
  const metricPercentage = new Map<string, number>();

  // calculate metric percentage value and floor it
  for (const metric of metrics) {
    const floorValue = Math.floor((metric.value / metricSum) * 100);
    metricPercentage.set(metric.name, floorValue);
    metricPercentageSum -= floorValue;
  }

  // sort metrics descdending by fractional part of percnetage value
  const metricsByDescendingFractionalPartOfPercentage = _.cloneDeep(metrics);
  metricsByDescendingFractionalPartOfPercentage.sort(function (
    a: { value: number },
    b: { value: number }
  ) {
    const diff =
      (((a.value / metricSum) * 100) % 1) - (((b.value / metricSum) * 100) % 1);
    return -Math.sign(diff);
  });

  // increment remaining percentage value for metric according to previously computed order
  for (const metric of metricsByDescendingFractionalPartOfPercentage) {
    if (metricPercentageSum <= 0) {
      break;
    }

    metricPercentage.set(metric.name, metricPercentage.get(metric.name) + 1);
    metricPercentageSum -= 1;
  }

  return metrics.map((metric) => ({
    ...metric,
    percentage: metricPercentage.get(metric.name),
  }));
}

export function StatisticsSummarySection({
  metrics,
  withSummary = false,
  withPercentage = false,
}: Props) {
  const metricsToDisplay = metrics.filter(({ value }) => value > 0);
  const metricsWithPercentage = calculateMetricPercentage(metricsToDisplay);

  const allCalls = metricsToDisplay
    .map(({ value }) => value)
    .reduce((acc, cur) => acc + cur, 0);

  return (
    <>
      {metricsWithPercentage.map((metric, index) => (
        <React.Fragment key={metric.name}>
          {index > 0 && <StatisticsPlusIcon animationClass={styles.plus} />}
          <StatisticsSectionItem
            metric={metric}
            postfix={withPercentage ? `${metric.percentage}%` : ''}
          />
        </React.Fragment>
      ))}
      {withSummary && (
        <React.Fragment key="allCalls">
          {metricsToDisplay.length > 0 && (
            <StatisticsSeparatorIcon animationClass={styles.separator} />
          )}
          <StatisticsSectionItem
            metric={{ name: 'allCalls', value: allCalls }}
            postfix={withPercentage && allCalls > 0 ? '100%' : ''}
            isSummary
          />
        </React.Fragment>
      )}
    </>
  );
}
