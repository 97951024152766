import React from 'react';

import styles from './icons.pcss';

export function ArrowUpIcon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6">
        <path className={styles.iconFillColor} fillRule="nonzero" d="M5 1.809l3.626 3.936a.798.798 0 0 0 1.115.053.77.77 0 0 0 .054-1.099L5.783.344a1.064 1.064 0 0 0-1.566 0L.205 4.7a.77.77 0 0 0 .054 1.1.798.798 0 0 0 1.115-.054L5 1.809z"/>
      </svg>
    );
}
