import * as React from 'react';
import { useIntl } from 'react-intl';

import { SelectComponent, Value } from '../../components/Select/Select';
import {DontReadIcon} from '../../icons/DontReadIcon';
import {InOrderIcon} from '../../icons/InOrderIcon';
import {RandomIcon} from '../../icons/RandomIcon';
import {ToBottomIcon} from '../../icons/ToBottomIcon';
import {ToTopIcon} from '../../icons/ToTopIcon';
import {DefaultReadAnswerType} from '../model';

import styles from './ReadOption.pcss';

const readTypes = ['SEQUENCE', 'PERMUTATE', 'FIRST', 'LAST', 'DONT_READ'];

type ReadOptionProps = { onChange: (readOption: Value) => void; value: string, defaultReadType: DefaultReadAnswerType };

const icons = {
    SEQUENCE: InOrderIcon,
    PERMUTATE: RandomIcon,
    FIRST: ToTopIcon,
    LAST: ToBottomIcon,
    DONT_READ: DontReadIcon,
}

export const ReadOption = (props: ReadOptionProps) => {
    const intl = useIntl();
    const toSelectType = (value: string) => ({
        id: value,
        name: intl.messages[`survey-creator.readOption${value}`] as string,
        icon: icons[value]
    });

    const getValue = (value: string) => !value ? 'DONT_READ' : value

    const options = readTypes.filter(el => (el !== 'SEQUENCE' && el !== 'PERMUTATE') || (el === props.defaultReadType))

    return (
        <div data-test={'read-type'} className={styles.container}>
            <div className={styles.select}>
                <SelectComponent
                    onChange={props.onChange}
                    options={options.map(toSelectType)}
                    value={toSelectType(getValue(props.value))}
                    type={'nonBordered'}
                />
            </div>
        </div>
    );
};
