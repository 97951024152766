import _ from 'lodash';
import * as React from 'react';
import { useIntl } from 'react-intl';

import { DeleteIcon } from '../../../../icons';
import { ToLearnMessage } from '../../../../inspector/Inspector';
import { Bot } from '../../../../surveyCreator/model';
import { StateBotProps } from '../../../../utils/CurrentBot';
import { HttpClient } from '../../../../utils/HttpClient';
import { IconButton } from '../../../IconButton/IconButton';

type Props = {
    currentBot: Bot;
    message: ToLearnMessage;
    onMark: (id: string) => void;
};

const DoneButtonComponent: React.FC<Props & StateBotProps> = ({
    currentBot,
    onMark,
    message
}) => {
    const intl = useIntl();
    const markDone = async () => {
        await HttpClient.post({
            path: `/bots/${currentBot.id}/to-learn-messages/done`,
            body: {
                messageIds: [message.id]
            }
        });
        await onMark(message.id);
    };
    return <IconButton
        data-test="done-button"
        onClick={markDone}
        Icon={DeleteIcon}
        title={intl.messages['inspector.actions.remove'] as string}
    />
}

export const DoneButton = DoneButtonComponent;
