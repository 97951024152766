import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Box } from '../../../../components/Box/Box'
import { Tag } from '../../../../components/Tag/Tag';

import styles from './SpecialCharacters.pcss';

export type SpecialCharacterDefinitionProps = {
  character: string;
}

export const SpecialCharacterDefinition: React.FC<SpecialCharacterDefinitionProps> = ({
  character
}) => <Box
dataTest="special-character-definition"
 className={styles.definition}
 >
    <FormattedMessage
      id={'survey-creator.specialCharacters.specialCharacterDefinition'}
      values={{
        character: <Tag className={styles.character}>{character}</Tag>
      }}
    />
  </Box>