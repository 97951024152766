import React from 'react';
import cx from 'classnames';

import styles from './icons.pcss';

export function ConversationsIcon(props: {animationClass?: string}) {
    return <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h18v18H0z"/>
            <path className={cx(styles.iconFillColor, props.animationClass)} d="M13.5 5.587a4.537 4.537 0 0 1 4.538 4.538 4.528 4.528 0 0 1-2.25 3.906v3.406a.6.6 0 0 1-1.025.425l-3.199-3.2h-.314a4.537 4.537 0 0 1 0-9.075h2.25zm-2.25 1.2a3.338 3.338 0 0 0 0 6.676h.563a.6.6 0 0 1 .424.175l2.35 2.351v-2.32a.6.6 0 0 1 .344-.542 3.328 3.328 0 0 0 .93-5.362 3.337 3.337 0 0 0-2.361-.978h-2.25zM6.75-.038a4.54 4.54 0 0 1 4.35 3.241.6.6 0 1 1-1.15.344 3.34 3.34 0 0 0-3.2-2.385H4.499a3.33 3.33 0 0 0-1.43 6.34.6.6 0 0 1 .343.542v2.32l1.226-1.226a.6.6 0 0 1 .849.849l-2.25 2.25a.6.6 0 0 1-1.025-.425V8.406A4.53 4.53 0 0 1 4.5-.038h2.25z"/>
        </g>
    </svg>;
}
