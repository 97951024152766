import React from 'react';
import { IconProps } from './iconTypes';

export function EntityIcon(props: IconProps) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <defs>
      <filter id="04wj14hiha">
        <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g filter="url(#04wj14hiha)" transform="translate(-1258 -448) translate(1180 430) translate(76 16) translate(2 2)">
              <path d="M0 0H20V20H0z" />
              <path fill="#000" d="M13.185 4.48C12.905 3.079 11.595 2 10 2 8.404 2 7.094 3.078 6.815 4.48H4.628C3.716 4.48 3 5.19 3 6.032V16.45c0 .842.716 1.55 1.628 1.55h10.744c.912 0 1.628-.708 1.628-1.55V6.03c0-.842-.716-1.55-1.628-1.55h-2.187zm-1.052.559c0 .308.247.558.553.558h2.686c.275 0 .521.18.521.434V16.45c0 .254-.246.434-.52.434H4.627c-.275 0-.521-.18-.521-.434V6.03c0-.254.246-.434.52-.434h2.687c.306 0 .553-.25.553-.558 0-1.076.968-1.923 2.133-1.923s2.133.847 2.133 1.923zm-5.085 9.488h5.904c.305 0 .553-.25.553-.558 0-.308-.248-.558-.553-.558H7.048c-.305 0-.553.25-.553.558 0 .308.248.558.553.558zm0-2.48h5.904c.305 0 .553-.25.553-.559 0-.308-.248-.558-.553-.558H7.048c-.305 0-.553.25-.553.558 0 .308.248.559.553.559zm0-2.481h5.904c.305 0 .553-.25.553-.558 0-.308-.248-.558-.553-.558H7.048c-.305 0-.553.25-.553.558 0 .308.248.558.553.558zm2.993-5.442H10c-.441 0-.8.36-.8.806 0 .445.359.806.8.806.441 0 .8-.361.8-.806 0-.432-.336-.784-.759-.806z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>;
};
