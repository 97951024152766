import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { SurveyCreator } from '../actionTypes';

export const fetchEntities = createAsyncAction(
  SurveyCreator.FETCH_ENTITIES,
  SurveyCreator.FETCH_ENTITIES_SUCCESS,
  SurveyCreator.FETCH_ENTITIES_FAILURE
)<{id: string}, string[], string>();

export const updateEntities = createStandardAction(
  SurveyCreator.UPDATE_ENTITIES
)<string[]>();

export const updatePendingEntity = createStandardAction(
  SurveyCreator.UPDATE_PENDING_ENTITY
)<string>();