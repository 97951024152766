import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';

import { Modal } from '../../components/Modal/Modal';
import { ModalContent } from '../../components/ModalContent/ModalContent';
import { MarkdownContent } from '../../components/MarkdownContent/MarkdownContent';
import { Row } from '../../components/Row/Row';
import { Button } from '../../components/Button/Button';
import EmailMultiSelect from '../../components/EmailMultiSelect/EmailMultiSelect';
import { PasswordInput } from '../../components/PasswordInput/PasswordInput';
import { Loader } from '../../components/Loader';

import styles from './ReportConfigModal.pcss';
import { HorizontalSeparator } from '../../components/HorizontalSeparator/HorizontalSeparator';
import { Toggle } from '../../components/Toggle/Toggle';
import { Label, TextLabel } from '../../components/Label';

export type ReportConfigForm = {
  emails: string[];
  password: string;
  notificationEnabled: boolean;
};

interface ReportConfigProps {
  onClose: () => void;
  data?: ReportConfigForm;
  isLoading?: boolean;
  onSubmit: (values: ReportConfigForm) => void;
  report: string;
}

const validationSchema = yup.object().shape({
  emails: yup.array().of(yup.string().email().required()).required(),
  password: yup
    .string()
    .matches(
      /^[^ĄĆĘŁŃÓŚŹŻąćęłńóśźż]+$/,
      'password must contain only latin characters'
    )
    .notRequired(),
  notificationEnabled: yup.boolean().required(),
});

export const ReportConfigModal = ({
  onClose,
  data,
  onSubmit,
  isLoading,
  report,
}: ReportConfigProps) => {
  const intl = useIntl();

  return (
    <Modal
      modalSize="medium"
      dataTest="report-config-modal"
      noPadding
      noFixedHeight
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Formik<ReportConfigForm>
          initialValues={{
            emails: data?.emails ?? [],
            password: data?.password ?? '',
            notificationEnabled: data?.notificationEnabled ?? false,
          }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({ values, errors, setFieldValue, submitForm, isSubmitting }) => (
            <ModalContent
              headerContent={
                <MarkdownContent
                  className={styles.header}
                  contentKey={'reportConfig.modal.title'}
                  extraValues={{ report }}
                />
              }
              footerContent={
                <Row>
                  <Button
                    onClick={onClose}
                    data-test="report-config-cancel-button"
                    buttonType="normal"
                    translateText="cancelLabel"
                  />
                  <Button
                    data-test="report-config-save-button"
                    className={styles.saveButton}
                    onClick={submitForm}
                    type="submit"
                    buttonType={isSubmitting ? 'loading' : 'primary'}
                    translateText={
                      isSubmitting ? undefined : 'reportConfig.modal.save'
                    }
                  />
                </Row>
              }
            >
              <MarkdownContent
                className={styles.title}
                contentKey={'reportConfig.modal.scheduledReports.title'}
              />
              <MarkdownContent
                className={styles.text}
                contentKey={'reportConfig.modal.scheduledReports.text'}
              />
              <EmailMultiSelect
                emails={values.emails}
                onChange={(value) => setFieldValue('emails', value)}
                showErrorMessage={!!errors.emails}
                classNames={styles.spaceBottom}
              />
              <TextLabel
                label={intl.formatMessage({
                  id: 'reportConfig.modal.scheduledReports.passwordLabel',
                })}
              />
              <PasswordInput
                value={values.password}
                onChange={(value) => setFieldValue('password', value)}
                error={
                  errors.password &&
                  (intl.messages[
                    'reportConfig.modal.scheduledReports.passwordValidation'
                  ] as string)
                }
              />
              <HorizontalSeparator />
              <MarkdownContent
                className={styles.title}
                contentKey={'reportConfig.modal.notifications.title'}
              />
              <MarkdownContent
                className={styles.text}
                contentKey={'reportConfig.modal.notifications.text'}
              />
              <div 
                className={styles.toggleContainer}
                data-test="notification-toggle-wrapper"
              >
                <Toggle
                  className={styles.toggle}
                  on={values.notificationEnabled}
                  onToggle={() =>
                    setFieldValue(
                      'notificationEnabled',
                      !values.notificationEnabled
                    )
                  }
                />
                <Label labelId="reportConfig.modal.notifications.toggleText" />
              </div>
            </ModalContent>
          )}
        </Formik>
      )}
    </Modal>
  );
};
