import React from 'react';

import {IconProps} from './iconTypes';

export function YesNoStepIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H24V24H0z"/>
                <path className={props.animationClass} d="M11.998 6.15c1.518 0 2.758 1.186 2.845 2.683l.005.167-.001 7.65h2.809c2.565.125 4.593 2.192 4.69 4.731l.004.219v1.653c0 .331-.269.6-.6.6-.298 0-.546-.218-.592-.503l-.008-.097v-1.656c.01-1.932-1.453-3.54-3.328-3.732l-.195-.015h-3.379c-.298 0-.546-.218-.592-.503l-.008-.097V9c0-.911-.739-1.65-1.65-1.65-.863 0-1.572.663-1.644 1.508L10.348 9v11.245c0 .559-.682.798-1.037.413l-.062-.08-1.8-2.7c-.497-.748-1.497-.966-2.26-.495-.718.444-.983 1.349-.64 2.103l.072.14 1.9 3.325c.164.288.064.655-.223.819-.26.148-.582.082-.764-.143l-.055-.08-1.9-3.326c-.767-1.34-.334-3.047.98-3.858 1.26-.78 2.897-.464 3.782.7l.106.15.701 1.051V9c0-1.518 1.186-2.758 2.683-2.845l.167-.005zM7.848.26c.243.174.318.495.19.754l-.05.084-3.75 5.25c-.196.274-.573.329-.837.14l-.075-.065-3-3C.09 3.19.09 2.81.326 2.575c.208-.209.531-.232.765-.07l.083.07 2.499 2.498L7.012.4c.192-.27.567-.332.837-.14zM18.342.256l.083.07 2.201 2.2 2.2-2.2c.235-.235.615-.235.85 0 .208.208.23.531.069.765l-.07.083-2.2 2.201 2.2 2.2c.235.235.235.615 0 .85-.208.208-.531.23-.765.069l-.083-.07-2.201-2.2-2.2 2.2c-.235.235-.615.235-.85 0-.208-.208-.23-.531-.069-.765l.07-.083 2.2-2.201-2.2-2.2c-.235-.235-.235-.615 0-.85.208-.208.531-.23.765-.069z"/>
            </g>
        </svg>
    );
}
