import * as React from 'react';

export function useIntervalUpdater({ interval = 1000 }: { interval?: number } = {}) {
    const [, setValue] = React.useState<Record<string, never>>({});
  
    React.useEffect(() => {
      const currentInterval = setInterval(() => {
        setValue({});
      }, interval);
  
      return () => clearInterval(currentInterval);
    });
  }
  