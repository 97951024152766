import * as React from 'react';
import { NoContacts } from './NoContacts';
import { NoContactsForFilters } from './NoContactsForFilters';
import { NoContactsNoSchedule } from './NoContactsNoSchedule';

type Props = {
  hasFilters: boolean;
  hasSchedule: boolean;
  openAddContactsModal: () => void;
};

export function ContactEmptyState({ hasFilters, hasSchedule, openAddContactsModal }: Props) {
  if (!hasSchedule && !hasFilters) {
    return <NoContactsNoSchedule />;
  }

  if (hasFilters) {
    return <NoContactsForFilters />;
  }

  return <NoContacts {...{ openAddContactsModal }} />;
}
