import React, { useCallback } from 'react';
import { v4 } from 'uuid';

import { DraggableItems } from '../../../components/DraggableItems/DraggableItems';
import { Loader } from '../../../components/Loader';
import { Scrollbar } from '../../../components/Scrollbar/Scrollbar';
import { AssociatedAnswer, BlockQuestion, isConditionalStep } from '../../model';
import { AddItemBar } from '../AddItemBar/AddItemBar';
import { AddItemModal } from '../AddItemModal/AddItemModal';
import {AssociatedAnswerComponent} from '../AssociatedAnswer/AssociatedAnswerComponent';
import {useEntitiesWithSystemAndNerEntities} from '../EntityDetails/hooks/useEntitiesWithSystemAndNerEntities';

import styles from './AssociatedAnswers.pcss'

type AssociatedAnswersProps = { question: BlockQuestion, onChange: (question: BlockQuestion) => void }

export const AssociatedAnswers = ({ question, onChange }: AssociatedAnswersProps) => {
  const { isLoading, isReady, entities, systemAndNerEntitiesKeys } = useEntitiesWithSystemAndNerEntities();

  const updateAssociatedAnswers = useCallback((newAssociatedAnswers: AssociatedAnswer[]) => {
    if (isConditionalStep(question)) {
      onChange({
        ...question,
        question: {
          ...question.question,
          associatedAnswers: newAssociatedAnswers
        }
      })
    } else {
      onChange({
        ...question,
        associatedAnswers: newAssociatedAnswers
      })
    }
  }, [question, onChange]);

  const associatedAnswers = (isConditionalStep(question)
    ? question.question.associatedAnswers
    : question.associatedAnswers);

  return (
    <div className={styles.root} data-test={'associated-answers'}>
      { (isLoading || !isReady) && <Loader />}
      {
        !(isLoading || !isReady) &&
        <AddItemModal
          placeholderLabelId="survey-creator.addNewEntityPlaceholderLabel"
          addLabelId="survey-creator.addNewEntityLabel"
          dataTest="add-entity-modal"
          items={entities}
          onSelect={(item) => updateAssociatedAnswers([
            ...(associatedAnswers ?? []),
            { id: v4(), entity: item, reaction: 'no_reaction' }
          ]
          )}
          renderTrigger={({ open }) => <AddItemBar
            dataTest="add-entity-button"
            className={styles.addItemBar}
            addButtonLabelId="survey-creator.entities"
            descriptionId="survey-creator.addEntitiesDescription"
            onClick={open}
          />}
          forbidAddingItems
        />
      }
      <div className={styles.associatedAnswers}>
        <Scrollbar>
          <DraggableItems<AssociatedAnswer>
            onDragEnd={updateAssociatedAnswers}
            items={associatedAnswers}
            renderItem={(associatedAnswer) =>
              <AssociatedAnswerComponent
                key={associatedAnswer.id}
                associatedAnswer={associatedAnswer}
                onDelete={(id: string) => updateAssociatedAnswers(
                  associatedAnswers.filter(
                    answer => answer.id !== id
                  )
                )}
                onChange={(newAssociatedAnswer: AssociatedAnswer) => updateAssociatedAnswers(
                  associatedAnswers.map(
                    answer => answer.id === newAssociatedAnswer.id ? newAssociatedAnswer : answer
                  )
                )}
                systemAndNerEntitiesKeys={systemAndNerEntitiesKeys}
              />
            }
          />
        </Scrollbar>
      </div>
    </div>
  )
}