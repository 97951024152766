import { useContext, useMemo } from 'react';
import { PaletteItemsHook } from './types';
import { SurveyContext } from '../../surveyCreator/SurveyCreator';
import { getBotUrlId, useCurrentBot } from '../../utils/CurrentBot';
import { useIntl } from 'react-intl';
import { useCreatorRouter } from '../../surveyCreator/useCreatorRouter';
import { linkElementAltAction } from './utils';
import { ModuleIcon } from '../../icons/ModuleIcon';
import React from 'react';

export const useModuleItems: PaletteItemsHook = () => {
  const intl = useIntl();
  const { blocks } = useContext(SurveyContext);
  const { getUrlForBlock } = useCreatorRouter();
  const modules = blocks?.get()?.map((block) => ({
    id: block.id,
    name: block.name,
    href: getUrlForBlock(block.id),
    steps: block.questions
  }));
  const category = intl.formatMessage({ id: `survey-creator.command-palette.categories.modules` });
  const { navigateToBlock } = useCreatorRouter();

  return useMemo(() => modules?.map(module => ({
    id: module.id,
    name: module.name,
    category,
    onAction: () => { navigateToBlock(module.id) },
    onAltAction: () => linkElementAltAction(module.href),
    data: module,
    Icon: ({ className }: { className?: string }) => <ModuleIcon animationClass={className} />,
  })), [blocks]);
}