import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AddItemModal } from '../../../../components/AddItemModal/AddItemModal';
import { AddButton } from '../../../../components/AddButton';

import styles from './AddAnswerModal.pcss';
import { AddAnswerModalProps } from './types';

const AddAnswerModal: React.FC<AddAnswerModalProps> = ({ items, onAdd }) => {
    return <AddItemModal
        addLabelId={'survey-creator.word.addAnswerLabel'}
        dataTest={'new-dictionary-entry'}
        items={items.map(item => ({ key: item, name: item }))}
        onCreate={onAdd}
        onSelect={onAdd}
        renderTrigger={({ open }) => (
            <div className={styles.addRow}>
                <AddButton
                    labelId={'survey-creator.word.addAnswerButton'}
                    dataTest={'add-dictionary-entry-button'}
                    onClick={open}
                />
                <div className={styles.addDescription}>
                    <FormattedMessage id={'survey-creator.word.addAnswerDescription'} />
                </div>
            </div>
        )}
    />
}

export default AddAnswerModal
