import * as React from 'react';
import {useIntl} from 'react-intl';

import {SelectComponent} from '../components/Select/Select';

import styles from './PeriodSelect.pcss';

export type Period = 'day' | 'week' | 'month' | 'year' | 'custom';

type Props = {
  selected?: Period;
  onSelected: (period: Period) => void
};


export const PeriodSelect = (props: Props) => {
  const intl = useIntl();

  const getOption = (period: Period) => {
    return {id: period, name: intl.formatMessage({id: `statistics.period.${period}`})};
  };

  const options = [
    getOption('day'),
    getOption('week'),
    getOption('month'),
    getOption('year'),
  ];
  return (
    <div data-test={'statistics-period-select'} className={styles.container}>
      <SelectComponent
        options={options}
        onChange={selected => props.onSelected(selected.id)}
        value={props.selected ? getOption(props.selected) : undefined}
        isClearable={false}
        type={'nonBordered'}
        placeholderMessage={'statistics.period.placeholder'}
        className={styles.select}
        key={props.selected}
      />
    </div>
  );
};
