import { useEffect, useState } from 'react';
import uniq from 'lodash/uniq';
import pickBy from 'lodash/pickBy';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router';

import { Report, ReportFromAPI, ReportStatus, ReportsResponse } from '../reports/model';
import { HttpClient } from '../utils/HttpClient';
import { BotRouteMatch } from '../utils/CurrentBot';
import { useFilters } from '../filters/useFilters';

export interface ReportsFilters {
  readonly search: string;
  readonly startDate: string | null;
  readonly endDate: string | null;
  readonly status: string | null;
};

const convertReportItemForDisplay = (reportData: ReportFromAPI[]): Report[] => reportData.map(item => {
  const uniqueDataKeys = uniq(item.data.map(Object.keys).flat());
  return ({
    ...item,
    conversationStart: Math.round(item.conversationStart / 1000),
    data: item.data.reduce((acc: Record<string, string[]>, itemData) => {
      const flatData: Record<string, string[]> = acc;
      uniqueDataKeys.forEach((key) => {
        flatData[key] = [...(flatData[key] || []), itemData[key]];
      });
      return flatData;
    }, {}),
  })
});

const encodeReportName = (reportName: string) => encodeURIComponent(reportName)
const decodeReportName = (encodedReportName: string) => decodeURIComponent(encodedReportName)

type BotReportsRouteMatch = BotRouteMatch & {
  reportName?: string;
}

export const useReports = (botId: string) => {
  const history = useHistory();
  const routeParams = useParams<BotReportsRouteMatch>();
  const queryClient = useQueryClient();
  const [selectedReport, setSelectedReport] = useState<string | null>(null);
  const [columns, setColumns] = useState<string[]>([]);

  const getUrlForReport = (reportName: string) => `/${routeParams.orgId}/${routeParams.botId}/reports/${encodeReportName(reportName)}`;

  const { filters, setFilters } = useFilters<ReportsFilters>({ search: '', status: null, startDate: null, endDate: null });

  const navigateToReport = (reportName: string) => history.push(getUrlForReport(reportName));

  // Load the list of reports
  const { isFetching: isLoadingReportList, data: reportList } = useQuery<ReportsResponse>(
    [botId, 'data-collection'],
    () =>
      HttpClient
        .get({ path: `/bots/${botId}/data-collection` })
        .then(res => res.data.items),
    { initialData: [] }
  );
  
  const reportFromCurrentPath = reportList?.find(report => report.name === decodeReportName(routeParams.reportName));
  
  // Navigate to first report after initial load or after report list changes
  useEffect(() => {
    if (!reportList || reportList.length === 0) {
      return;
    }

    if (!routeParams.reportName) {
      navigateToReport(reportList[0].name);
      return;
    }

    if (!reportFromCurrentPath) {
      navigateToReport(reportList[0].name);
    }
  }, [reportList]);

  // Update selected report on route param change
  useEffect(() => {
    if (!routeParams.reportName || !reportList || reportList.length === 0) {
      return;
    }

    if (reportFromCurrentPath) {
      setSelectedReport(reportFromCurrentPath.name);
    }
  }, [routeParams.reportName, reportList]);

  // Load the list of selected report items
  const fetchReportData = (filtersFromQueryKey: ReportsFilters, pageParam?: string) => HttpClient
    .get({
      path: `/bots/${botId}/data-collection/${encodeURIComponent(selectedReport)}`,
      params: { ...(pageParam ? { after: pageParam } : {}), ...pickBy(filtersFromQueryKey) },
    })
    .then(res => ({ ...res.data, items: convertReportItemForDisplay(res.data.items) }));

  const {
    data: selectedReportItems,
    fetchNextPage: loadMoreItems,
    hasNextPage: hasMoreItems,
    isFetching: isLoadingReportItems,
    isFetchingNextPage: isLoadingMoreReportItems,
    refetch,
  } = useInfiniteQuery(
    [botId, 'data-collection', selectedReport, JSON.stringify(filters)],
    ({ pageParam }) => fetchReportData(filters, pageParam),
    {
      getNextPageParam: (lastPage) => lastPage?.hasMore ? lastPage.items[lastPage.items.length - 1]?.id : undefined,
      initialData: () => ({ pageParams: [], pages: [] }),
      enabled: !!botId && !!selectedReport,
    },
  );

  // Update column names after initial load or when report items change
  const updateColumnNames = () => {
    const items = selectedReportItems.pages.map(page => page.items).flat();
    let newColumns = [];
    newColumns.push('phoneNumber');
    items.forEach((item) => {
      newColumns = uniq([...newColumns, ...Object.keys(item.data)]);
    });
    if (items.some(item => !!item.smsText)) {
      newColumns.push('smsText');
    }
    newColumns.push('updatedAt');
    setColumns(newColumns);
  };

  useEffect(() => {
    if (selectedReportItems?.pages.length > 0) {
      updateColumnNames();
    }
  }, [selectedReportItems]);

  const selectReport = async (name) => navigateToReport(name);

  // Updating report item status - START
  const updateReportItemStatus = async ({ id, status }: { id: string, status: ReportStatus }) => HttpClient
    .patch({ path: `/bots/${botId}/data-collection/${selectedReport}/${id}`, body: { status } })
    .then(res => ({
      ...selectedReportItems,
      pages: selectedReportItems.pages.map(page => page.items.map(item => item.id !== id ? item : convertReportItemForDisplay([res.data])[0])),
    }));

  const reportDataMutation = useMutation(
    updateReportItemStatus,
    {
      onMutate: async (reportItemUpdateData: { id: string, status: ReportStatus }) => {
        let pageIndexToRefetch;

        // Cancel currently running queries
        await queryClient.cancelQueries([botId, 'data-collection', selectedReport, JSON.stringify(filters)]);

        // Create a snapshot of current selected report state - before the update
        const previousSelectedReport: {
          pages: { hasMore: boolean, items: ReportFromAPI[] }[],
          pageParams: (string | undefined)[],
        } = queryClient
          .getQueryData([botId, 'data-collection', selectedReport, JSON.stringify(filters)]);

        // Create a snapshot of report list state - before the update
        const previousReportList: ReportsResponse = queryClient.getQueryData([botId, 'data-collection']);
        const previousReport = previousReportList.find(report => report.name === selectedReport);

        if (previousReport.notificationEnabled) {
          // Make an optimistic update of report list for an instant UI effect
          const updatedReportsData = previousReportList.map(report => report.name === selectedReport
            ? {
              ...report,
              uncompletedCount: reportItemUpdateData.status === 'COMPLETED'
              ? report.uncompletedCount - 1
              : report.uncompletedCount + 1
            }
            : report)
          queryClient.setQueryData([botId, 'data-collection'], updatedReportsData);
          
          // Make an optimistic update of report notifications for an instant UI effect
          const hasAnyReportWithUncompletedItems = updatedReportsData.some(report => report.uncompletedCount > 0);
          queryClient.setQueryData(
            [botId, 'data-collection', 'notifications'],
            {
              notificationsEnabled: true,
              showNotificationIcon: hasAnyReportWithUncompletedItems,
            },
          );
        }

        // Make an optimistic update of selected report for an instant UI effect
        queryClient.setQueryData(
          [botId, 'data-collection', selectedReport, JSON.stringify(filters)],
          {
            ...previousSelectedReport,
            pages: previousSelectedReport.pages.map((page, index) => ({
              ...page,
              items: page.items.map(item => {
                if (item.id !== reportItemUpdateData.id) {
                  return item;
                }

                pageIndexToRefetch = index;
                return {
                  ...item,
                  status: reportItemUpdateData.status,
                };
              }),
            })),
          },
        );

        return { previousSelectedReport, pageIndexToRefetch, previousReportList };
      },
      onSuccess: async (data, variables, context) => {
        await refetch({ refetchPage: (page, index) => index === context.pageIndexToRefetch });
      },
      onError: (err, reportItemUpdateData, context) => {
        // Revert the optimistic update of selected report in case of any error
        queryClient.setQueryData(
          [botId, 'data-collection', selectedReport, JSON.stringify(filters)],
          context.previousSelectedReport
        )

        // Revert the optimistic update of report list in case of any error
        queryClient.setQueryData(
          [botId, 'data-collection'],
          context.previousReportList,
        )
      },
    },
  );

  const changeReportItemStatus = async ({
    id, status, onSuccess, onError,
  }: {
    id: string, status: ReportStatus, onSuccess?: () => void, onError?: () => void,
  }) =>
    reportDataMutation.mutate({ id, status }, { onSuccess, onError });
  // Updating report item status - END

  return {
    data: {
      reportList: reportList,
      selectedReportName: selectedReport,
      selectedReportColumns: columns,
      selectedReportItems: selectedReportItems?.pages.map(page => page.items).flat(),
    },
    isLoadingReportList,
    isLoadingReportItems: isLoadingReportItems || isLoadingMoreReportItems,
    hasMoreItems,
    loadMoreItems,
    selectReport,
    changeReportItemStatus,
    filters,
    setFilters: setFilters,
  };
};
