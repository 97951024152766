import React from 'react';

import styles from './icons.pcss';

export function RefreshIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
            <g>
              <path d="M0 0H24V24H0z" transform="translate(-301 -558) translate(301 558)"/>
            </g>
            <g strokeLinecap="round" strokeLinejoin="round" transform="translate(-301 -558) translate(301 558)">
              <g strokeWidth="1.4">
                <path className={styles.iconFillColor} stroke="#3C38BC" d="M4.656 10.781L4.091 13.703 0.906 13.906" transform="matrix(0 1 1 0 3.5 3)"/>
                <path className={styles.iconStrokeColor} d="M3.855 13.701C1.038 10.757.902 6.16 3.54 3.054 6.18-.05 10.737-.66 14.098 1.644 17.46 3.948 18.535 8.419 16.59 12c-1.945 3.58-6.282 5.111-10.044 3.545" transform="matrix(0 1 1 0 3.5 3)"/>
              </g>
            </g>
      </g>
    </svg>
  );
}
