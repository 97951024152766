import { Auth } from 'aws-amplify'
import { useEffect } from 'react'
import { useHistory } from 'react-router';

export function useNavigateDashboardIfAuth() {
    const history = useHistory();

    useEffect(() => {
        async function navigateIfAuthenticated() {
            try {
                const user = await Auth.currentAuthenticatedUser()
                if (user) {
                    history.replace('/')
                }
            } catch (error) {
                // user not authenticated
            }
        }

        navigateIfAuthenticated()
    }, [])
}
