import React from 'react';

export function CopyIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.2822 3H6.95851C6.00977 3 5.24066 3.7691 5.24066 4.71784V17.0415C5.24066 17.9902 6.00977 18.7593 6.95851 18.7593H19.2822C20.2309 18.7593 21 17.9902 21 17.0415V4.71784C21 3.7691 20.2309 3 19.2822 3ZM6.95851 4.19502H19.2822C19.5709 4.19502 19.805 4.4291 19.805 4.71784V17.0415C19.805 17.3302 19.5709 17.5643 19.2822 17.5643H6.95851C6.66976 17.5643 6.43568 17.3302 6.43568 17.0415V4.71784C6.43568 4.4291 6.66976 4.19502 6.95851 4.19502ZM4.1872 5.74126C4.14089 5.45735 3.89451 5.24066 3.59751 5.24066C3.26751 5.24066 3 5.50818 3 5.83817V19.2822L3.00631 19.4304C3.08146 20.3097 3.81904 21 4.71784 21H18.1618L18.2587 20.9922C18.5426 20.9459 18.7593 20.6995 18.7593 20.4025C18.7593 20.0725 18.4918 19.805 18.1618 19.805H4.71784L4.62386 19.7966C4.37997 19.7523 4.19502 19.5388 4.19502 19.2822V5.83817L4.1872 5.74126Z" fill="#919AAF"/>
        </svg>

    );
}
