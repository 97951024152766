import styles from './ImportVisitsAsContactsModal.pcss';
import cx from 'classnames';
import {FormattedMessage, useIntl} from 'react-intl';
import {default as React} from 'react';
import moment from 'moment';
import {get} from 'lodash';
import {Table} from '../../components/Table/Table';
import {createColumnHelper} from '@tanstack/react-table';
import {CancelIcon, EmptyStateFridge} from '../../icons';
import {TooltipComponent} from '../../components/Tooltip/Tooltip';
import {IconButton} from '../../components/IconButton/IconButton';
import {MarkdownContent} from '../../components/MarkdownContent/MarkdownContent';
import {getStringFromConditions} from './ImportVisitsAsContactsModal';
import {Loader} from '../../components/Loader';
import {EmptyState} from '../../components/EmptyState/EmptyState';

const columnHelper = createColumnHelper();

const getTableColumn = (headerTextId: string, textKey: string | string[], isPhoneNumber?: boolean) => {
  return columnHelper.accessor(headerTextId, {
    header: () => (
      <span><FormattedMessage id={`contacts.add.integrationModal.step1.preview.${headerTextId}`} /></span>
    ),
    cell: info => (
      <span title={Array.isArray(textKey) ? textKey.map(key => get(info.row.original, key)).join(' ') : get(info.row.original, textKey)} className={styles.tableCellText} data-sensitive="true" data-test={isPhoneNumber && 'contacts-preview-phone-number'}>
          {Array.isArray(textKey) ? textKey.map(key => get(info.row.original, key)).join(' ') : get(info.row.original, textKey)}
        </span>
    ),
    size: isPhoneNumber ? 140 : 159,
  });
};

export const VisitsExclusionStage = ({
  contacts,
  toggleContactStatus,
  startDate,
  endDate,
  timeFrom,
  timeTo,
  conditions,
  isLoadingContacts,
}: {
  contacts: { phoneNumber: string; additionalData: Record<string, any>; excluded?: boolean }[];
  toggleContactStatus: (id: string) => void;
  startDate: string;
  endDate: string;
  timeFrom: number;
  timeTo: number;
  conditions: { option: { name: string; id: string; } | null; values: { id: string; name: string; }[] }[];
  isLoadingContacts: boolean;
}) => {
  const intl = useIntl();

  return (
    <div className={styles.mainContainer}>
      <span className={styles.text}>
        <FormattedMessage id="contacts.add.integrationModal.step2.description" />
      </span>

      <div className={styles.detailsContainer}>
        <span className={cx(styles.text, styles.secondary, styles.filterSummary)}>
          <MarkdownContent
            contentKey="contacts.add.integrationModal.step2.query"
            extraValues={{
              contactsCount: contacts?.length.toString() || '0',
              dateTimeFrom: moment(startDate).startOf('day').add(timeFrom, 'minutes').format('L LT'),
              dateTimeTo: moment(endDate).startOf('day').add(timeTo, 'minutes').format('L LT'),
            }}
            className={styles.textLine}
          />
          {getStringFromConditions(conditions)}
        </span>

        <span className={cx(styles.text, styles.secondary, styles.excludedContacts)}>
          <FormattedMessage id="contacts.add.integrationModal.step2.excludedContacts" />
          <span className={styles.excludedContactsCount}>{contacts?.filter(c => c.excluded)?.length || '0'}</span>
        </span>
      </div>

      <div>
        <div className={styles.excludeTableContainer}>
          {isLoadingContacts && (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          )}

          {!isLoadingContacts && (!contacts || contacts.length === 0) && (
            <EmptyState
              content={
                <MarkdownContent
                  contentKey="contacts.add.integrationModal.step1.preview.emptyState"
                />
              }
              Icon={EmptyStateFridge}
              size="small"
            />
          )}

          {(!isLoadingContacts && contacts?.length > 0) && (
            <div className={styles.tableContainer} data-test="contacts-preview-table">
              <Table
                data={contacts}
                columns={[
                  getTableColumn('phoneNumber', 'phoneNumber', true),
                  getTableColumn('patient', 'additionalData.imie i nazwisko pacjenta'),
                  getTableColumn('visit', ['additionalData.data wizyty', 'additionalData.godzina wizyty']),
                  getTableColumn('doctor', 'additionalData.imie i nazwisko lekarza'),
                  getTableColumn('service', 'additionalData.usluga'),
                  getTableColumn('localization', 'additionalData.lokalizacja'),
                  getTableColumn('practice', 'additionalData.poradnia'),
                  columnHelper.accessor('excluded', {
                    header: () => '',
                    size: 56,
                    cell: info => (
                      <span data-test={get(info.row.original, 'excluded') && 'contacts-preview-table-excluded-row'}>
                        <TooltipComponent
                          tooltipText={intl.formatMessage({ id: 'contacts.add.integrationModal.step2.excludeContact' })}
                        >
                          <IconButton
                            dataTest={`contacts-preview-table-exclude-toggle-button-${get(info.row.original, 'additionalData.id wizyty')}`}
                            className={styles.iconButton}
                            Icon={CancelIcon}
                            onClick={() => toggleContactStatus(get(info.row.original, 'additionalData.id wizyty'))}
                          />
                        </TooltipComponent>
                      </span>
                    ),
                  }),
                ]}
                selectedRows={contacts.reduce((acc, c) => ({ ...acc, [c.additionalData['id wizyty']]: c.excluded }), {})}
                getRowId={row => row.additionalData?.['id wizyty']}
                selectType="EXCLUDED"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
