import _ from 'lodash';
import { useIntl } from 'react-intl';
import { PaletteItemsHook } from './types';
import { useContext, useMemo } from 'react';
import { SurveyContext } from '../../surveyCreator/SurveyCreator';
import { useHistory } from 'react-router';
import { PaletteStepSubtitle } from '../components/PaletteStepSubtitle';
import { fromDBFormatToName, linkElementAltAction } from './utils';
import { BlockQuestion, Question } from '../../surveyCreator/model';
import React from 'react';
import { useAllSteps } from './useAllSteps';

function collectTexts(obj: object): string[] {
  let texts: string[] = [];

  function processProperty(value: object): void {
    if (Array.isArray(value)) {
      value.forEach(item => processProperty(item));
    } else if (value !== null && typeof value === 'object') {
      if ('text' in value) {
        texts.push((value as { text: string }).text);
      }
      Object.values(value).forEach(val => processProperty(val));
    }
  }

  processProperty(obj);
  return texts;
}

type AugmentedBlockQuestion = BlockQuestion & {
  moduleName: string;
  href: string;
  texts: string[]
};

export const useTextItems: PaletteItemsHook = () => {
  const intl = useIntl();
  const { variables } = useContext(SurveyContext);
  const history = useHistory();
  const category = intl.formatMessage({ id: `survey-creator.command-palette.categories.texts` });

  const steps = useAllSteps();
  const questionTexts = useMemo(() => {
    const variableNames = fromDBFormatToName(variables);
    const variableKeys = Object.keys(variableNames);
    return steps.map((step: Question) => {
      const allTexts = collectTexts(step);

      const textsWithVariableNames = allTexts.map((text) => {
        variableKeys.forEach((variable) => {
          text = text.replace(variable, variableNames[variable]);
        });
        return text;
      });

      return {
        ...(step as AugmentedBlockQuestion),
        texts: textsWithVariableNames,
      };
    });
  }, [steps, variables]);

  const texts = useMemo(() => questionTexts.map((step) => step.texts.map((text) => ({
    ...step,
    content: text,
  }))).flat().filter(text => !text.texts.every(message => !message || /^\s*$/.test(message))), [questionTexts]);

  return useMemo(() => {
    return texts?.map(text => ({
      id: text.id + text.content + Math.random(),
      name: text.content,
      subtitle: <PaletteStepSubtitle moduleName={text.moduleName} stepName={text.name} />,
      category,
      onAction: () => history.push(text.href),
      onAltAction: () => linkElementAltAction(text.href),
      data: text
    }));
  }, [texts]);
};