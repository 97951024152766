import classnames from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';

import addIcon from '../../images/add-icon.svg';
import {Button, ButtonProps} from '../Button/Button';
import { TooltipComponent } from '../Tooltip/Tooltip';

import styles from './AddButton.pcss';

type Props = {
  dataTest?: string;
  className?: string;
  textId: string;
  tooltipText?: string;
};

export function AddButton({
  dataTest,
  className,
  textId,
  tooltipText,
  ...props
}: ButtonProps & Props) {
  return (
    <TooltipComponent
      tooltipText={tooltipText}
      trigger={!!tooltipText ? 'hover' : 'none'}
    >
      <Button
        className={classnames(styles.button, className)}
        dataTest={dataTest}
        {...props}
      >
        {props.buttonType !== 'loading' && (
          <>
            <img src={addIcon} className={styles.addIcon} alt={'add'} />
            <FormattedMessage id={textId} />
          </>
        )}
      </Button>
    </TooltipComponent>
  );
}
