import {AxiosResponse} from 'axios';
import _ from 'lodash';

import {HttpClient} from '../../../../utils/HttpClient';
import {Bot, IntentSentence, SurveyIntent} from '../../../model';


const get = (bot: Bot, intent: SurveyIntent) => {

    type TrainDataStatus = 'new' | 'learnt' | 'removed'
    type TrainData = { text: string, intent: string, status: TrainDataStatus };
    type IntentTrainData = {
        global: TrainData[]
        local: TrainData[]
    }

    const propagateError = (reason: any) => {
        throw reason;
    }

    const unwrap = (response: AxiosResponse) => {
        return response.data;
    }

    const unwrapToVoid = () => undefined as void

    const emptyWhenMissing = (data: IntentTrainData): IntentTrainData => {
        return {
            ...{global: [], local: []},
            ...data
        }
    }

    const group = ({global, local}: IntentTrainData): GroupedSentences => {
        const text = it => it.text;
        const globalData = global.map(text);
        const localData = local.filter(({status}) => status !== 'new').map(text);
        const enqueuedData = local.filter(({status}) => status === 'new').map(text);
        return {
            global: globalData,
            local: localData,
            enqueued: enqueuedData
        };
    }

    const {id: botId} = bot;
    const {name: intentName} = intent;

    const fetchSentences = (): Promise<GroupedSentences> => HttpClient
        .get({path: `/bots/${botId}/intents/${encodeURIComponent(intentName)}`})
        .then(unwrap)
        .then(emptyWhenMissing)
        .then(group)
        .catch(propagateError);

    const fetchTrainingStatus = (): Promise<TrainingStatus> => HttpClient
        .get({path: encodeURI(`/bots/${botId}/train-status`), params: {stage: 'dev'}})
        .then(unwrap)
        .catch(propagateError)

    const addSentences = (sentences: IntentSentence[]): Promise<GroupedSentences> => {
        if (_.isEmpty(sentences)) {
            return;
        }

        const trainData = sentences.map(it => ({text: it, intent: intentName}));

        return HttpClient
            .post({path: encodeURI(`/bots/${botId}/rasa-sentences`), body: {trainData}})
            .then(unwrap)
            .then(emptyWhenMissing)
            .then(group)
            .catch(propagateError);
    }

    const removeSentence = (sentence: IntentSentence) => HttpClient
        .delete({path: `/bots/${botId}/rasa-sentences/${encodeURIComponent(sentence)}`})
        .then(unwrapToVoid)
        .catch(propagateError);

    const removeGlobalSentence = (sentence: IntentSentence) => HttpClient
        .delete({path: `/bots/${botId}/rasa-sentences/global/${encodeURIComponent(sentence)}`})
        .then(unwrapToVoid)
        .catch(propagateError);

    const train = () => HttpClient
        .post({path: encodeURI(`/bots/${botId}/train`), body: {stage: 'prod'}})
        .then(unwrapToVoid)
        .catch(propagateError);

    return {
        fetchSentences,
        fetchTrainingStatus,
        addSentences,
        removeSentence,
        removeGlobalSentence,
        train
    }
}

export const IntentTrainingService = {
    get,
};

export type IntentTrainingService = ReturnType<typeof get>

export type TrainingStatus = { training: boolean };

export type GroupedSentences = {
    global: string[]
    local: string[]
    enqueued: string[]
}
