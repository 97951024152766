import _ from 'lodash';

import { FormProps } from './types';

export const eraseErrorsOnFocus = (formProps: FormProps): FormProps => {
  return {
    ...formProps,
    fieldsProps: _.mapValues(formProps.fieldsProps, (value) => ({
      ...value,
      onFocus: () => formProps.setFieldError(value.name, undefined)
    }))
  };
}