import classNames from 'classnames';
import _ from 'lodash';
import * as React from 'react';
import {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {TryCallIcon} from '../../icons/TryCallIcon';
import {useCurrentBot} from '../../utils/CurrentBot';
import {HttpClient} from '../../utils/HttpClient';

import styles from './TryIt.pcss';

export const useTryIt = () => {
    const [isCalling, setCalling] = useState(false);
    const [phone, setPhone] = useState(localStorage.getItem('tryBotNumber') || '');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const currentBot = useCurrentBot();
    const {formatMessage} = useIntl();
    const tryIt = async () => {
        if (isCalling) {
            return;
        }
        const botId = _.get(currentBot, 'id');
        setCalling(true);
        setTimeout(() => {
            setCalling(false);
        }, 4300);
        try {
            await HttpClient.post({path: `/bots/${botId}/creator/call`, body: {phone: phone}});
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 3000);
        } catch (e) {
            setError(formatMessage({id: 'survey-creator.tryIt.error'}));
            setTimeout(() => {
                setError('');
            }, 3000);
        }
    };
    return {
        isCalling,
        phone,
        error,
        success,
        tryIt,
        setPhone,
    };
}

export const TryIt = () => {
    const {messages} = useIntl()
    const {isCalling, phone, tryIt, setPhone} = useTryIt();


    const handleChange = (e) => {
        const value = e.target.value;
        setPhone(value)
        localStorage.setItem('tryBotNumber', value);
    }

    return (
        <div className={styles.try}>
            <button
                className={classNames(styles.button, {[styles.buttonActive]: !!phone, [styles.buttonCall]: isCalling})}
                onClick={tryIt} disabled={!phone} data-test={'try-button'}>
                <TryCallIcon animationClass={styles.icon}/>
            </button>
            <div className={styles.numberSliderContainer}>
                <div className={styles.numberInputContainer}>
                    <div className={styles.description}>
                        <FormattedMessage id={'survey-creator.tryIt.label'}/>
                    </div>
                    <input className={styles.input} onChange={handleChange} value={phone}
                           placeholder={messages['survey-creator.tryIt.placeholder'] as string}
                           data-test={'try-number-input'}/>
                </div>
            </div>
        </div>
    );
};
