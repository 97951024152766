import React from 'react';

import {IconProps} from './iconTypes';

export function DialerArrowIcon({ animationClass }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <defs>
                <filter id="flxkxrsy7a">
                    <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.235294 0 0 0 0 0.219608 0 0 0 0 0.737255 0 0 0 1.000000 0" />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g transform="translate(-28 -626) rotate(-180 24 323) translate(4 4)">
                            <path d="M0 0H16V16H0z" />
                            <path fill="#3C38BC" fillRule="nonzero" d="M12.804 4.636c.18-.283.556-.367.839-.187.254.163.348.483.232.752l-.046.087-4.676 7.349c-.251.393-.686.632-1.153.632-.415 0-.805-.189-1.063-.507l-.09-.126L2.17 5.288c-.18-.283-.097-.658.186-.839.255-.162.585-.11.78.108l.06.079 4.674 7.347c.028.044.077.07.129.07.035 0 .068-.011.094-.032l.034-.037 4.676-7.348z" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    );
}
