import React from 'react';
import classNames from 'classnames';

import styles from './ModalContent.pcss';

interface Props {
  children: React.ReactNode;
  withScrollBarMargin?: boolean;
}

export const ModalBody: React.FC<Props> = ({
  children,
  withScrollBarMargin,
}) => {
  return (
    <div
      className={classNames(styles.modalBody, {
        [styles.withScrollBarMargin]: withScrollBarMargin,
      })}
    >
      {children}
    </div>
  );
};
