import cx from 'classnames';
import React from 'react';

import { ElementWithLabel, ElementWithLabelProps } from '../ElementWithLabel/ElementWithLabel';
import { Range, RangeProps } from '../Range/Range';

import styles from './RangeWithLabel.pcss';

export type RangeWithLabelProps = RangeProps & ElementWithLabelProps

export const RangeWithLabel: React.FC<RangeWithLabelProps> = ({
  dataTest,
  labelId,
  borderEnabled,
  noPadding,
  className,
  value,
  ...rest
}) => <ElementWithLabel
  dataTest={dataTest}
  labelId={labelId}
  borderEnabled={borderEnabled}
  noPadding={noPadding}
  className={cx(styles.box, className)}
  inner
>
    <label className={styles.value}>{value}</label>
    <Range value={value} {...rest} />
  </ElementWithLabel>