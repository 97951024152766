import React from 'react';

import { IconProps } from './iconTypes';

export const HelpPopupDescriptionIcon: React.FC<IconProps> = ({ animationClass }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <g fill="none" fillRule="evenodd">
        <g fill="#F94242" fillRule="nonzero">
            <g>
                <path d="M7 0c3.866 0 7 3.134 7 7s-3.134 7-7 7-7-3.134-7-7 3.134-7 7-7zm0 1C3.686 1 1 3.686 1 7s2.686 6 6 6 6-2.686 6-6-2.686-6-6-6zm.017 8.447l.085.006c.44.05.774.424.774.87 0 .484-.392.876-.876.876s-.876-.392-.876-.876c0-.446.335-.82.792-.872l.101-.004zm-.291-6.63c1.226-.14 2.362.664 2.639 1.866.277 1.203-.393 2.422-1.556 2.833-.083.03-.142.103-.151.17l-.004.049v.594c0 .362-.293.655-.654.655-.322 0-.595-.234-.649-.573l-.005-.082v-.594c0-.61.359-1.161.925-1.413l.173-.068c.494-.213.766-.75.645-1.277-.127-.554-.65-.924-1.215-.86-.565.065-.992.543-.992 1.111l-.007.097c-.047.315-.319.557-.647.557-.362 0-.655-.293-.655-.654 0-1.234.927-2.272 2.153-2.41z" transform="translate(-533.000000, -375.000000) translate(533.000000, 375.000000)"/>
            </g>
        </g>
    </g>
</svg>


)