import classNames from 'classnames';
import * as React from 'react';
import {FormattedMessage} from 'react-intl';

import styles from './Label.pcss';

export const Label = ({labelId, className}: { labelId: string; className?: string }) => {
    return (
        <div className={classNames(styles.label, className)}>
            <FormattedMessage id={labelId}/>
        </div>
    );
};

export const TextLabel = ({label, className, dataTest = 'text-label' }: { label: string; className?: string, dataTest?: string }) => {
    return (
        <div data-test={dataTest} className={classNames(styles.label, styles.textLabel, className)}>
            {label}
        </div>
    );
};
