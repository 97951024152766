import * as React from 'react';
import { HeaderContainer } from '../layout/header/HeaderContainer';
import { HeaderLogo } from '../layout/header/HeaderLogo';

import styles from './OrganizationHeader.pcss';
import { KnowledgeBaseIcon } from '../layout/header/KnowledgeBaseIcon';
import { UserSettingsIcon } from '../layout/header/UserSettingsIcon';
import { HeaderActionContainer } from '../layout/header/HeaderActionContainer';
import { FormattedMessage } from 'react-intl';

type OrganizationHeaderProps = {
  organizationName: string;
};

export function OrganizationHeader({
  organizationName,
}: OrganizationHeaderProps) {
  return (
    <>
      <HeaderContainer className={styles.headerContainer}>
        <div>
          <HeaderLogo className={styles.logo} />
          <p className={styles.organizationName}>
            <FormattedMessage
              id="organization.header.organization"
              values={{ organizationName }}
            />
          </p>
        </div>
        <HeaderActionContainer>
          <KnowledgeBaseIcon />
          <UserSettingsIcon />
        </HeaderActionContainer>
      </HeaderContainer>
    </>
  );
}
