import classNames from 'classnames';
import moment from 'moment';
import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import uuid from 'uuid';
import { useQuery } from 'react-query';
import Scrollbars from 'react-custom-scrollbars';

import { AudioPlayer, EmptyAudioPlayer, useAudioState } from '../components/callsList/AudioPlayer';
import { Loader } from '../components/Loader';
import { Scrollbar } from '../components/Scrollbar/Scrollbar';
import { currentUser, currentUserRoles, getNamesDictionary } from '../selectors';
import { UserRole } from '../utils/CurrentUser';
import { HttpClient } from '../utils/HttpClient';

import styles from './ConversationDetails.pcss';
import {
    ConversationLine, TranscriptItemDataCollection,
    TranscriptItemFunction,
    TranscriptItemHttpStep,
    TranscriptItemRedirect,
    TranscriptItemSetVariable,
    TranscriptItemSMS,
    TranscriptLine
} from './models/Transcript';
import { convertInformationItemToEventData } from './models/Events';
import { ConversationDetailsMetadata } from './ConversationDetailsMetadata';
import { Modal } from '../components/Modal/Modal';
import { ModalContent } from '../components/ModalContent/ModalContent';
import { Row } from '../components/Row/Row';
import { Button } from '../components/Button/Button';
import { getBotUrlId, useCurrentBot } from '../utils/CurrentBot';
import { PlayCircleIcon, ReportBugIcon } from '../icons';
import { TextArea } from '../components/TextArea/TextArea';
import { ConversationOptions } from './options/ConversationOptions';
import { HttpStepLine } from './line/HttpStepLine';
import { SetVariableStepLine } from './line/SetVariableStepLine';
import { useVariables } from '../views/Settings/AdminTabContent/Variables.hook';
import { useSurveyCreator } from '../surveyCreator/useSurveyCreator';
import { useSurveyConfig } from '../views/Settings/Settings.hooks';
import {Block, BlockQuestion, Bot, Callbacks, ConditionalQuestion, Fallback, Question} from '../surveyCreator/model';
import { Link } from 'react-router-dom';
import { InterpretationStepLine } from './line/InterpretationStepLine';
import { DataCollectionStepLine } from './line/DataCollectionStepLine';
import { showErrorToast, showSuccessToast } from '../components/Toast/Toast';
import { FunctionStepLine } from './line/FunctionStepLine';
import { SMSStepLine } from './line/SMSStepLine';
import { RedirectStepLine } from './line/RedirectStepLine';

const TEXT_CHAT_PHONE_NUMBER = 'bot-tester';

export const REFETCH_INTERVAL_TIME = 3000;

type ConversationDetailsProps = {
    live?: boolean;
    botId: string;
    callId: string;
    timezone: string;
    minimized?: boolean;
};

export const ConversationDetails: React.FC<ConversationDetailsProps> = ({
    live,
    botId,
    callId,
    timezone,
    minimized,
}) => {
    const [conversationPreviewScroll, setConversationPreviewScroll] = useState(0);
    const [currentState] = useState(uuid.v4());

    useEffect(() => {
        setConversationPreviewScroll(0);
    }, [callId]);

    return (
        <>
            {callId && (
                <ConversationView
                    live={live}
                    detailsUrl={`/bots/${botId}/${live ? 'live-calls' : 'calls'}/${callId}`}
                    recordingUrl={`/bots/${botId}/calls/${callId}/recording`}
                    currentStateId={currentState}
                    timezone={timezone}
                    minimized={minimized}
                    setConversationPreviewScroll={setConversationPreviewScroll}
                    initialPreviewScroll={conversationPreviewScroll}
                />
            )}
            {!callId && (
                <ConversationEmptyState>
                    <FormattedMessage id="callDetails.emptyState" />
                </ConversationEmptyState>
            )}
        </>
    );
};

function ConversationEmptyState(props: { children?: React.ReactNode }) {
    return (
        <div className={styles.details} data-test="conversation-details-empty">
            <div className={styles.audioRecord}>
                <EmptyAudioPlayer />
            </div>
            <div className={styles.emptyState}>{props.children}</div>
        </div>
    );
}

const withSSMLTagsStore = {
    key: 'showSSMLTags',
    get() {
        return localStorage.getItem(this.key) === 'true';
    },
    set(value: boolean) {
        localStorage.setItem(this.key, value.toString());
    }
};

const withDebugModeStore = {
    key: 'debugMode',
    get() {
        return localStorage.getItem(this.key) === 'true';
    },
    set(value: boolean) {
        localStorage.setItem(this.key, value.toString());
    }
};

function ConversationView(props: {
    live?: boolean;
    detailsUrl: string;
    recordingUrl: string;
    currentStateId?: string;
    timezone: string;
    minimized?: boolean;
    setConversationPreviewScroll: (topScroll: number) => void;
    initialPreviewScroll: number;
}) {
    const intl = useIntl();
    const [audioLoaded, setAudioLoaded] = useState(false);
    const [isInProgress, setInProgress] = useState(false);
    const roles = useSelector(currentUserRoles);
    const namesDictionary = useSelector(getNamesDictionary);
    const audioState = useAudioState();
    const user = useSelector(currentUser);

    const isAdmin = roles.includes(UserRole.ADMIN);
    const hasConversationDebuggerPermission = roles.includes(UserRole.CONVERSATION_DEBUGGER);
    const shouldShowConversationOptions = isAdmin || hasConversationDebuggerPermission;

    const [withSSMLTags, setWithSSMLTags] = useState(
        shouldShowConversationOptions ? withSSMLTagsStore.get() : false
    )
    const [withDebugMode, setWithDebugMode] = useState(
        shouldShowConversationOptions ? withDebugModeStore.get() : false
    )

    const currentBot = useCurrentBot();
    useSurveyConfig();
    const { definition } = useSurveyCreator(currentBot);

    function setShowSSMLTags(value: boolean) {
        withSSMLTagsStore.set(value);
        setWithSSMLTags(value);
    }

    function setDebugMode(value: boolean) {
        withDebugModeStore.set(value);
        setWithDebugMode(value);
    }

    function onConversationOptionsSave({ showDebugMode, showSSMLTags }: { showDebugMode: boolean, showSSMLTags: boolean }) {
        setShowSSMLTags(showSSMLTags);
        setDebugMode(showDebugMode);
    }

    const { data: call, isLoading: loadingCall, isError: error } = useQuery([props.detailsUrl, withSSMLTags, withDebugMode], () => {
        if (!isInProgress) {
            audioState.reset();
            setAudioLoaded(false);
        }
        return HttpClient.get({
            path: props.detailsUrl,
            params: {
                withSSMLTags,
                debugMode: withDebugMode
            }
        }).then(({ data }) => {
            setInProgress(props.live && !data.conversationEnd)
            return data
        });
    }, {
        refetchInterval: isInProgress ? REFETCH_INTERVAL_TIME : false, refetchOnWindowFocus: false,
    });

    const events = useMemo(() => convertInformationItemToEventData(call ?? {}, namesDictionary), [
        call,
        namesDictionary,
    ]);

    if (error) {
        return (
            <ConversationEmptyState data-test="conversation-details-error">
                <FormattedMessage id="callDetails.notFound" />
            </ConversationEmptyState>
        );
    }

    const hasAudio = call && !!call.recordingUrl;
    const hasTranscript = call && !!call.transcript && !!call.transcript.length;
    const hasSmsTranscript = call && !!call.smsTranscript && !!call.smsTranscript.length;
    const isTextChat = call && call.phoneNumber.includes(TEXT_CHAT_PHONE_NUMBER);
    const allDataLoaded = !loadingCall && (!hasAudio || audioLoaded);
    const isSmsOnlyTranscript = !hasAudio && !hasTranscript && hasSmsTranscript;

    const timestamp = moment.tz(call?.conversationStart, props.timezone).format(`${intl.messages['timestamp.format']}`);
    const eventWithIcon = events.events.find(event => event.icon);
    const eventIcon = eventWithIcon?.icon;
    const eventLabel = intl.formatMessage({ id: `conversations.filter.${eventWithIcon?.key}` });

    return (
        <div className={styles.details} data-test="conversation-details">
            {!props.minimized && (
                <div className={styles.audioRecord}>
                    {!isSmsOnlyTranscript && !isTextChat && (
                        <>
                            {!loadingCall && (
                                <AudioPlayer
                                    audioState={audioState}
                                    onLoaded={setAudioLoaded}
                                    data-test="list-audio-player"
                                    streamAudioUrl={call.recordingUrl}
                                    downloadAudioUrl={props.recordingUrl}
                                />
                            )}
                            {loadingCall && <EmptyAudioPlayer />}
                        </>
                    )}
                    {isSmsOnlyTranscript && <EmptyAudioPlayer label="callDetails.smsTranscript" />}
                    {
                        isTextChat && <div className={styles.textChatMessage}>
                            <FormattedMessage id="callDetails.textChat" />
                        </div>
                    }
                </div>
            )}
            <ConversationDetailsMetadata
                isLoading={!allDataLoaded}
                isTextChat={isTextChat}
                call={call}
                eventIcon={eventIcon}
                eventLabel={eventLabel}
                timestamp={timestamp}
            />
            {!props.minimized && (
                <>
                    {!allDataLoaded && (
                        <div className={styles.emptyState}>
                            <Loader className={styles.loader} />
                        </div>
                    )}
                    {allDataLoaded && (
                        <>
                            {!hasTranscript && !hasSmsTranscript && (
                                <div className={styles.emptyState}>
                                    <FormattedMessage id="callDetails.noTranscript" />
                                </div>
                            )}
                            {(hasTranscript || hasSmsTranscript) && (
                                <>
                                    <RenderTranscript
                                        transcript={call.transcript}
                                        smsTranscript={call.smsTranscript}
                                        playPart={audioState.playPart}
                                        callId={call.id}
                                        callDuration={call.callDuration}
                                        setConversationPreviewScroll={props.setConversationPreviewScroll}
                                        initialPreviewScroll={props.initialPreviewScroll}
                                        withDebugMode={withDebugMode}
                                        bot={currentBot}
                                        blocks={definition.blocks.get()}
                                        fallback={definition.fallback.get()}
                                        silenceFallback={definition.silenceFallback.get()}
                                        callbacks={definition.callbacks.all}
                                        userData={{ id: user.id, email: user.email }}
                                    />
                                </>
                            )}
                            {shouldShowConversationOptions && <div className={styles.conversationOptionsContainer}>
                                <ConversationOptions
                                    debugMode={withDebugMode}
                                    showSSMLTags={withSSMLTags}
                                    onSave={onConversationOptionsSave}
                                />
                            </div>}
                        </>
                    )}
                </>
            )}
        </div>
    );
}

type TranscriptLineSource = TranscriptLine & { readonly source: 'sms' | 'call' };
type TranscriptLineSourceWithType = TranscriptLineSource & {
    type: 'http' | 'set_variable' | 'data_collection' | undefined
    questionId: string | undefined
    stepId: string | undefined
}

type StepMeta = {
    moduleId: string;
    moduleName: string;
    stepId: string;
    stepName: string;
}

function RenderTranscript({
    smsTranscript = [],
    transcript = [],
    children,
    playPart,
    callId,
    callDuration,
    setConversationPreviewScroll,
    initialPreviewScroll,
    withDebugMode,
    blocks,
    fallback,
    silenceFallback,
    callbacks,
    bot,
    userData
}: {
    transcript: TranscriptLine[];
    smsTranscript: TranscriptLine[];
    children?: React.ReactNode;
    callId: string;
    callDuration?: number;
    setConversationPreviewScroll: (topScroll: number) => void;
    initialPreviewScroll: number;
    withDebugMode: boolean;
    blocks: Block[];
    fallback: Fallback;
    silenceFallback: Fallback;
    callbacks: Callbacks;
    bot: Bot;
    userData: { id: string, email: string }
    playPart(start: number, duration?: number): void;
}) {
    const scrollBarRef = useRef<Scrollbars>(null);
    const intl = useIntl();
    const roles = useSelector(currentUserRoles);

    const { variables } = useVariables();

    const getMetaForBlocksQuestions = (blocksWithQuestions) => blocksWithQuestions.reduce((acc, block) => {
        block.questions.forEach((blockQuestion:  BlockQuestion) => {
            acc[(blockQuestion as ConditionalQuestion)?.question?.id ?? blockQuestion.id] = {
                moduleId: block.id,
                moduleName: block.name,
                stepId: blockQuestion.id,
                stepName: blockQuestion.name,
            };
        })
        return acc;
    }, {});

    const getMetaForSteps = (moduleName: string, moduleId: string, steps) => steps.reduce((acc, step) => {
        acc[(step as ConditionalQuestion)?.question?.id ?? step.id] = {
            moduleName,
            moduleId,
            stepId: step.id,
            stepName: step.name,
        };
        return acc;
    }, {});

    const stepsAndQuestionsMeta: Record<string, StepMeta> = {
        ...getMetaForBlocksQuestions(blocks),
        ...(fallback?.steps && getMetaForSteps(intl.messages['survey-creator.fallback'] as string, 'fallback', fallback.steps)),
        ...(silenceFallback?.steps && getMetaForSteps(intl.messages['survey-creator.silenceFallback'] as string, 'silenceFallback', silenceFallback.steps)),
        ...(callbacks?.callFinished?.questions && getMetaForSteps(intl.messages['survey-creator.callbacks.callFinished'] as string, 'callbacks/callFinished', callbacks.callFinished.questions)),
        ...(callbacks?.callNotAnswered?.questions && getMetaForSteps(intl.messages['survey-creator.callbacks.callNotAnswered'] as string, 'callbacks/callNotAnswered', callbacks.callNotAnswered.questions)),
        ...(callbacks?.voiceMailDetected?.questions && getMetaForSteps(intl.messages['survey-creator.callbacks.voiceMailDetected'] as string, 'callbacks/voiceMailDetected', callbacks.voiceMailDetected.questions)),
    };

    const getModuleLink = (stepMeta: StepMeta) => {
        switch (stepMeta?.moduleId) {
            case 'fallback':
                return `/${getBotUrlId(bot)}/survey-creator/scenario/fallback`;
            case 'silenceFallback':
                return `/${getBotUrlId(bot)}/survey-creator/scenario/silence-fallback`;
            case 'callbacks/callFinished':
                return `/${getBotUrlId(bot)}/survey-creator/scenario/callbacks/callFinished`;
            case 'callbacks/callNotAnswered':
                return `/${getBotUrlId(bot)}/survey-creator/scenario/callbacks/callNotAnswered`;
            case 'callbacks/voiceMailDetected':
                return `/${getBotUrlId(bot)}/survey-creator/scenario/callbacks/voiceMailDetected`;
            default:
                return `/${getBotUrlId(bot)}/survey-creator/scenario/modules/${stepMeta?.moduleId}`;
        }
    };

    const [showModal, setShowModal] = useState(false);
    const [eventId, setEventId] = useState<string | number>('');
    const [note, setNote] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const smsTranscriptWithSource: TranscriptLineSource[] = smsTranscript.map(line => ({
        ...line,
        source: 'sms'
    }));
    const callTranscriptWithSource: TranscriptLineSource[] = transcript.map(line => ({
        ...line,
        source: 'call'
    }));

    const wholeTranscript: TranscriptLineSource[] = [...callTranscriptWithSource, ...smsTranscriptWithSource];

    const isOnlySmsTranscript = callTranscriptWithSource.length === 0 && smsTranscriptWithSource.length > 0;

    const handleReportUtterance = useCallback(() => {
        HttpClient.post({
            path: `/bots/${bot.id}/issues`,
            body: {
                callId,
                eventId,
                note,
                submitter: userData
            }
        })
            .then(() => {
                setShowModal(false);
                showSuccessToast(intl.messages['issues.successToast']);
            })
            .catch(() => {
                setError(true);
                showErrorToast(intl.messages['issues.errorToast']);
            });
    }, [bot.id, eventId, callId, note]);

    const handleTextAreaTyping = useCallback((value: string) => {
        setNote(value);
        setError(false);
    }, []);

    const handleShowModal = () => {
        setNote('');
        setShowModal(true);
    }

    useEffect(() => {
        if (initialPreviewScroll) {
            scrollBarRef.current.scrollTop(initialPreviewScroll);
        }
    }, []);

    return (
        <>
            {showModal && (
                <Modal
                    modalSize="small"
                    noPadding
                    noFixedHeight
                >
                    <ModalContent
                        headerContent={<FormattedMessage id="issues.title" />}
                        footerContent={
                            <Row>
                                <Button
                                    data-test="report-utterance-modal-cancel-button"
                                    onClick={() => setShowModal(false)}
                                    buttonType="normal"
                                    translateText="cancelLabel"
                                />
                                <Button
                                    data-test="report-utterance-modal-save-button"
                                    onClick={handleReportUtterance}
                                    translateText="issues.save"
                                />
                            </Row>
                        }
                    >
                        <TextArea
                            placeholder={intl.messages['issues.placeholder'] as string}
                            dataTest="report-utterance-modal-text-area"
                            value={note}
                            onChange={handleTextAreaTyping}
                            errorMessage={error ? intl.messages['errors.VALUE_REQUIRED'] as string : null}
                        />
                    </ModalContent>
                </Modal>
            )}
            <div className={styles.transcript} data-test="conversation-transcript">
                <Scrollbar onScroll={setConversationPreviewScroll} ref={scrollBarRef}>
                    <ol className={styles.conversation}>
                        {wholeTranscript.map((line: TranscriptLineSourceWithType, index: number, data: TranscriptLineSourceWithType[]) => {
                            const blockId = line.questionId ?? line.stepId
                            const prevLine = index !== 0 && data[index - 1]
                            const prevBlockId = prevLine && (prevLine.questionId ?? prevLine.stepId)
                            const changedModule = (
                                index === 0 ||
                                (stepsAndQuestionsMeta[prevBlockId]?.moduleId !== stepsAndQuestionsMeta[blockId]?.moduleId)
                            )
                            const moduleLink = getModuleLink(stepsAndQuestionsMeta[blockId]);
                            const stepLink = `${moduleLink}/${stepsAndQuestionsMeta[blockId]?.stepId}`
                            const shouldShowModuleLink = withDebugMode && stepsAndQuestionsMeta[blockId] && changedModule;
                            const isStepLinkExpired = !stepsAndQuestionsMeta[blockId]

                            return (
                                <>
                                    {shouldShowModuleLink &&
                                        <Link
                                            to={moduleLink}
                                            target="_blank"
                                            data-test="conversation-transcript-line-module-link"
                                            className={styles.moduleName}
                                        >
                                            {stepsAndQuestionsMeta[blockId]?.moduleName}
                                        </Link>
                                    }
                                    {isConversationLine(line) && (
                                        <RenderTranscriptLine
                                            key={index}
                                            line={line as ConversationLine}
                                            label={
                                                !isOnlySmsTranscript && line.source === 'sms'
                                                    ? 'SMS'
                                                    : undefined
                                            }
                                            onPlay={() => {
                                                const { start, duration } = getTimeRangeForStatement(
                                                    data as ConversationLine[],
                                                    index,
                                                    callDuration
                                                );
                                                playPart(start, duration);
                                            }}
                                            setShowModal={handleShowModal}
                                            setEventId={setEventId}
                                            question={stepsAndQuestionsMeta[line.questionId]}
                                            withDebugMode={withDebugMode}
                                            stepLink={stepLink}
                                        />
                                    )}
                                    {withDebugMode &&
                                        <>
                                            {isDataCollectionStepLine(line) &&
                                                <DataCollectionStepLine
                                                    line={line}
                                                    stepLink={stepLink}
                                                    isStepLinkExpired={isStepLinkExpired}
                                                />
                                            }
                                            {isHttpStepLine(line) &&
                                                <HttpStepLine
                                                    line={line}
                                                    stepLink={stepLink}
                                                    isStepLinkExpired={isStepLinkExpired}
                                                />
                                            }
                                            {isSetVariableStepLine(line) &&
                                                <SetVariableStepLine
                                                    line={line}
                                                    stepLink={stepLink}
                                                    variableName={variables?.find(v => v.id === line.variable)?.name ?? line.variable}
                                                    isStepLinkExpired={isStepLinkExpired}
                                                />
                                            }
                                            {isFunctionStepLine(line) &&
                                                <FunctionStepLine
                                                    line={line}
                                                    stepLink={stepLink}
                                                    isStepLinkExpired={isStepLinkExpired}
                                                />
                                            }
                                            {isSMSStepLine(line) &&
                                                <SMSStepLine
                                                    line={line}
                                                    moduleLink={moduleLink}
                                                    isStepLinkExpired={isStepLinkExpired}
                                                />
                                            }
                                            {isRedirectStepLine(line) &&
                                                <RedirectStepLine
                                                    line={line}
                                                    moduleLink={moduleLink}
                                                    isStepLinkExpired={isStepLinkExpired}
                                                />
                                            }
                                        </>
                                    }
                                </>
                            )
                        })}
                    </ol>
                    {children}
                </Scrollbar>
            </div>
        </>
    );
}

const isConversationLine = (line: any): line is ConversationLine => line.type === undefined
const isHttpStepLine = (line: any): line is TranscriptItemHttpStep => line.type === 'http'
const isSetVariableStepLine = (line: any): line is TranscriptItemSetVariable => line.type === 'set_variable'
// todo: test
const isDataCollectionStepLine = (line: any): line is TranscriptItemDataCollection => line.type === 'data_collection'
const isFunctionStepLine = (line: any): line is TranscriptItemFunction => line.type === 'function'
const isSMSStepLine = (line: any): line is TranscriptItemSMS => line.type === 'send_sms'
const isRedirectStepLine = (line: any): line is TranscriptItemRedirect => line.type === 'redirect'

const checkIfIsBot = (transcriptLine: ConversationLine) => transcriptLine.actor?.toLowerCase() === 'bot';

function RenderTranscriptLine({
    line,
    label,
    onPlay,
    setShowModal,
    setEventId,
    question,
    withDebugMode,
    stepLink
}: {
    line: ConversationLine;
    label?: string;
    setShowModal: (showModal: boolean) => void;
    setEventId: (eventId: string | number) => void;
    question: { stepId: string; stepName: string; };
    withDebugMode: boolean;
    stepLink: string;
    onPlay(): void;
}) {
    const statementUnderstood = line?.text?.length > 0;
    const isBot = checkIfIsBot(line);
    const isHuman = !isBot

    if (isBot && !statementUnderstood) {
        return null;
    }

    const renderPlayPartButton = () => (
        <div className={styles.jumpToButton} onClick={onPlay} data-test="conversation-details-play-part-button">
            <PlayCircleIcon animationClass={styles.animationClass} />
        </div>
    )

    return (
        <>
            <li
                data-test="conversation-transcript-statement"
                data-sensitive="true"
                className={classNames(
                    styles.line,
                    isHuman ? styles.human : styles.bot,
                    statementUnderstood ? undefined : styles.notUnderstood
                )}
            >
                {isBot && renderPlayPartButton()}
                <div>
                    <div data-test="conversation-transcript-line" data-test-actor={line.actor} className={styles.bubble}>
                        {
                            withDebugMode && isBot && question?.stepName && <Link
                                to={stepLink}
                                target="_blank"
                                data-test="conversation-transcript-line-step-link"
                                className={styles.questionName}
                            >
                                {question?.stepName}
                            </Link>
                        }
                        {statementUnderstood ? line.text : <FormattedMessage id="callDetails.statementNotRecognized" />}
                    </div>
                    {label && (
                        <span data-test="conversation-transcript-label" className={styles.label}>
                            {label}
                        </span>
                    )}
                </div>
                {isHuman && renderPlayPartButton()}
                {
                    isHuman && (
                        <div className={styles.reportBugButton} onClick={() => {
                            setEventId(line.timestamp);
                            setShowModal(true);
                        }} data-test="report-utterance-button">
                            <ReportBugIcon animationClass={styles.animationClass} />
                        </div>
                    )
                }
            </li>
            {withDebugMode &&
                <InterpretationStepLine interpretation={line?.interpretation} />}
        </>
    );
}

export function getTimeRangeForStatement(
    transcript: ReadonlyArray<ConversationLine>,
    index: number,
    callDuration?: number,
): {
    start: number;
    duration: number;
} {
    const currentTranscriptLine = transcript[index];

    if (checkIfIsBot(currentTranscriptLine)) {
        return {
            start: (getStartForBot(currentTranscriptLine) - transcript[0].timestamp) / 1000,
            duration: getDurationForBot(transcript, index, callDuration),
        };
    }

    if (transcript.some(line => line.recognitionStartedAt > 0)) {
        return {
            start: (getStartForHuman(currentTranscriptLine) - transcript[0].timestamp) / 1000,
            duration: getDurationForHuman(transcript, index),
        };
    }
    return {
        start: getStartForHumanFallback(transcript, index) / 1000,
        duration: getDurationForHumanFallback(transcript, index),
    };
}

// Fallback for old conversations without recognitionStartedAt
const getDurationForHumanFallback = (transcript: ReadonlyArray<TranscriptLine>, index: number) => {
    function getTranscriptLineStartRelativeToFirstLine(transcriptIndex: number) {
        return (transcript[transcriptIndex].timestamp - transcript[0].timestamp) / 1000;
    }

    const previousTranscriptLineStart = index - 1 >= 0 ? getTranscriptLineStartRelativeToFirstLine(index - 1) : 0;

    return (index + 1 < transcript.length
        ? getTranscriptLineStartRelativeToFirstLine(index + 1) - previousTranscriptLineStart
        : Number.POSITIVE_INFINITY);
};

// Fallback for old conversations without recognitionStartedAt
const getStartForHumanFallback = (transcript: ReadonlyArray<ConversationLine>, index: number) => (
    index === 0 ? 0 : (transcript[index - 1].timestamp - transcript[0].timestamp)
);

const getStartForHuman = (transcriptLine: ConversationLine) => transcriptLine.recognitionStartedAt;

const getStartForBot = (transcriptLine: ConversationLine) => transcriptLine.timestamp;

const getDurationForHuman = (transcript: ReadonlyArray<ConversationLine>, index: number) => (
    (transcript[index].timestamp - transcript[index].recognitionStartedAt) / 1000
);

const getDurationForBot = (transcript: ReadonlyArray<ConversationLine>, index: number, callDuration?: number) => (
    (index + 1) < transcript.length
        ? (transcript[index + 1].timestamp - transcript[index].timestamp) / 1000
        : callDuration - ((transcript[index].timestamp - transcript[0].timestamp) / 1000)
);
