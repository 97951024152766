import * as React from 'react';
import { IntlProvider } from 'react-intl';

import { useCurrentBot } from '../utils/CurrentBot';

import { i18nConfig } from './i18n';
import { getBrowserLanguage, mapBotLanguage, mapBotMessagesLanguage } from './language';
import { setLocale } from './momentLocale';

type Props = {
    children?: React.ReactNode;
};

export function CustomIntlProvider(props: Props) {
    const currentBot = useCurrentBot();
    const language = (currentBot?.voiceConfig?.languageCode && mapBotLanguage(currentBot.voiceConfig.languageCode)) ?? getBrowserLanguage();
    const messagesLanguage = (currentBot?.voiceConfig?.languageCode && mapBotMessagesLanguage(currentBot.voiceConfig.languageCode)) ?? getBrowserLanguage();

    // this is setting global moment locale for the whole application,
    // needs to be done before any life-cycle event in child components
    // so, componentDidUpdate/useEffect are too late for this
    // for the quick fix - this solution is ok-ish, as it's just setting flag in moment
    // the other problem is, that in general situation, when there is nothing between
    // this component and usage of moment that will force react tree to do re-render, this won't work
    // as react is not aware of changes in moment config
    // in long term we should not use global moment locale in the application
    // date formatting should be done either by react-intl or by context injected moment instance
    setLocale(language);
    return (
        <IntlProvider locale={language} messages={i18nConfig[messagesLanguage]}>
            {props.children}
        </IntlProvider>
    );
}
