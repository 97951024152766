import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import {useCallback, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'

import {useAudioPlayer} from '../../../surveyCreator/components/useAudioPlayer'
import {useCancelChanges, usePatchConfig, useSurveyConfig, useSurveyScheduleStatus} from '../Settings.hooks'
import {updateModifiedConfig} from '../store/actions'

import voices from './Voice/voices.data';
import {useIntl} from 'react-intl';

export const useGeneralTabProps = () => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const {savedConfig, modifiedConfig, displayedConfig, ...meta} = useSurveyConfig()
    const {isSurveyInProgress} = useSurveyScheduleStatus();
    const [voiceSampleText, setVoiceSampleText] = useState(intl.messages['survey.config.voiceConfig.defaultContent'] as string);

    const updateVoiceConfigProperty = useCallback((propertyName: string, newValue: string) => {
        dispatch(updateModifiedConfig(merge(cloneDeep(modifiedConfig), {
            voiceConfig: {[propertyName]: newValue}
        })));
    }, [modifiedConfig, dispatch])

    useEffect(() => {
        if (!meta.isReady || !Boolean(modifiedConfig?.voiceConfig?.languageCode)) {
            return;
        }

        const voiceLanguage = modifiedConfig.voiceConfig.languageCode;
        const voiceNameOptions = voiceLanguage
            ? voices.filter(el => el.languageCodes.includes(voiceLanguage))
            : [];

        if (voiceNameOptions.map(el => el.name).includes(modifiedConfig.voiceConfig.voiceName)) {
           return;
        }

        updateVoiceConfigProperty('voiceName', _.head(voiceNameOptions).name);
    }, [meta.isReady, modifiedConfig?.voiceConfig?.languageCode]);

    const updateTimezone = useCallback((timeZone: string) => {
        dispatch(updateModifiedConfig(merge(cloneDeep(modifiedConfig), {
            timeZone
        })))
    }, [modifiedConfig, dispatch])

    const updateFlag = useCallback((value: boolean, flag: string) => {
        dispatch(updateModifiedConfig(merge(cloneDeep(modifiedConfig), {
            [flag]: value
        })))
    }, [modifiedConfig, dispatch])

    const onSave = usePatchConfig();
    const onCancel = useCancelChanges();

    const handleSynthesize = useAudioPlayer({
        url: `/voice/synthesize-sample`,
        params: {
            text: encodeURIComponent(voiceSampleText),
            ...displayedConfig?.voiceConfig
        }
    });

    return {
        displayedConfig,
        voiceSampleText,
        isSurveyInProgress,
        setVoiceSampleText,
        updateVoiceConfigProperty,
        updateTimezone,
        updateFlag,
        onSave,
        onCancel,
        handleSynthesize,
        ...meta
    }
}
