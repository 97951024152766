import * as FileSaver from 'file-saver';
import _, {constant, Dictionary} from 'lodash';
import moment from 'moment';
import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {IntlShape, useIntl} from 'react-intl';

import {Scrollbar} from '../components/Scrollbar/Scrollbar';
import {StatisticsChart} from '../components/statisticsChart/StatisticsChart';
import {InformationIcon} from '../icons/InformationIcon';
import {StatisticsIntentsIcon} from '../icons/StatisticsIntentsIcon';
import {StatisticsScenarioIcon} from '../icons/StatisticsScenarioIcon';
import {Header} from '../layout/Header';
import {MainPage} from '../layout/MainPage';
import {useAsyncFn} from '../utils/asyncUtils';
import {useCurrentBot} from '../utils/CurrentBot';
import {nowInTimezone} from '../utils/DateService';
import {HttpClient} from '../utils/HttpClient';
import { useSurveyConfig } from '../views/Settings/Settings.hooks';
import { useFilters } from '../filters/useFilters';

import {NumberStatisticsSection} from './NumberStatisticsSection';
import {periodToRange} from './period';
import {Period} from './PeriodSelect';
import {PeriodStatistics} from './PeriodStatistics';
import styles from './Statistics.pcss';
import {StatisticsFiltersView} from './StatisticsFiltersView';
import { ChartTile } from './tile/ChartTile';
import { StatisticsSummarySection } from './StatisticsSummarySection';
import { ScenarioStepsDashboard } from './ScenarioStepsDashboard/ScenarioStepsDashboard';
import { StatisticsSectionsNavigation } from './StatisticsSectionsNavigation/StatisticsSectionsNavigation';
import { ScenarioStepMetric } from './types';
import { StatisticsSectionItem } from './StatisticsSectionItem';
import { StatisticsSectionBox } from './StatisticsSectionBox';

export interface StatisticsSummary {
  readonly averageCallDurationSeconds?: number;
  readonly uniquePhoneNumbersCount?: number;
}

export interface StatisticsFilters {
  readonly period: Period | null;
  readonly startDate?: string;
  readonly endDate?: string;
  readonly phrase?: string;
  readonly callDurationMin: string | null;
  readonly callDurationMax: string | null;
}

export type ConfigurationItem = {
  id: string;
  type: string;
  metrics: string[];
  metricId: string;
  metricName?: string;
  metricCategory?: string;
};

const tabSections = [
  {name: 'surveyQuestions', icon: StatisticsScenarioIcon},
  {name: 'recognizedIntents', icon: StatisticsIntentsIcon},
  {name: 'information', icon: InformationIcon},
  {name: 'results', icon: InformationIcon},
];
const redirectSection = {name: 'redirects', icon: InformationIcon}
const allSections = [
  {name: 'summary', icon: InformationIcon}, 
  redirectSection,
  ...tabSections
];

const SURVEY_QUESTIONS_TAB_CATEGORIES = ['surveyQuestions', 'fallbacks', 'silenceFallbacks'];

function isRedirectMetric(item: ConfigurationItem) {
  return item.metricId === 'call_redirected'
}

const dateRangeSpecification = (range: number, intl: IntlShape) => {
  const daysRange = (timestamp: number) => moment.duration(timestamp).asDays();
  const monthsRange = (timestamp: number) => moment.duration(timestamp).asMonths();
  const lessDaysThan = (days: number) => (timestampRange: number) => daysRange(timestampRange) <= days;
  const moreMonthsThan = (months: number) => (timestampRange: number) => monthsRange(timestampRange) >= months;

  const datesFormat = _.cond([
    [lessDaysThan(1), constant({period: 'hourly', format: intl.messages['hour.format'] as string})],
    [lessDaysThan(7), constant({period: 'hourly', format: intl.messages['timestamp.format'] as string})],
    [moreMonthsThan(2), constant({period: 'monthly', format: intl.messages['month.format'] as string})],
    [_.stubTrue, constant({period: 'daily', format: intl.messages['date.format'] as string})]
  ]);

  return datesFormat(range);
};

const pickMetrics = (statistic: PeriodStatistics, metricId: string) => ({
  ..._.pick(statistic, metricId),
  ..._.pick(statistic.information, metricId),
  ..._.pick(statistic.results, metricId),
  ..._.pick(statistic.surveyQuestions, metricId),
  ..._.pick(statistic.recognizedIntents, metricId)
});

export function Statistics() {
  const currentBot = useCurrentBot();
  const intl = useIntl();
  const [configuration, setConfiguration] = useState<ConfigurationItem[]>([]);
  const [statistics, setStatistics] = useState<PeriodStatistics[]>([]);
  const [statisticsSummary, setStatisticsSummary] = useState<StatisticsSummary>({});
  const [activeSection, setActiveSection] = useState<string>();
  const { displayedConfig } = useSurveyConfig();

  const defaultFilters: StatisticsFilters = {
    ...periodToRange(displayedConfig?.timeZone).day(nowInTimezone(displayedConfig?.timeZone)),
    callDurationMin: null,
    callDurationMax: null,
    phrase: '',
    period: 'custom',
  };
  const { filters, setFilters } = useFilters<StatisticsFilters>(defaultFilters, { ...defaultFilters, period: 'day' });

  const statisticsForSections = useMemo(
    () => {
      const isSurveyBot = currentBot.type === 'survey';
      const config = configuration.filter(item => !isRedirectMetric(item))
      const redirectMetric = configuration.find(isRedirectMetric)
      const redirectSectionConfig = {
        section: redirectSection,
        tabSection: false,
        statistics: [redirectMetric],
      }

      const sections = allSections
        .map(section => ({
          section,
          tabSection: tabSections.some(el => el.name === section.name),
          statistics: config
            .filter(conf => {
              const defaultCategory = conf.metricCategory;
              const category = isSurveyBot && defaultCategory === 'information' ? 'summary' : defaultCategory;

              if (section.name === 'surveyQuestions') {
                return conf.type === 'number' && (SURVEY_QUESTIONS_TAB_CATEGORIES.includes(category))
              }

              return conf.type === 'number' && category === section.name
            })
        }))
        .filter(el => el.statistics.length > 0)

      return [
        ...sections,
        ...(redirectMetric ? [redirectSectionConfig] : []),
      ];
    },
    [configuration, currentBot]
  );

  const fetchConfiguration = useCallback(() => {
    return HttpClient.get({
      path: `/bots/${currentBot.id}/statistics-configuration`,
      params: {
        timezone: displayedConfig?.timeZone
      }
    }).then(({data}) => {
      setConfiguration(data);
    });
  }, [currentBot.id, displayedConfig, setConfiguration]);

  useEffect(() => {
    if (!activeSection) {
      const section = statisticsForSections.find(el => el.tabSection);
      setActiveSection(section?.section?.name);
    }
  }, [configuration, statisticsForSections, activeSection]);

  const selectedDatesRangePeriod = useCallback(() => {
    const range = moment(filters.endDate).valueOf() - moment(filters.startDate).valueOf();
    return dateRangeSpecification(range, intl).period;
  }, [filters.endDate, filters.startDate]);

  const fetchForRange = useCallback(() => {
    return HttpClient.get({
      path: `/bots/${currentBot.id}/statistics`,
      params: {
        from: moment(filters.startDate).valueOf(),
        to: moment(filters.endDate).valueOf(),
        period: selectedDatesRangePeriod(),
        timezone: displayedConfig?.timeZone,
        phrase: filters.phrase || undefined,
        callDurationMin: filters.callDurationMin,
        callDurationMax: filters.callDurationMax,
      }
    });
  }, [currentBot.id, displayedConfig, filters, selectedDatesRangePeriod]);

  const {
    callback: loadData,
    cancelPendingTasks
  } = useAsyncFn(
    fetchForRange,
    [currentBot.id, filters, displayedConfig]
  );

  useEffect(() => {
    if (configuration.length > 0) {
      loadData()
        .then((response) => {
          setStatistics(response.data?.timeSeries || []);
          setStatisticsSummary(response.data?.summary || {});
        });
    }
    return cancelPendingTasks;
  }, [filters, loadData, configuration, cancelPendingTasks, setStatistics]);

  useEffect(() => {
    setConfiguration([]);
    setStatistics([]);
    setActiveSection(undefined);
    fetchConfiguration();
  }, [currentBot.id]);

  const numberValue = useCallback((metricId: string): number => {
    return statistics
      .map(statistic => pickMetrics(statistic, metricId))
      .reduce((sum, currentStatistic) => (currentStatistic[metricId] || 0) + sum, 0);
  }, [statistics]);

  const dataRangeSpecification = () => {
    const timestamps = statistics.map(statistic => statistic.timestamp);
    const range = Math.max(...timestamps) - Math.min(...timestamps);
    return dateRangeSpecification(range, intl);
  };

  const formatStatistics = (selectedMetricId: string, timezone: string) => {
    const format = dataRangeSpecification().format;
    return statistics
      .sort((s1, s2) => s1.timestamp - s2.timestamp)
      .map(statistic => ({
        ...pickMetrics(statistic, selectedMetricId),
        date: moment.tz(statistic.timestamp, timezone).format(format)
      }));
  };

  const downloadReport = useCallback(async () => {
    const {data} = await HttpClient.get({
      path: `/bots/${currentBot.id}/statistics/report`,
      params: {
        from: moment(filters.startDate).valueOf(),
        to: moment(filters.endDate).valueOf(),
        timezone: displayedConfig?.timeZone,
        phrase: filters.phrase || '',
        callDurationMin: filters.callDurationMin,
        callDurationMax: filters.callDurationMax,
      }
    }, {responseType: 'blob'});
    FileSaver.saveAs(new Blob([data]), `${intl.messages['statistics.report.fileName']}.xlsx`);
  }, [currentBot.id, displayedConfig, filters]);

  const getStatisticsFor = (forSection: string) => {
    const statisticsFor = statisticsForSections.find(statistic => statistic.section.name === forSection);
    return statisticsFor?.statistics || [];
  };

  const statisticsSections = useMemo(() => statisticsForSections
    .filter(({ tabSection }) => tabSection)
    .map(({ section }) => section), [statisticsForSections])

  if (_.isEmpty(configuration)) {
    return (
      <MainPage>
        <Header />
      </MainPage>
    );
  }

  const minutesUsed = configuration.find(conf => conf.type === 'chart-addon')
  
  function mapMetricToSum({ metricId }: ConfigurationItem) {
    return {
      name: metricId,
      value: numberValue(metricId),
    };
  }

  function convertSecondsToMinutes(value: number) {
    if (!value) {
      return '0:00';
    }
    return `${Math.floor(value / 60)}:${Math.ceil(value % 60).toString().padStart(2, '0')}`;
  }

  const summaryStatistics = getStatisticsFor('summary').map(mapMetricToSum);
  const redirectStatistics = getStatisticsFor('redirects').map(mapMetricToSum);

  return (
    <MainPage>
      <Header />
      <Scrollbar>
        <div className={styles.statistics}>
          <div className={styles.statisticsFilters}>
            <StatisticsFiltersView setFilters={setFilters} filters={filters} onReport={downloadReport} timezone={displayedConfig?.timeZone} />
          </div>

          <div className={styles.chart}>
            {configuration
              .filter(conf => conf.type === 'chart')
              .map(conf => (
                <StatisticsChart
                  key={conf.id}
                  title={intl.formatMessage({id: `statistics.chart.${dataRangeSpecification().period}`})}
                  data={formatStatistics(conf.metricId, displayedConfig?.timeZone)}
                  series={[{
                    key: conf.metricId,
                    name: intl.formatMessage({id: `conversations.filter.${conf.metricId}`}) || conf.metricName,
                  }]}
                />
              ))}
              {minutesUsed && <div className={styles.chartAddonsContainer} data-test="statistics-chart-addons">
                  <ChartTile 
                    title={intl.formatMessage({ id: 'statistics.addons.minutesUsed' })} 
                    tooltip={intl.formatMessage({ id: 'statistics.addons.tooltip.minutesUsed' })} 
                    value={numberValue(minutesUsed.metricId)}
                  />
              </div>}
          </div>
          <div
            className={styles.summaryStatistics}
            data-test={'summary-number-statistics'}
          >
            <StatisticsSectionBox dataTest="summary-number-statistics-main" isWide={summaryStatistics.filter(({ value }) => value > 0).length > 3}>
              <StatisticsSummarySection withPercentage withSummary metrics={summaryStatistics} />
            </StatisticsSectionBox>
            {redirectStatistics?.[0]?.value > 0 && (
              <StatisticsSectionBox dataTest="summary-number-statistics-redirects">
                <StatisticsSectionItem metric={{name: redirectStatistics?.[0]?.name, value: redirectStatistics?.[0]?.value }} />
              </StatisticsSectionBox>
            )}
            {statisticsSummary.averageCallDurationSeconds > 0 && (
              <StatisticsSectionBox dataTest="summary-number-statistics-average-call-duration">
                <StatisticsSectionItem metric={{name: 'average_call_duration', value: convertSecondsToMinutes(statisticsSummary.averageCallDurationSeconds) }} postfix="min" />
              </StatisticsSectionBox>
            )}
            {!!statisticsSummary.uniquePhoneNumbersCount && (
              <StatisticsSectionBox dataTest="summary-number-statistics-unique-callers-count">
                <StatisticsSectionItem metric={{ name: 'unique_callers_count', value: statisticsSummary.uniquePhoneNumbersCount }} />
              </StatisticsSectionBox>
            )}
          </div>
          <div className={styles.sectionsContainer}>
            {activeSection === 'surveyQuestions' ? (
              <ScenarioStepsDashboard
                // remount component when bot changes to clear its state
                key={currentBot.id} 
                scenarioStepMetrics={mapStatisticsForScenarioStepsDashboard({
                  statistics: [
                    ...getStatisticsFor('surveyQuestions'),
                    ...getStatisticsFor('fallbacks'),
                    ...getStatisticsFor('silenceFallbacks')
                  ],
                  calculateMetricValue: numberValue
                })}
                { ...{ statisticsSections, activeSection, setActiveSection } }
              />
            ) : (
              activeSection && (
                <>
                  <StatisticsSectionsNavigation {... { statisticsSections, activeSection, setActiveSection }} />
                  <div
                    className={styles.sectionContent}
                    data-test={'tab-statistics'}
                  >
                    <NumberStatisticsSection
                      statistics={getStatisticsFor(activeSection)}
                      calculateValue={numberValue}
                    />
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </Scrollbar>
    </MainPage>
  );
}

function mapStatisticsForScenarioStepsDashboard({
  statistics,
  calculateMetricValue,
}: {
  statistics: ConfigurationItem[];
  calculateMetricValue: (metricId: string) => number;
}): Dictionary<ScenarioStepMetric> {
  return statistics.reduce((acc, statistic) => {
    const id = statistic.metricId

    acc[id] = {
      id,
      name: statistic.metricName,
      value: calculateMetricValue(statistic.metricId),
    };

    return acc;
  }, {} as Dictionary<ScenarioStepMetric>);
}
