import * as React from 'react';

import { AnswersList } from '../../../../components/AnswersList/AnswersList';
import { EntityAnswer } from '../../../model';
import { GlobalSynonymsStore } from '../../../store/globalSynonyms/reducer';
import { EntityAnswerComponent } from '../EntityAnswer/EntityAnswerComponent';

export type EntityAnswersListProps = {
  answers: EntityAnswer[];
  onChange: (answers: EntityAnswer[]) => void;
  globalWords?: GlobalSynonymsStore['data'][any];
}

export const EntityAnswersList: React.FC<EntityAnswersListProps> = (props) => <AnswersList
  {...props}
  draggableDataTest="entity-answers"
  AnswerComponent={EntityAnswerComponent}
/>;