import React from 'react';

import {IconProps} from './iconTypes';

export function SmsStepIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H24V24H0z"/>
                <path className={props.animationClass} d="M5.924 1.519c.336 0 .608.272.608.608 0 .302-.22.552-.51.6l-.098.007H3.375c-1.14 0-2.075.885-2.154 2.006l-.006.154v13.333h10.936v-3.949c0-.335.273-.607.608-.607.303 0 .553.22.6.509l.008.098v6.348c0 1.801-1.412 3.273-3.189 3.369L9.993 24H3.375c-1.801 0-3.273-1.411-3.37-3.189L0 20.626V4.894c0-1.802 1.412-3.273 3.19-3.37l.185-.005h2.55zm6.227 17.924H1.215v1.183c0 1.14.885 2.074 2.006 2.153l.154.006h6.618c1.14 0 2.074-.885 2.153-2.005l.006-.154-.001-1.183zM20.354 0C22.368 0 24 1.632 24 3.646v4.557c0 2.013-1.632 3.645-3.646 3.645h-.508l-3.619 3.62c-.359.358-.956.143-1.03-.332l-.007-.098v-3.19H12c-1.948 0-3.54-1.528-3.64-3.452l-.006-.193V3.646C8.354 1.632 9.987 0 12 0zm0 1.215H12c-1.342 0-2.43 1.088-2.43 2.43v4.558c0 1.342 1.088 2.43 2.43 2.43h3.797c.336 0 .608.272.608.608v2.329l2.76-2.76c.091-.09.209-.15.334-.17l.096-.007h.76c1.342 0 2.43-1.088 2.43-2.43V3.646c0-1.343-1.088-2.43-2.43-2.43z"/>
            </g>
        </svg>
    );
}
