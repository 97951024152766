import React from 'react';

import {IconProps} from './iconTypes';

export function AddNavigationStepIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g transform="translate(-521 -662) translate(365 654) translate(156 8)">
                <path d="M0 0H24V24H0z"/>
                <path className={props.animationClass} fillRule="nonzero" d="M19.256 3.096L3.778 9.514c-.513.213-.826.736-.772 1.288l.019.126c.099.494.486.887.988.988l6.725 1.344 1.346 6.726c.11.545.562.954 1.114 1.008.552.054 1.075-.26 1.288-.772l6.418-15.477c.195-.472.087-1.015-.273-1.375-.361-.361-.904-.47-1.375-.274zm.466 1.125c.016-.006.035-.003.047.01.013.012.016.03.01.047L13.36 19.755c-.007.018-.025.029-.044.027-.02-.002-.035-.016-.039-.035l-1.425-7.123c-.048-.24-.236-.43-.477-.477l-7.123-1.425c-.019-.004-.033-.02-.035-.039-.002-.019.01-.037.027-.044L19.722 4.22z"/>
              </g>
            </g>
          </g>
        </g>
      </svg>

    );
}
