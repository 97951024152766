import { Loadable } from '../../../../reducers/types';
import { EntityAnswer } from '../../../model';
import { variableResolver } from './variableResolver';
import { useContext, useMemo } from 'react';
import { SurveyContext, SurveyContextType } from '../../../SurveyCreator';

const parseEntitiesDetails = (entitiesDetails: Pick<{ [p: string]: Loadable<EntityAnswer[]> }, string>, processEntities: boolean) => {
    return Object.entries(entitiesDetails).map(([entityName, payload]) => ({
        name: entityName,
        values: processEntities ? payload?.data || [] : [],
    }));
}

export const useVariableResolver = ({ shouldProcessEntities = true }: { shouldProcessEntities?: boolean } = {}) => {
    const { variables, steps, intents, entities, executableFunctions } = useContext<SurveyContextType>(SurveyContext);

    const parsedEntities = useMemo(() => parseEntitiesDetails(entities.details(), shouldProcessEntities), [entities]);

    const resolvedVariablesTypes = useMemo(() => variableResolver({
        steps: steps.get(),
        intents: intents.get(),
        variables: variables.get(),
        entities: parsedEntities,
        executableFunctions: executableFunctions.get(),
    }), [steps, intents, variables, parsedEntities, executableFunctions]);

    return { resolvedVariablesTypes };
};
