import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';

import {ConfirmationModal} from '../../../../components/ConfirmationModal/ConfirmationModal';
import {BorderedElementWithLabel} from '../../../../components/ElementWithLabel/BorderedElementWithLabel';
import {Vertically} from '../../../../components/layouts/Vertically';
import {Scrollbar} from '../../../../components/Scrollbar/Scrollbar';
import {currentUserRoles} from '../../../../selectors';
import {useCurrentBot} from '../../../../utils/CurrentBot';
import {UserRole} from '../../../../utils/CurrentUser';
import {IntentSentence, SurveyIntent} from '../../../model';

import {DuplicateSentencesPopup} from './DuplicateSentencesPopup';
import styles from './IntentTraining.pcss';
import {GroupedSentences, IntentTrainingService} from './IntentTrainingService';
import {SentenceList} from './SentenceList';
import {TrainingDataInput} from './TrainingDataInput';
import {TrainingQueue} from './TrainingQueue';

type Props = {
  intent: SurveyIntent,
};

type RemoveGlobalSentenceState = {
  showModal: boolean;
  sentence?: string;
}

export const IntentTraining = ({intent}: Props) => {
  const currentBot = useCurrentBot();
  const isAdmin = useSelector(currentUserRoles).includes(UserRole.ADMIN);
  const trainingService = IntentTrainingService.get(currentBot, intent);
  const [localSentences, setLocalSentences] = useState([]);
  const [globalSentences, setGlobalSentences] = useState([]);
  const [enqueuedSentences, setEnqueuedSentences] = useState([]);
  const [duplicates, setDuplicates] = useState([]);
  const ref = useRef();
  const [showRemoveGlobalConfirmationModal, setRemoveGlobalConfirmationModal] = useState<RemoveGlobalSentenceState>({showModal: false});
  useEffect(() => {
    trainingService
      .fetchSentences()
      .then(onSentences);
  }, [currentBot.id]);

  const onSentences = (sentences: GroupedSentences) => {
    setGlobalSentences(sentences.global);
    setLocalSentences(sentences.local);
    setEnqueuedSentences(sentences.enqueued);
  };

  const onAddToQueue = async (sentences: IntentSentence[]) => {
    const {global, local, enqueued} = await trainingService.addSentences(sentences);
    const duplicatedData = [...global, ...local, ...enqueued];
    setDuplicates(duplicatedData);
    if (!duplicatedData.length) {
      const trainData = await trainingService.fetchSentences();
      onSentences(trainData);
    }
  };

  const onRemoveEnqueued = async (sentence: IntentSentence) => {
    await trainingService.removeSentence(sentence);
    setEnqueuedSentences(enqueuedSentences.filter(it => it !== sentence));
  };

  const onRemoveLocal = async (sentence: IntentSentence) => {
    await trainingService.removeSentence(sentence);
    setLocalSentences(localSentences.filter(it => it !== sentence));
  };

  const onRemoveGlobal = async () => {
    const sentence = showRemoveGlobalConfirmationModal.sentence;
    await trainingService.removeGlobalSentence(sentence);
    setGlobalSentences(globalSentences.filter(it => it !== sentence));
    setRemoveGlobalConfirmationModal({showModal: false});
  };

  const onStartTraining = async () => {
    await trainingService.train();
    const sentences = await trainingService.fetchSentences();
    onSentences(sentences);
  };

  const onCloseDuplicatesPopup = async () => {
    setDuplicates([]);
    const trainData = await trainingService.fetchSentences();
    onSentences(trainData);
  };

  return (
    <>
      <TrainingDataInput onAdd={onAddToQueue} />
        <Scrollbar hideHorizontal>
          <Vertically className={styles.insetSides}>
            {!!enqueuedSentences.length &&
            <TrainingQueue data-test={'training-queue'}
                           sentences={enqueuedSentences}
                           onRemove={onRemoveEnqueued}
                           onStartTraining={onStartTraining}
            />}

            <BorderedElementWithLabel data-test={'local'} labelId={'survey-creator.intentDetails.localSentences'}>
              <SentenceList isRemovable={true}
                            onRemove={onRemoveLocal}
                            type={'local'}
                            sentences={localSentences}
              />
            </BorderedElementWithLabel>

            <BorderedElementWithLabel data-test={'talkie'} labelId={'survey-creator.intentDetails.talkieSentences'} className={styles.lastElement}>
              <div ref={ref}>
                <SentenceList isRemovable={isAdmin}
                              onRemove={(sentence) => setRemoveGlobalConfirmationModal({
                                showModal: true,
                                sentence,
                              })}
                              type={'talkie'}
                              sentences={globalSentences}
                />
              </div>
            </BorderedElementWithLabel>
          </Vertically>
        </Scrollbar>
      {!!duplicates.length && <DuplicateSentencesPopup duplicates={duplicates} onClose={onCloseDuplicatesPopup} />}
      {
        showRemoveGlobalConfirmationModal.showModal &&
        <ConfirmationModal
          onConfirm={onRemoveGlobal}
          onCancel={() => setRemoveGlobalConfirmationModal({showModal: false})}
          parentRef={ref}
          renderText={() => (
            <div>
              <div>
                <FormattedMessage id={'survey-creator.intentDetails.removeGlobalSentenceText'} />
              </div>
              <div className={styles.removeGlobalSentence}>
                {showRemoveGlobalConfirmationModal.sentence}
              </div>
            </div>
          )}
        />
      }
    </>
  );
};
