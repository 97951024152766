import React from 'react';

import {IconProps} from './iconTypes';

export function CheckBigIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g fill="none" fillRule="evenodd">
        <g fill="#37BD3C" fillRule="nonzero">
          <g>
            <path d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0zm0 1.391C7.932 1.391 1.391 7.931 1.391 16c0 8.068 6.54 14.609 14.609 14.609 8.068 0 14.609-6.54 14.609-14.609 0-8.068-6.54-14.609-14.609-14.609zm8.594 7.029c.286.226.347.632.148.93l-.034.047-10.661 13.488c-.415.547-1.068.859-1.754.838-.656-.02-1.264-.341-1.641-.856l-3.383-4.801c-.221-.314-.146-.748.168-.97.298-.21.705-.152.935.123l.034.045 3.343 4.744c.131.197.35.317.586.324.216.007.423-.083.573-.251l.039-.048 10.67-13.499c.238-.301.675-.353.977-.114z" transform="translate(-463.000000, -108.000000) translate(463.000000, 108.000000)" />
          </g>
        </g>
      </g>
    </svg>
  );
}
