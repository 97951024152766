import React, { InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import styles from './Checkbox.pcss';

export function Checkbox({
  className,
  ...props
}: InputHTMLAttributes<HTMLInputElement>) {
  return (
    <input
      type="checkbox"
      className={classNames(styles.checkbox, className)}
      {...props}
    />
  );
}
