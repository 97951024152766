import classNames from 'classnames';
import omit from 'lodash/omit'
import React from 'react'

import { IconProps } from '../../icons/iconTypes'
import { StandardInitialProps } from '../types';
import { NotificationBadge } from '../NotificationBadge/NotificationBadge';

import styles from './IconButton.pcss'

type IconButtonProps = StandardInitialProps & {
  Icon: React.FC<IconProps>;
  onClick?: (e: React.MouseEvent) => void;
  dataTest?: string;
  disabled?: boolean;
  [key: string]: any;
  hoverType?: 'warning' | 'confirm' | 'regular';
  iconClass?: string;
  withBadge?: boolean;
}

export const IconButton: React.FC<IconButtonProps> = ({
  className,
  dataTest,
  Icon,
  onClick,
  hoverType,
  disabled = false,
  iconClass,
  withBadge,
  ...rest
}) => <button
  data-test={dataTest}
  className={classNames(
    styles.iconButton,
    className
  )}
  onClick={onClick}
  disabled={disabled}
  type="button"
>
    <Icon
      animationClass={classNames(
        styles.optionIcon,
        {
          [styles.warningIcon]: hoverType === 'warning',
          [styles.confirmIcon]: hoverType === 'confirm'
        },
        iconClass
      )}
      {...omit(rest, 'data-test')}
    />
    {withBadge && <NotificationBadge className={styles.badge} />}
  </button>