import React, { useCallback, useContext, useMemo } from 'react';
import { scroller } from 'react-scroll';
import { useIntl } from 'react-intl';

import { Box } from '../../../../../../components/Box/Box';
import { ElementWithLabel } from '../../../../../../components/ElementWithLabel/ElementWithLabel';
import { SelectGoToBlock } from '../../../../../components/SelectGoTo';
import { SynonymsChipsListComponent } from '../../../../../../components/chips/SynonymsChipsListComponent';

import {AnswerType, ReactionType} from '../../../../types';
import { AnswerEntityValueProps } from './types';

import { useGlobalSynonyms } from '../../../../hooks';
import { BlocksContext, DisambiguationContext } from '../../../../containers';
import { ModelToViewMapper } from '../../../../services';
import { specialReactions, getReactionFromDropdownOption } from '../../utils';

import styles from './AnswerEntityValue.pcss';

const AnswerEntityValue: React.FC<AnswerEntityValueProps> = ({
    value,
    entityId,
    onChange,
}) => {
    const { messages } = useIntl();

    // uses GlobalSynonymsContext
    const globalSynonyms = useGlobalSynonyms(value.key);

    const { blocks } = useContext(BlocksContext);

    const { disambiguation } = useContext(DisambiguationContext);

    const disambiguationItem = useMemo(() => {
        return disambiguation.find((item) => {
            return item.answers.find((answer) => {
                if (answer.type === AnswerType.DICTIONARY) {
                    return answer.id === entityId;
                }
                return answer.id === entityId && answer.key === value.key;
            });
        });
    }, [disambiguation, entityId, value.key]);

    const getCurrentReactionOptionId = useCallback(() => {
        if (!value.reaction) {
            return specialReactions.continue;
        }

        if (value.reaction.type === ReactionType.GOTO) {
            return value.reaction.moduleId;
        }

        return value.reaction.type;
    }, [value.reaction?.type]);


    const handleReactionChange = useCallback((id) => {
        const reaction = getReactionFromDropdownOption(id);
        onChange({ ...value, reaction });
    }, [value, onChange]);

    const handleScrollTo = useCallback(() => {
        // @TODO: leftover for debugging purposes (scrolling does not work)
        // const closestScroll = containerRef.current.closest('div[style*="overflow: scroll"]')
        const id = ModelToViewMapper.getDisambiguationIdentifier(disambiguationItem?.answers);
        scroller.scrollTo(id, {
            duration: 600,
            smooth: true,
            // containerId: closestScroll && closestScroll.id,
        });
    }, [disambiguationItem?.answers]);

    const renderGlobalSynonyms = () => {
        if (globalSynonyms.length === 0) {
            return null;
        }

        return <Box className={styles.globalSynonymsContainer} dataTest="global-synonyms">
            <ElementWithLabel
                inner
                noPadding
                labelId={'survey-creator.dictionary.chipsGlobalLabel'}
            >
                <SynonymsChipsListComponent items={globalSynonyms} showElementsNumber={4} />
            </ElementWithLabel>
        </Box>;
    }

    const renderLocalSynonyms = () => {
        if (value.synonyms.length === 0) {
            return null;
        }

        return <ElementWithLabel
            labelId={'survey-creator.dictionary.chipsLabel'}
            inner
        >
            <SynonymsChipsListComponent
                items={value.synonyms}
                showElementsNumber={1200}
                chipsItemClassName={styles.synonym}
            />
        </ElementWithLabel>
    }

    const renderDisambiguationInfo = () => {
        if (!disambiguationItem) {
            return null;
        }

        return <Box className={styles.disambiguationDescription}>
            <span>
                {messages['survey-creator.word.notPreciseLinkDescription']}
                <button
                    data-test={'not-precise-answer-link'}
                    className={styles.disambiguationLink}
                    onClick={handleScrollTo}
                >{messages['survey-creator.word.notPreciseLink']}</button>
            </span>
        </Box>;
    }

    // @TODO: Replace content with common layer with AnswerDictionary (AnswerBase component)
    return <div className={styles.entityValue}>
        <Box
            className={styles.answerBase}
            dataTest="answer"
            border
            noPadding
        >
            <div className={styles.answerKeyAndReaction}>
                <ElementWithLabel
                    inner
                    labelId="survey-creator.word.answerIdLabel"
                    className={styles.answerKey}
                >
                    <span className={styles.answerKeyText} data-test="answer-text">{value.key}</span>
                </ElementWithLabel>

                <ElementWithLabel
                    inner
                    labelId={'survey-creator.wordAnswerGoToLabel'}
                    className={styles.answerReaction}
                >
                    <div data-test="answer-go-to">
                        <SelectGoToBlock
                            onChange={handleReactionChange}
                            currentBlockId={getCurrentReactionOptionId()}
                            blocks={blocks}
                            includeContinue
                            specialOptionsIds={specialReactions}
                        />
                    </div>
                </ElementWithLabel>
            </div>

            {renderGlobalSynonyms()}

            {renderLocalSynonyms()}

            {renderDisambiguationInfo()}
        </Box>
    </div>;
}

export default AnswerEntityValue;
