import { useContext, useMemo } from 'react';
import { SurveyContext } from '../../surveyCreator/SurveyCreator';
import { useCreatorRouter } from '../../surveyCreator/useCreatorRouter';
import { QuestionType } from '../../surveyCreator/model';

export const useAllSteps = () => {
  const { blocks, fallback, silenceFallback, callbacks } = useContext(SurveyContext);
  const { getUrlForFallback, getUrlForSilenceFallback, getUrlForCallback, getUrlForBlock } = useCreatorRouter();

  const modules = blocks?.get()?.map((block) => ({
    id: block.id,
    name: block.name,
    href: getUrlForBlock(block.id),
    steps: block.questions
  }));

  const fallbackObjects = useMemo(() => [fallback.getAsBlock()]
    .map((block) => ({
      id: block.id,
      name: block.name,
      href: getUrlForFallback(),
      steps: block.questions
    })), [fallback]);

  const silenceFallbackObjects = useMemo(() => [silenceFallback.getAsBlock()]
    .map((block) => ({
      id: block.id,
      name: block.name,
      href: getUrlForSilenceFallback(),
      steps: block.questions
    })), [silenceFallback]);

  const callbackObjects = Object.values(callbacks.all).map((callback) => {
    return {
      id: callback.id,
      name: callback.name,
      href: getUrlForCallback(callback.id),
      steps: callback.questions
    }
  });

  return [...modules, ...fallbackObjects, ...silenceFallbackObjects, ...callbackObjects].map(module => module.steps
    .map(step => step.type === QuestionType.CONDITIONAL ? {
      ...step.question,
      id: step.id,
      name: step.name
    } : step)
    .map(step => ({
      ...step,
      moduleName: module.name,
      href: `${module.href}/${step.id}`,
    })
    )).flat();
}