import _ from 'lodash';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import { useUnsavedChanges } from '../surveyCreator/components/useUnsavedChanges';
import { useCurrentBot } from '../utils/CurrentBot';
import { HttpClient } from '../utils/HttpClient';
import { useSurveyConfig } from '../views/Settings/Settings.hooks';
import { SchedulerSteps } from './steps/SchedulerSteps';
import {Button} from '../components/Button/Button';
import { Schedule } from './schedule-model';
import { SchedulerSettings } from './schedule-settings/SchedulerSettings';
import {Box} from '../components/Box/Box';
import {useDialerContext} from '../dialer/context/DialerContext';

import styles from './Scheduler.pcss';
import { useQueryClient } from 'react-query';
import { getUseGetSchedulerStatusQueryKey } from './scheduler-api';

export const Scheduler = () => {
    const queryClient = useQueryClient();
    const intl = useIntl();
    const currentBot = useCurrentBot();
    const [schedule, setSchedule] = useState<Schedule>(null);
    const [hasPendingContact, setHasPendingContact] = useState<boolean>(false);
    const [changedSchedule, setChangedSchedule] = useState<Schedule>(null);
    const {setHasSchedule} = useDialerContext();

    const onScheduleChanged = (s: Schedule) => {
        setHasSchedule(s && !_.isEmpty(s.interactionPlan) && !_.isEmpty(s.allowedDaysOfWeekRule?.daysOfWeek));
        setSchedule(s);
    }

    const saveScheduleSettings = () => {
        HttpClient.put({
            path: `/bots/${currentBot.id}/schedule`,
            body: changedSchedule
        }).then(() => onScheduleChanged(_.clone(changedSchedule)))
          .then(() => queryClient.invalidateQueries(getUseGetSchedulerStatusQueryKey(currentBot.id)))
    };

    const cancelScheduleSettings = useCallback(() => {
        setChangedSchedule(schedule);
    }, [setChangedSchedule, schedule]);

    useEffect(() => {
        HttpClient.get({ path: `/bots/${currentBot.id}/schedule` })
            .then(response => response.data)
            .then(newSchedule => {
                onScheduleChanged(newSchedule);
                setChangedSchedule(newSchedule || {});
            });
        HttpClient
            .get({ path: `/bots/${currentBot.id}/contacts`, params: { status: 'in_progress', limit: 1 } })
            .then(response => setHasPendingContact(response.data.contacts && response.data.contacts.length > 0));
    }, [currentBot]);

    const { isLoading, displayedConfig } = useSurveyConfig();

    const hasChanges = !_.isEqual(schedule, changedSchedule);

    const {
        containerRef,
        showModal,
        renderUnsavedChangesComponents
    } = useUnsavedChanges(hasChanges, intl.messages['unsavedChangesModal.text'] as string);

    const renderConfirmEditButtons = () => {
        if (!hasChanges) { return null }

        return (
            <Box border className={styles.buttonsRow}>
                <Button
                    dataTest={'cancel-schedule-settings-btn'}
                    buttonType="normal"
                    className={styles.button}
                    onClick={showModal}
                    disabled={!hasChanges}>
                    <FormattedMessage id={'scheduler.cancel'} />
                </Button>
                <Button
                    dataTest={'save-schedule-settings-btn'}
                    className={styles.button}
                    onClick={saveScheduleSettings}
                    disabled={!hasChanges}>
                    <FormattedMessage id={'scheduler.save'} />
                </Button>
            </Box>
       )
    }

    return (
        <>
            <div className={styles.content} ref={containerRef}>
                <div className={styles.leftPanel}>
                    <SchedulerSettings
                        schedule={changedSchedule}
                        setSchedule={setChangedSchedule}
                        timeZone={isLoading ? '' : displayedConfig?.timeZone}
                    />
                </div>
                <div className={styles.rightPanel}>
                    {renderConfirmEditButtons()}
                    <SchedulerSteps
                        schedule={changedSchedule}
                        setSchedule={setChangedSchedule}
                        hasPendingContact={hasPendingContact}
                        currentBotId={currentBot.id}
                    />
                </div>
            </div>
            {renderUnsavedChangesComponents(cancelScheduleSettings)}
        </>
    );
};
