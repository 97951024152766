import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Prompt} from 'react-router';

import {ConfirmationModal} from '../../components/ConfirmationModal/ConfirmationModal';

import styles from './UnsavedChanges.pcss';

export const useUnsavedChanges = (hasChanges: boolean, leaveMessage: string) => {
    const [overlayWidth, setOverlayWidth] = useState<number>(0);
    const [showModal, toggleModal] = useState<boolean>(false);
    const componentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setOverlayWidth(componentRef.current !== null ? componentRef.current.offsetLeft : 0);
    }, [componentRef.current, hasChanges, setOverlayWidth]);

    useEffect(() => {
        const confirmation = (e: any) => {
            if (hasChanges) {
                e.preventDefault();
                e.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', confirmation);
        return () => window.removeEventListener('beforeunload', confirmation);
    });

    return {
        containerRef: componentRef,
        showModal: () => toggleModal(true),
        renderUnsavedChangesComponents: (confirm: () => void) => {
            return (
                <>
                    {hasChanges && <div data-test={'unsaved-changes-overlay'} className={styles.overlay} style={{width: overlayWidth, left: -overlayWidth}}/>}
                    {showModal && (
                        <ConfirmationModal
                            onConfirm={() => {
                                toggleModal(false);
                                confirm();
                            }}
                            onCancel={() => toggleModal(false)}
                            renderText={() => <FormattedMessage id={'unsavedChangesModal.text'}/>}
                        />
                    )}
                    <Prompt message={leaveMessage} when={hasChanges}/>
                </>
            );
        }
    };
};
