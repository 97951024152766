import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './ScenarioStepsDashboardUserHelpTile.pcss';

type Props = {
  allStepsPinned: boolean;
};

export function ScenarioStepsDashboardUserHelpTile({ allStepsPinned }: Props) {
  return (
    <div
      className={styles.container}
      data-test="scenario-steps-dashboard-user-help-tile"
    >
      <p className={styles.text}>
        <FormattedMessage
          id={
            allStepsPinned
              ? 'statistics.scenarioStepsDashboard.userHelp.allStepsPinned.description'
              : 'statistics.scenarioStepsDashboard.userHelp.someStepsPinned.description'
          }
        />
      </p>
      <p className={styles.text}>
        <FormattedMessage
          id={
            allStepsPinned
              ? 'statistics.scenarioStepsDashboard.userHelp.allStepsPinned.callToAction'
              : 'statistics.scenarioStepsDashboard.userHelp.someStepsPinned.callToAction'
          }
        />
      </p>
    </div>
  );
}
