import React, { useContext, useMemo } from 'react';

import { DeleteIcon } from '../../../../icons';
import { Input } from '../../../../components/Input';
import { ElementWithLabel } from '../../../../components/ElementWithLabel/ElementWithLabel';
import { SelectComponent } from '../../../../components/Select/Select';
import { StringChipsComponent } from '../../../../components/chips/ChipsComponent';
import { Variables } from '../../../../surveyCreator/components/variables/Variables';

import { DictionaryVariable, Variable } from '../../../../surveyCreator/model';
import { typeOptions, VariableType } from '../../../../surveyCreator/components/variables/Variables.utils';

import { Mapping } from '../VariablesWithSipHeaderMappings.hook';

import { SipHeaderMappingsContext } from '../AdminTabContentContainer';
import styles from './HeaderMappingsComponent.pcss';

export const HeaderMappingComponent = ({mapping}: { mapping: Mapping }) => {
  const {
    changeVariableName,
    removeSipHeaderMapping,
    changeVariableValues,
    changeVariableType,
    changeVariableSipHeaderName,
    variables,
    assignVariableToMapping,
    createVariableForMapping,
  } = useContext(SipHeaderMappingsContext);
  const currentVariable = useMemo(() => variables.find(v => v.id === mapping.variableId), [mapping.variableId, variables]);

  const currentVariableTypeValueForSelect = useMemo(() => ({
    id: currentVariable?.type || VariableType.STRING,
    name: currentVariable?.type || VariableType.STRING,
  }), [currentVariable?.type]);

  return (
    <div data-test="sip-header" className={styles.mappingElement}>
      <div className={styles.mappingDefinitionRow}>
        <ElementWithLabel labelId={'settings.admin.sipHeaders.variable'} noPadding className={styles.mappingInput}
                          dataTest={'variable'}>
          <Variables
            options={variables}
            selectedOption={variables.find(v => v.id === mapping.variableId)}
            handleSelect={(variableId) => assignVariableToMapping(mapping.id, variableId)}
            handleCreate={(variableName) => createVariableForMapping(mapping.id, variableName)}
            handleRename={changeVariableName}
            filter={(v) => v.origin === 'step'}
          />
        </ElementWithLabel>
        <ElementWithLabel labelId={'settings.admin.sipHeaders.headerName'} noPadding className={styles.mappingInput}>
          <Input
            dataTest="sip-header-name"
            value={mapping.sipHeaderName}
            onChange={(sipHeaderName) => changeVariableSipHeaderName(mapping.id, sipHeaderName)}
          />
        </ElementWithLabel>
        <div className={styles.remove}>
          <div className={styles.fakeLabel}/>
          <div className={styles.icon}
               data-test="remove-mapping-button"
               onClick={() => removeSipHeaderMapping(mapping.id)}>
            <DeleteIcon/>
          </div>
        </div>
      </div>
      <ElementWithLabel labelId={'settings.admin.sipHeaders.variableType'} noPadding>
        <div className={styles.mappingType}>
          <SelectComponent
            isDisabled={!mapping.variableId}
            data-test={'variable-type'}
            options={typeOptions}
            onChange={(type) => changeVariableType(currentVariable.id, type.id)}
            value={currentVariableTypeValueForSelect}
          />
          {shouldDisplayPossibleValues(currentVariable) && (
            <StringChipsComponent
              data-test={'variable-values'}
              i18nKey={''}
              items={currentVariable?.values || []}
              onChange={(values) => changeVariableValues(currentVariable.id, values)}
            />
          )}
        </div>
      </ElementWithLabel>
    </div>
  );
};

function shouldDisplayPossibleValues(variable?: Variable): variable is DictionaryVariable {
  return variable?.type === VariableType.DICTIONARY;
}