import React, { } from 'react';
import { IntentTrainingBar } from '../IntentTrainingBar/IntentTrainingBar'
import { useTrainingBarProps, useTrainingStatusProps } from './IntentTrainingBarContainer.hooks';

export const IntentTrainingBarContainer = () => {
  const { trainingStatus } = useTrainingStatusProps();
  const {
    intentSuggestions,
    onChangeValue,
    intentInput,
    confidence,
    recognizedIntent,
    onAddUtterance,
    onOpenAddMultipleModal,
    onTrain,
    setSelectedIntent,
    selectedIntent,
    addSentences,
    isAddMultipleModalOpen,
    onCloseAddMultipleModal
  } = useTrainingBarProps();

  return <IntentTrainingBar
    status={trainingStatus}
    intentOptions={intentSuggestions}
    onIntentInputChange={onChangeValue}
    intentInputValue={intentInput}
    confidence={confidence}
    recognizedIntent={recognizedIntent}
    onAddUtterance={onAddUtterance}
    onAddMultiple={onOpenAddMultipleModal}
    onTrain={onTrain}
    onSetSelectedIntent={setSelectedIntent}
    selectedIntent={selectedIntent}
    onAddSentences={addSentences}
    isAddMultipleModalOpen={isAddMultipleModalOpen}
    onCloseAddMultipleModal={onCloseAddMultipleModal}
  />;
}