import * as React from 'react';
import {useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';

import {ConfirmationModal} from '../../../components/ConfirmationModal/ConfirmationModal';
import {CancelIcon} from '../../../icons';

import {MenuOption} from './OptionsMenu';

type DeleteOptionProps = {
    onDelete: (event: any) => void
}

export const RemoveGroupOption: React.FC<DeleteOptionProps> = ({onDelete}) => {
    const [showModal, toggleModal] = useState<boolean>(false);
    const ref = useRef();
    return (
        <>
                <MenuOption
                    dataTest="remove-group-option"
                    onClick={() => toggleModal(true)}
                    icon={CancelIcon}
                    hoverType={'warning'}
                    tooltipLabelId="menuOption.removeGroup.tooltip"
                />
            <div ref={ref}>
                {
                    showModal && (
                        <ConfirmationModal
                            onConfirm={(event) => {
                                onDelete(event);
                                toggleModal(false);
                            }}
                            onCancel={() => toggleModal(false)}
                            renderText={() => <FormattedMessage id={'survey-creator.removeIntentsGroup'}/>}
                            okLabelId={'survey-creator.removeIntentsGroup.confirm'}
                            cancelLabelId={'survey-creator.removeIntentsGroup.cancel'}
                            parentRef={ref}
                        />
                    )
                }
            </div>
        </>
    );
};
