import * as React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useFormik} from 'formik';
import classNames from 'classnames';
import {Box} from '../../components/Box/Box';
import {SelectComponent as Select} from '../../components/Select/Select';
import {Input} from '../../components/Input';
import {OrganizationSMSSettings, providerOptions} from './SMSSettings';
import {SuperVoipSMSSettingsFormFields} from './SuperVoipSMSSettingsFormFields';
import {VonageSMSSettingsFormFields} from './VonageSMSSettingsFormFields';
import {ParlanceSMSSettingsFormFields} from './ParlanceSMSSettingsFormFields';
import {TwilioSMSSettingsFormFields} from './TwilioSMSSettingsFormFields';
import {HorizontalSeparator} from '../../components/HorizontalSeparator/HorizontalSeparator';
import {VerticalSeparator} from '../../surveyCreator/components/VerticalSeparator';
import {DeleteIcon} from '../../icons';
import {IconButton} from '../../components/IconButton/IconButton';
import {Button} from '../../components/Button/Button';
import {useUpdateOrganizationSMSSettings} from './useUpdateOrganizationSMSSettings';
import styles from './SMSSettingsForm.pcss';
import {TestSMSModal} from './TestSMSModal';
import {TooltipComponent} from '../../components/Tooltip/Tooltip';
import {useTestSMS} from '../hooks/useTestSMS';

const emptySMSSettings = {
  provider: undefined,
  phoneNumber: '',
  providerConfiguration: {},
  overrides: [],
} as OrganizationSMSSettings;

type SMSSettingsFormProps = {
  organizationName: string;
  organizationBots: { id: string; name: string }[];
  organizationSMSSettings: OrganizationSMSSettings | undefined;
};

type SMSSelectValue = {
  id: string
  label: string
  name: string
  value: string
};

export function SMSSettingsForm({
  organizationName,
  organizationBots,
  organizationSMSSettings,
}: SMSSettingsFormProps) {

  const {mutateAsync: updateOrganizationSMSSettings} =
    useUpdateOrganizationSMSSettings(organizationName);

  const formik = useFormik<OrganizationSMSSettings>({
    initialValues: organizationSMSSettings ?? emptySMSSettings,
    onSubmit: async (values, { resetForm }) => {
      await updateOrganizationSMSSettings(values);
      resetForm({ values });
    },
  });
  const intl = useIntl();

  const selectableBots = organizationBots.filter(
    (bot) =>
      !formik.values.overrides.some((override) => override.botId === bot.id)
  );

  const {selectedConfiguration, setSelectedConfiguration, handleSendSMS} = useTestSMS(organizationName);


  function getOverrides(index: number, value: SMSSelectValue) {
    return formik.values.overrides.map((override, i) => {
          if (i !== index) {
            return override;
          }
          const sameProviderForOverrideAndMainConfig = value.id === formik.values.provider;
          return {
            provider: value.id,
            phoneNumber: '',
            botId: override.botId,
            providerConfiguration: sameProviderForOverrideAndMainConfig ? formik.values.providerConfiguration : undefined,
          }
        }
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      {formik.dirty && (
        <Box border className={styles.formActionButtons}>
          <Button
            buttonType="normal"
            type="button"
            onClick={() => formik.resetForm()}
            dataTest="cancel-sms-configuration"
          >
            <FormattedMessage id="organization.sms.cancel" />
          </Button>
          <Button type="submit" dataTest="save-sms-configuration">
            <FormattedMessage id="organization.sms.saveChanges" />
          </Button>
        </Box>
      )}

      <Box border className={styles.contentContainer}>
        <p className={styles.heading}>
          <FormattedMessage
            id="organization.sms.smsSettings.heading"
            values={{
              disclaimer: (
                <span className={styles.headingDisclaimer}>
                  <FormattedMessage id="organization.sms.smsSettings.disclaimer" />
                </span>
              ),
            }}
          />
        </p>

        <div className={styles.formGrid}>
          <div data-test="provider-select">
            <label htmlFor="provider" className={styles.label}>
              <FormattedMessage id="organization.sms.providerName" />
            </label>
            <Select
              options={providerOptions}
              onChange={(value) =>
                formik.setValues({
                  provider: value.id,
                  phoneNumber: '',
                  overrides: formik.values.overrides,
                } as OrganizationSMSSettings)
              }
              value={providerOptions.find(
                (option) => option.id === formik.values.provider
              )}
            />
          </div>
          <div>
            <label htmlFor="phoneNumber" className={styles.label}>
              <FormattedMessage id="organization.sms.phoneNumber" />
            </label>
                <Input
                    className={styles.input}
                    id="phoneNumber"
                    name="phoneNumber"
                    dataTest="phone-number-input"
                    onChange={(value) =>
                        formik.handleChange({
                          target: {name: 'phoneNumber', value: value},
                        })
                    }
                    value={formik.values.phoneNumber}
                />
          </div>
          {formik.values.provider === 'supervoip' && (
            <SuperVoipSMSSettingsFormFields formik={formik} />
          )}
          {formik.values.provider === 'vonage' && (
            <VonageSMSSettingsFormFields formik={formik} />
          )}
          {formik.values.provider === 'twilio' && (
            <TwilioSMSSettingsFormFields formik={formik} />
          )}
          {(formik.values.provider === 'parlance-helpdesk' || formik.values.provider === 'parlance-patient-access') && (
            <ParlanceSMSSettingsFormFields formik={formik} />
          )}
        </div>


          {formik.values.provider && (<div className={styles.testSms}>
              <TooltipComponent
                  tooltipText={intl.formatMessage({
                      id: 'organization.sms.testSms.tooltip.disabled',
                  })}
                  trigger={formik.dirty ? 'hover' : 'none'}
                  className={styles.cancelContactsButtonWrapper}
              >
                  <Button buttonType="link" type="button" onClick={() => {
                      setSelectedConfiguration({botId: null, fromPhoneNumber: formik.values.phoneNumber});
                  }} disabled={formik.dirty} dataTest="test-configuration-button">
                      {intl.formatMessage({ id: 'organization.sms.testSms' }) }
                  </Button>
              </TooltipComponent>
          </div>)}

        {Boolean(selectedConfiguration) && <TestSMSModal onClose={() => setSelectedConfiguration(null)} onSend={handleSendSMS} />}

        <HorizontalSeparator />

        {(formik.values.overrides ?? []).length > 0 && (
          <>
            <p className={styles.heading}>
              <FormattedMessage
                id="organization.sms.additionalBotNumber.heading"
                values={{
                  disclaimer: (
                    <span className={styles.headingDisclaimer}>
                      <FormattedMessage id="organization.sms.additionalBotNumber.disclaimer" />
                    </span>
                  ),
                }}
              />
            </p>

            <div className={styles.overrides}>
              {formik.values.overrides.map((override, index) => (
               <React.Fragment key={index}>
                <div className={styles.overridesItem}>
                  <div
                    className={classNames(
                      styles.formGrid,
                      styles.overrideConfig
                    )}
                  >
                    <div
                      className={styles.fullWidthRow}
                      data-test={`overrides[${index}]-bot-select`}
                    >
                      <label
                        htmlFor={`overrides[${index}].botId`}
                        className={styles.label}
                      >
                        <FormattedMessage id="organization.sms.botName" />
                      </label>
                      <Select
                        options={selectableBots}
                        onChange={(value) =>
                          formik.handleChange({
                            target: {
                              name: `overrides[${index}].botId`,
                              value: value.id,
                            },
                          })
                        }
                        value={organizationBots.find(
                          (option) => option.id === override.botId
                        )}
                      />
                    </div>
                    <div data-test={`overrides[${index}]-provider-select`}>
                      <label
                        htmlFor={`overrides[${index}].provider`}
                        className={styles.label}
                      >
                        <FormattedMessage id="organization.sms.providerName" />
                      </label>
                      <Select
                        options={providerOptions}
                        onChange={(value) => {
                          formik.setValues({
                            ...formik.values,
                            overrides: getOverrides(index, value as SMSSelectValue),
                          } as unknown as OrganizationSMSSettings);
                        }
                        }
                        value={providerOptions.find(
                          (option) => option.id === override.provider
                        )}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor={`overrides[${index}].phoneNumber`}
                        className={styles.label}
                      >
                        <FormattedMessage id="organization.sms.phoneNumber" />
                      </label>
                        <Input
                          className={styles.input}
                          id={`overrides[${index}].phoneNumber`}
                          name={`overrides[${index}].phoneNumber`}
                          dataTest={`overrides[${index}]-phone-number-input`}
                          onChange={(value) =>
                            formik.handleChange({
                              target: {
                                name: `overrides[${index}].phoneNumber`,
                                value: value,
                              },
                            })
                          }
                          value={override.phoneNumber}
                        />
                    </div>
                    {override.provider === 'supervoip' && (
                      <SuperVoipSMSSettingsFormFields
                        formik={formik}
                        keyPrefix={`overrides[${index}]`}
                      />
                    )}
                    {override.provider === 'vonage' && (
                      <VonageSMSSettingsFormFields
                        formik={formik}
                        keyPrefix={`overrides[${index}]`}
                      />
                    )}
                    {override.provider === 'twilio' && (
                      <TwilioSMSSettingsFormFields
                        formik={formik}
                        keyPrefix={`overrides[${index}]`}
                      />
                    )}
                    {(override.provider === 'parlance-helpdesk' || override.provider === 'parlance-patient-access') && (
                      <ParlanceSMSSettingsFormFields
                        formik={formik}
                        keyPrefix={`overrides[${index}]`}
                      />
                    )}
                  </div>
                  <VerticalSeparator noMargin />
                  <div className={styles.overrideActionContainer}>
                    <IconButton
                      dataTest={`overrides[${index}]-delete-button`}
                      className={styles.overrideAction}
                      onClick={() => {
                        formik.setFieldValue(
                          'overrides',
                          formik.values.overrides.filter((_, i) => i !== index)
                        );
                      }}
                      Icon={DeleteIcon}
                    />
                  </div>
                </div>

                <div className={styles.testSms}>
                    <TooltipComponent
                        tooltipText={intl.formatMessage({
                            id: 'organization.sms.testSms.tooltip.disabled',
                        })}
                        trigger={formik.dirty ? 'hover' : 'none'}
                        className={styles.cancelContactsButtonWrapper}
                    >
                      <Button disabled={!override.provider || formik.dirty} buttonType="link" type="button" onClick={() => {
                        setSelectedConfiguration({botId: override.botId, fromPhoneNumber: override.phoneNumber});
                      }} dataTest={`overrides[${index}]-test-configuration-button`}>
                        {intl.formatMessage({ id: 'organization.sms.testSms' }) }
                      </Button>
                    </TooltipComponent>
                </div>
              </React.Fragment>
              ))}
            </div>

            <HorizontalSeparator />
          </>
        )}

        <div className={styles.overridesActions}>
          <Button
            buttonType="normal"
            type="button"
            dataTest="add-override"
            onClick={() => {
              formik.setFieldValue('overrides', [
                ...formik.values.overrides,
                {
                  provider: undefined,
                },
              ]);
            }}
          >
            <FormattedMessage id="organization.sms.addOverride" />
          </Button>
        </div>
      </Box>
    </form>
  );
}
