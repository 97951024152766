import classNames from 'classnames';
import * as React from 'react';
import {useState} from 'react';
import {FormattedMessage} from 'react-intl';

import {Scrollbar} from '../../components/Scrollbar/Scrollbar';
import {WordQuestion} from '../model';

import {StepTabType} from './factory/types';
import {wordQuestionFactory} from './factory/word-question-factory';
import styles from './IntentQuestionComponent.pcss';

type Props = {
  question: WordQuestion;
  onChange: (question: (WordQuestion)) => void;
};


export const IntentQuestionComponent = ({question, onChange}: Props) => {
  const [activeTab, setActiveTab] = useState('question');

  const renderWordTab = () => {
    const currentQuestion = question as WordQuestion;
    return wordQuestionFactory[activeTab]({question: currentQuestion, onChange});
  };
  return (
    <div data-test={`${question.type}-reaction`} className={styles.container}>
      <div className={styles.header}>
        <div className={styles.tabs} data-test={`${question.type}-question-tabs`} data-active-tab={activeTab}>
          <TabHeaderButton active={activeTab === StepTabType.Question} onClick={() => setActiveTab(StepTabType.Question)}
                           dataTest={'question-tab'}>
            <FormattedMessage id={'step.tabs.question'} />
          </TabHeaderButton>
          <TabHeaderButton active={activeTab === StepTabType.Answer} onClick={() => setActiveTab(StepTabType.Answer)}
                           dataTest={'answer-tab'}>
            <FormattedMessage id={'step.tabs.answer'} />
          </TabHeaderButton>
          <TabHeaderButton active={activeTab === StepTabType.General} onClick={() => setActiveTab(StepTabType.General)}
                           dataTest={'general-tab'}>
            <FormattedMessage id={'step.tabs.general'} />
          </TabHeaderButton>
        </div>
      </div>

      <div className={styles.scrollContainer}>
        <Scrollbar hideHorizontal>
          <div className={styles.tabContent}>
            {renderWordTab()}
          </div>
        </Scrollbar>
      </div>
    </div>
  );
};

const TabHeaderButton = (props: { children: any, active: boolean, onClick: () => void, dataTest?: string }) => {
  const classes = classNames(styles.tabHeaderButton, props.active && styles.active);
  return (
    <button className={classes} onClick={props.onClick} data-test={props.dataTest}>
      {props.children}
    </button>
  );
};
