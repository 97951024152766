import moment from 'moment';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getNamesDictionary } from '../../selectors';
import { Call } from '../models/Call';
import { convertInformationItemToEventData } from '../models/Events';

import { Events } from './Events';

type Props = React.HTMLAttributes<HTMLDivElement> & {
    readonly eventsColumnWidth: number;
    readonly conversation: Call;
    isLoading?: boolean;
    timezone?: string;
};

export function ConversationRow({ eventsColumnWidth, conversation, isLoading = true, timezone, ...props }: Props) {
    const intl = useIntl();
    const namesDictionary = useSelector(getNamesDictionary);

    const events = useMemo(() => convertInformationItemToEventData(conversation, namesDictionary), [
        conversation,
        namesDictionary,
    ]);

    return (
        <div {...props} data-test="conversation-row" data-test-conversation-id={conversation.id}>
            <div data-test="conversation-start-cell">
                {!isLoading && moment.tz(conversation.conversationStart, timezone).format(`${intl.messages['timestamp.format']}`)}
            </div>
            <div data-test="duration-cell">
                {formatDuration(conversation.callDuration, conversation.redirectDuration)}
            </div>
            <div data-test="phone-number-cell" data-sensitive="true">{conversation.phoneNumber}</div>
            <Events
                {...events}
                eventsAvailableSpace={eventsColumnWidth}
            />
        </div>
    );
}

function formatDuration(callDuration?: number, redirectDuration: number = 0) {
    if (!callDuration) {
        return null;
    }
    const duration = moment.duration((callDuration + redirectDuration) * 1000);
    return `${duration.minutes()}m ${duration.seconds()}s`;
}
