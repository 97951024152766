import React from 'react';

import {IconProps} from './iconTypes';

export function InOrderIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <defs>
          <filter id="prefix__a">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"/>
          </filter>
        </defs>
        <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" filter="url(#prefix__a)" transform="translate(-2 -2)">
          <g stroke="#000" strokeWidth="1.2" transform="rotate(90 9 12)">
            <rect width="7.875" height="16.875" x="5.063" y=".56" rx="1.125"/>
            <rect width="2.25" height="16.875" x=".563" y=".56" rx=".563"/>
            <rect width="2.25" height="16.875" x="15.188" y=".56" rx=".563"/>
          </g>
        </g>
      </svg>
    );
}
