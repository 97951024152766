import _ from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useRouteMatch } from 'react-router';

import { Header } from '../layout/Header';
import { MainPage } from '../layout/MainPage';
import { useCurrentBot } from '../utils/CurrentBot';
import { useSurveyCreator } from './useSurveyCreator';
import { AnalyticsCommandPalette } from '../commandPalette/AnalyticsCommandPalette';
import { AnalyticsCommandPaletteProvider } from '../commandPalette/AnalyticsCommandPaletteProvider';
import { BlockDetails } from './components/BlockDetails';
import { BlocksAndIntents } from './components/BlocksAndIntents';
import { CallbackDetails } from './components/CallbackDetails';
import { EntityDetails } from './components/EntityDetails/EntityDetails';
import { IntentDetails } from './components/intentDetails/IntentDetails';
import { SilenceFallbackEmptyState } from './components/SilenceFallbackEmptyState/SilenceFallbackEmptyState';
import { StepComponent } from './components/StepComponent';
import { useCreatorRouter } from './useCreatorRouter';
import { useSurveyCreatorView } from './useSurveyCreatorView';
import { VisualEditorContainer } from './VisualEditor/VisualEditorContainer';

import styles from './SurveyCreator.pcss';
import HistoryModal from './history/HistoryModal';

export type Intent = { name: string; displayName?: string; group?: string };

export const getIntentDisplayName = (intent: Intent) =>
  intent && (intent.displayName || intent.name);

export const IntentsContext = React.createContext<Intent[]>([]);
export type SurveyContextType = ReturnType<
  typeof useSurveyCreator
>['definition'];
export const SurveyContext = React.createContext<SurveyContextType>(
  {} as SurveyContextType
);

export const SurveyCreator = () => {
  const currentBot = useCurrentBot();
  const { definition, allIntents } = useSurveyCreator(currentBot);
  const match = useRouteMatch<{ tabName: string }>();
  const { current } = useCreatorRouter();
  const isInIntentContext = current.intentId || definition.intents.new;
  const surveyCreatorView = useSurveyCreatorView();

  if (
    definition.blocks.get() === undefined ||
    definition.intents.get() === undefined
  ) {
    return (
      <MainPage>
        <Header />
        <div className={styles.errorText}>
          <FormattedMessage id="survey-creator.incorrectDefinition" />
        </div>
      </MainPage>
    );
  }

  const displayDetails = () => {
    if (isInIntentContext) {
      return (
        <IntentDetails
          key={
            definition.intents.new
              ? `new`
              : `${_.get(definition.intents.current, 'name')}`
          }
        />
      );
    } else if (current.callbackId) {
      return (
        <>
          <CallbackDetails />
          <StepComponent />
        </>
      );
    } else if (current.fallback) {
      return (
        <>
          <BlockDetails />
          <CallbackDetails />
          {definition.questions.current && <StepComponent />}
        </>
      );
    } else if (current.silenceFallback) {
      return (
        <>
          <BlockDetails />
          <CallbackDetails />
          {definition.questions.current ? (
            <StepComponent />
          ) : (
            <SilenceFallbackEmptyState />
          )}
        </>
      );
    }
    
    return (
      <>
        <BlockDetails />
        <StepComponent />
      </>
    );
  };

  return (
    <MainPage>
      <Header />
      <IntentsContext.Provider value={allIntents}>
        <SurveyContext.Provider value={definition}>
            <AnalyticsCommandPaletteProvider>
              <>
                {surveyCreatorView === 'visual' ? (
                  <VisualEditorContainer />
                ) : (
                  <div
                    className={
                      isInIntentContext
                        ? styles.intentContent
                        : styles.blockContent
                    }
                  >
                    <BlocksAndIntents />
                    {match.params.tabName === 'scenario' && displayDetails()}
                    {match.params.tabName === 'entities' && <EntityDetails />}
                  </div>
                )}
              </>
              <AnalyticsCommandPalette />
            </AnalyticsCommandPaletteProvider>
            <HistoryModal />
        </SurveyContext.Provider>
      </IntentsContext.Provider>
    </MainPage>
  );
};
