import React from 'react';

import {IconProps} from './iconTypes';

export function StatisticsIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h18v18H0z" />
                <path
                    className={props.animationClass}
                    fill="#3C38BC"
                    d="M9-.037a9.038 9.038 0 1 1 0 18.075A9.038 9.038 0 0 1 9-.037zm0 1.2a7.837 7.837 0 1 0 0 15.674A7.837 7.837 0 0 0 9 1.162zM5.062 13.537a.6.6 0 0 1-.592-.502l-.007-.098v-6.75a.6.6 0 0 1 1.192-.097l.007.098v6.15l1.05-.001V9a.6.6 0 0 1 .503-.592l.098-.008a.6.6 0 0 1 .592.503L7.912 9v3.337h1.05V7.313a.6.6 0 0 1 .503-.593l.098-.007a.6.6 0 0 1 .6.6l-.001 5.024h1.05v-3.9a.6.6 0 0 1 1.193-.097l.008.098-.001 3.899h.525a.6.6 0 0 1 .593.503l.008.098a.6.6 0 0 1-.503.592l-.098.008H5.063z"
                />
            </g>
        </svg>
    );
}
