import React from 'react';

import {IconProps} from './iconTypes';

export function SendIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <g fill="none" fillRule="evenodd">
              <g>
                  <g>
                      <g transform="translate(-372 -668) translate(368 664) translate(4 4)">
                          <path d="M0 0H24V24H0z"/>
                          <path fill="#FFF" fillRule="nonzero" d="M4.074 3.387c.55-.58 1.415-.734 2.133-.38l15.563 7.906c.408.207.664.625.664 1.082 0 .456-.256.874-.664 1.081L6.207 20.993c-.718.354-1.582.2-2.133-.38-.551-.58-.66-1.451-.258-2.17l3.946-6.45-3.958-6.456c-.365-.652-.294-1.454.165-2.03zm15.996 9.207H8.801l-3.95 6.454c-.117.21-.103.465.029.66l.064.078c.189.2.486.253.726.134l14.4-7.326zM5.582 4.046c-.225-.069-.473-.007-.638.168-.19.199-.227.498-.105.717L8.8 11.394H20.07L5.671 4.08z"/>
                      </g>
                  </g>
              </g>
          </g>
      </svg>

    );
}
