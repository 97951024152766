import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router';

import { Header } from '../layout/Header';
import { MainPage } from '../layout/MainPage';
import { useAsyncFn } from '../utils/asyncUtils';
import { getBotUrlId, useCurrentBot } from '../utils/CurrentBot';
import { HttpClient } from '../utils/HttpClient';
import { useSurveyConfig } from '../views/Settings/Settings.hooks';

import { ConversationDetails } from './ConversationDetails';
import { ConversationViews } from './ConversationViews';
import { ConversationsList } from './list';
import styles from './LiveConversations.pcss';
import { Call } from './models/Call';

export const LiveConversations = withRouter((props: RouteComponentProps<{ callId: string }>) => {
    const currentBot = useCurrentBot();
    const surveyConfig = useSurveyConfig();
    const { callId: currentConversationId } = useParams<{ callId: string }>();

    const [items, setItems] = useState<Call[]>([]);
    const [itemsCount, setItemsCount] = useState();
    const [hasMore, setHasMore] = useState<boolean>();

    const {
        state: { loading },
        callback: loadItems,
        cancelPendingTasks
    } = useAsyncFn(
        (after?: string) => {
            return HttpClient.post({
                path: `/bots/${currentBot.id}/live-calls`,
                body: {
                    after,
                    pageSize: 50
                }
            });
        },
        [currentBot.id],
        {
            minimumQueryLoadingTime: 150
        }
    );

    const fetchPage = useCallback(
        (lastItemId?: string) => {
            return loadItems(lastItemId)
                .then(({ data }) => ({ items: data.items, itemsCount: data.size, hasMore: data.hasMore }))
                .then(data => {
                    setItems(currentItems => [...currentItems, ...data.items]);
                    setItemsCount(data.itemsCount);
                    setHasMore(data.hasMore);
                });
        },
        [loadItems]
    );

    const onSelectCall = useCallback(
        (call: Call) => {
            props.history.push({
                ...props.location,
                pathname: `/${getBotUrlId(currentBot)}/live/${call.id}`
            });
        },
        [props.history, props.location, currentBot]
    );

    const refreshList = useCallback(() => {
        setHasMore(false);
        setItemsCount(undefined);
        setItems([]);
        fetchPage();
    }, [fetchPage]);

    useEffect(() => {
        setHasMore(false);
        setItemsCount(undefined);
        setItems([]);
        fetchPage();
        return cancelPendingTasks;
    }, [fetchPage, currentBot.id, cancelPendingTasks]);

    const currentConversation = items.find(call => call.id === currentConversationId);

    return (
        <MainPage>
            <Header />
            <div className={styles.page} data-test="conversations">
                <div className={styles.section}>
                    <ConversationViews />
                </div>
                <div className={styles.callSection}>
                    <ConversationsList
                        orderable={false}
                        hasMore={hasMore}
                        conversations={items}
                        fetchNextPage={fetchPage}
                        itemsCount={itemsCount}
                        onSelect={onSelectCall}
                        loading={loading}
                        refresh={refreshList}
                        isSurveyConfigLoading={surveyConfig.isLoading}
                        timezone={surveyConfig?.displayedConfig?.timeZone}
                        currentConversation={currentConversation}
                    />
                    <div className={styles.conversationDetailsContainer}>
                        <ConversationDetails
                            live
                            callId={currentConversationId}
                            botId={currentBot.id}
                            timezone={surveyConfig?.displayedConfig?.timeZone}
                        />
                    </div>
                </div>
            </div>
        </MainPage>
    );
});
