import { useHistory, useLocation, useParams } from 'react-router';
import { BotRouteMatch, CallbackTypeId } from '../utils/CurrentBot';
import { slugifyIntentName } from './model';

export type BotCreatorRouteMatch = BotRouteMatch & {
    tabName?: string;
    blockId?: string;
    stepId?: string;
    fallbackStepId?: string;
    silenceFallbackStepId?: string;
    callbackId?: CallbackTypeId;
    callbackStepId?: string;
    intentId?: string;
}

export function useCreatorRouter() {
    const params = useParams<BotCreatorRouteMatch>();
    const { pathname, search } = useLocation();
    const history = useHistory();

    const baseCreatorPath = `/${params.orgId}/${params.botId}/survey-creator/${params.tabName}`

    function getUrlForBlock(blockId: string) {
        return `${baseCreatorPath}/modules/${blockId}`;
    }

    function getUrlForStep(blockId: string, stepId: string) {
        return `${getUrlForBlock(blockId)}/${stepId}`;
    }

    function getUrlForFallback() {
        return `${baseCreatorPath}/fallback`;
    }

    function getUrlForFallbackStep(stepId: string) {
        return `${getUrlForFallback()}/${stepId}`;
    }

    function getUrlForSilenceFallback() {
        return `${baseCreatorPath}/silence-fallback`;
    }

    function getUrlForSilenceFallbackStep(stepId: string) {
        return `${getUrlForSilenceFallback()}/${stepId}`;
    }

    function getUrlForCallback(callbackId: CallbackTypeId) {
        return `${baseCreatorPath}/callbacks/${callbackId}`;
    }

    function getUrlForCallbackStep(callbackId: CallbackTypeId, stepId: string) {
        return `${getUrlForCallback(callbackId)}/${stepId}`;
    }

    function getUrlForIntent(intentId: string) {
        return `${baseCreatorPath}/intents/${intentId}`;
    }

    function getUrlForCurrentStep(stepId: string) {
        if (params.blockId) {
            return getUrlForStep(params.blockId, stepId);
        }
        if (pathname.startsWith(getUrlForFallback())) {
            return getUrlForFallbackStep(stepId);
        }
        if (pathname.startsWith(getUrlForSilenceFallback())) {
            return getUrlForSilenceFallbackStep(stepId);
        }
        if (params.callbackId) {
            return getUrlForCallbackStep(params.callbackId as CallbackTypeId, stepId);
        }
        return baseCreatorPath;
    }

    function getUrlForEntity(entityId: string) {
        return `/${params.orgId}/${params.botId}/survey-creator/entities/${entityId}`;
    }

    function setActiveTab(tabName: string) {
        const searchParams = new URLSearchParams(search);
        searchParams.set('tab', tabName);
        history.push(`${pathname}?${searchParams.toString()}`);
    }

    return {
        getUrlForBlock,
        getUrlForFallback,
        getUrlForFallbackStep,
        getUrlForCallback,
        getUrlForSilenceFallback,
        getUrlForSilenceFallbackStep,
        getUrlForCallbackStep,
        getUrlForIntent,
        getUrlForEntity,
        navigateToNewQuestion: (questionId: string) => history.push(getUrlForCurrentStep(questionId)),
        navigateToIntent: (intentId: string) => history.push(getUrlForIntent(slugifyIntentName(intentId))),
        navigateToBlock: (blockId: string) => history.push(getUrlForBlock(blockId)),
        navigateToBlockQuestion: (blockId: string, questionId: string) => history.push(getUrlForStep(blockId, questionId)),
        navigateToFallback: () => history.push(getUrlForFallback()),
        navigateToFallbackQuestion: (questionId: string) => history.push(getUrlForFallbackStep(questionId)),
        navigateToSilenceFallbackQuestion: (questionId: string) => history.push(getUrlForSilenceFallbackStep(questionId)),
        navigateToSilenceFallback: () => history.push(getUrlForSilenceFallback()),
        navigateToCallbackQuestion: (callbackId: CallbackTypeId, questionId: string) => history.push(getUrlForCallbackStep(callbackId, questionId)),
        navigateToCallback: (callbackId: CallbackTypeId) => history.push(getUrlForCallback(callbackId)),
        activeTab: {
            value: new URLSearchParams(search).get('tab'),
            set: setActiveTab,
        },
        current: {
            blockId: params.blockId,
            stepId: params.stepId,
            fallback: pathname.startsWith(getUrlForFallback()),
            fallbackStepId: params.fallbackStepId,
            silenceFallback: pathname.startsWith(getUrlForSilenceFallback()),
            silenceFallbackStepId: params.silenceFallbackStepId,
            callbackId: params.callbackId,
            callbackStepId: params.callbackStepId,
            intentId: params.intentId,
        }
    };
}