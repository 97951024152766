import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Modal } from '../../components/Modal/Modal';
import { ModalContent } from '../../components/ModalContent/ModalContent';
import { TextLabel } from '../../components/Label';
import { TranscriptItemRedirect } from '../models/Transcript';
import { StepLinkExpired } from './StepLinkExpired';
import styles from './StepLineDetails.pcss';
import { Highlighter, prettyPrintJson } from '../../components/Highlighter';

type Props = {
  onClose: () => void;
  line: TranscriptItemRedirect;
  moduleLink: string;
  isStepLinkExpired: boolean | undefined;
};

export function RedirectStepLineDetails({
  line,
  onClose,
  moduleLink,
  isStepLinkExpired,
}: Props) {
  const intl = useIntl();

  return (
    <Modal
      modalSize="medium"
      dataTest="redirect-step-details"
      noPadding
      noFixedHeight
    >
      <ModalContent
        headerContent={
          <span data-test="redirect-step-details-header">{line.stepName}</span>
        }
        onClose={onClose}
        closeButtonDataTest="redirect-step-details-close"
        displayBottomSeparator={false}
      >
        <div className={styles.content}>
          <div className={styles.entry}>
            <TextLabel
              className={styles.stepEntry}
              label={intl.formatMessage({
                id: 'conversations.redirect.modal.stepLink',
              })}
            />
            {isStepLinkExpired ? (
              <StepLinkExpired />
            ) : (
              <Link
                to={`${moduleLink}/${line.stepId}`}
                target="_blank"
                data-test="redirect-step-details-step-link"
                className={styles.stepLink}
              >
                {line.stepName}
              </Link>
            )}
          </div>
          {line.redirectTo && (
            <div className={styles.entry}>
              <TextLabel
                className={styles.stepEntry}
                label={intl.formatMessage({
                  id: 'conversations.redirect.modal.to',
                })}
              />
              <span data-test="redirect-step-details-to" data-sensitive="true">
                {line.redirectTo}
              </span>
            </div>
          )}
          {line.redirectFrom && (
            <div className={styles.entry}>
              <TextLabel
                className={styles.stepEntry}
                label={intl.formatMessage({
                  id: 'conversations.redirect.modal.from',
                })}
              />
              <span
                data-test="redirect-step-details-from"
                data-sensitive="true"
              >
                {line.redirectFrom}
              </span>
            </div>
          )}
          {line.redirectTrunk && (
            <div className={styles.entry}>
              <TextLabel
                className={styles.stepEntry}
                label={intl.formatMessage({
                  id: 'conversations.redirect.modal.trunk',
                })}
              />
              <span
                data-test="redirect-step-details-trunk"
                data-sensitive="true"
              >
                {line.redirectTrunk}
              </span>
            </div>
          )}
          {line.redirectType && (
            <div className={styles.entry}>
              <TextLabel
                className={styles.stepEntry}
                label={intl.formatMessage({
                  id: 'conversations.redirect.modal.type',
                })}
              />
              <span
                data-test="redirect-step-details-type"
                data-sensitive="true"
              >
                {line.redirectType}
              </span>
            </div>
          )}
          {line.redirectDestination && (
            <div className={styles.entry}>
              <TextLabel
                className={styles.stepEntry}
                label={intl.formatMessage({
                  id: 'conversations.redirect.modal.destination',
                })}
              />
              <span
                data-test="redirect-step-details-destination"
                data-sensitive="true"
              >
                {line.redirectDestination}
              </span>
            </div>
          )}
          {line.recordingAction && (
            <div className={styles.entry}>
              <TextLabel
                className={styles.stepEntry}
                label={intl.formatMessage({
                  id: 'conversations.redirect.modal.recordingAction',
                })}
              />
              <span
                data-test="redirect-step-details-recording-action"
                data-sensitive="true"
              >
                {line.recordingAction}
              </span>
            </div>
          )}
          {line.redirectHeaders && (
            <div className={styles.entry}>
              <TextLabel
                className={styles.stepEntry}
                label={intl.formatMessage({
                  id: 'conversations.redirect.modal.headers',
                })}
              />
              <Highlighter
                dataTest="redirect-step-details-headers"
                dataSensitive="true"
              >
                {prettyPrintJson(line.redirectHeaders)}
              </Highlighter>
            </div>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
}
