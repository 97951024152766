import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';
import _ from 'lodash';

import { Input } from '../../components/Input';
import { PasswordInput } from '../../components/PasswordInput/PasswordInput';
import { OrganizationSMSSettings } from './SMSSettings';
import { getDataTestKey, getFormKey } from './SMSSettingsFormUtils';

import styles from './SMSSettingsForm.pcss';

type SuperVoipSMSSettingsFormFieldsProps = {
  formik: ReturnType<typeof useFormik<OrganizationSMSSettings>>;
  keyPrefix?: string;
};

export function SuperVoipSMSSettingsFormFields({
  formik,
  keyPrefix = '',
}: SuperVoipSMSSettingsFormFieldsProps) {
  return (
    <>
      <div>
        <label
          htmlFor={getFormKey('providerConfiguration.login', keyPrefix)}
          className={styles.label}
        >
          <FormattedMessage id="organization.sms.supervoip.login" />
        </label>
        <Input
          className={styles.input}
          id={getFormKey('providerConfiguration.login', keyPrefix)}
          name={getFormKey('providerConfiguration.login', keyPrefix)}
          dataTest={getDataTestKey('login-input', keyPrefix)}
          onChange={(value) =>
            formik.handleChange({
              target: {
                name: getFormKey('providerConfiguration.login', keyPrefix),
                value,
              },
            })
          }
          value={_.get(
            formik.values,
            getFormKey('providerConfiguration.login', keyPrefix)
          )}
        />
      </div>
      <div>
        <label
          htmlFor={getFormKey('providerConfiguration.password', keyPrefix)}
          className={styles.label}
        >
          <FormattedMessage id="organization.sms.supervoip.password" />
        </label>
        <PasswordInput
          id={getFormKey('providerConfiguration.password', keyPrefix)}
          name={getFormKey('providerConfiguration.password', keyPrefix)}
          dataTest={getDataTestKey('password-input', keyPrefix)}
          onChange={(value) =>
            formik.handleChange({
              target: {
                name: getFormKey('providerConfiguration.password', keyPrefix),
                value,
              },
            })
          }
          value={_.get(
            formik.values,
            getFormKey('providerConfiguration.password', keyPrefix)
          )}
        />
      </div>
      <div>
        <label
          htmlFor={getFormKey('providerConfiguration.apiKey', keyPrefix)}
          className={styles.label}
        >
          <FormattedMessage id="organization.sms.supervoip.apiKey" />
        </label>
        <PasswordInput
          id={getFormKey('providerConfiguration.apiKey', keyPrefix)}
          name={getFormKey('providerConfiguration.apiKey', keyPrefix)}
          dataTest={getDataTestKey('api-key-input', keyPrefix)}
          onChange={(value) =>
            formik.handleChange({
              target: {
                name: getFormKey('providerConfiguration.apiKey', keyPrefix),
                value,
              },
            })
          }
          value={_.get(
            formik.values,
            getFormKey('providerConfiguration.apiKey', keyPrefix)
          )}
        />
      </div>
    </>
  );
}
