import React from 'react';

import {IconProps} from './iconTypes';

export function TryCallIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
          <g fill="none" fillRule="evenodd">
              <path d="M0 0H32V32H0z"/>
              <path className={props.animationClass} fill="#000" fillRule="nonzero" d="M7.564 5.923c.953-.952 2.496-.952 3.45 0l2.897 2.901c.458.458.715 1.078.715 1.725 0 .648-.257 1.268-.685 1.694l-.091.1c-.266.331-.242.815.062 1.119l4.638 4.64c.157.157.37.245.593.245.223 0 .436-.088.625-.276l.166-.147c.966-.777 2.37-.705 3.253.177l2.899 2.897c.952.953.952 2.497 0 3.45l-.688.688c-1.896 1.896-4.854 2.22-7.133.769-4.795-3.24-8.917-7.362-12.164-12.169-1.444-2.27-1.122-5.228.774-7.125zm2.319 1.132c-.329-.328-.86-.328-1.188 0l-.688.688c-1.366 1.366-1.598 3.496-.568 5.116 3.12 4.619 7.096 8.595 11.704 11.708 1.628 1.037 3.758.803 5.123-.562l.688-.688c.328-.328.328-.86 0-1.188l-2.898-2.896c-.304-.304-.788-.329-1.086-.09l-.26.234c-.437.367-.992.57-1.567.57-.647 0-1.268-.257-1.725-.715l-4.638-4.64c-.882-.882-.954-2.285-.15-3.284l.15-.166c.158-.157.246-.37.246-.593 0-.222-.088-.436-.246-.593z"/>
          </g>
      </svg>
    );
}
