import React from 'react';

import styles from './icons.pcss';

export function AudioFileDownloadIcon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <g fill="none" fillRule="evenodd">
              <path d="M0 0h24v24H0z"/>
              <path className={styles.iconStrokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" d="M17.133 23a5.867 5.867 0 1 0 0-11.733 5.867 5.867 0 0 0 0 11.733zm0-8.8v5.867m0 0l-2.2-2.2m2.2 2.2l2.2-2.2m-11 2.2H2.467C1.657 20.067 1 19.41 1 18.6V2.467C1 1.657 1.657 1 2.467 1h10.392c.39 0 .762.154 1.037.43l2.808 2.807c.275.275.43.648.43 1.037v3.06m-11 5.866c1.214 0 2.2-.657 2.2-1.467s-.986-1.466-2.2-1.466c-1.216 0-2.2.656-2.2 1.466 0 .81.984 1.467 2.2 1.467zm2.2-1.467l-.29-5.797 1.04-.11a2.625 2.625 0 0 1 2.525 1.253"/>
          </g>
      </svg>
    );
}
