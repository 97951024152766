import React from 'react';

import {IconProps} from './iconTypes';

export function EndStepIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g transform="translate(-381 -561) translate(365 549) translate(16 12)">
                <path d="M0 0H24V24H0z"/>
                <path className={props.animationClass} d="M12.16 23.887c.386.055.779-.06 1.074-.317.296-.256.466-.628.466-1.02v-21c0-.407-.184-.793-.5-1.05-.318-.256-.734-.355-1.133-.27l-.018.004-9 2.221.018-.004C2.444 2.584 2 3.135 2 3.772v17.493c0 .672.494 1.241 1.16 1.336l9 1.286zm.169-1.188c.043.006.086-.007.12-.036.032-.028.051-.07.051-.112v-21c0-.046-.02-.09-.056-.118-.034-.027-.079-.039-.122-.03L3.337 3.62l-.018.004c-.07.015-.12.076-.119.147v17.494c0 .074.055.137.128.148l9 1.286zM16.1 3.647h4.5c.497 0 .9.403.9.9v15c0 .497-.403.9-.9.9h-4.5c-.331 0-.6.268-.6.6 0 .33.269.6.6.6h4.5c1.16 0 2.1-.94 2.1-2.1v-15c0-1.16-.94-2.1-2.1-2.1h-4.5c-.331 0-.6.268-.6.6 0 .33.269.6.6.6zm-6.757 7.435c.035-.007.071-.01.108-.01.26-.001.508.102.692.285.183.183.286.432.285.683.004.395-.23.753-.593.908-.363.154-.783.075-1.065-.202-.281-.276-.369-.695-.221-1.06.135-.334.441-.564.794-.604z"/>
              </g>
            </g>
          </g>
        </g>
      </svg>

    );
}
