import { v4 } from 'uuid';

import { NumericalQuestion } from '../../model';
import {Range} from '../../model';

import { ComponentPartProps } from './question-component-factory-model';

export const useNumericalQuestionAnswerProps = ({ question, onChange }: ComponentPartProps<NumericalQuestion>) => {
  const setRanges = (ranges: Range[]) => onChange({ ...question, ranges });

  const setRange = (index: number) => (changedRange: Range) => {
    const newRanges = question.ranges.map((range, rangeIndex) => rangeIndex === index ? changedRange : range);
    setRanges(newRanges);
  };

  const addRange = (range: Range) => setRanges([...question.ranges, range]);
  const onRemoveRange = (indexToRemove: number) => () => setRanges(question.ranges.filter((range, rangeIndex) => rangeIndex !== indexToRemove));

  const onAddRange = () => addRange({ id: v4() } as Range);

  const currentRanges = question.ranges.some(range => range.id === undefined) ? question.ranges.map(range => ({ ...range, id: range.id || v4() })) : question.ranges;

  return {
    setRange,
    setRanges,
    onAddRange,
    onRemoveRange,
    currentRanges
  }
}