import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../components/Button/Button';
import {
  ConversationOptionsModal,
  ConversationOptionsProps,
} from './ConversationOptionsModal';

export function ConversationOptions(
  conversationOptionsProps: ConversationOptionsProps
) {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <Button
        data-test="conversation-options"
        buttonType="link"
        onClick={() => setIsOpened(true)}
      >
        <FormattedMessage id="conversations.options.button" />
      </Button>
      {isOpened && (
        <ConversationOptionsModal
          {...conversationOptionsProps}
          onClose={() => setIsOpened(false)}
        />
      )}
    </>
  );
}
