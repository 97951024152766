import { useEffect } from 'react';
import { showErrorToast } from '../components/Toast/Toast';
import { MarkdownContent } from '../components/MarkdownContent/MarkdownContent';
import * as React from 'react';
import { Messages } from '../i18n/i18n';

export const useShowErrorToast = (show: boolean, contentKey?: keyof Messages) => {
  useEffect(() => {
    if (show) {
      showErrorToast(<MarkdownContent contentKey={contentKey || 'generalError'} />);
    }
  }, [show]);
};
