import classnames from 'classnames';
import React from 'react';

import styles from './HelpIcon.pcss';
import {IconProps} from './iconTypes';

export const HelpIcon = ({ animationClass, fill }: IconProps & { fill?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
        <g transform="translate(-1290 -16) translate(1290 16)">
          <path fill={fill || '#919aaf'} className={classnames(styles.path, animationClass)}
                d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 1.215C6.044 1.215 1.215 6.044 1.215 12S6.044 22.785 12 22.785 22.785 17.956 22.785 12 17.956 1.215 12 1.215zm0 15.494l.115.007c.491.057.872.474.872.98 0 .545-.442.988-.987.988-.545 0-.987-.443-.987-.988 0-.506.38-.923.872-.98l.115-.007zm3.552-8.565c.417 1.806-.589 3.638-2.337 4.256-.328.116-.558.406-.6.745l-.007.115v1.018c0 .336-.272.608-.608.608-.302 0-.553-.22-.6-.509l-.008-.099V13.26c0-.845.5-1.605 1.262-1.944l.312-.123c1.068-.462 1.667-1.627 1.402-2.776-.277-1.205-1.414-2.009-2.642-1.87-1.228.14-2.156 1.18-2.156 2.415 0 .336-.273.608-.608.608-.336 0-.608-.273-.608-.608 0-1.854 1.392-3.412 3.234-3.622 1.842-.209 3.548.997 3.964 2.804z"/>
        </g>
    </g>
  </svg>
)
