import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import {NavLink} from 'react-router-dom';

import {ContactsListIcon} from '../../../icons/ContactsListIcon';
import {getBotUrlId, useCurrentBot} from '../../../utils/CurrentBot';

import styles from './InteractionViews.pcss';

export function InteractionViews() {
    const currentBot = useCurrentBot();
    return (
        <div className={styles.tabs}>
            <NavLink
                to={`/${getBotUrlId(currentBot)}/contacts`}
                activeClassName={styles.active}
                className={styles.link}
                isActive={(match, location) =>
                    match && location.pathname.startsWith(`/${getBotUrlId(currentBot)}/contacts`)
                }
            >
                <ContactsListIcon animationClass={styles.icon} />
                <div className={styles.linkText}>
                    <FormattedMessage id={'contacts.viewsSelect.contactsList'}/>
                </div>
            </NavLink>
            <NavLink
                to={`/${getBotUrlId(currentBot)}/planer`}
                activeClassName={styles.active}
                className={styles.link}
                isActive={(match, location) =>
                    match && location.pathname.startsWith(`/${getBotUrlId(currentBot)}/planer`)
                }
            >
                <ContactsListIcon animationClass={styles.icon} />
                <div className={styles.linkText}>
                    <FormattedMessage id={'contacts.viewsSelect.scheduler'} />
                </div>
            </NavLink>
        </div>
    );
}
