import React from 'react';

import {IconProps} from './iconTypes';

export function DataCollectionStepIcon(props: IconProps) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path className={props.animationClass} d="M22.93 11.33s-.02-.03-.04-.04a2.716 2.716 0 0 0-3.84 0l-7.63 7.63a.58.58 0 0 0-.16.31l-.75 3.75c-.04.2.02.4.16.54.11.11.27.18.42.18.04 0 .08 0 .12-.01l3.75-.75c.12-.02.22-.08.31-.16l7.56-7.56s.08-.05.11-.09c.51-.52.78-1.2.77-1.92 0-.69-.28-1.34-.79-1.87l.01-.01zm-.78 2.88s-.07.05-.09.08l-7.5 7.5-2.69.54.54-2.69 7.5-7.5a1.515 1.515 0 0 1 2.16.02c.29.29.45.66.46 1.06 0 .37-.12.72-.37 1l-.01-.01zM12.6 14.7c.33 0 .6-.27.6-.6 0-.33-.27-.6-.6-.6h-.9V8.1c0-.33-.27-.6-.6-.6-.33 0-.6.27-.6.6v5.4H8.7V6.6c0-.33-.27-.6-.6-.6-.33 0-.6.27-.6.6v6.9H5.7V9.6c0-.33-.27-.6-.6-.6-.33 0-.6.27-.6.6v3.9h-.9c-.33 0-.6.27-.6.6 0 .33.27.6.6.6h9z"/>
                <path className={props.animationClass} d="M7.35 19.5H2.1c-.5 0-.9-.4-.9-.9V2.1c0-.5.4-.9.9-.9h10.63c.24 0 .47.1.64.26l2.87 2.87c.17.17.26.4.26.64V8.1c0 .33.27.6.6.6.33 0 .6-.27.6-.6V4.97c0-.56-.22-1.09-.61-1.48L14.21.61C13.82.22 13.28 0 12.73 0H2.1A2.1 2.1 0 0 0 0 2.1v16.5c0 1.16.94 2.1 2.1 2.1h5.25c.33 0 .6-.27.6-.6 0-.33-.27-.6-.6-.6z"/>
            </g>
        </svg>
    );
}
