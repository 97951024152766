import React from 'react';

import {IconProps} from './iconTypes';

export function InspectorTrainIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <g fill="none" fillRule="evenodd">
              <path d="M0 0H24V24H0z"/>
              <path className={props.animationClass} fill={'#919aaf'} d="M18.4 18.785c-2.627 2.28-6.36 2.852-9.573 1.418-3.407-1.52-5.52-4.981-5.313-8.7.017-.31.283-.547.593-.53.31.017.547.282.53.591-.18 3.255 1.668 6.284 4.649 7.614 2.77 1.237 5.985.767 8.278-1.156H15.98c-.31 0-.562-.252-.562-.562 0-.31.252-.562.562-.562h2.983c.096 0 .186.024.264.066l.004.002c.041.022.08.05.115.083l.015.014.012.012c.034.036.063.076.085.118.043.08.068.17.068.267v2.978c0 .31-.252.562-.563.562-.31 0-.562-.252-.562-.562v-1.652zM7.06 9.71l5.262-1.751c.116-.039.24-.039.356 0l5.273 1.755c.23.076.385.29.385.533 0 .241-.155.456-.385.532l-2.251.75v3.104c0 .242-.155.457-.385.533l-2.637.878c-.116.039-.24.039-.356 0l-2.636-.878c-.23-.076-.385-.29-.385-.532v-3.105l-1.512-.503v2.192c0 .31-.252.562-.562.562-.31 0-.563-.252-.563-.562V10.23c.003-.097.03-.188.077-.266l.001-.003c.052-.087.126-.16.216-.208l.003-.002c.031-.016.064-.03.098-.04zm7.515 2.193l-1.897.631c-.116.039-.24.039-.356 0l-1.896-.63v2.325l2.074.69 2.075-.69v-2.326zM5.54 6.804c-.042-.079-.066-.169-.066-.264V3.562c0-.31.252-.562.563-.562.31 0 .562.252.562.562v1.653c2.628-2.279 6.362-2.851 9.574-1.417 3.407 1.52 5.52 4.981 5.313 8.7-.017.31-.283.547-.593.53-.31-.017-.547-.282-.53-.592.18-3.254-1.668-6.283-4.649-7.613-2.77-1.237-5.984-.768-8.277 1.155H9.02c.31 0 .563.252.563.562 0 .31-.253.561-.563.561H6.037c-.096 0-.187-.024-.266-.066-.043-.023-.083-.052-.12-.086l-.022-.022-.002-.002c-.034-.037-.063-.077-.086-.12zm9.44 3.78l1.015-.338L12.5 9.082l-3.495 1.163 1.01.336c.018.005.036.011.053.018l2.432.81 2.44-.812.04-.014z"/>
          </g>
      </svg>
    );
}
