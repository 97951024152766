import React from 'react';

import {IconProps} from './iconTypes';

export function StatementStepIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H24V24H0z"/>
                <path className={props.animationClass} d="M12.601 0l.3.005c4.591.115 8.651 3.052 10.186 7.401 1.57 4.445.16 9.395-3.513 12.347-3.507 2.819-8.348 3.23-12.262 1.097l-.27-.153L.86 23.643c-.481.23-.983-.233-.833-.715l.034-.085 2.944-6.185-.184-.327c-1.723-3.209-1.763-7.072-.088-10.322l.168-.313C4.86 2.176 8.574-.004 12.6 0zm.002 1.201C9.01 1.196 5.696 3.141 3.95 6.28c-1.746 3.139-1.653 6.979.246 10.029.108.174.12.39.032.575l-2.358 4.949 4.95-2.357c.153-.073.33-.077.484-.013l.09.045c3.57 2.22 8.152 1.943 11.429-.69 3.276-2.633 4.533-7.048 3.134-11.012-1.4-3.964-5.15-6.612-9.353-6.605zm4.497 12.3c.332 0 .6.269.6.6 0 .298-.217.546-.502.592l-.098.008h-9c-.331 0-.6-.269-.6-.6 0-.298.218-.546.503-.592L8.1 13.5h9zm1.5-3c.332 0 .6.269.6.6 0 .298-.217.546-.502.592l-.098.008h-12c-.331 0-.6-.269-.6-.6 0-.298.218-.546.503-.592L6.6 10.5h12zm-1.5-3c.332 0 .6.269.6.6 0 .298-.217.546-.502.592l-.098.008h-9c-.331 0-.6-.269-.6-.6 0-.298.218-.546.503-.592L8.1 7.5h9z"/>
            </g>
        </svg>
    );
}
