import { isEmpty } from 'lodash';
import { ClipboardEvent } from 'react';
import { Synonym } from '../../surveyCreator/model';
import { isLemmatizationEnabled } from '../../utils/featureFlags';
import { HttpClient } from '../../utils/HttpClient';

const SYNONYMS_MIME_TYPE = 'text/synonyms-chips-data';

export function useSynonymsClipboard(botId?: string) {
    return {
        onCopy: (event: ClipboardEvent): void => {
            const selectedText = window.getSelection().toString()?.split('\n').filter(Boolean);

            if (!isEmpty(selectedText)) {
                const synonyms: Synonym[] = selectedText
                    .map((item) => {
                        try {
                            return JSON.parse(item);
                        } catch (e) {
                            // Ignore non-json items. Cannot filter by index, as selection results sometimes are offset
                            return null;
                        }
                    })
                    .filter(item => item !== null);
                const plaintextData = selectedText.filter((item, index) => index % 2 === 0).join('\n');

                event.clipboardData.setData(SYNONYMS_MIME_TYPE, JSON.stringify(synonyms));
                event.clipboardData.setData('text/plain', plaintextData);
            }
            event.preventDefault();
        },
        getDataFromPaste: async (e: ClipboardEvent): Promise<Synonym[]> => {
            const data = e.clipboardData.getData(SYNONYMS_MIME_TYPE);
            const plaintext = e.clipboardData.getData('text/plain');
            e.preventDefault();

            if (data) {
                return JSON.parse(data);
            } else if (plaintext) {
                const verbatimSynonyms = plaintext.split('\n').filter(Boolean).map(item => ({ verbatim: item.trim() }));
                if (isLemmatizationEnabled()) {
                    const lemmatizedSynonyms = Promise.all(verbatimSynonyms.map(async synonym => {
                        const response = await HttpClient.post({ path: `/bots/${botId}/lemma`, body: { text: synonym.verbatim } });
                        return ({
                            ...synonym,
                            lemma: response.data.lemma
                        } as Synonym);
                    }));
                    return lemmatizedSynonyms;
                } else {
                    return verbatimSynonyms;
                }
            }


        },
        mergePastedItems: (pasted: Synonym[], existing: readonly Synonym[]): Synonym[] => {
            return [
                ...existing,
                ...pasted.filter(item => !existing.find(existingItem => item.verbatim === existingItem.verbatim)),
            ];
        }
    }
}