import get from 'lodash/get'
import uniq from 'lodash/uniq'
import { useCallback, useContext } from 'react'

import { Value } from '../../../../components/Select/Select';
import { IntentsContext, SurveyContext, SurveyContextType } from '../../../SurveyCreator'
import { IgnorableIntentsContainerProps } from '../types';

export const useIgnorableIntentsProps = ({onChange, question}: IgnorableIntentsContainerProps) => {
  const allIntents = useContext(IntentsContext);
  const { intents } = useContext<SurveyContextType>(SurveyContext);

  const findIntent = useCallback(
    (intent: string) => get(allIntents.find(it => it.name === intent), 'displayName', intent),
    [allIntents]
    );

  const setIgnorableIntents = useCallback(
    (ignorableIntents: string[]) => onChange({ ...question, ignorableIntents }),
    [onChange, question]);

  const selectedIntents = (question.ignorableIntents || []).map(intent => ({ id: intent, name: findIntent(intent) }));
  
  const addIgnorableIntent = useCallback(
    (value: Value) => setIgnorableIntents(uniq([...question.ignorableIntents, value.id])),
    [onChange, question, setIgnorableIntents])

  const removeIgnorableIntent = useCallback(
    (value: Value) => setIgnorableIntents(question.ignorableIntents.filter(el => el !== value.id)), 
    [onChange, question, setIgnorableIntents])

  return { 
    intents, 
    selectedIntents, 
    findIntent,
    addIgnorableIntent,
    removeIgnorableIntent
  }
}
