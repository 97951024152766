import React from 'react';

import {IconProps} from './iconTypes';

export function CrossIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 13 13">
            <g className={props.animationClass} fillRule="evenodd">
                <path d="M11.314 0l1.414 1.414L1.414 12.728 0 11.314z"/>
                <path d="M12.728 11.314l-1.414 1.414L0 1.414 1.414 0z"/>
            </g>
        </svg>

    );
}
