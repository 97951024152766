import config from '../../branding/config.json';
import { useEffect, useState } from 'react';

const useBranding = () => {
  const domain = window.location.hostname;
  const brandConfig = config.brands.find(c => c.domain === domain) || config.brands.find(c => c.default);
  const [logoSquare, setLogoSquare] = useState(null);
  const [logoWide, setLogoWide] = useState(null);
  const [favicon, setFavicon] = useState(null);
  const [faviconWithNotification, setFaviconWithNotification] = useState(null);

  const initBranding = async () => {
    const [logoSquareModule, logoWideModule, faviconModule, faviconWithNotificationModule] = await Promise.all([
      import(`../../branding/${brandConfig.name}/logoSquare.svg`),
      import(`../../branding/${brandConfig.name}/logoWide.svg`),
      import(`../../branding/${brandConfig.name}/favicon.ico`),
      import(`../../branding/${brandConfig.name}/faviconWithNotification.ico`),
      import(`../../branding/${brandConfig.name}/Theme`),
    ]);
    setLogoSquare(logoSquareModule.default);
    setLogoWide(logoWideModule.default);
    setFavicon(faviconModule.default);
    setFaviconWithNotification(faviconWithNotificationModule.default);
  };

  useEffect(() => {
    initBranding();
  }, []);

  return {
    name: brandConfig.name,
    logoSquare,
    logoWide,
    favicon,
    faviconWithNotification,
    title: brandConfig.title,
    primaryColor: brandConfig.primaryColor,
  };
};

export default useBranding;
