import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import TermsOfServiceInfoModal from '../../TermsOfService/TermsOfServiceInfoModal';

import styles from './TermsOfServiceInformation.pcss';

const TERMS_OF_SERVICE_EFFECTIVE_FROM = '22-09-2023';

interface Props {
  translationKey: string;
  termsOfServiceTranslationKey: string;
  className?: string;
}

const TermsOfServiceInformation: FC<Props> = ({
  translationKey,
  termsOfServiceTranslationKey,
  className,
}) => {
  const [tosModalOpen, setTosModalOpen] = useState(false);
  const intl = useIntl();

  return (
    <>
      <div className={className}>
        <FormattedMessage
          id={translationKey}
          values={{
            termsOfService: (
              <a
                className={styles.link}
                data-test="terms-of-service-open-modal"
                onClick={(e) => {
                  e.preventDefault();
                  setTosModalOpen(true);
                }}
              >
                <FormattedMessage id={termsOfServiceTranslationKey} />
              </a>
            ),
            date: (
              <span data-test="terms-of-service-date">
                {moment(TERMS_OF_SERVICE_EFFECTIVE_FROM, 'DD-MM-YYYY').format(
                  intl.messages[
                    'auth.signIn.termsOfServiceDateFormat'
                  ] as string
                )}
              </span>
            ),
          }}
        />
      </div>
      {tosModalOpen && (
        <TermsOfServiceInfoModal onClose={() => setTosModalOpen(false)} />
      )}
    </>
  );
};

export default TermsOfServiceInformation;
