import lodashMap from 'lodash/map';
import zipObject from 'lodash/zipObject';
import { Action } from 'redux';
import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { getType } from 'typesafe-actions';

import { RootState } from '../../../reducers/reducers';
import { HttpClient } from '../../../utils/HttpClient';

import { fetchSurveyConfig, fetchSurveyScheduleStatus, patchSurveyConfig } from './actions';
import { SurveyConfigAction } from './reducer';
import { SurveyConfigRawType, SurveyConfigType } from './types';

type NormalizeSurveyConfig = (config: SurveyConfigRawType) => SurveyConfigType;
const normalizeSurveyConfig: NormalizeSurveyConfig = (config) => ({
  ...config,
  phoneConfigs: lodashMap(config.phoneConfigs, (serverAddress, phoneNumber) => ({
    phoneNumber,
    serverAddress
  }))
})

type DenormalizeSurveyConfig = (config: SurveyConfigType) => SurveyConfigRawType;
const denormalizeSurveyConfig: DenormalizeSurveyConfig = (config: SurveyConfigType) => ({
  ...config,
  ...(config.phoneConfigs && {
    phoneConfigs: zipObject(
      lodashMap(config.phoneConfigs, 'phoneNumber'),
      lodashMap(config.phoneConfigs, 'serverAddress')
    )
  })
})

export const fetchSurveyConfigEpic: Epic<Action, SurveyConfigAction, RootState> =
  (action$: ActionsObservable<SurveyConfigAction>): Observable<SurveyConfigAction> =>
    action$.pipe(
      ofType(getType(fetchSurveyConfig.request)),
      switchMap(({ payload }: { payload: string, type: any }) =>
        from(HttpClient.get({ path: `/bots/${payload}/survey-configuration` })
        ).pipe(
          map(({ data }: { data: SurveyConfigRawType }) => fetchSurveyConfig.success(normalizeSurveyConfig(data))),
          catchError((error: string) => of(fetchSurveyConfig.failure(error))
          )))
    );

export const patchSurveyConfigEpic: Epic<Action, SurveyConfigAction, RootState> =
  (action$: ActionsObservable<SurveyConfigAction>): Observable<SurveyConfigAction> =>
    action$.pipe(
      ofType(getType(patchSurveyConfig.request)),
      switchMap(({ payload }: { payload: { id: string, config: SurveyConfigType }, type: any }) => from(
        HttpClient.patch({ path: `/bots/${payload.id}`, body: denormalizeSurveyConfig(payload.config) })
      ).pipe(
        map(() => patchSurveyConfig.success(payload.config)),
        catchError((error: string) => of(patchSurveyConfig.failure(error)))
      ))
    );

export const fetchSurveyScheduleStatusEpic: Epic<Action, SurveyConfigAction, RootState> =
  (action$: ActionsObservable<SurveyConfigAction>): Observable<SurveyConfigAction> =>
    action$.pipe(
      ofType(getType(fetchSurveyScheduleStatus.request)),
      switchMap(({ payload }: { payload: string, type: any }) =>
        from(HttpClient.get({ path: `/bots/${payload}/schedule/status` }),
        ).pipe(
          map(({ data: { enabled } }) => enabled as boolean),
          map(enabled => {
            if (enabled) {
              return from(HttpClient.get({
                path: `/bots/${payload}/contacts`,
                params: { status: 'in_progress', limit: 1 }
              }).then(({ data }) => Boolean(data.contacts && data.contacts.length > 0)))
            } else {
              return from(Promise.resolve(false));
            }
          }),
          mergeMap(isSurveyInProgress => isSurveyInProgress.pipe(map(fetchSurveyScheduleStatus.success))),
          catchError((error: string) => of(fetchSurveyScheduleStatus.failure(error)))
        )
      ));