import * as React from 'react';
import {useContext} from 'react';

import {SurveyContext, SurveyContextType} from '../SurveyCreator';

import {StepDetail} from './StepDetail';

export const StepComponent = () => {
    const {blocks, callbacks, questions} = useContext<SurveyContextType>(SurveyContext);

    return (!!blocks.current || !!callbacks.current) && !!questions.current ?
        (
            <StepDetail key={`${questions.current.id}`}
                        question={questions.current}
                        close={questions.close}
                        onQuestionUpdated={questions.save}
            />
        ) : null;

};
