import { createFetchEpic, createMergedFetchEpic } from '../../../epics/epicUtils';
import { isSynonymsArray, Synonym } from '../../model';

import { fetchGlobalWords, fetchSynonymsForWord } from './actions';

export const fetchGlobalWordsEpic = createFetchEpic(
  fetchGlobalWords,
  () => '/admin/global-synonyms'
)

export const fetchSynonymsForWordEpic = createMergedFetchEpic({
  actionCreator: fetchSynonymsForWord,
  pathCreator: ({ word, language }) => `/admin/global-synonyms/${language}/${word}`,
  payloadMapper: (args: any): ReturnType<typeof fetchSynonymsForWord['success']>['payload'] => ({
    ...args,
    synonyms: isSynonymsArray(args.synonyms)
    ? args.synonyms
    : args.synonyms.map(synonym => ({ verbatim: synonym }))
  })
})