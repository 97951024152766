import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { NotificationBadge } from '../../components/NotificationBadge/NotificationBadge';
import { IconProps } from '../../icons/iconTypes';

import styles from './HeaderLink.pcss';

type HeaderLinkProps = {
  readonly label: string;
  readonly children?: React.ReactNode;
  readonly withBadge?: boolean;
  readonly IconComponent: (props: IconProps) => React.ReactElement;
};

export function HeaderLink({
  label,
  activeClassName = styles.active,
  children,
  withBadge,
  IconComponent,
  ...props
}: HeaderLinkProps & React.ComponentProps<typeof NavLink>) {
  return (
    <NavLink
      {...props}
      activeClassName={activeClassName}
      className={styles.headerLink}
      data-test="header-link"
    >
      <IconComponent animationClass={styles.svgIcon} />
      <div className={styles.linkText}>
        <FormattedMessage id={label} />
        {withBadge && <NotificationBadge />}
      </div>
    </NavLink>
  );
}
