import * as React from 'react';

import {StepElementProps} from './StepElementProps';
import {ToggleWithLabel} from './ToggleWithLabel';

type Props = { useDynamicPhonemization?: boolean };

export const UseDynamicPhonemizationToggle = ({onChange, question}: StepElementProps<Props>) => {
    const setUseDynamicPhonemization = () => onChange({...question, useDynamicPhonemization: !question.useDynamicPhonemization});
    return (
        <ToggleWithLabel dataTest={'use-dynamic-phonemization-toggle'} labelId={'survey-creator.useDynamicPhonemizationLabel'}
                         value={!!question.useDynamicPhonemization} onToggle={setUseDynamicPhonemization} />
    );
};
