import React from 'react';

import { IconProps } from './iconTypes';

export function CloseBigIcon(props: IconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 11.153 5.023 4.175a.6.6 0 0 0-.848.848L11.153 12l-6.978 6.977a.6.6 0 0 0 .848.848L12 12.847l6.977 6.978a.6.6 0 0 0 .848-.848L12.847 12l6.978-6.977a.6.6 0 0 0-.848-.848L12 11.153z" fill="#919AAF" />
    </svg>
  )
}



