import React, { useState } from 'react';
import _, { Dictionary } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../components/Button/Button';
import { IconButton } from '../../components/IconButton/IconButton';
import { StatisticsPinIcon } from '../../icons/statistics/StatisticsPinIcon';
import { StatisticsSectionsNavigationContainer } from '../StatisticsSectionsNavigation/StatisticsSectionsNavigation';
import { ScenarioStepMetric, StatisticsSettings } from '../types';
import { ScenarioStepsDashboardTile } from './ScenarioStepsDashboardTile';
import { ScenarioStepsDashboardTileContainer } from './ScenarioStepsDashboardTileContainer';
import { useUpdateStatisticsSettings } from './hooks/useUpdateStatisticsSettings';
import styles from './ScenarioStepsDashboardEditView.pcss';
import { TooltipComponent } from '../../components/Tooltip/Tooltip';
import { HorizontalSeparator } from '../../components/HorizontalSeparator/HorizontalSeparator';

type Props = {
  closeEditMode: () => void;
  scenarioStepMetrics: Dictionary<ScenarioStepMetric>;
  statisticsSettings: StatisticsSettings;
};

export function ScenarioStepsDashboardEditView({
  closeEditMode,
  scenarioStepMetrics,
  statisticsSettings,
}: Props) {
  const [currentlyPinnedSteps, setCurrentlyPinnedSteps] = useState(
    statisticsSettings.pinnedSteps
  );

  const pinnedSteps = currentlyPinnedSteps.map(
    (stepId) => scenarioStepMetrics[stepId]
  ).filter(Boolean);
  const unpinnedSteps = _.difference(
    Object.values(scenarioStepMetrics),
    pinnedSteps
  );

  const { mutate: updateStatistics, isLoading } = useUpdateStatisticsSettings({
    onSuccess: closeEditMode,
  });

  const intl = useIntl();

  function pinScenarioStep(pinnedStep: string) {
    setCurrentlyPinnedSteps((steps) => [...steps, pinnedStep]);
  }

  function pinAllScenarioSteps() {
    const remainingSteps = unpinnedSteps.map((step) => step.id);
    setCurrentlyPinnedSteps((steps) => [...steps, ...remainingSteps]);
  }

  function unpinAllScenarioSteps() {
    setCurrentlyPinnedSteps([]);
  }

  function unpinScenarioStep(unpinnedStep: string) {
    setCurrentlyPinnedSteps((steps) =>
      steps.filter((step) => step !== unpinnedStep)
    );
  }

  function handleCancelEdit() {
    setCurrentlyPinnedSteps(statisticsSettings.pinnedSteps);
    closeEditMode();
  }

  function handleUpdateStatisticsSettings() {
    updateStatistics({
      pinnedSteps: currentlyPinnedSteps,
      stepNames: statisticsSettings.stepNames,
    });
  }

  return (
    <>
      <StatisticsSectionsNavigationContainer className={styles.navbarContainer}>
        <p className={styles.navbarDescription}>
          <FormattedMessage id="statistics.scenarioStepsDashboard.navbar.pinDescription" />
        </p>
        <div className={styles.actionsContainer}>
          <Button
            buttonType="normal"
            onClick={handleCancelEdit}
            disabled={isLoading}
            dataTest="scenario-steps-dashboard-cancel"
          >
            <FormattedMessage id="statistics.scenarioStepsDashboard.navbar.cancel" />
          </Button>
          <Button
            disabled={isLoading}
            onClick={handleUpdateStatisticsSettings}
            dataTest="scenario-steps-dashboard-save-changes"
          >
            <FormattedMessage id="statistics.scenarioStepsDashboard.navbar.saveChanges" />
          </Button>
        </div>
      </StatisticsSectionsNavigationContainer>
      {pinnedSteps.length > 0 && (
        <div data-test="scenario-steps-dashboard-pinned-steps">
          <div className={styles.sectionHeader}>
            <p
              className={styles.sectionTitle}
              data-test="scenario-steps-dashboard-section-title"
            >
              <FormattedMessage id="statistics.scenarioStepsDashboard.pinnedSteps" />
            </p>
            <Button
              onClick={unpinAllScenarioSteps}
              buttonType="link"
              dataTest="scenario-steps-dashboard-unpin-all-steps"
            >
              <FormattedMessage id="statistics.scenarioStepsDashboard.unpinAllSteps" />
            </Button>
          </div>
          <ScenarioStepsDashboardTileContainer>
            {pinnedSteps.map(({ id, name, value }) => (
              <ScenarioStepsDashboardTile
                key={id}
                variant="editingPinned"
                title={name}
                value={value}
                actions={
                  <TooltipComponent
                    tooltipText={intl.formatMessage({
                      id: 'statistics.scenarioStepsDashboard.tooltip.unpin',
                    })}
                  >
                    <IconButton
                      dataTest="scenario-steps-dashboard-step-pin-action"
                      className={styles.pinIcon}
                      iconClass={styles.pinIconActive}
                      onClick={() => unpinScenarioStep(id)}
                      Icon={StatisticsPinIcon}
                    />
                  </TooltipComponent>
                }
              />
            ))}
          </ScenarioStepsDashboardTileContainer>
        </div>
      )}
      {pinnedSteps.length > 0 && unpinnedSteps.length > 0 && (
        <HorizontalSeparator className={styles.separator} />
      )}
      {unpinnedSteps.length > 0 && (
        <div data-test="scenario-steps-dashboard-unpinned-steps">
          <div className={styles.sectionHeader}>
            <p
              className={styles.sectionTitle}
              data-test="scenario-steps-dashboard-section-title"
            >
              <FormattedMessage
                id={
                  pinnedSteps.length === 0
                    ? 'statistics.scenarioStepsDashboard.allSteps'
                    : 'statistics.scenarioStepsDashboard.remainingSteps'
                }
              />
            </p>
            <Button
              onClick={pinAllScenarioSteps}
              buttonType="link"
              dataTest="scenario-steps-dashboard-pin-all-steps"
            >
              <FormattedMessage id="statistics.scenarioStepsDashboard.pinAllSteps" />
            </Button>
          </div>
          <ScenarioStepsDashboardTileContainer>
            {unpinnedSteps.map(({ id, name, value }) => (
              <ScenarioStepsDashboardTile
                key={id}
                variant="editingUnpinned"
                title={name}
                value={value}
                actions={
                  <TooltipComponent
                    tooltipText={intl.formatMessage({
                      id: 'statistics.scenarioStepsDashboard.tooltip.pin',
                    })}
                  >
                    <IconButton
                      className={styles.pinIcon}
                      dataTest="scenario-steps-dashboard-step-pin-action"
                      onClick={() => pinScenarioStep(id)}
                      Icon={StatisticsPinIcon}
                    />
                  </TooltipComponent>
                }
              />
            ))}
          </ScenarioStepsDashboardTileContainer>
        </div>
      )}
    </>
  );
}
