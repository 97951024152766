import * as React from 'react';
import {FormattedMessage} from 'react-intl';

import styles from './NoBotsPage.pcss';

export const NoBotsPage = () => (
    <div data-test="no-bots-page" className={styles.text}>
        <div>
            <FormattedMessage id="no-bots.message_1"/>
        </div>
        <div>
            <FormattedMessage id="no-bots.message_2"/>
            {/* tslint:disable-next-line:jsx-no-plain-text-elements */}
            <a className={styles.mailto} href="mailto:support@talkie.ai">support@talkie.ai</a>
        </div>
    </div>
);
