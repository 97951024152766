import React from 'react';

import {EmailStep} from '../../model';
import {EmailConfigTab} from '../EmailConfigTab';

import {TabFactory} from './question-component-factory-model';
import {StepTabType} from './types';

export const sendEmailFactory: TabFactory<EmailStep> = {
  [StepTabType.Question]: ({question, onChange}) => {
    return (
      <EmailConfigTab question={question} onChange={onChange}/>
    );
  },
  questionLabelId: () => 'step.tabs.question.email'
};
