import React, { useState } from 'react';
import { TranscriptItemFunction } from '../models/Transcript';
import { FunctionStepLineDetails } from './FunctionStepLineDetails';
import { FunctionStep } from '../../icons/conversations/FunctionStep';
import styles from './StepLine.pcss';

type Props = {
  line: TranscriptItemFunction;
  stepLink: string;
  isStepLinkExpired: boolean | undefined;
};

export function FunctionStepLine({ line, stepLink, isStepLinkExpired }: Props) {
  const [isModalOpened, setIsModalOpened] = useState(false);

  return (
    <div className={styles.wrapper}>
      <button
        data-test="function-step-line"
        className={styles.button}
        onClick={() => setIsModalOpened(true)}
      >
        <FunctionStep animationClass={styles.icon} />
        <span className={styles.text}>{line.stepName}</span>
      </button>
      {isModalOpened && (
        <FunctionStepLineDetails
          {...{ line, stepLink }}
          onClose={() => setIsModalOpened(false)}
          isStepLinkExpired={isStepLinkExpired}
        />
      )}
    </div>
  );
}
