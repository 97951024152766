import classNames from 'classnames';
import * as React from 'react';

import { EventType } from '../models/Events';

import styles from './Event.pcss';

type Props = React.HTMLAttributes<HTMLDivElement> & Readonly<{
    dataTest?: string;
    name?: string;
    type: EventType;
}>;

export function Event({ dataTest, name, type, className, children, ...props }: Props) {
    return (
        <div
            {...props}
            className={classNames(styles.event, styles[`event-${type}`], className)}
            data-test={dataTest || 'calls-list-event'}
            title={name}
        >
            {children}
        </div>
    );
}
