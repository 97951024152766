import cx from 'classnames';
import React from 'react';

import { StandardInitialProps } from '../types';

import styles from './Tag.pcss';
import { CrossIcon } from '../../icons';
import { useBotTags } from '../../dashboard/hooks';

type TagProps = StandardInitialProps & {
  onClick?: (tag: string) => void;
  withCross?: boolean;
};

const isColorLight = (color: string) => {
  const theColor = +(
    '0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&')
  );

  // tslint:disable-next-line:no-bitwise
  const r = theColor >> 16;
  // tslint:disable-next-line:no-bitwise
  const g = (theColor >> 8) & 255;
  // tslint:disable-next-line:no-bitwise
  const b = theColor & 255;

  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  return hsp > 160;
};

export const Tag: React.FC<TagProps> = ({
  children,
  className,
  onClick,
  withCross,
  dataTest,
}) => {
  const tags = useBotTags();
  const color = tags.find((t) => t.tag === children)?.color;
  const isLight = color && isColorLight(color);

  return (
    <div
      data-test={dataTest || 'tag'}
      onClick={onClick ? () => onClick(children as string) : undefined}
      className={cx(styles.tag, className, {
        [styles.tagWithOnClick]: !!onClick,
        [styles.tagTextLight]: color && !isLight,
      })}
      style={color && { background: color }}
    >
      {children}
      {withCross && (
        <CrossIcon
          animationClass={cx(styles.removeIcon, {
            [styles.removeIconLight]: color && !isLight,
          })}
        />
      )}
    </div>
  );
};
