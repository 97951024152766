import * as React from 'react';
import {useIntl} from 'react-intl';

import {BorderedElementWithLabel} from '../../components/ElementWithLabel/BorderedElementWithLabel';
import {SelectComponent, Value} from '../../components/Select/Select';
import {QuestionCustomStatus} from '../model';

import {StepElementProps} from './StepElementProps';

type QuestionProps = { changeStatusTo?: QuestionCustomStatus };

export const CustomStatus = ({question, onChange}: StepElementProps<QuestionProps>) => {
    const intl = useIntl();

    const changeStatus = (status: Value) => onChange({...question, changeStatusTo: !!status ? status.id as QuestionCustomStatus : null});

    const optionFor = (id: QuestionCustomStatus): Value => ({id, name: intl.messages[`survey-creator.customStatus.${id}`] as string});

    const valueFor = (questionFor: QuestionProps) => !!questionFor.changeStatusTo ? optionFor(questionFor.changeStatusTo) : null;

    return (
      <BorderedElementWithLabel labelId={'survey-creator.customStatus'}>
        <SelectComponent
          data-test="custom-status"
          isClearable={true}
          options={[optionFor('voicemail'), optionFor('stop_calling'), optionFor('all_required_questions_answered')]}
          onChange={changeStatus}
          value={valueFor(question)}
        />
      </BorderedElementWithLabel>
    );
};
