import { Auth } from 'aws-amplify';
import qs from 'qs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';

import { useForm } from '../../forms/useForm';
import { AlertIcon } from '../../icons';

import { LoginBox } from './components/LoginBox';
import { PageContainer } from '../components/PageContainer';
import { ActivationLinkAlreadyUsedMessage } from './components/messages/ActivationLinkAlreadyUsedMessage';
import { ActivationLinkExpiredMessage } from './components/messages/ActivationLinkExpiredMessage';
import { LoginDetailsAreIncorrectMessage } from './components/messages/LoginDetailsAreIncorrectMessage';
import { SetNewPasswordConfirmationMessage } from './components/messages/SetNewPasswordConfirmationMessage';
import { SomethingWentWrongMessage } from './components/messages/SomethingWentWrongMessage';
import { LoginForm } from './forms/LoginForm';
import { CognitoUser } from './types';
import TermsOfServiceInformation from './components/TermsOfServiceInformation';

import styles from './LoginFormContainer.pcss';

const initialFields = {
  email: {
    name: 'email',
    labelId: 'auth.signIn.email',
  },
  password: {
    name: 'password',
    labelId: 'auth.signIn.password',
  },
};

enum LoginError {
  setNewPasswordConfirmation = 'setNewPasswordConfirmation',
  activationLinkAlreadyUsed = 'activationLinkAlreadyUsed',
  activationLinkExpired = 'activationLinkExpired',
  passwordAttemptsExceeded = 'passwordAttemptsExceeded',
  incorrectUsernameOrPassword = 'incorrectUsernameOrPassword',
  userIsDisabled = 'userIsDisabled',
}

const loginErrorToMessage = {
  setNewPasswordConfirmation: {
    message: <SetNewPasswordConfirmationMessage />,
  },
  activationLinkAlreadyUsed: {
    message: <ActivationLinkAlreadyUsedMessage />,
    MessageIcon: AlertIcon,
  },
  activationLinkExpired: {
    message: <ActivationLinkExpiredMessage />,
    MessageIcon: AlertIcon,
  },
  incorrectUsernameOrPassword: {
    message: <LoginDetailsAreIncorrectMessage />,
    MessageIcon: AlertIcon,
  },
  passwordAttemptsExceeded: {
    message: <LoginDetailsAreIncorrectMessage />,
    MessageIcon: AlertIcon,
  },
  userIsDisabled: {
    message: <LoginDetailsAreIncorrectMessage />,
    MessageIcon: AlertIcon,
  },
  genericErrorMessage: {
    message: <SomethingWentWrongMessage />,
    MessageIcon: AlertIcon,
  },
} as const;

interface Props {
  setUser: (user: CognitoUser) => void;
}

export const LoginFormContainer: React.FC<Props> = ({ setUser }) => {
  const [loginBoxMessage, setLoginBoxMessage] = useState<string | null>(null);
  const intl = useIntl();
  const history = useHistory();
  const { search } = useLocation();
  const query = useMemo(
    () => qs.parse(search, { ignoreQueryPrefix: true }),
    [search]
  );

  useEffect(() => {
    if (query.changedPassword) {
      setLoginBoxMessage(LoginError.setNewPasswordConfirmation);
    } else if (query.activationLinkAlreadyUsed) {
      setLoginBoxMessage(LoginError.activationLinkAlreadyUsed);
    } else if (query.activationLinkExpired) {
      setLoginBoxMessage(LoginError.activationLinkExpired);
    }
  }, [query]);

  const { onSubmit, onFormSubmit, fieldsProps, isSubmitting } = useForm({
    initialFields,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const user = await Auth.signIn(values.email, values.password);

        if (user.challengeName === 'SMS_MFA') {
          setUser(user);
          history.replace('/login/enter-mfa-code', { userCredentials: values });
        } else {
          history.replace('/');
        }
      } catch (e) {
        if (e.message === 'Incorrect username or password.') {
          setLoginBoxMessage(LoginError.incorrectUsernameOrPassword);
        } else if (e.message === 'Password attempts exceeded') {
          setLoginBoxMessage(LoginError.passwordAttemptsExceeded);
        } else if (e.message === 'User is disabled.') {
          setLoginBoxMessage(LoginError.userIsDisabled);
        } else {
          setLoginBoxMessage(e.message);
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  const onForgotPasswordClick = useCallback(
    () => history.push('/login/reset-password'),
    []
  );

  const boxMessageProps = loginBoxMessage
    ? loginErrorToMessage[loginBoxMessage] ??
      loginErrorToMessage.genericErrorMessage
    : null;

  return (
    <PageContainer>
      <LoginBox
        header={intl.messages['auth.signIn.header'] as string}
        {...boxMessageProps}
      >
        <LoginForm
          fieldsProps={fieldsProps}
          onSubmit={onSubmit}
          onFormSubmit={onFormSubmit}
          onForgotPasswordClick={onForgotPasswordClick}
          isSubmitting={isSubmitting}
        />
      </LoginBox>
      <TermsOfServiceInformation
        className={styles.termsOfServiceInfo}
        translationKey={'auth.signIn.termsOfServiceLoginReminder'}
        termsOfServiceTranslationKey={
          'auth.signIn.termsOfServiceLoginReminder.termsOfService'
        }
      />
    </PageContainer>
  );
};
