import moment from 'moment';
import * as React from 'react';
import {useCallback} from 'react';
import {CallDurationFilter} from '../filters/CallDurationFilter/CallDurationFilter';
import {PhraseFilter} from '../filters/PhraseFilter/PhraseFilter';
import {CallDuration} from '../filters/types';
import {nowInTimezone} from '../utils/DateService';
import {periodToRange} from './period';
import {Period, PeriodSelect} from './PeriodSelect';
import {DownloadButton} from '../components/DownloadButton/DownloadButton';
import { DatePicker } from '../components/Dates/DatePicker/DatePicker';
import {StatisticsFilters} from './Statistics';

import styles from './StatisticsFilterView.pcss';

interface Props {
  filters: StatisticsFilters;
  timezone?: string;
  setFilters: (newFilters: Partial<StatisticsFilters>) => void;
  onReport(): Promise<void>;
}

export const StatisticsFiltersView = ({filters, setFilters, onReport, timezone}: Props) => {
    const onChangePhrase = useCallback((phrase: string) => setFilters({ phrase: phrase }), [setFilters]);

    const onDurationChange = useCallback((callDuration: CallDuration) => {
      setFilters({ callDurationMin: callDuration.min?.toString(), callDurationMax: callDuration.max?.toString() });
    }, [setFilters]);

    const onPeriodChange = useCallback((period: Period) => {
      if (period === 'day') {
        setFilters({
          period,
          startDate: undefined,
          endDate: undefined,
        });
      } else {
        const range = periodToRange(timezone)[period](nowInTimezone(timezone));
        setFilters({
          period,
          startDate: range.startDate,
          endDate: range.endDate,
        });
      }
    }, [setFilters]);

    const onRangeChange = useCallback(
        (startDate?: string, endDate?: string) => {
          setFilters({
            startDate: startDate && moment.tz(startDate, timezone).startOf('day').toISOString(),
            endDate: endDate && moment.tz(endDate, timezone).endOf('day').toISOString(),
            period: 'custom',
          });
        },
        [setFilters]
    );

    return (
        <div className={styles.filters}>
            <PeriodSelect selected={filters.period} onSelected={onPeriodChange} />
            <div className={styles.dateFilter}>
              <DatePicker
                data-test="statistics-date-range-filter"
                startDate={filters.startDate ? moment.tz(filters.startDate, timezone).format('YYYY-MM-DD') : undefined}
                endDate={filters.endDate ? moment.tz(filters.endDate, timezone).format('YYYY-MM-DD') : undefined}
                onChange={onRangeChange}
                clearable={false}
              />
            </div>
            <CallDurationFilter
                className={styles.durationFilter}
                min={parseInt(filters.callDurationMin, 10)}
                max={parseInt(filters.callDurationMax, 10)}
                onChange={onDurationChange}
            />
            <PhraseFilter
                className={styles.phraseFilter}
                onChange={onChangePhrase}
                phrase={filters.phrase}
                withDebounce={true}
            />
            <div>
              <DownloadButton
                buttonType="primary"
                data-test="statistics-report-button"
                textId="statistics.button.report.download"
                onClick={onReport}
              />
            </div>
        </div>
    );
};
