import * as React from 'react';

import {Label} from '../../components/Label';
import {Toggle} from '../../components/Toggle/Toggle';
import {ToggleDescription} from '../../components/ToogleDescription/ToggleDescription';

import styles from './ToggleWithLabel.pcss';

export const ToggleWithLabel = (props: { value: boolean, onToggle: () => void, dataTest?: string, labelId: string, descriptionId?: string }) => (
  <div data-test={props.dataTest} className={styles.container}>
      <div className={styles.toggle}>
          <Toggle on={props.value} onToggle={props.onToggle}/>
          <Label labelId={props.labelId}/>
      </div>
      {
          props.descriptionId &&
          <div className={styles.description}>
              <ToggleDescription descriptionId={props.descriptionId}/>
          </div>
      }
  </div>
);
