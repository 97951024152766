import React from 'react'
import {useIntl} from 'react-intl';

import {SelectComponent, Value} from '../../../components/Select/Select';
import {Condition, ConditionOperator} from '../../model'
import {VariableType} from '../variables/Variables.utils';

import styles from './ConditionalQuestionForm.pcss';

const resolveOptionsForCondition = (
    conditionSourceType: VariableType, 
    questionSaveToVariableId: string,
    variableId: string,
    messages: any
    ) => {
    const lt = { id: ConditionOperator.LessThan, name: messages['survey-creator.condition.lt'] };
    const gt = { id: ConditionOperator.GreaterThan, name: messages['survey-creator.condition.gt'] };
    const eq = { id: ConditionOperator.Equal, name: messages['survey-creator.condition.eq'] };
    const neq = { id: ConditionOperator.NotEqual, name: messages['survey-creator.condition.neq'] };
    const iN = { id: ConditionOperator.In, name: messages['survey-creator.condition.in'] };
    const truthy = { id: ConditionOperator.Truthy, name: messages['survey-creator.condition.truthy'] };
    const falsy = { id: ConditionOperator.Falsy, name: messages['survey-creator.condition.falsy'] };
    const exists = { id: ConditionOperator.Exists, name: messages['survey-creator.condition.exists'] };
    const includes = { id: ConditionOperator.Includes, name: messages['survey-creator.condition.includes'] };
    const invalid = { id: ConditionOperator.Invalid, name: messages['survey-creator.condition.invalid'] };

    const questionSavesToCurrentVariable = !!questionSaveToVariableId && !!variableId && questionSaveToVariableId === variableId

    switch (conditionSourceType) {
        case VariableType.NUMBER:
            return [eq, neq, lt, gt, exists, questionSavesToCurrentVariable && invalid];
        case VariableType.DICTIONARY:
            return [eq, neq, iN, includes, exists, questionSavesToCurrentVariable && invalid];
        case VariableType.BOOLEAN:
            return [truthy, falsy, exists, questionSavesToCurrentVariable && invalid];
        case VariableType.STRING:
            return [exists, includes, eq, neq];
        default:
            return [];
    }
};

type ConditionOperatorSelectorProps = {
    type: VariableType;
    condition: Condition;
    questionSaveToVariableId: string;
    onChange: (condition: Condition) => void;
    error?: string;
}

const isFalsy = (condition: Condition) => condition.operator === ConditionOperator.Truthy && condition.operand === false;

export function ConditionOperatorSelector({ type, condition, questionSaveToVariableId, onChange, error }: ConditionOperatorSelectorProps) {
    const intl = useIntl();

    const label = intl.messages[`survey-creator.condition.${isFalsy(condition) ? 'falsy' : condition.operator}`] as string;
    const options = resolveOptionsForCondition(type, questionSaveToVariableId, condition.variableId, intl.messages).filter(Boolean);
    const value = label ? { id: isFalsy(condition) ? 'falsy' : condition.operator, name: label } : undefined

    function handleChange(newValue: Value) {
        const newOperator = newValue.id;

        if (newOperator === 'falsy') {
            onChange({
                ...condition,
                operator: ConditionOperator.Truthy,
                operand: false,
            });
            return;
        }

        const defaultOperandsForOperators = {
            'truthy': true,
            'exists': true,
        };

        const operatorsWithMatchingTypes = [
            ['in', 'includes'],
            ['eq', 'neq'],
            ['gt', 'lt'],
            ['truthy', 'exists'],
        ];

        let newOperand = defaultOperandsForOperators[newOperator] ?? null;

        if (operatorsWithMatchingTypes.some(operators => operators.includes(condition.operator) && operators.includes(newOperator))) {
            newOperand = condition.operand;
        }

        onChange({
            ...condition,
            operator: newOperator,
            operand: newOperand,
        });
    }

    return (
        <div data-test="condition-operator" className={styles.conditionOperator}>
            <span>{intl.messages['survey-creator.conditional.selectConditionOperatorPlaceholder']}</span>
            <SelectComponent
                value={value}
                options={options}
                onChange={handleChange}
                isDisabled={!condition.variableId}
                placeholderMessage={'survey-creator.conditional.selectConditionOperatorPlaceholder'}
                invalid={!!error}
                error={error}
            />
        </div>
    );
}