import * as React from 'react';

import {Message} from '../model';

import {MessageComponent} from './messages/Message';
import {StepElementProps} from './StepElementProps';
import {useContext} from 'react';
import {SurveyContext, SurveyContextType} from '../SurveyCreator';

export const RedirectMessage = (props: StepElementProps<{ id: string; message: Message }>) => {
  const { questions: { withError } } = useContext<SurveyContextType>(SurveyContext);

  const setMessage = (message: Message) => {
    props.onChange({
      ...props.question,
      message
    });
  };
  return (
    <MessageComponent message={props.question.message} dataTestPrefix={'question'} labelId={'survey-creator.statementLabel'} onChange={setMessage} errors={withError(props.question.id, 'message')}/>
  );
};
