import produce from 'immer';
import isEqual from 'lodash/isEqual'
import { ActionType, createReducer } from 'typesafe-actions';

import { Loadable } from '../../../reducers/types';

import * as actions from './actions'
import { SurveyConfig } from './actionTypes'
import { SurveyConfigType } from './types';

export class SurveyConfigStore {
  savedConfig: Loadable<SurveyConfigType> = {
    isLoading: false,
    isReady: false,
    hasError: false
  };
  modifiedConfig?: SurveyConfigType;
  isSurveyInProgress?: boolean = false;
  dirty?: boolean = false;
}

export const defaultState = {
  ...new SurveyConfigStore()
};

export type SurveyConfigAction = ActionType<typeof actions>;

export const surveyConfig = createReducer<SurveyConfigStore, SurveyConfigAction>(defaultState, {
  [SurveyConfig.FETCH_SURVEY_CONFIG]: (state) =>
    produce(state, draft => {
      draft.savedConfig.hasError = false;
      draft.savedConfig.isLoading = true;
      draft.savedConfig.isReady = false;
    }),
  [SurveyConfig.FETCH_SURVEY_CONFIG_SUCCESS]: (state, { payload }: { payload: SurveyConfigType }) =>
    produce(state, draft => {
      draft.savedConfig.hasError = false;
      draft.savedConfig.isLoading = false;
      draft.savedConfig.isReady = true;
      draft.savedConfig.data = payload;
      draft.modifiedConfig = payload;
      draft.dirty = false;
    }),
  [SurveyConfig.FETCH_SURVEY_CONFIG_FAILURE]: (state, { payload }) =>
    produce(state, draft => {
      draft.savedConfig.hasError = true;
      draft.savedConfig.isLoading = false;
      draft.savedConfig.isReady = true;
      draft.savedConfig.error = String(payload);
    }),
  [SurveyConfig.UPDATE_MODIFIED_CONFIG]: (state, { payload }) =>
    produce(state, draft => {
      draft.modifiedConfig = {
        ...draft.modifiedConfig,
        ...payload
      };
      draft.dirty = !isEqual(draft.modifiedConfig, draft.savedConfig.data);
    }),
  [SurveyConfig.DELETE_MODIFIED_CONFIG]: (state) =>
    produce(state, draft => {
      draft.modifiedConfig = draft.savedConfig.data;
      draft.dirty = false;
    }),
  [SurveyConfig.PATCH_SURVEY_CONFIG_SUCCESS]: (state, { payload }) =>
    produce(state, draft => {
      draft.savedConfig.hasError = false;
      draft.savedConfig.isLoading = false;
      draft.savedConfig.isReady = true;
      draft.savedConfig.data = payload;
      draft.dirty = false;
    }),
  [SurveyConfig.FETCH_SURVEY_SCHEDULE_STATUS_SUCCESS]: (state, { payload }) =>
    produce(state, draft => {
      draft.isSurveyInProgress = payload;
    })
});