import classNames from 'classnames';
import * as React from 'react';
import { InputHTMLAttributes, useEffect, useImperativeHandle, useRef, useState } from 'react';

import styles from './Input.pcss';

type Props = {
    dataTest?: string;
    dataPrefix?: string;
    pattern?: string;
    value?: string | number;
    error?: string;
    onChange: (value?: string) => void;
    onBlur?: React.FocusEventHandler;
    width?: number | string;
    className?: string;
}

export type InputProps = Props & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

export const Input = React.forwardRef(({dataTest, dataPrefix, value, onChange, pattern, width, className = '', type, error, ...props}: InputProps, ref: any) => {
    const classes = classNames(styles.input, {
        [styles.error]: !!error
    });
    const [cursorPosition, setCursorPosition] = useState({ start: 0, end: 0 });
    const internalRef = useRef<HTMLInputElement>();
    useImperativeHandle(ref, () => internalRef.current);

    useEffect(() => {
        internalRef.current.setSelectionRange(cursorPosition.start, cursorPosition.end);
    }, [value]);

    return (
      <div className={classNames(styles.container, className)} style={{width}}>
        <input {...props} ref={internalRef} className={classes} type={type || 'text'} pattern={pattern} data-test={dataTest} data-prefix={dataPrefix} value={value} onChange={(event) => {
            const { selectionStart, selectionEnd, value: newValue } = event.target;
            setCursorPosition({ start: selectionStart, end: selectionEnd });
            onChange(newValue);
        }}/>
        {!!error && <div className={styles.tooltip} role="tooltip">{error}</div> }
      </div>
    );
});
