type Calendar = string;

export interface TimeOfDay {
    hours?: number
    minutes?: number
}

export interface TimeOfDayRule {
    from?: TimeOfDay
    to?: TimeOfDay
}

interface SkipHolidaysRule {
    calendar: Calendar
}

export type DayOfWeek = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';

interface AllowedDaysOfWeekRule {
    daysOfWeek: DayOfWeek[]
}

export interface PhoneCallStepConfig {
    transport: 'call'
}

export interface SmsStepConfig {
    transport: 'sms'
    messageTemplate: string
    senderName?: string
}

export interface HttpStepConfig {
    transport: 'http'
    url: string;
    method: HTTPMethod;
    headers?: HttpStepHeader[];
    body?: string;
}

type HTTPMethod = 'get' | 'post' | 'put' | 'delete';

export type HttpStepHeader = {
    name: string;
    value: string;
};

export type OnStepChangeType = (newStepValue: AnyStep) => void;

export type StepConfig = PhoneCallStepConfig | SmsStepConfig | HttpStepConfig;

export type StepType = 'JustInTime' | 'FollowUpInGivenHours' | 'FollowUpInGivenDaysAt';

export type TransportType = 'call' | 'sms' | 'http';

interface ScheduleStep<S extends StepConfig> {
    stepConfig: S
}

interface JustInTimeScheduleStep<S extends StepConfig> extends ScheduleStep<S> {
    type: 'JustInTime'
}

export interface FollowUpInGivenHoursScheduleStep<S extends StepConfig> extends ScheduleStep<S> {
    type: 'FollowUpInGivenHours'
    hours?: number
    minutes?: number
}

export interface FollowUpInGivenDaysAtScheduleStep<S extends StepConfig> extends ScheduleStep<S> {
    type: 'FollowUpInGivenDaysAt'
    days: number
    timeOfDay: TimeOfDay
}

export type AnyStep<S extends StepConfig = any> =
    JustInTimeScheduleStep<S>
    | FollowUpInGivenHoursScheduleStep<S>
    | FollowUpInGivenDaysAtScheduleStep<S>;

export type Schedule = {
    timeOfDayRule?: TimeOfDayRule;
    skipHolidaysRule?: SkipHolidaysRule;
    allowedDaysOfWeekRule?: AllowedDaysOfWeekRule;
    interactionPlan: AnyStep[];
    maxConcurrentCalls?: number
    enableConcurrentCallsLimit?: boolean;
    acceptIncomingCallsOnSurveyIncomplete?: boolean
};

interface Template {
    type: string;
    stepConfig: {
        transport: string;
        messageTemplate?: string;
        url?: string;
        method?: string;
        headers?: HttpStepHeader[];
    }
    hours?: number;
    days?: number;
    timeOfDay?: TimeOfDay;
}

export const getTemplateScheduleStep = (type: StepType, transport: TransportType, stepConfig?: StepConfig): AnyStep => {
    let template = { type, stepConfig: { transport } } as Template;

    if (transport === 'sms') {
        template.stepConfig = { ...template.stepConfig, messageTemplate: '', ...stepConfig }
    } else if (transport === 'http') {
        template.stepConfig = { ...template.stepConfig, url: '', method: 'get', headers: [], ...stepConfig }
    }

    if (type === 'FollowUpInGivenHours') {
        template = { ...template, hours: 0 }
    } else if (type === 'FollowUpInGivenDaysAt') {
        template = { ...template, days: undefined, timeOfDay: undefined }
    }
    return template as AnyStep;
};