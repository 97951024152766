import _ from 'lodash';
import * as React from 'react';
import { useContext } from 'react';

import { slugifyIntentName, SurveyIntent } from '../model';
import { Intent, IntentsContext } from '../SurveyCreator';
import { useCreatorRouter } from '../useCreatorRouter';

import { IntentOptionsComponent } from './IntentOptions';
import styles from './Intents.pcss';
import { PillComponent } from './pill/Pill';

type Props = {
    selected?: boolean;
    intent: SurveyIntent;
    onRemove: (intent: SurveyIntent) => void;
    onAssignGroup: (group: string) => void;
    onRemoveGroup?: () => void;
    onIntentNameUpdate: (newName: string, intent: Intent) => void;
    onEditToggle?: (value: boolean) => void;
};

export const IntentComponent = ({ intent, selected, onRemove, onIntentNameUpdate, onAssignGroup, onRemoveGroup, onEditToggle }: Props) => {
    const allIntents = useContext(IntentsContext);
    const intentName = _.get(allIntents.find(item => item.name === intent.name), 'displayName', intent.name);

    const { navigateToIntent } = useCreatorRouter()

    return (
        <PillComponent 
            onSelect={() => navigateToIntent(slugifyIntentName(intent.name))}
            item={intent}
            itemName={intentName}
            selected={selected}
            onItemNameChange={onIntentNameUpdate}
            dataTest={'survey-creator-intent'}
            isEditable
            onEditToggle={onEditToggle}
            renderOptions={
                (params) => (
                    <>
                        <IntentOptionsComponent
                            {...params}
                            onDelete={() => onRemove(intent)}
                            onAssignGroup={onAssignGroup}
                            onRemoveGroup={onRemoveGroup} />
                    </>
                )
            }
            classes={{ editableContainer: styles.editableContainer }}
        />
    );
};
