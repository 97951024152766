import { Modal } from '../../components/Modal/Modal';
import { ModalContent } from '../../components/ModalContent/ModalContent';
import {default as React, useEffect, useState} from 'react';
import styles from './ImportVisitsAsContactsModal.pcss';
import {FormattedMessage, useIntl} from 'react-intl';
import { Button } from '../../components/Button/Button';
import cx from 'classnames';
import { SupportedDictionary } from '../model';
import { VisitsFilteringStage } from './VisitsFilteringStage';
import { VisitsExclusionStage } from './VisitsExclusionStage';
import useIntegration from '../useIntegration';
import { PlannerExecutionStage } from './PlannerExecutionStage';
import {useGetScheduleStatus, useSchedule} from '../../scheduler/scheduler-api';
import _ from 'lodash';
import uniq from 'lodash/uniq';
import {HttpClient} from '../../utils/HttpClient';
import {Schedule} from '../../scheduler/schedule-model';
import {showErrorToast, showSuccessToast, showWarningToast} from '../../components/Toast/Toast';
import {MarkdownContent} from '../../components/MarkdownContent/MarkdownContent';
import {SurveyConfigType} from '../../views/Settings/store/types';
import {Link} from 'react-router-dom';
import {getBotUrlId} from '../../utils/CurrentBot';
import {Bot} from '../../surveyCreator/model';
import {TooltipComponent} from '../../components/Tooltip/Tooltip';

export const getStringFromConditions = (conditions: { option: { name: string; id: string; } | null; values: { id: string; name: string; }[] }[]) => Object.entries(
  conditions
    .filter((cond) => cond.option && cond.values?.length > 0)
    .reduce<Record<string, string[]>>((acc, cond) => ({
      ...acc,
      [cond.option.id]: uniq([...(acc[cond.option.id] || []), ...cond.values.map(v => v.name)]),
    }), {})
).map(([key, value]) => (
  <>
    {'; '}
    <FormattedMessage id={`contacts.add.integrationModal.step2.query.${key.toLowerCase()}`} />{': '}
    <b>{value.join(', ')}</b>
  </>
));

const ModalSteps = ({ stepsCount, activeStep }) => (
  <div className={styles.stepsContainer}>
    {Array.apply(null, Array(stepsCount)).map((x, i) => {
      return (
        <React.Fragment key={i}>
          {i > 0 && <div className={styles.stepsSeparator} />}
          <div
            className={cx(styles.singleStep, {
              [styles.completed]: i + 1 <= activeStep,
            })}
          >
            {i + 1}
          </div>
        </React.Fragment>
      );
    })}
  </div>
);

const modalTitles = {
  '1': 'contacts.add.integrationModal.step1.title',
  '2': 'contacts.add.integrationModal.step2.title',
  '3': 'contacts.add.integrationModal.step3.title',
};

export const ImportVisitsAsContactsModal = ({
  isOpen,
  close,
  integration,
  currentBot,
  surveyConfig,
  hasSchedule,
  timezone,
}: {
  isOpen: boolean;
  close: () => void;
  integration: {
    name: string;
    system: string;
    supportedDictionaries: SupportedDictionary[];
  };
  currentBot: Bot;
  surveyConfig: SurveyConfigType;
  hasSchedule: boolean;
  timezone?: string
}) => {
  const intl = useIntl();
  const { mutate } = useSchedule(currentBot.id);
  const { data: status } = useGetScheduleStatus(currentBot.id);
  const hasPhoneNumbers = Boolean(surveyConfig?.phoneConfigs?.length);
  const disabled = !hasSchedule || !hasPhoneNumbers;
  const currentState = disabled ? 'no-schedule' : _.get(status, 'state', 'no-schedule');

  const [step, setStep] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [timeFrom, setTimeFrom] = useState(360); // 360 minutes (time 06:00)
  const [timeTo, setTimeTo] = useState(1200); // 1200 minutes (time 20:00)
  const [conditions, setConditions] = useState([{ option: null, values: [] }]);
  const [schedule, setSchedule] = useState<Schedule>(null);

  const {
    doctors,
    clinics,
    practices,
    services,
    fetchContacts,
    contacts,
    toggleContactStatus,
    isLoadingContacts,
  } = useIntegration({
    botId: currentBot.id,
    startDate,
    endDate,
    timeFrom,
    timeTo,
    conditions,
    integration,
  });

  const handleClosingModal = () => {
    setStep(1);
    setStartDate(null);
    setEndDate(null);
    setTimeFrom(360);
    setTimeTo(1200);
    setConditions([{ option: null, values: [] }]);
    close();
  }

  const importContacts = async () => {
    const dataToUpload = contacts
      .filter(contact => !contact.excluded)
      .map((item: any) => {
        return {
          phoneNumber: item.phoneNumber,
          variables: item.additionalData,
        };
      });

    const importResponse = await HttpClient.post({
      path: `/bots/${currentBot.id}/contacts`,
      body: dataToUpload,
    });

    return (importResponse.status === 207) ? importResponse.data.contactsCreated : dataToUpload.length;
  };

  useEffect(() => {
    if (currentBot.id && integration) {
      HttpClient.get({path: `/bots/${currentBot.id}/schedule`})
        .then(response => response.data)
        .then(data => {
          setSchedule(data);
        })
        .catch(() => {
          setSchedule(null);
        });
    }
  }, [currentBot.id, integration]);

  if (!isOpen) {
    return null;
  }

  const isRequiredFilterUnset = !startDate || !endDate || !timeFrom || !timeTo || !conditions.some(cond => cond.option && cond.values.length > 0);

  return (
    <Modal
      position="top"
      dataTest="import-visits-as-contacts-modal"
      noPadding
      noFixedHeight
      modalSize="large"
    >
      <ModalContent
        headerContent={
          <div className={styles.headerContainer}>
            <FormattedMessage id={modalTitles[step]} />
            <ModalSteps stepsCount={3} activeStep={step} />
          </div>
        }
        footerContent={
          <>
            {step === 1 && (
              <>
                <Button
                  buttonType="normal"
                  dataTest="import-visits-as-contacts-modal-cancel-button"
                  translateText="contacts.add.integrationModal.cancel"
                  onClick={handleClosingModal}
                />
                <TooltipComponent
                  tooltipText={intl.formatMessage({
                    id: 'contacts.add.integrationModal.nextDisabledTooltip',
                  })}
                  trigger={isRequiredFilterUnset ? 'hover' : 'none'}
                >
                  <Button
                    dataTest="import-visits-as-contacts-modal-next-step-button"
                    translateText="contacts.add.integrationModal.next"
                    onClick={() => {
                      fetchContacts();
                      setStep((state) => state + 1);
                    }}
                    disabled={isRequiredFilterUnset}
                  />
                </TooltipComponent>
              </>
            )}

            {step === 2 && (
              <>
                <Button
                  buttonType="normal"
                  dataTest="import-visits-as-contacts-modal-back-button"
                  translateText="contacts.add.integrationModal.back"
                  onClick={() => {
                    setStep((state) => state - 1);
                  }}
                />
                <Button
                  dataTest="import-visits-as-contacts-modal-next-step-button"
                  translateText="contacts.add.integrationModal.confirm"
                  onClick={() => {
                    setStep((state) => state + 1);
                  }}
                />
              </>
            )}

            {step === 3 && currentState !== 'running' && (
              <>
                <Button
                  buttonType="normal"
                  dataTest="import-visits-as-contacts-modal-planner-paused-import-button"
                  translateText="contacts.add.integrationModal.inactivePlanner.import"
                  className={styles.autoWidthButton}
                  onClick={async () => {
                    try {
                      const importedContactsCount = await importContacts();
                      if (importedContactsCount === contacts.filter(contact => !contact.excluded).length) {
                        showSuccessToast(
                          <FormattedMessage
                            id="contacts.add.integrationModal.import.successToast.disabledPlanner"
                            values={{
                              count: contacts.filter(contact => !contact.excluded).length,
                              link: (
                                <Button buttonType="link" onClick={() => mutate({ action: 'resume' })}>
                                  <FormattedMessage
                                    id="contacts.add.integrationModal.import.successToast.disabledPlannerLink"/>
                                </Button>
                              ),
                            }}
                          />
                        );
                      } else {
                        showWarningToast(
                          <FormattedMessage
                            id="contacts.add.integrationModal.import.warningToast.disabledPlanner"
                            values={{
                              successCount: importedContactsCount,
                              failureCount: contacts.filter(contact => !contact.excluded).length - importedContactsCount,
                              resumePlannerLink: (
                                <Button buttonType="link" onClick={() => mutate({ action: 'resume' })}>
                                  <FormattedMessage
                                    id="contacts.add.integrationModal.import.warningToast.resumePlannerLink"/>
                                </Button>
                              ),
                              notImportedVisitsLink: (
                                <Link to={`/${getBotUrlId(currentBot)}/reports/${intl.messages['contacts.add.integrationModal.import.warningToast.notImportedVisitsReportName']}`}>
                                  <FormattedMessage id="contacts.add.integrationModal.import.warningToast.notImportedVisitsLink" />
                                </Link>
                              ),
                            }}
                          />
                        );
                      }
                    } catch (error) {
                      showErrorToast(
                        <MarkdownContent contentKey="contacts.add.integrationModal.import.errorToast" />
                      );
                    }
                    handleClosingModal();
                  }}
                />
                <Button
                  dataTest="import-visits-as-contacts-modal-planner-paused-import-and-run-button"
                  translateText="contacts.add.integrationModal.inactivePlanner.importAndRun"
                  onClick={async () => {
                    try {
                      const importedContactsCount = await importContacts();
                      mutate({action: 'resume'});
                      if (importedContactsCount === contacts.filter(contact => !contact.excluded).length) {
                        showSuccessToast(
                          <FormattedMessage
                            id="contacts.add.integrationModal.import.successToast.enabledPlanner"
                            values={{
                              count: contacts.filter(contact => !contact.excluded).length,
                              link: (
                                <Link to={`/${getBotUrlId(currentBot)}/planer`}>
                                  <FormattedMessage
                                    id="contacts.add.integrationModal.import.successToast.enabledPlannerLink"/>
                                </Link>
                              ),
                            }}
                          />
                        );
                      } else {
                        showWarningToast(
                          <FormattedMessage
                            id="contacts.add.integrationModal.import.warningToast.enabledPlanner"
                            values={{
                              successCount: importedContactsCount,
                              failureCount: contacts.filter(contact => !contact.excluded).length - importedContactsCount,
                              plannerLink: (
                                <Link to={`/${getBotUrlId(currentBot)}/planer`}>
                                  <FormattedMessage
                                    id="contacts.add.integrationModal.import.warningToast.plannerLink"/>
                                </Link>
                              ),
                              notImportedVisitsLink: (
                                <Link to={`/${getBotUrlId(currentBot)}/reports/${intl.messages['contacts.add.integrationModal.import.warningToast.notImportedVisitsReportName']}`}>
                                  <FormattedMessage id="contacts.add.integrationModal.import.warningToast.notImportedVisitsLink" />
                                </Link>
                              ),
                            }}
                          />
                        );
                      }
                    } catch (error) {
                      showErrorToast(
                        <MarkdownContent contentKey="contacts.add.integrationModal.import.errorToast" />
                      );
                    }
                    handleClosingModal();
                  }}
                />
              </>
            )}

            {step === 3 && currentState === 'running' && (
              <>
                <Button
                  buttonType="normal"
                  dataTest="import-visits-as-contacts-modal-cancel-button"
                  translateText="contacts.add.integrationModal.activePlanner.cancel"
                  onClick={handleClosingModal}
                />
                <Button
                  dataTest="import-visits-as-contacts-modal-planner-running-import-and-run-button"
                  translateText="contacts.add.integrationModal.activePlanner.import"
                  onClick={async () => {
                    try {
                      const importedContactsCount = await importContacts();
                      if (importedContactsCount === contacts.filter(contact => !contact.excluded).length) {
                        showSuccessToast(
                          <FormattedMessage
                            id="contacts.add.integrationModal.import.successToast.enabledPlanner"
                            values={{
                              count: contacts.filter(contact => !contact.excluded).length,
                              link: (
                                <Link to={`/${getBotUrlId(currentBot)}/planer`}>
                                  <FormattedMessage
                                    id="contacts.add.integrationModal.import.successToast.enabledPlannerLink"/>
                                </Link>
                              ),
                            }}
                          />
                        );
                      } else {
                        showWarningToast(
                          <FormattedMessage
                            id="contacts.add.integrationModal.import.warningToast.enabledPlanner"
                            values={{
                              successCount: importedContactsCount,
                              failureCount: contacts.filter(contact => !contact.excluded).length - importedContactsCount,
                              plannerLink: (
                                <Link to={`/${getBotUrlId(currentBot)}/planer`}>
                                  <FormattedMessage
                                    id="contacts.add.integrationModal.import.warningToast.plannerLink"/>
                                </Link>
                              ),
                              notImportedVisitsLink: (
                                <Link to={`/${getBotUrlId(currentBot)}/reports/${intl.messages['contacts.add.integrationModal.import.warningToast.notImportedVisitsReportName']}`}>
                                  <FormattedMessage id="contacts.add.integrationModal.import.warningToast.notImportedVisitsLink" />
                                </Link>
                              ),
                            }}
                          />
                        );
                      }
                    } catch (error) {
                      showErrorToast(
                        <MarkdownContent contentKey="contacts.add.integrationModal.import.errorToast" />
                      );
                    }
                    handleClosingModal();
                  }}
                />
              </>
            )}
          </>
        }
        onClose={handleClosingModal}
        closeButtonDataTest="import-visits-as-contacts-modal-close-button"
      >
        {step === 1 && (
          <VisitsFilteringStage
            integration={integration}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            timeFrom={timeFrom}
            setTimeFrom={setTimeFrom}
            timeTo={timeTo}
            setTimeTo={setTimeTo}
            conditions={conditions}
            setConditions={setConditions}
            doctors={doctors}
            clinics={clinics}
            practices={practices}
            services={services}
            timezone={timezone}
          />
        )}
        {step === 2 && (
          <VisitsExclusionStage
            contacts={contacts}
            toggleContactStatus={toggleContactStatus}
            startDate={startDate}
            endDate={endDate}
            timeFrom={timeFrom}
            timeTo={timeTo}
            conditions={conditions}
            isLoadingContacts={isLoadingContacts}
          />
        )}
        {step === 3 && (
          <PlannerExecutionStage
            contacts={contacts}
            startDate={startDate}
            endDate={endDate}
            timeFrom={timeFrom}
            timeTo={timeTo}
            conditions={conditions}
            schedule={schedule}
          />
        )}
      </ModalContent>
    </Modal>
  );
};
