import { useQuery } from 'react-query';
import { queryClient } from '../AppWrapper';

const localStorageKey = 'survey-creator-view';
const cacheKey = 'survey-creator-view';

export function getSurveyCreatorView() {
    return localStorage.getItem(localStorageKey) || 'list';
}

export function setSurveyCreatorView(view: 'list' | 'visual') {
    localStorage.setItem(localStorageKey, view);
    queryClient.setQueryData(cacheKey, view);
}

export function useSurveyCreatorView() {
    const { data } = useQuery(cacheKey, getSurveyCreatorView, {
        initialData: getSurveyCreatorView(),
        cacheTime: Infinity,
    });
    return data
}