import { DisambiguationItem } from '..';
import { HttpClient } from '../../../utils/HttpClient';
import { RawEntity, WordQuestion } from '../../model';

class StepsHttpClient {
  async getDisambiguations(question: WordQuestion, entities: RawEntity[], language: string): Promise<DisambiguationItem[]> {
    const response = await HttpClient.post({
      path: '/steps/disambiguations',
      body: {question, entities, language}
    });

    return response.data.notPreciseAnswer;
  }
}

export default StepsHttpClient;