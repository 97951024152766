import { useIntl } from 'react-intl';
import { PaletteItemsHook } from './types';
import { useCurrentBot } from '../../utils/CurrentBot';
import { useTryIt } from '../../surveyCreator/components/TryIt';
import { setSurveyCreatorView } from '../../surveyCreator/useSurveyCreatorView';
import { setIsHistoryModalOpen } from '../../surveyCreator/history/useScenarioHistory';

export const useActionItems: PaletteItemsHook = () => {
  const intl = useIntl();
  const currentBot = useCurrentBot();
  const { tryIt } = useTryIt();
  const category = intl.formatMessage({ id: `survey-creator.command-palette.categories.actions` });
  return ([
    {
      id: 'copy-bot-id',
      name: intl.formatMessage({ id: `survey-creator.command-palette.actions.copy-bot-id` }),
      category,
      onAction: () => navigator.clipboard.writeText(currentBot.id),
      data: {}
    },
    {
      id: 'copy-bot-name',
      name: intl.formatMessage({ id: `survey-creator.command-palette.actions.copy-bot-name` }),
      category,
      onAction: () => navigator.clipboard.writeText(currentBot.name),
      data: {}
    },
    {
      id: 'call me',
      name: intl.formatMessage({ id: `survey-creator.command-palette.actions.call-me` }),
      category,
      onAction: () => {
        tryIt();
      },
      data: {}
    },
    {
      id: 'creator-list-view',
      name: intl.formatMessage({ id: `survey-creator.command-palette.actions.creator-list-view` }),
      category,
      onAction: () => {
        setSurveyCreatorView('list');
      },
      data: {}
    },
    {
      id: 'creator-visual-view',
      name: intl.formatMessage({ id: `survey-creator.command-palette.actions.creator-visual-view` }),
      category,
      onAction: () => {
        setSurveyCreatorView('visual');
      },
      data: {}
    },
    {
      id: 'creator-rollback',
      name: intl.formatMessage({ id: `survey-creator.command-palette.actions.creator-rollback` }),
      category,
      onAction: () => {
        setIsHistoryModalOpen(true);
      },
      data: {}
    }
  ]);
}