import React, { useCallback, useState } from 'react';
import {HttpClient} from '../../utils/HttpClient';
import {showErrorToast, showSuccessToast} from '../../components/Toast/Toast';
import {MarkdownContent} from '../../components/MarkdownContent/MarkdownContent';


export const useTestSMS = (organizationName: string) => {
    const [selectedConfiguration, setSelectedConfiguration] = useState<null | {
        botId: string | null,
        fromPhoneNumber: string
    }>(null);


    const handleSendSMS = useCallback(
        async (toNumber: string) => {
            try {
                await HttpClient.post({
                    path: `/organizations/${organizationName}/configuration/sms/test`,
                    body: {
                        botId: selectedConfiguration?.botId || null,
                        fromNumber: selectedConfiguration.fromPhoneNumber,
                        toNumber,
                    },
                })
                showSuccessToast(<MarkdownContent contentKey="organization.sms.testSms.success"/>);
            } catch (e) {
                showErrorToast(<MarkdownContent contentKey="organization.sms.testSms.error"/>);
            } finally {
                setSelectedConfiguration(null)
            }
        },
        [organizationName, selectedConfiguration?.botId, selectedConfiguration?.fromPhoneNumber]
    );

    return {
        selectedConfiguration,
        setSelectedConfiguration,
        handleSendSMS
    }
}