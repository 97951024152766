import React, {useCallback} from 'react';
import uuid from 'uuid';

import styles from '../../../components/DictionaryAnswers.pcss';

import {AnswerTabProps} from './types';
import {
    AnswerItem,
    AnswerType,
    DisambiguationItem,
    GlobalReadOrderType,
    ItemReadOrderType,
    ReactionType
} from '../../types';

import {Scrollbar} from '../../../../components/Scrollbar/Scrollbar';
import {IndentedStepContent} from '../../../../components/layouts/IndentedStepContent/IndentedStepContent';
import {ElementContainer} from '../../../../components/ElementContainer/ElementContainer';

import {AnswerList} from '../AnswerList';
import {AddAnswerModal} from './AddAnswerModal';
import {Disambiguation, GlobalReadOrder, Incomprehension} from './';
import { HttpClient } from '../../../../utils/HttpClient';
import { useCurrentBot } from '../../../../utils/CurrentBot';
import { isLemmatizationEnabled } from '../../../../utils/featureFlags';
import { Synonym } from '../../../model';

const AnswerTab: React.FC<AnswerTabProps> = ({ question, entities, onChange }) => {
    const currentBot = useCurrentBot();
    const handleAnswersListChange = useCallback((answersList: AnswerItem[]) => {
        return onChange({ ...question, answersList });
    }, [question, onChange]);

    const handleAddAnswer = useCallback(async (value: string) => {
        if (entities.includes(value)) {
            return handleAnswersListChange([
                ...question.answersList,
                {
                    id: value,
                    type: AnswerType.ENTITY,
                    values: []
                },
            ]);
        }

        let firstSynonym: Synonym = {verbatim: value};
        if (isLemmatizationEnabled()) {
            const response = await HttpClient.post({ path: `/bots/${currentBot.id}/lemma`, body: { text: value } });
            firstSynonym.lemma = response.data.lemma;
        }

        return handleAnswersListChange([
            ...question.answersList,
            {
                id: uuid.v4(),
                key: value,
                synonyms: [firstSynonym],
                type: AnswerType.DICTIONARY,
                order: { item: ItemReadOrderType.INHERIT, global: question.readOrder },
                reaction: { type: ReactionType.CONTINUE },
            },
        ]);
    }, [question, entities, handleAnswersListChange]);

    const handleReadOrderTypeChange = useCallback((readOrder: GlobalReadOrderType) => {
        if (readOrder === GlobalReadOrderType.NONE) {
            return onChange({ ...question, readOrder });
        }

        return onChange({
            ...question,
            readOrder,
            // need to update the local (item) state, because we don't distinguish between lack of state and state === NONE
            // if we did, this would not be necessary, because it could be handled in view<->model mapping process
            answersList: question.answersList.map((answer) => {
                // entity does not support order changes
                if (answer.type === AnswerType.ENTITY) {
                    return answer;
                }

                // keep item read order and message, if there was something in place
                if (question.readOrder !== GlobalReadOrderType.NONE) {
                    return {
                        ...answer,
                        order: { item: answer.order.item, global: readOrder }
                    }
                }

                // default to inherit
                return {
                    ...answer,
                    readMessage: { text: '' },
                    order: { item: ItemReadOrderType.INHERIT, global: readOrder }
                }
            }),
        });
    }, [question, onChange]);

    const handleIncomprehensionChange = useCallback((reaction: string) => {
        if (reaction === ReactionType.CONTINUE) {
            return onChange({
                ...question,
                incomprehension: { type: ReactionType.CONTINUE },
            });
        }
        return onChange({
            ...question,
            incomprehension: { type: ReactionType.GOTO, moduleId: reaction },
        });
    }, [question, onChange]);

    const handleDisambiguationChange = useCallback((disambiguationItem: DisambiguationItem, itemIndex) => {
        onChange({
            ...question,
            disambiguation: question.disambiguation.map((d, i) => i === itemIndex ? disambiguationItem : d),
        });
    }, [question, onChange]);

    if (!question) {
        return null;
    }

    return (
        <div className={styles.dictionaryAnswers}>
            <ElementContainer>
                <AddAnswerModal onAdd={handleAddAnswer} items={entities} />
            </ElementContainer>

            <Scrollbar>
                <IndentedStepContent withTopPadding>
                    <GlobalReadOrder order={question.readOrder} onChange={handleReadOrderTypeChange} />
                    <AnswerList answers={question.answersList} onChange={handleAnswersListChange} />
                    <Incomprehension value={question.incomprehension} onChange={handleIncomprehensionChange} />
                    <Disambiguation value={question.disambiguation} onChange={handleDisambiguationChange} />
                </IndentedStepContent>
            </Scrollbar>
        </div>
    );
}

export default AnswerTab
