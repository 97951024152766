import classNames from 'classnames';
import React from 'react';

import styles from './ElementContainer.pcss';

export const ElementContainer = (props: React.PropsWithChildren<{ className?: string}>) => {
  return (
    <div className={classNames(styles.container, props.className)}>
      {props.children}
    </div>
  )
}
