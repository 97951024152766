import { IntlShape } from 'react-intl';

import ToastIcon from '../../images/toast.svg';
import ToastWarningIcon from '../../images/toastWarning.svg';
import styles from './MarkdownContent.pcss';

export type MarkdownContentI18nHelperArgs = {
  contentKey: string;
  intl: IntlShape;
  extraValues?: {
    [key: string]: string;
  };
}

export const markdownContentI18nHelper = ({
  contentKey,
  extraValues,
  intl
}: MarkdownContentI18nHelperArgs) => {
  return intl.formatMessage({ id: contentKey }, { ...styles, ...extraValues, ToastIcon, ToastWarningIcon });
}

// Used for testing because in the test environment styles are stubbed out,
// and they cause a formatting error.
export const markdownContentI18nHelperTest = ({
  contentKey,
  extraValues,
  intl
}: MarkdownContentI18nHelperArgs) => {
  return intl.formatMessage({ id: contentKey }, {
    ...extraValues,
    muted: 'muted',
    icon: 'icon',
    centered: 'centered',
    ToastIcon: 'ToastIcon',
    ToastWarningIcon: 'ToastWarningIcon',
  });
}