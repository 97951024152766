import * as _ from 'lodash';
import * as React from 'react';
import {useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';

import {NumberInputWithStepper} from '../../components/NumberInputWithStepper/NumberInputWithStepper';

import styles from './RepeatQuestionsLimit.pcss';
import {useFixedPosition} from './useFixedPosition';
import {useOutsideClick} from './useOutsideClick';

type Props = { maxRepeatQuestionCount: number, onChange: (maxRepeatQuestionCount: number) => void };

export const RepeatQuestionsLimit = ({ maxRepeatQuestionCount, onChange }: Props) => {
    const [showEdit, toggleEdit] = useState(false);
    const {contentRef, style} = useFixedPosition([], {top: -9, left: -37});
    const editContainer = useRef<HTMLDivElement>(null);
    const outsideClick = useOutsideClick(editContainer, () => toggleEdit(false));
    const startEdit = () => {
        toggleEdit(true);
        outsideClick.addHandler();
    }
    return (
        <div data-test="repeat-question-limit" className={styles.container}>
            <div className={styles.label}>
                <FormattedMessage id={'survey-creator.numberOfRepeatQuestionLimitLabel'}/>
            </div>
            <div className={styles.limit} onClick={startEdit} ref={contentRef}>
                {_.isNil(maxRepeatQuestionCount) ? '0' : maxRepeatQuestionCount}
            </div>
            {
                showEdit &&
                    <div className={styles.limitEdit} style={style} ref={editContainer}>
                        <NumberInputWithStepper value={maxRepeatQuestionCount || 0} onChange={onChange}/>
                    </div>
            }
        </div>
    );
};

