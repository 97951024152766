import React from 'react';
import { IconProps } from '../iconTypes';

export function SetVariableStep(props: IconProps) {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.animationClass}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.335 9.102v1.099a.3.3 0 0 1-.6 0V9.102H.303a.3.3 0 1 1 0-.598H2.74a.68.68 0 0 0 .51-.223.84.84 0 0 0 .217-.589c0-.174-.116-.33-.275-.491-.318-.324-.818-.63-1.316-.939C1.335 5.926.796 5.587.449 5.235c-.282-.286-.442-.59-.446-.899a1.377 1.377 0 0 1 .358-1.027c.26-.28.62-.422.985-.395h.39V1.815a.3.3 0 0 1 .6 0v1.099h1.432a.3.3 0 1 1 0 .598H1.336l-.026-.001a.623.623 0 0 0-.509.204.778.778 0 0 0-.199.587v.022c0 .174.117.33.275.492.32.323.82.63 1.317.938.542.336 1.081.675 1.428 1.028.285.29.446.597.446.905.006.375-.13.735-.377 1a1.275 1.275 0 0 1-.956.415h-.4zM9.442 1.51a.924.924 0 0 1 .728.28c.264.255.459.688.485 1.203V4.158c.006.322.107.62.291.77l.959.852a.298.298 0 0 1 0 .446l-.958.85c-.185.151-.286.45-.292.766v1.171c-.026.515-.222.948-.485 1.202a.924.924 0 0 1-.728.28h-.436a.3.3 0 1 1 0-.598h.45c.01 0 .02 0 .031.002.132.013.245-.072.338-.196.131-.174.215-.427.23-.714V7.837c.01-.513.21-.972.502-1.216l.697-.618-.697-.619c-.291-.244-.493-.703-.502-1.216v-1.15c-.015-.288-.099-.541-.23-.716-.093-.123-.206-.21-.338-.195a.292.292 0 0 1-.031.001h-.45a.3.3 0 1 1 0-.598h.436zm-2.227 8.978a.924.924 0 0 1-.728-.28c-.264-.254-.459-.687-.485-1.202V7.841c-.006-.322-.108-.62-.292-.772l-.958-.85a.299.299 0 0 1 0-.446l.958-.851c.185-.15.286-.45.291-.766v-1.17c.027-.515.222-.948.486-1.203a.924.924 0 0 1 .727-.28h.437a.3.3 0 1 1 0 .598h-.45A.3.3 0 0 1 7.17 2.1c-.132-.014-.245.072-.338.195-.131.175-.215.428-.23.715v1.151c-.01.513-.21.972-.503 1.216l-.696.619.697.618c.291.244.492.703.501 1.216v1.152c.016.287.1.54.231.714.093.124.206.21.338.196A.291.291 0 0 1 7.2 9.89h.45a.3.3 0 1 1 0 .598h-.436z"
        fill="#919AAF"
      />
    </svg>
  );
}
