import {
    DefaultReadAnswerType,
    GoTo,
    NotPreciseAnswer, NotPreciseAnswerDictionary,
    PermutableBasicMessage,
    ReactionItem,
    ReadOption,
    WordAnswerType,
    WordsDictionary,
} from '../../../model';
import {
    AnswerItem,
    AnswerItemDictionary,
    AnswerType,
    AnswerValue,
    GlobalReadOrderType,
    ItemReadOrderType,
    ReactionType,
} from '../../types';

import {Model, View} from './types';

class ViewToModelMapper {
    static globalReadOrderTypeMapping: Record<GlobalReadOrderType, DefaultReadAnswerType> = {
        [GlobalReadOrderType.NONE]: undefined,
        [GlobalReadOrderType.RANDOM]: 'PERMUTATE',
        [GlobalReadOrderType.ORIGINAL]: 'SEQUENCE',
    };
    static itemReadOrderTypeMapping: Record<ItemReadOrderType, ReadOption> = {
        [ItemReadOrderType.NONE]: undefined,
        [ItemReadOrderType.INHERIT]: undefined,
        [ItemReadOrderType.FIRST]: 'FIRST',
        [ItemReadOrderType.LAST]: 'LAST',
    };
    static answerTypeMapping: Record<AnswerType, WordAnswerType> = {
        [AnswerType.ENTITY]: WordAnswerType.ENTITY,
        [AnswerType.DICTIONARY]: WordAnswerType.DICTIONARY,
    }

    static getModelValue(view: View): Model {
        return {
            notPreciseAnswer: ViewToModelMapper.getNotPreciseAnswers(view),
            goTo: ViewToModelMapper.getGlobalGoTo(view),
            reactions: ViewToModelMapper.getAnswersReactions(view),
            defaultReadAnswerType: ViewToModelMapper.getDefaultReadAnswerType(view),
            dictionary: ViewToModelMapper.getDictionaryItems(view),
        };
    }

    static getNotPreciseAnswers(view: View): NotPreciseAnswer[] {
        return view.disambiguation
            .map((item) => ({
                message: item.message,
                repeatMessages: item.repeatMessages,
                answers: item.answers
                    .filter(({ id, key }) => {
                        return view.answersList.find((answer) => {
                            if (answer.type === AnswerType.DICTIONARY) {
                                return answer.id === id;
                            }
                            return answer.id === id && answer.values.find(v => v.key === key);
                        });
                    })
                    .map(({ id, key, type }) => {
                        if (type === AnswerType.DICTIONARY) {
                            return { id, type: WordAnswerType.DICTIONARY } as NotPreciseAnswerDictionary;
                        }

                        return { id, type: WordAnswerType.ENTITY, key };
                    }),
            }))
            .filter(item => item.answers.length > 1);
    }

    static getGlobalGoTo(view: View): GoTo {
        if (view.incomprehension.type === ReactionType.GOTO) {
            return { repeated: view.incomprehension.moduleId };
        }
        return { repeated: ReactionType.CONTINUE };
    }

    static getAnswersReactions(view: View): ReactionItem[] {
        return view.answersList.reduce((acc: ReactionItem[], answerItem: AnswerItem) => {
            if (answerItem.type === AnswerType.DICTIONARY) {
                return [
                    ...acc,
                    {
                        type: WordAnswerType.DICTIONARY,
                        goTo: ViewToModelMapper.getItemGoTo(answerItem),
                        id: answerItem.id,
                    }
                ];
            }

            return [
                ...acc,
                ...answerItem.values.map((answerValue) => ({
                    goTo: ViewToModelMapper.getItemGoTo(answerValue),
                    id: answerItem.id,
                    key: answerValue.key,
                    type: WordAnswerType.ENTITY,
                })),
            ];
        }, []);
    }

    static getItemGoTo(answerValue: AnswerValue): string {
        if (answerValue.reaction.type === ReactionType.GOTO) {
            return answerValue.reaction.moduleId;
        }
        return ReactionType.CONTINUE;
    }

    static getDefaultReadAnswerType(view: View): DefaultReadAnswerType {
        const isAllDisabled = view.answersList
            .every((item) => (
                item.type !== AnswerType.DICTIONARY ||
                (item.type === AnswerType.DICTIONARY && item.order.item === ItemReadOrderType.NONE)
            ));
        if (isAllDisabled) {
            return;
        }
        return ViewToModelMapper.globalReadOrderTypeMapping[view.readOrder];
    }

    static getDictionaryItems(view: View): WordsDictionary {
        return view.answersList.map((item) => {
            if (item.type === AnswerType.ENTITY) {
                return {
                    id: item.id,
                    key: item.id,
                    type: WordAnswerType.ENTITY,
                };
            }

            const base = {
                id: item.id,
                key: item.key,
                type: WordAnswerType.DICTIONARY,
                synonyms: item.synonyms,
            };
            const message = ViewToModelMapper.getMessageModelValue(item, view);
            if (!message) {
                return base;
            }
            return {
                ...base,
                message,
            };
        });
    }

    static getMessageModelValue(dictionaryItem: AnswerItemDictionary, view: View): PermutableBasicMessage {
        const { global, item } = dictionaryItem.order;
        const isGlobalReadingDisabled = !global || global === GlobalReadOrderType.NONE || view.readOrder === GlobalReadOrderType.NONE;
        const isItemReadingDisabled = !item || item === ItemReadOrderType.NONE;

        // disabled
        if (isGlobalReadingDisabled || isItemReadingDisabled) {
            return;
        }

        // inherit from global
        if (item === ItemReadOrderType.INHERIT) {
            return {
                ...dictionaryItem.readMessage,
                read: ViewToModelMapper.globalReadOrderTypeMapping[view.readOrder],
            }
        }

        // override global
        return {
            ...dictionaryItem.readMessage,
            read: ViewToModelMapper.itemReadOrderTypeMapping[item],
        }
    }
}

export default ViewToModelMapper;
