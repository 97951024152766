import { combineReducers } from 'redux';

import { entities } from './entities/reducer';
import { entityDetails } from './entityDetails/reducer';
import { globalSynonyms } from './globalSynonyms/reducer';

export const surveyCreator = combineReducers(
  {
    entities,
    entityDetails,
    globalSynonyms,
  }
);