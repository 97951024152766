import classNames from 'classnames';
import _ from 'lodash';
import * as React from 'react';
import {useCallback, useContext, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {Button} from '../../components/Button/Button';
import {ArrowDownIcon} from '../../icons/ArrowDownIcon';
import {ArrowUpIcon} from '../../icons/ArrowUpIcon';
import {SurveyIntent} from '../model';
import {SurveyContext, SurveyContextType} from '../SurveyCreator';

import {IntentComponent} from './Intent';
import {IntentGroupOptionsComponent} from './IntentGroupOptions';
import styles from './Intents.pcss';
import {TooltipComponent} from '../../components/Tooltip/Tooltip';
import {InformationIcon} from '../../icons/InformationIcon';

export type IntentsProps = {
  onEditToggle?: (value: boolean) => void;
}

export const Intents: React.FC<IntentsProps> = ({ onEditToggle }) => {
  const {intents} = useContext<SurveyContextType>(SurveyContext);
  const {updateName, removeFromGroup, assignGroup} = intents;
  const currentIntent = intents.current;
  const updateIntentName = useCallback(
    (newIntentName: string, intent: SurveyIntent) => updateName(intent.name, newIntentName.trim()),
    [updateName]
  );
  const intl = useIntl();
  const restoreFromLocalStorage = () => {
    const collapsedFromStorage = localStorage.getItem('collapsed-creator-intent-groups');
    return collapsedFromStorage ? JSON.parse(collapsedFromStorage) : [];
  };
  const [collapsed, setCollapsed] = useState<string[]>(restoreFromLocalStorage);

  const toggleCollapsedGroup = (group: string) => {
    setCollapsed((prevCollapsed) => {
      const newGroups = prevCollapsed.includes(group) ? prevCollapsed.filter(item => item !== group) : [...prevCollapsed, group];
      localStorage.setItem('collapsed-creator-intent-groups', JSON.stringify(newGroups));
      return newGroups;
    });
  };

  const handleRemoveFromGroup = useCallback((intentName: string) => {
    removeFromGroup(intentName);
  }, [removeFromGroup]);

  const removeIntentFromGroup = useCallback((name) => () => {
    handleRemoveFromGroup(name);
  }, [handleRemoveFromGroup]);

  const removeGroup = (group: string) => {
    intents.removeGroup(group);
    setCollapsed((prevCollapsed) => {
      const newGroups = prevCollapsed.includes(group) ? prevCollapsed.filter(item => item !== group) : prevCollapsed;
      localStorage.setItem('collapsed-creator-intent-groups', JSON.stringify(newGroups));
      return newGroups;
    });
  };

  const handleAssignToGroup = useCallback((name) => (groupToAssign) => assignGroup(name, groupToAssign), [assignGroup]);

  const [assigned, unassigned] = _.partition(intents.get(), element => element.group !== undefined);
  const groupedIntents = _.orderBy(Object.entries(_.groupBy(assigned, 'group')), ([group]) => group);

  const hasSomeGroups = groupedIntents.length > 0;
  return (
    <div className={styles.intents} data-test={'intents-list'}>
      <div className={styles.titleWithIcon}>
          <FormattedMessage id={'survey-creator.intentsLabel'}>
            {(value) => <span className={styles.title}>{value}</span>}
          </FormattedMessage>

          <TooltipComponent tooltipText={intl.messages['survey-creator.intentsTooltip'] as string}>
            <div style={{padding: '5px'}}>
              <InformationIcon/>
            </div>
          </TooltipComponent>
      </div>

      <div className={styles.list}>
        {
          groupedIntents.map(([group, intentsOfGroup]) => {
            const collapsedGroup = collapsed.includes(group);
            const itemsHeight = !collapsedGroup ? intentsOfGroup.length * 50 - 8 : 0;
            return (
              <div key={group} className={styles.intentGroup} data-test={'intent-group'}>
                <div data-test={'intent-group-header'} className={styles.groupHeader}>
                  <div data-test={'intent-group-title'} className={styles.groupTitle}>
                    {`#${group}`}
                  </div>
                  <Button data-test={'group-collapse-toggle'} className={styles.collapseToggle} onClick={() => toggleCollapsedGroup(group)}>
                    {collapsedGroup ? <ArrowDownIcon /> : <ArrowUpIcon />}
                  </Button>
                  <div data-test={'group-options'} className={styles.groupOptions}>
                    <IntentGroupOptionsComponent onDelete={() => removeGroup(group)} />
                  </div>
                </div>

              <div className={classNames(styles.groupItems, {[styles.expandedGroup]: !collapsedGroup})} style={{height: itemsHeight}} data-test={collapsedGroup ? 'collapsed-intents' : 'displayed-intents'}>
                  {
                    intentsOfGroup.map(intent => (
                      <IntentComponent
                        intent={intent}
                        key={intent.name}
                        onRemove={intents.remove}
                        selected={currentIntent && currentIntent.name === intent.name}
                        onIntentNameUpdate={updateIntentName}
                        onRemoveGroup={removeIntentFromGroup(intent.name)}
                        onAssignGroup={handleAssignToGroup(intent.name)}
                        onEditToggle={onEditToggle}
                      />
                    ))
                }
              </div>
            </div>);
          })
        }
        <div data-test={'unassigned-intents'} className={classNames({ [styles.unassigned]: hasSomeGroups })}>
          {unassigned.map(intent => (
            <IntentComponent
              intent={intent}
              key={intent.name}
              onRemove={intents.remove}
              selected={currentIntent && currentIntent.name === intent.name}
              onIntentNameUpdate={updateIntentName}
              onAssignGroup={(groupToAssign) => intents.assignGroup(intent.name, groupToAssign)}
              onEditToggle={onEditToggle}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
