import React, { useCallback } from 'react';

import { AnswerDictionaryProps } from './types';
import { AnswerItemDictionary } from '../../../types';
import { AnswerBase } from '../AnswerBase';

const AnswerDictionary: React.FC<AnswerDictionaryProps> = ({
    answer,
    onChange,
    onRemove,
}) => {
    const handleChange = useCallback((value: AnswerItemDictionary) => {
        return onChange(value);
    }, [onChange]);

    const handleRemove = useCallback(() => {
        onRemove(answer);
    }, [onRemove]);

    return <AnswerBase
        value={answer}
        onChange={handleChange}
        onRemove={handleRemove}
    />;
}

export default AnswerDictionary;
