import classNames from 'classnames';
import * as React from 'react';
import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

import styles from './Modal.pcss';

type ModalPosition = 'top' | 'center';

type Props = {
    children: ReactNode;
    dataTest?: string;
    className?: string;
    position?: ModalPosition;
    noPadding?: boolean;
    noFixedHeight?: boolean;
    modalSize?: 'large' | 'medium' | 'small';
};

export const Modal = ({ children, dataTest, className, position, noPadding, noFixedHeight, modalSize }: Props) => {
    const modalClasses = classNames(
        styles.modal,
        { [styles.topPosition]: position === 'top' },
    );
    return createPortal(
        <div data-test={dataTest || 'modal'} className={modalClasses}>
            <div className={styles.overlay} />
            <div className={classNames([styles.content, {
                [styles.noPadding]: noPadding,
                [styles.noFixedHeight]: noFixedHeight,
                [styles.large]: modalSize === 'large',
                [styles.medium]: modalSize === 'medium',
                [styles.small]: modalSize === 'small',
            }, className])}>{children}</div>
        </div>,
        document.getElementsByTagName('body')[0]
    );
};
