import React from 'react';
import { ExecutableFunctionOutputDefinition } from '../../model';
import { useVariableCreation } from '../variables/useVariableCreation';
import { Variables } from '../variables/Variables';
import { Mapping, Mappings } from '../mapping/Mapping';

type VariableOutputWidgetProps = {
  value: string;
  onChange: (value: string) => void;
};

export const VariableOutputWidget = ({
  value,
  onChange,
}: VariableOutputWidgetProps) => {
  const { selectedOption, options, handleSelect, handleRename, handleCreate } =
    useVariableCreation(
      (changedVariableValue: string) => onChange(changedVariableValue),
      value
    );

  return (
    <Variables
      selectedOption={selectedOption}
      options={options}
      handleSelect={handleSelect}
      handleRename={handleRename}
      handleCreate={handleCreate}
    />
  );
};

type MappingOutputWidgetMapping = { type: 'mapping'; mapping: Mapping[] };

type MappingOutputWidgetProps = {
  value: MappingOutputWidgetMapping;
  onChange: (value: MappingOutputWidgetMapping) => void;
};

const defaultMappingValue = { type: 'mapping' as const, mapping: [] };

export const MappingOutputWidget = ({
  value = defaultMappingValue,
  onChange,
}: MappingOutputWidgetProps) => {
  return (
    <Mappings
      mapping={value.mapping}
      onChange={(mappingsValue) =>
        onChange({ type: 'mapping', mapping: mappingsValue })
      }
    />
  );
};

export const renderOutputTypeToWidget = (
  widgetType: ExecutableFunctionOutputDefinition['type']
) => {
  switch (widgetType) {
    case 'mapping':
      return MappingOutputWidget;
    case 'string':
    case 'number':
    case 'boolean':
      return VariableOutputWidget;
    default:
      return null;
  }
};
