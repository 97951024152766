import get from 'lodash/get'
import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useRouteMatch } from 'react-router'
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { VerticalTabsLayout } from '../../components/layouts/VerticalTabsLayout/VerticalTabsLayout';
import { ViewContainer } from '../../components/ViewContainer/ViewContainer';
import { Header } from '../../layout/Header';
import { MainPage } from '../../layout/MainPage';
import { AdminTabContentContainer } from './AdminTabContent/AdminTabContentContainer'
import {ApiTabContentContainer} from './ApiTabContent/ApiTabContentContainer';
import { GeneralTabContentContainer } from './GeneralTabContent/GeneralTabContentContainer'
import { PhoneTabContentContainer } from './PhoneTabContent/PhoneTabContentContainer'
import { currentUserRoles } from '../../selectors';
import { UserRole } from '../../utils/CurrentUser';

export enum SettingsTabs {
  general = 'general',
  phone = 'phone',
  admin = 'admin',
  api = 'api',
}

const TabToComponentMapper = (tab: SettingsTabs) =>
  ({
    [SettingsTabs.general]: <GeneralTabContentContainer />,
    [SettingsTabs.phone]: <PhoneTabContentContainer />,
    [SettingsTabs.admin]: <AdminTabContentContainer />,
    [SettingsTabs.api]: <ApiTabContentContainer />,
  }[tab]);

export const SettingsViewContainer: React.FC = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const intl = useIntl();

  const roles = useSelector(currentUserRoles);
  const isAdmin = roles.includes(UserRole.ADMIN);
  const hasSettingsPermission = roles.includes(UserRole.SETTINGS);

  const tabNames =
    isAdmin || hasSettingsPermission
      ? [
          SettingsTabs.general,
          SettingsTabs.phone,
          SettingsTabs.admin,
          SettingsTabs.api,
        ]
      : [SettingsTabs.phone];

  const tabs = tabNames.map((key) => {
    return {
      id: SettingsTabs[key],
      label: String(intl.messages[`survey.config.tabs.${SettingsTabs[key]}`]),
      dataTest: `${SettingsTabs[key]}-tab`,
    };
  });

  const onTabClick = useCallback(
    (path: string) => history.push(`./${path}`),
    [history]
  );

  return <MainPage>
    <Header />
    <ViewContainer>
      <VerticalTabsLayout
        tabs={tabs}
        currentTab={get(match.params, 'tabName')}
        onTabClick={onTabClick}
        getComponentForTab={TabToComponentMapper}
      />
    </ViewContainer>
  </MainPage>
}