import React from 'react';

export function NotificationSuccessIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#zpco5hceaa)">
        <path
          d="M12 0a12 12 0 1 0 12 12A12.014 12.014 0 0 0 12 0zm6.927 8.2-6.845 9.289a1.01 1.01 0 0 1-1.43.188l-4.888-3.908a1 1 0 1 1 1.25-1.562l4.076 3.261 6.227-8.451a1.001 1.001 0 1 1 1.61 1.183z"
          fill="#48BE4D"
        />
      </g>
      <defs>
        <clipPath id="zpco5hceaa">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
