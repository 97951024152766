import React from 'react';

import {IconProps} from './iconTypes';

export function InformationIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
          <g className={props.animationClass} stroke="#919aaf" style={{fill: 'none'}} strokeWidth="1.2">
            <g>
              <path d="M10.6 12.2h-.533C9.477 12.2 9 11.722 9 11.133V8.467c0-.295-.239-.534-.533-.534h-.534m.8-2.666c-.147 0-.266.12-.266.266 0 .148.12.267.266.267.148 0 .267-.12.267-.267 0-.147-.12-.266-.267-.266h0M9 17c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8z" transform="translate(-394 -154) translate(394 154)"/>
            </g>
          </g>
        </g>
      </svg>
    );
}
