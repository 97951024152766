import classNames from 'classnames';
import * as React from 'react';

import styles from './arrange.pcss';
import {Layout} from './layouts';

type Props = {
    layout?: Layout
    className?: string
    [K: string]: any
}

export const Vertically = (props: Props) =>
    <div className={classNames(styles.rows, props.className, styles[`layout-${props.layout}`])}>
        {props.children}
    </div>