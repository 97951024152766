import cx from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import { DialerArrowIcon } from '../../icons/DialerArrowIcon';
import { IconButton } from '../IconButton/IconButton';

import styles from './CollapsiblePanel.pcss';

export type CollapsiblePanelProps = {
    children: (isCollapsed: boolean) => React.ReactElement;
    isInitiallyCollapsed?: boolean;
    borderPrimary?: boolean;
}

export const CollapsiblePanel = ({
    children,
    isInitiallyCollapsed = true,
    borderPrimary = false
}: CollapsiblePanelProps) => {
    const [isCollapsed, setCollapsed] = useState(isInitiallyCollapsed);

    const toggleCollapsed = useCallback(() => {
        setCollapsed(!isCollapsed);
    }, [isCollapsed, setCollapsed]);

    return <div className={styles.collapsiblePanel}>
        <IconButton
            dataTest="collapse-button"
            className={cx(
                styles.iconButton, 
                { 
                    [styles.inverted]: isCollapsed,
                    [styles.borderPrimary]: borderPrimary
                }
                )}
            Icon={DialerArrowIcon}
            onClick={toggleCollapsed}
        />
        {children(isCollapsed)}
    </div>
}