import React, {useEffect, useState} from 'react';

import {useCurrentBot} from '../../../utils/CurrentBot';
import {HttpClient} from '../../../utils/HttpClient';
import {ApiCredentials} from '../types';

import {ApiTabContentComponent} from './ApiTabContentComponent';


export const ApiTabContentContainer: React.FC = () => {
  const currentBot = useCurrentBot();
  const [apiCredentials, setApiCredentials] = useState<ApiCredentials>({username: '', password: ''});

  useEffect(() => {
    HttpClient.get({path: `/organizations/${currentBot.organization}/credentials`}).then((result) => {
      setApiCredentials(result.data || {})
    })
  }, [currentBot])

  return <ApiTabContentComponent apiCredentials={apiCredentials} botId={currentBot.id}/>
}
