import React from 'react';

import { IconProps } from './iconTypes';

export function CallStepIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-47 -131) translate(31 113) translate(16 18)">
          <path d="M0 0H24V24H0z" />
          <path className={props.animationClass} fillRule="nonzero" d="M3.388 1.67l-.717.717C.84 4.219.478 7.044 1.762 9.27l.144.235C5.259 14.468 9.53 18.74 14.49 22.09l.043.027c2.237 1.424 5.189 1.1 7.08-.79l.717-.717c.893-.894.893-2.343 0-3.238l-3.02-3.017c-.837-.836-2.17-.896-3.076-.148l-.143.128c-.231.231-.518.35-.816.35-.299 0-.585-.119-.797-.33L9.646 9.523c-.411-.412-.44-1.068-.073-1.513l.092-.101c.41-.409.652-.991.652-1.598 0-.608-.242-1.19-.671-1.62l-3.019-3.02C5.732.776 4.283.776 3.388 1.67zm2.417.823l3.018 3.021c.212.212.33.498.33.797 0 .256-.086.503-.244.7l-.216.238c-.765.927-.705 2.26.13 3.096l4.833 4.832c.429.43 1.011.67 1.619.67.546 0 1.073-.195 1.486-.547l.234-.214c.424-.35 1.08-.32 1.493.091l3.02 3.018c.439.44.439 1.153 0 1.593l-.717.716c-1.452 1.452-3.69 1.744-5.458.737l-.225-.135c-4.611-3.118-8.634-7.08-11.84-11.672l-.402-.585c-1.136-1.786-.878-4.134.628-5.64l.717-.717c.44-.44 1.153-.44 1.594 0z" />
        </g>
      </g>
    </svg>

  );
}
