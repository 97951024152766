import _ from 'lodash';
import * as React from 'react';
import {CSSProperties, useCallback, useMemo, useRef} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory, useLocation} from 'react-router';
import {Column, default as ReactTable} from 'react-table';

import {Button} from '../components/Button/Button';
import {Loader} from '../components/Loader';
import {Scrollbar} from '../components/Scrollbar/Scrollbar';
import {toggleConversationOrdering} from '../conversations/Conversations';
import {convertToDateTimeString} from '../language/botLanguage';
import {SurveyDefinitionVariable} from '../surveyCreator/model';

import {parseContactsOrdering} from './Contacts';
import {Contact} from './model';

import styles from './ContactList.pcss';

interface Props {
    readonly hasMore?: boolean;
    readonly loading?: boolean;
    readonly contacts: Contact[];
    readonly contactsCount?: number;
    readonly variables?: SurveyDefinitionVariable[];
    selectedContact: Contact;
    timezone?: string;

    fetchNextPage(lastItemId: string): Promise<void>;

    onContactSelected(contactId: string): void;
}

function Header(props: { labelKey: string }) {
    return (
        <div className={styles.header}>
            <FormattedMessage id={props.labelKey} />
        </div>
    );
}

const dateCell = (dataTest: string, format: string, timezone: string) => ({ value }: { value: number }) => (
    <div className={styles.cell}>
        <span data-sensitive="true" data-test={`contact-item-${dataTest}`}>{convertToDateTimeString(format, timezone, value)}</span>
    </div>
);

const statusCell = (value: string) => {
    return (
        <div className={styles.cell}>
            <span data-test={`contact-item-status`} className={styles[value]}>
                <FormattedMessage id={`contacts.status.${value}`} />
            </span>
        </div>
    );
};

const basicColumns = (timestampFormat: string, timezone= 'UTC') => [
    {
        Header: <Header labelKey="contacts.table.phoneNumber" />,
        accessor: 'phoneNumber',
        Cell: ({ value }) => (
            <div className={styles.cell}>
                <span data-sensitive="true" data-test="contact-item-phone-number">{value}</span>
            </div>
        ),
        width: 120
    },
    {
        Header: <Header labelKey="contacts.table.status" />,
        accessor: 'status',
        Cell: ({ value }) => statusCell(value),
        width: 175
    },
    {
        Header: <Header labelKey="contacts.table.importDate" />,
        accessor: 'importDate',
        Cell: dateCell('import-date', timestampFormat, timezone),
        width: 160
    },
    {
        Header: <Header labelKey="contacts.table.lastInteraction" />,
        accessor: 'lastInteraction',
        Cell: dateCell('last-interaction', timestampFormat, timezone),
        width: 170,
        sortable: false
    },
    {
        Header: <Header labelKey="contacts.table.currentInteraction" />,
        accessor: 'currentInteraction',
        Cell: dateCell('current-interaction', timestampFormat, timezone),
        width: 170,
        sortable: false
    },
    {
        Header: <Header labelKey="contacts.table.followUpInteraction" />,
        accessor: 'followUpInteraction',
        Cell: dateCell('follow-up-interaction', timestampFormat, timezone),
        width: 170,
        sortable: false
    },
    {
        Header: <Header labelKey="contacts.table.interactionsPerformed" />,
        accessor: 'interactionsPerformed',
        Cell: ({ value }) => (
            <div className={styles.cell} data-test={'contact-item-interactions-performed'}>
                {value}
            </div>
        ),
        width: 170,
        sortable: false
    },
];

export const ContactList = (props: Props) => {
    const intl = useIntl();
    const history = useHistory();
    const location = useLocation();
    const scrollTopPositionRef = useRef(0);
    const horizontalScrollbar = useRef<Scrollbars>();
    const verticalScrollbar = useRef<Scrollbars>();
    const verticalScrollbarContainer = useRef<HTMLDivElement>();
    const loadMoreContainer = useRef<HTMLDivElement>();
    const ordering = useMemo(() => parseContactsOrdering(location.search), [location.search]);
    const { contacts } = props;

    const toggleOrder = useCallback(
        property => {
            toggleConversationOrdering(history, location, ordering, property);
        },
        [history, location, ordering]
    );

    const skipNestedVariables = variables => _.omitBy(variables, (value, ) => typeof value === 'object');
    const variableColumns = _(contacts)
        .flatMap('variables')
        .flatMap(skipNestedVariables)
        .flatMap(Object.keys)
        .uniq()
        .map(variableId => {
            const contactVariables = props.variables.filter(v => v.origin === 'contact')
            const variableName = contactVariables.find(v => v.id === variableId)?.name ?? variableId;

            return ({
                id: `variables.${variableId}`,
                Header: <div
                    data-test={`header-${variableName}`}
                    className={styles.header}
                >
                    {variableName}
                </div>,
                Cell: (value: { original: Contact }) => (
                    <div className={styles.cell}>
                        <span data-sensitive="true" data-test={`contact-item-${variableId}`}>{value.original.variables[variableId]}</span>
                    </div>
                )
            });
        })
        .value();

    const columns: Column[] = [
        ...basicColumns(intl.messages['timestamp.format'] as string, props.timezone),
        ...variableColumns
    ];

    const selectedIndex = useMemo(() => {
        return contacts.findIndex(contact => props.selectedContact && contact.id === props.selectedContact.id);
    }, [contacts, props.selectedContact]);


    const style: CSSProperties = { overflowY: 'hidden' }
    const onScrollVertical = (scrollTop) => {
        if (scrollTop !== scrollTopPositionRef.current) {
            scrollTopPositionRef.current = scrollTop;
        }
    };
    const onScroll = useCallback((scrollTop, scrollLeft) => {
        const verticalTrack = getVerticalTrack(verticalScrollbar.current);
        if (verticalTrack) {
            verticalTrack.style.left = `${scrollLeft + horizontalScrollbar.current.getClientWidth() - 15}px`;
        }
        if (verticalScrollbarContainer.current && horizontalScrollbar.current) {
            verticalScrollbarContainer.current.style.height = getInnerWindowHeight(horizontalScrollbar.current);
        }
        if (loadMoreContainer.current) {
            loadMoreContainer.current.style.marginLeft = `${scrollLeft}px`;
            loadMoreContainer.current.style.width = `${horizontalScrollbar.current.getClientWidth()}px`;
        }
    }, [verticalScrollbarContainer.current, loadMoreContainer.current, verticalScrollbar.current]);

    const verticalScrollRef = (ref) => {
        verticalScrollbar.current = ref;
        if (verticalScrollbar.current) {
            verticalScrollbar.current.scrollTop(scrollTopPositionRef.current);
        }
    };
    const rowProps = (state, rowInfo) => ({
        className: selectedIndex === rowInfo.index ? styles.selected : null,
        onClick: (e, handleOriginal) => {
            props.onContactSelected(contacts[rowInfo.index].id);
            if (handleOriginal) {
                handleOriginal();
            }
        }
    });
    const loadMore = () => props.fetchNextPage(contacts.length ? contacts[contacts.length - 1].id : undefined);

    return (
        <div data-test="contact-list" className={styles.contactsList}>
            <Scrollbar ref={horizontalScrollbar} style={style} onScroll={onScroll}>
                <ReactTable
                    data={contacts}
                    columns={[...columns]}
                    minRows={0}
                    pageSize={100}
                    manual={true}
                    defaultSorted={[{ id: ordering.property, desc: ordering.direction === 'DESC' }]}
                    showPagination={false}
                    noDataText=""
                    onSortedChange={([newSorted]) => toggleOrder(newSorted.id)}
                    getTrGroupProps={rowProps}
                    // TODO magic numbers
                    TbodyComponent={state => (
                        <div
                            ref={verticalScrollbarContainer}
                            style={{ height: getInnerWindowHeight(horizontalScrollbar.current), width: '100%', ...state.style }}
                        >
                            <Scrollbar ref={verticalScrollRef} verticalTrackClassName={styles.verticalTrack} hideHorizontal={true}
                                onScroll={onScrollVertical}>
                                {state.children}
                                {(props.hasMore || props.loading) && (
                                    <div ref={loadMoreContainer} className={styles.loadMoreContainer}>
                                        {props.hasMore && !props.loading && (
                                            <Button onClick={loadMore} dataTest="load-more-button">
                                                <FormattedMessage id="callsList.loadMore" />
                                            </Button>
                                        )}
                                        {props.loading && <Loader dataTest="contacts-list-loader"/>}
                                    </div>
                                )}
                            </Scrollbar>
                        </div>
                    )}
                    resizable={true}
                />
            </Scrollbar>
        </div>
    );
};

function getInnerWindowHeight(horizontalScrollbar?: Scrollbars) {
    return `${horizontalScrollbar ? horizontalScrollbar.getClientHeight() - 50 : 0}px`;
}

function getVerticalTrack(scrollbar: any): HTMLDivElement {
    return scrollbar && scrollbar.trackVertical ? scrollbar.trackVertical : undefined;
}
