import React from 'react';

import { HelpPopup } from '../../views/Login/components/HelpPopup';
import { Button } from '../Button/Button';
import { Column } from '../Column/Column';
import { InputWithLabel } from '../InputWithLabel/InputWithLabel';
import { Row } from '../Row/Row';

import { ChangePasswordModalProps } from './ChangePasswordModal';
import styles from './ChangePasswordModal.pcss';

export const ChangePasswordForm: React.FC<ChangePasswordModalProps> = ({
  fieldsProps,
  onFormSubmit,
  hasErrors,
  popupMessageId,
  closeModal
}) => {
  const Popup = () => <HelpPopup messageId={popupMessageId} />
  return <form
    data-test="change-password-form"
    className={styles.form}
    onSubmit={onFormSubmit}
  >
    <Column>
      <InputWithLabel
        className={styles.input}
        type="password"
        {...fieldsProps.oldPassword} />
      <InputWithLabel
        className={styles.input}
        type="password"
        Icon={Popup}
        {...fieldsProps.newPassword} />
      <InputWithLabel
        className={styles.input}
        type="password"
        {...fieldsProps.repeatNewPassword} />
    </Column>
    <Row className={styles.buttonsRow}>
      <Button
        dataTest="cancel-button"
        translateText="header.changePasswordModal.cancel"
        buttonType="normal" 
        onClick={closeModal} />
      <Button
        dataTest="change-password-button"
        type="submit"
        translateText="header.changePasswordModal.submit"
        disabled={!(Boolean(fieldsProps.oldPassword.value) && Boolean(fieldsProps.newPassword.value) && Boolean(fieldsProps.repeatNewPassword.value)) ||
          hasErrors} />
    </Row>
  </form>;
};