import * as React from 'react';

import {InputWithLabel} from '../../components/InputWithLabel/InputWithLabel';

import styles from './NameInput.pcss';

type Props = { 
    name: string, 
    onChange: (name: string) => void, 
    dataTest?: string, 
    error?: string 
};

export const NameInput = ({name, onChange, dataTest = 'details-name', error}: Props) => {
    return (
        <div className={styles.container}>
            <InputWithLabel 
                value={name} 
                dataTest={dataTest} 
                onChange={onChange} 
                labelId={'step.name'} 
                borderEnabled 
                error={error}
                errorClass={styles.errorMessage}
            />
        </div>
    );
};
