import {Modal} from '../../components/Modal/Modal';
import {ModalContent} from '../../components/ModalContent/ModalContent';
import {FormattedMessage} from 'react-intl';
import {Button} from '../../components/Button/Button';
import { PhoneNumberUtil } from 'google-libphonenumber';

import * as React from 'react';
import {useState} from 'react';
import {PhoneInput} from 'react-international-phone';
import 'react-international-phone/style.css';
import styles from './TestSMSModal.pcss';
import {ElementWithLabel} from '../../components/ElementWithLabel/ElementWithLabel';
import {getBrowserLanguage} from '../../i18n/language';


interface TestSMSModalProps {
    onClose: () => void;
    onSend: (phoneNumber: string) => Promise<void>;
}

function isPhoneValid (phone: string) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
        return false;
    }
};
export const TestSMSModal: React.FC<TestSMSModalProps> = ({onClose, onSend }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isSending, setIsSending] = useState(false);
    const isValidPhone = isPhoneValid(phoneNumber);
    const browserLanguage = getBrowserLanguage();
    const defaultCountry = browserLanguage.includes('en') ? 'us' : 'pl';

    return (
        <Modal position="center" dataTest="test-sms-popup" className={styles.modalContainer} modalSize="medium">
            <ModalContent
                headerContent={<FormattedMessage id="organization.sms.testSms.title"/>}
                footerContent={
                    <>
                        <Button
                            buttonType="normal"
                            dataTest="test-configuration-button-cancel"
                            translateText="organization.sms.testSms.cancel"
                            className={styles.cancelButton}
                            onClick={onClose}
                        />
                        <Button
                            buttonType={isSending ? 'loading' : 'primary'}
                            dataTest="test-configuration-button-send"
                            translateText={isSending ? '' : 'organization.sms.testSms.send'}
                            disabled={!isValidPhone}
                            onClick={async () => {
                                setIsSending(true);
                                await onSend(phoneNumber);
                            }}
                        />
                    </>
                }
            >
                <ElementWithLabel className={styles.phoneInputLabel} labelId="organization.sms.testSms.phoneNumber" disabled={true} >
                    <PhoneInput
                        inputClassName={styles.phoneInput}
                        inputProps={{'data-test': 'test-sms-phone-input'} as any}
                        autoFocus={true}
                        hideDropdown={true}
                        defaultCountry={defaultCountry}
                        value={phoneNumber}
                        onChange={(phone) => setPhoneNumber(phone)}
                    />
                </ElementWithLabel>
            </ModalContent>
        </Modal>
    );
}