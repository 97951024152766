import classNames from 'classnames';
import React from 'react';
import styles from './ScenarioStepsDashboardTile.pcss';

type Props = {
  variant?: 'pinned' | 'editingPinned' | 'editingUnpinned';
  title: string;
  value: number;
  actions?: React.ReactNode;
};

export function ScenarioStepsDashboardTile({
  variant = 'pinned',
  title,
  value,
  actions,
}: Props) {
  return (
    <div
      className={classNames(styles.container, styles[variant])}
      data-test="scenario-steps-dashboard-step"
    >
      <span
        className={styles.title}
        data-test="scenario-steps-dashboard-step-title"
      >
        {title}
      </span>
      <div className={styles.bottomWrapper}>
        <span
          className={styles.value}
          data-test="scenario-steps-dashboard-step-value"
        >
          {value}
        </span>
        <div className={styles.actionContainer}>{actions}</div>
      </div>
    </div>
  );
}
