import React, { ButtonHTMLAttributes, useState } from 'react';

import { DownloadIcon } from '../../icons/DownloadIcon';
import { Button, ButtonType } from '../Button/Button';
import { MarkdownContent } from '../MarkdownContent/MarkdownContent';
import { showErrorToast } from '../Toast/Toast';

type Props = {
  dataTest?: string;
  textId: string;
  buttonType?: ButtonType;
  onClick: () => any;
};

export function DownloadButton({
  dataTest,
  textId,
  buttonType,
  onClick,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & Props) {
  const [isPending, setIsPending] = useState(false);

  const asyncOnClickHandler = async () => {
    setIsPending(true);
    try {
      await onClick();
    } catch (err) {
      showErrorToast(
        <MarkdownContent contentKey="downloadButton.errorToast" />
      );
    }
    setIsPending(false);
  };

  const loadingButtonType = buttonType === 'link' ? 'linkLoading' : 'loading';
  const resolvedButtonType = isPending ? loadingButtonType : buttonType;

  return (
    <Button
      dataTest={dataTest}
      buttonType={resolvedButtonType}
      Icon={resolvedButtonType !== loadingButtonType && DownloadIcon}
      translateText={resolvedButtonType !== loadingButtonType && textId}
      onClick={asyncOnClickHandler}
      {...props}
    />
  );
};
