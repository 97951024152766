import React, {useState} from 'react';
import TooltipTrigger, { TooltipTriggerProps } from 'react-popper-tooltip';

import styles from './Tooltip.pcss';

type TooltipComponentProps = {
    trigger?: TooltipTriggerProps['trigger'];
    tooltipPlacement?: TooltipTriggerProps['placement'];
    hideArrow?: boolean;
    delayShow?: number;
    delayHide?: number;
    tooltipText: string;
    className?: string;
    hidden?: boolean;
}

export const TooltipComponent: React.FC<TooltipComponentProps> = ({
    children,
    tooltipText,
    hideArrow = true,
    delayShow= 150,
    delayHide= 0,
    tooltipPlacement= 'top',
    trigger= 'hover',
    className,
    hidden,
    ...props
}) => {
    const [isCursorOnTooltip, toggleCursorOnTooltip] = useState(false);
    const [isShown, toggleShown] = useState(false);
    
    const tooltip = ({arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement}) => (
        <div {...getTooltipProps({ref: tooltipRef, className: styles.tooltipContainer})}
             onMouseLeave={() => toggleCursorOnTooltip(false)}
             onMouseEnter={(e) => {
                 toggleCursorOnTooltip(true);
             }}
        >
            {!hideArrow && (
                <div {...getArrowProps({ref: arrowRef, className: styles.tooltipArrow, 'data-placement': placement})}/>
            )}
            {tooltipText}
        </div>
    );

    if (hidden) {
        return <>{children}</>;
    }

    return (
        <TooltipTrigger
            {...props}
            placement={tooltipPlacement}
            trigger={trigger}
            delayShow={delayShow}
            delayHide={delayHide}
            tooltip={tooltip}
            tooltipShown={isShown && !isCursorOnTooltip}
            onVisibilityChange={(value) => {
                toggleShown(value);
                if (value) {
                    toggleCursorOnTooltip(false);
                }
            }}
        >
            {({ getTriggerProps, triggerRef }) => (
                <div {...getTriggerProps({ ref: triggerRef })} className={className}>
                    {children}
                </div>
            )}
        </TooltipTrigger>
    );
};
