import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {
  entitiesSelectors as entitiesSelector,
  pendingEntitySelector as pendingEntitySelector
} from '../../../../selectors/entitiesSelectors';
import {useCurrentBot} from '../../../../utils/CurrentBot';
import {HttpClient} from '../../../../utils/HttpClient';
import {fetchEntities, updateEntities, updatePendingEntity} from '../../../store/entities/actions';

export const usePendingEntity = () => {
  const dispatch = useDispatch();
  const currentBot = useCurrentBot();
  const pendingEntity = useSelector(pendingEntitySelector);
  const entities = useSelector(entitiesSelector);
  const location = useLocation();

  useEffect(() => {
    cancelPendingEntity();
  }, [location.pathname]);

  const postEntity = async (entityName: string) => {
    await HttpClient.post({path: `/bots/${currentBot.id}/entities`, body: { entityName }});
    dispatch(fetchEntities.request({id: currentBot.id}));
  }

  const createPendingEntity = useCallback(() => dispatch(updatePendingEntity('entity')), []);

  const confirmPendingEntity = useCallback((entity: string) => {
    cancelPendingEntity();
    dispatch(updateEntities([
      ...(entities.data ?? []),
      entity
    ]));
    postEntity(entity);
  }, [entities]);

  const cancelPendingEntity = useCallback(() => dispatch(updatePendingEntity(undefined)), []);

  return {
    createPendingEntity,
    confirmPendingEntity,
    cancelPendingEntity,
    pendingEntity,
  };
}