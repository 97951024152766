import cx from 'classnames'
import React from 'react'

import { StandardInitialProps } from '../types'

import styles from './Row.pcss'

export type RowProps = StandardInitialProps & Partial<Record<'verticallyCentered', boolean>>

export const Row: React.FC<RowProps> = ({
  children,
  className,
  dataTest,
  verticallyCentered
}) => <div
  data-test={dataTest}
  className={cx(
  styles.row,
  { [styles.verticallyCentered]: verticallyCentered },
  className
)}>{children}</div>