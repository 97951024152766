import React from 'react';
import cx from 'classnames';

import { IconProps } from './iconTypes';
import styles from './icons.pcss';

export const PlayCircleIcon: React.FC<IconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <circle cx="16" cy="16" r="16" className={cx(props.animationClass, styles.iconFillColor)} />
      <path fill="#FFF" d="M11.223 10.486v11.522a.988.988 0 0 0 1.455.871l10.754-5.761a.988.988 0 0 0 0-1.742L12.678 9.615a.988.988 0 0 0-1.455.87z" />
    </g>
  </svg>
);
