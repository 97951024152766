import React from 'react';

import {IconProps} from './iconTypes';

export const CancelSmallIcon = (props: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="none" fillRule="evenodd">
      <circle className={props.animationClass} cx="9" cy="9" r="9" fill="#919AAF" />
      <g stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25">
        <path d="M5.686 12.314l6.629-6.629M5.686 5.686l6.629 6.629" />
      </g>
    </g>
  </svg>

)
