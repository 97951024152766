import _ from 'lodash';
import {useSelector} from 'react-redux';

import {RootState} from '../reducers/reducers';
import {getRolesFromUser} from '../utils/CurrentUser';

export function getNamesDictionary(state: RootState) {
    return state.namesDictionary.data;
}

export function getBots(state: RootState) {
    return state.bots.bots;
}

export const isFetched = (s: RootState) => s.bots.fetched;

export function useBots() {
    const bots = useSelector(getBots);
    return _.cloneDeep(bots).sort((bot1, bot2) => {
        if (bot1.organization === bot2.organization) {
            return bot1.name.toLowerCase() < bot2.name.toLowerCase() ? -1 : 1;
        }
        return bot1.organization < bot2.organization ? -1 : 1;
    });
}

export function currentUserRoles(state: RootState) {
    return state.currentUser && state.currentUser.user ? getRolesFromUser(state.currentUser.user) : [];
}

export function currentUser(state: RootState) {
    return state?.currentUser?.user;
}
