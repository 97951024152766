import React from 'react';

import {IconProps} from './iconTypes';

export function DragAnswerIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15">
        <g fill="#919AAF">
          <circle cx="1.5" cy="1.5" r="1.5"/>
          <circle cx="6.5" cy="1.5" r="1.5"/>
          <circle cx="1.5" cy="7.5" r="1.5"/>
          <circle cx="6.5" cy="7.5" r="1.5"/>
          <circle cx="1.5" cy="13.5" r="1.5"/>
          <circle cx="6.5" cy="13.5" r="1.5"/>
        </g>
      </svg>
    );
}
