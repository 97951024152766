import React from 'react';

import {IconProps} from './iconTypes';

export function GoToStepIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g transform="translate(-381 -610) translate(365 598) translate(16 12)">
                <path d="M0 0H24V24H0z"/>
                <path className={props.animationClass} d="M21.854 5.402l-1.978-1.978c-.234-.234-.234-.614 0-.848.234-.235.614-.235.848 0l3 3c.118.117.176.271.176.425 0 .18-.078.34-.203.45l-2.973 2.973c-.234.234-.614.234-.848 0-.234-.234-.234-.614 0-.848l1.973-1.974H18.05c-1.345 0-2.564.896-3.565 1.93-1.26 1.3-2.17 2.855-2.506 3.47.336.614 1.246 2.17 2.506 3.47 1 1.034 2.22 1.93 3.565 1.93h3.803l-1.977-1.978c-.234-.234-.234-.614 0-.848.234-.235.614-.235.848 0l3 3c.118.117.176.27.176.425 0 .18-.078.34-.203.45l-2.973 2.973c-.234.234-.614.234-.848 0-.234-.234-.234-.614 0-.848l1.973-1.974H18.05c-1.655 0-3.196-1.024-4.427-2.295-1.334-1.377-2.305-3.018-2.684-3.707H.8c-.331 0-.6-.269-.6-.6 0-.331.269-.6.6-.6h10.141c.38-.691 1.35-2.329 2.682-3.704 1.231-1.27 2.772-2.294 4.427-2.294h3.803z"/>
              </g>
            </g>
          </g>
        </g>
      </svg>

    );
}
