import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export const SetNewPasswordConfirmationMessage = () => {
  return (
    <FormattedMessage
      id="auth.signIn.setNewPasswordConfirmation"
      values={{ linebreak: <br /> }}
    />
  );
};
