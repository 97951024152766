import cx from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import popupStyles from '../Popups.pcss';

import styles from './Popup.pcss';

export type PopupProps = {
  messageId: string;
}

export const Popup: React.FC<PopupProps> = ({ messageId }) => <div className={cx(popupStyles.popup, styles.popup)}>
  <FormattedMessage id={messageId} />
</div>