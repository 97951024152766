import {useIntl} from 'react-intl';
import {useCurrentBot} from '../../../../utils/CurrentBot';
import {nerEntities} from '../ner-entities';
import {useEntities} from './useEntities';

export const useEntitiesWithSystemAndNerEntities = () => {
  const intl = useIntl();
  const currentBot = useCurrentBot();

  let nerEntitiesForLang = [];

  if (currentBot.voiceConfig.languageCode === 'pl-PL') {
    nerEntitiesForLang = nerEntities.pl
  } else if (/^en/.test(currentBot.voiceConfig.languageCode)) {
    nerEntitiesForLang = nerEntities.en
  }

  const { isLoading, isReady, entities } = useEntities();

  const systemAndNerEntitiesKeys = ['number', 'datetime', ...nerEntitiesForLang]

  const systemAndNerEntities = systemAndNerEntitiesKeys.map(entity => {
    const name = intl.messages[`survey-creator.intentDetails.entities.${entity}`] || entity
    return {
      key: entity,
      name,
      entityKey: entity,
    }
  });

  return {
    isLoading,
    isReady,
    entities: [
      ...(entities ? [...entities].map(entity => ({
        key: entity, 
        name: entity,
        entityKey: entity
      })) : []),
      ...systemAndNerEntities
    ],
    systemAndNerEntitiesKeys
  };
};
