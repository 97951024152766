import React, { useState } from 'react';
import { TranscriptItemDataCollection } from '../models/Transcript';
import styles from './StepLine.pcss';
import { DataCollectionStepIcon } from '../../icons';
import { DataCollectionLineDetails } from './DataCollectionStepLineDetails';
import { useIntl } from 'react-intl';

type Props = {
  stepLink: string;
  line: TranscriptItemDataCollection;
  isStepLinkExpired: boolean | undefined;
};

export function DataCollectionStepLine({ line, stepLink, isStepLinkExpired }: Props) {
  const intl = useIntl();

  const [isModalOpened, setIsModalOpened] = useState(false);

  const title = `${intl.messages['conversations.dataCollection.report']} ${line.stepName}`;

  return (
    <>
      <div className={styles.wrapper}>
        <button
          data-test="data-collection-step-line"
          className={styles.button}
          onClick={() => setIsModalOpened(true)}
        >
          <DataCollectionStepIcon animationClass={styles.icon} />
          <span className={styles.text}>{title}</span>
        </button>
      </div>
      {isModalOpened && (
        <DataCollectionLineDetails
          line={line}
          stepLink={stepLink}
          title={title}
          onClose={() => setIsModalOpened(false)}
          isStepLinkExpired={isStepLinkExpired}
        />
      )}
    </>
  );
}
