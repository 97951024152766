import * as React from 'react';

import {Label} from '../../components/Label';
import {NumberInputWithStepper} from '../../components/NumberInputWithStepper/NumberInputWithStepper';
import {Toggle} from '../../components/Toggle/Toggle';
import {Schedule} from '../schedule-model';

import styles from './MaxConcurrentCallsSettings.pcss'

type Props = {
    schedule: Schedule, setSchedule: (schedule: Schedule) => void
};

export const MaxConcurrentCallsSettings = ({schedule, setSchedule}: Props) => {

    const onToggled = (value: boolean) => setSchedule({
        ...schedule,
        enableConcurrentCallsLimit: value,
        maxConcurrentCalls: value ? 1 : 0
    })

    const onMaxConcurrentCallsChanged = (value: number) => setSchedule({
        ...schedule,
        maxConcurrentCalls: value
    })

    return (
        <div data-test={'max-concurrent-calls'} className={styles.container}>
            <div className={styles.toggleContainer}>
                <Toggle on={schedule && schedule.enableConcurrentCallsLimit}
                        onToggle={() => onToggled(!schedule.enableConcurrentCallsLimit)}
                        data-test={'max-concurrent-calls-toggle'}
                />
                <Label labelId={'scheduler.maxConcurrentCallsSettings.enabled'}/>
            </div>
            {schedule?.enableConcurrentCallsLimit && <div className={styles.toggleContainerR}>
              <NumberInputWithStepper data-test={'max-concurrent-calls-stepper'}
                                      step={1}
                                      minValue={1}
                                      value={(schedule && schedule.maxConcurrentCalls) || 0}
                                      onChange={value => onMaxConcurrentCallsChanged(value)}
              />
            </div>}
        </div>
    )
};
