import React, { useCallback } from 'react';

import { EntityCollapsiblePanel } from '../../../../components/EntityCollapsiblePanel/EntityCollapsiblePanel';

import { AnswerEntityValue } from './AnswerEntityValue';

import { AnswerValue } from '../../../types';
import { AnswerEntityProps } from './types';

const AnswerEntity: React.FC<AnswerEntityProps> = ({
    answer,
    onChange,
    onRemove,
}) => {
    const handleChange = useCallback((changedValue: AnswerValue, index) => {
        onChange({
            ...answer,
            values: answer.values.map((v, i) => {
                // need to rely on index, since the key can be changed
                if (index === i) {
                    return changedValue;
                }
                return v;
            }),
        });
    }, [answer, onChange]);

    const handleRemove = useCallback(() => {
        if (typeof onRemove === 'function') {
            onRemove(answer);
        }
    }, [answer, onChange]);

    return <EntityCollapsiblePanel
        entity={answer.id}
        isInitiallyCollapsed={true}
        onRemoveClick={handleRemove}
    >
        <div>
            {answer.values.map((value, index) => <AnswerEntityValue
                entityId={answer.id}
                key={value.key}
                value={value}
                onChange={(changedValue) => handleChange(changedValue, index)}
            />)}
        </div>
    </EntityCollapsiblePanel>;
};

export default AnswerEntity;
