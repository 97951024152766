import * as React from 'react';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router';
import slugify from 'slugify';

import {getBots} from '../selectors';
import {Bot} from '../surveyCreator/model';

export interface StateBotProps {
    currentBot: Bot;
}

export type BotRouteMatch = {
    botId?: string;
    orgId?: string;
}

export type CallbackTypeId = 'callNotAnswered' | 'callFinished' | 'voiceMailDetected'

export const CurrentBot = React.createContext({
    getCurrentBot: useCurrentBot,
});

export function useCurrentBot(): Bot {
    const match = useRouteMatch<BotRouteMatch>();
    const botName = match.params.botId;
    const orgName = match.params.orgId;
    const bots = useSelector(getBots) || [];
    const currentBot = useMemo(() => (botName ? bots.find(bot => getBotUrlId(bot) === `${orgName}/${botName}`) : undefined), [
        bots,
        botName
    ]);
    return currentBot;
}

export const withCurrentBot = <CustomProps extends Object>(WrappedComponent: React.ComponentType<CustomProps>) => (
    props: CustomProps
) => {
    const currentBot = useCurrentBot();
    return <WrappedComponent {...props} currentBot={currentBot}/>;
};

export function getBotUrlId(bot?: Bot) {
    return bot ? `${slugify(bot.organization, { strict: true })}/${slugify(bot.name, { strict: true })}` : '';
}

export function getSlugifiedBotParams(bot: Bot) {
    return {
        botId: slugify(bot.name, { strict: true }),
        orgId: slugify(bot.organization, { strict: true })
    };
}

