import React from 'react';

import {IconProps} from './iconTypes';

export function DictationStepIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g transform="translate(-381 -218) translate(365 206) translate(16 12)">
                <path d="M0 0H24V24H0z"/>
                <path className={props.animationClass} fillRule="nonzero" d="M19.5 7.65c.298 0 .546.218.592.503l.008.097v3c0 3.568-2.83 6.474-6.368 6.596l-.232.004-.901-.001v4.8L15 22.65c.331 0 .6.269.6.6 0 .298-.218.546-.503.592L15 23.85H9c-.331 0-.6-.269-.6-.6 0-.298.218-.546.503-.592L9 22.65l2.399-.001v-4.8l-.899.001c-3.568 0-6.474-2.83-6.596-6.368L3.9 11.25v-3c0-.331.269-.6.6-.6.298 0 .546.218.592.503l.008.097v3c0 2.91 2.301 5.282 5.183 5.396l.217.004h3c2.91 0 5.282-2.301 5.396-5.183l.004-.217v-3c0-.331.269-.6.6-.6zm-9-7.5l3.037.001c2.088.128 3.68 1.92 3.563 3.974l-.001 6.716c.118 2.089-1.474 3.88-3.599 4.009l-3.037-.001c-2.088-.128-3.68-1.92-3.563-3.974V5.26 5.24l.001-1.08C6.786 2.135 8.277.39 10.302.167L10.5.15zm.037 1.199c-1.43.087-2.519 1.313-2.437 2.776l-.001.524 2.401.001c.331 0 .6.269.6.6 0 .298-.218.546-.503.592l-.097.008-2.401-.001v1.8l2.401.001c.331 0 .6.269.6.6 0 .298-.218.546-.503.592l-.097.008-2.401-.001v2.06c-.078 1.374.927 2.56 2.242 2.726l.159.015 2.963.001c1.43-.087 2.519-1.313 2.437-2.776l-.001-2.026-2.399.001c-.331 0-.6-.269-.6-.6 0-.298.218-.546.503-.592l.097-.008 2.4-.001v-1.8l-2.4.001c-.331 0-.6-.269-.6-.6 0-.298.218-.546.503-.592l.097-.008 2.4-.001.001-.558c.078-1.374-.927-2.56-2.274-2.727l-.153-.015z"/>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
}
