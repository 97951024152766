import React from 'react';
import cx from 'classnames';

import styles from './LegacySynonymComponent.pcss';
import { CrossIcon } from '../../../icons';
import { Synonym } from '../../../surveyCreator/model';

interface Props {
  synonym: Synonym;
  onRemove?: React.MouseEventHandler;
  onClick?: React.MouseEventHandler;
  mode: 'editable' | 'readonly';
  chipsItemClassName?: string;
}

const EditableChip: React.FC<Props> = ({
                                          synonym,
                                          children,
                                          onRemove,
                                       }) => {
  return <div
    data-test={'chips-list-element'}
    className={cx(styles.chip, styles.editable)}
    onMouseDown={(e) => e.stopPropagation()}
  >
    {children}
    <div className={styles.jsonData}>
      {JSON.stringify(synonym)}
    </div>
    <button
      data-test="delete-synonym"
      className={styles.removeButton}
      onClick={onRemove}
    >
      <CrossIcon />
    </button>
  </div>;
}

const ReadOnlyChip: React.FC<Props> = ({
                                         synonym,
                                         children,
                                         onClick,
                                         chipsItemClassName,
                                       }) => {
  return onClick
    ? <button
      className={cx(styles.chip, styles.readOnly, chipsItemClassName)}
      data-test={'chips-list-element-readonly'}
      onClick={onClick}
    >
      {children}
      <div className={styles.jsonData}>
        {JSON.stringify(synonym)}
      </div>
    </button>
    : <div
      className={cx(styles.chip, styles.readOnly, chipsItemClassName)}
      data-test={'chips-list-element-readonly'}
    >
      {children}
      <div className={styles.jsonData}>
        {JSON.stringify(synonym)}
      </div>
    </div>
}

export const LegacySynonymComponent: React.FC<Props> = (props) => {
  const synonym = props.synonym;

  if (props.mode === 'editable') {
    return <span
      className={styles.chipContainer}
      data-synonym={synonym.verbatim}
      onDragStart={(e) => e.preventDefault()}
    >
      <EditableChip {...props}>{synonym.verbatim}</EditableChip>
    </span>
  } else {
    return <span
      className={styles.chipContainer}
      data-synonym={synonym.verbatim}
      onDragStart={(e) => e.preventDefault()}
    >
      <ReadOnlyChip{...props}>{synonym.verbatim}</ReadOnlyChip>
    </span>
  }
}