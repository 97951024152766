import classNames from 'classnames';
import * as React from 'react';

import styles from './Toggle.pcss';

type Props = { 
    on: boolean, 
    onToggle: () => void; 
    disabled?: boolean
    className?: string
};

export const Toggle = ({ on, onToggle, disabled, className }: Props) => {
    return (
        <label className={styles.toggle}>
            <input
                type={'checkbox'}
                data-test="toggle"
                data-toggle-enabled={on}
                className={classNames(styles.input, className)}
                checked={on || false}
                onChange={() => !disabled && onToggle()}
                disabled={disabled}
                tabIndex={0}
            />
            <span className={styles.label} />
        </label>
    );
};
