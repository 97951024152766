import _ from 'lodash';
import {
    EntityWordAnswerPointer,
    RawEntity,
    WordAnswerType,
} from '../../../../model';
import {
    AnswerItem,
    AnswerItemEntity,
    AnswerType,
    DisambiguationAnswer,
    DisambiguationAnswerEntity,
    DisambiguationItem,
} from '../../../types';

import { Model } from '../types';

import { EntitiesHttpClient } from '../../index';
import { BaseModelToViewMapper } from './';
import StepsHttpClient from '../../StepsHttpClient';

class ModelToViewMapper extends BaseModelToViewMapper {
    private entities: Map<string, RawEntity>;
    private entitiesClient: EntitiesHttpClient;
    private stepsClient: StepsHttpClient;
    private language: string;

    static getDisambiguationIdentifier(answers: DisambiguationAnswer[]) {
        const answersIdentifiers = answers.map((answer) => ModelToViewMapper.getDisambiguationAnswerIdentifier(answer)).sort();
        return _.uniq(answersIdentifiers).join(',');
    }

    static getDisambiguationAnswerIdentifier(answer: DisambiguationAnswer) {
        if (answer.type === AnswerType.DICTIONARY) {
            return answer.id;
        }
        return ModelToViewMapper.getEntityAnswerIdentifier(answer);
    }

    static getEntityAnswerIdentifier(answer: DisambiguationAnswerEntity) {
        return answer.id + '-' + answer.key;
    }

    constructor(botId: string, language: string) {
        super();
        this.entities = new Map();
        this.entitiesClient = new EntitiesHttpClient(botId);
        this.stepsClient = new StepsHttpClient();
        this.language = language;
    }

    public async getViewValue(model: Model) {
        await this.fetchMissingEntities(model);
        return {
            answersList: this.getAnswersList(model),
            disambiguation: await this.getDisambiguation(model),
            incomprehension: ModelToViewMapper.getIncomprehensionReaction(model),
            readOrder: ModelToViewMapper.getReadOrder(model),
        };
    }

    private async fetchMissingEntities(model: Model): Promise<RawEntity[]> {
        const missingEntities = model.dictionary.filter((item) => {
            return (
                item.type === WordAnswerType.ENTITY &&
                !this.entities.has(item.id)
            );
        });
        if (missingEntities.length === 0) {
            return;
        }
        const names = missingEntities.map(e => e.id);
        const entities = await this.entitiesClient.searchEntities(names);
        entities.forEach(entity => this.entities.set(entity.name, entity));
    }

    private getAnswersList(model: Model): AnswerItem[] {
        return model.dictionary.map((dictionaryItem) => {
            if (dictionaryItem.type === WordAnswerType.ENTITY) {
                return this.getEntityAnswerItem(dictionaryItem, model);
            }
            return ModelToViewMapper.getDictionaryAnswerItem(dictionaryItem, model);
        });
    }

    private getEntityAnswerItem(dictionaryItem: EntityWordAnswerPointer, model: Model): AnswerItemEntity {
        return {
            id: dictionaryItem.id,
            type: AnswerType.ENTITY,
            values: this.entities.get(dictionaryItem.id).values.map(value => ({
                key: value.key,
                synonyms: value.synonyms,
                // need to override key, since dictionaryItem is an entity pointer, not entity value pointer
                reaction: ModelToViewMapper.getAnswerReaction({
                    ...dictionaryItem,
                    key: value.key,
                }, model),
            })),
        }
    }

    /**
     * @param model Model value
     * @return DisambiguationItem[] Disambiguation objects
     */
    private async getDisambiguation(model: Model): Promise<DisambiguationItem[]> {
        const disambiguations = await this.stepsClient.getDisambiguations(model as any, Array.from(this.entities.values()), this.language);
        return disambiguations.map(disambiguation => ({
            ...disambiguation,
            answers: disambiguation.answers.map((answer) => ({
                ...answer,
                key: answer.type === AnswerType.DICTIONARY ? model.dictionary.find(entry => entry.id === answer.id).key : answer.key
            }))
        }));
    }
}

export default ModelToViewMapper;
