import {ActionsObservable, Epic, ofType} from 'redux-observable';
import {from, Observable, of} from 'rxjs';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {AsyncActionCreator, getType, PayloadAction} from 'typesafe-actions';

import {HttpClient} from '../utils/HttpClient';

type GetRequestActionPayload = { [key: string]: string }
type PutRequestActionPayload = { [key: string]: string, body: any }

export const createFetchEpic = <A extends PayloadAction<string, GetRequestActionPayload>>(
  actionCreator: AsyncActionCreator<any, any, any>,
  pathCreator: (args: GetRequestActionPayload) => string
) =>
  (action$: ActionsObservable<A>): Observable<PayloadAction<any, any>> =>
    action$.pipe(
      ofType(getType(actionCreator.request)),
      switchMap(({payload}: A) =>
        from(HttpClient.get({path: pathCreator(payload)}))
          .pipe(
            map(({data}: { data: any }) =>
              actionCreator.success(data)),
            catchError((error: string) => of(actionCreator.failure(error)))
          )
      )
    );

export const createMergedFetchEpic = <A extends PayloadAction<string, GetRequestActionPayload>>(
  {
    actionCreator,
    pathCreator,
    payloadMapper
  }:
  {
  actionCreator: AsyncActionCreator<any, any, any>,
  pathCreator: (args: GetRequestActionPayload) => string,
  payloadMapper?: (payload: any) => any
}) =>
  (action$: ActionsObservable<A>): Observable<PayloadAction<any, any>> =>
    action$.pipe(
      ofType(getType(actionCreator.request)),
      mergeMap(({payload}: A) =>
        from(HttpClient.get({path: pathCreator(payload)}))
          .pipe(
            map(({data}: { data: any }) =>
                actionCreator.success(payloadMapper ? payloadMapper(data) : data),
              catchError((error: string) => of(actionCreator.failure(error)))
            )
          )
      ));

export const createPutEpic = <A extends PayloadAction<string, PutRequestActionPayload>>(
  actionCreator: AsyncActionCreator<any, any, any>,
  pathCreator: (args: PutRequestActionPayload) => string
) => (action$: ActionsObservable<A>): Observable<PayloadAction<any, any>> =>
  action$.pipe(
    ofType(getType(actionCreator.request)),
    switchMap(({payload}: A) =>
      from(HttpClient.put({path: pathCreator(payload), body: payload.body}))
        .pipe(
          map(({data}: { data: string[] }) =>
            actionCreator.success(data)),
          catchError((error: string) => of(actionCreator.failure(error)))
        )
    )
  );

