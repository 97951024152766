import React from 'react';

import { Box } from '../../../components/Box/Box';
import { SavableLayout } from '../../../components/layouts/SavableLayout/SavableLayout';
import { HorizontalSeparator } from '../../../components/HorizontalSeparator/HorizontalSeparator';
import { PhoneTabContentComponentProps } from '../types';

import { CountryCallingCode } from './CountryCallingCode/CountryCallingCode';
import { PhoneNumbers } from './PhoneNumbers/PhoneNumbers';

export const PhoneTabContentComponent: React.FC<PhoneTabContentComponentProps> = ({
  hasChanges,
  isReady,
  isAdmin,
  onSave,
  onCancel,
  addPhoneConfig,
  deletePhoneConfig,
  updatePhoneConfig,
  phoneConfigs,
  countryCallingCode,
  setCountryCallingCode
}) => <SavableLayout
  isLoading={!isReady}
  hasChanges={hasChanges}
  onSave={onSave}
  onCancel={onCancel}
  dataTest="phone-tab-content"
>
    <Box border>
      <CountryCallingCode
        countryCallingCode={countryCallingCode}
        setCountryCallingCode={setCountryCallingCode}
        isAdmin={isAdmin}
      />
      <HorizontalSeparator />
      <PhoneNumbers
        addPhoneConfig={addPhoneConfig}
        deletePhoneConfig={deletePhoneConfig}
        updatePhoneConfig={updatePhoneConfig}
        phoneConfigs={phoneConfigs}
        isAdmin={isAdmin}
      />
    </Box>
  </SavableLayout>