import _ from 'lodash';
import * as React from 'react';
import { Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import useBranding from '../../hooks/useBranding/useBranding';

import styles from './statistics-chart.pcss';

export type Series = {
    key: string,
    name: string
};

interface Props<T> {
    series: Series[];
    data: T[];
    title: string;
}

type TooltipPayload = {
    name: string;
    value: string;
};

type TooltipProps = {
    label: string;
    active: boolean;
    payload: TooltipPayload[]
};

const renderTooltip = (props: TooltipProps) => {
    if (!props.active || !props.payload) {
        return null;
    }
    return (
        <div className={styles.chartTooltip}>
            <div className={styles.tooltipRow}>
                <div className={styles.tooltipRowValue}>
                    {props.label}
                </div>
            </div>
            {props.payload.map(element => (
                <div className={styles.tooltipRow} key={element.name}>
                    <div>
                        {element.name}: <span className={styles.tooltipRowValue}>{element.value}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

type LegendPayload = {
    name: string;
    value: string;
    color: string;
};

type LegendProps = {
    payload?: LegendPayload[];
    title: string;
};

const LegendWithTitle = (props: LegendProps) => {
    if (!props.payload) {
        return null;
    }
    // TODO: replace icon with SVG
    return (
        <div className={styles.chartLegend}>
            <div data-test="statistics-chart-title" className={styles.legendTitle}>{props.title}</div>
            <div className={styles.legendItems}>
                {props.payload.map(element => (
                    <div data-test="statistics-chart-legend-item" className={styles.legendItem} key={element.value}>
                        <div className={styles.legendIcon} style={{ borderColor: element.color }} />
                        <div>
                            {element.value}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

function prepareData<T>(series: Series[], data: T[]) {
    data.forEach(statistics => {
        series.forEach(({ key }) => {
            if (!statistics[key]) {
                statistics[key] = 0;
            }
        }
        );
    });
}

export function StatisticsChart<T>({ series, data, title }: Props<T>): React.ReactElement {
    const { primaryColor } = useBranding();

    function maxForKey(key: string) {
        let map = data
            .map(d => d[key]);
        return _.max(map);
    }

    function calculateMaxYAxis(): number {
        let map = series
            .map(serie => maxForKey(serie.key));
        let max = _.max(map);
        return (max + _.ceil(0.1 * max) || 4).toFixed(0);
    }

    const colors = [primaryColor, '#919aaf'];
    if (_.isEmpty(data)) {
        return null;
    }
    prepareData(series, data);
    const countOfHorizontalLinesAboveZero = 9;
    const horizontalLinesDistance = calculateMaxYAxis() / countOfHorizontalLinesAboveZero;
    return (
        <div className={styles.statisticsChartContainer}>
            <ResponsiveContainer width={'100%'} height={307}>
                <LineChart
                    data={data}
                    data-test={'statistics-chart'}
                    margin={{ top: 10, right: 0, bottom: 0, left: 0 }}
                >
                    <XAxis
                        dataKey="date"
                        data-test={'statistics-chart-x-axis'}
                        axisLine={false}
                        tickLine={false}
                        padding={{ left: 30, right: 30 }}
                        interval={'preserveStartEnd'}
                        height={24}
                        tick={{ fontSize: '12px', color: '#969797' }}
                    />
                    <YAxis
                        type="number"
                        axisLine={false}
                        tickLine={false}
                        width={36}
                        tick={{ fontSize: '12px', color: '#969797' }}
                        data-test={'statistics-chart-y-axis'}
                        padding={{ top: 20, bottom: 20 }}
                        domain={[0, calculateMaxYAxis()]}
                        tickCount={20}
                        allowDecimals={false}
                    />
                    <Tooltip content={renderTooltip} />
                    {_.range(0, 10).map(i => (
                        <ReferenceLine
                            key={i * horizontalLinesDistance}
                            y={i * horizontalLinesDistance}
                            stroke="#dedede"
                            strokeDasharray="2 2"
                            ifOverflow="extendDomain"
                            data-test={'statistics-chart-y-axis-line'}
                        />
                    ))}
                    <Legend verticalAlign="top" height={20} content={<LegendWithTitle title={title} />} />
                    {series.map(({ key, name }, index) => (
                        <Line data-test={'statistics-chart-line'} key={key} name={name} dataKey={key}
                            strokeWidth={2}
                            stroke={colors[index]}
                            dot={false}
                            activeDot={{ stroke: primaryColor, strokeWidth: 2, fill: '#ffffff' }}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}
