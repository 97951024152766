import classNames from 'classnames';
import _, {Dictionary} from 'lodash';
import moment from 'moment';
import React, { useState} from 'react';
import {IntlShape, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router';

import {Button} from '../../components/Button/Button';
import {Scrollbar} from '../../components/Scrollbar/Scrollbar';
import {CallDurationFilter} from '../../filters/CallDurationFilter/CallDurationFilter';
import {PhoneNumberFilter} from '../../filters/PhoneNumberFilter/PhoneNumberFilter';
import {PhraseFilter} from '../../filters/PhraseFilter/PhraseFilter';
import {currentUserRoles, getNamesDictionary} from '../../selectors';
import {useCurrentBot} from '../../utils/CurrentBot';
import {UserRole} from '../../utils/CurrentUser';
import {NamesDictionaryItem} from '../../utils/NamesDictionary';
import { EventType, EventTypeList } from '../models/Events';
import {EventsBar} from './EventsBar';
import {ConversationsFilters, ConversationsEventsFilters} from '../reports/useReports';
import { DatePicker } from '../../components/Dates/DatePicker/DatePicker';

import styles from './AllFiltersPage.pcss';

interface Props {
    readonly filters: ConversationsFilters;
    setFilters: (newFilters: Partial<ConversationsFilters>) => void;
    timezone?: string;
    onClose(): void;
}

export const AllFiltersPage = withRouter(({ setFilters, onClose, timezone, ...props }: Props & RouteComponentProps) => {
    const [currentFilters, setCurrentFilters] = useState<ConversationsFilters>(props.filters);

    const applyFilter = () => {
        setFilters(currentFilters);
        onClose();
    };

    const events = _.pick(currentFilters, [
        'information',
        'recognizedIntents',
        'results',
        'surveyQuestions',
        'technicalInformation',
    ]);

    return (
        <div className={styles.page}>
            <div className={styles.filtersSeparator}>
                <div className={styles.filterBox}>
                    <div className={styles.filters}>
                        <div className={styles.dateFilter}>
                            <DatePicker
                                data-test="conversations-date-range-filter"
                                startDate={!!currentFilters.startDate ? moment.tz(currentFilters.startDate, timezone).format('YYYY-MM-DD') : null}
                                endDate={!!currentFilters.endDate ? moment.tz(currentFilters.endDate, timezone).format('YYYY-MM-DD') : null}
                                onChange={(startDate, endDate) => {
                                    setCurrentFilters(filters => ({
                                        ...filters,
                                        startDate: startDate ? moment.tz(startDate, timezone).startOf('day').toISOString() : undefined,
                                        endDate: endDate ? moment.tz(endDate, timezone).endOf('day').toISOString() : undefined
                                    }));
                                }}
                            />
                        </div>
                        <CallDurationFilter
                            className={styles.durationFilter}
                            min={parseInt(currentFilters.callDurationMin, 10)}
                            max={parseInt(currentFilters.callDurationMax, 10)}
                            onChange={callDuration => setCurrentFilters(filters => ({...filters, callDuration}))}
                        />
                        <PhoneNumberFilter
                            className={styles.phoneFilter}
                            phoneNumber={currentFilters.phoneNumber}
                            onChange={phoneNumber => setCurrentFilters(filters => ({...filters, phoneNumber}))}
                        />
                        <PhraseFilter
                            className={styles.phraseFilter}
                            phrase={currentFilters.phrase}
                            onChange={phrase => setCurrentFilters(filters => ({...filters, phrase}))}
                        />

                        <div className={styles.filterControls}>
                            <Button buttonType="normal" onClick={onClose} translateText="conversations.filters.cancel"/>
                            <Button
                                onClick={applyFilter}
                                dataTest="conversations-apply-filters-button"
                                translateText="conversations.filters.filter"
                            />
                        </div>
                    </div>
                    <div className={styles.filtersBar}>
                        <EventsBar
                            events={events}
                            onChange={newFilter => setCurrentFilters(prevState => ({...prevState, ...newFilter}))}
                        />
                    </div>
                </div>
            </div>
            <Scrollbar>
                <EventTagsList
                    events={events}
                    onClick={newFilter => setCurrentFilters(prevState => ({...prevState, ...newFilter}))}
                />
            </Scrollbar>
        </div>
    );
});

interface EventTagsListProps {
    readonly events: ConversationsEventsFilters;

    onClick(newFilter: ConversationsEventsFilters): void;
}

const getEventsList = (namesDictionary: Dictionary<NamesDictionaryItem>, isAdminUser: boolean, botType: string, intl: IntlShape) => {
    function mapNamesDictionary(key: string, def: NamesDictionaryItem) {
        if (botType === 'survey') {
            return {
                key,
                label: intl.messages[`conversations.filter.${key}`] as string || def.label,
                category: def.category
            };
        }
        return {key, label: def.label, category: def.category};
    }

    return _.entries(namesDictionary)
        .filter(([, def]) => EventTypeList.includes(_.camelCase(def.category) as EventType))
        .filter(([, def]) => isAdminUser || def.category !== 'technicalInformation')
        .map(([key, def]) => mapNamesDictionary(key, def))
        .filter(el => !!el.label)
        .sort((a, b) => a.label.localeCompare(b.label));
};

function EventTagsList({events, onClick}: EventTagsListProps) {
    const bot = useCurrentBot();
    const intl = useIntl();
    const isAdminUser = useSelector(currentUserRoles).includes(UserRole.ADMIN);
    const namesDictionary = useSelector(getNamesDictionary);

    const eventsList = getEventsList(namesDictionary, isAdminUser, bot.type, intl);
    const columns = _.chunk(eventsList, Math.ceil(eventsList.length / 4));

    const selectedValues = new Set(_.flatten(_.values(events)));

    const applyFilter = (filterName, category) => {
        const type = _.camelCase(category);
        const hasFilter = events[type].some(name => name === filterName);
        const filters = hasFilter ? events[type].filter(f => f !== filterName) : [...events[type], filterName];

        onClick({...events, [type]: filters});
    };

    return (
        <div className={styles.events}>
            {columns.map((column, i) => (
                <div key={i} className={styles.eventsColumn}>
                    {column.map(({key, label, category}) => (
                        <div
                            data-test="filter-item"
                            data-active={selectedValues.has(key)}
                            key={key}
                            onClick={() => applyFilter(key, category)}
                            className={classNames(styles.event, selectedValues.has(key) ? styles.active : undefined)}
                        >
                            {label}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}
