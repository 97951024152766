import cx from 'classnames'
import React from 'react'

import { StandardInitialProps } from '../types'

import styles from './Box.pcss'

export type BoxProps = StandardInitialProps & 
Partial<Record<
'border' | 'noPadding' | 'selectable' | 'selected',
boolean>> & {
  onClick?: React.MouseEventHandler;
  dataTest?: string;
};

export const Box: React.FC<BoxProps> = ({
  className,
  children,
  border,
  noPadding,
  selectable,
  selected,
  dataTest,
  onClick,
}) => <div
data-test={dataTest}
onClick={onClick}
className={cx(
  styles.box,
  {
    [styles.border]: border,
    [styles.noPadding]: noPadding,
    [styles.selectable]: selectable,
    [styles.selected]: selected
  },
  className
)}>
    {children}
  </div>