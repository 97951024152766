import React from 'react';

import { EndQuestion } from '../../model';
import {CustomStatus} from '../CustomStatus';
import {EndQuestionMessage} from '../EndQuestionMessage';

import {TabFactory} from './question-component-factory-model';
import { StepTabType } from './types';

export const endQuestionFactory: TabFactory<EndQuestion> = {
  [StepTabType.Question]: ({question, onChange}) => {
    return (
      <>
        <EndQuestionMessage question={question} onChange={onChange} />
        <CustomStatus question={question} onChange={onChange} />
      </>
    );
  },
  questionLabelId: () => 'step.tabs.question.end'
};
