import React from 'react';
import { CloseBigIcon } from '../../icons/CloseBigIcon';
import { IconButton } from '../IconButton/IconButton';

import styles from './ModalContent.pcss';

type ModalHeaderProps = {
    subheader?: string;
    onClose?: React.MouseEventHandler;
    closeButtonDataTest?: string;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({
    children,
    subheader,
    onClose,
    closeButtonDataTest
}) => {
    return (
        <div className={styles.modalHeader}>
            <div className={styles.modalHeaderInner}>
                {children}
                {subheader && <div className={styles.modalSubheader}>{subheader}</div>}
            </div>
            {
                onClose && <IconButton
                    dataTest={closeButtonDataTest}
                    Icon={CloseBigIcon}
                    onClick={onClose}
                />
            }
        </div>
    );
};