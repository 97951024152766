import React from 'react';

import { IconProps } from './iconTypes';

export const PasswordConfirmIcon: React.FC<IconProps> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
    <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
            <g>
                <path fill="#48BE4D" d="M46.396 28.79c9.724 0 17.607 7.883 17.607 17.606 0 9.724-7.883 17.607-17.607 17.607-9.723 0-17.606-7.883-17.606-17.607 0-9.723 7.883-17.606 17.606-17.606zm0 2.313c-8.446 0-15.293 6.847-15.293 15.293 0 8.447 6.847 15.294 15.293 15.294 8.447 0 15.294-6.847 15.294-15.294 0-8.446-6.847-15.293-15.294-15.293zm8.026 9.587c.477.357.599 1.013.301 1.514l-.07.105-7.966 10.62c-.56.743-1.413 1.208-2.341 1.275-.862.061-1.71-.226-2.356-.795l-.146-.136-4.112-4.112c-.452-.452-.452-1.184 0-1.636.422-.421 1.088-.45 1.542-.084l.094.084 4.11 4.11c.185.185.442.28.703.261.223-.016.432-.114.585-.273l.072-.084 7.964-10.618c.384-.511 1.109-.615 1.62-.231z" transform="translate(-651.000000, -221.000000) translate(651.000000, 221.000000)"/>
                <path fill="#3C38BC" d="M54.587 0c2.857 0 5.187 2.259 5.302 5.088l.004.219v16.426c0 .663-.537 1.2-1.2 1.2-.621 0-1.132-.472-1.193-1.077l-.007-.123V5.307c0-1.548-1.21-2.814-2.736-2.902l-.17-.005H5.307c-1.548 0-2.814 1.21-2.902 2.736l-.005.17v24.64c0 1.549 1.21 2.814 2.736 2.902l.17.005h18.48c.663 0 1.2.538 1.2 1.2 0 .622-.472 1.133-1.077 1.194l-.122.006H5.307c-2.858 0-5.188-2.258-5.303-5.088L0 29.947V5.307C0 2.449 2.259.119 5.088.004L5.307 0h49.28zM13.523 15.4l.156.006c1.103.077 1.984.959 2.062 2.062l.006.159c0 1.23-.997 2.226-2.227 2.226s-2.227-.997-2.227-2.223c-.001-.592.233-1.16.652-1.579.418-.418.986-.653 1.578-.651zm16.427 0l.156.006c1.103.077 1.984.959 2.062 2.062l.005.159c0 1.23-.997 2.226-2.226 2.226-1.23 0-2.227-.997-2.227-2.223-.002-.592.233-1.16.651-1.579.419-.418.987-.653 1.579-.651zm16.427 0l.155.006c1.103.077 1.985.959 2.062 2.062l.006.159c0 1.23-.997 2.226-2.227 2.226s-2.226-.997-2.226-2.223c-.002-.592.233-1.16.651-1.579.419-.418.987-.653 1.579-.651z" transform="translate(-651.000000, -221.000000) translate(651.000000, 221.000000)"/>
            </g>
        </g>
    </g>
</svg>
);