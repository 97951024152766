import cx from 'classnames';
import * as React from 'react';

import {Label} from '../../components/Label';
import {Toggle} from '../../components/Toggle/Toggle';

import styles from './ToggleWithLabel.pcss';

type ToggleWithLabelAndElementClasses = Partial<{
  root: string;
  toggle: string;
}>;

type Props = { 
  value: boolean, 
  onToggle: () => void, 
  dataTest?: string, 
  labelId: string, 
  classes?: ToggleWithLabelAndElementClasses,
  children: JSX.Element 
};

export const ToggleWithLabelAndElement = (props: Props) => (
  <div data-test={props.dataTest} className={styles.container}>
    <div className={cx(styles.toggle, props.classes?.toggle)}>
      <Toggle on={props.value} onToggle={props.onToggle} />
      <Label labelId={props.labelId} />
    </div>
    {props.children}
  </div>

);
