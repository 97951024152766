import {sha256} from 'js-sha256';
import _ from 'lodash';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import * as FileSaver from 'file-saver';
import cx from 'classnames';


import {TooltipComponent} from '../../../components/Tooltip/Tooltip';
import {
    AddAudioIcon,
    PlayCircleIcon,
    PlayCircleDisabledIcon,
    ReplaceAudioIcon,
    DownloadAudioIcon,
} from '../../../icons';
import {HttpClient} from '../../../utils/HttpClient';
import {BasicMessage} from '../../model';
import {DeleteOption} from '../optionsMenu/DeleteOption';
import {OptionsComponent} from '../optionsMenu/Options';
import {MenuOption} from '../optionsMenu/OptionsMenu';
import {useAudioPlayer} from '../useAudioPlayer';

import styles from './MessageAudio.pcss';

const preventDefault = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
};

type MessageAudioProps = {
    onChange: (message: BasicMessage, answerId?: string) => void;
    message: BasicMessage,
    botId: string,
    disabled?: boolean,
    answerId?: string
};

export const MessageAudio = (props: MessageAudioProps) => {
    const intl = useIntl();
    const currentAudio = props.message.audio && props.message.audio[0];
    const handlePlay = useAudioPlayer({ url: `/bots/${props.botId}/creator/audio/${currentAudio}` });
    const handleSynthesize = useAudioPlayer({
        url: `/bots/${props.botId}/synthesize`,
        params: { text: encodeURIComponent(props.message.text) }
    });

    const playAudio = useCallback((e: any) => {
        preventDefault(e);
        return handlePlay();
    }, [handlePlay]);

    const handleFileRemove = useCallback((e: any) => {
        if (props.onChange) {
            props.onChange({ ...props.message, audio: null }, props.answerId);
        }
        preventDefault(e);
    }, [props.onChange, props.message]);

    const handleFileUpload = useCallback(async (event) => {
        const file: File = event.target.files[0];
        const response = await HttpClient.put({
            path: `/bots/${props.botId}/creator/upload-audio`,
            headers: {
                'Content-Type': 'application/octet-stream',
                'X-Filename': `${sha256(await file.arrayBuffer())}.${file.name.substring(file.name.lastIndexOf('.') + 1)}`
            },
            body: file
        });
        if (props.onChange) {
            props.onChange({ ...props.message, audio: [response.data.fileName] }, props.answerId);
        }
    }, [props.botId, props.onChange, props.message]);

    const handleDownloadFile = useCallback(async () => {
        const { data } = await HttpClient.get({ path: `/bots/${props.botId}/creator/audio/${currentAudio}` }, { responseType: 'blob' });
        FileSaver.saveAs(data, 'recording.wav');
    }, [props.botId, currentAudio]);

    return (
        <div className={styles.container}>
            {
                !!currentAudio ? (
                    <>
                        <MessageAudioOptions onUpload={handleFileUpload} onDelete={handleFileRemove} onDownload={handleDownloadFile} />
                        <button className={styles.playButton} onClick={playAudio} data-test="play-audio"/>
                    </>
                ) : (
                    <>
                        <TooltipComponent tooltipText={intl.formatMessage({id: 'survey-creator.audio.addRecording'})}>
                            <div className={styles.addAudio}>
                                <AddAudioIcon animationClass={styles.addAudioIcon}/>
                                <input type="file" data-test="upload-input" name="audio-file" className={styles.fileInput}
                                       title="" onChange={(event) => {
                                    handleFileUpload(event);
                                }}/>
                            </div>
                        </TooltipComponent>
                        <button className={styles.playButton} disabled={props.disabled || !props.message.text} onClick={handleSynthesize} data-test="synthesize-button">
                            {props.disabled || !props.message.text ? <PlayCircleDisabledIcon /> : <PlayCircleIcon />}
                        </button>
                    </>
                )
            }
        </div>
    );
}

type MessageAudioOptionsProps = {
    onUpload?: (event: any) => any;
    onDelete?: (event: any) => any;
    onDownload?: (event: any) => any;
};
export const MessageAudioOptions = ({onUpload, onDelete, onDownload}: MessageAudioOptionsProps) => {

    const options = [
        <DeleteOption key={'delete-option'} onDelete={onDelete} tooltip={'survey-creator.audio.deleteRecording'}/>,
        <DownloadOption key={'download-option'} onDownload={onDownload} />,
        <UploadOption key={'upload-option'} onClick={onUpload} />
    ];

    return (
        <OptionsComponent
            containerPositionClassName={styles.optionsPosition}
            options={options}/>
    )
};

export const DownloadOption = ({onDownload}: {onDownload: (event: any ) => any }) => {
    const intl = useIntl();

    return <TooltipComponent tooltipText={intl.formatMessage({id: 'survey-creator.audio.downloadRecording'})}>
        <MenuOption dataTest={'download-audio'} onClick={onDownload} icon={DownloadAudioIcon} positionRelative />
    </TooltipComponent>
}
export const UploadOption = (props: { onClick: (event: any) => void}) => {
    const intl = useIntl();


    return (
        <TooltipComponent tooltipText={intl.formatMessage({id: 'survey-creator.audio.replaceRecording'})}>
            <MenuOption dataTest="upload-option" onClick={_.noop} icon={ReplaceAudioIcon} positionRelative>
                <input type="file" data-test="upload-input" name="audio-file" className={styles.fileInput}
                       title="" onChange={event => {
                    props.onClick(event);
                }}/>
            </MenuOption>
        </TooltipComponent>
    );
}
export type SurveyQuestionType = 'repeat' | 'failure';
