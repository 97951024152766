import * as FileSaver from 'file-saver';
import pickBy from 'lodash/pickBy';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import _ from 'lodash';
import { useQueryClient } from 'react-query';

import { TooltipComponent } from '../components/Tooltip/Tooltip';
import { createUrlOrderingState } from '../components/urlOrderingState';
import { useAsyncFn } from '../utils/asyncUtils';
import { useCurrentBot } from '../utils/CurrentBot';
import { HttpClient } from '../utils/HttpClient';
import { useSurveyConfig } from '../views/Settings/Settings.hooks';

import { AddContactsModal } from './AddContactsModal';
import { ContactDetails } from './ContactDetails';
import { ContactList } from './ContactList';
import { ContactsFilter } from './ContactsFilter';
import {
  isAnyContactFilterDefined,
  parseContactsFilters,
} from './contactsUrlFiltersState';
import { Contact, Integration } from './model';
import { DownloadButton } from '../components/DownloadButton/DownloadButton';
import { Loader } from '../components/Loader';
import { ContactEmptyState } from './status/ContactEmptyState';
import { AddButton } from '../components/AddButton/AddButton';
import { Button } from '../components/Button/Button';
import { CancelMultipleContactsModal } from './CancelMultipleContactsModal';
import {
  showErrorToast,
  showSuccessToast,
  showWarningToast,
} from '../components/Toast/Toast';
import { MarkdownContent } from '../components/MarkdownContent/MarkdownContent';
import { getUseGetContactStatisticsQueryKey } from '../scheduler/scheduler-api';
import { useDialerContext } from '../dialer/context/DialerContext';

import styles from './Contacts.pcss';
import { useSurveyCreator } from '../surveyCreator/useSurveyCreator';
import { DropdownMenu } from '../components/DropdownMenu/DropdownMenu';
import OutsideClickHandler from 'react-outside-click-handler';
import { ImportVisitsAsContactsModal } from './ImportVisitsAsContactsModal/ImportVisitsAsContactsModal';

export interface ContactsFilterType {
  readonly status: string;
  readonly phoneNumber: string;
  readonly startDate?: number;
  readonly endDate?: number;
}

const DEFAULT_FILTER: ContactsFilterType = {
  status: undefined,
  phoneNumber: '',
};

export const {
  parse: parseContactsOrdering,
  toggleOrdering: toggleContactsOrdering,
} = createUrlOrderingState({
  allowedProperties: [
    'phoneNumber',
    'importDate',
    'status',
    'importDate',
    'lastInteraction',
    'currentInteraction',
    'followUpInteraction',
    'interactionsPerformed',
    new RegExp('variables\\..*'),
  ],
  defaultSort: 'importDate',
});

const defaultCancellableContactsStats = {
  created: 0,
  in_progress: 0,
  all: 0,
};

export const CANCELING_VISITS_TAG = 'Odwoływanie przez placówkę';

export const Contacts = withRouter((props: RouteComponentProps) => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const currentBot = useCurrentBot();
  const { displayedConfig } = useSurveyConfig();
  const { definition } = useSurveyCreator(currentBot);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [integration, setIntegration] = useState<Integration>(null);
  const [isIntegrationLoading, setIsIntegrationLoading] = useState(true);
  const [selectedContactId, setSelectedContactId] = useState<string>(null);
  const [contactsCount, setContactsCount] = useState<number>();
  const [contactsCancellableStats, setContactsCancellableStats] = useState(
    defaultCancellableContactsStats
  );
  const [hasMoreContacts, setHasMoreContacts] = useState<boolean>();
  const [addContactsModalOpened, setAddContactsModalOpened] =
    useState<boolean>(false);
  const [
    importVisitsAsContactsModalOpened,
    setImportVisitsAsContactsModalOpened,
  ] = useState<boolean>(false);
  const [cancelContactsModalOpened, setCancelContactsModalOpened] =
    useState<boolean>(false);
  const { hasSchedule, setHasSchedule } = useDialerContext();
  const [isAddContactsDropdownOpen, setAddContactsDropdownOpen] =
    useState(false);

  const isIntegrationContactsImportAvailable =
    currentBot.tags?.includes(CANCELING_VISITS_TAG) && integration;

  const selectedContact = useMemo(
    () => contacts.find((contact) => contact.id === selectedContactId),
    [contacts, selectedContactId]
  );

  const currentFilters: ContactsFilterType = useMemo(
    () => ({
      ...DEFAULT_FILTER,
      ...parseContactsFilters(props.location.search),
    }),
    [props.location.search]
  );
  const ordering = useMemo(
    () => parseContactsOrdering(props.location.search),
    [props.location.search]
  );

  const {
    state: { loading },
    callback: loadContacts,
    cancelPendingTasks,
  } = useAsyncFn(
    (lastContactId?: string) => {
      return HttpClient.get({
        path: `/bots/${currentBot.id}/schedule`,
      })
        .then(({ data }) => {
          setHasSchedule(
            !_.isEmpty(data.interactionPlan) &&
              !_.isEmpty(data.allowedDaysOfWeekRule?.daysOfWeek)
          );
          return HttpClient.get({
            path: `/bots/${currentBot.id}/contacts`,
            params: {
              ...currentFilters,
              limit: 50,
              'order.property': ordering.property,
              'order.dir': ordering.direction,
              ...(lastContactId ? { after: lastContactId } : {}),
            },
          });
        })
        .then(({ data }) => ({
          contacts: data.contacts,
          count: data.count,
          hasMore: data.hasMore,
          stats: data.stats,
          cancellable: data.cancellable,
        }))
        .then((data) => {
          setContacts((currentContacts) => [
            ...currentContacts,
            ...data.contacts,
          ]);
          setContactsCount(data.count);
          setContactsCancellableStats({
            ...data.cancellable,
            all: data.cancellable.created + data.cancellable.in_progress,
          });
          setHasMoreContacts(data.hasMore);
        });
    },
    [
      currentBot.id,
      currentFilters,
      ordering,
      setContacts,
      setContactsCount,
      setContactsCancellableStats,
      setHasMoreContacts,
    ],
    {
      minimumQueryLoadingTime: 150,
    }
  );

  async function cancelMultipleContacts() {
    try {
      const contactsToCancel = contactsCancellableStats.all;

      const { data } = await HttpClient.put({
        path: `/bots/${currentBot.id}/contacts/cancel`,
        body: {
          ...currentFilters,
        },
      });
      const { cancelledCount } = data;

      if (contactsToCancel > cancelledCount) {
        showWarningToast(
          <MarkdownContent
            contentKey="contacts.cancel.multipleContacts.toast.warning"
            extraValues={{
              cancelledCount,
              notCancelledCount: (contactsToCancel - cancelledCount).toString(),
            }}
          />
        );
      } else {
        showSuccessToast(
          <MarkdownContent
            contentKey="contacts.cancel.multipleContacts.toast.success"
            extraValues={{
              count: cancelledCount,
            }}
          />
        );
      }
    } catch (error) {
      showErrorToast(
        <MarkdownContent contentKey="contacts.cancel.multipleContacts.toast.error" />
      );
    } finally {
      setCancelContactsModalOpened(false);
      refreshList();
    }
  }

  const fetchContactsPage = useCallback(
    (lastContactId?: string) => {
      return loadContacts(lastContactId);
    },
    [loadContacts]
  );

  const refreshList = useCallback(() => {
    setHasMoreContacts(false);
    setContactsCount(undefined);
    setContactsCancellableStats(defaultCancellableContactsStats);
    setContacts([]);
    void fetchContactsPage();
    void queryClient.invalidateQueries(
      getUseGetContactStatisticsQueryKey(currentBot.id)
    );
    return cancelPendingTasks;
  }, [
    fetchContactsPage,
    cancelPendingTasks,
    setHasMoreContacts,
    setContactsCount,
    setContactsCancellableStats,
    setContacts,
    queryClient,
  ]);

  const handleOpenContact = useCallback(
    (contactId: string) => {
      setSelectedContactId(contactId);
    },
    [contacts]
  );

  const clearOpenedContact = useCallback(() => {
    setSelectedContactId(undefined);
  }, []);

  const getReport = async () => {
    const { data } = await HttpClient.get(
      {
        path: `/bots/${currentBot.id}/contacts/report`,
        params: {
          ...pickBy(currentFilters, (f) => f !== undefined && f !== ''),
        },
      },
      { responseType: 'blob' }
    );
    FileSaver.saveAs(
      new Blob([data]),
      `${intl.messages['contacts.report.fileName']}.xlsx`
    );
  };

  useEffect(() => {
    return refreshList();
  }, [refreshList]);

  const fetchIntegration = async () => {
    try {
      const { data } = await HttpClient.get({
        path: `/bots/${currentBot.id}/integration`,
      });
      setIntegration(data.integration);
    } catch (err) {
      // No integration, do nothing
    }
    setIsIntegrationLoading(false);
  };

  useEffect(() => {
    fetchIntegration();
  }, []);

  const contactsAvailable = contactsCount > 0 || contacts.length > 0;
  const isEmptyStateDisplayed = !contactsAvailable && !loading;
  const isAnyFilterDefined = isAnyContactFilterDefined(currentFilters);

  return (
    <>
      <div className={styles.filterHeader}>
        <div className={styles.navbar}>
          <ContactsFilter
            onFilterChanged={clearOpenedContact}
            contactsCount={contactsCount}
            timezone={displayedConfig?.timeZone}
          />
          <div className={styles.navbar__buttons}>
            <TooltipComponent
              tooltipText={intl.formatMessage({
                id: 'contacts.cancel.noContactsTooltip',
              })}
              trigger={!contactsCancellableStats.all ? 'hover' : 'none'}
              className={styles.cancelContactsButtonWrapper}
            >
              <Button
                dataTest="cancel-contacts-btn"
                buttonType="link"
                className={styles.navbar__button}
                disabled={!contactsCancellableStats.all}
                onClick={() => setCancelContactsModalOpened(true)}
              >
                <FormattedMessage
                  id={getCancelContactsButtonTextKey(
                    contactsCancellableStats.all,
                    isAnyFilterDefined
                  )}
                  values={{ count: contactsCancellableStats.all }}
                />
              </Button>
            </TooltipComponent>
            <CancelMultipleContactsModal
              stats={contactsCancellableStats}
              hasFilters={isAnyFilterDefined}
              modalOpened={cancelContactsModalOpened}
              closeModal={() => setCancelContactsModalOpened(false)}
              onConfirm={cancelMultipleContacts}
            />
            <TooltipComponent
              tooltipText={intl.formatMessage({
                id: 'contacts.report.noContacts',
              })}
              trigger={!contactsCount ? 'hover' : 'none'}
            >
              <DownloadButton
                buttonType="normal"
                dataTest="generate-contacts-report-btn"
                className={styles.navbar__button}
                disabled={!contactsCount}
                onClick={getReport}
                textId="contacts.report.buttonLabel"
              />
            </TooltipComponent>
            <div className={styles.buttonContainer}>
              <OutsideClickHandler
                onOutsideClick={() => setAddContactsDropdownOpen(false)}
              >
                <DropdownMenu
                  dataTest="add-contacts-dropdown"
                  classes={{
                    menu: styles.addContactsDropdownMenu,
                  }}
                  isOpen={isAddContactsDropdownOpen}
                  options={[
                    {
                      dataTest: 'add-contacts-dropdown-manual-option',
                      id: 'manual',
                      name: intl.formatMessage({
                        id: 'contacts.add.manualOption.name',
                      }),
                      description: intl.formatMessage({
                        id: 'contacts.add.manualOption.description',
                      }),
                      onClick: () => {
                        setAddContactsDropdownOpen(false);
                        setAddContactsModalOpened(true);
                      },
                    },
                    {
                      dataTest: 'add-contacts-dropdown-integration-option',
                      id: 'integration',
                      name: intl.formatMessage({
                        id: 'contacts.add.integrationOption.name',
                      }),
                      description: intl.formatMessage({
                        id: 'contacts.add.integrationOption.description',
                      }),
                      onClick: () => {
                        setAddContactsDropdownOpen(false);
                        setImportVisitsAsContactsModalOpened(true);
                      },
                    },
                  ]}
                />
              </OutsideClickHandler>
              <AddButton
                buttonType={isIntegrationLoading ? 'loading' : undefined}
                disabled={!hasSchedule || isIntegrationLoading}
                tooltipText={
                  !hasSchedule
                    ? intl.formatMessage({
                        id: 'contacts.add.buttonLabel.disabled.noSchedule',
                      })
                    : undefined
                }
                textId="contacts.add.buttonLabel"
                onClick={() => {
                  if (isIntegrationContactsImportAvailable) {
                    setAddContactsDropdownOpen(true);
                  } else {
                    setAddContactsModalOpened(true);
                  }
                }}
                dataTest="add-contacts-btn"
              />
            </div>
            <AddContactsModal
              currentBot={currentBot}
              afterAddCallback={refreshList}
              timezone={displayedConfig?.timeZone}
              modalOpened={addContactsModalOpened}
              closeModal={() => setAddContactsModalOpened(false)}
            />
            <ImportVisitsAsContactsModal
              isOpen={importVisitsAsContactsModalOpened}
              close={() => setImportVisitsAsContactsModalOpened(false)}
              integration={integration}
              currentBot={currentBot}
              surveyConfig={displayedConfig}
              hasSchedule={hasSchedule}
              timezone={displayedConfig?.timeZone}
            />
          </div>
        </div>
      </div>
      <div className={styles.contactsDisplay}>
        {loading && !contactsAvailable && <Loader />}
        {isEmptyStateDisplayed && (
          <ContactEmptyState
            hasSchedule={hasSchedule}
            hasFilters={isAnyFilterDefined}
            openAddContactsModal={() => setAddContactsModalOpened(true)}
          />
        )}
        {contactsAvailable && (
          <>
            <ContactList
              hasMore={hasMoreContacts}
              contacts={contacts}
              contactsCount={contactsCount}
              fetchNextPage={fetchContactsPage}
              loading={loading}
              variables={definition.variables.get()}
              onContactSelected={handleOpenContact}
              selectedContact={selectedContact}
              timezone={displayedConfig?.timeZone}
            />
            <ContactDetails
              interactions={selectedContact ? selectedContact.interactions : []}
              contactId={selectedContact && selectedContact.id}
              status={selectedContact ? selectedContact.status : undefined}
              timezone={displayedConfig?.timeZone}
              onRefreshContacts={refreshList}
            />
          </>
        )}
      </div>
    </>
  );
});

function getCancelContactsButtonTextKey(
  cancellableContactsCount: number,
  isAnyFilterDefined: boolean
) {
  if (isAnyFilterDefined) {
    if (cancellableContactsCount > 0) {
      return 'contacts.cancel.selectedContactsButtonLabelWithCount';
    }
    return 'contacts.cancel.selectedContactsButtonLabel';
  }

  if (cancellableContactsCount > 0) {
    return 'contacts.cancel.allContactsButtonLabelWithCount';
  }
  return 'contacts.cancel.allContactsButtonLabel';
}
