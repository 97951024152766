import classNames from 'classnames';
import _ from 'lodash';
import * as React from 'react';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import Select, { components as basicComponents } from 'react-select';
import CreatableSelect, {
  Props as CreatableProps,
} from 'react-select/creatable';
import { SelectComponents } from 'react-select/src/components';

import { IconType } from '../../icons/IconType';
import { ArrowUpIcon, DropdownIcon } from '../../icons';
import clearIcon from '../../images/cancel-small.svg';

import styles from './Select.pcss';

export type Value = {
  id: any;
  name: string;
  icon?: IconType;
  className?: string;
};

export type SelectProps = {
  value?: Value | null;
  options: Value[];
  onChange: (value: Value) => void;
  onCreate?: (value: string) => void;
  isClearable?: boolean;
  styleClass?: any;
  className?: string;
  components?: Partial<SelectComponents<any, boolean>>;
  placeholderMessage?: string;
  isDisabled?: boolean;
  isCreatable?: boolean;
  isSearchable?: boolean;
  isOpen?: boolean;
  invalid?: boolean;
  autoFocus?: boolean;
  createOptionMessageId?: string;
  type?: 'classic' | 'nonBordered';
  error?: string;
};

export const SelectComponent = ({
  styleClass,
  value,
  options,
  onChange,
  onCreate,
  components,
  placeholderMessage,
  createOptionMessageId,
  className,
  isClearable = false,
  isDisabled = false,
  isCreatable = false,
  isSearchable = false,
  isOpen = false,
  autoFocus = false,
  invalid = false,
  type = 'classic',
  error,
}: SelectProps) => {
  const intl = useIntl();
  const classes = classNames(
    styleClass || styles.select,
    styles[type],
    className
  );
  const handleClear = useCallback(() => onChange(undefined), [onChange]);
  const preventPropagationForClear = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);
  let adjustedOptions = value
    ? options.filter((option) => option.id !== value.id)
    : options;
  adjustedOptions = adjustedOptions.map((opt) => ({
    label: opt.name,
    value: opt.id,
    ...opt,
  }));

  const BaseSelect = (
    isCreatable ? CreatableSelect : Select
  ) as React.ComponentClass<CreatableProps<Value, boolean>>;

  return (
    <div className={styles.container}>
      <BaseSelect
        key={`react-select-${value}`}
        className={classes}
        data-test="custom-select"
        classNamePrefix="custom-select"
        value={value}
        options={_.orderBy(adjustedOptions, (option) =>
          value && option.id === value.id ? -1 : value ? value.name : 0
        )}
        isSearchable={isSearchable || isCreatable}
        isClearable={isClearable}
        createOptionPosition={'last'}
        onChange={onChange}
        onCreateOption={onCreate}
        placeholder={
          !!placeholderMessage
            ? (intl.messages[placeholderMessage] as string)
            : (intl.messages['select.placeholder'] as string)
        }
        isDisabled={isDisabled}
        defaultMenuIsOpen={isOpen}
        autoFocus={autoFocus}
        components={{
          Option: ({ innerProps, data, isSelected, isFocused }) => {
            return (
              <div
                {...innerProps}
                className={classNames(
                  styles.option,
                  { [styles.selectedOption]: isSelected },
                  { [styles.focusedOption]: isFocused },
                  data.className
                )}
              >
                {data.icon && (
                  <div className={styles.icon}>
                    <data.icon />
                  </div>
                )}
                {data.__isNew__ ? (
                  <div
                    className={styles.createNewLabel}
                    data-test="create-option"
                  >{`${intl.messages[createOptionMessageId] as string} "${
                    data.value
                  }"`}</div>
                ) : (
                  <div
                    className={styles.selectedLabel}
                    data-test="select-option"
                  >
                    {data.name}
                  </div>
                )}
              </div>
            );
          },
          Control: (props) => (
            <div
              className={invalid ? styles.invalid : ''}
              data-test={invalid ? 'select-is-invalid' : ''}
            >
              <basicComponents.Control {...props} />
            </div>
          ),
          SingleValue: ({ innerProps, data }: any) => (
            <div {...innerProps} className={styles.selected}>
              {data.icon && (
                <div className={styles.icon}>
                  <data.icon />
                </div>
              )}
              <div
                className={styles.selectedLabel}
                data-test={`select-selected`}
              >
                {data.name}
              </div>
            </div>
          ),
          ClearIndicator: () => (
            <img
              src={clearIcon}
              className={styles.clearIcon}
              data-test={'select-clear'}
              alt={'clear'}
              onMouseDown={preventPropagationForClear}
              onClick={handleClear}
            />
          ),
          DropdownIndicator: () => {
            return (
              <>
                <span className={styles.customIndicatorClosed}>
                  <DropdownIcon />
                </span>
                <span className={styles.customIndicatorOpen}>
                  <ArrowUpIcon />
                </span>
              </>
            );
          },
          NoOptionsMessage: () => {
            return <div />;
          },
          ...(components || {}),
        }}
      />
      {!!error && (
        <div className={styles.tooltip} role="tooltip">
          {error}
        </div>
      )}
    </div>
  );
};
