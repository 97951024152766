import * as React from 'react';

import {DuplicateIcon} from '../../../icons';

import {MenuOption} from './OptionsMenu';

export const DuplicateOption = (props: { onClick: (event: any) => void }) => {
    return (
        <MenuOption dataTest="duplicate-option" onClick={props.onClick} icon={DuplicateIcon}
                    tooltipLabelId="menuOption.duplicate.tooltip"/>
    );
}
