import classNames from 'classnames';
import React from 'react';
import { NotificationSuccessIcon } from '../../icons/notifications/NotificationSuccessIcon';
import { NotificationErrorIcon } from '../../icons/notifications/NotificationErrorIcon';
import styles from './Alert.pcss';

type Props = {
  variant: 'success' | 'error';
  children: React.ReactNode;
  className?: string;
  dataTest?: string;
};

const alertIcons = {
  success: NotificationSuccessIcon,
  error: NotificationErrorIcon,
} as const;

export function Alert({ variant, children, dataTest, className }: Props) {
  const Icon = alertIcons[variant];

  return (
    <div
      className={classNames(styles.alert, styles[variant], className)}
      data-test={dataTest}
    >
      <div className={styles.iconContainer}>
        <Icon />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
