import React from 'react';
import { CallDetails } from './models/Call';
import styles from './ConversationDetails.pcss';
import { TooltipComponent } from '../components/Tooltip/Tooltip';

type ConversationDetailsMetadataProps = {
  isLoading?: boolean;
  isTextChat?: boolean;
  call?: CallDetails;
  eventIcon?: React.ReactNode;
  eventLabel?: string;
  timestamp?: string;
}

export const ConversationDetailsMetadata: React.FC<ConversationDetailsMetadataProps> = ({
  isLoading,
  isTextChat,
  call,
  eventIcon,
  eventLabel,
  timestamp,
}) => {
  return isLoading
    ? null
    : <div className={styles.metadata}>
      {
        !isTextChat && call?.conversationStart &&
        <div
          data-test="conversation-details-phone-number"
          className={styles.metadataItem}
        >
          <div data-sensitive="true" className={styles.metadataItemText}>
            {
              eventIcon &&
              <div className={styles.metadataItemIcon}>
                <TooltipComponent tooltipText={eventLabel}>
                  {eventIcon}
                </TooltipComponent>
              </div>
            }
            {call?.phoneNumber}
          </div>
        </div>
      }

      {
        call?.conversationStart &&
        <div
          data-test="conversation-details-start-time"
          className={styles.metadataItem}
        >
          {timestamp}
        </div>
      }
    </div>
}