import { filter } from 'lodash';
import { IntlShape, useIntl } from 'react-intl';
import uuid from 'uuid';

import { Answer } from '../../../../components/AnswersList/AnswersList';
import { WordAnswerType, WordsDictionary } from '../../../model';
import { useGlobalWords } from '../../EntityDetails/hooks/useGlobalWords'
import { SpecialCharactersContainerProps } from '../types';

export enum SpecialCharacter {
  SPACE = 'SPACE',
  HYPHEN = 'HYPHEN',
  DOT = 'DOT',
  UNDERSCORE = 'UNDERSCORE'
}
export const mapGlobalWordToCharacter = (word: SpecialCharacter, intl: IntlShape) => ({
  [SpecialCharacter.SPACE]: intl.messages['survey-creator.specialCharacters.characters.space'] as string,
  [SpecialCharacter.HYPHEN]: '-',
  [SpecialCharacter.DOT]: '.',
  [SpecialCharacter.UNDERSCORE]: '_'
})[word];

export const filterSpecialCharacters = (globalWords: ReturnType<typeof useGlobalWords>['globalWords']) => filter(globalWords, (globalWord, key) => Object.keys(SpecialCharacter).includes(key));

export const useSpecialCharactersProps = (props: SpecialCharactersContainerProps) => {
  const { globalWords } = useGlobalWords();
  const intl = useIntl();
  const items = Object.keys(SpecialCharacter)
    .map(key => ({ key, name: mapGlobalWordToCharacter(key as SpecialCharacter, intl) }));

  const onConfirm = (character: string) => {
    const { dictionary } = props.question;

    const newItem = {
      id: uuid.v4(),
      key: character,
      synonyms: [],
      type: WordAnswerType.DICTIONARY as const
    };

    props.onChange({
      ...props.question,
      dictionary: dictionary ? [...dictionary, newItem] : [newItem]
    });
  }

  const onChange = (answers: Answer[]) => props.onChange({
    ...props.question,
    dictionary: answers as WordsDictionary
  });

  return {
    items,
    onConfirm,
    onChange,
    globalWords
  }
}