import { useEffect, useState } from 'react';
import { Recognizer } from './model';
import { HttpClient } from '../utils/HttpClient';

export const useRecognizers = () => {
  const [recognizers, setRecognizers] = useState<Recognizer[]>([]);
  useEffect(() => {
    HttpClient.get({ path: `/recognizers` }).then(result => {
      if (result) {
        setRecognizers(result.data.recognizers)
      }
  })}, []);

  return { recognizers };
}