import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../components/Button/Button';
import { MarkdownContent } from '../components/MarkdownContent/MarkdownContent';
import { Modal } from '../components/Modal/Modal';
import { ModalContent } from '../components/ModalContent/ModalContent';
import styles from './CancelMultipleContactsModal.pcss';

type CancelMultipleContactsStats = {
  all: number;
  created: number;
  in_progress: number;
};

type Props = {
  modalOpened: boolean;
  closeModal: () => void;
  hasFilters: boolean;
  stats: CancelMultipleContactsStats;
  onConfirm: () => void;
};

export function CancelMultipleContactsModal({
  stats,
  hasFilters,
  modalOpened,
  closeModal,
  onConfirm,
}: Props) {
  return (
    modalOpened && (
      <Modal
        position="center"
        dataTest="cancel-multiple-contacts-modal"
        noFixedHeight
        noPadding
        modalSize="medium"
      >
        <ModalContent
          headerContent={
            <span data-test="cancel-multiple-contacts-modal-header">
              <FormattedMessage
                id={
                  hasFilters
                    ? 'contacts.cancel.multipleContactsModal.header.selectedContacts'
                    : 'contacts.cancel.multipleContactsModal.header.allContacts'
                }
              />
            </span>
          }
          footerContent={
            <>
              <Button
                dataTest="cancel-multiple-contacts-cancel"
                buttonType="normal"
                onClick={closeModal}
              >
                <FormattedMessage id="contacts.cancel.multipleContactsModal.cancelButton" />
              </Button>
              <Button
                dataTest="cancel-multiple-contacts-confirm"
                buttonType="warning"
                onClick={onConfirm}
              >
                <FormattedMessage id="contacts.cancel.multipleContactsModal.confirmButton" />
              </Button>
            </>
          }
        >
          <div data-test="cancel-multiple-contacts-modal-message">
            <MarkdownContent
              contentKey={getModalMessageKey(stats)}
              extraValues={{
                count: stats.all.toString(),
                inProgressCount: stats.in_progress.toString(),
                createdCount: stats.created.toString(),
              }}
              className={styles.message}
            />
            <MarkdownContent
              contentKey="contacts.cancel.multipleContactsModal.info"
              className={styles.info}
            />
          </div>
        </ModalContent>
      </Modal>
    )
  );
}

function getModalMessageKey(stats: CancelMultipleContactsStats) {
  if (stats.created > 0 && stats.in_progress > 0) {
    return 'contacts.cancel.multipleContactsModal.message.contacts';
  } else if (stats.created > 0) {
    return 'contacts.cancel.multipleContactsModal.message.createdContacts';
  } else if (stats.in_progress > 0) {
    return 'contacts.cancel.multipleContactsModal.message.inProgressContacts';
  }
}
