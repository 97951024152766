import {useEffect, useRef, useState} from 'react';
import { HttpClient } from '../utils/HttpClient';
import moment from 'moment';
import { SupportedDictionary } from './model';

const useIntegration = ({
  botId,
  startDate,
  endDate,
  timeFrom,
  timeTo,
  conditions,
  integration,
}: {
  botId: string;
  startDate: string;
  endDate: string;
  timeFrom: number;
  timeTo: number;
  conditions: { option: any, values: any[] }[];
  integration: {
    name: string;
    system: string;
    supportedDictionaries: SupportedDictionary[];
  };
}) => {
  const cancellable = useRef(null);
  const [doctors, setDoctors] = useState(null);
  const [clinics, setClinics] = useState(null);
  const [practices, setPractices] = useState(null);
  const [services, setServices] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [isLoadingContacts, setIsLoadingContacts] = useState(false);

  const reformat = (record) => ({ id: record.id, name: record.entity });

  const fetchDoctors = async () => {
    try {
      const { data } = await HttpClient.get({
        path: `/bots/${botId}/integration/doctors`,
      });
      setDoctors(data.doctors.map(reformat));
    } catch (err) {
      // No doctors, do nothing
    }
  };

  const fetchClinics = async () => {
    try {
      const { data } = await HttpClient.get({
        path: `/bots/${botId}/integration/clinics`,
      });
      setClinics(data.clinics.map(reformat));
    } catch (err) {
      // No clinics, do nothing
    }
  };

  const fetchPractices = async () => {
    try {
      const { data } = await HttpClient.get({
        path: `/bots/${botId}/integration/practices`,
      });
      setPractices(data.practices.map(reformat));
    } catch (err) {
      // No practices, do nothing
    }
  };

  const fetchServices = async () => {
    try {
      const { data } = await HttpClient.get({
        path: `/bots/${botId}/integration/services`,
      });
      setServices(data.services.map(reformat));
    } catch (err) {
      // No services, do nothing
    }
  };

  const fetchContacts = async () => {
    if (cancellable.current) {
      cancellable.current.cancel();
    }

    if (!startDate || !endDate || !timeFrom || !timeTo) {
      setContacts(null);
      return;
    }

    setIsLoadingContacts(true);
    try {
      cancellable.current = HttpClient.get({
        path: `/bots/${botId}/integration/contacts`,
        params: {
          startDateTime: moment(startDate).startOf('day').add(timeFrom, 'minutes').format('YYYY-MM-DDTHH:mm:ss.SSS'),
          endDateTime: moment(endDate).startOf('day').add(timeTo, 'minutes').format('YYYY-MM-DDTHH:mm:ss.SSS'),
          ...conditions
            .filter(condition => condition.option && condition.values.length > 0)
            .reduce((acc, condition) => ({
              ...acc,
              [condition.option.id.toLowerCase()]: [...(acc[condition.option.id.toLowerCase()] || []), ...condition.values.map(v => v.id)],
            }), {}),
        },
      });
      const { data } = await cancellable.current;
      setContacts(data.contacts);
    } catch (err) {
      setContacts([]);
    }
    setIsLoadingContacts(false);
  };

  const toggleContactStatus = (id: string) => {
    setContacts((state) => state.map((contact) => {
      if (contact.additionalData['id wizyty'] !== id) {
        return contact;
      }

      return {
        ...contact,
        excluded: !contact.excluded,
      };
    }));
  };

  useEffect(() => {
    if (
      !integration ||
      !integration.supportedDictionaries ||
      integration.supportedDictionaries.length === 0
    ) {
      return;
    }

    if (
      integration.supportedDictionaries.includes(SupportedDictionary.DOCTORS)
    ) {
      fetchDoctors();
    }

    if (
      integration.supportedDictionaries.includes(SupportedDictionary.CLINICS)
    ) {
      fetchClinics();
    }

    if (
      integration.supportedDictionaries.includes(SupportedDictionary.PRACTICES)
    ) {
      fetchPractices();
    }

    if (
      integration.supportedDictionaries.includes(SupportedDictionary.SERVICES)
    ) {
      fetchServices();
    }
  }, [integration]);

  return {
    integration,
    doctors,
    clinics,
    practices,
    services,
    fetchContacts,
    contacts,
    toggleContactStatus,
    isLoadingContacts,
  };
}

export default useIntegration;
