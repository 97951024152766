import React from 'react';

import { IconProps } from '../iconTypes';

export function StatisticsPlusIcon(props: IconProps) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.animationClass}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0C19.4477 0 19 0.447714 19 0.999999V19H1C0.447715 19 0 19.4477 0 20C0 20.5523 0.447714 21 0.999999 21H19V39C19 39.5523 19.4477 40 20 40C20.5523 40 21 39.5523 21 39V21H39C39.5523 21 40 20.5523 40 20C40 19.4477 39.5523 19 39 19H21V1C21 0.447715 20.5523 0 20 0Z"
        fill="#DCDCE5"
      />
    </svg>
  );
}
