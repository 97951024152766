import React from 'react';

import {IconProps} from './iconTypes';

export function MinusIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H24V24H0z"/>
                <circle cx="10" cy="10" r="10" className={props.animationClass} fill="#3C38BC" transform="translate(2 2)"/>
                <path fill="#FFF" fillRule="nonzero" d="M15.75 11.4c.331 0 .6.269.6.6 0 .298-.218.546-.503.592l-.097.008h-7.5c-.331 0-.6-.269-.6-.6 0-.298.218-.546.503-.592l.097-.008h7.5z"/>
            </g>
        </svg>
    );
}
