import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { entitiesSelectors as entitiesSelector } from '../../../../selectors/entitiesSelectors';
import { useCurrentBot } from '../../../../utils/CurrentBot';
import { fetchEntities } from '../../../store/entities/actions';

export const useEntities = () => {
  const currentBot = useCurrentBot();

  const dispatch = useDispatch();
  const { isLoading, isReady, data } = useSelector(entitiesSelector);

  useEffect(() => {
    dispatch(fetchEntities.request({ id: currentBot.id }));
  }, [currentBot]);

  return {
    isLoading: isLoading,
    isReady: isReady,
    entities: data,
  };
};
