import * as React from 'react';

import {Message} from '../model';

import {MessageComponent} from './messages/Message';
import {StepElementProps} from './StepElementProps';
import {useContext} from 'react';
import {SurveyContext, SurveyContextType} from '../SurveyCreator';

type TypeProps = { id: string, type: 'end', message: Message };
export const EndQuestionMessage = ({question, onChange}: StepElementProps<TypeProps>) => {
  const { questions: { withError } } = useContext<SurveyContextType>(SurveyContext);

  const setMessage = (message: Message) => {
    onChange({
      ...question,
      message
    });
  };

  return (
    <MessageComponent
      message={question.message}
      dataTestPrefix={'question'}
      labelId={'survey-creator.statementLabel'}
      onChange={setMessage}
      errors={withError(question.id, 'message')}
    />
  );
};
