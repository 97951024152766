import * as React from 'react';

import {StepElementProps} from './StepElementProps';
import {ToggleWithLabel} from './ToggleWithLabel';

type Props = { joinNumbers?: boolean };
export const JoinNumbers = ({question, onChange}: StepElementProps<Props>) => {
  const setJoinNumbers = () => onChange({...question, joinNumbers: !question.joinNumbers});
  return (
    <ToggleWithLabel
      dataTest={'join-numbers'}
      labelId={'survey-creator.joinNumbers'}
      value={!!question.joinNumbers}
      onToggle={setJoinNumbers}
    />
  );
};
