import _ from 'lodash';
import React from 'react';

import { AnswerComponentProps } from '../../../../components/AnswersList/AnswersList';
import { DefaultAnswerComponent } from '../../../../components/AnswersList/DefaultAnswerComponent';
import { InputWithLabel } from '../../../../components/InputWithLabel/InputWithLabel';

import styles from './EntityAnswerComponent.pcss';

export const EntityAnswerComponent: React.FC<AnswerComponentProps> = (props) => 
  <DefaultAnswerComponent {...props}>
    <InputWithLabel value={props.answer.key} onChange={_.noop} labelId={'survey-creator.entityAnswerId'}
      dataTest={'answer-key'} className={styles.answerKeyInput} disabled />
  </DefaultAnswerComponent>
