import * as React from 'react';
import {useContext} from 'react';

import {SurveyContext} from '../SurveyCreator';

import styles from './IntentOptions.pcss';
import {DeleteOption} from './optionsMenu/DeleteOption';
import {EditOption} from './optionsMenu/EditOption';
import {GroupOption} from './optionsMenu/GroupOption';
import {OptionsComponent} from './optionsMenu/Options';
import {RemoveFromGroupOption} from './optionsMenu/RemoveFromGroupOption';
import {RenderOptionsParams} from './pill/Pill';

type Props = {
    onDelete?: (event: any) => any;
    onAssignGroup: (group: string) => any;
    onRemoveGroup: () => any;
} & RenderOptionsParams;

export const IntentOptionsComponent = ({onDelete, onEditStart, onAssignGroup, onRemoveGroup}: Props) => {
    const {intents} = useContext(SurveyContext);
    const options = [
        <DeleteOption key={'delete-option'} onDelete={onDelete}/>,
        <EditOption key={'edit-option'} onClick={onEditStart}/>,
        ...(onRemoveGroup ? [<RemoveFromGroupOption key={'remove-from-group-option'} onClick={onRemoveGroup}/>] : []),
        <GroupOption key={'group-option'} onAssignGroup={onAssignGroup} items={intents.groups()}/>,
    ];

    return (
        <OptionsComponent
            iconContainerClass={styles.options}
            options={options}/>
    )
};
