import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Modal } from '../../components/Modal/Modal';
import { ModalContent } from '../../components/ModalContent/ModalContent';
import { TextLabel } from '../../components/Label';
import { TranscriptItemSMS } from '../models/Transcript';
import { StepLinkExpired } from './StepLinkExpired';
import styles from './StepLineDetails.pcss';

type Props = {
  onClose: () => void;
  line: TranscriptItemSMS;
  moduleLink: string;
  isStepLinkExpired: boolean | undefined;
};

export function SMSStepLineDetails({
  line,
  onClose,
  moduleLink,
  isStepLinkExpired,
}: Props) {
  const intl = useIntl();

  return (
    <Modal
      modalSize="medium"
      dataTest="sms-step-details"
      noPadding
      noFixedHeight
    >
      <ModalContent
        headerContent={
          <span data-test="sms-step-details-header">{line.stepName}</span>
        }
        onClose={onClose}
        closeButtonDataTest="sms-step-details-close"
        displayBottomSeparator={false}
      >
        <div className={styles.content}>
          <div className={styles.entry}>
            <TextLabel
              className={styles.stepEntry}
              label={intl.formatMessage({
                id: 'conversations.sms.modal.stepLink',
              })}
            />
            {isStepLinkExpired ? (
              <StepLinkExpired />
            ) : (
              <Link
                to={`${moduleLink}/${line.stepId}`}
                target="_blank"
                data-test="sms-step-details-step-link"
                className={styles.stepLink}
              >
                {line.stepName}
              </Link>
            )}
          </div>
          <div className={styles.entry}>
            <TextLabel
              className={styles.stepEntry}
              label={intl.formatMessage({
                id: 'conversations.sms.modal.from',
              })}
            />
            <span data-test="sms-step-details-from" data-sensitive="true">
              {line.from}
            </span>
          </div>
          <div className={styles.entry}>
            <TextLabel
              className={styles.stepEntry}
              label={intl.formatMessage({
                id: 'conversations.sms.modal.to',
              })}
            />
            <span data-test="sms-step-details-to" data-sensitive="true">
              {line.to}
            </span>
          </div>
          <div className={styles.entry}>
            <TextLabel
              className={styles.stepEntry}
              label={intl.formatMessage({
                id: 'conversations.sms.modal.text',
              })}
            />
            <span data-test="sms-step-details-text" data-sensitive="true">
              {line.text}
            </span>
          </div>
          <div className={styles.entry}>
            <TextLabel
              className={styles.stepEntry}
              label={intl.formatMessage({
                id: 'conversations.sms.modal.provider',
              })}
            />
            <span data-test="sms-step-details-provider" data-sensitive="true">
              {line.provider}
            </span>
          </div>
          <div className={styles.provider}>
            <TextLabel
              className={styles.stepEntry}
              label={intl.formatMessage({
                id: 'conversations.sms.modal.status',
              })}
            />
            <span data-test="sms-step-details-status" data-sensitive="true">
              {line.status}
            </span>
          </div>
          {line.errorMessage && (
            <div className={styles.entry}>
              <TextLabel
                className={styles.stepEntry}
                label={intl.formatMessage({
                  id: 'conversations.sms.modal.errorMessage',
                })}
              />
              <span
                data-test="sms-step-details-error-message"
                data-sensitive="true"
              >
                {line.errorMessage}
              </span>
            </div>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
}
