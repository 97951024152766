import classNames from 'classnames';
import {default as React, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {Button} from '../components/Button/Button';
import {Input} from '../components/Input';
import {Modal} from '../components/Modal/Modal';
import {ToggleWithLabel} from '../surveyCreator/components/ToggleWithLabel';

import styles from './BotEditPopup.pcss';
import {BotTags} from './BotTags';
import {Tag} from './BotComponent';
import {useSelector} from 'react-redux';
import {currentUserRoles} from '../selectors';
import {UserRole} from '../utils/CurrentUser';
import {HttpClient} from '../utils/HttpClient';

type Props = {
    botLanguage: string;
    botClient: string;
    currentBotName: string;
    currentBotVisibleForClient: boolean;
    onCancel: () => void;
    currentBotTags: string[];
    predefinedTags: Tag[];
    onSuccess: () => void;
    botId: string;
};

export type EditBasicBotSettingsRequest = {
    name: string;
    customerVisible?: boolean;
    tags?: string[];
}

export const BotEditPopup = ({currentBotVisibleForClient, currentBotName, currentBotTags, botLanguage, botClient, botId, onSuccess, onCancel, predefinedTags}: Props) => {
    const isAdmin = useSelector(currentUserRoles).includes(UserRole.ADMIN);
    const intl = useIntl();
    const [botName, setBotName] = useState(currentBotName);
    const [visibleForClient, toggleVisibleForClient] = useState(currentBotVisibleForClient);
    const [botTags, setBotTags] = useState<string[]>(currentBotTags)
    const [tagError, setTagError] = useState<string | undefined>(undefined)

    const performUpdateRequest = () => HttpClient.patch({
        path: `/bots/${botId}`,
        body: isAdmin ?
            {
                name: botName,
                customerVisible:  visibleForClient,
                tags: botTags,
            } :
            {
                name: botName
            }
    }).then(onSuccess)
        .catch((error) =>  {
            if ((error.response?.data || []).some((err: {error: 'TAG_LIST_CONTAINS_INVALID_TAGS', field: string}) => {
                return err.error === 'TAG_LIST_CONTAINS_INVALID_TAGS'
            })
            ) {
                setTagError(intl.formatMessage({id: 'dashboard.addBotPopup.invalidTags'}))
            }
        });


    return (
        <Modal position={'top'} dataTest={'edit-bot-popup'}
               className={isAdmin ? styles.modal__admin : styles.modal__notAdmin}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <FormattedMessage id={'dashboard.editBotPopup.title'}/>
                </div>
                <div className={styles.body}>
                    <div className={styles.bodyRow}>
                        <div className={styles.botName}>
                            <div className={styles.botNameLabel}>
                                <FormattedMessage id={'dashboard.editBotPopup.botName'}/>
                            </div>
                            <Input
                                dataTest="bot-name-input"
                                className={styles.botNameInput}
                                value={botName}
                                onChange={setBotName}
                            />
                        </div>

                        <div className={styles.newBotLanguage}>
                            <div className={styles.newBotLanguageLabel}>
                                <FormattedMessage id={'dashboard.editBotPopup.botLanguage'}/>
                            </div>
                            <div className={styles.newBotLanguageValue}>
                                {intl.messages[`dashboard.addBotPopup.languages.${botLanguage}`] as string}
                            </div>
                        </div>
                    </div>
                    <div className={styles.client}>
                        <div className={styles.clientLabel}>
                            <FormattedMessage id={'dashboard.editBotPopup.client'}/>
                        </div>
                        <div className={styles.clientValue}>
                            {botClient}
                        </div>
                    </div>
                    {
                        isAdmin && (
                            <>
                                <BotTags
                                    tags={botTags}
                                    setTags={setBotTags}
                                    tagError={tagError}
                                    setTagError={(message: string) => setTagError(message)}
                                    predefinedTags={predefinedTags}
                                />

                                <div className={styles.toggle}>
                                    <ToggleWithLabel value={visibleForClient}
                                                     onToggle={() => toggleVisibleForClient(!visibleForClient)}
                                                     labelId={'dashboard.editBotPopup.visibleForClient'}
                                                     dataTest={'visible-for-client-toggle'}
                                    />
                                </div>
                            </>
                        )
                    }
                </div>
                <div className={styles.bottom}>
                    <div className={classNames(styles.buttons, isAdmin ? undefined : styles.buttons__notAdmin)}>
                        <Button buttonType="normal" onClick={onCancel} translateText="dashboard.editBotPopup.cancel"
                                dataTest={'cancel-edit-bot-button'}/>
                        <Button
                            disabled={Boolean(tagError)}
                            onClick={performUpdateRequest}
                            dataTest={'send-edit-bot-request-button'}
                            translateText="dashboard.editBotPopup.update"
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};
