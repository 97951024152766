export type ContactStatus = 'created' | 'in_progress' | 'survey_complete' | 'survey_incomplete' | 'cancelled';
export type InteractionTransport = 'sms' | 'call' | 'http';
export type InteractionDirection = 'inbound' | 'outbound';
export type InteractionStatus = 'pending' | 'active' | 'processed' | 'failed' | 'cancelled';
type SmsResult = 'delivered' | 'undelivered' | 'received' | 'all_required_questions_answered';
type HttpResult = 'failed' | 'succeeded';
type CallResult = 'voicemail' | 'all_required_questions_answered' | 'missing_required_question' | 'not_answered' | 'postpone_request' | 'stop_calling';
export type InteractionResult = SmsResult | CallResult | HttpResult;

export function isOfContactStatus(value: string): value is ContactStatus {
    return ['created', 'in_progress', 'survey_complete', 'survey_incomplete', 'cancelled'].includes(value);
}

type Primitive = undefined | null | string | number | boolean;

export interface Contact {
    id: string;
    phoneNumber: string;
    lastInteraction?: string;
    currentInteraction?: string;
    followUpInteraction?: string;
    interactionsPerformed: number;
    variables?: Record<string, Primitive>;
    importDate: string;
    status: ContactStatus;
    interactions: Interaction[]
}
export interface Interaction {
    scheduledAt: string
    transport: InteractionTransport
    status: InteractionStatus
    direction: InteractionDirection
    finishedAt: string
    result: InteractionResult
    message?: string
}

export interface ContactToImport {
    phoneNumber: string;
    variables?: Record<string, Primitive>;
}

export interface ContactStatusStatistics {
    created: number;
    in_progress: number;
    survey_complete: number;
    survey_incomplete: number;
    cancelled: number;
}

export enum SupportedDictionary {
    'PRACTICES' = 'PRACTICES',
    'CLINICS' = 'CLINICS',
    'DOCTORS' = 'DOCTORS',
    'SERVICES' = 'SERVICES',
}

export interface Integration {
    name: string;
    system: string;
    supportedDictionaries: SupportedDictionary[];
}
