import _ from 'lodash';
import * as React from 'react';
import {useState} from 'react';
import {FormattedMessage} from 'react-intl';

import {Button} from '../../../../components/Button/Button';
import {BorderedElementWithLabel} from '../../../../components/ElementWithLabel/BorderedElementWithLabel';
import {Horizontally} from '../../../../components/layouts/Horizontally';
import {Vertically} from '../../../../components/layouts/Vertically';
import {TextArea} from '../../../../components/TextArea/TextArea';
import {processUserInputForIntent} from '../../../../utils/userInput';

import styles from './IntentTraining.pcss';

type Props = {
    onAdd: (sentences: string[]) => void
};

export const TrainingDataInput = ({onAdd}: Props) => {
    const [trainingData, setTrainingData] = useState('');

    const onClick = async () => {
        const sentences = processUserInputForIntent(trainingData)
        onAdd(sentences);
        setTrainingData('')
    }

    return <BorderedElementWithLabel labelId={'survey-creator.intentDetails.trainingDataHint'}>
        <Vertically>
            <TextArea dataTest="training-data-input"
                      value={trainingData}
                      onChange={setTrainingData}
            />
            <Horizontally layout={'end'}>
                <Button buttonType="primary"
                        type="button"
                        dataTest="enqueue-button"
                        onClick={onClick}
                        disabled={_.isEmpty(processUserInputForIntent(trainingData))}
                        className={styles.trainingButton}>
                    <FormattedMessage id={'survey-creator.intentDetails.addSentences'}/>
                </Button>
            </Horizontally>
        </Vertically>
    </BorderedElementWithLabel>
}