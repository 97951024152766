import React from 'react';

import {IconProps} from './iconTypes';

export function TextChatIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g transform="translate(-288 -656) translate(280 648) translate(8 8)">
                <path d="M0 0H32V32H0z"/>
                <path fill="#FFF" fillRule="nonzero" d="M16.738 4l.365.005c4.624.141 8.707 3.111 10.255 7.501 1.59 4.505.162 9.523-3.559 12.516-3.508 2.822-8.335 3.263-12.273 1.19l-.328-.18L5.14 27.92c-.639.304-1.304-.31-1.105-.95l.044-.112 2.885-6.064-.178-.325c-1.695-3.222-1.727-7.08-.066-10.337l.191-.36C8.896 6.205 12.659 3.994 16.738 4zm.002 1.593c-3.505-.005-6.734 1.891-8.437 4.954-1.703 3.063-1.611 6.81.24 9.785.143.23.159.518.042.763l-2.103 4.42 4.416-2.104c.204-.097.438-.102.643-.018l.12.06c3.48 2.166 7.947 1.896 11.14-.673 3.195-2.569 4.42-6.876 3.056-10.744-1.364-3.867-5.02-6.45-9.117-6.443zm4.474 11.849c.44 0 .796.357.796.797 0 .396-.289.724-.667.786l-.13.01H12.26c-.44 0-.796-.356-.796-.796 0-.396.289-.725.667-.787l.13-.01h8.954zm1.492-2.987c.44 0 .796.356.796.796 0 .396-.289.725-.667.787l-.129.01h-11.94c-.439 0-.795-.357-.795-.797 0-.396.288-.724.667-.786l.129-.01h11.94zm-1.492-2.988c.44 0 .796.357.796.797 0 .396-.289.725-.667.786l-.13.01H12.26c-.44 0-.796-.356-.796-.796 0-.396.289-.724.667-.786l.13-.01h8.954z"/>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
}
