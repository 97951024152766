import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom'

import { Box } from '../../../components/Box/Box';
import { ElementWithLabel } from '../../../components/ElementWithLabel/ElementWithLabel';
import { IconButton } from '../../../components/IconButton/IconButton';
import { IconWithLabel } from '../../../components/IconWithLabel/IconWithLabel';
import { Row } from '../../../components/Row/Row';
import { Value } from '../../../components/Select/Select';
import { SelectWithLabel } from '../../../components/SelectWithLabel/SelectWithLabel';
import { TooltipComponent } from '../../../components/Tooltip/Tooltip';
import { StartpointIcon, DeleteIcon } from '../../../icons';
import { EntityButtonSmallIcon } from '../../../icons/EntityButtonSmallIcon';
import { AssociatedAnswer, AssociatedAnswerReactionType } from '../../model';
import { Variables } from '../variables/Variables';

import { useVariableCreation } from '../variables/useVariableCreation';
import { useCreatorRouter } from '../../useCreatorRouter';
import {EntityButtonIcon} from '../../../icons/EntityButtonIcon';

import styles from './AssociatedAnswerComponent.pcss';

type AssociatedAnswerComponentProps = {
  associatedAnswer: AssociatedAnswer;
  onChange: (newAssociatedAnswer: AssociatedAnswer) => void;
  onDelete: (id: string) => void;
  hasReaction?: boolean;
  systemAndNerEntitiesKeys: string[];
}

export const AssociatedAnswerComponent = ({
  associatedAnswer,
  onChange,
  onDelete,
  hasReaction = true,
  systemAndNerEntitiesKeys
}: AssociatedAnswerComponentProps) => {

  const intl = useIntl();
  const updateVariable = (id: string) => onChange({
    ...associatedAnswer,
    variableId: id
  });

  const {getUrlForEntity} = useCreatorRouter();
  const updateReaction = ({ id }: { id: AssociatedAnswerReactionType }) => onChange({
    ...associatedAnswer,
    reaction: id
  });

  const { selectedOption, options, handleSelect, handleRename, handleCreate } = useVariableCreation(updateVariable, associatedAnswer.variableId)

  const reactionOptions: Value[] = [{
    id: 'no_reaction',
    name: intl.messages['survey-creator.associatedAnswerReaction.noReaction'] as string
  }, {
    id: 'continue',
    name: intl.messages['survey-creator.associatedAnswerReaction.continue'] as string
  }];

  return (
    <Box
      border
      data-test={`associated-answer`}
      className={styles.associatedAnswer}
    >
      <Row className={styles.iconRow}>
        <IconButton
          dataTest="delete-button"
          Icon={DeleteIcon}
          hoverType="warning"
          className={styles.deleteButton}
          onClick={() => onDelete(associatedAnswer.id)}
        />
      </Row>

      <div className={styles.entityGrid}>
        <ElementWithLabel noPadding inner labelId={'survey-creator.associatedAnswerEntityLabel'}
          className={styles.entity}>
          <IconWithLabel
            dataTest="entity"
            Icon={EntityButtonSmallIcon}
            label={`${intl.messages[`survey-creator.intentDetails.entities.${associatedAnswer.entity}`] || associatedAnswer.entity}`}
            color={'cyan'}
          >
            {systemAndNerEntitiesKeys.includes(associatedAnswer.entity) ? (
              <TooltipComponent tooltipText={intl.messages['survey-creator.associatedAnswerSystemEntity'] as string}>
                <span className={styles.entitySpan}>
                  <EntityButtonIcon animationClass={styles.systemEntityIcon} />
                </span>
              </TooltipComponent>
            ) : (
              <TooltipComponent tooltipText={intl.messages['survey-creator.associatedAnswerGoToEntity'] as string}>
                <Link
                  data-test="entity-link"
                  to={getUrlForEntity(associatedAnswer.entity)}
                  target="__blank"
                  rel="noopener noreferrer"
                >
                  <span className={styles.entitySpan}>
                    <StartpointIcon animationClass={styles.entityIcon} />
                  </span>
                </Link>
              </TooltipComponent>
            )}
          </IconWithLabel>
        </ElementWithLabel>
        {hasReaction && (
          <SelectWithLabel
            dataTest="reaction"
            noPadding
            inner
            labelId={'survey-creator.associatedAnswerReaction'}
            options={reactionOptions}
            onChange={updateReaction}
            value={reactionOptions.find(option => option.id === associatedAnswer.reaction)}
          />
        )}
        <ElementWithLabel noPadding inner labelId={'survey-creator.associatedAnswerVariable'} className={styles.entity}
        dataTest="variable-element">
          <Variables
            selectedOption={selectedOption}
            options={options}
            handleSelect={handleSelect}
            handleRename={handleRename}
            handleCreate={handleCreate}
            fluid
          />
        </ElementWithLabel>
      </div>
    </Box>
  )
}