import React, { useState } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Synonym } from '../../surveyCreator/model';
import { StandardInitialProps } from '../types';
import styles from './ChipsListComponent.pcss';
import { SynonymComponent } from './synonym';
import { useSynonymsClipboard } from './useSynonymsClipboard';
import cx from 'classnames';

type SynonymsChipsListComponentProps = StandardInitialProps & {
  items: Synonym[];
  showElementsNumber: number;
  chipsItemClassName?: string;
  onItemClick?: (item: Synonym) => void;
}

export const SynonymsChipsListComponent: React.FC<SynonymsChipsListComponentProps> = ({
  className,
  items,
  showElementsNumber,
  chipsItemClassName,
  onItemClick
}) => {
  const [showAll, setShowAll] = useState(false);
  const {onCopy} = useSynonymsClipboard();

  const collapseOrExpandButton = () => {
    if (items.length > showElementsNumber) {
      return !showAll ? (
        <div className={styles.clickableChips} onClick={() => setShowAll(true)}
          data-test={'chips-list-expand-button'}>
          {`+ ${items.length - showElementsNumber}`}
        </div>
      ) : (
        <div className={styles.clickableChips} onClick={() => setShowAll(false)}
          data-test={'chips-list-collapse-button'}>
          <FormattedMessage id={'survey-creator.dictionary.chipsGlobalLabel.hide'} />
        </div>
      )
    }

    return undefined;
  }
  return (
    <div data-test="chips-list-component" onCopy={onCopy}
      className={classnames(className, styles.container, styles.container__not_editable)}>
      {
        (showAll ? items : items.slice(0, showElementsNumber)).map((item) =>
          <SynonymComponent
            key={item.verbatim}
            synonym={item}
            mode="readonly"
            onClick={() => onItemClick && onItemClick(item)}
            className={cx(chipsItemClassName, styles.synonym)}
          />)
      }
      {
        collapseOrExpandButton()
      }
    </div>
  );
}
