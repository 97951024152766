import React, {useState} from 'react';

type DialerContextType = {
    hasSchedule: boolean,
    setHasSchedule: (hasSchedule: boolean) => void
}

const DialerContext = React.createContext<DialerContextType | null>(null);

function DialerContextProvider(props: any) {
    const [hasSchedule, setHasSchedule] = useState<boolean>(false);

    return <DialerContext.Provider value={{ hasSchedule, setHasSchedule }} {...props} />;
}

const useDialerContext = () => React.useContext<DialerContextType>(DialerContext);

export { DialerContextProvider, useDialerContext };
