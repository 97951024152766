import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import { SurveyCreator } from './SurveyCreator';

export const SurveyCreatorRouter: React.FC = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        path={[
          `${match.path}/:tabName/modules/:blockId?/:stepId?`,
          `${match.path}/:tabName/fallback/:fallbackStepId?`,
          `${match.path}/:tabName/silence-fallback/:silenceFallbackStepId?`,
          `${match.path}/:tabName/callbacks/:callbackId?/:callbackStepId?`,
          `${match.path}/:tabName/intents/:intentId?`,
          `${match.path}/:tabName/:entity?/:entityDetailsTab?`,
        ]}
        component={SurveyCreator}
      />
      <Route path={match.path}>
        <Redirect to={`${match.url}/scenario`} />
      </Route>
    </Switch>
  );
};


