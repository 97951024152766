import * as React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import useBranding from '../../hooks/useBranding/useBranding';

import styles from './HeaderLogo.pcss';

type HeaderLogoProps = {
  className?: string;
};

export function HeaderLogo({ className }: HeaderLogoProps) {
  const { logoSquare, name } = useBranding();

  return (
    <Link to={'/'}>
      <img
        src={logoSquare}
        className={classNames(styles.logo, className)}
        alt={name}
      />
    </Link>
  );
}
