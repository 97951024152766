import classNames from 'classnames';
import moment from 'moment';
import { report } from 'process';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { DownloadReportIcon } from '../../icons/DownloadReportIcon';
import { GenerateReportIcon } from '../../icons/GenerateReportIcon';

import styles from './ConversationsReport.pcss';
import { Report } from './model';

export function ConversationsReport(props: { report: Report; onClick(report: Report): void }) {
    const intl = useIntl();
    const reportTitle = intl.formatMessage(
        { id: 'conversations.reports.reportTitle' },
        { reportTitle: moment(props.report.creationDate).format(`${intl.messages['timestamp.format']}`) }
    );
    const conversationsLimitLabel = props.report.conversationsLimit ? intl.formatMessage(
        { id: 'conversations.reports.conversationsLimit' },
        { conversationsLimit: props.report.conversationsLimit }
    ) : undefined;

    const isPending = props.report.status === 'pending';

    return (
        <button
            data-test="conversations-report"
            data-test-id={props.report.id}
            key={props.report.id}
            disabled={isPending}
            className={styles.report}
            onClick={() => props.report.status === 'ready' && props.onClick(props.report)}
        >
            {
                !isPending
                    ? <div 
                    data-test="report-title"
                    data-test-status="ready"
                    className={styles.reportTitle}
                    >{reportTitle}</div>
                    : <div
                        data-test="report-title"
                        data-test-status="pending"
                        className={classNames(styles.status, isPending && styles.pending)}
                    >
                        <FormattedMessage id={`conversations.reports.${props.report.status}`} />
                    </div>
            }
            {conversationsLimitLabel &&
                <span
                    data-test="report-conversations-limit"
                    className={styles.conversationsLimit}
                >{conversationsLimitLabel}</span>
            }
            {
                isPending
                    ? <GenerateReportIcon />
                    : <DownloadReportIcon />
            }
        </button>
    );
}
