import * as React from 'react';
import {useContext, useLayoutEffect, useRef} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {animateScroll} from 'react-scroll';

import {SurveyContext, SurveyContextType} from '../SurveyCreator';
import {AddStepButton} from './AddStepButton';
import styles from './BlockDetails.pcss';
import {BlockPreview} from './BlockPreview';
import {VerticalSeparator} from './VerticalSeparator';
import {BlockQuestion} from '../model';
import {addOrdinalNumberToBlockQuestionName} from './utils';

const SCROLL_CONTAINER_ID = 'callback-preview-scroll-container';

const questionTypes = [
    'send_sms',
    'http',
    'send_email',
    'data_collection',
    'set_variable'
];

export const CallbackDetails = () => {
    const {callbacks, questions} = useContext<SurveyContextType>(SurveyContext);
    const currentBlock = callbacks.current;
    const newQuestionRef = useRef<string>();

    useLayoutEffect(() => {
        animateScroll.scrollToTop({
            duration: 0,
            containerId: SCROLL_CONTAINER_ID,
            smooth: true
        });
    }, [currentBlock]);

    if (!currentBlock) {
        return null;
    }

    const handleAdd = (questionToAdd: BlockQuestion) => {
        newQuestionRef.current = questionToAdd.id;

        questions.add(addOrdinalNumberToBlockQuestionName(questionToAdd, currentBlock && currentBlock.questions.length + 1));
    };

    return (
        <div className={styles.container} data-test={'survey-callback-details'}>
            <VerticalSeparator/>
            <Scrollbars renderView={(props: any) => <div {...props} id={SCROLL_CONTAINER_ID}/>}>
                <div className={styles.details}>
                    <BlockPreview
                        scope="callback"
                        blockId={currentBlock.id}
                        currentlySelectedBlock={currentBlock}
                        onBlockUpdated={callback => callbacks.update(callback.id, {steps: callback.questions})}
                    />
                </div>
            </Scrollbars>
            <div className={styles.buttonContainer}>
                <AddStepButton onAdd={handleAdd} disabled={!!questions.new} allowedQuestionTypes={questionTypes}
                               buttonLabel={'survey-creator.addCallbackStepButton'}/>
            </div>
            <VerticalSeparator/>
        </div>
    );
};
