import React from 'react';

import { HttpStep } from '../../model';
import {HttpHeadersComponent} from '../HttpHeadersComponent';
import {HttpMappingsComponent} from '../HttpMappingsComponent';
import {HttpStepDefinition} from '../HttpStepComponent';
import { HttpStepTimeoutComponent } from '../HttpStepTimeoutComponent';

import {TabFactory} from './question-component-factory-model';
import { StepTabType } from './types';

export const httpStepFactory: TabFactory<HttpStep> = {
  [StepTabType.General]: ({question, onChange}) => {
    return (
      <>
        <HttpStepDefinition question={question} onChange={onChange} />
        <HttpHeadersComponent question={question} onChange={onChange} />
        <HttpMappingsComponent question={question} onChange={onChange} />
        <HttpStepTimeoutComponent question={question} onChange={onChange} />
      </>
    );
  }
};
