import Graph from 'graphology';
import { topologicalSort } from 'graphology-dag/topological-sort';
import uuid from 'uuid';
import uniq from 'lodash/uniq';

export function calculateDependenciesGraph(
    variableIds: string[],
    variablesAssignments: Record<string, string[]> // [target, source[]]
) {
    const graph = buildInitGraph(variableIds, variablesAssignments)
    const cycles = getGraphCycles(graph)
    const graphWithoutCycles = eliminateCyclesFromGraph(graph.copy(), cycles);
    const graphWithDependantVariables = calculateDependantVariables(graphWithoutCycles.copy());
    return mapDependantVariables(graphWithDependantVariables)
}

function buildInitGraph(
    variableIds: string[],
    variablesAssignments: Record<string, string[]>
) {
    const graph = new Graph();

    variableIds.forEach(node => graph.addNode(node))
    Object.entries(variablesAssignments).forEach(([node, assigners]) => assigners.forEach(assigner => graph.addEdge(assigner, node)))

    return graph
}

function getGraphCycles(graph: Graph) {
    const cycles: string[][] = [];

    // Algorithm for detecting cycles relies on Tarjan's strongly connected components algorithm (bookkeeping implementation)
    // Reference: https://en.wikipedia.org/wiki/Tarjan%27s_strongly_connected_components_algorithm#Bookkeeping
    // Strongly connected components that contains more than one node are cycles in our case

    let index = 0;
    const stack: string[] = [];
    for (const node of graph.nodes()) {
        if (!graph.getNodeAttribute(node, 'index')) {
            strongconnect(node);
        }
    }

    function strongconnect(node: string) {
        graph.setNodeAttribute(node, 'index', index);
        graph.setNodeAttribute(node, 'lowlink', index);
        index++;
        stack.push(node);
        graph.setNodeAttribute(node, 'onStack', true);

        for (const successor of graph.outNeighbors(node)) {
            if (!graph.getNodeAttribute(successor, 'index')) {
                strongconnect(successor);
                graph.setNodeAttribute(node, 'lowlink', Math.min(graph.getNodeAttribute(node, 'lowlink'), graph.getNodeAttribute(successor, 'lowlink')));
            } else if (graph.getNodeAttribute(successor, 'onStack')) {
                graph.setNodeAttribute(node, 'lowlink', Math.min(graph.getNodeAttribute(node, 'lowlink'), graph.getNodeAttribute(successor, 'index')));
            }
        }

        if (graph.getNodeAttribute(node, 'lowlink') === graph.getNodeAttribute(node, 'index')) {
            let successor = stack.pop();
            const stronglyConnectedComponent: string[] = [successor];
            graph.setNodeAttribute(successor, 'onStack', false);
            while (successor !== node) {
                successor = stack.pop();
                graph.setNodeAttribute(successor, 'onStack', false);
                stronglyConnectedComponent.push(successor);
            }

            if (stronglyConnectedComponent.length > 1) {
                cycles.push(stronglyConnectedComponent)
            }
        }
    }

    return cycles;
}

function eliminateCyclesFromGraph(graph: Graph, cycles: string[][]): Graph {
    // { a: 1, b: 1, c: 2, d: 3 }
    const nodeToFakeNode: Record<string, string> = cycles.reduce((acc, cycle) => {
        const fakeNodeId = uuid();
        return {
            ...acc,
            ...(Object.fromEntries(cycle.map(node => [node, fakeNodeId]))),
        };
    }, {});

    // { 1: [a,b], 2: c, 3: d }
    const fakeNodeToNodes = Object.entries(nodeToFakeNode)
      .reduce((acc, [cycleNode, fakeNodeId]) => ({
        ...acc,
        [fakeNodeId]: [...(acc[fakeNodeId] ?? []), cycleNode],
    }), {});
    const flatCycles = cycles.flat();

    Object.entries(fakeNodeToNodes).forEach(([fakeNodeId, cycleNodes]) => {
       graph.addNode(fakeNodeId, { originalNodes: cycleNodes });
    });

    Array.from(graph.edgeEntries()).forEach(edge => {
        const isSourceInCycle = flatCycles.includes(edge.source);
        const isTargetInCycle = flatCycles.includes(edge.target);

        // variable is assigned to itself (cycle to itself)
        if (edge.source === edge.target) {
            graph.dropEdge(edge.source, edge.target);
            return;
        }

        // variable is not a part of any cycle and is not connected to any cycle
        if (!isSourceInCycle && !isTargetInCycle) {
            return;
        }

        graph.dropEdge(edge.source, edge.target);

        // hasEdge checks below prevent from creating multiple edges from the same nodes (multigraph), which makes the graph library throw an error
        // example: d = a, d = b, cycle: a, b, created fakeNode has 2 edges to d
        
        // variable from a cycle is assigned to another variable
        if (isSourceInCycle && !isTargetInCycle && !graph.hasEdge(nodeToFakeNode[edge.source], edge.target)) {
            graph.addEdge(nodeToFakeNode[edge.source], edge.target);
        }

        // variable is assigned to a variable from a cycle
        if (!isSourceInCycle && isTargetInCycle && !graph.hasEdge(edge.source, nodeToFakeNode[edge.target])) {
            graph.addEdge(edge.source, nodeToFakeNode[edge.target]);
        }
    });

    flatCycles.forEach(cycleNodeId => {
        graph.dropNode(cycleNodeId);
    });

    return graph;
}

function calculateDependantVariables(graph: Graph) {
    // There are on variables setting value based on other variables
    if (graph.nodes().length === 0) {
        return graph;
    }
    
    const sortedNodes = topologicalSort(graph);

    sortedNodes.forEach((node) => {
        // I. Setting dependencies for the current node
        const dependantVariablesFromParent = graph.getNodeAttribute(node, 'dependantVariables') ?? [];
        const currentNodeDependencies = graph.getNodeAttribute(node, 'originalNodes') ?? [node];
        const currentNodeDependantVariables = uniq([...dependantVariablesFromParent, ...currentNodeDependencies]);
        graph.setNodeAttribute(node, 'dependantVariables', currentNodeDependantVariables);

        // II. Setting dependencies for outneighbors
        graph.outNeighbors(node).forEach(neighborNode => {
            const neighbourDependantVariables = graph.getNodeAttribute(neighborNode, 'dependantVariables') ?? [];
            // existing neighbor dependencies + current node dependencies - we're passing dependencies to outneighbors
            const neighbourDependantVariablesIncludingCurrentNode = uniq([...neighbourDependantVariables, ...currentNodeDependantVariables]);
            graph.setNodeAttribute(neighborNode, 'dependantVariables', neighbourDependantVariablesIncludingCurrentNode);
        });
    });

    return graph;
}

function mapDependantVariables(graph: Graph): Record<string, string[]> {
    return Array.from(graph.nodeEntries()).reduce((acc, nodeEntry) => {
        if (nodeEntry.attributes.originalNodes) {
            return {
                ...acc,
                ...(Object.fromEntries(
                  nodeEntry.attributes.originalNodes.map(node => ([node, nodeEntry.attributes.dependantVariables])),
                )),
            };
        }

        return {
            ...acc,
            [nodeEntry.node]: nodeEntry.attributes.dependantVariables,
        };
    }, {});
}
