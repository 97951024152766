import { createBrowserHistory } from 'history';
import { Action, applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { persistReducer, persistStore } from 'redux-persist';
import { createFilter } from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';

import { rootEpic } from './epics/epics';
import { reducers, RootState } from './reducers/reducers';

const epicMiddleware = createEpicMiddleware<Action, Action, RootState>({
    dependencies: {
        history: createBrowserHistory()
    }
});

const saveCurrentBotFilter = createFilter(
    'bots',
    ['current']
);

export const getStore = () => {
    const composeEnhancers =
        process.env.NODE_ENV !== 'production' &&
            typeof window === 'object' &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
            : compose;

    const enhancer = composeEnhancers(
        applyMiddleware(
            epicMiddleware
        )
    );


    const persistedReducer = persistReducer({
        key: 'talkie-panel',
        storage,
        whitelist: ['bots'],
        transforms: [saveCurrentBotFilter]
    }, reducers);

    const store = createStore(persistedReducer, {}, enhancer);

    persistStore(store);

    epicMiddleware.run(rootEpic);
    return store;
};