import uniqBy from 'lodash/uniqBy';
import some from 'lodash/some';
import {
    BlockQuestion,
    DictionaryVariable,
    QuestionType,
    SetVariableStep,
    SurveyDefinitionVariable,
    WordQuestion,
    isHttpStep,
    EntityAnswer,
    SurveyIntent,
    isQuestionWithAnswer,
    isSetVariableStep,
    isWordStep,
    isFunctionStep,
    ExecutableFunctionDefinition
} from '../../../model'
import { calculateDependenciesGraph } from './dependenciesGraph';
import { PrimitiveVariableType, VariableType } from '../Variables.utils';
import _ from 'lodash';

export type ResolvedVariablePrimitiveType = { type: PrimitiveVariableType }

export type ResolvedVariableDictionaryType = { type: VariableType.DICTIONARY, id: string, key: string }[]

export type ResolvedVariableType =
    | ResolvedVariablePrimitiveType
    | ResolvedVariableDictionaryType

export type VariableResolverReturnType = Record<string, ResolvedVariableType>

type Entity = {
    name: string,
    values: EntityAnswer[]
}

type VariableTypesToBeResolved = (
    | { type: PrimitiveVariableType }
    | { type: VariableType.DICTIONARY, id: string, key: string }
)[]

const variableRegexp = /\$\{([^\\}]+)\}/gi
const onlyVariableRegexp = /^\$\{([^\\}]+)\}$/gi

export const isVariableDictionaryType = (variableType: ResolvedVariableType): variableType is ResolvedVariableDictionaryType => Array.isArray(variableType)



const mapVariableToConditionSource = (it: SurveyDefinitionVariable): { id: string, type: ResolvedVariableType } => ({
    id: it.id,
    type: 'values' in it
        ? it.values.map(v => ({ id: v, key: v, type: VariableType.DICTIONARY } as const))
        : { type: it.type as PrimitiveVariableType },
})

export function variableResolver({
    steps, variables, entities, intents, executableFunctions
}: {
    steps: BlockQuestion[],
    variables: SurveyDefinitionVariable[],
    entities: Entity[],
    intents: SurveyIntent[],
    executableFunctions: ExecutableFunctionDefinition<any, any>[]
}): VariableResolverReturnType {
    const [nonSystemVariables, otherVariables] = _.partition(variables, (item) => item.origin !== 'system');
    const sourcesFromVariables: VariableResolverReturnType = otherVariables
        .filter(v => (v.type !== VariableType.STRING || ['phoneNumber', 'followUpInteraction'].includes(v.id)))
        .map(mapVariableToConditionSource)
        .reduce((acc, sources) => ({
            ...acc,
            [sources.id]: sources.type,
        }), {});

    const typesMap = stepsVariablesResolver({
        steps,
        variables: nonSystemVariables,
        entities,
        intents,
        executableFunctions,
    })

    return { ...typesMap, ...sourcesFromVariables };
}

export function stepsVariablesResolver({
    steps, variables, entities, intents, executableFunctions
}: {
    steps: BlockQuestion[],
    variables: SurveyDefinitionVariable[],
    entities: Entity[]
    intents: SurveyIntent[]
    executableFunctions: ExecutableFunctionDefinition<any, any>[]
}) {
    const existingVariableIds = variables.map(v => v.id)
    const setVariableSteps = steps.filter(isSetVariableStep)

    const { variableIds, variablesAssignments } = parseVariableDependencies(setVariableSteps, existingVariableIds)
    const variablesDependencyGraph = calculateDependenciesGraph(variableIds, variablesAssignments)

    const typesMap = resolveTypes({
        variables: variables,
        variablesDependencyGraph,
        allSteps: steps,
        entities,
        intents,
        executableFunctions,
    });

    return typesMap;
}

function parseVariableDependencies(
    setVariableSteps: SetVariableStep[],
    existingVariableIds: string[],
) {
    const variablesWithVariablesAssigned = setVariableSteps.filter(v => v.value.match(variableRegexp))

    const variablesAssignments = variablesWithVariablesAssigned
        .map(v => ({
            id: v.variable,
            // ${a} = "hello ${b} + ${c}" - b and c are assigners
            assigners: retrieveVariableIdsFromSetVariableStepValue(v.value, existingVariableIds)
        }))
        .filter(el => el.assigners.length > 0)
        .reduce((acc, el) => {
            if (acc[el.id]) {
                acc[el.id] = [...new Set([...acc[el.id], ...el.assigners])]
            } else {
                acc[el.id] = el.assigners
            }
            return acc
        }, {} as { [key: string]: string[] })

    const variableIds = [
        ...new Set(
            Object.entries(variablesAssignments)
                .map(([key, values]) => ([key, ...values]))
                .flat()
        )
    ]

    return { variablesAssignments, variableIds }
}

function retrieveVariableIdsFromSetVariableStepValue(value: string, existingVariableIds: string[]) {
    const occurrence = value.match(variableRegexp).map(el => el.replace('${', '').replace('}', ''))
    const deduplicatedOccurrence = [...new Set(occurrence)]
    const existingVariables = deduplicatedOccurrence.filter(el => existingVariableIds.includes(el))

    return existingVariables;
}

function includesAnotherVariable(value: string) {
    return !!value.match(variableRegexp);
}

function includesOnlyAnotherVariable(value: string) {
    return !!value.match(onlyVariableRegexp);
}

function resolveTypes({
    variables,
    variablesDependencyGraph,
    allSteps,
    entities,
    intents,
    executableFunctions
}: {
    variables: SurveyDefinitionVariable[];
    variablesDependencyGraph: Record<string, string[]>;
    allSteps: BlockQuestion[];
    entities: Entity[];
    intents: SurveyIntent[];
    executableFunctions: ExecutableFunctionDefinition<any, any>[]
    }): Record<string, ResolvedVariableType> {
    const entitiesMap = entities.reduce((acc, entity) => ({
        ...acc,
        [entity.name]: entity
    }), {})

    const setVariableStepsMap = getSetVariableStepsMap(allSteps);
    const wordQuestionStepsMap = getWordQuestionStepsMap(allSteps, entitiesMap);
    const httpStepsMap = getHttpStepsMap(allSteps);
    const functionsStepsMap = getFunctionStepsMap(allSteps, executableFunctions);
    const stepsWithAssociatedAnswersMap = getStepsWithAssociatedAnswersMap(allSteps, entitiesMap);
    const entitiesFromIntentsVariableTypeMap = getEntitiesFromIntentsVariableTypeMap(intents, entitiesMap);
    const remainingStepsMap = getRemainingStepsMap(allSteps);

    const variableIdsToTypesFromSteps: Record<string, VariableTypesToBeResolved> = variables.reduce((acc, variable) => ({
        ...acc,
        [variable.id]: getVariableTypesFromSteps(variable, {
            setVariableStepsMap,
            wordQuestionStepsMap,
            remainingStepsMap,
            httpStepsMap,
            functionsStepsMap,
            stepsWithAssociatedAnswersMap,
            entitiesFromIntentsVariableTypeMap
        }),
    }), {});

    return Object.entries(variableIdsToTypesFromSteps).reduce((acc, [id, types]) => ({
        ...acc,
        [id]: getTypeForVariableConsideringDependencies(types, variableIdsToTypesFromSteps, variablesDependencyGraph[id]),
    }), {});
}

function getSetVariableStepsMap(allSteps: unknown[]): Record<string, VariableTypesToBeResolved> {
    const setVariableSteps = allSteps.filter(isSetVariableStep);
    return setVariableSteps.reduce((acc, step) => {
        if (includesAnotherVariable(step.value)) {
            if (!includesOnlyAnotherVariable(step.value)) {
                acc[step.variable] = [...(acc[step.variable] || []), { type: VariableType.STRING }];
            }
        } else {
            acc[step.variable] = [...(acc[step.variable] || []), { id: step.value, key: step.value, type: VariableType.DICTIONARY }];
        }
        return acc;
    }, {});
}

function getWordQuestionStepsMap(allSteps: BlockQuestion[], entities: Record<string, Entity>): Record<string, VariableTypesToBeResolved> {
    const wordQuestionSteps = allSteps.filter((step): step is WordQuestion => (isWordStep(step) && !!step.saveTo))
    return wordQuestionSteps.reduce((acc, step) => {
        const questionAnswers = step.dictionary.map((dict) => {
            if (dict.type === 'ENTITY') {
                return entities[dict.key]?.values?.map(value => ({ id: value.key, key: value.key, type: VariableType.DICTIONARY }))?.flat();
            }
            return [{ id: dict.id, key: dict.key, type: VariableType.DICTIONARY }];
        }).flat();

        acc[step.saveTo] = [...(acc[step.saveTo] || []), ...questionAnswers];
        return acc;
    }, {});
}

function getHttpStepsMap(allSteps: BlockQuestion[]): Record<string, VariableTypesToBeResolved> {
    const httpSteps = allSteps.filter(isHttpStep)
    return httpSteps.reduce((httpStepsAcc, step) => {
        return step.mappings.reduce((acc, mapping) => {
            const { variable, type, possibleValues } = mapping;

            if (type === VariableType.DICTIONARY) {
                acc[variable] = [
                    ...(acc[variable] || []),
                    ...possibleValues.map(v => ({ id: v, key: v, type: VariableType.DICTIONARY }))
                ];
            } else {
                acc[variable] = [
                    ...(acc[variable] || []),
                    { type }
                ];
            }

            return acc;
        }, httpStepsAcc)
    }, {});
}

function getFunctionStepsMap(allSteps: BlockQuestion[], executableFunctions: ExecutableFunctionDefinition<any, any>[]): Record<string, VariableTypesToBeResolved> {
    const functionSteps = allSteps.filter(isFunctionStep)
    return functionSteps.reduce((functionStepsAcc, step) => {
        return Object.entries(step.functionOutputs).reduce((outputsAcc, [outputKey, output]) => {
            if (output.type === 'mapping') {
                return output.mapping.reduce((acc, mapping) => {
                    if (mapping.type === VariableType.DICTIONARY) {
                        acc[mapping.variable] = [
                            ...(acc[mapping.variable] || []),
                            ...mapping.possibleValues.map(v => ({ id: v, key: v, type: VariableType.DICTIONARY }))
                        ];
                    } else {
                        acc[mapping.variable] = [
                            ...(acc[mapping.variable] || []),
                            { type: mapping.type }
                        ];
                    }
                    return acc;
                }, outputsAcc)
            }

            const executableFunction = executableFunctions.find(fn => fn.name === step.functionName);
            const matchedOutput = executableFunction.outputs.find(o => o.name === outputKey);
            outputsAcc[output] = [
                ...(outputsAcc[output] || []),
                { type: matchedOutput.type }
            ];
            return outputsAcc;
        }, functionStepsAcc)
    }, {});
}

function getRemainingStepsMap(allSteps: BlockQuestion[]): Record<string, VariableTypesToBeResolved> {
    const remainingSteps = allSteps
        .filter(isQuestionWithAnswer)
        .filter((step) => (step?.saveTo && ![QuestionType.SET_VARIABLE, QuestionType.WORD, QuestionType.HTTP].includes(step.type)));
    return remainingSteps.reduce((acc, step) => {
        acc[step.saveTo] = [...(acc[step.saveTo] || []), getTypeForQuestionWithAnswer(step.type)];
        return acc;
    }, {});
}

function getStepsWithAssociatedAnswersMap(allSteps: BlockQuestion[], entities: Record<string, Entity>): Record<string, VariableTypesToBeResolved> {
    const stepsWithAssociatedAnswers = allSteps.filter(step => !!step.associatedAnswers);
    return stepsWithAssociatedAnswers.reduce((stepsAcc, step) => {
        return step.associatedAnswers.reduce((acc, associatedAnswer) => {
            return {
                ...acc,
                [associatedAnswer.variableId]: [
                    ...(acc[associatedAnswer.variableId] || []),
                    ...(entities[associatedAnswer.entity]?.values?.map(value => ({ id: value.key, key: value.key, type: VariableType.DICTIONARY })) ?? [])
                ]
            }
        }, stepsAcc)
    }, {})
}

function getEntitiesFromIntentsVariableTypeMap(intents: SurveyIntent[], entities: Record<string, Entity>): Record<string, VariableTypesToBeResolved> {
    return intents.map(intent => intent.entities).flat().reduce((acc, entity) => ({
        ...acc,
        [entity.variableId]: [
            ...(acc[entity.variableId] || []),
            ...(entities[entity.entity]?.values?.map(value => ({ id: value.key, key: value.key, type: VariableType.DICTIONARY })) ?? [])
        ]
    }), {})
}

function getTypeForQuestionWithAnswer(type: QuestionType): { type: PrimitiveVariableType } {
    switch (type) {
        case QuestionType.NUMERICAL:
            return { type: VariableType.NUMBER };
        case QuestionType.OPEN:
            return { type: VariableType.STRING };
        case QuestionType.SPELLING:
            return { type: VariableType.STRING };
        case QuestionType.DATE:
            return { type: VariableType.STRING };
        case QuestionType.DATETIME:
            return { type: VariableType.STRING };
        default:
            return { type: VariableType.STRING };
    }
};

function getVariableType(variable: SurveyDefinitionVariable) {
    if (!variable.type) {
        return [];
    }

    if (variable.type === VariableType.DICTIONARY) {
        return [...(variable as DictionaryVariable).values.map(v => ({ id: v, key: v, type: VariableType.DICTIONARY }))];
    }

    return [{ type: variable.type }];
}

function getVariableTypesFromSteps(variable: SurveyDefinitionVariable, stepMaps: Record<string, Record<string, VariableTypesToBeResolved>>) {
    return [
        ...(getVariableType(variable)),
        ...Object.values(stepMaps).map((stepMap) => stepMap[variable.id] || []).flat()
    ];
}

function getTypeForVariableConsideringDependencies(typesFromSteps: VariableTypesToBeResolved, variablesTypesMap: Record<string, VariableTypesToBeResolved>, dependencies?: string[]): ResolvedVariableType {
    const allTypes = [...typesFromSteps];

    if (dependencies) {
        const dependenciesTypes = dependencies.map((variableId) => variablesTypesMap[variableId]).flat();
        allTypes.push(...dependenciesTypes);
    }

    const allUniqueTypes = uniqBy(allTypes, (t: {type: VariableType, id?: string}) => `${t?.type ?? ''}#${t?.id ?? ''}`);

    const hasNoTypes = allUniqueTypes.length === 0
    const isNumberOrBooleanAndHasOtherTypes = ((some(allUniqueTypes, { type: VariableType.NUMBER }) || some(allUniqueTypes, { type: VariableType.BOOLEAN })) && allUniqueTypes.length > 1)

    if (hasNoTypes || isNumberOrBooleanAndHasOtherTypes || some(allUniqueTypes, { type: VariableType.STRING })) {
        return { type: VariableType.STRING };
    } else if (some(allUniqueTypes, { type: VariableType.NUMBER })) {
        return { type: VariableType.NUMBER };
    } else if (some(allUniqueTypes, { type: VariableType.BOOLEAN })) {
        return { type: VariableType.BOOLEAN };
    } 
    
    return allUniqueTypes as ResolvedVariableDictionaryType
}