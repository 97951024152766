import * as React from 'react';

import styles from './IntentOptions.pcss';
import {DeleteOption} from './optionsMenu/DeleteOption';
import {OptionsComponent} from './optionsMenu/Options';

type Props = {
    onDelete?: (event: any) => any;
}

export const CallbackOptionsComponent = (props: Props) => {
    const options = [<DeleteOption key={'delete-option'} onDelete={props.onDelete}/>];

    return (
        <OptionsComponent
            iconContainerClass={styles.options}
            options={options} />
    )
}
