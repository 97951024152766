import cx from 'classnames';
import React from 'react';

import { CancelIcon, DoneIcon } from '../../../icons';

import styles from './ConfirmCancelOptionsMenu.pcss';
import { MenuOption, OptionProps, OptionsMenuComponent } from './OptionsMenu';

type Props = {
    positionClassName: string;
    dataTest?: string;
    onConfirm?: (event: any) => any,
    onCancel?: (event: any) => any;
    confirmDisabled?: boolean;
}

export const ConfirmCancelOptionsMenuComponent = (props: Props) => <OptionsMenuComponent
    containerPositionClassName={cx(styles.container, props.positionClassName)}
    dataTest={props.dataTest}
>
    <CancelOption onClick={props.onCancel} />
    <ConfirmOption
        isDisabled={props.confirmDisabled}
        onClick={props.onConfirm}
    />
</OptionsMenuComponent>;

export const CancelOption = (props: Partial<OptionProps>) => {
    return (
        <MenuOption
            dataTest="cancel-option"
            icon={CancelIcon}
            hoverType={'warning'}
            onClick={props.onClick}
        />
    );
}
export const ConfirmOption = (props: Partial<OptionProps>) => {
    return (
        <MenuOption
            dataTest="confirm-option"
            data-test="confirm-option-button"
            icon={DoneIcon}
            hoverType={'confirm'}
            onClick={props.onClick}
            isDisabled={props.isDisabled}
        />
    );
}
