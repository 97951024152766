import {
    Model,
    View,
    Mapper,
    InitialModelToViewMapper,
    ModelToViewMapper,
    ViewToModelMapper,
} from './';

class DictionaryQuestionMapper implements Mapper<Model, View> {
    private modelToView: ModelToViewMapper;

    /**
     * translates initial model (API) value into view value
     * initial view value does not include entities, and works for the first mount (before useEffect)
     *
     * @param model Model value
     * @return View View value
     */
    static getInitialViewValue(model: Model): View {
        return InitialModelToViewMapper.getViewValue(model);
    }

    constructor(botId: string, language: string) {
        this.modelToView = new ModelToViewMapper(botId, language);
    }

    /**
     * translates model (API) value into view value
     * view value includes additional information from entities database
     *
     * @param model Model value
     * @return Promise<View> View value
     */
    public async getViewValue(model: Model): Promise<View> {
        return this.modelToView.getViewValue(model);
    }

    /**
     * translates view value back into the model (API) value
     * note: since full entities are not part of the original model value, it will loose information(!)
     *
     * @param view View value
     * @return Model Model value
     */
    public getModelValue(view: View): Model {
        return ViewToModelMapper.getModelValue(view);
    }
}

export default DictionaryQuestionMapper;
