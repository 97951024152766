import moment, {Moment} from 'moment';

const getRange = (period: 'day' | 'week' | 'month' | 'year', current: Moment, timezone: string) => ({
    startDate: moment.tz(current, timezone).subtract(1, period).startOf('day').toISOString(),
    endDate: moment.tz(timezone).endOf('day').toISOString()
});

export const periodToRange = (timezone: string) => ({
    day: (current: Moment) => ({
        startDate: moment.tz(current, timezone).subtract(1, 'day').add(1, 'hour').startOf('hour').toISOString(),
        endDate: moment.tz(current, timezone).endOf('hour').toISOString()
    }),
    week: (current: Moment) => getRange('week', current, timezone),
    month: (current: Moment) => getRange('month', current, timezone),
    year: (current: Moment) => getRange('year', current, timezone),
});
