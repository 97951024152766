import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import qs from 'qs';
import { pickBy, isEqual, Dictionary } from 'lodash';

export const useFilters = <T>(defaultFilters: T, initialFilters?: T) => {
  const history = useHistory();

  const [filtersState, setFiltersState] = useState<T>(initialFilters || defaultFilters);

  const setFilters = (newFilters: Partial<T>) => history
    .push({
      pathname: history.location.pathname,
      search: qs.stringify(pickBy({
        ...qs.parse(history.location.search, { ignoreQueryPrefix: true }),
        ...newFilters,
      }), { addQueryPrefix: true }),
    });

  const replaceFilters = (newFilters: T) => history
    .push({
      pathname: history.location.pathname,
      search: qs.stringify({
        ...pickBy(newFilters as Dictionary<string[]>),
      }, { addQueryPrefix: true }),
    });

  const reset = () => history.push({ pathname: history.location.pathname });

  useEffect(() => {
    if (initialFilters) {
      replaceFilters({ ...initialFilters, ...qs.parse(history.location.search, { ignoreQueryPrefix: true }) });
    }
  }, []);

  useEffect(() => {
    const queryParams = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    const newFilters = { ...defaultFilters, ...queryParams };
    if (!isEqual(filtersState, newFilters)) {
      setFiltersState(newFilters);
    }
  }, [history.location]);

  return {
    filters: filtersState,
    setFilters: setFilters,
    replaceFilters,
    reset,
  };
};
