import {default as React} from 'react';
import {useIntl} from 'react-intl';

import {SelectComponent} from '../../../components/Select/Select';

type ExistsConditionValueProps = {
  onChange: (operator: boolean) => void;
  value: boolean;
  error?: string;
};
export const ExistsConditionValue = ({onChange, value, error}: ExistsConditionValueProps) => {
  const intl = useIntl();

  const mapToSelect = (id: boolean) => ({id, name: intl.messages[`survey-creator.condition.exists.${id}`] as string})

  const options = [mapToSelect(true), mapToSelect(false)];
  return (
    <SelectComponent
      value={mapToSelect(value)}
      options={options}
      onChange={(newValue) => onChange(newValue.id)}
      invalid={!!error}
      error={error}
    />
  );
};
