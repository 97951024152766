import React from 'react';

import {IconProps} from './iconTypes';

export function MinimizeIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
          <g>
            <g transform="translate(-56 -32) translate(56 32)">
              <path d="M0 0H24V24H0z"/>
              <path className={props.animationClass} fill="#919aaf" fillRule="nonzero" d="M19.842 17.564c.64 0 1.158.519 1.158 1.158v1.12c0 .64-.518 1.158-1.158 1.158h-5.601c-.64 0-1.158-.518-1.158-1.158v-1.12c0-.64.518-1.158 1.158-1.158zm-.037 1.195h-5.527v1.045h5.527V18.76zM8.699 10.108l.082.07 4.301 4.299.001-1.917c0-.297.217-.543.5-.59l.097-.007c.297 0 .544.216.59.5l.008.097v3.361l-.001.036-.003.03.004-.066c0 .046-.005.09-.015.133l-.008.033c-.007.023-.015.045-.024.067l-.01.023c-.01.022-.022.042-.035.062l-.018.028c-.039.055-.087.103-.142.142l.077-.065c-.032.032-.068.06-.105.084l-.055.03c-.013.007-.026.012-.04.018-.016.006-.034.013-.052.018-.016.005-.032.009-.049.012l-.025.005-.02.003-.053.004H10.32c-.33 0-.598-.267-.598-.597 0-.297.217-.543.5-.59l.098-.007 1.916-.002-4.3-4.3c-.233-.233-.233-.612 0-.845.208-.207.53-.23.763-.07zM14.8 3c.899 0 1.636.69 1.711 1.57l.007.148v7.842c0 .33-.268.598-.598.598-.297 0-.543-.217-.59-.5l-.007-.098-.001-5.004H4.195v5.004c0 .257.185.47.429.515l.094.008h3.36c.33 0 .598.268.598.597 0 .297-.216.544-.5.59l-.097.008H4.718c-.899 0-1.637-.69-1.712-1.57L3 12.56V4.718c0-.899.69-1.637 1.57-1.712L4.718 3H14.8zm0 1.195H4.718c-.257 0-.47.185-.515.429l-.008.094V6.36h11.128V4.718c0-.257-.184-.47-.428-.515l-.094-.008z"/>
            </g>
          </g>
        </g>
      </svg>
    );
}
