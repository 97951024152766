import classNames from 'classnames';
import _ from 'lodash';
import * as React from 'react';
import {FormattedMessage} from 'react-intl';

import {DraggableItems} from '../../components/DraggableItems/DraggableItems';
import {ElementContainer} from '../../components/ElementContainer/ElementContainer';
import {ElementWithLabel} from '../../components/ElementWithLabel/ElementWithLabel';
import {Input} from '../../components/Input';
import {Label} from '../../components/Label';
import {DeleteIcon} from '../../icons';
import {Range} from '../model';

import {AddButton} from './AddButton';
import styles from './NumericalRanges.pcss';

const RangeComponent = (props: { range: Range, onChange: (range: Range) => void, onRemove: () => void; }) => {
  const handleChange = (key: string) => (value: string) => {
    const changed = {...props.range, [key]: parseInt(value, 10) || 0};
    props.onChange(changed);
  };
  return (
    <div data-test="numerical-range" className={styles.ranges}>
      <div className={styles.header}>
        <Label labelId={'survey-creator.numerical.range.label'} />
        <div data-test="remove-range-button" onClick={() => props.onRemove()} className={styles.remove}>
          <DeleteIcon animationClass={classNames(styles.removeIcon)} />
        </div>
      </div>

      <div className={styles.rangeRow}>
        <ElementWithLabel labelId={'survey-creator.numerical.range.from'} noPadding className={styles.rangeInput}>
          <Input dataTest="min" type={'text'} pattern="[0-9]*" value={_.isNil(props.range.min) ? '' : props.range.min} onChange={handleChange('min')}
                 width={'100%'} />
        </ElementWithLabel>
        <ElementWithLabel labelId={'survey-creator.numerical.range.to'} noPadding className={styles.rangeInput}>
          <Input dataTest="max" type={'text'} pattern="[0-9]*" value={_.isNil(props.range.max) ? '' : props.range.max} onChange={handleChange('max')}
                 width={'100%'} />
        </ElementWithLabel>
      </div>

    </div>
  );
};

type Props = { 
  currentRanges: Range[];
  setRange: (index: number) => (changedRange: Range) => void;
  setRanges: (ranges: Range[]) => void;
  onAddRange: () => void;
  onRemoveRange: (indexToRemove: number) => () => void;
};

export const NumericalRanges: React.FC<Props> = ({ 
  setRange,
  setRanges,
  onAddRange,
  onRemoveRange,
  currentRanges
 }) => {
  return (
    <div className={styles.rangeContainer}>
      <DraggableItems
        onDragEnd={setRanges}
        items={currentRanges}
        renderItem={(range: Range, index: number) => (
          <ElementContainer data-test="numerical-range-container">
            <RangeComponent range={range} onChange={setRange(index)} onRemove={onRemoveRange(index)} />
          </ElementContainer>
        )}
        dataTest={'ranges-drag-drop-container'}
      />
    </div>
  );
};
