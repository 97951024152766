import { Model } from '../types';
import {
    AnswerItemDictionary,
    AnswerType,
    DisambiguationAnswer,
    GlobalReadOrderType, ItemReadOrderType,
    Reaction,
    ReactionType
} from '../../../types';
import {
    DefaultReadAnswerType,
    DictionaryWordAnswer,
    NotPreciseAnswer, ReadOption,
    WordAnswerType,
    WordsDictionary, WordsDictionaryItem
} from '../../../../model';

class BaseModelToViewMapper {
    static globalReadOrderTypeMapping: Record<DefaultReadAnswerType, GlobalReadOrderType> = {
        'PERMUTATE': GlobalReadOrderType.RANDOM,
        'SEQUENCE': GlobalReadOrderType.ORIGINAL,
    }
    static itemReadOrderTypeMapping: Record<ReadOption, ItemReadOrderType> = {
        'FIRST': ItemReadOrderType.FIRST,
        'LAST': ItemReadOrderType.LAST,
        'PERMUTATE': ItemReadOrderType.INHERIT,
        'SEQUENCE': ItemReadOrderType.INHERIT,
    }

    static getDictionaryAnswerItem(dictionaryItem: DictionaryWordAnswer, model: Model, ): AnswerItemDictionary {
        return {
            id: dictionaryItem.id,
            key: dictionaryItem.key,
            synonyms: dictionaryItem.synonyms,
            reaction: BaseModelToViewMapper.getAnswerReaction(dictionaryItem, model),
            type: AnswerType.DICTIONARY,
            order: {
                item: BaseModelToViewMapper.itemReadOrderTypeMapping[dictionaryItem.message?.read] || ItemReadOrderType.NONE,
                global: BaseModelToViewMapper.globalReadOrderTypeMapping[model.defaultReadAnswerType] || GlobalReadOrderType.NONE,
            },
            // readMessage is decided in getMessageModel already.
            // view value transformation should always go through model value transformation first (providing `dictionaryItem.message` value)
            readMessage: dictionaryItem.message,
        };
    }

    static getAnswerReaction(item: WordsDictionaryItem, model: Model): Reaction {
        // reaction could be saved on both question level, and answer level because of entities and the previous way of saving reactions
        const fromReactions = model.reactions?.find(reaction => {
            if (reaction.type === WordAnswerType.DICTIONARY) {
                return reaction.id === item.id;
            }
            return reaction.id === item.id && reaction.key === item.key;
        })?.goTo;
        const fromItem = item.type === WordAnswerType.DICTIONARY && item.goTo;
        const final = fromReactions || fromItem;

        // if no reaction is specified, or reaction is specified as continue
        if (!final || final === ReactionType.CONTINUE) {
            return { type: ReactionType.CONTINUE };
        }

        // otherwise it must be "go to module" reaction
        return {
            type: ReactionType.GOTO,
            moduleId: final,
        };
    }

    static getIncomprehensionReaction(model: Model): Reaction {
        if (model.goTo.repeated === undefined || model.goTo.repeated === ReactionType.CONTINUE ) {
            return { type: ReactionType.CONTINUE };
        }

        return {
            type: ReactionType.GOTO,
            moduleId: model.goTo.repeated,
        };
    }

    static getReadOrder(model: Model): GlobalReadOrderType {
        switch (model.defaultReadAnswerType) {
            case 'PERMUTATE':
                return GlobalReadOrderType.RANDOM;
            case 'SEQUENCE':
                return GlobalReadOrderType.ORIGINAL;
            default:
                return GlobalReadOrderType.NONE;
        }
    }
}

export default BaseModelToViewMapper;
