import * as React from 'react';

import { useDebounceValue } from '../utils/withDebounce';

import { Input, InputProps } from './Input';

export type DebouncedInputProps = InputProps & {
    debounced?: number;
};

export function DebouncedInput({ onChange, value, debounced, ...props }: DebouncedInputProps) {
    const { setValue, value: inputValue, flush } = useDebounceValue(debounced, !!debounced, onChange, value);
    return (
        <Input
            {...props}
            value={inputValue}
            onChange={setValue}
            onBlur={flush}
        />
    );
}
