import * as React from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Bot } from '../surveyCreator/model';
import { getBotUrlId } from '../utils/CurrentBot';
import { HttpClient } from '../utils/HttpClient';

import { BotClonePopup } from './BotClonePopup';
import styles from './BotComponent.pcss';
import { BotComponentOptions } from './BotComponentOptions';
import { BotEditPopup } from './BotEditPopup';
import { Tag } from '../components/Tag/Tag';
import classNames from 'classnames';

export type Tag = { id: string; tag: string; color?: string };
type BotComponentProps = {
  bot: Bot;
  allOrganizations: { name: string; id: string }[];
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  isAdmin: boolean;
  onBotsUpdate: () => void;
  predefinedTags: Tag[];
};

export const BotComponent = ({
  onBotsUpdate,
  bot,
  allOrganizations,
  onClick,
  isAdmin,
  predefinedTags,
}: BotComponentProps) => {
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showClonePopup, setShowClonePopup] = useState(false);

  const tags = bot.tags || [];
  return (
    <div 
      data-test="bot-component"
      className={
        classNames(
          styles.container, 
          { [styles.nonCustomerVisible]: isAdmin && !bot.customerVisible }
        )}
      >
      <NavLink
        className={styles.component}
        data-test={`bot-${bot.name}-component`}
        to={`/${getBotUrlId(bot)}`}
        onClick={(event) => {
          if (event.button === 0) {
            return;
          }
          onClick(event);
        }}
      >
        <div className={styles.previewHeader}>
          <div data-test="bot-name" className={styles.name}>
            {bot.name}
          </div>
          {isAdmin && (
            <>
              <div
                data-test="bot-tags-container"
                className={styles.tagsContainer}
              >
                {tags.map((tag) => (
                  <Tag key={tag}>{tag}</Tag>
                ))}
              </div>
            </>
          )}
        </div>
        <div className={styles.options}>
          <BotComponentOptions
            onDuplicate={() => setShowClonePopup(true)}
            onEdit={() => setShowEditPopup(true)}
          />
        </div>
      </NavLink>

      {showClonePopup && (
        <BotClonePopup
          originalBot={bot}
          onCancel={() => setShowClonePopup(false)}
          onSave={(request) => {
            HttpClient.post({
              path: `/bots/${bot.id}/clone`,
              body: request,
            })
              .then(onBotsUpdate)
              .then(() => setShowClonePopup(false));
          }}
          organizations={allOrganizations}
        />
      )}
      {showEditPopup && (
        <BotEditPopup
          botId={bot.id}
          onSuccess={() => {
            onBotsUpdate();
            setShowEditPopup(false);
          }}
          onCancel={() => setShowEditPopup(false)}
          botClient={bot.organization}
          currentBotVisibleForClient={bot.customerVisible}
          currentBotTags={tags}
          predefinedTags={predefinedTags}
          currentBotName={bot.name}
          botLanguage={bot.voiceConfig.languageCode}
        />
      )}
    </div>
  );
};
