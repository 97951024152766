import * as React from 'react';

import {ToggleWithLabel} from './ToggleWithLabel';

type QuestionProps = { anonymizeAnswer?: boolean };
type Props = { question: QuestionProps, onChange: (question: QuestionProps) => void };

export const AnonymizeAnswerToggle = ({question, onChange}: Props) => {
    const setAnonymizeAnswer = () => onChange({...question, anonymizeAnswer: !question.anonymizeAnswer});
    return (
        <ToggleWithLabel dataTest={'anonymize-answer'} labelId={'survey-creator.anonymizeAnswerLabel'} value={!!question.anonymizeAnswer} onToggle={setAnonymizeAnswer} />
    );
};
