import classNames from 'classnames';
import * as React from 'react';
import { FunctionComponent, ReactNode, Ref } from 'react';
import { useIntl } from 'react-intl';

import { TooltipComponent } from '../../../components/Tooltip/Tooltip';
import { IconProps } from '../../../icons/iconTypes';

import styles from './OptionsMenu.pcss';

type Props = {
    dataTest?: string;
    containerPositionClassName?: string;
    children: ReactNode
}

export const OptionsMenuComponent = React.forwardRef(({ children, dataTest, containerPositionClassName }: Props, ref: Ref<any>) => {
    return (
        <div
            data-test={dataTest}
            className={classNames(styles.container, { [styles.containerPosition]: !containerPositionClassName }, containerPositionClassName)}
            ref={ref}>
            {children}
        </div>
    );
});

export type OptionProps = {
    onClick: (event: any) => void;
    icon: FunctionComponent<IconProps>;
    dataTest?: string;
    hoverType?: 'regular' | 'warning' | 'confirm';
    readonly children?: ReactNode | ReactNode[];
    positionRelative?: boolean;
    alignToTop?: boolean;
    isDisabled?: boolean;
    tooltipLabelId?: string;
}

export const MenuOption = (props: OptionProps) => {
    const intl = useIntl();
    const handleClick = (event: any) => {
        event.stopPropagation();
        if (!props.isDisabled) {
            props.onClick(event);
        }
    }
    return (
        <div className={classNames(
            styles.option,
            {
                [styles.alignToTop]: props.alignToTop,
                [styles.disabled]: props.isDisabled
            }
        )}
            onClick={handleClick}
            data-test={props.dataTest}
            style={{ position: props.positionRelative ? 'relative' : 'initial' }}
        >
            {
                props.tooltipLabelId
                    ? <TooltipComponent
                        tooltipText={intl.messages[props.tooltipLabelId] as string}
                    >
                        <props.icon animationClass={classNames(styles.optionIcon, { [styles.warningIcon]: props.hoverType === 'warning', [styles.confirmIcon]: props.hoverType === 'confirm' })} />
                    </TooltipComponent>
                    : <props.icon animationClass={classNames(styles.optionIcon, { [styles.warningIcon]: props.hoverType === 'warning', [styles.confirmIcon]: props.hoverType === 'confirm' })} />
            }

            {props.children}
        </div>
    )
};



