import produce from 'immer';
import _ from 'lodash';
import {ActionType, createReducer} from 'typesafe-actions';
import {v4} from 'uuid';

import {errorStateMeta, loadingStateMeta, successStateMeta} from '../../../reducers/reducerUtils';
import {Loadable} from '../../../reducers/types';
import {EntityAnswer} from '../../model';
import {SurveyCreator} from '../actionTypes';

import * as actions from './actions';


export class EntityDetailsStore {
  savedEntityAnswers: {
    [entity: string]: Loadable<EntityAnswer[]>;
  } = {};
  modifiedEntityAnswers: {
    [entity: string]: {
      answers: EntityAnswer[];
      dirty: boolean;
    }
  } = {};
}

export const defaultState = {...new EntityDetailsStore()}

export type EntityDetailsAction = ActionType<typeof actions>;

export const entityDetails = createReducer<EntityDetailsStore, EntityDetailsAction>(
  defaultState, {
    [SurveyCreator.FETCH_ENTITY_DETAILS]: (state, {payload}) =>
      produce(state, draft => {
        draft.savedEntityAnswers[payload.entity] = {...loadingStateMeta};
      }),
    [SurveyCreator.FETCH_ENTITY_DETAILS_SUCCESS]: (state, {payload}) =>
      produce(state, draft => {
        const valuesWithIds = (payload.values ?? []).map(answer => ({...answer, id: v4()}));

        draft.savedEntityAnswers[payload.name] = {
          ...successStateMeta,
          data: valuesWithIds,
        };
        draft.modifiedEntityAnswers[payload.name] = {
          dirty: false,
          answers: valuesWithIds
        }
      }),
    [SurveyCreator.FETCH_ENTITY_DETAILS_FAILURE]: (state, {payload}) =>
      produce(state, draft => {
        draft.savedEntityAnswers[payload.entity] = {
          ...errorStateMeta,
          data: [],
          error: payload.error,
        };
      }),
    [SurveyCreator.FETCH_ENTITIES_DETAILS]: (state, {payload}) =>
      produce(state, draft => {
        payload.entities.forEach(entity => {
          draft.savedEntityAnswers[entity] = {...loadingStateMeta};
        });
      }),
    [SurveyCreator.FETCH_ENTITIES_DETAILS_SUCCESS]: (state, {payload}) =>
      produce(state, draft => {
        payload.entities.forEach(entity => {
          const valuesWithIds = (entity.values ?? []).map(answer => ({...answer, id: v4()}));

          draft.savedEntityAnswers[entity.name] = {
            ...successStateMeta,
            data: valuesWithIds,
          };
          draft.modifiedEntityAnswers[entity.name] = {
            dirty: false,
            answers: valuesWithIds
          }
        });
      }),
    [SurveyCreator.FETCH_ENTITIES_DETAILS_FAILURE]: (state, {payload}) =>
      produce(state, draft => {
        payload.entities.forEach(entity => {
          draft.savedEntityAnswers[entity] = {
            ...errorStateMeta,
            data: [],
            error: payload.error,
          };
        });
      }),
    [SurveyCreator.UPDATE_ENTITY_DETAILS]: (state, {payload}) =>
      produce(state, draft => {
        const dirty = !(_.isEmpty(state.savedEntityAnswers[payload.entity].data) && _.isEmpty(payload.answers)) &&
          !_.isEqual(state.savedEntityAnswers[payload.entity].data, payload.answers);
        draft.modifiedEntityAnswers[payload.entity] = {
          dirty,
          answers: payload.answers
        }
      }),
    [SurveyCreator.DELETE_MODIFIED_ENTITY_DETAILS]: (state, {payload}) =>
      produce(state, draft => {
        draft.modifiedEntityAnswers[payload.entity] = {
          answers: state.savedEntityAnswers[payload.entity].data,
          dirty: false
        };
      }),
    [SurveyCreator.SAVE_ENTITY_DETAILS_SUCCESS]: (state, {payload}) =>
      produce(state, draft => {
        const valuesWithIds = payload.values.map(answer => ({...answer, id: v4()}));
        draft.savedEntityAnswers[payload.name].data = valuesWithIds;
        draft.modifiedEntityAnswers[payload.name] = {
          answers: valuesWithIds,
          dirty: false
        };
      }),
  });