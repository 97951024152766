import { create } from 'lodash';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { SurveyConfig } from './actionTypes';
import { SurveyConfigType } from './types';

export const fetchSurveyConfig = createAsyncAction(
  SurveyConfig.FETCH_SURVEY_CONFIG,
  SurveyConfig.FETCH_SURVEY_CONFIG_SUCCESS,
  SurveyConfig.FETCH_SURVEY_CONFIG_FAILURE
)<string, SurveyConfigType, string>();

export const patchSurveyConfig = createAsyncAction(
  SurveyConfig.PATCH_SURVEY_CONFIG,
  SurveyConfig.PATCH_SURVEY_CONFIG_SUCCESS,
  SurveyConfig.PATCH_SURVEY_CONFIG_FAILURE
)<{id: string, config: Partial<SurveyConfigType>}, SurveyConfigType, string>();

export const fetchSurveyScheduleStatus = createAsyncAction(
  SurveyConfig.FETCH_SURVEY_SCHEDULE_STATUS,
  SurveyConfig.FETCH_SURVEY_SCHEDULE_STATUS_SUCCESS,
  SurveyConfig.FETCH_SURVEY_SCHEDULE_STATUS_ERROR
)<string, boolean, string>();

export const deleteModifiedConfig = createStandardAction(
  SurveyConfig.DELETE_MODIFIED_CONFIG
)();

export const updateModifiedConfig = createStandardAction(
  SurveyConfig.UPDATE_MODIFIED_CONFIG
)<Partial<SurveyConfigType>>();