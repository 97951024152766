import {Action} from 'redux';
import {ActionsObservable, Epic, ofType} from 'redux-observable';
import {from, Observable, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {getType} from 'typesafe-actions';

import {
    AddNameDictionaryType,
    addNamesDictionaryAction,
    DeleteNameDictionaryType,
    deleteNamesDictionaryAction
} from '../actions/namesDictionaryActions';
import {NamesDictionaryAction} from '../reducers/namesDictionary';
import {RootState} from '../reducers/reducers';
import {HttpClient} from '../utils/HttpClient';

export const addNamesDictionaryEpic: Epic<Action, NamesDictionaryAction, RootState> = (action$: ActionsObservable<NamesDictionaryAction>): Observable<NamesDictionaryAction> =>
    action$.pipe(
        ofType(getType(addNamesDictionaryAction.request)),
        mergeMap(({payload}: { payload: AddNameDictionaryType, type: any }) =>
            from(HttpClient.post({
                    path: `/bots/${payload.botId}/names-dictionary`,
                    body: {
                        key: payload.key,
                        nameDictionaryItem: payload.namesDictionaryItem
                    }
                })
            ).pipe(
                map(() => addNamesDictionaryAction.success(payload)),
                catchError((error) => of(addNamesDictionaryAction.failure(error)))
            )
        )
    );

export const deleteNamesDictionaryEpic: Epic<Action, NamesDictionaryAction, RootState> = (action$: ActionsObservable<NamesDictionaryAction>): Observable<NamesDictionaryAction> =>
    action$.pipe(
        ofType(getType(deleteNamesDictionaryAction.request)),
        mergeMap(({payload}: { payload: DeleteNameDictionaryType, type: any }) =>
            from(HttpClient.delete({
                    path: `/bots/${payload.botId}/names-dictionary/${payload.key}`,
                })
            ).pipe(
                map(() => deleteNamesDictionaryAction.success(payload)),
                catchError((error) => of(deleteNamesDictionaryAction.failure(error)))
            )
        )
    );
