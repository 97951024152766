export enum SurveyConfig {
  FETCH_SURVEY_CONFIG = 'FETCH_SURVEY_CONFIG',
  FETCH_SURVEY_CONFIG_SUCCESS = 'FETCH_SURVEY_CONFIG_SUCCESS',
  FETCH_SURVEY_CONFIG_FAILURE = 'FETCH_SURVEY_CONFIG_FAILURE',

  PATCH_SURVEY_CONFIG = 'PATCH_SURVEY_CONFIG',
  PATCH_SURVEY_CONFIG_SUCCESS = 'PATCH_SURVEY_CONFIG_SUCCESS',
  PATCH_SURVEY_CONFIG_FAILURE = 'PATCH_SURVEY_CONFIG_FAILURE',

  FETCH_SURVEY_SCHEDULE_STATUS = 'FETCH_SURVEY_SCHEDULE_STATUS',
  FETCH_SURVEY_SCHEDULE_STATUS_SUCCESS = 'FETCH_SURVEY_SCHEDULE_STATUS_SUCCESS',
  FETCH_SURVEY_SCHEDULE_STATUS_ERROR = 'FETCH_SURVEY_SCHEDULE_STATUS_ERROR',

  UPDATE_MODIFIED_CONFIG = 'UPDATE_MODIFIED_CONFIG',
  DELETE_MODIFIED_CONFIG = 'DELETE_MODIFIED_CONFIG'
}