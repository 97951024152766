import classNames from 'classnames';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '../../components/Button/Button';
import { TooltipComponent } from '../../components/Tooltip/Tooltip';
import {
  DateStepIcon,
  DateTimeStepIcon,
  DictStepIcon,
  HelpStepIcon,
  HttpStepIcon,
  NumberStepIcon,
  OpenStepIcon,
  SmsStepIcon,
  StatementStepIcon,
  YesNoStepIcon
} from '../../icons';
import { AddConfigStepIcon } from '../../icons/AddConfigStepIcon';
import { AddNavigationStepIcon } from '../../icons/AddNavigationStepIcon';
import { AddScriptStepIcon } from '../../icons/AddScriptStepIcon';
import { DictationStepIcon } from '../../icons/DictationStepIcon';
import { EmailStepIcon } from '../../icons/EmailStepIcon';
import { EndStepIcon } from '../../icons/EndStepIcon';
import { GoToStepIcon } from '../../icons/GoToStepIcon';
import { RedirectStepIcon } from '../../icons/RedirectStepIcon';
import { SetVariableStepIcon } from '../../icons/SetVariableStepIcon';
import { DataCollectionStepIcon } from '../../icons/DataCollectionStepIcon';

import styles from './AddStepMenu.pcss';
import { FunctionStepIcon } from '../../icons/FunctionStepIcon';

type Props = {
  onStepTypeClick: (stepType: string, e: any) => void;
  allowedQuestionTypes?: string[];
  dataTest?: string;
}

interface AddStepItem {
  type: string;
  icon: any;
}

export type CreatorStepType = 'script' | 'config' | 'navigation';

const buttons: { type: CreatorStepType, icon: any }[] = [
  { type: 'script', icon: AddScriptStepIcon },
  { type: 'config', icon: AddConfigStepIcon },
  { type: 'navigation', icon: AddNavigationStepIcon }
];

const questionTypes: Record<CreatorStepType, AddStepItem[]> = {
  script: [
    { type: 'confirmation', icon: YesNoStepIcon },
    { type: 'word', icon: DictStepIcon },
    { type: 'statement', icon: StatementStepIcon },
    { type: 'open', icon: OpenStepIcon },
    { type: 'date', icon: DateStepIcon },
    { type: 'datetime', icon: DateTimeStepIcon },
    { type: 'numerical', icon: NumberStepIcon },
    { type: 'help_propose', icon: HelpStepIcon },
    { type: 'spelling', icon: DictationStepIcon }
  ].reverse(),
  config: [
    { type: 'set_variable', icon: SetVariableStepIcon },
    { type: 'data_collection', icon: DataCollectionStepIcon },
    { type: 'http', icon: HttpStepIcon },
    { type: 'send_sms', icon: SmsStepIcon },
    { type: 'send_email', icon: EmailStepIcon },
    { type: 'redirect', icon: RedirectStepIcon },
    { type: 'function', icon: FunctionStepIcon }

  ].reverse(),
  navigation: [
    { type: 'end', icon: EndStepIcon },
    { type: 'go_to_block', icon: GoToStepIcon }
  ].reverse()
};

type QuestionProps = { onStepTypeClick: (stepType: string, e: any) => void, stepType?: AddStepItem };
const Step = (props: QuestionProps) => {
  const intl = useIntl();
  return (
    <TooltipComponent
      tooltipText={intl.messages[`survey-creator.${props.stepType.type}StepTooltip`] as string}
    >
      <div className={classNames(styles.optionContainer)}
        onClick={(event) => props.onStepTypeClick(props.stepType.type, event)}
        data-test={'add-step-option'}
        data-add-question-type={props.stepType.type}>
        <div className={styles.iconContainer}>
          {props.stepType.icon && <props.stepType.icon animationClass={styles.icon} />}
        </div>
        <div className={styles.text}>
          {props.stepType.type && <FormattedMessage id={`survey-creator.${props.stepType.type}StepLabel`} />}
        </div>
      </div>
    </TooltipComponent>
  );
};


export const AddStepMenu = (props: Props) => {
  const allowedTypes = useCallback(
    (stepType: string) => props.allowedQuestionTypes ? questionTypes[stepType].filter(qt => props.allowedQuestionTypes.includes(qt.type)) : questionTypes[stepType],
    [props.allowedQuestionTypes]);
  const availableTypes = useMemo(() => Object.keys(questionTypes).filter((type) => allowedTypes(type).length > 0), [allowedTypes]);
  const availableButtons = useMemo(() => buttons.filter(button => availableTypes.includes(button.type)), [availableTypes]);
  const [selectedType, setSelectedType] = useState(() => availableTypes[0]);
  const stepsTypes = allowedTypes(selectedType);

  return (
    <div className={styles.container} data-test={'add-step-menu'}>
      <div className={styles.stepsContainer}>
        {
          stepsTypes.map((stepType) => (
            <Step key={stepType.type} onStepTypeClick={props.onStepTypeClick} stepType={stepType} />
          ))
        }
      </div>
      <div className={styles.row}>
        {
          availableButtons.map(button => (
            <div key={button.type} data-test={'open-step-type'}
              data-selected={selectedType === button.type}
              data-step-type={button.type}
              className={styles.addStepElement}
            >
              <Button
                buttonType={'link'}
                tabIndex={0}
                dataTest={`${button.type}-step-button`}
                className={styles.addStepIconButton}
                onClick={() => setSelectedType(button.type)}
              >
                <button.icon animationClass={classNames(styles.stepIcon, { [styles.selectedStepIcon]: selectedType === button.type })} />
              </Button>
            </div>
          ))
        }
      </div>
    </div>
  );
};
