import {MutableRefObject, useEffect, useRef} from 'react';


export const useOutsideClick = (ref: MutableRefObject<any>, callback: () => void) => {

    const handler = useRef<any>(null);
    useEffect(() => {
        return removeHandler;
    }, [])

    const addHandler = ()  => {
        document.addEventListener('click', outsideClick);
        handler.current = () => document.removeEventListener('click', outsideClick);
    }

    const removeHandler = () => {
        if (handler.current) {
            handler.current();
        }
        handler.current = null;
    }

    const outsideClick = (event) => {
        if (document.body.contains(event.target)) {
            if (!ref.current) {
                removeHandler();
            } else if (!ref.current.contains(event.target) && !document.getElementById('modal')?.contains(event.target)) {
                removeHandler();
                callback();
            }
        } else {
            removeHandler();
            if (ref.current) {
                callback();
            }
        }
    };
    return {
        addHandler,
        removeHandler
    }
}
