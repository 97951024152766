import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { StepElementProps } from './StepElementProps';
import { HttpStep } from '../model';
import { NumberInputWithStepper } from '../../components/NumberInputWithStepper/NumberInputWithStepper';
import { RefreshIcon } from '../../icons/RefreshIcon';
import styles from './HttpStepTimeoutComponent.pcss';
import { Label } from '../../components/Label';
import { Button } from '../../components/Button/Button';

const [DEFAULT_TIMEOUT, MIN_TIMEOUT, MAX_TIMEOUT, STEP] = [
  7_000,
  1_000,
  60_000,
  1_000,
];

export function HttpStepTimeoutComponent({
  onChange,
  question,
}: StepElementProps<HttpStep>) {
  const setHttpStepTimeout = (value: number) => {
    const previousTimeout = question.timeout;

    if (value === DEFAULT_TIMEOUT && previousTimeout === undefined) {
      onChange({ ...question });
    } else {
      onChange({ ...question, timeout: value });
    }
  };

  const httpStepTimeout = question.timeout || DEFAULT_TIMEOUT;

  return (
    <div className={styles.container}>
      <Label
        labelId="scheduler.step.httpStep.timeout"
        className={styles.label}
      />
      <div className={styles.content}>
        <NumberInputWithStepper
          dataTest="http-step-timeout-value"
          step={STEP}
          minValue={MIN_TIMEOUT}
          maxValue={MAX_TIMEOUT}
          value={httpStepTimeout}
          seconds
          onChange={setHttpStepTimeout}
        />
        <Button
          buttonType="link"
          className={styles.button}
          onClick={() => setHttpStepTimeout(DEFAULT_TIMEOUT)}
          dataTest="http-step-timeout-reset"
          disabled={httpStepTimeout === DEFAULT_TIMEOUT}
        >
          <FormattedMessage id="scheduler.step.httpStep.resetTimeout" />
          <RefreshIcon />
        </Button>
      </div>
    </div>
  );
}
