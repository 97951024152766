import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export function useDebounceValue<T>(
    debounceTime: number,
    enabled: boolean,
    onChange: (value: T) => void,
    initialValue: T,
): { setValue: (value: T, skipDebounce?: boolean) => void, value: T, flush: () => void} {
    const [isCurrentlyDebouncing, setIsCurrentlyDebouncing] = useState(false);
    const [valueState, setValueState] = useState(initialValue);

    const debouncedOnChange = useCallback(debounce((newValue: T) => {
        onChange(newValue);
        setIsCurrentlyDebouncing(false);
    }, debounceTime), [onChange]);

    const setValue = useCallback(
        (newValue?: T, skipDebounce: boolean = false) => {
            setValueState(newValue);
            if (enabled && !skipDebounce) {
                setIsCurrentlyDebouncing(true);
                debouncedOnChange(newValue);
            } else {
                onChange(newValue);
            }
        },
        [debouncedOnChange, enabled, onChange]
    );

    const flush = useCallback(() => {
        debouncedOnChange.flush();
    }, [debouncedOnChange]);

    useEffect(() => {
        if (valueState !== initialValue && !isCurrentlyDebouncing) {
            setValueState(initialValue);
        }
    }, [initialValue]);

    return { setValue, value: valueState, flush };
}
