import React from 'react';

import {IconProps} from './iconTypes';

export function CallbackIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <g fill="none" fillRule="evenodd">
              <g>
                  <g>
                      <g transform="translate(-28 -515) translate(12 497) translate(16 18)">
                          <path d="M0 0H24V24H0z"/>
                          <path className={props.animationClass} d="M3.06 11.81C3.414 6.334 7.961 2 13.52 2 19.308 2 24 6.701 24 12.5S19.308 23 13.519 23c-.335 0-.608-.273-.608-.609 0-.336.273-.608.608-.608 5.117 0 9.266-4.156 9.266-9.283s-4.149-9.283-9.266-9.283c-4.869 0-8.86 3.762-9.237 8.542l1.972-1.975c.237-.238.622-.238.86 0 .236.237.236.623 0 .86l-3.005 3.01c-.112.132-.278.216-.463.216-.186 0-.352-.084-.464-.215l-3.004-3.01c-.237-.238-.237-.623 0-.861s.622-.238.86 0L3.06 11.81zm10.307.84h4.709c.335 0 .608.272.608.608 0 .336-.273.609-.608.609h-5.317c-.335 0-.607-.273-.607-.61V6.41c0-.336.272-.609.607-.609.336 0 .608.273.608.609v6.24z"/>
                      </g>
                  </g>
              </g>
          </g>
      </svg>

    );
}
