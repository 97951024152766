import React from 'react';

import {VerticalSeparator} from '../../../surveyCreator/components/VerticalSeparator';
import {Box} from '../../Box/Box';
import {TabsLayoutProps} from '../types';

import styles from './VerticalTabsLayout.pcss';


export const VerticalTabsLayout: React.FC<TabsLayoutProps> = (
    {
        currentTab,
        tabs,
        onTabClick,
        getComponentForTab
    }) => <div className={styles.verticalTabsLayout}>
    <div className={styles.tabsContainer}>
        {
            tabs.map(tab => <Box
                key={tab.id}
                selected={currentTab === tab.id}
                onClick={() => onTabClick(tab.id)}
                dataTest={tab.dataTest}
                border
                selectable
            >
                {tab.label}
            </Box>)
        }
    </div>
    <VerticalSeparator noMargin/>
    <div className={styles.currentTabContent}>
        {getComponentForTab(currentTab)}
    </div>
</div>;