import _ from 'lodash';
import { useIntl } from 'react-intl';
import { PaletteItemsHook } from './types';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { PaletteStepSubtitle } from '../components/PaletteStepSubtitle';
import { linkElementAltAction } from './utils';
import { HttpStep, QuestionType } from '../../surveyCreator/model';
import React from 'react';
import { HttpStepIcon } from '../../icons';
import { useAllSteps } from './useAllSteps';

export const useHttpStepPathItems: PaletteItemsHook = () => {
  const intl = useIntl();
  const history = useHistory();
  const category = intl.formatMessage({ id: `survey-creator.command-palette.categories.paths` });

  const steps = useAllSteps();
  const httpStepPaths = steps.filter(step => step.type === QuestionType.HTTP)
    .map((step: (HttpStep & { href: string; moduleName: string; })) => {
      const paths = step.mappings.map(mapping => mapping.path);
      return paths.map((path) => {
        return {
          ...step,
          path,
        }
      });
    }).flat();

  return useMemo(() => httpStepPaths?.map(httpStep => ({
    id: `${httpStep.id}-${httpStep.path}-path`,
    name: httpStep.path,
    subtitle: <PaletteStepSubtitle moduleName={httpStep.moduleName} stepName={httpStep.name} />,
    category,
    onAction: () => history.push(httpStep.href),
    onAltAction: () => linkElementAltAction(httpStep.href),
    data: httpStep,
    Icon: ({ className }) => <HttpStepIcon animationClass={className} />
  })), [httpStepPaths]);
}