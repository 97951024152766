import React from 'react';

import { IndentedStepContent } from '../../../components/layouts/IndentedStepContent/IndentedStepContent';
import { Scrollbar } from '../../../components/Scrollbar/Scrollbar';
import { NumericalQuestion } from '../../model';
import { AddItemBar } from '../AddItemBar/AddItemBar';
import { AnonymizeAnswerToggle } from '../AnonymizeAnswerToggle';
import { DuplexToggle } from '../DuplexToggle';
import { GoToComponent } from '../GoToComponent';
import { IntentsTab } from '../IntentsTab/IntentsTab';
import { JoinNumbers } from '../JoinNumbers';
import { BasicMessages } from '../messages/BasicMessages';
import { NumberLength } from '../NumberLength';
import { NumericalRanges } from '../NumericalRanges';
import { RequiredAnswer } from '../RequiredAnswer';
import { SaveAs } from '../SaveAs';
import { PostSpeechTimeoutField } from '../PostSpeechTimeoutField/PostSpeechTimeoutField';
import { PreSpeechTimeoutField } from '../PreSpeechTimeoutField/PreSpeechTimeoutField';
import { RecognizerField } from '../RecognizerField/RecognizerField';

import { useNumericalQuestionAnswerProps } from './numerical-question.hooks';
import { TabFactory } from './question-component-factory-model';
import { StepTabType } from './types';

export const numericalQuestionFactory: TabFactory<NumericalQuestion> = {
    [StepTabType.Question]: ({ question, onChange, isConditional }) => {
        return (
            <>
                <BasicMessages question={question} onChange={onChange} isConditional={isConditional} />
            </>
        );
    },
    [StepTabType.General]: ({ question, onChange }) => {
        return (
            <>
                <RecognizerField question={question} onChange={onChange} />
                <SaveAs question={question} onChange={onChange} />
                <RequiredAnswer question={question} onChange={onChange} />
                <JoinNumbers question={question} onChange={onChange} />
                <DuplexToggle question={question} onChange={onChange} />
                <AnonymizeAnswerToggle question={question} onChange={onChange} />
                <PostSpeechTimeoutField question={question} onChange={onChange} />
                <PreSpeechTimeoutField question={question} onChange={onChange} />
            </>
        );
    },
    [StepTabType.Answer]: ({ question, onChange }) => {
        const props = useNumericalQuestionAnswerProps({ question, onChange });
        return (
            <>
                <AddItemBar
                    dataTest="add-range-button"
                    addButtonLabelId="survey-creator.numerical.addRangeButton"
                    descriptionId="survey-creator.numerical.addRangeDescription"
                    onClick={props.onAddRange}
                />
                <Scrollbar style={{margin: 0}}>
                    <NumericalRanges {...props} />
                    <IndentedStepContent withTopPadding>
                        <GoToComponent question={question} availableOptions={['outOfRange']} onChange={onChange} />
                        <NumberLength question={question} onChange={onChange} />
                        <GoToComponent question={question} availableOptions={['repeated']} onChange={onChange} />
                    </IndentedStepContent>
                </Scrollbar>
            </>
        );
    },
    [StepTabType.Intents]: (props) => <IntentsTab {...props} />
};
