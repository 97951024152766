import { FormattedMessage } from 'react-intl';
import { Button } from '../components/Button/Button';
import * as React from 'react';
import { BotFilter } from './Dashboard';
import styles from './TagFilter.pcss';
import { Tag } from '../components/Tag/Tag';
import TagFilterModal from './TagFilterModal';
import { useState } from 'react';

type TagFilterProps = {
  botFilter: BotFilter;
  setBotFilter: (
    value:
      | null
      | BotFilter
      | ((prevState: null | BotFilter) => null | BotFilter)
  ) => void;
};

export default function TagFilter({ botFilter, setBotFilter }: TagFilterProps) {
  const [isModal, setIsModal] = useState(false);

  const removeTag = (tagToRemove: string) =>
    setBotFilter((state) => ({
      ...state,
      tags: state.tags.filter((tag) => tag !== tagToRemove),
    }));

  return (
    <>
      <div className={styles.container}>
        <Button
          dataTest="open-filters-modal-button"
          buttonType="link"
          onClick={() => setIsModal(true)}
        >
          <FormattedMessage id="dashboard.botsView.filterByTags.button" />
          {botFilter.tags.length > 0 && ':'}
        </Button>

        <div data-test="filters-tag-list" className={styles.tagList}>
          {botFilter.tags.map((tag) => (
            <Tag dataTest="filters-tag" onClick={removeTag} withCross key={tag}>
              {tag}
            </Tag>
          ))}
        </div>
      </div>

      {isModal && (
        <TagFilterModal
          onSubmit={(tags) => setBotFilter((state) => ({ ...state, tags }))}
          onClose={() => setIsModal(false)}
          selectedTags={botFilter.tags}
        />
      )}
    </>
  );
}
