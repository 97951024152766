import * as React from 'react';

import {CancelIcon} from '../../../icons';

import {MenuOption} from './OptionsMenu';

type DeleteOptionProps = {
    onClick: (event: any) => void,
}

export const RemoveFromGroupOption: React.FC<DeleteOptionProps> = ({onClick}) => {
    return (
        <>
                <MenuOption
                  dataTest="remove-from-group-option"
                  onClick={onClick}
                  icon={CancelIcon}
                  hoverType={'warning'}
                  tooltipLabelId="menuOption.removeFromGroup.tooltip"
                />
        </>
    );
}
