import React from 'react';

import {IconProps} from './iconTypes';

export function DoneIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H24V24H0z"/>
                <path className={props.animationClass}
                      d="M12 2.962c4.991 0 9.038 4.046 9.038 9.037 0 4.992-4.047 9.038-9.038 9.038-4.991 0-9.037-4.046-9.037-9.038 0-4.991 4.046-9.037 9.037-9.037zm0 1.2c-4.329 0-7.838 3.509-7.838 7.837 0 4.329 3.51 7.838 7.838 7.838 4.329 0 7.837-3.51 7.837-7.838S16.33 4.162 12 4.162zm4.872 3.488c.231.183.292.501.158.752l-.06.091-5.874 7.433c-.272.357-.698.56-1.146.547-.398-.011-.77-.194-1.016-.487l-.087-.115-1.837-2.608c-.191-.271-.126-.645.144-.836.244-.172.572-.137.774.07l.062.075 1.846 2.62c.034.05.09.08.15.082.04.001.079-.01.113-.036l.048-.047 5.882-7.442c.206-.26.583-.304.843-.099z"/>
            </g>
        </svg>
    );
}
