

export class StoppableInterval {
  private intervalId: number;

  private timeoutId: number;
  private fireInterval: number;
  private stopTime: number;
  withFireInterval(fireInterval: number) {
    this.fireInterval = fireInterval;
    return this;
  }

  withHandler(fn: Function) {
    this.intervalHandlerFn = fn;
    return this;
  }

  withStopTimeout(stopTime: number, fn: Function) {
    this.stopTime = stopTime;
    this.timeoutHandlerFn = fn;
    return this;
  }

  start() {
    if (!this.fireInterval || !this.intervalHandlerFn) {
      throw new Error('both fire interval and handler needs to be provided to start');
    }
    this.createInterval();
    this.createTimeout();
  }

  stop() {
    clearInterval(this.intervalId);
    clearTimeout(this.timeoutId);
  }

  reset() {
    this.stop();
    this.start();
  }

  private createInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.intervalId = setInterval(this.intervalHandlerFn, this.fireInterval);
  }

  private createTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    if (this.stopTime) {
      // @ts-ignore
      this.timeoutId = setTimeout(() => {
        this.stop();
        this.timeoutHandlerFn();
      }, this.stopTime);
    }
  }

  private intervalHandlerFn: Function = () => ({});
  private timeoutHandlerFn: Function = () => ({});

}
