import classNames from 'classnames';
import * as React from 'react';
import { useIntl } from 'react-intl';

import { ClearIcon } from '../../components/ClearIcon/ClearIcon';
import { Input } from '../../components/Input';
import { useDebounceValue } from '../../utils/withDebounce';

import filterStyles from './FilterInputElement.pcss';

interface Props {
    readonly className?: string;
    readonly value?: string;
    readonly withDebounce?: boolean;
    readonly iconClass?: string;
    readonly dataTestPrefix?: string;
    readonly placeholderId?: string;
    readonly icon?: React.ReactNode;

    onChange(value?: string): void;
}

export function FilterInputElement(props: Props) {
    const intl = useIntl();
    const { setValue, value, flush } = useDebounceValue(500, props.withDebounce, props.onChange, props.value);

    return (
        <div className={classNames(filterStyles.item, props.className)}>
            {props.iconClass && (
              <i className={classNames(filterStyles.icon, props.iconClass)} />
            )}
            {props.icon && (
              <span className={filterStyles.icon}>{props.icon}</span>
            )}
            <Input
                className={filterStyles.inputWrapper}
                dataTest={`${props.dataTestPrefix}__value`}
                type="text"
                placeholder={`${intl.messages[props.placeholderId]}`}
                value={value}
                onChange={setValue}
                onBlur={flush}
            />
            <ClearIcon className={filterStyles.clearIcon} enabled={!!value} onClear={() => setValue('', true)} />
        </div>
    );
}
