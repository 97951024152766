import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Value } from '../../components/Select/Select';
import { MultiSelectComponent } from '../../surveyCreator/components/MultiSelect';
import { DayOfWeek, Schedule } from '../schedule-model';

import styles from './DateOptions.pcss';

type Props = {
    schedule: Schedule;
    setSchedule: (schedule: Schedule) => void;
};

const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const DateOptions = ({ schedule, setSchedule }: Props) => {
    const intl = useIntl();

    const setNewCallingDays = (newCallingDays: Value[]) =>
        setSchedule({
            ...schedule,
            allowedDaysOfWeekRule: {
                daysOfWeek: newCallingDays.map(el => el.id as DayOfWeek)
            }
        });

    return (
        <div className={styles.dateOptions}>
            <div className={styles.callingDays}>
                <div className={styles.label}>
                    <span>
                        <FormattedMessage id={'scheduler.callingDays.text'} />
                    </span>
                </div>
                <div className={styles.daysSelect}>
                    <MultiSelectComponent
                        onChange={setNewCallingDays}
                        value={
                            schedule &&
                            schedule.allowedDaysOfWeekRule &&
                            schedule.allowedDaysOfWeekRule.daysOfWeek.map(el => ({
                                id: el,
                                name: intl.messages[`scheduler.callingDays.${el}`] as string
                            }))
                        }
                        options={weekDays.map(el => ({
                            id: el,
                            name: intl.messages[`scheduler.callingDays.${el}`] as string
                        }))}
                        placeholderMessage={'scheduler.dateOptions.placeholder'}
                    />
                </div>
            </div>
        </div>
    );
};
