import * as React from 'react';
import { FC } from 'react';
import { useHistory } from 'react-router';

import { PageContainer } from '../components/PageContainer';
import { useAccept } from './terms-of-service-api';
import { showErrorToast } from '../../components/Toast/Toast';
import { MarkdownContent } from '../../components/MarkdownContent/MarkdownContent';
import TermsOfServiceModal from './TermsOfServiceModal';

const TermsOfServicePage: FC = () => {
  const { mutateAsync, isLoading } = useAccept();
  const history = useHistory();

  const onAccept = async () => {
    try {
      await mutateAsync();
      history.push('/');
    } catch {
      showErrorToast(<MarkdownContent contentKey="generalError" />);
    }
  };

  return (
    <PageContainer>
      <TermsOfServiceModal buttonTextKey="termsOfServiceModal.accept" onClick={onAccept} isSubmitting={isLoading}/>
    </PageContainer>
  );
};

export default TermsOfServicePage;
