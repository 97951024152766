import React from 'react';

import {IconProps} from '../iconTypes';

export function ConversationPreviewIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" data-test={props.dataTest}>
      <path
        className={props.animationClass}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.224 15.597A8.428 8.428 0 0 1 12.56 3a.598.598 0 0 1-.002 1.195 7.233 7.233 0 0 0-6.151 11.056c.107.173.12.388.032.572L4.86 19.14l3.317-1.58a.598.598 0 0 1 .572.033 7.233 7.233 0 0 0 11.056-6.151.598.598 0 0 1 1.195-.002 8.428 8.428 0 0 1-12.597 7.337l-4.549 2.166a.598.598 0 0 1-.796-.796l2.166-4.549zM18.959 5.241 17.74 4.02a.598.598 0 0 1 .845-.845l2.24 2.24a.597.597 0 0 1 0 .846l-2.24 2.24a.598.598 0 0 1-.845-.845l1.22-1.22H17.04a3.884 3.884 0 0 0-3.884 3.883.598.598 0 0 1-1.195 0 5.079 5.079 0 0 1 5.079-5.078h1.92z"
        fill="#919AAF"
      />
    </svg>
  );
}
