import {useEffect, useRef} from 'react';

import {HttpClient} from '../../utils/HttpClient';

export const useAudioPlayer = ({url, params}: { url: string, params?: object }) => {
    const audioRef = useRef<HTMLAudioElement>();

    useEffect(() => {
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
            }
        };
    }, []);

    return async () => {
        await HttpClient.verifyToken();
        const fullUrl = HttpClient.getUrl(url, params);
        if (audioRef.current) {
            audioRef.current.src = fullUrl;
            audioRef.current.load();
        } else {
            audioRef.current = new Audio(fullUrl);
        }
        audioRef.current.play();
    };
};
