import * as React from 'react';
import {useIntl} from 'react-intl';

import {Box} from '../../components/Box/Box';
import {ElementWithLabel} from '../../components/ElementWithLabel/ElementWithLabel';
import {SelectComponent} from '../../components/Select/Select';
import {TextAreaWithVariables} from '../../components/TextArea/TextAreaWithVariables';
import { useSurveyCreator } from '../../surveyCreator/useSurveyCreator';
import {useCurrentBot} from '../../utils/CurrentBot';
import {AnyStep, HttpStepConfig, OnStepChangeType} from '../schedule-model';

import {CollapsedStep} from './CollapsedStep';
import {HttpHeadersComponent} from './HttpHeadersComponent';
import styles from './HttpStep.pcss';

interface HttpStepProps {
    step: AnyStep<HttpStepConfig>;
    isCollapsed: boolean;
    onStepChange: OnStepChangeType;
}

export const HttpStep = ({ step, isCollapsed, onStepChange }: HttpStepProps) => {
    const currentBot = useCurrentBot();
    const { definition } = useSurveyCreator(currentBot);
    const intl = useIntl();

    function requestMayContainBody() {
        return ['post', 'put', 'delete'].includes(step.stepConfig.method);
    }

    const plannerVariables = definition.variables.getVariablesForPlanner();
    return (

        isCollapsed ? <CollapsedStep messageId={'scheduler.steps.httpCollapsed'} /> :
            <Box border className={styles.httpStepBox}>
                <div className={styles.urlMethodBlock}>
                    <ElementWithLabel inner className={styles.urlBlock} labelId={'scheduler.step.httpStep.url'}>
                        <TextAreaWithVariables
                            dataTest={'http-url'}
                            placeholder={intl.messages['survey-creator.httpStep.urlPlaceholder'] as string}
                            value={step.stepConfig.url || ''}
                            asInput
                            availableVariables={definition.variables.getVariablesForPlanner()}
                            onChange={(value) => onStepChange({
                                ...step,
                                stepConfig: { ...step.stepConfig, url: value }
                            })}
                        />
                    </ElementWithLabel>

                    <ElementWithLabel inner className={styles.methodBlock} labelId={'scheduler.step.httpStep.method'}>
                        <SelectComponent
                            data-test={'http-method'}
                            options={[
                                { id: 'get', name: 'get' },
                                { id: 'post', name: 'post' },
                                { id: 'put', name: 'put' },
                                { id: 'delete', name: 'delete' },
                            ]}
                            value={{ id: step.stepConfig.method, name: step.stepConfig.method }}
                            onChange={(value) => onStepChange({
                                ...step,
                                stepConfig: { ...step.stepConfig, method: value.id }
                            })}
                        />
                    </ElementWithLabel>

                </div>
                <div className={styles.headersBlock}>
                    <HttpHeadersComponent headers={step.stepConfig.headers || []}
                                          variables={plannerVariables}
                        onChange={(value) => onStepChange({
                            ...step,
                            stepConfig: { ...step.stepConfig, headers: value }
                        })}
                    />
                </div>
                {requestMayContainBody() && <div>
                    <ElementWithLabel inner labelId={'scheduler.step.httpStep.body'}>
                        <TextAreaWithVariables
                            dataTest={'http-body'}
                            availableVariables={definition.variables.getVariablesForPlanner()}
                            onChange={(body: string) => {
                                const stepConfig: HttpStepConfig = { ...step.stepConfig, body };
                                onStepChange({
                                    ...step,
                                    stepConfig
                                });
                            }}
                            value={step.stepConfig.body}
                        />
                    </ElementWithLabel>
                </div>
                }
            </Box>
    );
};
