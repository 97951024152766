import _ from 'lodash';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {Box} from '../../components/Box/Box';
import {CollapsiblePanel} from '../../components/CollapsiblePanel/CollapsiblePanel';
import {IconButton} from '../../components/IconButton/IconButton';
import {SelectComponent} from '../../components/Select/Select';
import {DeleteIcon} from '../../icons';
import {
    AnyStep,
    FollowUpInGivenDaysAtScheduleStep,
    FollowUpInGivenHoursScheduleStep,
    getTemplateScheduleStep,
    StepConfig,
    StepType
} from '../schedule-model';

import {FollowUpInGivenDays} from './FollowUpInGivenDaysComponent';
import {FollowUpInGivenHours} from './FollowUpInGivenHoursComponent';
import {HttpStep} from './HttpStep';
import {SmsStep} from './SmsStep';
import styles from './StepBar.pcss'

interface StepBarProps {
    stepIndex: number,
    dataTest: string,
    onStepRemove: React.MouseEventHandler,
    onStepChange: (step: AnyStep) => void,
    disableRemove: boolean,
    step: AnyStep,
}

export const StepBar: React.FC<StepBarProps> = ({
    stepIndex,
    dataTest,
    onStepRemove,
    onStepChange,
    disableRemove,
    step
}) => {
    const intl = useIntl();
    const stepTypeOptions = ['JustInTime', 'FollowUpInGivenHours', 'FollowUpInGivenDaysAt'].map(stepType => ({
        id: stepType,
        name: intl.messages[`scheduler.steps.callStep.type.${stepType}`] as string
    }));

    return <div data-test={dataTest}>
        <Box border className={styles.stepBar} dataTest={'step'}>
            <div className={styles.stepTypeLabel} data-test={'step-name'}>
                <FormattedMessage tagName="div" id={'scheduler.steps.titleLabel'}>
                    {msg => `${msg} ${stepIndex + 1}`}
                </FormattedMessage>
                <FormattedMessage tagName="div" id={`scheduler.steps.${step.stepConfig.transport}Header`} />
            </div>
            <div className={styles.controls}>
                <SelectComponent
                    data-test="step-type-select"
                    value={stepTypeOptions.find(el => el.id === step.type)}
                    options={stepTypeOptions}
                    onChange={newStep => {
                        if (newStep.id !== step.type) {
                            onStepChange(getTemplateScheduleStep(newStep.id as StepType, step.stepConfig.transport, step.stepConfig));
                        }
                    }}
                />
                {step.type === 'FollowUpInGivenDaysAt' &&

                    <FollowUpInGivenDays step={step as unknown as FollowUpInGivenDaysAtScheduleStep<StepConfig>} onStepChange={onStepChange} />
                }
                {
                    step.type === 'FollowUpInGivenHours' &&
                    <FollowUpInGivenHours step={step as unknown as FollowUpInGivenHoursScheduleStep<StepConfig>} onStepChange={onStepChange} />
                }
                <span className={styles.deleteButton}>
                    <IconButton
                        data-test="remove-step-btn"
                        Icon={DeleteIcon}
                        animationClass={styles.deleteIcon}
                        onClick={onStepRemove}
                        disabled={disableRemove}
                    />
                </span>
            </div>
        </Box>
        {step.stepConfig.transport !== 'call' && <CollapsiblePanel
            isInitiallyCollapsed={
                (step.stepConfig.transport === 'sms' && !_.isEmpty(step.stepConfig.messageTemplate)) ||
                (step.stepConfig.transport === 'http' && !_.isEmpty(step.stepConfig.url))
            }
        >
            {
                step.stepConfig.transport === 'sms' ?
                    (isCollapsed) => <SmsStep step={step} isCollapsed={isCollapsed} onStepChange={onStepChange} /> :
                    (isCollapsed) => <HttpStep step={step} isCollapsed={isCollapsed} onStepChange={onStepChange} />
            }
        </CollapsiblePanel>}
    </div>
}
