import React from 'react';

import {IconProps} from './iconTypes';

export function NumberStepIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H24V24H0z"/>
                <path className={props.animationClass} d="M2.464 16.532c1.072.188 1.822 1.165 1.728 2.25-.045.516-.274.976-.62 1.315.344.34.575.798.621 1.314.098 1.082-.646 2.06-1.715 2.255-1.07.195-2.11-.459-2.4-1.506-.089-.32.099-.65.418-.738.32-.089.65.099.738.418.125.449.57.729 1.029.645.458-.083.777-.502.735-.966-.039-.428-.373-.764-.79-.813l-.215-.014c-.655-.098-.656-1.085-.001-1.184l.215-.014c.418-.05.752-.387.79-.816.04-.465-.281-.884-.741-.964-.421-.075-.83.158-.989.544l-.036.11c-.086.32-.415.51-.735.424-.32-.086-.51-.415-.424-.735.281-1.052 1.32-1.714 2.392-1.525zM23.101 21c.331 0 .6.269.6.6 0 .298-.217.546-.503.592l-.097.008h-15c-.331 0-.6-.269-.6-.6 0-.298.218-.546.503-.592L8.1 21h15zm-21-12c1.16 0 2.1.94 2.1 2.1 0 .552-.167 1.09-.476 1.544l-.123.167-.14.126-.857.563h.996c.298 0 .546.218.592.503l.008.097c0 .298-.217.546-.503.592l-.097.008h-3c-.563 0-.8-.69-.406-1.043l.077-.058 2.441-1.606.039-.052c.135-.208.218-.445.242-.692L3 11.1c0-.497-.403-.9-.9-.9-.459 0-.837.343-.893.787l-.007.113c0 .331-.269.6-.6.6-.331 0-.6-.269-.6-.6 0-1.16.94-2.1 2.1-2.1zm21 3c.331 0 .6.269.6.6 0 .298-.217.546-.503.592l-.097.008h-15c-.331 0-.6-.269-.6-.6 0-.298.218-.546.503-.592L8.1 12h15zM.601 7.2c-.331 0-.6-.269-.6-.6 0-.298.218-.546.503-.592L.6 6l.9-.001V1.35c0-.066-.043-.123-.102-.142L1.35 1.2h-.75c-.331 0-.6-.269-.6-.6C.001.302.22.054.504.008L.6 0h.75c.702 0 1.279.535 1.344 1.22l.006.13v4.649l.9.001c.331 0 .6.269.6.6 0 .298-.217.546-.503.592l-.097.008h-3zm22.5-4.2c.331 0 .6.269.6.6 0 .298-.217.546-.503.592l-.097.008h-15c-.331 0-.6-.269-.6-.6 0-.298.218-.546.503-.592L8.1 3h15z"/>
            </g>
        </svg>
    );
}
