import * as React from 'react';

import {Block} from '../model';

import styles from './BlockQuestionOptions.pcss';
import {DeleteOption} from './optionsMenu/DeleteOption';
import {DuplicateOption} from './optionsMenu/DuplicateOption';
import {MoveQuestionOption} from './optionsMenu/MoveQuestionOption';
import {OptionsComponent} from './optionsMenu/Options';
import {QuestionScopeType} from './BlockQuestionComponent';

type Props = {
    onDelete?: (event: any) => any;
    isStepDisabled?: boolean;
    onDuplicate?: (event: any) => any;
    onMove?: (event: any, blockId: string) => void;
    blocks?: Block[];
    currentBlock?: Block;
    scope: QuestionScopeType
}

export const BlockQuestionOptionsComponent = (props: Props) => {
    const options = [
        <DeleteOption key={'delete-option'} onDelete={props.onDelete} />,
        ...(props.scope !== 'callback'
            ? [<MoveQuestionOption key={'move-option'} onMove={props.onMove} blocks={props.blocks} currentBlock={props.currentBlock} />]
            : []),
        <DuplicateOption key={'duplicate-option'} onClick={props.onDuplicate} />,
    ];

    return (
        <OptionsComponent
            containerPositionClassName={styles.optionsPosition}
            options={options} />
    )
};
