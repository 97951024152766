import React from 'react';
import cx from 'classnames';

import { Box } from '../components/Box/Box';
import styles from './ExtendedLabel.pcss';

type ExtendedLabelProps = {
  dataTest?: string;
  className?: string;
  label: string;
  value?: string;
  isPrimary?: boolean;
}

export const ExtendedLabel = ({ dataTest, className, label, value, isPrimary = false }: ExtendedLabelProps) => {
  return <Box noPadding className={cx(styles.extendedLabel, className)}>
    <label className={cx({ [styles.hasValue]: Boolean(value) })}>{label}</label>
    {
      value &&
      <span data-test={dataTest} className={cx(styles.value, { [styles.primary]: isPrimary })}>{value}</span>
    }
  </Box>
}