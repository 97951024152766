import React, { useContext, useMemo } from 'react';
import _ from 'lodash';

import {AnswerTabContainerProps} from './types';

import {AnswerTab} from '../../components';

import {useDictionaryQuestionValues} from '../../hooks';
import {SurveyContext, SurveyContextType} from '../../../SurveyCreator';
import {useEntities} from '../../../components/EntityDetails/hooks/useEntities';
import {AnswerType, DisambiguationItem} from '../../types';
import {Model} from '../../services';

type GlobalSynonymsContextType = { globalSynonyms: string[] };
type DisambiguationContextType = { disambiguation: DisambiguationItem[] };

export const BlocksContext = React.createContext({ blocks: [] });
export const GlobalSynonymsContext = React.createContext<GlobalSynonymsContextType>({ globalSynonyms: [] });
export const DisambiguationContext = React.createContext<DisambiguationContextType>({ disambiguation: [] });

const AnswerTabContainer: React.FC<AnswerTabContainerProps> = ({ question, onChange }) => {
    const { entities } = useEntities();

    const handlePartialQuestionChange = (changed) => {
        return onChange({
            ...question,
            ...changed,
        });
    };
    const partialQuestion = useMemo<Model>(() => (
        _.pick(question, ['dictionary', 'reactions', 'notPreciseAnswer', 'goTo', 'defaultReadAnswerType'])
    ), [question]);

    const [questionViewValue, onQuestionChange] = useDictionaryQuestionValues(partialQuestion, handlePartialQuestionChange);

    const { globalSynonyms, blocks } = useContext<SurveyContextType>(SurveyContext);

    const availableEntities = useMemo(() => {
        const base = entities || [];
        return base.filter(entity => {
            return !questionViewValue?.answersList.find(answer => {
                return answer.type === AnswerType.ENTITY && answer.id === entity;
            });
        });
    }, [entities, questionViewValue?.answersList]);

    return <BlocksContext.Provider value={{ blocks: blocks.available() }}>
        <GlobalSynonymsContext.Provider value={{ globalSynonyms: globalSynonyms.get() }}>
            <DisambiguationContext.Provider value={{ disambiguation: questionViewValue?.disambiguation }}>
                <AnswerTab
                    question={questionViewValue}
                    entities={availableEntities}
                    onChange={onQuestionChange}
                />
            </DisambiguationContext.Provider>
        </GlobalSynonymsContext.Provider>
    </BlocksContext.Provider>
}

export default AnswerTabContainer;
