import * as React from 'react';

import { ColumnFactory } from '../components/callsList/columns/ColumnFactory';

import { selectedStyles, TableCell, TableHeader } from './Inspector';
import { WithSelectableRowsProps } from './Inspector.hoc';

export type GetQuestionName = (questionId: string) => string;

export const questionNameColumn: (
    questionName: GetQuestionName,
    selectedRows: WithSelectableRowsProps['selectedRows']
) => ColumnFactory = (questionName, selectedRows) => () => ({
    Header: <TableHeader labelKey="inspector.stepName" />,
    id: 'stepName',
    Cell: (row: any) => (<TableCell 
        className={selectedStyles(selectedRows, row.index)}
        value={questionName(row.original.questionId)} 
        />),
    sortable: false,
    maxWidth: 300,
    minWidth: 100
});

