import * as React from 'react';

import {EditIcon} from '../../../icons';

import {MenuOption} from './OptionsMenu';


export const EditOption = (props: { onClick: (event: any) => void }) => {
    return (
        <>
                <MenuOption dataTest="edit-option" onClick={props.onClick} icon={EditIcon}
                tooltipLabelId="menuOption.rename.tooltip"/>
        </>
    );
}
