import _ from 'lodash';
import React from 'react';

import { DeleteIcon } from '../../icons';
import { useSynonymsForWord } from '../../surveyCreator/components/EntityDetails/hooks/useSynonymsForWord';
import { Synonym } from '../../surveyCreator/model';
import { Box } from '../Box/Box';
import { SynonymsChipsComponent } from '../chips/SynonymsChipsComponent';
import { SynonymsChipsListComponent } from '../chips/SynonymsChipsListComponent';
import { ElementWithLabel } from '../ElementWithLabel/ElementWithLabel';
import { IconButton } from '../IconButton/IconButton';

import { AnswerComponentProps } from './AnswersList';
import styles from './AnswersList.pcss';

export const DefaultAnswerComponent: React.FC<AnswerComponentProps> = ({
  children,
  answer,
  globalWords,
  onChange,
  onDelete
}) => {
  const onSynonymsChange = (synonyms: Synonym[]) => {
    onChange({ ...answer, synonyms: _.uniq(synonyms) });
  }

  const matchingGlobalWord = globalWords && Object.values(globalWords).find(
    globalWord => globalWord.data?.word === answer.key
  );
  useSynonymsForWord(matchingGlobalWord?.data?.word);

  return (
    <Box
      border
      dataTest={'answer'}
      className={styles.answer}
      noPadding
    >
      <IconButton Icon={DeleteIcon} hoverType={'warning'} onClick={onDelete} dataTest={'delete-answer-button'}
        className={styles.deleteAnswerButton} />
      {children}
      {
        matchingGlobalWord &&
        matchingGlobalWord.isReady &&
        matchingGlobalWord.data.synonyms && <ElementWithLabel
          className={styles.defaultAnswerGlobalSynonyms}
          labelId={'survey-creator.entityGlobalSynonyms'}
          inner
        >
          <SynonymsChipsListComponent
            className={styles.globalSynonymsChips}
            data-test={'global-synonyms'}
            items={matchingGlobalWord.data.synonyms}
            showElementsNumber={4}
            onItemClick={(synonym) => onSynonymsChange([...answer.synonyms, synonym])}
          />
        </ElementWithLabel>
      }
      <ElementWithLabel labelId={'survey-creator.entitySynonyms'} inner>
        <SynonymsChipsComponent data-test={'synonyms'} items={answer.synonyms}
          i18nKey={'survey-creator.dictionaryPlaceholder'} onChange={onSynonymsChange} />
      </ElementWithLabel>
    </Box>
  );
}