import * as React from 'react';
import { useContext } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage, useIntl } from 'react-intl';

import { Block } from '../model';
import { SurveyContext, SurveyContextType } from '../SurveyCreator';

import { BlockComponent } from './Block';
import styles from './Blocks.pcss';
import { FallbackBlock } from './FallbackBlock/FallbackBlock';
import { InformationIcon } from '../../icons/InformationIcon';
import { TooltipComponent } from '../../components/Tooltip/Tooltip';
import { HorizontalSeparator } from '../../components/HorizontalSeparator/HorizontalSeparator';
import { useCreatorRouter } from '../useCreatorRouter';

export type BlocksProps = {
    onEditToggle?: (value: boolean) => void;
}

export const Blocks: React.FC<BlocksProps> = ({ onEditToggle }) => {
    const intl = useIntl();
    const { blocks, fallback, silenceFallback, blocksRef } = useContext<SurveyContextType>(SurveyContext);
    const setBlockAsFirst = (block: Block) => blocks.setAsFirst(block);
    const updateBlockName = (name: string, block: Block) => blocks.rename(block.id, name);
    const handleClone = (block: Block) => blocks.clone({
        ...block,
        name: `${block.name} (${intl.messages['survey-creator.cloneLabel']})`
    });

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        blocks.move(blocks.current, result.source.index, result.destination.index);
    };

    const { navigateToFallback, navigateToSilenceFallback } = useCreatorRouter()

    return (
        <div ref={blocksRef} className={styles.blocks}>
            <div className={styles.titleWithIcon}>
                <span className={styles.title}>
                    <FormattedMessage id={'survey-creator.blocksLabel'} />
                </span>

                <TooltipComponent tooltipText={intl.messages['survey-creator.blocksTooltip'] as string}>
                    <div style={{ padding: '5px' }}>
                        <InformationIcon />
                    </div>
                </TooltipComponent>

            </div>
            <div className={styles.list}>
                <DragDropContext data-test={'blocks-drag-drop-container'} onDragEnd={onDragEnd}>
                    <Droppable droppableId="blocksDroppable">
                        {(droppable) => (
                            <div {...droppable.droppableProps} ref={droppable.innerRef}>
                                {
                                    blocks.get().map((block, index) => {
                                        return (
                                            <BlockComponent
                                                key={block.id}
                                                block={block}
                                                index={index}
                                                onEditToggle={onEditToggle}
                                                selected={
                                                    blocks.current &&
                                                    blocks.current.id === block.id
                                                }
                                                dataTest={'survey-creator-block'}
                                                removeBlock={blocks.remove}
                                                cloneBlock={handleClone}
                                                setBlockAsFirst={setBlockAsFirst}
                                                onBlockNameUpdate={
                                                    updateBlockName
                                                }
                                            />
                                        );
                                    })
                                }
                                {droppable.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <HorizontalSeparator />
                <FallbackBlock
                    navigateToFallback={navigateToFallback}
                    fallback={fallback.get()}
                    selected={fallback.selected} />
                <FallbackBlock
                    navigateToFallback={navigateToSilenceFallback}
                    dataTest="survey-creator-silence-fallback-block"
                    itemName="survey-creator.silenceFallback"
                    fallback={silenceFallback.get()}
                    selected={silenceFallback.selected}
                />
            </div>
        </div>
    );
};
