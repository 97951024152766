import React from 'react';

import {IconProps} from './iconTypes';

export function PlusIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H24V24H0z"/>
                <circle cx="10" cy="10" r="10" className={props.animationClass} fill="#3C38BC" transform="translate(2 2)"/>
                <path fill="#FFF" fillRule="nonzero" d="M12 7.65c.298 0 .546.218.592.503l.008.097v3.149l3.15.001c.331 0 .6.269.6.6 0 .298-.218.546-.503.592l-.097.008-3.15-.001v3.151c0 .331-.269.6-.6.6-.298 0-.546-.218-.592-.503l-.008-.097v-3.151l-3.15.001c-.331 0-.6-.269-.6-.6 0-.298.218-.546.503-.592l.097-.008 3.15-.001V8.25c0-.331.269-.6.6-.6z"/>
            </g>
        </svg>


    );
}
