import * as React from 'react';
import {FormattedMessage} from 'react-intl';

import {Button} from '../../../../components/Button/Button';
import {Vertically} from '../../../../components/layouts/Vertically';
import {Modal} from '../../../../components/Modal/Modal';
import {IntentSentence} from '../../../model';

import styles from './DuplicateSentencesPopup.pcss'
import {SentenceList} from './SentenceList';

type Props = {
    duplicates: IntentSentence[];
    onClose: () => void;
};

export const DuplicateSentencesPopup = ({duplicates, onClose}: Props) =>
    <Modal position={'center'} className={styles.modal} dataTest={'duplicates-popup'}>
        <Vertically layout={'wide'}>
            <div className={styles.header}>
                <FormattedMessage id={'survey-creator.intentDetails.duplicateSentencesHeader'}/>
            </div>
            <div className={styles.list}>
                <div>
                    <FormattedMessage id={'survey-creator.intentDetails.duplicateSentencesList'}/>
                </div>
                <SentenceList data-test="duplicates-list"
                              sentences={duplicates}
                              isRemovable={false}
                              type={'talkie'}
                />
            </div>
            <div className={styles.footer}>
                <Button buttonType={'normal'}
                        type="button"
                        dataTest="close-button"
                        onClick={onClose}>
                    <FormattedMessage id={'survey-creator.intentDetails.confirmDuplicates'}/>
                </Button>
            </div>
        </Vertically>
    </Modal>;


