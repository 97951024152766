import React from 'react';

import {IconProps} from './iconTypes';

export function RandomIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <defs>
          <filter id="prefix__a">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"/>
          </filter>
        </defs>
        <g fill="none" fillRule="evenodd" filter="url(#prefix__a)" transform="translate(-3 -3)">
          <path fill="#000" d="M10.251 3.376c1.113-.501 2.385-.501 3.497 0l5.477 2.464c.776.349 1.275 1.126 1.275 1.982v8.356c0 .856-.499 1.633-1.274 1.982l-5.477 2.464c-.553.249-1.144.374-1.735.376h-.027c-.591-.002-1.183-.127-1.735-.376l-5.477-2.463C3.999 17.812 3.5 17.036 3.5 16.18V7.825c-.002-.859.497-1.639 1.275-1.988l5.476-2.46zM4.64 7.62c-.013.066-.02.134-.02.203v8.356c0 .414.241.788.613.955l5.477 2.464c.236.106.481.181.73.227v-9.452L4.64 7.62zm14.72 0l-6.8 2.752.001 9.452c.182-.033.362-.082.537-.148l.192-.079 5.477-2.464c.372-.167.612-.54.612-.955V7.822c0-.069-.006-.136-.02-.202zm-6.07-3.218c-.821-.37-1.759-.37-2.58 0L5.487 6.75l6.512 2.636 6.51-2.635-5.22-2.348zM6.444 9.64c.539 0 .977.44.977.984 0 .543-.438.984-.977.984-.54 0-.978-.441-.978-.984 0-.543.438-.984.978-.984zm1.19 2.82c.539 0 .977.44.977.983 0 .543-.438.984-.978.984-.539 0-.977-.44-.977-.984 0-.543.438-.984.977-.984zm1.588 3.216c.54 0 .977.44.977.984 0 .543-.438.984-.977.984-.54 0-.977-.441-.977-.984 0-.543.438-.984.977-.984zm6.085-.99c.54 0 .977.44.977.984 0 .543-.438.984-.977.984-.54 0-.977-.441-.977-.984 0-.543.438-.984.977-.984zm1.701-4.145c.54 0 .978.44.978.984 0 .543-.438.984-.978.984-.539 0-.977-.441-.977-.984 0-.543.438-.984.977-.984zm-5.014-4.75c.54 0 .977.441.977.984 0 .543-.437.984-.977.984-.539 0-.977-.44-.977-.984 0-.543.438-.983.977-.983z"/>
        </g>
      </svg>
    );
}
