import React from 'react';

import {IconProps} from './iconTypes';

export function DownloadAudioIcon(props: IconProps) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={props.animationClass} fillRule="evenodd" clipRule="evenodd"
                  d="M15.921 21A5.081 5.081 0 0 0 21 15.921a5.081 5.081 0 0 0-5.079-5.079 5.081 5.081 0 0 0-5.079 5.08A5.081 5.081 0 0 0 15.922 21zm0-1.195a3.886 3.886 0 0 1-3.884-3.884 3.886 3.886 0 0 1 3.884-3.884 3.886 3.886 0 0 1 3.884 3.884 3.886 3.886 0 0 1-3.884 3.884zm-.597-3.086V13.68a.598.598 0 0 1 1.195 0v3.04l.66-.661a.598.598 0 0 1 .845.845l-1.68 1.68-.024.023-.026.022-.026.02-.021.014-.006.004a.6.6 0 0 1-.09.047h-.002a.596.596 0 0 1-.456 0h-.001a.593.593 0 0 1-.193-.13l-1.68-1.68a.598.598 0 0 1 .844-.845l.66.66zm-6.125.845H4.718a.523.523 0 0 1-.523-.523V4.718c0-.289.234-.523.523-.523h7.938c.139 0 .272.055.37.153l2.145 2.145c.098.098.153.23.153.37v2.336a.598.598 0 0 0 1.195 0V6.862c0-.455-.181-.892-.503-1.214L13.87 3.503A1.718 1.718 0 0 0 12.656 3H4.718C3.769 3 3 3.77 3 4.718V17.04c0 .95.77 1.718 1.718 1.718h4.481a.598.598 0 0 0 0-1.195zm-.675-6.549-.142-2.853a.597.597 0 0 1 .534-.624l.793-.085a2.603 2.603 0 0 1 2.504 1.243.598.598 0 0 1-1.023.618 1.408 1.408 0 0 0-1.355-.672l-.231.024.188 3.775c.003.038.005.076.005.114v.012c-.002.407-.184.791-.52 1.097-.398.361-1.034.614-1.758.614s-1.36-.253-1.758-.614c-.338-.307-.52-.694-.52-1.104 0-.41.182-.796.52-1.103.398-.362 1.034-.615 1.758-.615.363 0 .705.064 1.005.173zM8.6 12.53c-.016-.108-.113-.19-.224-.264-.219-.146-.523-.228-.856-.228-.334 0-.638.082-.857.228-.121.081-.226.172-.226.295s.105.214.226.295c.219.146.523.228.857.228.333 0 .637-.082.856-.228.118-.079.22-.167.226-.285L8.6 12.53z"
                  fill="#919AAF"/>
        </svg>

    );
}
