import * as React from 'react';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './StatisticsSummarySection.pcss';
import classNames from 'classnames';
import { TooltipComponent } from '../components/Tooltip/Tooltip';

type Metric = {
  name: string;
  value: string | number;
};

type StatisticsSummarySectionItemProps = {
  metric: Metric;
  postfix?: string;
  isSummary?: boolean;
};

export function StatisticsSectionItem({
  metric,
  postfix,
  isSummary = false,
}: StatisticsSummarySectionItemProps) {
  const intl = useIntl();

  return (
    <div data-test={`summary-statistic-${_.kebabCase(metric.name)}`}>
      <TooltipComponent
        className={classNames(styles.item, isSummary && styles.summary)}
        tooltipText={intl.formatMessage({
          id: `statistics.dashboard.tooltip.${metric.name}`,
        })}
      >
        <span className={styles.name}>
          <FormattedMessage
            id={`statistics.dashboard.${metric.name}`}
            values={{ linebreak: <br /> }}
          />
        </span>
        <span className={styles.value}>
          <span className={styles.number}>{metric.value}</span>
          {postfix && (
            <span className={styles.percentage}>{postfix}</span>
          )}
        </span>
      </TooltipComponent>
    </div>
  );
}
