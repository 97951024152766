import React from 'react';

import { Box } from '../../../components/Box/Box';
import { SavableLayout } from '../../../components/layouts/SavableLayout/SavableLayout';
import { ContactVariablesComponent } from '../../../survey/ContactVariablesComponent';
import { HeaderMappingsComponent } from './SipHeaders/HeaderMappingsComponent';

import { useVariablesWithSipHeaderMappings } from './VariablesWithSipHeaderMappings.hook';

export const SipHeaderMappingsContext = React.createContext<ReturnType<typeof useVariablesWithSipHeaderMappings>>(null);

export const AdminTabContentContainer: React.FC = () => {
  const sipHeaders = useVariablesWithSipHeaderMappings();
  const {
    hasChanges,
    isReady,
    onSave,
    resetChanges,
  } = sipHeaders;

  return <SavableLayout
    hasChanges={hasChanges}
    isLoading={!isReady}
    onSave={onSave}
    onCancel={resetChanges}
    dataTest="admin-tab-content"
  >
    <SipHeaderMappingsContext.Provider value={sipHeaders}>
      <Box border>
        <ContactVariablesComponent />
      </Box>
      <Box border>
        <HeaderMappingsComponent />
      </Box>
    </SipHeaderMappingsContext.Provider>
  </SavableLayout>
}
