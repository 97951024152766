import React, { useContext } from 'react';
import classname from 'classnames'
import { FunctionStep } from '../../model';
import { SelectWithLabel } from '../../../components/SelectWithLabel/SelectWithLabel';
import { Value } from '../../../components/Select/Select';
import { useIntl } from 'react-intl';
import { renderInputTypeToWidget } from './FunctionStepInputWidgets';
import { Box } from '../../../components/Box/Box';
import { ElementWithLabel } from '../../../components/ElementWithLabel/ElementWithLabel';
import styles from './FunctionStepComponent.pcss';
import { SurveyContext, SurveyContextType } from '../../SurveyCreator';
import { renderOutputTypeToWidget } from './FunctionStepOutputWidgets';

type FunctionStepProps = {
  question: FunctionStep;
  onChange: (step: FunctionStep) => void;
};

export const FunctionStepComponent: React.FC<FunctionStepProps> = ({
  question,
  onChange,
}) => {
  const intl = useIntl();
  const { executableFunctions } = useContext<SurveyContextType>(SurveyContext);

  const handleFunctionNameChange = (value: Value) =>
    onChange({
      ...question,
      functionName: value.id,
      functionInputs: {},
      functionOutputs: {},
    });
  const handleFunctionOutputVariableChange = (key: string, value: string) =>
    onChange({
      ...question,
      functionOutputs: {
        ...question.functionOutputs,
        [key]: value,
      },
    });

  const executableFunctionDefinitions = executableFunctions.get();
  const functionNameOptions: Value[] =
    executableFunctionDefinitions?.map(({ name }) => ({
      id: name,
      name: intl.formatMessage({
        id: `survey-creator.executableFunctions.functionNames.${name}`,
      }),
    })) || [];

  const functionInputs =
    executableFunctionDefinitions?.find(
      ({ name }) => name === question.functionName
    )?.inputs || [];
  const functionOutputs =
    executableFunctionDefinitions?.find(
      ({ name }) => name === question.functionName
    )?.outputs || [];
  const functionDescription =
    executableFunctionDefinitions?.find(
      ({ name }) => name === question.functionName
    )?.description

  return (
    <Box border noPadding>
      <SelectWithLabel
        dataTest="function-name-select"
        labelId="survey-creator.functionStepLabel"
        onChange={handleFunctionNameChange}
        options={functionNameOptions}
        value={functionNameOptions.find(
          ({ id }) => id === question.functionName
        )}
      />
      {
        functionDescription && <div className={classname(styles.description, styles.descriptionContainer)}>
          {intl.formatMessage({
            id: `survey-creator.functionStep.functions.${functionDescription}`,
          })}
        </div>
      }

      {functionInputs.map(
        ({ name, description, placeholder, render, options }) => {
          const Widget = renderInputTypeToWidget(render);
          return (
            <ElementWithLabel
              dataTest={`function-input-${name}`}
              key={name}
              labelId={`survey-creator.functionStep.functions.${name}`}
            >
              <div className={styles.description}>
                {intl.formatMessage({
                  id: `survey-creator.functionStep.functions.${description}`,
                })}
              </div>
              <Widget
                value={question.functionInputs[name]}
                onChange={(value) =>
                  onChange({
                    ...question,
                    functionInputs: {
                      ...question.functionInputs,
                      [name]: value,
                    },
                  })
                }
                {...(options && { options })}
                {...(placeholder && {
                  placeholder: intl.formatMessage({
                    id: `survey-creator.functionStep.functions.${placeholder}`,
                  }),
                })}
              />
            </ElementWithLabel>
          );
        }
      )}

      {functionOutputs.map(({ name, description, type }) => {
        const Widget = renderOutputTypeToWidget(type);
        return (
          <ElementWithLabel
            key={name}
            labelId={`survey-creator.functionStep.functions.${name}`}
            dataTest={`function-output-${name}`}
          >
            <div className={styles.description}>
              {intl.formatMessage({
                id: `survey-creator.functionStep.functions.${description}`,
              })}
            </div>
            <Widget
              value={question.functionOutputs[name]}
              onChange={(changedValue) =>
                handleFunctionOutputVariableChange(name, changedValue)
              }
            />
          </ElementWithLabel>
        );
      })}
    </Box>
  );
};
