import cx from 'classnames';
import React from 'react';

import { IconProps } from '../../icons/iconTypes';
import { Box } from '../Box/Box';
import { Button } from '../Button/Button'
import { StandardInitialProps } from '../types';

import styles from './DropdownButton.pcss';

export type DropdownButtonProps = StandardInitialProps & {
  isOpen: boolean;
  onOpen?: React.MouseEventHandler;
  Icon?: React.FC<IconProps>;
  label: string;
}

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  Icon,
  label,
  isOpen,
  onOpen,
  dataTest,
  className,
  children
}) => {
  return <div className={cx(styles.dropdownButtonWrapper, className)}>
    <Button
      dataTest={dataTest}
      onClick={onOpen}
      className={styles.dropdownButton}
    >
      { Icon && <Icon /> }
      <span>
        {label}
      </span>
    </Button>
    {
      isOpen &&
      <Box border noPadding className={styles.dropdownContent}>
        {children}
      </Box>
    }
  </div>;
}