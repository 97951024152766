import classNames from 'classnames';
import * as React from 'react';

import {BasicMessage, Message, QuestionError} from '../../model';
import {AddButton} from '../AddButton';
import {RepeatQuestionsLimit} from '../RepeatQuestionsLimit';

import styles from './Message.pcss';
import {SingleMessageComponent} from './SingleMessage';

type Props = {
    messages: BasicMessage[];
    maxRepeatQuestionCount?: number;
    onChange: (messages: BasicMessage[]) => void;
    onMaxRepeatQuestionCountChange?: (value: number) => void;
    onConditionChange?: (message: Message) => void;
    dataTestPrefix?: string;
    nested?: boolean;
    noRepeatedQuestionCount?: boolean;
    mainLabelId?: string;
    alternativeLabelId?: string;
    errors?: QuestionError[];
};

export const MultipleBasicMessages = (props: Props) => {
    const handleAddVersion = () => props.onChange([...props.messages, { text: '' }]);

    const handleRemoveVersion = (indexToRemove: number) => () =>
        props.onChange(props.messages.filter((message, index) => indexToRemove !== index));

    const handleChange = (indexToChange: number) => (newMessage: BasicMessage) =>
        props.onChange(props.messages.map((message, messageIndex) => indexToChange === messageIndex ? newMessage : message));

    const isFirstMessage = (index: number) => index === 0;
    const canAddMoreMessages = () => props.messages.length < 2;
    const labelByIndex = (index) => index === 0 ? props.mainLabelId || 'survey-creator.repeatMessageLabel' : props.alternativeLabelId || 'survey-creator.repeatMessageLabelAlternative'

    const errorFor = (index: number) => {
        const errors = props.errors && props.errors.filter(({field}) => field.includes(`repeatMessages[${index}].text`)).map((({error}) => error));
        return errors && errors.length > 0 ? errors[0] : undefined;
    };

    return (
        <div data-test={`${props.dataTestPrefix}-basic-messages`} className={classNames(styles.messagesContainer, {[styles.nested] : props.nested})}>
            {props.messages.map((message, index) => (
                <div key={index} data-test={`${props.dataTestPrefix}-basic-message`} className={styles.messageElement}>
                    <SingleMessageComponent
                        message={message}
                        labelId={labelByIndex(index)}
                        dataTestPrefix={props.dataTestPrefix}
                        onChange={handleChange(index)}
                        onDelete={!isFirstMessage(index) && handleRemoveVersion(index)}
                        onConditionChange={isFirstMessage(index) && props.onConditionChange}
                        standalone={false}
                        errorMessage={errorFor(index)}
                   />
                </div>
            ))}
                <div className={styles.bottomContainer}>
                    <div>{canAddMoreMessages() && <AddButton onClick={handleAddVersion} dataTest={'add-message-version'} labelId={'survey-creator.addRepeatMessageButton'} buttonType={'link'}/>}</div>
                    {!props.noRepeatedQuestionCount && <RepeatQuestionsLimit onChange={props.onMaxRepeatQuestionCountChange} maxRepeatQuestionCount={props.maxRepeatQuestionCount}/>}
                </div>
        </div>
    );
};
