import * as React from 'react';
import {useLayoutEffect, useState} from 'react';
import {createPortal} from 'react-dom';
import {FormattedMessage} from 'react-intl';

import {Button} from '../Button/Button';

import styles from './ConfirmationModal.pcss';

type Props = {
  onConfirm: (e?: any) => void;
  onCancel: () => void;
  renderText: () => JSX.Element,
  okLabelId?: string,
  cancelLabelId?: string,
  parentRef?: React.RefObject<HTMLElement>;
}

export const ConfirmationModal = ({onConfirm, onCancel, renderText, parentRef, okLabelId, cancelLabelId}: Props) => {
  const [position, setPosition] = useState<{left: number, right: number, width?: string}>({left: 0, right: 0});
  const currentParent = parentRef && parentRef.current;
  useLayoutEffect(() => {
    if (parentRef) {
      const current = parentRef.current.closest('div[style*="overflow: scroll"]').parentElement;
      const boundingClientRect = current.getBoundingClientRect();
      const left = boundingClientRect.left + 16;
      const right = boundingClientRect.right - 16;
      setPosition({
        left: left,
        right: right,
        width: `${right - left}px`
      });
    }
  }, [parentRef, currentParent]);
  return createPortal(
    (
      <div data-test="confirmation-modal" className={styles.modal}>
        <div className={styles.overlay} />
        <div className={styles.contentWrapper} style={position}>
        <div className={styles.content}>
          <div className={styles.text} data-test="modal-text">
            {renderText()}
          </div>
          <div className={styles.buttons}>
            <Button buttonType={'normal'} className={styles.button} dataTest="cancel-button" onClick={onCancel}>
              <FormattedMessage id={cancelLabelId || 'confirmationModal.no'} />
            </Button>
            <Button buttonType={'primary'} className={styles.button} dataTest="ok-button" onClick={onConfirm}>
              <FormattedMessage id={okLabelId || 'confirmationModal.yes'} />
            </Button>
          </div>
        </div>
        </div>
      </div>
    ),
    document.getElementsByTagName('body')[0]
  );
};
