// tslint:disable: jsx-no-plain-text-elements
import { CommandPaletteFooterComponent } from '@talkie/command-palette/components/types';
import React from 'react';
import cx from 'classnames';
import styles from './PaletteFooterComponent.pcss';
import { FormattedMessage } from 'react-intl';
import { isMacOs } from '../../surveyCreator/components/useShortcut';

export const PaletteFooterComponent: CommandPaletteFooterComponent = ({ className }) => (
  <div
    data-test="palette-footer"
    className={cx(className, styles.footer)}
  >
    <FormattedMessage id="survey-creator.command-palette.footer"
      values={{
        esc: <kbd>Esc</kbd>,
        enter: <kbd>Enter</kbd>,
        arrowup: <kbd>↑</kbd>,
        arrowdown: <kbd>↓</kbd>,
        altAction: isMacOs() ? <kbd>⌘+Enter</kbd> : <kbd>Ctrl+Enter</kbd>
      }} />
  </div>
);