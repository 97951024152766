import React from 'react';

import {AddButton} from '../../../../components/AddButton/AddButton';
import {IconButton} from '../../../../components/IconButton/IconButton';
import {InputWithLabel} from '../../../../components/InputWithLabel/InputWithLabel';
import {Row} from '../../../../components/Row/Row';
import {DeleteIcon} from '../../../../icons';
import {HorizontalSeparator} from '../../../../components/HorizontalSeparator/HorizontalSeparator';
import {PhoneNumbersProps} from '../../types';

import styles from './PhoneNumbers.pcss';

export const PhoneNumbers: React.FC<PhoneNumbersProps> = ({
                                                              isAdmin,
                                                              phoneConfigs,
                                                              addPhoneConfig,
                                                              deletePhoneConfig,
                                                              updatePhoneConfig
                                                          }) => <div className={styles.phoneNumbers}>
    {
        phoneConfigs.map((entry, i) => (
                <div key={`phone-config-row-${i}`}>
                    <Row data-test="phone-config-row">
                        <InputWithLabel
                            className={styles.phoneNumber}
                            dataTest="phone-config-number"
                            labelId="survey.config.phoneNumber"
                            type="tel"
                            onChange={(value) => updatePhoneConfig(i, 'phoneNumber', value as string)}
                            value={entry.phoneNumber}
                            disabled={!isAdmin}
                            noPadding
                        />
                        {
                            isAdmin &&
                            <InputWithLabel
                                dataTest="phone-config-address"
                                type="text"
                                labelId="survey.config.serverAddress"
                                onChange={(value) => updatePhoneConfig(i, 'serverAddress', value as string)}
                                value={entry.serverAddress}
                                noPadding
                            />
                        }
                        {
                            isAdmin &&
                            <IconButton
                                dataTest="delete-phone-config"
                                className={styles.deleteButton}
                                Icon={DeleteIcon}
                                hoverType={'warning'}
                                onClick={() => deletePhoneConfig(i)}
                            />
                        }
                    </Row>
                    {i < phoneConfigs.length - 1 && <HorizontalSeparator/>}
                </div>
            )
        )
    }
    {
        isAdmin &&
        <AddButton
            dataTest="add-phone-number"
            textId="survey.config.addNumber"
            onClick={addPhoneConfig}
        />
    }
</div>
