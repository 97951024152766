import React from 'react'
import { useMutation, useQueryClient } from 'react-query';
import { MarkdownContent } from '../../../components/MarkdownContent/MarkdownContent';
import { showErrorToast } from '../../../components/Toast/Toast';
import { useCurrentBot } from '../../../utils/CurrentBot';
import { HttpClient } from '../../../utils/HttpClient';
import { StatisticsSettings } from '../../types';
import { statisticsSettingsCacheKey } from './cacheConfig';

export function useUpdateStatisticsSettings({ onSuccess }: { onSuccess?: () => void } = {}) {
    const currentBot = useCurrentBot();
    const queryClient = useQueryClient();

    return useMutation((data: StatisticsSettings) => HttpClient.put({
        path: `/bots/${currentBot.id}/statistics/settings`,
        body: data,
    }), {
        onMutate: (newStatisticsSettings) => {
            const oldStatisticsSettings = queryClient.getQueryData(statisticsSettingsCacheKey);

            if (oldStatisticsSettings) {
                queryClient.setQueryData(statisticsSettingsCacheKey, () => newStatisticsSettings);
            }

            return oldStatisticsSettings
        },
        onError: (error, newStatisticsSettings, oldStatisticsSettings) => {
            showErrorToast(
                <MarkdownContent contentKey="statistics.scenarioStepsDashboard.savingChangesToastError" />
            );
            queryClient.setQueryData(statisticsSettingsCacheKey, oldStatisticsSettings)
        },
        onSettled: () => {
            queryClient.invalidateQueries(statisticsSettingsCacheKey);
            onSuccess()
        },
    });
}
