import cx from 'classnames';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import OutsideClickHandler from 'react-outside-click-handler';
import { Auth } from 'aws-amplify';

import { postHog } from '../../utils/postHog';
import { TooltipComponent } from '../../components/Tooltip/Tooltip';
import { IconButton } from '../../components/IconButton/IconButton';
import { UserIcon } from '../../icons';
import { DropdownMenu } from '../../components/DropdownMenu/DropdownMenu';
import TermsOfServiceInfoModal from '../../views/TermsOfService/TermsOfServiceInfoModal';
import { ChangePasswordModalContainer } from '../../views/ChangePasswordModal/ChangePasswordModalContainer';

import styles from './UserSettingsIcon.pcss';

const logout = () => {
  postHog.opt_out_capturing();
  postHog.reset();
  Auth.signOut().then(() => window.location.reload());
};

export const UserSettingsIcon = () => {
  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);
  const [tosModalOpen, setTosModalOpen] = useState(false);
  const [isChangePasswordModalOpened, setIsChangePasswordModalOpened] =
    React.useState(false);

  const openMenu = useCallback(() => setOpen(true), [setOpen]);
  const closeMenu = useCallback(() => setOpen(false), [setOpen]);

  return (
    <>
      <TooltipComponent
        tooltipText={intl.messages['header.user'] as string}
        tooltipPlacement={'bottom-end'}
      >
        <OutsideClickHandler onOutsideClick={closeMenu}>
          <IconButton
            dataTest="open-user-menu"
            Icon={UserIcon}
            onClick={openMenu}
            className={styles.iconButton}
            iconClass={isOpen && styles.icon}
          />
          <DropdownMenu
            classes={{
              ...styles,
              menu: cx(styles.userMenu, styles.specificityLevel),
              options: styles.options,
            }}
            isOpen={isOpen}
            options={[
              {
                classes: {
                  ...styles,
                  option: cx(styles.option, styles.withBorder),
                },
                dataTest: 'open-terms-of-service',
                name: intl.messages['header.termsOfService'] as string,
                onClick: () => {
                  setTosModalOpen(true);
                  closeMenu();
                },
              },
              {
                dataTest: 'change-password-button',
                name: intl.messages['header.changePassword'] as string,
                onClick: () => {
                  setIsChangePasswordModalOpened(true);
                  closeMenu();
                },
              },
              {
                dataTest: 'logout-button',
                name: intl.messages['header.logout'] as string,
                onClick: logout,
              },
            ]}
          />
        </OutsideClickHandler>
      </TooltipComponent>
      {tosModalOpen && (
        <TermsOfServiceInfoModal onClose={() => setTosModalOpen(false)} />
      )}
      {isChangePasswordModalOpened && (
        <ChangePasswordModalContainer
          closeModal={() => setIsChangePasswordModalOpened(false)}
        />
      )}
    </>
  );
};
