import React from 'react';

import {IconProps} from './iconTypes';

export function BotIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h18v18H0z" />
                <path
                    className={props.animationClass}
                    fill="#3C38BC"
                    d="M9-.037c3.701 0 6.392 2.189 6.497 6.187h1.941a.6.6 0 0 1 .098 1.192l-.098.008h-1.983a13.81 13.81 0 0 1-.563 2.743 2.855 2.855 0 0 1 2.651 3.055l-.023.205-.613 4.172a.6.6 0 0 1-1.194-.077l.007-.098.613-4.172a1.65 1.65 0 0 0-1.474-1.883l-.159-.008H3.3a1.655 1.655 0 0 0-1.65 1.733l.015.158.614 4.172a.6.6 0 0 1-1.165.27l-.022-.095-.615-4.174A2.855 2.855 0 0 1 3.3 10.087l-.192.007c-.3-.98-.483-1.896-.563-2.744H.563a.6.6 0 0 1-.097-1.192l.097-.008h1.94C2.607 2.151 5.299-.037 9-.037zm0 13.5c.643 0 1.163.52 1.163 1.162v2.25c0 .642-.52 1.163-1.162 1.163H4.5c-.642 0-1.163-.521-1.163-1.163v-2.25c0-.642.52-1.162 1.163-1.162H9zm5.063 2.25a.6.6 0 0 1 .098 1.192l-.098.008h-2.25a.6.6 0 0 1-.097-1.193l.097-.008h2.25zm-5.1-1.05H4.538v2.174h4.425v-2.175zm5.1-1.2a.6.6 0 0 1 .098 1.192l-.098.008h-2.25a.6.6 0 0 1-.097-1.193l.097-.008h2.25zM9 1.162c-4.084 0-6.587 3.025-4.635 8.924h9.27c1.952-5.898-.55-8.924-4.635-8.925zm2.25 3.3a1.725 1.725 0 1 1 0 3.45 1.725 1.725 0 0 1 0-3.45zm-4.5 0a1.725 1.725 0 1 1 0 3.45 1.725 1.725 0 0 1 0-3.45zm4.5 1.2a.525.525 0 1 0 0 1.05.525.525 0 0 0 0-1.05zm-4.5 0a.525.525 0 1 0 0 1.05.525.525 0 0 0 0-1.05z"
                />
            </g>
        </svg>
    );
}
