import React from 'react';

import { IconProps } from './iconTypes';

export function ShowPasswordIcon(props: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.244 12a12.528 12.528 0 0 0 1.71 1.794c1.259 1.08 2.999 2.106 5.046 2.106s3.787-1.026 5.047-2.106A12.522 12.522 0 0 0 18.757 12a12.522 12.522 0 0 0-1.71-1.794c-1.26-1.08-3-2.106-5.047-2.106-2.047 0-3.787 1.026-5.047 2.106A12.528 12.528 0 0 0 5.243 12zM19.5 12l.499-.333-.001-.002-.003-.004-.008-.012a4.095 4.095 0 0 0-.142-.199 13.739 13.739 0 0 0-2.017-2.156C16.463 8.124 14.453 6.9 12 6.9c-2.453 0-4.463 1.224-5.828 2.394a13.735 13.735 0 0 0-2.017 2.156 7.588 7.588 0 0 0-.142.199l-.008.012-.003.004v.001c-.001 0-.001.001.498.334l-.5-.333a.6.6 0 0 0 0 .666L4.5 12l-.499.333.001.002.003.004.008.012a3.864 3.864 0 0 0 .142.199 13.74 13.74 0 0 0 2.017 2.156C7.537 15.876 9.547 17.1 12 17.1c2.453 0 4.463-1.224 5.828-2.395a13.745 13.745 0 0 0 2.017-2.155 7.789 7.789 0 0 0 .142-.199l.008-.012.003-.004v-.001H20L19.5 12zm0 0 .5.333a.6.6 0 0 0 0-.666l-.5.333z"
        fill="#919AAF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 10.725a1.275 1.275 0 1 0 0 2.55 1.275 1.275 0 0 0 0-2.55zM9.525 12a2.475 2.475 0 1 1 4.95 0 2.475 2.475 0 0 1-4.95 0z"
        fill="#919AAF"
      />
    </svg>
  );
}
