import classNames from 'classnames';
import * as React from 'react';
import {useContext, useState} from 'react';

import {BorderedElementWithLabel} from '../../components/ElementWithLabel/BorderedElementWithLabel';
import {Label} from '../../components/Label';
import { TextAreaWithVariables } from '../../components/TextArea/TextAreaWithVariables';
import {DeleteIcon} from '../../icons';
import { SurveyContext } from '../SurveyCreator';

import {AddButton} from './AddButton';
import styles from './RedirectToInput.pcss';
import {StepElementProps} from './StepElementProps';

export const RedirectToInput = (props: StepElementProps<{ redirectTo?: string, redirectFrom?: string }>) => {
  const availableVariables = useContext(SurveyContext).variables.getVariablesForCreator();


  const setRedirectTo = (redirectTo: string) => {
        props.onChange({
          ...props.question,
          redirectTo
        });
      };
  const setRedirectFrom = (redirectFromPhoneNumber: string) => {
        props.onChange({
          ...props.question,
          redirectFrom: redirectFromPhoneNumber
        });
      };
  const [showRedirectFrom, setShowRedirectFrom] = useState<boolean>(!!props.question.redirectFrom);

  return (
          <>
            <BorderedElementWithLabel labelId={'survey-creator.redirectStep.redirectTo'}>
              <>
                <TextAreaWithVariables
                    asInput
                    dataTest={'redirect-to-input'}
                    value={props.question.redirectTo || ''}
                    onChange={setRedirectTo}
                    availableVariables={availableVariables}
                />
                {
                  !showRedirectFrom
                      ? (
                          <AddButton onClick={() => setShowRedirectFrom(true)}
                                     dataTest={'add-redirect-from'}
                                     labelId={'survey-creator.redirectStep.redirectFromButton'}
                                     className={styles.addRedirectFromButton}
                                     buttonType={'link'}/>
                      )
                      : (
                          <div className={styles.addRedirectFromContainer}>
                            <div className={styles.redirectFromHeaderContainer}>
                              <Label labelId={'survey-creator.redirectStep.redirectFrom'}/>
                              <div data-test={'redirect-from-remove'} className={styles.remove} onClick={() => {
                                setRedirectFrom(undefined);
                                setShowRedirectFrom(false);
                              }}>
                                <DeleteIcon animationClass={classNames(styles.removeIcon)}/>
                              </div>
                            </div>
                            <TextAreaWithVariables
                                asInput
                                dataTest={'redirect-from-input'}
                                value={props.question.redirectFrom || ''}
                                onChange={setRedirectFrom}
                                availableVariables={availableVariables}
                            />
                          </div>
                      )
                }
              </>
            </BorderedElementWithLabel>
          </>
      );
    }
;
