import React from 'react';

import {IconProps} from './iconTypes';

export function EmailStepIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g transform="translate(-381 -463) translate(365 451) translate(16 12)">
                <path d="M0 0H24V24H0z"/>
                <path className={props.animationClass} d="M2.127 4C.952 4 0 4.929 0 6.074v11.852C0 19.07.952 20 2.127 20h19.746C23.048 20 24 19.071 24 17.926V6.074C24 4.93 23.048 4 21.873 4H2.127zM22.78 5.98l-6.077 4.087 3.304 2.98c.247.221.262.597.034.837s-.612.255-.859.033l-3.504-3.16-1.948 1.31c-1.041.7-2.419.7-3.46 0l-1.948-1.31-3.504 3.16c-.247.222-.631.207-.86-.033-.227-.24-.212-.616.035-.838l3.304-2.979L1.22 5.98c-.003.031-.005.062-.005.094v11.852c0 .49.408.889.912.889h19.746c.504 0 .912-.398.912-.89V6.076c0-.033-.002-.064-.005-.095zm-.959-.795H2.18l8.782 5.907c.625.42 1.453.42 2.078 0l8.782-5.907z"/>
              </g>
            </g>
          </g>
        </g>
      </svg>

    );
}
