
import React from 'react';
import { useIntl } from 'react-intl'

import { LoginBox } from './components/LoginBox';
import { PageContainer } from '../components/PageContainer';
import { ResetPasswordConfirmation } from './forms/ResetPasswordConfirmation';

import styles from './ResetPasswordConfirmationContainer.pcss';

export const ResetPasswordConfirmationContainer: React.FC = () => {
  const intl = useIntl();

  return <PageContainer>
    <LoginBox className={styles.resetPasswordContainer} header={intl.messages['auth.signIn.resetPasswordConfirmationHeader'] as string}>
      <ResetPasswordConfirmation
        resetPasswordConfirmationDescription={intl.formatMessage({
          id: 'auth.signIn.resetPasswordConfirmationDescription'
        }, { linebreak: <br />, b: (chunks) => <strong>{chunks}</strong> })}
      />
    </LoginBox>
  </PageContainer>
}