import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import OutsideClickHandler from 'react-outside-click-handler';

import { AddButton } from '../../components/AddButton/AddButton';
import { Box } from '../../components/Box/Box';
import { DropdownMenu, DropdownMenuOptionProps } from '../../components/DropdownMenu/DropdownMenu';
import { HttpStepIcon, SmsStepIcon } from '../../icons';
import { CallStepIcon } from '../../icons/CallStepIcon';
import {
    AnyStep,
    getTemplateScheduleStep,
} from '../schedule-model';

import styles from './AddStep.pcss';

interface Props {
    onAddStep: (newStep: AnyStep) => void;
}

export const AddStep = ({ onAddStep }: Props) => {
    const intl = useIntl();

    const [isMenuOpen, setMenuOpen] = useState(false);
    const openMenu = useCallback(() => setMenuOpen(true), [setMenuOpen]);
    const closeMenu = useCallback(() => setMenuOpen(false), [setMenuOpen]);

    const addCallStep = useCallback(() => {
        onAddStep(getTemplateScheduleStep('JustInTime', 'call'));
        closeMenu();
    }, [onAddStep, getTemplateScheduleStep, closeMenu]);
    const addSmsStep = useCallback(() => {
        onAddStep(getTemplateScheduleStep('JustInTime', 'sms'));
        closeMenu();
    }, [onAddStep, getTemplateScheduleStep, closeMenu]);
    const addHttpStep = useCallback(() => {
        onAddStep(getTemplateScheduleStep('JustInTime', 'http'));
        closeMenu();
    }, [onAddStep, getTemplateScheduleStep, closeMenu]);

    const addStepOptions: DropdownMenuOptionProps[] = [{
        name: intl.messages['scheduler.steps.callHeader'] as string,
        description: intl.messages['scheduler.steps.callDescription'] as string,
        Icon: CallStepIcon,
        onClick: addCallStep,
        dataTest: 'add-call-step-btn'
    },
    {
        name: intl.messages['scheduler.steps.smsHeader'] as string,
        description: intl.messages['scheduler.steps.smsDescription'] as string,
        Icon: SmsStepIcon,
        onClick: addSmsStep,
        dataTest: 'add-sms-step-btn'
    },
    {
        name: intl.messages['scheduler.steps.httpHeader'] as string,
        description: intl.messages['scheduler.steps.httpDescription'] as string,
        Icon: HttpStepIcon,
        onClick: addHttpStep,
        dataTest: 'add-http-step-btn'
    }
    ];

    return (
        <Box
            border
            data-test="add-step"
            className={styles.container}
        >
            <OutsideClickHandler
                onOutsideClick={closeMenu}
            >
                <DropdownMenu
                    isOpen={isMenuOpen}
                    options={addStepOptions}
                />
            </OutsideClickHandler>
            <AddButton
                className={styles.addButton}
                onClick={openMenu}
                dataTest={'add-step-button'}
                textId={'scheduler.steps.addStep'}
            />
            <p>
                <FormattedMessage id="scheduler.steps.addStepDescription" />
            </p>
        </Box>
    );
};
