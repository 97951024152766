import _ from 'lodash';
import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import ReactTable, {Column, RowInfo} from 'react-table';

import {Button} from '../components/Button/Button';
import {Modal} from '../components/Modal/Modal';
import {DeleteIcon} from '../icons';
import {Header} from '../layout/Header';
import {MainPage} from '../layout/MainPage';
import {useCurrentBot} from '../utils/CurrentBot';
import {HttpClient} from '../utils/HttpClient';

import styles from './Intent.pcss';

type IntentSentences = {
    name: string;
    sentences: string[];
};

type Props = {
    match: { params: { intent: string } };
};

const renderKeyValue = () => (row: RowInfo) => {
    const key = row.original.name;
    return (
        <div>
            <div data-test="keyRow">{key}</div>
        </div>
    );
};

type TrainDataProps = {
    isGlobal: boolean;
    sentences: string[];
    onSentenceRemove: (sentence: string) => Promise<void>;
}

const TrainDataComponent = ({isGlobal, sentences, onSentenceRemove}: TrainDataProps) => {
    const [sentenceToRemove, setSentenceToRemove] = useState<string>(undefined);

    return (
        <div>
            <div data-test="keyRow">
                {
                    sentences.map((sentence: string, index: number) => (
                        <div key={index} data-test={'train-data'}>
                            {
                                !isGlobal && <span
                                    data-test={`remove-train-data-${sentence}`}
                                    onClick={() => setSentenceToRemove(sentence)}>
                                    <DeleteIcon fill={'#000'} />
                                </span>
                            }
                            {sentence}
                        </div>
                    ))
                }
                {
                    !!sentenceToRemove &&
                    <Modal position={'top'} dataTest={'remove-sentence-modal'} className={styles.modal}>
                        <div>
                            <FormattedMessage id={'learning.view.intent.trainData.shouldRemove'}/>
                        </div>
                        <div>{sentenceToRemove}</div>
                        <div className={styles.modalButtons}>
                            <div className={styles.button}>
                                <Button data-test="confirm-remove-train-data-button" onClick={() => {
                                    onSentenceRemove(sentenceToRemove);
                                    setSentenceToRemove(undefined);
                                }} translateText={'learning.view.intent.trainData.shouldRemove.yes'}/>
                            </div>
                            <div className={styles.button}>
                                <Button data-test="reject-remove-train-data-button"
                                        onClick={() => setSentenceToRemove(undefined)}
                                        translateText={'learning.view.intent.trainData.shouldRemove.no'}/>
                            </div>
                        </div>
                    </Modal>
                }

            </div>
        </div>
    );
};

const IntentComponent = (props: Props & WrappedComponentProps) => {
    const currentBot = useCurrentBot();
    const [intentSentences, setIntentSentences] = useState<IntentSentences[]>([]);

    const removeSentence = async (sentence: string) => {
        await HttpClient.delete({path: `/bots/${currentBot.id}/rasa-sentences/${encodeURIComponent(sentence)}`});
        fetchSentences();
    };

    const fetchSentences = useCallback(() => {
        HttpClient.get({path: `/bots/${currentBot.id}/intents/${props.match.params.intent}`})
            .then(response => response.data)
            .then(data =>
                setIntentSentences(() => [
                    {
                        name: 'Global',
                        sentences: _.get(data, 'global', []).map((trainData: any) => trainData.text)
                    },
                    {
                        name: 'Local',
                        sentences: data.local.map((trainData: any) => trainData.text)
                    }
                ])
            );
    }, [currentBot?.id, props.match.params.intent]);
    useEffect(() => {
        fetchSentences();
    }, [currentBot?.id, fetchSentences]);

    const renderIntentValue = () => (row: RowInfo) => {

        return (
            <TrainDataComponent isGlobal={row.original.name === 'Global'}
                                sentences={row.original.sentences}
                                onSentenceRemove={removeSentence}
            />
        );
    };

    const columns: Column[] = [
        {
            Header: <FormattedMessage id={'entity.table.key'}/>,
            Cell: renderKeyValue(),
            accessor: 'key',
            maxWidth: 200
        },
        {
            Header: <FormattedMessage id={'entity.table.values'}/>,
            Cell: renderIntentValue(),
            id: 'result'
        }
    ];
    return (
        <MainPage>
            <Header/>
            <div>
                <div data-test="intentName">{props.match.params.intent}</div>
                <ReactTable
                    data={intentSentences}
                    columns={columns}
                    minRows={1}
                    pageSize={100}
                    noDataText=""
                    pageText={props.intl.messages['table.page']}
                    ofText={props.intl.messages['table.of']}
                    rowsText={props.intl.messages['table.rows'] as string}
                />
            </div>
        </MainPage>
    );
};

export const Intent = injectIntl(IntentComponent);
