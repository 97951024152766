import * as React from 'react';

import {StepElementProps} from './StepElementProps';
import {ToggleWithLabel} from './ToggleWithLabel';

type Props = { acceptAnyAnswer?: boolean };

export const AcceptAnyAnswerToggle = ({onChange, question}: StepElementProps<Props>) => {
    const setAcceptAnyAnswer = () => onChange({...question, acceptAnyAnswer: !question.acceptAnyAnswer});
    return (
        <ToggleWithLabel dataTest={'accept-any-answer-toggle'} labelId={'survey-creator.acceptAnyAnswerLabel'}
                         value={!!question.acceptAnyAnswer} onToggle={setAcceptAnyAnswer} />
    );
};
