import _, { uniqBy } from 'lodash';
import React, { createContext, useContext } from 'react'
import { CommandPaletteCategory, CommandPaletteItem } from '@talkie/command-palette/components/types';
import { AnalyticsItem } from './hooks/types';
import { useActionItems } from './hooks/useActionItems';
import { useModuleItems } from './hooks/useModuleItems';
import { useStepItems } from './hooks/useStepItems';
import { useIntentItems } from './hooks/useIntentItems';
import { useTextItems } from './hooks/useTextItems';
import { useHttpStepURLItems } from './hooks/useHttpStepURLItems';
import { useHttpStepPathItems } from './hooks/useHttpStepPathItems';

let Context = createContext<CommandPaletteContext>({
  items: [],
  categories: []
});

export type CommandPaletteContext = {
  items: CommandPaletteItem<AnalyticsItem>[];
  categories: CommandPaletteCategory[];
}

export function useCommandPalette() {
  return useContext(Context);
}
export const AnalyticsCommandPaletteProvider: React.FC = ({ children }) => {
  const modulesItems = useModuleItems();
  const stepsItems = useStepItems();
  const intentsItems = useIntentItems();
  const textsItems = useTextItems();
  const urlItems = useHttpStepURLItems();
  const pathItems = useHttpStepPathItems();

  const actions = useActionItems();

  const commandPaletteItems = [...modulesItems, ...stepsItems, ...intentsItems, ...textsItems, ...urlItems, ...pathItems, ...actions];

  return <Context.Provider value={{
    items: commandPaletteItems,
    categories: uniqBy(commandPaletteItems.map(({ category }) => ({ name: category })), 'name')
  }}>
    {children}
  </Context.Provider>;
}
