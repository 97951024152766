import * as React from 'react';

import {ElementWithLabel} from '../../components/ElementWithLabel/ElementWithLabel';
import {NumberInputWithStepper} from '../../components/NumberInputWithStepper/NumberInputWithStepper';
import {GoTo, LengthConfig} from '../model';

import {GoToComponent} from './GoToComponent';
import styles from './NumberLength.pcss';
import {StepElementProps} from './StepElementProps';
import {ToggleWithLabelAndElement} from './ToggleWithLabelAndElement';

export const NumberLength = ({onChange, question}: StepElementProps<{ length?: LengthConfig, goTo: GoTo }>) => {
    const setMaxLength = (value: number) => {
        onChange({
            ...question,
            length: {
                max: value,
                min: question.length?.min === undefined ? 1 : question.length.min
            }
        });
    };

    const setMinLength = (value: number) => {
        onChange({
            ...question,
            length: {
                max: question.length?.max,
                min: value
            }
        });
    };
    const isEnabled = question.length?.max !== undefined || question.length?.min !== undefined;
    const toggleValidation = () => {
        if (isEnabled) {
            onChange({
                ...question,
                goTo: {
                    ...question.goTo,
                    invalidLength: undefined
                },
                length: {
                    max: undefined,
                    min: undefined
                }
            });
        } else {
            onChange({
                ...question,
                length: {
                    max: 1,
                    min: 1
                }
            });
        }
    };
    const min = question.length?.min || 1;
    const max = question.length?.max || min;

    return (
        <ToggleWithLabelAndElement value={isEnabled} onToggle={toggleValidation}
                                   labelId={'survey-creator.numerical.validation.length'}
                                   dataTest={'length-validation-toggle'}>
            {isEnabled && (
                <div>
                    <div className={styles.counts}>
                        <ElementWithLabel labelId={'survey-creator.numerical.validation.min'} className={styles.item}>
                            <NumberInputWithStepper dataTest={'min-length-validation'} minValue={1} value={min}
                                                    onChange={setMinLength}/>
                        </ElementWithLabel>
                        <ElementWithLabel labelId={'survey-creator.numerical.validation.max'} className={styles.item}>
                            <NumberInputWithStepper dataTest={'max-length-validation'} minValue={1} value={max}
                                                    onChange={setMaxLength}/>
                        </ElementWithLabel>
                    </div>
                    <GoToComponent question={question} availableOptions={['invalidLength']} onChange={onChange}/>
                </div>
            )}
        </ToggleWithLabelAndElement>
    );
};
