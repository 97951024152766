import FileSaver from 'file-saver';
import { isEmpty, isNumber, omitBy, sortBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import moment from 'moment';

import { useAsyncFn } from '../../utils/asyncUtils';
import { HttpClient } from '../../utils/HttpClient';
import { useFilters } from '../../filters/useFilters';

import { Report } from './model';

export const REPORTS_ASYNC_SETTINGS = {
    minimumQueryLoadingTime: 150
};

type GenerateReportArgs = Partial<ConversationsFilters & {
    questionIds: ReadonlyArray<string>;
    intentIds: ReadonlyArray<string>;
}>
export type GenerateReport = () => void;

export interface ConversationsFilters {
    readonly startDate: string | null;
    readonly endDate: string | null;
    readonly callDurationMin: string | null;
    readonly callDurationMax: string | null;
    readonly phoneNumber: string;
    readonly phrase: string;
    readonly information: string[],
    readonly recognizedIntents: string[],
    readonly results: string[],
    readonly surveyQuestions: string[],
    readonly technicalInformation: string[],
}

export type ConversationsEventsFilters = Pick<ConversationsFilters, 'information' | 'recognizedIntents' | 'results' | 'surveyQuestions' | 'technicalInformation'>;

const defaultFilters: ConversationsFilters = {
    startDate: null,
    endDate: null,
    callDurationMin: null,
    callDurationMax: null,
    phoneNumber: '',
    phrase: '',
    information: [],
    recognizedIntents: [],
    results: [],
    surveyQuestions: [],
    technicalInformation: [],
};

export function useReports(
    currentBotId: string
): [
        ReadonlyArray<Report>,
        boolean,
        GenerateReport,
        (report: Report) => void,
        ConversationsFilters,
        (newFilters: Partial<ConversationsFilters>) => void,
        (filters: ConversationsFilters) => void,
    ] {
    const { filters, setFilters, replaceFilters } = useFilters<ConversationsFilters>(defaultFilters);
    const [reports, setReports] = useState<Report[]>([]);

    const {
        callback: getSurveyReports,
        state: { loading: loadingReports },
        cancelPendingTasks
    } = useAsyncFn(
        () => {
            const { promise, cancellable } = getReports(currentBotId);
            promise.then(setReports);
            return cancellable;
        },
        [currentBotId],
        REPORTS_ASYNC_SETTINGS
    );

    useEffect(() => {
        getSurveyReports();
        return cancelPendingTasks;
    }, []);

    useEffect(() => {
        let timeout;
        let cancellableQuery;
        if (reports.find(r => r.status === 'pending')) {
            timeout = setTimeout(() => {
                cancelPendingTasks();
                const { promise, cancellable } = getReports(currentBotId);
                cancellableQuery = cancellable;
                promise.then(setReports);
            }, 5000);
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
            if (cancellableQuery) {
                cancellableQuery.cancel();
            }
        };
    }, [reports, cancelPendingTasks]);

    const { search } = useLocation();

    const generateReport: GenerateReport = useCallback(
        () => {
            if (!loadingReports) {
                const {
                    startDate,
                    endDate,
                    callDurationMin,
                    callDurationMax,
                    information,
                    recognizedIntents,
                    results,
                    surveyQuestions,
                    technicalInformation,
                    ...rest
                } = filters;

                orderReport({
                    currentBotId,
                    ...omitBy({
                        ...rest,
                        startDate: moment(startDate).valueOf() || null,
                        endDate: moment(endDate).valueOf() || null,
                        ...((callDurationMin || callDurationMax) && {
                            callDuration: {
                                min: parseInt(callDurationMin, 10) || undefined,
                                max: parseInt(callDurationMax, 10) || undefined,
                            },
                        }),
                        results,
                        questionIds: surveyQuestions,
                        intentIds: recognizedIntents,
                        information,
                        technicalInformation
                    }, element => isEmpty(element) && !isNumber(element))
                }).then(getSurveyReports);
            }
        },
        [filters, loadingReports, currentBotId, search]
    );

    return [
        reports,
        loadingReports,
        generateReport,
        useCallback(downloadReport(currentBotId), [currentBotId]),
        filters,
        setFilters,
        replaceFilters
    ];
}

function getReports(currentBotId: string) {
    const cancellable = HttpClient.get({ path: `/bots/${currentBotId}/reports` });
    return {
        promise: cancellable
            .then(result => result.data)
            .then(currentReports => sortBy(currentReports, report => report.creationDate).reverse()),
        cancellable
    };
}

function orderReport({
    currentBotId,
    ...body
}: GenerateReportArgs & { currentBotId: string }) {
    return HttpClient.post({
        path: `/bots/${currentBotId}/reports`,
        body
    });
}

const downloadReport = (currentBotId: string) => (report: Report) => {
    HttpClient.get(
        {
            path: `/bots/${currentBotId}/reports/${report.id}`
        },
        { responseType: 'blob' }
    ).then(request => FileSaver.saveAs(request.data, report.fileName));
};