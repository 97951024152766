import { useCallback } from 'react';

import { VoiceProps } from '../../types';

export const useVoiceCallbacks = (updateVoiceConfigProperty: VoiceProps['updateVoiceConfigProperty']) => {
  const onLanguageChange = useCallback((value) => updateVoiceConfigProperty(
    'languageCode',
    value.id
  ), [updateVoiceConfigProperty])

  const onVoiceNameChange = useCallback((value) => updateVoiceConfigProperty(
    'voiceName',
    value.id
  ), [updateVoiceConfigProperty])

  const onVoiceTempoChange = useCallback((value) => updateVoiceConfigProperty(
    'speakingRate',
    String(value)
  ), [updateVoiceConfigProperty]);

  const onVoicePitchChange = useCallback((value) => updateVoiceConfigProperty(
    'pitch',
    String(value)
  ), [updateVoiceConfigProperty]);

  return {
    onLanguageChange,
    onVoiceNameChange,
    onVoicePitchChange,
    onVoiceTempoChange
  }
}