import * as React from 'react';

import {ToggleWithLabel} from './ToggleWithLabel';

type QuestionProps = { duplex?: boolean };
type Props = { question: QuestionProps, onChange: (question: QuestionProps) => void, labelId?: string };

export const DuplexToggle = ({question, onChange, labelId}: Props) => {
    const setDuplex = () => onChange({...question, duplex: !question.duplex});
    return (
        <ToggleWithLabel dataTest={'duplex-toggle'} labelId={labelId ?? 'survey-creator.duplexLabel'} value={!!question.duplex} onToggle={setDuplex} />
    );
};
