import cx from 'classnames';
import React from 'react'

import { TabsLayoutProps } from '../types'

import styles from './SavableLayoutTabs.pcss';

type SavableLayoutTabsProps = Partial<TabsLayoutProps>;

export const SavableLayoutTabs: React.FC<SavableLayoutTabsProps> = ({
  tabs,
  currentTab,
  onTabClick
}) => <div className={styles.tabs}>
    {
      tabs.map(tab => <button
        key={tab.id}
        className={cx(styles.tab, {[styles.selected]: currentTab === tab.id})}
        onClick={() => onTabClick(tab.id)}
        data-test={tab.dataTest}
      >
        {tab.label}
      </button>)
    }
  </div>