import React, { useState } from 'react';
import {
  InterpretEntity,
  InterpretEntityValue,
  TextInterpretation,
} from '../models/Transcript';
import styles from './StepLine.pcss';
import { InterpretationStepLineDetails } from './InterpretationStepLineDetails';
import { EntityButtonIcon, IntentIcon } from '../../icons';
import {useIntl} from 'react-intl';

type Props = {
  interpretation?: TextInterpretation;
};

export function InterpretationStepLine({ interpretation }: Props) {
  const intl = useIntl();

  const [lineDetails, setLineDetails] = useState<null | {
    title: string;
    entities: InterpretEntity[];
  }>(null);

  // todo: write test for !interpretation.entities
  if (!interpretation || !interpretation?.entities) {
    return null;
  }

  return (
    <>
      {Object.entries(interpretation?.entities).map(([key, value]) => {
        const isIntent = key === 'intent';
        const title =
          (isIntent
            ? intl.messages['conversations.interpretation.intent']
            : `${intl.messages['conversations.interpretation.entity']} ${key}`) +
          ' ' +
          (value[0] as InterpretEntityValue).value;

        // todo: test for no intent
        if (
          isIntent &&
          (value[0] as InterpretEntityValue)?.value === 'No intent'
        ) {
          return null;
        }

        return (
          <div className={styles.wrapper} key={key}>
            <button
              data-test="interpretation-line"
              className={styles.button}
              onClick={() => setLineDetails({ title, entities: value })}
            >
              {isIntent ? (
                <IntentIcon animationClass={styles.icon} />
              ) : (
                <EntityButtonIcon animationClass={styles.icon} />
              )}
              <span className={styles.text}>{title}</span>
            </button>
          </div>
        );
      })}
      {lineDetails && (
        <InterpretationStepLineDetails
          title={lineDetails.title}
          line={lineDetails.entities}
          onClose={() => setLineDetails(null)}
        />
      )}
    </>
  );
}
