import React from 'react';

import {IconProps} from './iconTypes';

export function FilledCancelIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
          <g>
            <g transform="translate(-24 -32) translate(24 32)">
              <path d="M0 0H24V24H0z"/>
              <path className={props.animationClass} fill="#919aaf" d="M12 3c4.97 0 9 4.03 9 9s-4.03 9-9 9-9-4.03-9-9 4.03-9 9-9zM9.057 8.183c-.244-.182-.592-.161-.814.06-.244.245-.244.64 0 .884L11.116 12l-2.873 2.873-.06.07c-.182.244-.161.592.06.814.245.244.64.244.884 0L12 12.884l2.873 2.873.07.06c.244.182.592.161.814-.06.244-.245.244-.64 0-.884L12.884 12l2.873-2.873.06-.07c.182-.244.161-.592-.06-.814-.245-.244-.64-.244-.884 0L12 11.116 9.127 8.243z"/>
            </g>
          </g>
        </g>
      </svg>

    );
}
