import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box } from '../../components/Box/Box';
import { Button } from '../../components/Button/Button';
import { Input } from '../../components/Input';
import { DropdownIcon } from '../../icons/DropdownIcon';
import { getIntentDisplayName, Intent } from '../../surveyCreator/SurveyCreator';
import { ExtendedLabel } from '../ExtendedLabel';
import { TrainingStatus, TrainingStatusComponent } from '../TrainingStatus/TrainingStatus';

import styles from './IntentTrainingBar.pcss';
import { MultipleIntents } from '../../learning/MultipleIntents';
import { TrainData } from '../../learning/DuplicateTrainDataPopup';
import { noop } from 'lodash';

type IntentTrainingBarProps = {
  status: TrainingStatus;
  recognizedIntent?: string;
  confidence?: number;
  intentOptions: Intent[];
  intentInputValue: string;
  onAddUtterance: () => void;
  onAddMultiple: () => void;
  onTrain: () => void;
  onIntentInputChange: (value: string) => void;

  selectedIntent?: string;
  onSetSelectedIntent: (value: string) => void;

  onAddSentences: (sentences: TrainData[]) => Promise<void>;

  isAddMultipleModalOpen: boolean;
  onCloseAddMultipleModal: () => void;
};

export const IntentTrainingBar = ({
  status,
  recognizedIntent,
  confidence,
  intentOptions,
  onAddUtterance,
  onAddMultiple,
  onTrain,
  onIntentInputChange,
  intentInputValue,
  selectedIntent,
  onSetSelectedIntent,
  onAddSentences,
  isAddMultipleModalOpen,
  onCloseAddMultipleModal,
}: IntentTrainingBarProps) => {
  const intl = useIntl();
  const [intentSuggestions, setIntentSuggestions] = useState<Intent[]>([]);
  const onFilterSuggestions = (newValue: { value: string }) => {
    const intents = intentOptions.filter(intentOption => {
      return getIntentDisplayName(intentOption).toLowerCase().includes(newValue.value.trim().toLowerCase());
    });
    const sortedIntents = intents
        .map(i => i.displayName ? {...i, sortingName: i.displayName} : {...i, sortingName: i.name})
        .sort((first, second) => first.sortingName.toLowerCase() < second.sortingName.toLowerCase() ? - 1 : 1);
    setIntentSuggestions(
        sortedIntents
    );
  }

  return <div className={styles.intentTrainingBar}>
    <MultipleIntents
      intentSuggestions={intentSuggestions}
      filterSuggestions={onFilterSuggestions}
      onAdd={onAddSentences}
      isOpen={isAddMultipleModalOpen}
      onClose={onCloseAddMultipleModal}
    />
    <TrainingStatusComponent status={status} onTrain={onTrain} />
    <Box border noPadding className={styles.intentTraining}>
      <Input
        dataTest="learn-intent-input"
        className={styles.intentTrainingInput}
        placeholder={intl.formatMessage({ id: 'learning.intentTraining.intentInput.placeholder' })}
        onChange={onIntentInputChange}
        value={intentInputValue}
      />
      <ExtendedLabel
        dataTest="current-intent"
        className={styles.recognizedIntentLabel}
        label={intl.formatMessage({ id: 'learning.intentTraining.recognizedIntentLabel' })}
        value={recognizedIntent}
      />
      {
        !recognizedIntent && <span className={styles.separator}>|</span>
      }
      <ExtendedLabel
        dataTest="current-confidence"
        className={styles.recognizedIntentLabel}
        label={intl.formatMessage({ id: 'learning.intentTraining.confidenceLabel' })}
        value={confidence && `${(100 * confidence).toFixed(0)}%`}
        isPrimary
      />
      <Autosuggest
        suggestions={intentSuggestions}
        onSuggestionsFetchRequested={onFilterSuggestions}
        onSuggestionsClearRequested={noop}
        getSuggestionValue={suggestion => suggestion.displayName ? suggestion.displayName : suggestion.name}
        shouldRenderSuggestions={() => true}
        renderSuggestion={(intent: Intent) => <div
          data-test="suggested-intent">{getIntentDisplayName(intent)}</div>
        }
        renderInputComponent={(inputProps) => <div className={styles.inputWrapper}>
          <input {...inputProps} onChange={inputProps.onChange as React.ChangeEventHandler} />
          <DropdownIcon animationClass={styles.inputCaret} />
        </div>
        }
        inputProps={{
          className: styles.intentTrainingSelect,
          placeholder: intl.formatMessage({
            id:
              'learning.intentTraining.intentSelect.placeholder'
          }),
          onChange: (event, { newValue }) => onSetSelectedIntent(newValue),
          value: selectedIntent,
          ...{ 'data-test': 'intents-autosuggest' }
        }}
      />

      <Button
        dataTest="multiple-intents-add-button"
        buttonType="link"
        className={styles.linkButton}
        onClick={onAddMultiple}
      >
        <FormattedMessage id="learning.intentTraining.addMultiple" />
      </Button>
      <Button
        dataTest="add-intent-button"
        buttonType="primary"
        className={styles.button}
        onClick={onAddUtterance}
      >
        <FormattedMessage id="learning.intentTraining.addUtterance" />
      </Button>
    </Box>
  </div >
}
