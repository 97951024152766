import React, {FC} from 'react';
import {Route, Switch} from 'react-router';

import {Header} from '../layout/Header';
import {MainPage} from '../layout/MainPage';
import {InteractionViews} from './components/interactions/InteractionViews';
import {SchedulerStatus} from './components/scheduler-status/SchedulerStatus';
import {Contacts} from '../contacts/Contacts';
import {Scheduler} from '../scheduler/Scheduler';
import {DialerContextProvider} from './context/DialerContext';
import {HorizontalSeparator} from '../components/HorizontalSeparator/HorizontalSeparator';

import styles from './Dialer.pcss';

export const Dialer: FC = () => {
    return (
        <DialerContextProvider>
            <MainPage>
                <Header />
                <div className={styles.container}>
                    <div className={styles.header}>
                        <InteractionViews />
                        <SchedulerStatus />
                    </div>
                    <HorizontalSeparator />
                    <Switch>
                        <Route
                            path={`/:orgId?/:botId?/contacts`}
                            component={Contacts}
                        />
                        <Route
                            path={`/:orgId?/:botId?/planer`}
                            component={Scheduler}
                        />
                    </Switch>
                </div>
            </MainPage>
        </DialerContextProvider>
    )
}
