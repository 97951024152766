export enum FeatureFlag {
  LEMMATIZATION = 'LEMMATIZATION',
};

export const featureFlags = {
  get: (featureFlag: FeatureFlag) => {
    const flags = JSON.parse(localStorage.getItem('featureFlags') || '{}');
    return flags[featureFlag];
  },
  set: (featureFlag: FeatureFlag, value: boolean) => {
    const flags = JSON.parse(localStorage.getItem('featureFlags') || '{}');
    flags[featureFlag] = value;
    localStorage.setItem('featureFlags', JSON.stringify(flags));
  }
}

export const isLemmatizationEnabled = () => featureFlags.get(FeatureFlag.LEMMATIZATION);
