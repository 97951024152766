import React from 'react';

import {IconProps} from './iconTypes';

export function EditIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
          <path d="M0 0H24V24H0z"/>
          <path className={props.animationClass} d="M11.135 6.362c.322 0 .582.267.582.597 0 .297-.21.543-.488.59l-.094.008h-5.46c-.25 0-.459.185-.502.428l-.008.094v11.203c0 .257.18.47.418.515l.092.008h10.92c.25 0 .459-.185.502-.429l.008-.094v-5.601c0-.33.26-.598.582-.598.29 0 .53.217.575.5l.008.098v5.601c0 .899-.673 1.637-1.53 1.712l-.145.006H5.675c-.877 0-1.596-.69-1.669-1.57L4 19.282V8.08c0-.898.673-1.636 1.53-1.711l.145-.006h5.46zm6.074-2.695c.867-.89 2.273-.89 3.14 0 .868.89.868 2.332 0 3.222l-6.95 7.13c-.088.09-.204.15-.329.168l-2.702.397c-.385.056-.714-.282-.66-.676l.387-2.773c.017-.128.075-.247.164-.338zm2.317.845c-.412-.423-1.08-.423-1.493 0L11.22 11.5l-.249 1.788 1.74-.255 6.815-6.99c.383-.392.41-1.011.082-1.436z"/>
        </g>
      </svg>
    );
}
