import React from 'react';

import { IconProps } from './iconTypes';
import styles from './icons.pcss';

export function GoToModuleIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" className={props.animationClass}>
            <g fill="none" fillRule="evenodd">
                <path className={styles.iconFillColor} fillRule="nonzero" d="M17.438.81c.28 0 .511.204.555.472l.007.09v15.75c0 .311-.252.563-.563.563-.28 0-.511-.204-.555-.471l-.007-.091V1.373c0-.31.252-.563.563-.563zM11.57 5.972l.078.066L14.46 8.85c.022.022.042.045.06.07l-.06-.07c.031.031.058.065.08.101.011.017.02.035.03.053l.014.031.019.055.009.036.01.058.002.03.001.034-.001.034c0 .01-.001.02-.003.029l.004-.063c0 .042-.005.083-.013.122l-.01.035c-.005.019-.011.037-.019.055l-.014.032c-.008.018-.018.036-.029.053l-.015.023-.009.012-.056.065-2.812 2.813c-.22.22-.576.22-.796 0-.195-.195-.217-.498-.065-.718l.065-.077 1.852-1.854L.562 9.81C.253 9.81 0 9.558 0 9.248c0-.28.204-.512.471-.555l.092-.008h12.142l-1.853-1.852c-.195-.195-.217-.498-.065-.718l.065-.077c.196-.196.499-.217.718-.066z"/>
            </g>
        </svg>
    );
}
