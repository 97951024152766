import React, {useCallback} from 'react';

import {Box} from '../../../../components/Box/Box';
import {Value} from '../../../../components/Select/Select';
import {SelectWithLabel} from '../../../../components/SelectWithLabel/SelectWithLabel';
import {TimezoneProps} from '../../types';

import {getTimeZones} from './timeZones';
import { sortByNameComparator } from '../../../../utils/SortUtils';


export const Timezone: React.FC<TimezoneProps> = ({
                                                      disabled,
                                                      timezone,
                                                      updateTimezone
                                                  }) => {
    const options = getTimeZones().map(zone => ({
        name: zone,
        id: zone
    })).sort(sortByNameComparator);
    const value = options.find(option => option.name === timezone)
    const onChange = useCallback((v: Value) => updateTimezone(v.name), [updateTimezone])

    return <Box
        border
    >
        <SelectWithLabel
            dataTest="timezone-select"
            labelId="survey.config.timeZone"
            isDisabled={disabled}
            options={options}
            onChange={onChange}
            value={value}
            noPadding
            isSearchable
        />
    </Box>
}
