import React from 'react';

export function AlertIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g fill="none" fillRule="evenodd">
        <g fill="#F94242" fillRule="nonzero">
          <g>
            <path
              d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0zm0 1.4C7.937 1.4 1.4 7.937 1.4 16c0 8.063 6.537 14.6 14.6 14.6 8.063 0 14.6-6.537 14.6-14.6 0-8.063-6.537-14.6-14.6-14.6zm0 20.44l.117.006c.536.058.953.512.953 1.064 0 .59-.479 1.07-1.07 1.07-.591 0-1.07-.48-1.07-1.07 0-.552.417-1.006.953-1.064L16 21.84zm0-15.917c.354 0 .647.263.694.605l.006.095V17.48c0 .386-.313.7-.7.7-.354 0-.647-.264-.694-.605l-.006-.095V6.623c0-.387.313-.7.7-.7z"
              transform="translate(-463.000000, -108.000000) translate(463.000000, 108.000000)"/>
          </g>
        </g>
      </g>
    </svg>
  );
}
