import React from 'react';

import {IconProps} from './iconTypes';

export function ContactsListIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <g fill="none" fillRule="evenodd">
              <g transform="translate(-88 -120)">
                  <g transform="translate(87 119)">
                      <path d="M1 1H19V19H1z"/>
                      <path className={props.animationClass} fill={'#919aaf'} d="M10 .963c4.991 0 9.038 4.046 9.038 9.037 0 4.991-4.047 9.038-9.038 9.038C5.009 19.038.963 14.99.963 10 .963 5.009 5.009.963 10 .963zm0 1.2C5.671 2.163 2.162 5.67 2.162 10s3.51 7.837 7.838 7.837c4.329 0 7.837-3.508 7.837-7.837S14.33 2.162 10 2.162zM6.062 14.537c-.298 0-.545-.218-.592-.503l-.007-.098v-6.75c0-.33.268-.6.6-.6.298 0 .545.218.592.503l.007.098v6.15l1.05-.001V10c0-.298.218-.546.503-.592l.098-.008c.298 0 .545.218.592.503l.008.097-.001 3.337h1.05V8.313c0-.299.218-.546.503-.593l.098-.007c.33 0 .6.268.6.6l-.001 5.024h1.05v-3.9c0-.33.27-.6.6-.6.299 0 .546.218.593.503l.008.098-.001 3.899h.525c.299 0 .546.218.593.503l.008.098c0 .298-.218.545-.503.592l-.098.008H6.063z"/>
                  </g>
              </g>
          </g>
      </svg>
    );
}
