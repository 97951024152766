import classNames from 'classnames';
import { default as React, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { AddPurpleIcon } from '../../../icons';
import type {
  CompoundConditions,
  ConditionGroup,
  Condition as ConditionType,
  Variable,
  QuestionError,
} from '../../model';
import { ConditionSource } from './ConditionSource';
import { VariableType } from '../variables/Variables.utils';
import { isVariableDictionaryType, VariableResolverReturnType } from '../variables/variableResolver/variableResolver';

import { useCompoundConditions } from './useCompoundConditions';
import { SelectComponent } from '../../../components/Select/Select';
import { HorizontalSeparator } from '../../../components/HorizontalSeparator/HorizontalSeparator';
import { Button } from '../../../components/Button/Button';
import { Condition } from './Condition';
import styles from './ConditionalQuestionForm.pcss';

interface Props {
  conditions: CompoundConditions;
  onConditionsChange: (conditions: CompoundConditions) => void;
  questionSaveToVariableId?: string;
  variables: Variable[];
  variablesForCreator: { id: string; name: string; }[];
  resolvedVariablesTypes: VariableResolverReturnType;
  errors?: QuestionError[];
}

export const CompoundConditionsSelector = (props: Props) => {
  const intl = useIntl();

  const connectiveOptions = [
    { id: 'AND', name: intl.messages['survey-creator.conditional.connectiveAND'] as string },
    { id: 'OR', name: intl.messages['survey-creator.conditional.connectiveOR'] as string },
  ];

  const selectableVariables: ConditionSource[] = useMemo(() => props.resolvedVariablesTypes ? props.variables
    .filter(variable => props.resolvedVariablesTypes[variable.id])
    .map((variable) => {
      const resolvedType = props.resolvedVariablesTypes[variable.id]
      if (isVariableDictionaryType(resolvedType)) {
        return {
          variable: { id: variable.id, key: variable.name },
          type: VariableType.DICTIONARY,
          possibleValues: resolvedType.map(value => ({ id: value.id, key: value.key })),
        }
      }

      return {
        variable: { id: variable.id, key: variable.name },
        type: resolvedType.type,
        possibleValues: [],
      }
    }) : [], [props.resolvedVariablesTypes, props.variables]);

  const {
    rootConnective,
    groups,
    setRootConnective,
    setGroupConnective,
    addGroup,
    addCondition,
    removeCondition,
    updateCondition,
  } = useCompoundConditions(props.conditions, props.onConditionsChange);

  const errorsForConditions = useMemo(() => {
    return props.errors?.reduce((acc, error) => {
      const [errorGroupIndex, errorConditionIndex] = error.field.match(/[\d]/g);
      const key = error.field.split('.').reverse()[0];
      return {
        ...acc,
        [errorGroupIndex]: {
          [errorConditionIndex]: {
            ...acc[errorGroupIndex]?.[errorConditionIndex],
            [key]: intl.messages[`step.tabs.conditions.${key}.error`],
          },
        },
      };
    }, {});
  }, [props.errors]);

  return (
    <div className={styles.conditions}>
      <div className={styles.rootConnective}>
        <span>{intl.messages['survey-creator.conditional.rootConnectiveLabel']}</span>
        <SelectComponent
          data-test="root-connective"
          className={styles.rootConnectiveSelector}
          value={connectiveOptions.find(({ id }) => id === rootConnective)}
          options={connectiveOptions}
          onChange={({ id }) => setRootConnective(id)}
        />
        <HorizontalSeparator />
      </div>
      {groups.map((group: ConditionGroup, groupIndex) => (
        <React.Fragment key={`group-${groupIndex}`}>
          <div data-test="condition-group" className={classNames(styles.conditionGroup, {
            [styles.conditionGroupMultiple]: group.args.length > 1,
          })}>
            {group.args.length > 1 && (
              <div className={styles.groupConnective}>
                <span>{intl.messages['survey-creator.conditional.groupConnectiveLabel']}</span>
                <SelectComponent
                  data-test="connective"
                  className={styles.groupConnectiveSelector}
                  value={connectiveOptions.find(({ id }) => id === group.connective)}
                  options={connectiveOptions}
                  onChange={({ id }) => setGroupConnective(id, groupIndex)}
                />
                <HorizontalSeparator />
              </div>
            )}
            {group.args?.map((condition: ConditionType, conditionIndex) => (
              <Condition
                key={conditionIndex}
                selectableVariables={selectableVariables}
                condition={condition}
                onChange={(cond) => updateCondition(groupIndex, conditionIndex, cond)}
                onRemove={() => removeCondition(groupIndex, conditionIndex)}
                questionSaveToVariableId={props.questionSaveToVariableId}
                variables={props.variables}
                variablesForCreator={props.variablesForCreator}
                errors={errorsForConditions?.[groupIndex]?.[conditionIndex]}
              />
            ))}
            <Button data-test="add-condition" Icon={AddPurpleIcon} buttonType="link" onClick={() => addCondition(groupIndex)}>
              {intl.messages['survey-creator.conditional.addButtonLabel']}
            </Button>
          </div>
          {groupIndex < groups.length - 1 && (
            <div className={styles.groupSeparator}>
              <span>{intl.messages[`survey-creator.conditional.connective${rootConnective}`]}</span>
              <HorizontalSeparator />
            </div>
          )}
        </React.Fragment>
      ))}
      <div className={styles.addGroup}>
        <Button data-test="add-condition-group" Icon={AddPurpleIcon} buttonType="normal" onClick={addGroup}>
          {intl.messages['survey-creator.conditional.addGroupButtonLabel']}
        </Button>
        <HorizontalSeparator />
      </div>
    </div>
  );
};
