import * as React from 'react';

import { FilterInputElement } from '../FilterInputElement/FilterInputElement';
import { SearchPhraseIcon } from '../../icons';

interface Props {
    readonly className?: string;
    readonly phrase?: string;
    readonly withDebounce?: boolean;
    readonly placeholderId?: string;

    onChange(phrase?: string): void;
}

export function PhraseFilter(props: Props) {
    return (
      <FilterInputElement onChange={props.onChange} className={props.className} dataTestPrefix={'phrase-filter'}
                          icon={<SearchPhraseIcon />} placeholderId={props.placeholderId || 'conversations.filters.phrase.label'}
                          value={props.phrase} withDebounce={props.withDebounce}
      />
    );
}
