import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '../components/Button/Button';
import styles from './CancelContact.pcss';

type CancelContactProps = {
  onCancel: React.MouseEventHandler;
}

export const CancelContact = ({ onCancel }: CancelContactProps) => {
  return <div className={styles.cancelContact}>
    <Button
      dataTest="cancel-contact-button"
      buttonType="normal"
      onClick={onCancel}
    >
      <FormattedMessage id="contacts.details.cancelContactButtonLabel" />
    </Button>
    <FormattedMessage
      id="contacts.details.cancelContactDescription"
    >
      {txt => <p
        data-test="cancel-contact-label"
        className={styles.cancelContactDescription}
      >{txt}</p>}
    </FormattedMessage>
  </div>;
}