import React from 'react';
import cx from 'classnames';

import styles from './icons.pcss';

export function ProgressIcon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <circle cx="8" cy="8" r="6" className={cx(styles.iconFillColor, styles.iconStrokeColor)} fillRule="evenodd" strokeWidth="4"/>
      </svg>
    );
}
