import React from 'react';
import { useIntl } from 'react-intl'

import styles from './StepLineDetails.pcss';

export const StepLinkExpired: React.FC = () => {
  const intl = useIntl();

  return <span className={styles.stepLinkExpired}>
    {intl.formatMessage({ id: 'conversations.details.modal.stepLinkExpired' })}
  </span>
}