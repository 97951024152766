import React from 'react';

import { WordQuestion } from '../../model';
import { AcceptAnyAnswerToggle } from '../AcceptAnyAnswerToggle';
import { AnonymizeAnswerToggle } from '../AnonymizeAnswerToggle';
import { AnyIfMultipleAnswersToggle } from '../AnyIfMultipleAnswersToggle';
import { AnswerTabContainer } from '../../DictionaryQuestionAnswers';
import { DuplexToggle } from '../DuplexToggle';
import { ExpectedAnswersCount } from '../ExpectedAnswersCount';
import { IntentsTab } from '../IntentsTab/IntentsTab';
import { BasicMessages } from '../messages/BasicMessages';
import { RequiredAnswer } from '../RequiredAnswer';
import { SaveAs } from '../SaveAs';
import { PostSpeechTimeoutField } from '../PostSpeechTimeoutField/PostSpeechTimeoutField';
import { UseDynamicPhonemizationToggle } from '../UseDynamicPhonemizationToggle';
import { PreSpeechTimeoutField } from '../PreSpeechTimeoutField/PreSpeechTimeoutField';
import { RecognizerField } from '../RecognizerField/RecognizerField';

import { TabFactory } from './question-component-factory-model';
import { StepTabType } from './types';

export const wordQuestionFactory: TabFactory<WordQuestion> = {
  [StepTabType.Question]: ({ question, onChange, isConditional }) => {
    return (
      <>
        <BasicMessages question={question} onChange={onChange} isConditional={isConditional} />
      </>
    );
  },
  [StepTabType.General]: ({ question, onChange }) => {
    return (
      <>
        <RecognizerField question={question} onChange={onChange} />
        <SaveAs question={question} onChange={onChange} />
        <ExpectedAnswersCount question={question} onChange={onChange} />
        <RequiredAnswer question={question} onChange={onChange} />
        <AcceptAnyAnswerToggle question={question} onChange={onChange} />
        <UseDynamicPhonemizationToggle question={question} onChange={onChange} />
        <AnyIfMultipleAnswersToggle question={question} onChange={onChange} />
        <DuplexToggle question={question} onChange={onChange} />
        <AnonymizeAnswerToggle question={question} onChange={onChange} />
        <PostSpeechTimeoutField question={question} onChange={onChange} />
        <PreSpeechTimeoutField question={question} onChange={onChange} />
      </>
    );
  },
  [StepTabType.Answer]: ({ question, onChange }) => {
    return (
      <>
        <AnswerTabContainer question={question} onChange={onChange} />
      </>
    );
  },
  [StepTabType.Intents]: (props) => <IntentsTab {...props} />
};
