import styles from './HistoryModal.pcss';
import { ModalContent } from '../../components/ModalContent/ModalContent';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../components/Button/Button';
import { Modal } from '../../components/Modal/Modal';
import React, {useEffect, useState} from 'react';
import useScenarioHistory from './useScenarioHistory';
import { useCurrentBot } from '../../utils/CurrentBot';
import { Loader } from '../../components/Loader';
import { Badge } from '../../components/Badge/Badge';
import { CallbackIcon, EditIcon } from '../../icons';
import { Input } from '../../components/Input';

const HistoryModal = () => {
  const intl = useIntl();
  const currentBot = useCurrentBot();
  const [isPending, setIsPending] = useState(false);
  const [annotatedSnapshotId, setAnnotatedSnapshotId] = useState<string>(null);
  const [editedAnnotation, setEditedAnnotation] = useState<string>('');

  const { isHistoryModalOpen, closeHistoryModal, history, isLoading, rollbackById, annotateById, isUpdatingAnnotation } = useScenarioHistory(currentBot.id);

  useEffect(() => {
    setAnnotatedSnapshotId(null);
  }, [isHistoryModalOpen]);

  if (!isHistoryModalOpen) {
    return null;
  }

  return (
    <Modal
      className={styles.newModalContent}
      position="top"
      dataTest="history-modal"
      noPadding
      noFixedHeight
    >
      <ModalContent
        headerContent={<FormattedMessage id={'survey-creator.history.modal.title'} />}
        footerContent={
          <Button
            className={styles.newModalButton}
            dataTest={'history-modal-close'}
            translateText="survey-creator.history.modal.cancel"
            onClick={closeHistoryModal}
          />
        }
      >
        <div className={styles.historyModalContainer}>
          {(isLoading || isPending) ? (
            <Loader />
          ) : (
            history?.map((snapshot) => (
              <div key={snapshot.id} className={styles.snapshot}>
                {annotatedSnapshotId === snapshot.id && (
                  <div className={styles.snapshotRowTop}>
                    {isUpdatingAnnotation ? (
                        <Loader className={styles.annotationLoader} />
                      ) : (
                        <>
                          <Input
                            className={styles.botNameInput}
                            value={editedAnnotation}
                            onChange={(v) => setEditedAnnotation(v)}
                          />
                          <Button
                            buttonType="normal"
                            className={styles.rollbackButton}
                            type="button"
                            onClick={async () => {
                              await annotateById(snapshot.id, editedAnnotation);
                              setAnnotatedSnapshotId(null);
                            }}
                          >
                            {intl.messages['survey-creator.history.modal.save']}
                          </Button>
                          <Button
                            buttonType="normal"
                            className={styles.rollbackButton}
                            type="button"
                            onClick={() => setAnnotatedSnapshotId(null)}
                          >
                            {intl.messages['survey-creator.history.modal.cancel']}
                          </Button>
                        </>
                      )
                    }
                  </div>
                )}

                {annotatedSnapshotId !== snapshot.id && snapshot.annotation && (
                  <div className={styles.snapshotRowTop}>
                    <span className={styles.annotation}>{snapshot.annotation}</span>
                  </div>
                )}

                <div className={styles.snapshotRowBottom}>
                  <div className={styles.overwrittenBy}>
                    <FormattedMessage
                      id="survey-creator.history.modal.snapshotTitle"
                      values={{
                        overwrittenAt:
                          <Badge>{(new Date(snapshot.overwrittenAt)).toLocaleString()}</Badge>,
                        overwrittenBy: <Badge>{snapshot.overwrittenByEmail}</Badge>,
                      }}
                    />
                  </div>

                  <div className={styles.rollbackButtonsContainer}>
                    <Button
                      Icon={EditIcon}
                      buttonType="normal"
                      className={styles.rollbackButton}
                      type="button"
                      onClick={() => {
                        setAnnotatedSnapshotId(snapshot.id);
                        setEditedAnnotation(snapshot.annotation || '');
                      }}
                    >
                      {snapshot.annotation ? intl.messages['survey-creator.history.modal.changeAnnotation'] : intl.messages['survey-creator.history.modal.annotate']}
                    </Button>

                    <Button
                      Icon={CallbackIcon}
                      buttonType="normal"
                      className={styles.rollbackButton}
                      type="button"
                      onClick={async (e) => {
                        setIsPending(true);
                        await rollbackById(snapshot.id);
                        location.reload();
                      }}
                    >
                      {intl.messages['survey-creator.history.modal.rollback']}
                    </Button>
                  </div>
                </div>
              </div>
            ))
          )}

          {!isLoading && history?.length === 0 && (
            <div>
              {intl.messages['survey-creator.history.modal.emptyState']}
            </div>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
}

export default HistoryModal;
