import * as React from 'react';
import styles from './StatisticsSummarySection.pcss';
import classNames from 'classnames';

type Props = {
  isWide?: boolean;
  dataTest?: string;
  children: React.ReactElement;
};

export function StatisticsSectionBox({
  isWide,
  dataTest,
  children,
}: Props) {
  return (
    <div className={classNames(styles.container, isWide && styles.wide)} data-test={dataTest}>
      {children}
    </div>
  );
}
