import React, { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { BlocksContext } from '../../../containers';

import { ElementWithLabel } from '../../../../../components/ElementWithLabel/ElementWithLabel';
import { SelectGoToBlock } from '../../../../components/SelectGoTo';

import { ReactionType } from '../../../types';
import { IncomprehensionProps } from './types';

import styles from './Incomprehension.pcss';

const Incomprehension: React.FC<IncomprehensionProps> = ({ onChange, value }) => {
    const { blocks } = useContext(BlocksContext);

    const currentBlockId = useMemo(() => {
        if (value.type === ReactionType.GOTO) {
            return value.moduleId;
        }
        return ReactionType.CONTINUE;
    }, [value]);
    return <div data-key={'repeated'} className={styles.itemContainer}>
        <div className={styles.itemLabel} data-test="go-to-label">
            <FormattedMessage id={`survey-creator.goTo.repeatedLabel`}/>
        </div>
        <div className={styles.itemDescription} data-test="go-to-description">
            <FormattedMessage id={`survey-creator.goTo.repeatedDescription`}/>
        </div>
        <ElementWithLabel
            labelId={'survey-creator.goTo.titleLabel'}
            className={styles.selectContainer}
            data-test="go-to-select"
            noPadding
            inner
        >
            <SelectGoToBlock
                currentBlockId={currentBlockId}
                onChange={onChange}
                blocks={blocks}
                includeContinue
            />
        </ElementWithLabel>
    </div>
}

export default Incomprehension;
