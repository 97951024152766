import * as React from 'react';

import {StepElementProps} from './StepElementProps';
import {ToggleWithLabel} from './ToggleWithLabel';

type Props = { anyIfMultipleAnswers?: boolean };
export const AnyIfMultipleAnswersToggle = ({onChange, question}: StepElementProps<Props>) => {
    const setAnyIfMultipleAnswers = () => onChange({...question, anyIfMultipleAnswers: !question.anyIfMultipleAnswers});
    return (
        <ToggleWithLabel dataTest={'any-if-multiple-answers-toggle'} labelId={'survey-creator.anyIfMultipleAnswersLabel'}
                         value={!!question.anyIfMultipleAnswers} onToggle={setAnyIfMultipleAnswers} />
    );
};
