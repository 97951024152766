import React, {useCallback} from 'react';
import cx from 'classnames';

import { DraggableItems, DragHandleProps } from '../../../../components/DraggableItems/DraggableItems';
import { DragAnswerIcon } from '../../../../icons/DragAnswerIcon';

import { Answer } from '../Answer';
import { AnswerItem, AnswerType } from '../../types';
import { AnswerListProps } from './types';

import styles from './AnswerList.pcss';

const AnswerList: React.FC<AnswerListProps> = ({ answers, onChange }) => {
    // watch out, fn returns fn
    const getChangeHandler = useCallback((answer: AnswerItem) => (value) => {
        onChange(answers.map(a => a.id === answer.id ? value : a));
    }, [answers, onChange]);

    const handleRemove = useCallback((answer: AnswerItem) => {
        onChange(answers.filter(a => a.id !== answer.id));
    }, [answers, onChange]);

    const renderHandle = (props: DragHandleProps, item: AnswerItem) => {
        return <div {...props} className={cx(props.className, {
            [styles.entityDragHandle]: item.type === AnswerType.ENTITY,
        })}>
            <DragAnswerIcon />
        </div>;
    }

    const renderItem = (answer: AnswerItem) => {
        return <Answer
            key={answer.id}
            answer={answer}
            onChange={getChangeHandler(answer)}
            onRemove={handleRemove}
        />;
    }

    return <div data-test="dictionary-answers">
        <DraggableItems
            dataTest={'answers-drag-drop-container'}
            onDragEnd={onChange}
            items={answers}
            renderHandle={renderHandle}
            renderItem={renderItem}
        />
    </div>;
};

export default AnswerList;
