import cx from 'classnames';
import React from 'react';

import { StandardInitialProps } from '../types'

import styles from './Column.pcss';

export type ColumnProps = StandardInitialProps;
export const Column: React.FC<ColumnProps> = ({
  children,
  className,
  dataTest
}) => <div
  data-test={dataTest}
  className={cx(styles.column, className)}
>
    {children}
  </div>