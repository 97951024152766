import React from 'react';

export function MaximizeIcon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g transform="translate(-385 -656) translate(377 648) translate(8 8)">
                <path d="M0 0H32V32H0z"/>
                <path fill="#FFF" fillRule="nonzero" d="M13.012 23.42c.853 0 1.544.69 1.544 1.543v1.493c0 .853-.691 1.544-1.544 1.544H5.544C4.69 28 4 27.309 4 26.456v-1.493c0-.853.691-1.544 1.544-1.544zm-.05 1.591H5.594v1.395h7.37V25.01zm5.842-11.815l.028.03c.014.015.027.031.04.048l-.068-.078c.035.035.066.073.094.113.011.016.022.033.032.051.01.015.018.031.026.048l.02.045c.008.017.014.035.02.052l.014.046c.005.019.01.038.013.057l.004.022.003.022c.004.027.006.054.007.081v4.508c0 .44-.356.796-.796.796-.396 0-.725-.289-.787-.667l-.01-.13-.001-2.557-5.734 5.735c-.312.311-.816.311-1.127 0-.277-.276-.307-.706-.092-1.016l.092-.11 5.735-5.737-2.558.001c-.396 0-.724-.289-.786-.667l-.01-.13c0-.396.289-.724.667-.786l.13-.01H18.27c.016 0 .032.002.047.003l-.076-.003c.05 0 .097.004.144.013l.04.008.051.014.06.021.052.023.036.019.012.007.079.05c.03.024.06.05.089.078zM25.71 4c1.198 0 2.181.92 2.282 2.093L28 6.29v10.457c0 1.198-.92 2.182-2.093 2.282l-.197.008h-4.482c-.44 0-.796-.356-.796-.796 0-.396.288-.725.667-.787l.13-.01h4.48c.343 0 .627-.247.686-.572l.012-.125-.001-6.673H11.568v5.18c0 .44-.356.796-.796.796-.396 0-.725-.29-.786-.668l-.01-.129V6.29c0-1.198.92-2.181 2.092-2.282L12.266 4H25.71zm0 1.593H12.266c-.343 0-.627.247-.686.572l-.012.125v2.191h14.838v-2.19c0-.343-.246-.627-.571-.686l-.125-.012z"/>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
}
