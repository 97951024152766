import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useCurrentBot } from '../../../utils/CurrentBot';

import { HttpClient } from '../../../utils/HttpClient';

import { GlobalSynonymsContext } from '../containers';
import { Synonym } from '../../model';

function useGlobalSynonyms(key: string): Synonym[] {
    const { globalSynonyms: synonymsKeys } = useContext(GlobalSynonymsContext);
    const normalizedKey = useMemo(() => key.toLowerCase().trim(), [key])
    const cacheRef = useRef(new Map<string, Synonym[]>());
    const [globalSynonyms, setGlobalSynonyms] = useState<Synonym[]>([]);
    const bot = useCurrentBot();

    useEffect(() => {
        async function downloadSynonyms(): Promise<Synonym[]> {
            if (cacheRef.current.has(normalizedKey)) {
                const cached = cacheRef.current.get(normalizedKey);
                setGlobalSynonyms(cached);
                return cached;
            }

            const response = await HttpClient.get({path: `/bots/${bot.id}/global-synonyms/${normalizedKey}`})
                .then((getResponse) => ({...getResponse, data: getResponse.data}));
            const value = response.data || [];
            cacheRef.current.set(normalizedKey, value);
            setGlobalSynonyms(value);
            return value;
        }
        if (synonymsKeys.includes(normalizedKey)) {
            downloadSynonyms();
        } else {
            setGlobalSynonyms([]);
        }
    }, [bot.id, normalizedKey, synonymsKeys]);

    return globalSynonyms;
}

export default useGlobalSynonyms;
