import moment from 'moment';
import * as React from 'react';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import OutsideClickHandler from 'react-outside-click-handler';

import { DropdownButton } from '../../components/DropdownButton/DropdownButton';
import { CallDurationFilter } from '../../filters/CallDurationFilter/CallDurationFilter';
import { PhoneNumberFilter } from '../../filters/PhoneNumberFilter/PhoneNumberFilter';
import { PhraseFilter } from '../../filters/PhraseFilter/PhraseFilter';
import { CallDuration } from '../../filters/types';
import { AddIcon } from '../../icons';
import { useCurrentBot } from '../../utils/CurrentBot';
import { ConversationsFilters } from '../reports/useReports';
import { Report } from '../reports/model';
import { ConversationsReportDropdownContent } from './ConversationsReportDropdownContent';
import { DatePicker } from '../../components/Dates/DatePicker/DatePicker';

import styles from './ConversationsFiltersView.pcss';

interface Props {
    showReportsDropdown: boolean;
    timezone?: string;
    reports: readonly Report[];
    loadingReports: boolean;
    generateReport: () => void;
    downloadReport: (report: Report) => void;
    filters: ConversationsFilters;
    setFilters: (newFilters: Partial<ConversationsFilters>) => void;
    onOpenReportsDropdown(): void;
    onCloseReportsDropdown(): void;
}

export const ConversationsFiltersView: React.FC<Props> = ({
    onOpenReportsDropdown,
    onCloseReportsDropdown,
    showReportsDropdown,
    timezone,
    reports,
    loadingReports,
    generateReport,
    downloadReport,
    filters,
    setFilters
}) => {
    const intl = useIntl();
    const bot = useCurrentBot();
    const surveyBot = bot.type === 'survey';
    const onChangePhrase = useCallback((phrase: string) => setFilters({ phrase: phrase }), [setFilters]);
    const onPhoneChange = useCallback((phoneNumber: string) => setFilters({ phoneNumber: phoneNumber }), [setFilters]);
    const onDurationChange = useCallback((callDuration: CallDuration) => {
        setFilters({ callDurationMin: callDuration.min?.toString(), callDurationMax: callDuration.max?.toString() });
    }, [setFilters]);
    const onRangeChange = useCallback(
        (startDate?: string, endDate?: string) => {
            setFilters({
                startDate: startDate ? moment.tz(startDate, timezone).startOf('day').toISOString() : undefined,
                endDate: endDate ? moment.tz(endDate, timezone).endOf('day').toISOString() : undefined
            });
        },
        [setFilters]
    );
    return (
        <div className={styles.filters}>
            <div className={styles.dateFilter}>
                <DatePicker
                    data-test="conversations-date-range-filter"
                    startDate={!!filters.startDate ? moment.tz(filters.startDate, timezone).format('YYYY-MM-DD') : null}
                    endDate={!!filters.endDate ? moment.tz(filters.endDate, timezone).format('YYYY-MM-DD') : null}
                    onChange={onRangeChange}
                />
            </div>
            <CallDurationFilter
                className={styles.durationFilter}
                min={parseInt(filters.callDurationMin, 10)}
                max={parseInt(filters.callDurationMax, 10)}
                onChange={onDurationChange}
            />
            <PhoneNumberFilter
                className={styles.phoneFilter}
                phoneNumber={filters.phoneNumber}
                onChange={onPhoneChange}
                withDebounce={true}
            />
            <PhraseFilter
                className={styles.phraseFilter}
                phrase={filters.phrase}
                onChange={onChangePhrase}
                withDebounce={true}
            />
            {
                surveyBot &&
                <OutsideClickHandler
                    onOutsideClick={onCloseReportsDropdown}
                >

                    <DropdownButton
                        dataTest="conversations-reports-dropdown"
                        isOpen={showReportsDropdown}
                        onOpen={onOpenReportsDropdown}
                        Icon={AddIcon}
                        label={intl.formatMessage({ id: 'conversations.filters.report' })}
                    >
                        <ConversationsReportDropdownContent
                            isLoading={loadingReports}
                            reports={reports}
                            downloadReport={downloadReport}
                            generateReport={generateReport}
                            hasSelectedDateFilters={Boolean(filters.startDate) && Boolean(filters.endDate)}
                        />

                    </DropdownButton>
                </OutsideClickHandler>
            }
        </div>
    );
};
