import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { scroller } from 'react-scroll';
import classnames from 'classnames';

import { Message, MultipleMessages } from '../../../../../model';

import { ElementWithLabel } from '../../../../../../components/ElementWithLabel/ElementWithLabel';
import { MessageComponent } from '../../../../../components/messages/Message';
import { MultipleMessagesComponent } from '../../../../../components/messages/MultipleMessages';
import { ArrowRightIcon } from '../../../../../../icons/ArrowRightIcon';

import { DisambiguationAnswer } from '../../../../types';
import { DisambiguationCardProps } from './types';

import styles from './DisambiguationCard.pcss';
import { ModelToViewMapper } from '../../../../services';

const DisambiguationCard: React.FC<DisambiguationCardProps> = ({
    itemIndex,
    value,
    className,
    shouldRenderDescription,
    onChange,
}) => {
    const identifier = useMemo(() => ModelToViewMapper.getDisambiguationIdentifier(value.answers), [value.answers]);

    const handleMessageChange = useCallback((message: Message) => onChange({
        ...value,
        message,
    }, itemIndex), [onChange, value, itemIndex]);

    const handleRepeatMessageChange = useCallback((repeatMessages: MultipleMessages) => onChange({
        ...value,
        repeatMessages,
    }, itemIndex), [onChange, value, itemIndex]);

    const handleScrollTo = useCallback((id) => {
        // @TODO: leftover for debugging purposes (scrolling does not work)
        // const closestScroll = containerRef.current.closest('div[style*="overflow: scroll"]')
        scroller.scrollTo(id, {
            duration: 600,
            smooth: true,
            // containerId: closestScroll && closestScroll.id,
        });
    }, []);

    const renderDisambiguationDescription = () => {
        if (!shouldRenderDescription) {
            return null;
        }
        return <div className={styles.description} data-test="not-precise-answer-description" >
            <FormattedMessage id={'survey-creator.word.notPreciseAnswer.description'}/>
        </div>;
    }

    const renderDisambiguationAnswer = (answer: DisambiguationAnswer) => {
        const id = ModelToViewMapper.getDisambiguationAnswerIdentifier(answer);
        return (
            <div
                key={id}
                data-test="not-precise-answer-element"
                className={styles.answerElement}
                onClick={() => handleScrollTo(id)}
            >
                <span data-test={'not-precise-answer-element-text'}>{answer.key}</span>
                <ArrowRightIcon/>
            </div>
        )
    }

    return (
        <div
            id={identifier}
            data-test="not-precise-answer"
            className={classnames(className, styles.notPreciseAnswer)}
        >
            {renderDisambiguationDescription()}
            <div>
                <ElementWithLabel
                    inner
                    noPadding
                    labelId={'survey-creator.word.notPreciseAnswers.answersTitle'}
                >
                    <div className={styles.notPreciseAnswerElements}>
                        {value.answers.map(renderDisambiguationAnswer)}
                    </div>
                </ElementWithLabel>
            </div>
            <div className={styles.messages}>
                <MessageComponent
                    message={value.message}
                    dataTestPrefix={'not-precise-answer'}
                    labelId={'survey-creator.word.notPreciseAnswer.messageLabel'}
                    onChange={handleMessageChange}
                    nested
                    bypassOnChangeFromContext // using onChange function from the MessageContext is better for performance, but some cases are just too complicated, and we need to bypass it.
                />
                <MultipleMessagesComponent
                    messages={value.repeatMessages}
                    dataTestPrefix={'not-precise-answer-repeat'}
                    mainLabelId={'survey-creator.word.notPreciseAnswer.repeatMessageLabel'}
                    alternativeLabelId={'survey-creator.word.notPreciseAnswer.messageAlternativeLabel'}
                    onChange={handleRepeatMessageChange}
                    noRepeatedQuestionCount
                    nested
                />
            </div>
        </div>
    )
}

export default DisambiguationCard;
