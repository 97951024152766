import { Wrapper } from 'enzyme-custom-wrappers';
import {padStart} from 'lodash';

export type DurationFilterWrapper = ReturnType<typeof wrapperForDurationFilter>;

export const wrapperForDurationFilter = (component: Wrapper) => {
    return {
        async changeDurationFilterByText(value: string) {
            component
                .findByDataTest('duration-filter-input')
                .find('input')
                .simulate('change', { target: { value } })
                .simulate('blur');
            component.update();
        },
        changeDurationFilter(minSeconds: number, maxSeconds: number = 90 * 60) {
            const durationInput = component.findByDataTest('duration-filter-input').find('input') as Wrapper;
            durationInput.typeText(
              `${padStart(Math.floor(minSeconds / 60) + '', 2, '0')}m ${padStart(
                      (minSeconds % 60) + '',
                      2,
                      '0'
                    )}s - ${padStart(Math.floor(maxSeconds / 60) + '', 2, '0')}m ${padStart(
                      (maxSeconds % 60) + '',
                      2,
                      '0'
                    )}s`
            );
            component.update();
        },
        async changeDurationTextWithoutApply(value: string) {
            component
                .findByDataTest('duration-filter-input')
                .find('input')
                .simulate('change', { target: { value } });
            component.update();
        },
        getDurationFilter() {
            return component
                .findByDataTest('duration-filter-input')
                .find('input')
                .prop('value');
        },
        blurDurationFilter() {
            const comp = (component.findByDataTest('duration-filter-input').find('input') as Wrapper).simulate('blur');
            component.update()
            return comp
        }
    };
};
