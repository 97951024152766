import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { interpretationApiLocale } from '../../../../language/botLanguage';
import { useCurrentBot } from '../../../../utils/CurrentBot';
import { fetchSynonymsForWord } from '../../../store/globalSynonyms/actions';

export const useSynonymsForWord = (word?: string) => {
  const currentBot = useCurrentBot();
  const dispatch = useDispatch();

  const languageCode = currentBot?.voiceConfig?.languageCode;
  const language = interpretationApiLocale[languageCode];

  useEffect(() => {
    if (word && language) {
      dispatch(fetchSynonymsForWord.request({ word, language }));
    }
  }, [word, language]);
}