import produce from 'immer';
import _ from 'lodash';
import { ActionType, createReducer } from 'typesafe-actions';

import { loadingStateMeta, startingStateMeta, successStateMeta } from '../../../reducers/reducerUtils';
import { Loadable } from '../../../reducers/types';
import { Synonym } from '../../model';
import { SurveyCreator } from '../actionTypes';

import * as actions from './actions';

export type GlobalWords = {
  [word: string]: Loadable<{
    word: string;
    synonyms?: Synonym[];
  }>
};

export type GlobalSynonymsStore = Loadable<{
  [language: string]: GlobalWords;
}>;

export const defaultState: GlobalSynonymsStore = {
  isLoading: false,
  isReady: false,
  hasError: false,
  data: {}
};

export type GlobalSynonymsAction = ActionType<typeof actions>;

export const globalSynonyms = createReducer<GlobalSynonymsStore, GlobalSynonymsAction>(
  defaultState, {
  [SurveyCreator.FETCH_GLOBAL_WORDS]: (state) =>
    produce(state, () => {
      return {
        ...loadingStateMeta,
        data: state.data
      };
    }),
  [SurveyCreator.FETCH_GLOBAL_WORDS_SUCCESS]: (state, { payload }) =>
    produce(state, () => {
      let data: GlobalSynonymsStore['data'] = _.cloneDeep({
        ...state.data
      });
      payload.forEach(el => {
        if (_.has(data, el.language)) {
          data[el.language][el.word] = {
            ...state?.data[el.language]?.[el.word],
            data: {
              word: el.word,
              synonyms: state?.data[el.language]?.[el.word]?.data.synonyms
            }
          };
        } else {
          data[el.language] = {
            [el.word]: {
              ...startingStateMeta,
              data: {
                word: el.word
              }
            }
          }
        }
      })

      return {
        ...successStateMeta,
        data
      };
    }),
  [SurveyCreator.FETCH_SYNONYMS_FOR_WORD]: (state, { payload }) =>
    produce(state, draft => {
      draft.data[payload.language][payload.word] = {
        ...loadingStateMeta,
        data: {
          word: payload.word
        }
      };
    }),
  [SurveyCreator.FETCH_SYNONYMS_FOR_WORD_SUCCESS]: (state, { payload }) =>
    produce(state, draft => {
      draft.data[payload.language][payload.word] = {
        ...successStateMeta,
        data: {
          word: payload.word,
          synonyms: payload.synonyms
        }
      };
    })
});