import React, { useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router'

import { LoginFormContainer } from './LoginFormContainer';
import { ResetPasswordConfirmationContainer } from './ResetPasswordConfirmationContainer';
import { ResetPasswordFormContainer } from './ResetPasswordFormContainer';
import { SetNewPasswordFormContainer } from './SetNewPasswordFormContainer';
import { EnterMfaCodeFormContainer } from './EnterMfaCodeFormContainer';
import { useNavigateDashboardIfAuth } from './hooks/useNavigateDashboardIfAuth';
import { CognitoUser } from './types';

export const LoginRouter: React.FC = () => {
  const match = useRouteMatch();
  const [user, setUser] = useState<CognitoUser | null>(null);

  useNavigateDashboardIfAuth()

  return <Switch>
    <Route
      path={`${match.path}`}
      component={() => <LoginFormContainer setUser={setUser} />}
      exact
    />
    <Route
      path={`${match.path}/reset-password`}
      component={ResetPasswordFormContainer}
      exact
    />
    <Route
      path={`${match.path}/reset-password-confirmation`}
      component={ResetPasswordConfirmationContainer}
      exact
    />
    <Route
      path={`${match.path}/set-new-password`}
      component={SetNewPasswordFormContainer}
      exact
    />
    <Route
      path={`${match.path}/enter-mfa-code`}
      component={() => <EnterMfaCodeFormContainer user={user}/>}
      exact
    />
  </Switch>;
}
