import * as React from 'react';
import { useIntl } from 'react-intl';

import { IndentedStepContent } from '../../components/layouts/IndentedStepContent/IndentedStepContent';
import { ConfirmationDictionary, WordsDictionaryItem } from '../model';

import styles from './ConfirmationDictonaryComponent.pcss';
import { DictionaryAnswer } from './DictionaryAnswer';
import { defaultConfirmationDictionary } from './questions-factory';
import { StepElementProps } from './StepElementProps';

type Props = { dictionary?: ConfirmationDictionary };

export const ConfirmationDictionaryComponent = ({ question, onChange, noReactionGoTo }: StepElementProps<Props> & { noReactionGoTo?: boolean }) => {
    const intl = useIntl();
    const dictionary = question.dictionary || defaultConfirmationDictionary();
    const setDictionaryEntry = (newEntry: WordsDictionaryItem) => {
        onChange({
            ...question,
            dictionary: dictionary.map(el => (el.id === newEntry.id ? newEntry : el)) as ConfirmationDictionary
        });
    };
    return (
        <div data-test="dictionary-answers">
            <IndentedStepContent>
                {
                    dictionary.map((answer) => (
                        <div key={answer.id} data-test={'dictionary-answer'} className={styles.answer}>
                            <DictionaryAnswer
                                answerText={intl.messages[`survey-creator.confirmationDictionary${answer.key}`] as string}
                                readOnly
                                onChange={setDictionaryEntry}
                                answer={answer}
                                noReactionGoTo={noReactionGoTo}
                            />
                        </div>
                    ))
                }
            </IndentedStepContent>
        </div>
    );
};
