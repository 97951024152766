import produce from 'immer';
import {ActionType, createReducer} from 'typesafe-actions';

import { handleFetchFailure, handleFetchStart, handleFetchSuccess } from '../../../reducers/reducerUtils';
import {Loadable} from '../../../reducers/types';
import {SurveyCreator} from '../actionTypes';

import * as actions from './actions';

export class EntitiesStore {
  entities: Loadable<string[]> = {
    isLoading: false,
    isReady: false,
    hasError: false,
    data: []
  };
  pendingEntity?: string;
}

export const defaultState = {...new EntitiesStore()};

export type EntitiesAction = ActionType<typeof actions>;

const fetchKeyCreator = () => 'entities';
export const entities = createReducer<EntitiesStore, EntitiesAction>(
  defaultState, {
    [SurveyCreator.FETCH_ENTITIES]: handleFetchStart(fetchKeyCreator),
    [SurveyCreator.FETCH_ENTITIES_SUCCESS]: handleFetchSuccess(fetchKeyCreator),
    [SurveyCreator.FETCH_ENTITIES_FAILURE]: handleFetchFailure(fetchKeyCreator, []),
    [SurveyCreator.UPDATE_ENTITIES]: (state, {payload}) =>
      produce(state, draft => {
        draft.entities.data = payload;
      }),
    [SurveyCreator.UPDATE_PENDING_ENTITY]: (state, {payload}) =>
      produce
      (state, draft => {
        draft.pendingEntity = payload;
        if (payload === undefined) {
          delete draft.pendingEntity;
        }
      })
  });

