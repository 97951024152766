import React from 'react';
import { IconProps } from './iconTypes';

export function ToastCloseIcon({ animationClass }: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={animationClass}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.785 8.217a.598.598 0 0 0-.845 0l-2.938 2.938-2.939-2.938-.083-.07a.598.598 0 0 0-.762.915L11.156 12l-2.938 2.938-.07.083a.598.598 0 0 0 .915.762l2.938-2.938 2.939 2.938.083.07a.598.598 0 0 0 .762-.915L12.847 12l2.938-2.938.07-.083a.598.598 0 0 0-.07-.762z"
        fill="#919AAF"
      />
    </svg>
  );
}
