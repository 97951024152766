import React from 'react';
import { useIntl } from 'react-intl';
import { TranscriptItemHttpStep } from '../models/Transcript';
import { Modal } from '../../components/Modal/Modal';
import { ModalContent } from '../../components/ModalContent/ModalContent';
import { TextLabel } from '../../components/Label';
import { Highlighter, prettyPrintJson } from '../../components/Highlighter';
import styles from './StepLineDetails.pcss';
import { Link } from 'react-router-dom';
import { StepLinkExpired } from './StepLinkExpired';

type Props = {
  onClose: () => void;
  line: TranscriptItemHttpStep;
  stepLink: string;
  isStepLinkExpired: boolean | undefined;
};

export function HttpStepLineDetails({ line, onClose, stepLink, isStepLinkExpired }: Props) {
  const intl = useIntl();

  return (
    <Modal
      modalSize="medium"
      dataTest="http-step-details"
      noPadding
      noFixedHeight
    >
      <ModalContent
        headerContent={
          <span data-test="http-step-details-header">{line.stepName}</span>
        }
        onClose={onClose}
        closeButtonDataTest="http-step-details-close"
        displayBottomSeparator={false}
      >
        <div className={styles.content}>
          <div className={styles.entry}>
            <TextLabel
              className={styles.stepEntry}
              label={intl.formatMessage({
                id: 'conversations.http.modal.stepLink',
              })}
            />
            {
              isStepLinkExpired
                ? <StepLinkExpired />
                : <Link
                  to={stepLink}
                  target="_blank"
                  data-test="http-step-details-step-link"
                  className={styles.stepLink}
                >
                  {line.stepName}
                </Link>
            }
          </div>
          <div className={styles.entry}>
            <TextLabel
              className={styles.stepEntry}
              label={intl.formatMessage({
                id: 'conversations.http.modal.url',
              })}
            />
            <span data-test="http-step-details-url" data-sensitive="true">{line.url}</span>
          </div>
          <div className={styles.entry}>
            <TextLabel
              className={styles.stepEntry}
              label={intl.formatMessage({
                id: 'conversations.http.modal.method',
              })}
            />
            <span data-test="http-step-details-method">
              {line.method.toUpperCase()}
            </span>
          </div>
          {line.headers && (
            <div className={styles.entry}>
              <TextLabel
                className={styles.stepEntry}
                label={intl.formatMessage({
                  id: 'conversations.http.modal.headers',
                })}
              />
              <Highlighter dataTest="http-step-details-headers" dataSensitive="true">
                {prettyPrintJson(line.headers)}
              </Highlighter>
            </div>
          )}
          {line.requestBody && (
            <div className={styles.entry}>
              <TextLabel
                className={styles.stepEntry}
                label={intl.formatMessage({
                  id: 'conversations.http.modal.requestBody',
                })}
              />
              <Highlighter dataTest="http-step-details-request-body" dataSensitive="true">
                {prettyPrintJson(line.requestBody)}
              </Highlighter>
            </div>
          )}
          {line.responseBody && (
            <div className={styles.entry}>
              <TextLabel
                className={styles.stepEntry}
                label={intl.formatMessage({
                  id: 'conversations.http.modal.responseBody',
                })}
              />
              <Highlighter dataTest="http-step-details-response-body" dataSensitive="true">
                {prettyPrintJson(line.responseBody)}
              </Highlighter>
            </div>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
}
