import styles from './ImportVisitsAsContactsModal.pcss';
import {FormattedMessage} from 'react-intl';
import {default as React} from 'react';
import moment from 'moment/moment';
import {MarkdownContent} from '../../components/MarkdownContent/MarkdownContent';
import cx from 'classnames';
import {getStringFromConditions} from './ImportVisitsAsContactsModal';
import {
  FollowUpInGivenDaysAtScheduleStep,
  FollowUpInGivenHoursScheduleStep,
  Schedule
} from '../../scheduler/schedule-model';

const getStringForFollowUpInGivenHours = (step: FollowUpInGivenHoursScheduleStep<any>) => {
  if (step.hours && step.minutes) {
    return (
      <MarkdownContent
        contentKey="contacts.add.integrationModal.step3.interactionStep.madeAfterHoursAndMinutes"
        extraValues={{
          hours: step.hours.toString(),
          minutes: step.minutes.toString(),
        }}
        className={styles.textLine}
      />
    );
  }
  if (step.hours) {
    return (
      <MarkdownContent
        contentKey="contacts.add.integrationModal.step3.interactionStep.madeAfterHours"
        extraValues={{
          hours: step.hours.toString(),
        }}
        className={styles.textLine}
      />
    );
  }
  return (
    <MarkdownContent
      contentKey="contacts.add.integrationModal.step3.interactionStep.madeAfterMinutes"
      extraValues={{
        minutes: step.minutes.toString(),
      }}
      className={styles.textLine}
    />
  );
};

const getStringForFollowUpInGivenDaysAt = (step: FollowUpInGivenDaysAtScheduleStep<any>) => {
  const time = `${step.timeOfDay.hours}:${step.timeOfDay.minutes.toString().padStart(2, '0')}`;
  if (step.days === 0) {
    return (
      <MarkdownContent
        contentKey="contacts.add.integrationModal.step3.interactionStep.madeTodayAt"
        extraValues={{ time }}
        className={styles.textLine}
      />
    );
  }
  if (step.days === 1) {
    return (
      <MarkdownContent
        contentKey="contacts.add.integrationModal.step3.interactionStep.madeTomorrowAt"
        extraValues={{ time }}
        className={styles.textLine}
      />
    );
  }
  return (
    <MarkdownContent
      contentKey="contacts.add.integrationModal.step3.interactionStep.madeOnDayAt"
      extraValues={{ days: step.days.toString(), time }}
      className={styles.textLine}
    />
  );
};

export const PlannerExecutionStage = ({ contacts, startDate, endDate, timeFrom, timeTo, conditions, schedule }: {
  contacts: { phoneNumber: string; excluded?: boolean; additionalData: Record<string, any> }[];
  startDate: string;
  endDate: string;
  timeFrom: number;
  timeTo: number;
  conditions: { option: { name: string; id: string; } | null; values: { id: string; name: string; }[] }[];
  schedule: Schedule;
}) => {
  return (
    <div className={styles.mainContainer}>
      <span>
        <span className={styles.text}>
          <FormattedMessage id="contacts.add.integrationModal.step3.description" />
        </span>
        {' '}
        <span className={styles.text}>
          <MarkdownContent
            contentKey="contacts.add.integrationModal.step3.query"
            extraValues={{
              contactsCount: contacts.filter(c => !c.excluded).length.toString(),
              dateTimeFrom: moment(startDate).startOf('day').add(timeFrom, 'minutes').format('L LT'),
              dateTimeTo: moment(endDate).startOf('day').add(timeTo, 'minutes').format('L LT'),
            }}
            className={cx(styles.textLine, styles.secondary)}
          />
          {getStringFromConditions(conditions)}
        </span>
      </span>

      <div className={styles.stepList}>
        {schedule.interactionPlan.map((step, index) => (
          <div key={index} className={styles.step}>
            <div className={styles.singleStep}>
              {index + 1}
            </div>

            <span>
              <FormattedMessage id={`contacts.add.integrationModal.step3.interactionStep.${step.stepConfig.transport.toLowerCase()}`} />
              {' - '}
              {step.type === 'JustInTime' && <FormattedMessage id="contacts.add.integrationModal.step3.interactionStep.madeRightAway" />}
              {step.type === 'FollowUpInGivenHours' && getStringForFollowUpInGivenHours(step)}
              {step.type === 'FollowUpInGivenDaysAt' && getStringForFollowUpInGivenDaysAt(step)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
