import React from 'react';
import cx from 'classnames';

import { IconProps } from './iconTypes';
import styles from './icons.pcss';

export const PauseCircleIcon: React.FC<IconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g fill="none" fillRule="evenodd">
          <circle cx="16" cy="16" r="16" className={cx(props.animationClass, styles.iconFillColor)} />
          <g fill="#FFF" fillRule="nonzero" transform="translate(11 9)">
              <rect width="4" height="14" rx=".977"/>
              <rect width="4" height="14" x="7" rx=".977"/>
          </g>
      </g>
  </svg>
);