import React, { useState } from 'react';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from '../components/Modal/Modal';
import { ModalContent } from '../components/ModalContent/ModalContent';
import { Button } from '../components/Button/Button';

import styles from './TagFilterModal.pcss';
import { Tag } from '../components/Tag/Tag';
import { useBotTags } from './hooks';

interface Props {
  onSubmit: (tags: string[]) => void;
  onClose?: () => void;
  selectedTags: string[];
}

const TagFilterModal: FC<Props> = ({ onSubmit, onClose, selectedTags }) => {
  const [tagsToSelect, setTagsToSelect] = useState<string[]>(selectedTags);
  const tags = useBotTags();

  return (
    <Modal
      dataTest="tag-filter-modal"
      position="center"
      noPadding
      modalSize="medium"
      className={styles.modal}
    >
      <ModalContent
        headerContent={<FormattedMessage id="tagFilterModal.title" />}
        footerContent={
          <>
            <Button
              buttonType="normal"
              dataTest="tag-filter-modal-cancel-button"
              onClick={onClose}
            >
              <FormattedMessage id="tagFilterModal.cancelButton" />
            </Button>
            <Button
              buttonType="primary"
              dataTest="tag-filter-modal-submit-button"
              onClick={() => {
                onSubmit(tagsToSelect);
                onClose();
              }}
            >
              <FormattedMessage id="tagFilterModal.submitButton" />
            </Button>
          </>
        }
        onClose={onClose}
        withScrollBarMargin
      >
        <div className={styles.container}>
          <span className={styles.description}>
            <FormattedMessage id="tagFilterModal.description" />
          </span>
          <div className={styles.selectedTags}>
            {tagsToSelect.map((tag) => (
              <Tag
                dataTest={`selected-filters-tag-${tag}`}
                onClick={() =>
                  setTagsToSelect((state) => state.filter((t) => t !== tag))
                }
                withCross
                key={tag}
              >
                {tag}
              </Tag>
            ))}

            {tagsToSelect.length === 0 && (
              <span className={styles.tagsToSelectEmpty}>
                <FormattedMessage id="tagFilterModal.tagsToSelectEmpty" />
              </span>
            )}
          </div>

          <hr className={styles.modalSeparator} />

          <div className={styles.unselectedTags}>
            {tags
              .filter((t) => !tagsToSelect.includes(t.tag))
              .map((tag) => (
                <Tag
                  dataTest={`unselected-filters-tag-${tag.tag}`}
                  onClick={() =>
                    setTagsToSelect((state) => [...state, tag.tag])
                  }
                  key={tag.id}
                >
                  {tag.tag}
                </Tag>
              ))}
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default TagFilterModal;
