import * as React from 'react';

import {StepElementProps} from './StepElementProps';
import {ToggleWithLabel} from './ToggleWithLabel';

type Props = { requiredAnswer?: boolean };
export const RequiredAnswer = ({question, onChange}: StepElementProps<Props>) => {
  const setRequiredAnswer = () => onChange({...question, requiredAnswer: !question.requiredAnswer});
  return (
    <ToggleWithLabel
      dataTest={'required-answer-toggle'} labelId={'survey-creator.requiredAnswer'}
      value={!!question.requiredAnswer} onToggle={setRequiredAnswer}
      descriptionId={'survey-creator.requiredAnswerDescription'}
    />
  );
};
