import classNames from 'classnames';
import _ from 'lodash';
import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { scroller } from 'react-scroll';

import { Button } from '../../../components/Button/Button';
import { Scrollbar } from '../../../components/Scrollbar/Scrollbar';
import { FilledCancelIcon } from '../../../icons/FilledCancelIcon';
import { MaximizeIcon } from '../../../icons/MaximizeIcon';
import { MinimizeIcon } from '../../../icons/MinimizeIcon';
import { SendIcon } from '../../../icons/SendIcon';
import { TextChatIcon } from '../../../icons/TextChatIcon';
import { useCurrentBot } from '../../../utils/CurrentBot';
import { now } from '../../../utils/DateService';
import { HttpClient } from '../../../utils/HttpClient';
import { ConditionalQuestion, NumericalQuestion, WordQuestion } from '../../model';
import { IntentsContext, SurveyContext } from '../../SurveyCreator';

import styles from './BotTester.pcss';

const rangeForNumericalQuestion = (numericalQuestion: NumericalQuestion) => {
  if (numericalQuestion.ranges[0]) {
    return numericalQuestion.ranges.reduce((acc, range) => {
      if (acc.length >= 5) {
        return acc;
      }
      return _.take([...acc, ..._.range(range.min, range.max + 1, 2)], 8);
    }, [] as number[]);
  }
  return _.range(1, 11, 2);
};

type Message = {
  questionId?: string,
  text: string,
  interpretation?: { intent?: string; recognized?: string },
  actor: 'bot' | 'customer'
};
type CurrentConversation = {
  callId: string,
  messages: Message[],
  finished: boolean
}

export const BotTester = () => {
  const [chatOpened, setChatOpened] = useState(false);
  const [chatMinimized, setChatMinimized] = useState(false);
  const [textMessage, setTextMessage] = useState('');
  const [conversation, setConversation] = useState<CurrentConversation>();
  const scrollElementRef = useRef<HTMLDivElement>();
  const inputRef = useRef<HTMLTextAreaElement>();
  const intl = useIntl();
  const currentBot = useCurrentBot();
  const { blocks, intents, silenceFallback } = useContext(SurveyContext);
  const allIntents = useContext(IntentsContext);
  const showChat = () => {
    if (!chatOpened) {
      startConversation();
    }
    setChatOpened(true);
    setChatMinimized(false);
  };

  useEffect(() => {
    if (scrollElementRef.current) {
      scroller.scrollTo('scroll-hook', {
        duration: 700,
        containerId: 'chat-scroll',
        smooth: true
      });
    }
  }, [conversation?.messages]);

  const currentCallId = () => conversation?.callId;

  const startConversation = async () => {
    const response = await HttpClient.post({ path: `/bots/${currentBot.id}/bot-tester/start`, body: {} })
    setConversation({ callId: response.data.callId, messages: response.data.botMessages, finished: response.data.finished });
  };

  const endConversation = async () => {
    if (conversation && !conversation.finished) {
      await HttpClient.post({path: `/bots/${currentBot.id}/bot-tester/end`, body: {callId: conversation.callId}});
    }
    setConversation(undefined);
    setTextMessage('');
  }

  const restartConversation = async () => {
    await endConversation();
    await startConversation();
  };

  const handleSend = (event: any) => {
    if (event.key === 'Enter') {
      send(textMessage);
    }
  };

  const send = (text: string) => {
    HttpClient.post({ path: `/bots/${currentBot.id}/bot-tester/message`, body: { callId: currentCallId(), text } })
      .then(response => {
        setConversation(prev => {
          return { ...prev, messages: [...prev.messages, response.data.answer, ...response.data.botMessages], finished: response.data.finished };
        });
      });
    setTextMessage('');
  };

  const chipItems = (): string[] => {
    if (conversation.messages.length === 0) {
      return [];
    }
    const questions = [
      ..._.flatten([...blocks.get(), silenceFallback.getAsBlock()].map(block => block.questions)),
      ..._.flatten(intents.get().filter(intent => _.has(intent, 'question')).map((intent: any) => intent.question))
    ];
    const question = questions.find(q => q.id === _.last(conversation.messages).questionId);
    const actualQuestion = question.type === 'conditional' ? (question as ConditionalQuestion).question : question;
    switch (actualQuestion.type) {
      case 'word':
        return _.take((actualQuestion as WordQuestion).dictionary.map(item => item.key), 5);
      case 'date':
        return [
          intl.messages[`survey-creator.botTester.suggestions.today`] as string,
          intl.messages[`survey-creator.botTester.suggestions.yesterday`] as string,
          intl.messages[`survey-creator.botTester.suggestions.tomorrow`] as string,
          now().subtract(2, 'd').format('D MMMM YYYY'),
          now().add(2, 'd').format('D MMMM YYYY')
        ];
      case 'datetime':
        return [
          `${intl.messages[`survey-creator.botTester.suggestions.today`]} ${intl.messages[`survey-creator.botTester.suggestions.at`]} 14`,
          `${intl.messages[`survey-creator.botTester.suggestions.yesterday`]} ${intl.messages[`survey-creator.botTester.suggestions.at`]} 18`,
          `${intl.messages[`survey-creator.botTester.suggestions.tomorrowBetween`]} 8 ${intl.messages[`survey-creator.botTester.suggestions.and`]} 10`,
        ];
      case 'numerical':
        const numericalQuestion = actualQuestion as NumericalQuestion;
        return rangeForNumericalQuestion(numericalQuestion).map(el => el.toString());
      default:
        return [];
    }
  };

  const itemClick = (item: string) => () => {
    send(item);
    inputRef.current.focus();
  };
  const intentDisplayName = (intentRawName: string) => {
    return allIntents.find(intent => intent.name === intentRawName)?.displayName || intentRawName;
  };

  const displayRecognizedAnswer = (recognized: any) => {
    if (recognized === true) {
      return intl.messages[`survey-creator.confirmationDictionaryYES`];
    }
    if (recognized === false) {
      return intl.messages[`survey-creator.confirmationDictionaryNO`];
    }
    return recognized;
  };

  const closeChat = async () => {
    setChatOpened(false);
    await endConversation();
  };

  const minimizeChat = () => {
    setChatMinimized(true);
  };
  const renderMessageInterpretation = (message: Message) => (
    <div className={styles.label} data-test={'message-interpretation'}>
      {message.interpretation.intent === undefined && message.interpretation.recognized === undefined
        ? intl.messages[`survey-creator.botTester.notRecognized`]
        : message.interpretation.intent
          ? `${intl.messages['survey-creator.botTester.intent']}: ${intentDisplayName(message.interpretation.intent)}`
          : `${intl.messages['survey-creator.botTester.answer']}: ${(displayRecognizedAnswer(message.interpretation.recognized))}`
      }
    </div>
  );
  return (
    <div className={styles.container}>
      {(!chatOpened || chatMinimized) && (
        <div className={styles.initiateButton}>
          <Button className={styles.trigger} onClick={showChat} dataTest={'bot-tester-trigger'}>
            {chatMinimized ? <MaximizeIcon /> : <TextChatIcon />}
          </Button>
        </div>
      )}
      {chatOpened && !chatMinimized && (
        <div className={styles.chatWindow} data-test={'bot-tester-window'}>
          <div className={styles.header}>
            <button className={styles.close} onClick={closeChat} data-test={'close'}>
              <FilledCancelIcon animationClass={styles.closeIcon} />
            </button>
            <button className={styles.minimize} onClick={minimizeChat} data-test={'minimize'}>
              <MinimizeIcon animationClass={styles.minimizeIcon} />
            </button>
            <Button className={styles.startButton} onClick={restartConversation}>
              <FormattedMessage id={'survey-creator.botTester.newChat'} />
            </Button>
          </div>


          <div className={styles.messages}>
            {conversation && (
              <Scrollbar hideHorizontal renderView={(props: any) => <div {...props} id={'chat-scroll'} />}>
                <div key={conversation.callId} className={styles.conversation} ref={scrollElementRef}>
                  {conversation.messages.map((message: Message, index) => {
                    return (
                      <div
                        data-test={'message'}
                        key={index}
                        className={classNames(styles.line, message.actor === 'customer' ? styles.human : styles.bot)}
                        data-message-actor={message.actor}
                      >
                        <div data-test-actor={message.actor} className={styles.bubble} data-test={'message-text'}>
                          {message.text ? message.text : '🦗🦗🦗'}
                        </div>
                        {message.actor === 'customer' && renderMessageInterpretation(message)}
                      </div>
                    );
                  })}
                </div>
                <div id={'scroll-hook'} />
              </Scrollbar>
            )}
          </div>
          <div className={styles.footer}>
            {
              (conversation && !conversation?.finished)
                ? (
                  <>
                    <div className={styles.chipses}>
                      {
                        chipItems()
                          .map(item => (<div data-test={'suggestion-item'} className={styles.chips} key={item} onClick={itemClick(item)}>{item}</div>))
                      }
                    </div>
                    <div className={styles.inputArea}>
                      <textarea
                        data-test={'message-input'}
                        className={styles.input}
                        ref={inputRef}
                        autoFocus
                        placeholder={intl.messages['survey-creator.botTester.inputPlaceholder'] as string}
                        onChange={(event) => setTextMessage(event.target.value.replace(/\n/g, ''))}
                        onKeyPress={handleSend}
                        value={textMessage}
                        wrap="soft"
                        rows={2}
                      />
                      <button className={styles.sendButton} data-test={'send-button'} onClick={() => send(textMessage)}>
                        <SendIcon />
                      </button>
                    </div>
                  </>
                )
                : conversation?.finished && (
                  <div className={styles.footerText}>
                    <FormattedMessage id={'survey-creator.botTester.conversationFinished'} />
                  </div>
                )
            }
          </div>
        </div>
      )}
    </div>
  );
};
