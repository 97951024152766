import { Location } from 'history';
import { isEqual, pickBy } from 'lodash';
import qs from 'qs';

import {ContactsFilterType} from './Contacts';
import {isOfContactStatus} from './model';

export function isAnyContactFilterDefined(filters: ContactsFilterType) {
    return Object.values(filters).some(value => !!value)
}

export function parseContactsFilters(locationSearch: string): Partial<ContactsFilterType> {
    const {
        startDate = undefined,
        endDate = undefined,
        status = null,
        phoneNumber,
    } = qs.parse(locationSearch, { ignoreQueryPrefix: true });
    return {
        startDate: startDate && Number(startDate),
        endDate: endDate && Number(endDate),
        phoneNumber: phoneNumber as string || '',
        status: isOfContactStatus(status as string) ? status as string : ''
    };
}

export function stringifyContactsFilters(filters: Partial<ContactsFilterType>, currentLocation: Location<{}>): Location<{}> {
    const currentSearch = qs.parse(currentLocation.search, { ignoreQueryPrefix: true });
    const newSearch = pickBy(
        {
            ...currentSearch,
            phoneNumber: filters.phoneNumber || undefined,
            status: isOfContactStatus(filters.status) ? filters.status : undefined,
            startDate: filters.startDate,
            endDate: filters.endDate
        },
        v => v !== undefined
    );
    if (isEqual(currentSearch, newSearch)) {
        return currentLocation;
    }
    return {
        ...currentLocation,
        search: qs.stringify(newSearch, { addQueryPrefix: true })
    };
}
