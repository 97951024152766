import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';

import { Button } from '../../components/Button/Button';
import { InputWithLabel } from '../../components/InputWithLabel/InputWithLabel';
import { useForm } from '../../forms/useForm';
import { LoginBox } from './components/LoginBox';
import { PageContainer } from '../components/PageContainer';
import { AlertIcon } from '../../icons';
import { Row } from '../../components/Row/Row';
import loginStyles from './components/Login.pcss';
import styles from './EnterMfaCodeFormContainer.pcss';
import { SomethingWentWrongMessage } from './components/messages/SomethingWentWrongMessage';
import { InvalidMfaCodeMessage } from './components/messages/InvalidMfaCodeMessage';
import { showErrorToast, showSuccessToast } from '../../components/Toast/Toast';
import { CognitoUser } from './types';

type UserCredentials = {
  email: string;
  password: string;
};

const schema = Yup.object().shape({
  code: Yup.string()
    .length(6, 'auth.mfaCode.codeLength')
    .required('auth.mfaCode.codeLength'),
});

const initialFields = {
  code: {
    name: 'code',
    labelId: 'auth.mfaCode.code',
    initialValue: '',
  },
};

enum MFACodeError {
  invalidMfaCode = 'invalidMfaCode',
}

const mfaCodeErrorToMessage = {
  invalidMfaCode: {
    message: <InvalidMfaCodeMessage />,
    MessageIcon: AlertIcon,
  },
  genericErrorMessage: {
    message: <SomethingWentWrongMessage />,
    MessageIcon: AlertIcon,
  },
} as const;

interface Props {
  user: CognitoUser
}

export const EnterMfaCodeFormContainer: React.FC<Props> = (props) => {
  const [loginBoxMessage, setLoginBoxMessage] = useState<string | null>(null);
  const [invalidCode, setInvalidCode] = useState(true);
  const intl = useIntl();
  const history = useHistory();
  const { state = {} } = useLocation<{
    userCredentials?: UserCredentials;
  }>();

  const [user, setUser] = useState<CognitoUser | null>(props.user);

  async function resendAuthCode() {
    try {
      const newUserSession = await Auth.signIn(
        state.userCredentials.email,
        state.userCredentials.password
      );
      setUser(newUserSession);

      showSuccessToast(
        <FormattedMessage
          id="auth.mfaCode.resendCodeLinkSuccessToast"
          values={{
            phoneNumber: newUserSession?.challengeParam?.CODE_DELIVERY_DESTINATION,
          }}
        />
      );
    } catch (error) {
      showErrorToast(
        <FormattedMessage
          id="auth.mfaCode.resendCodeLinkFailureToast"
          values={{
            mail: (
              <a href="mailto:support@talkie.ai" className={styles.link}>
                <FormattedMessage id="auth.signIn.supportEmail" />
              </a>
            ),
            phoneNumber: user?.challengeParam?.CODE_DELIVERY_DESTINATION,
          }}
        />
      );
    }
  }

  const { onFormSubmit, fieldsProps, hasErrors, isSubmitting } = useForm({
    initialFields,
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await Auth.confirmSignIn(user, values.code, 'SMS_MFA');
        history.replace('/');
      } catch (error) {
        if (error.message === 'Invalid code or auth state for the user.') {
          setLoginBoxMessage(MFACodeError.invalidMfaCode);
        } else {
          setLoginBoxMessage(error.message);
        }
        setInvalidCode(true);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const boxMessageProps = loginBoxMessage
    ? mfaCodeErrorToMessage[loginBoxMessage] ??
      mfaCodeErrorToMessage.genericErrorMessage
    : null;

  return (
    <PageContainer>
      <LoginBox
        header={intl.messages['auth.mfaCode.header'] as string}
        {...boxMessageProps}
      >
        <form onSubmit={onFormSubmit} className={styles.form}>
          <p className={styles.description}>
            <FormattedMessage
              id="auth.mfaCode.description"
              values={{
                phoneNumber:
                  user?.challengeParam?.CODE_DELIVERY_DESTINATION,
              }}
            />
          </p>
          <div className={styles.separator} />
          <InputWithLabel
            className={loginStyles.loginInput}
            onFocus={() => setInvalidCode(false)}
            {...fieldsProps.code}
          />
          <Row className={styles.row}>
            <Button
              buttonType={isSubmitting ? 'loading' : 'primary'}
              dataTest="verifyCodeButton"
              type="submit"
              disabled={hasErrors || invalidCode}
            >
              {!isSubmitting && <FormattedMessage id="auth.mfaCode.verifyButton" />}
            </Button>
          </Row>
        </form>
      </LoginBox>
      <p className={styles.resendDescription}>
        <FormattedMessage id="auth.mfaCode.resendCodeDescription" />
      </p>
      <Button
        buttonType="link"
        className={styles.resendDescriptionLink}
        onClick={resendAuthCode}
        translateText="auth.mfaCode.resendCodeLink"
      />
    </PageContainer>
  );
}
