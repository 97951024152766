import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import pickBy from 'lodash/pickBy';
import FileSaver from 'file-saver';
import slugify from 'slugify';

import { HttpClient } from '../utils/HttpClient';
import { ReportsFilters } from './useReports';

export const useGetReportsReportDownload = (
  botId: string,
  selectedReport: string,
  filters: ReportsFilters,
  options?: UseQueryOptions
): UseQueryResult =>
  useQuery(
    ['useGetReportsReport', botId, selectedReport],
    () =>
      HttpClient.get(
        {
          path: `/bots/${botId}/data-collection/${encodeURIComponent(
            selectedReport
          )}/report`,
          params: { ...pickBy(filters) },
        },
        { responseType: 'blob' }
      ).then((resp) => {
        FileSaver.saveAs(
          new Blob([resp.data]),
          `${slugify(selectedReport)}.xlsx`
        );
      }),
    {
      ...options,
    }
  );
