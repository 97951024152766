import React, { useMemo } from 'react';
import cx from 'classnames';

import { isLemmatizationEnabled } from '../../../utils/featureFlags';
import { Synonym } from '../../../surveyCreator/model';
import { LegacySynonymComponent } from './LegacySynonymComponent';
import styles from './SynonymComponent.pcss';
import { SynonymMenu } from './SynonymMenu';

interface Props {
  synonym: Synonym;
  onRemove?: React.MouseEventHandler;
  onToggle?: (synonym: Synonym) => void;
  onClick?: React.MouseEventHandler;
  mode: 'editable' | 'readonly';
  chipsItemClassName?: string;
  className?: string;
}

export const SynonymComponent: React.FC<Props> = (props) => {
  const lemmatizationEnabled = isLemmatizationEnabled();
  if (!lemmatizationEnabled) {
    return <LegacySynonymComponent {...props}/>;
  }
  return <NewSynonymComponent {...props}/>;
}

const NewSynonymComponent: React.FC<Props> = ({
  synonym,
  onRemove,
  onToggle,
  onClick,
  mode,
  chipsItemClassName,
  className
                                              }) => {
  const chipType = useMemo(() => {
    if (synonym.lemma) {
      return 'lemma';
    }
    return 'verbatim';
  }, [synonym.lemma]);

  const visibleSynonymValue = synonym.lemma ? synonym.lemma : synonym.verbatim;

  if (mode === 'editable') {
    return <span
      className={cx(className, styles.chipContainer, styles.editable)}
      data-synonym={visibleSynonymValue}
      onDragStart={(e) => e.preventDefault()}
    >
      <SynonymMenu
        synonym={synonym}
        className={styles.menu}
        onRemove={onRemove}
        onToggle={onToggle} />
      <div
        data-test={'chips-list-element'}
        className={cx(styles.chip, styles[chipType])}
        onMouseDown={(e) => e.stopPropagation()}>
          {visibleSynonymValue}
        <div style={{
          width: 0,
          height: 0,
          opacity: 0,
        }}>
          {JSON.stringify(synonym)}
        </div>
      </div>
      {synonym.lemma && <div
          className={cx(styles.chip, styles.expanded)}
          data-test={'expanded-verbatim'}
      >
        {synonym.verbatim}
      </div>}
    </span>
  } else {
    return <span className={cx(className, styles.chipContainer, styles.readOnly)} data-synonym={visibleSynonymValue}>
      <ReadOnlyChip
        chipType={chipType}
        chipsItemClassName={chipsItemClassName}
        onClick={onClick}
      >
        {visibleSynonymValue}
        <div style={{
          width: 0,
          height: 0,
          opacity: 0,
        }}>
          {JSON.stringify(synonym)}
        </div>
      </ReadOnlyChip>
      {synonym.lemma && <div
          className={cx(styles.chip, styles.expanded)}
          data-test={'expanded-verbatim'}
      >
        {synonym.verbatim}
      </div>}
    </span>
  }
}

interface ReadOnlyChipProps {
  chipType: 'verbatim' | 'lemma' | 'expandedVerbatim';
  onClick?: React.MouseEventHandler;
  chipsItemClassName?: string;
}

const ReadOnlyChip: React.FC<ReadOnlyChipProps> = ({
  children,
  onClick,
  chipsItemClassName,
  chipType,
                                                   }) => {
  return onClick
    ? <button
      className={cx(styles.chip, styles.readOnly, styles[chipType], chipsItemClassName)}
      data-test={'chips-list-element-readonly'}
      onClick={onClick}
    >
      {children}
    </button>
    : <div
      className={cx(styles.chip, styles.readOnly, styles[chipType], chipsItemClassName)}
      data-test={'chips-list-element-readonly'}
    >
      {children}
    </div>
}

