import classNames from 'classnames';
import React from 'react';

import styles from './ClearIcon.pcss';

interface Props {
    readonly className?: string;
    readonly enabled: boolean;
    onClear(): void;
}

export function ClearIcon(props: Props) {
    if (!props.enabled) {
        return <div className={classNames(styles.placeholder, props.className)} />;
    }
    return <i className={classNames(styles.icon, props.className)} onClick={props.onClear} data-test={'clear-icon'} />;
}
