import * as React from 'react';

import { ElementWithLabel } from '../../components/ElementWithLabel/ElementWithLabel';
import { Row } from '../../components/Row/Row';
import { Schedule } from '../schedule-model';

import { AcceptIncomingCallsSettings } from './AcceptIncomingCallsSettings';
import { DateOptions } from './DateOptions';
import { MaxConcurrentCallsSettings } from './MaxConcurrentCallsSettings';
import styles from './SchedulerSettings.pcss';
import { TimeSettings } from './TimeSettings';

interface Props {
    schedule: Schedule,
    setSchedule: (schedule: Schedule) => void;
    timeZone: string;
}

export const SchedulerSettings = ({
    schedule,
    setSchedule,
    timeZone
}: Props) => (
    <div>
        <div className={styles.container}>
            <Row className={styles.row}>
                <DateOptions schedule={schedule} setSchedule={setSchedule} />
                <TimeSettings schedule={schedule} setSchedule={setSchedule} />
            </Row>
            <ElementWithLabel
                noPadding
                inner
                labelId="scheduler.timeZoneLabel"
                className={styles.timezone}
                dataTest="timezone"
            >
                {timeZone}
            </ElementWithLabel>
        </div>
        <MaxConcurrentCallsSettings schedule={schedule} setSchedule={setSchedule} />
        <AcceptIncomingCallsSettings schedule={schedule} setSchedule={setSchedule} />
    </div>
);
