import _, {isEqual} from 'lodash';
import {default as React, useContext, useEffect, useState} from 'react';

import {Label} from '../../../components/Label';
import {Toggle} from '../../../components/Toggle/Toggle';
import {
    CompoundConditions,
    BlockQuestion,
    ConditionalQuestion,
    QuestionType,
    isQuestionWithAnswer
} from '../../model';
import { SurveyContext, SurveyContextType } from '../../SurveyCreator';
import { useVariableResolver } from '../variables/variableResolver/useVariableResolver';

import { CompoundConditionsSelector } from './CompoundConditions';
import styles from './ConditionalQuestionForm.pcss';

const initialCompoundConditions = {
    connective: 'AND' as const,
    args: [{ connective: 'AND' as const, args: [{ variableId: null, operator: null, operand: null }] }],
};

export const ConditionalQuestionForm = ({question, onChange}: { question: BlockQuestion, onChange: (question: BlockQuestion) => void }) => {
    const [messageErrors, setMessageErrors] = useState(null);

    const { variables, questions: { withError } } = useContext<SurveyContextType>(SurveyContext);

    const { resolvedVariablesTypes } = useVariableResolver();

    const handleToggle = () => {
        const propsToPreserve: (keyof BlockQuestion)[] = ['id', 'name', 'isDisabled'];
        if (question.type !== QuestionType.CONDITIONAL) {
            onChange({
                ...(_.pick(question, propsToPreserve) as BlockQuestion),
                type: QuestionType.CONDITIONAL,
                conditions: initialCompoundConditions,
                question: {
                    ...(_.omit(question, 'name') as BlockQuestion)
                }
            });
        } else {
            onChange({
                ...(question as ConditionalQuestion).question,
                ...(_.pick(question, propsToPreserve) as BlockQuestion)
            });
        }
    };

    const isConditional = question.type === QuestionType.CONDITIONAL;

    const onConditionsChange = (conditions: CompoundConditions) => onChange({
        ...question,
        conditions,
    } as BlockQuestion)

    const getConditionalQuestionSaveToVariableId = (condQuestion: ConditionalQuestion) => {
        if (isQuestionWithAnswer(condQuestion.question)) {
            return condQuestion.question.saveTo;
        }
        return undefined;
    };

    useEffect(() => {
        const errors = withError(question.id, 'conditions');
        if (!isEqual(errors, messageErrors)) {
            setMessageErrors(errors);
        }
    }, [withError]);

    return (
        <div className={styles.container}>
            <div data-test={'conditional-toggle'} className={styles.toggleContainer}>
                <Toggle on={isConditional} onToggle={handleToggle} />
                <Label labelId={'survey-creator.conditional.toggleLabel'}/>
            </div>
            {isConditional && (
              <CompoundConditionsSelector
                conditions={(question as ConditionalQuestion).conditions}
                onConditionsChange={onConditionsChange}
                questionSaveToVariableId={getConditionalQuestionSaveToVariableId(question as ConditionalQuestion)}
                variables={variables.get()}
                variablesForCreator={variables.getVariablesForCreator()}
                resolvedVariablesTypes={resolvedVariablesTypes}
                errors={messageErrors}
              />
            )}
        </div>
    );
};

