import * as React from 'react';

import {ElementWithLabel} from '../../components/ElementWithLabel/ElementWithLabel';
import {NumberInputWithStepper} from '../../components/NumberInputWithStepper/NumberInputWithStepper';

import styles from './ExpectedAnswersCount.pcss';
import {StepElementProps} from './StepElementProps';
import {ToggleWithLabelAndElement} from './ToggleWithLabelAndElement';

type AnswersCount = { minAnswers?: number, maxAnswers?: number };

export const ExpectedAnswersCount = ({onChange, question}: StepElementProps<AnswersCount>) => {
  const setMaxAnswersCount = (value: number) => {
    onChange({
      ...question,
      maxAnswers: value,
      minAnswers: question.minAnswers === undefined ? 1 : question.minAnswers
    });
  };

  const setMinAnswersCount = (value: number) => {
    onChange({
      ...question,
      maxAnswers: question.maxAnswers,
      minAnswers: value,
    });
  };
  const isEnabled = question.maxAnswers !== undefined || question.minAnswers !== undefined;
  const toggleAnswers = () => {
    if (isEnabled) {
      onChange({
        ...question,
        maxAnswers: undefined,
        minAnswers: undefined
      });
    } else {
      onChange({
        ...question,
        maxAnswers: 1,
        minAnswers: 1
      });
    }
  };
  const minAnswers = question.minAnswers || 1;
  const maxAnswers = question.maxAnswers || minAnswers;
  return (
    <ToggleWithLabelAndElement value={isEnabled} onToggle={toggleAnswers} labelId={'survey-creator.wordQuestionAnswersCount'} dataTest={'answers-count-toggle'}>
      {isEnabled && (
        <div className={styles.counts}>
          <ElementWithLabel labelId={'survey-creator.answersCount.min'} className={styles.item}>
            <NumberInputWithStepper dataTest={'min-answers-count'} minValue={1} value={minAnswers} onChange={setMinAnswersCount} />
          </ElementWithLabel>
          <ElementWithLabel labelId={'survey-creator.answersCount.max'} className={styles.item}>
            <NumberInputWithStepper dataTest={'max-answers-count'} minValue={1} value={maxAnswers} onChange={setMaxAnswersCount} />
          </ElementWithLabel>
        </div>
      )}
    </ToggleWithLabelAndElement>
  );
};
