import { isString } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router';

import { ConfirmationModal } from '../components/ConfirmationModal/ConfirmationModal';
import { Header } from '../layout/Header';
import { MainPage } from '../layout/MainPage';
import { Synonym } from '../surveyCreator/model';
import { HttpClient } from '../utils/HttpClient';

import styles from './GlobalWord.pcss';

type Data = { word: string, synonyms: Synonym[] };
type ResponseData = { word: string, synonyms: (string | Synonym)[] };
type MatchParams = { word: string, language: string };

type ConfirmationModalData = {
    opened: boolean,
    message?: () => JSX.Element,
    onConfirm?: () => void
};

const confirmationMessageComponent = (text: string) => () => <div>{text}</div>;

export const GlobalWord = () => {
    const intl = useIntl();
    const [data, setData] = useState<Data>();
    const [confirmationModal, setConfirmationModal] = useState<ConfirmationModalData>({ opened: false });
    const match = useRouteMatch<MatchParams>();
    const history = useHistory();

    const fetchGlobalWord = () =>
        HttpClient.get({ path: `/admin/global-synonyms/${match.params.language}/${match.params.word}` })
            .then((response: { data: ResponseData }) => {
                setData({
                    ...response.data,
                    synonyms: response.data.synonyms.map(synonym => isString(synonym) ? { verbatim: synonym } : synonym)
                });
                setConfirmationModal({ opened: false });
            });

    useEffect(() => {
        fetchGlobalWord();
    }, [match.params.language, match.params.word]);



    const confirmSynonymRemoval = (synonym: Synonym) => () => {
        HttpClient.delete({
            path: `/admin/global-synonyms/${match.params.language}/${match.params.word}/synonyms/${synonym.verbatim}`
        })
            .then(() => {
                setConfirmationModal({ opened: false });
                setData((previousData) => ({
                    ...previousData,
                    synonyms: previousData.synonyms.filter(el => el.verbatim !== synonym.verbatim)
                }));
            });
    };

    const confirmWordRemoval = () => {
        HttpClient.delete({ path: `/admin/global-synonyms/${match.params.language}/${match.params.word}` })
            .then(() => {
                history.push(`learning`);
            });
    };

    const handleRemoveSynonym = (synonym: Synonym) => {
        setConfirmationModal({
            opened: true,
            message: confirmationMessageComponent(intl.messages['learning.globalWord.removeSynonymText'] as string),
            onConfirm: confirmSynonymRemoval(synonym)
        });
    };

    const handleRemoveWord = () => {
        setConfirmationModal({
            opened: true,
            message: confirmationMessageComponent(intl.messages['learning.globalWord.removeEntityText'] as string),
            onConfirm: confirmWordRemoval
        });
    };

    const cancelRemoval = () => {
        setConfirmationModal({ opened: false });
    };
    return (
        <MainPage>
            <Header />
            {
                data && (
                    <div className={styles.container}>
                        <div className={styles.header}><FormattedMessage id={'learning.globalWord.keyHeader'} /></div>
                        <div className={styles.header}><FormattedMessage id={'learning.globalWord.synonymsHeader'} /></div>
                        <div className={styles.word} data-test={'global-word'}>
                            <div className={styles.wordName} data-test="global-word-name">{data.word}</div>
                            <button className={styles.removeButton} data-test="global-word-remove" onClick={handleRemoveWord} />
                        </div>

                        <div className={styles.synonyms}>
                            {data.synonyms.map(synonym => (
                                <div className={styles.synonym} key={synonym.verbatim} data-test={`global-word-synonym-${synonym.verbatim}`}>
                                    <div data-test={'global-word-synonym-text'}>{synonym.verbatim}</div>
                                    <button className={styles.removeButton} data-test={'remove-synonym'} onClick={() => handleRemoveSynonym(synonym)} />
                                </div>
                            ))}
                        </div>
                    </div>
                )
            }
            {confirmationModal.opened && (
                <ConfirmationModal onConfirm={confirmationModal.onConfirm} onCancel={cancelRemoval} renderText={confirmationModal.message} />
            )}
        </MainPage>

    );
};
