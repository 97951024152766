import React from 'react';
import { FormattedMessage } from 'react-intl';

import { NumberInputWithStepper } from '../../components/NumberInputWithStepper/NumberInputWithStepper';
import { FollowUpInGivenHoursScheduleStep, StepConfig } from '../schedule-model';

import styles from './FollowUpInGivenHoursComponent.pcss';

type FollowUpInGivenHoursProps = {
    step: FollowUpInGivenHoursScheduleStep<StepConfig>;
    onStepChange: (step: FollowUpInGivenHoursScheduleStep<StepConfig>) => void;
};

export const FollowUpInGivenHours: React.FC<FollowUpInGivenHoursProps> = ({
    step,
    onStepChange
}) => {

    return (
    <div className={styles.steppers}>
        <NumberInputWithStepper data-test={'step-hour-stepper'}
            step={1}
            minValue={0}
            maxValue={23}
            value={step.hours}
            onChange={hours => onStepChange({ ...step, hours, minutes: step.minutes || 0 })} />
        <FormattedMessage tagName="label" id={'scheduler.step.numberOfHoursLabel'} />

        <NumberInputWithStepper data-test={'step-minute-stepper'}
            step={5}
            minValue={0}
            maxValue={55}
            value={step.minutes}
            onChange={minutes => onStepChange({ ...step, minutes, hours: step.hours || 0 })} />
        <FormattedMessage tagName="label" id={'scheduler.step.numberOfMinutesLabel'} />
    </div>
    );
}