import React, {ComponentProps, useCallback} from 'react';
import { ModalBody } from './ModalBody';

import styles from './ModalContent.pcss';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';
import { ModalSeparator } from './ModalSeparator';
import {useShortcut} from '../../surveyCreator/components/useShortcut';

type ModalContentProps = {
  headerContent: React.ReactNode;
  subheaderContent?: string;
  footerContent?: React.ReactNode;
  footerButtons?: {
    label: string;
    onClick: () => void;
  }[];
  displayTopSeparator?: boolean;
  displayBottomSeparator?: boolean;
  onClose?: ComponentProps<typeof ModalHeader>['onClose'];
  closeButtonDataTest?: string;
  withScrollBarMargin?: boolean;
};

export const ModalContent: React.FC<ModalContentProps> = ({
  children,
  headerContent,
  subheaderContent,
  footerContent,
  displayBottomSeparator = true,
  displayTopSeparator = true,
  onClose,
  closeButtonDataTest,
    withScrollBarMargin
}) => {

    const closeModal = useCallback(() => {
            if (onClose) {
                onClose(null);
            }
        }, [onClose]);

    useShortcut({keys: ['escape']}, () => {
        closeModal();
    });

    const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    }, [onClose]);

    return (
        <div className={styles.modalContent} onClick={handleClick}>
            <ModalHeader
                subheader={subheaderContent}
                onClose={onClose}
                closeButtonDataTest={closeButtonDataTest}
            >
                {headerContent}
            </ModalHeader>
            {displayTopSeparator && <ModalSeparator/>}
            <ModalBody withScrollBarMargin={withScrollBarMargin}>
                {children}
            </ModalBody>
            {displayBottomSeparator && <ModalSeparator/>}
            {footerContent &&
                <ModalFooter>
                    {footerContent}
                </ModalFooter>
            }
        </div>
    );
};