import cx from 'classnames';
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { AddButton } from '../../../components/AddButton/AddButton'
import { Box } from '../../../components/Box/Box'
import { Row } from '../../../components/Row/Row'

import styles from './AddItemBar.pcss'

export type BarVariant = 'normal' | 'step';

export type AddItemBarProps = {
  addButtonLabelId: string;
  descriptionId: string;
  onClick: React.MouseEventHandler;
  dataTest?: string;
  className?: string;
  barVariant?: BarVariant;
}

export const AddItemBar: React.FC<AddItemBarProps> = ({
  addButtonLabelId,
  descriptionId,
  onClick,
  dataTest,
  className,
  barVariant= 'normal'
}) => <Box
  border
  className={cx({[styles.step]: barVariant === 'step'}, className)}
>
    <Row className={styles.row}>
      <AddButton
        dataTest={dataTest}
        textId={addButtonLabelId}
        onClick={onClick}
      />
      <div className={styles.description}>
        <FormattedMessage id={descriptionId} values={{ linebreak: <br /> }} />
      </div>
    </Row>
  </Box>