import React from 'react';

import { IconProps } from './iconTypes'

export const UserIcon: React.FC<IconProps> = ({ animationClass }) => (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
        <g>
            <g transform="translate(-1326.000000, -16.000000) translate(1326.000000, 16.000000)">
                <path className={animationClass} fill="#000" fillRule="nonzero" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 18.683c-2.227 0-4.372.803-6.047 2.247C7.678 22.1 9.76 22.785 12 22.785c2.24 0 4.322-.684 6.047-1.853-1.675-1.446-3.82-2.249-6.047-2.249zm0-17.468C6.044 1.215 1.215 6.044 1.215 12c0 3.27 1.456 6.2 3.754 8.178 1.922-1.74 4.426-2.71 7.031-2.71 2.605 0 5.11.97 7.03 2.71 2.3-1.978 3.755-4.908 3.755-8.178 0-5.956-4.829-10.785-10.785-10.785zm0 2.582c3.272 0 5.924 2.653 5.924 5.925 0 3.271-2.652 5.924-5.924 5.924-3.272 0-5.924-2.653-5.924-5.924 0-3.272 2.652-5.925 5.924-5.925zm0 1.216c-2.6 0-4.709 2.108-4.709 4.709 0 2.6 2.108 4.708 4.709 4.708 2.6 0 4.709-2.108 4.709-4.708 0-2.601-2.108-4.71-4.709-4.71z"/>
            </g>
        </g>
    </g>
</svg>
);