import React from 'react';

import {IconProps} from './iconTypes';

export function CheckMarkIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
            <path fill="#3C38BC" d="M3.197 7.86L1.985 6.245c-.238-.317-.688-.382-1.006-.144-.317.238-.381.688-.143 1.006l1.225 1.632c.272.365.704.577 1.16.57.457-.008.882-.234 1.142-.61l4.815-6.876c.227-.325.148-.772-.177-1-.324-.227-.772-.148-1 .177L3.198 7.86z"/>
        </svg>
    );
}
