import * as React from 'react';
import {CSSProperties, useRef} from 'react';
import {FormattedMessage} from 'react-intl';

import {SelectComponent} from '../../../../components/Select/Select';
import {useOutsideClick} from '../../useOutsideClick';

import {
    availableOptions,
    ConditionalMessageProps,
    ConditionalMessageSource,
    handleConditionalVariableChange,
    MultipleConditionalMessageProps
} from './conditional-messages-utils';
import styles from './ConditionalMessageVariableSelect.pcss';

type Props = {
    availableConditionalSources: ConditionalMessageSource[],
    variable?: { id: string, key: string },
    onChange: (variable: { id: string, key: string }) => void,
};
export const ConditionalMessageVariableSelect = (props: Props) => {
    return (
        <div data-test="conditional-message-variable"
             className={styles.variableSelect}>
            <SelectComponent
                options={availableOptions(props.availableConditionalSources)}
                value={!props.variable ? undefined : {
                    id: props.variable.id,
                    name: props.variable.key
                }}
                onChange={(value) => props.onChange(value ? {id: value.id, key: value.name} : undefined)}
                isClearable
                isSearchable
            />
        </div>
    );
};

type ConditionalMessageVariableSelectorProps = (MultipleConditionalMessageProps | ConditionalMessageProps) & {
    outsideClickCallback: () => void;
    valueMapper?: (value: any) => any;
    positionStyle: CSSProperties;
}

export const ConditionalMessageVariableSelector = (props: ConditionalMessageVariableSelectorProps) => {
    const selectorContainer = useRef(null);
    const outsideClick = useOutsideClick(selectorContainer, props.outsideClickCallback);
    outsideClick.addHandler();
    const source = props.availableConditionalSources.find(entry => entry.variable.id === props.message.name);
    return (
        <>
            {
                <div className={styles.selectorContainer} ref={selectorContainer} style={props.positionStyle}>
                    <div className={styles.selectorLabel}><FormattedMessage
                        id={'survey-creator.conditional.messageVariableSelectLabel'}/></div>
                    <div>
                        <ConditionalMessageVariableSelect
                            availableConditionalSources={props.availableConditionalSources}
                            variable={source?.variable ? {
                                id: source.variable.id,
                                key: source.variable.key
                            } : undefined}
                            onChange={handleConditionalVariableChange(props, props.valueMapper)}
                        />
                    </div>
                </div>
            }
        </>
    );
}
