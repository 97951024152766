import React from 'react';

import { LocalIntentsContainerProps } from '../types';

import { useLocalIntentsProps } from './LocalIntents.hooks';
import { LocalIntentsComponent } from './LocalIntentsComponent';

export const LocalIntentsContainer: React.FC<LocalIntentsContainerProps> = (props) => {
  const localIntentsProps = useLocalIntentsProps(props)

  return <LocalIntentsComponent 
  {...localIntentsProps}
  possibleQuestions={props.question.possibleQuestions || []}
  />
}