import {FormattedMessage, useIntl} from 'react-intl';
import styles from './ImportVisitsAsContactsModal.pcss';
import {default as React} from 'react';
import {SupportedDictionary} from '../model';
import cx from 'classnames';
import moment from 'moment';
import {Label} from '../../components/Label';
import {TimeInputWithStepper} from '../../components/TimeInputWithStepper/TimeInputWithStepper';
import {SelectWithLabel} from '../../components/SelectWithLabel/SelectWithLabel';
import {ElementWithLabel} from '../../components/ElementWithLabel/ElementWithLabel';
import {MultiSelectComponent} from '../../surveyCreator/components/MultiSelect';
import {Button} from '../../components/Button/Button';
import { DatePicker } from '../../components/Dates/DatePicker/DatePicker';

const getConditionsOptionName = (dict: SupportedDictionary): string => {
  const intl = useIntl();
  return intl.messages[
    `contacts.add.integrationModal.step1.conditionOption.${dict}`
    ] as string;
};

export const VisitsFilteringStage = ({
  integration,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  timeFrom,
  setTimeFrom,
  timeTo,
  setTimeTo,
  conditions,
  setConditions,
  doctors,
  clinics,
  practices,
  services,
  timezone,
}: {
  integration: {
    name: string;
    system: string;
    supportedDictionaries: SupportedDictionary[];
  };
  startDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<number>>;
  endDate: string;
  setEndDate: React.Dispatch<React.SetStateAction<number>>;
  timeFrom: number;
  setTimeFrom: React.Dispatch<React.SetStateAction<number>>;
  timeTo: number;
  setTimeTo: React.Dispatch<React.SetStateAction<number>>;
  conditions: { option: { name: string; id: string; } | null; values: { id: string; name: string; }[] }[];
  setConditions: React.Dispatch<React.SetStateAction<{ option: { name: string; id: string; } | null; values: { id: string; name: string; }[] }[]>>;
  doctors: { name: string; id: string; }[];
  clinics: { name: string; id: string; }[];
  practices: { name: string; id: string; }[];
  services: { name: string; id: string; }[];
  timezone?: string;
}) => {
  const availableConditionValues = {
    DOCTORS: doctors,
    CLINICS: clinics,
    PRACTICES: practices,
    SERVICES: services,
  };

  const handleDateChange = (start: string, end: string) => {
    setStartDate(start ? moment.tz(start, timezone).startOf('day').valueOf() : undefined);
    setEndDate(end ? moment.tz(end, timezone).endOf('day').valueOf() : undefined);
  };

  return (
    <div className={styles.mainContainer}>
      <span className={cx(styles.text, styles.secondary)}>
        <FormattedMessage id="contacts.add.integrationModal.step1.instruction" />
      </span>

      <div className={styles.row}>
        <div className={styles.field} data-test="import-visits-as-contacts-modal-date-picker">
              <span className={cx(styles.text, styles.secondary)}>
                <FormattedMessage id="contacts.add.integrationModal.step1.date.label" />
              </span>
          <DatePicker
            startDate={startDate ? moment.tz(startDate, timezone).format('YYYY-MM-DD') : undefined}
            endDate={endDate ? moment.tz(endDate, timezone).format('YYYY-MM-DD') : undefined}
            onChange={handleDateChange}
            allowFutureDates
          />
        </div>

        <div className={styles.field}>
              <span className={cx(styles.text, styles.secondary)}>
                <FormattedMessage id="contacts.add.integrationModal.step1.time.label" />
              </span>

          <div className={cx(styles.field, styles.smallGap)}>
            <Label labelId="contacts.add.integrationModal.step1.time.labelFrom" />
            <TimeInputWithStepper
              dataTest="import-visits-as-contacts-modal-time-from-input"
              step={10}
              minValue={0} // 0 minutes (time 00:00)
              maxValue={1439} // 1439 minutes (time 23:59)
              value={timeFrom}
              onChange={setTimeFrom}
            />
          </div>

          <div className={cx(styles.field, styles.smallGap)}>
            <Label labelId="contacts.add.integrationModal.step1.time.labelTo" />
            <TimeInputWithStepper
              dataTest="import-visits-as-contacts-modal-time-to-input"
              step={10}
              minValue={0} // 0 minutes (time 00:00)
              maxValue={1439} // 1439 minutes (time 23:59)
              value={timeTo}
              onChange={setTimeTo}
            />
          </div>
        </div>
      </div>

      {conditions.map((condition, index) => (
        <div
          key={index}
          className={styles.row}
          data-test="condition-fields-row"
        >
          <SelectWithLabel
            className={styles.select}
            labelId="contacts.add.integrationModal.step1.condition.label"
            dataTest={`condition-select-input-${index}`}
            options={integration.supportedDictionaries.map((dict) => ({
              id: dict,
              name: getConditionsOptionName(dict),
            }))}
            onChange={(option) =>
              setConditions((state) =>
                state.map((c, i) => {
                  if (i === index) {
                    return {
                      values: [],
                      option: { id: option.id, name: option.name },
                    };
                  }
                  return c;
                })
              )
            }
            value={condition.option}
            noPadding
          />

          <ElementWithLabel
            noPadding
            labelId="contacts.add.integrationModal.step1.conditionValue.label"
            dataTest={`condition-value-multiselect-input-${index}`}
          >
            <MultiSelectComponent
              isDisabled={!condition.option}
              value={condition.values}
              options={availableConditionValues[condition.option?.id] || []}
              onChange={(value) => {
                setConditions((state) =>
                  state.map((c, i) => {
                    if (i === index) {
                      return {
                        ...c,
                        values: value,
                      };
                    }
                    return c;
                  })
                );
              }}
            />
          </ElementWithLabel>
        </div>
      ))}

      <Button
        buttonType="normal"
        className={styles.addCondition}
        dataTest="import-visits-as-contacts-modal-add-condition-button"
        translateText="contacts.add.integrationModal.step1.addCondition"
        onClick={() => {
          setConditions((state) => [
            ...state,
            { option: null, values: [] },
          ]);
        }}
      />
    </div>
  );
};
