import * as React from 'react';
import {useIntl} from 'react-intl';

import {NumberInputWithStepper} from '../../../components/NumberInputWithStepper/NumberInputWithStepper';
import {RefreshIcon} from '../../../icons/RefreshIcon';
import {QuestionWithAnswer} from '../../model';
import {StepElementProps} from '../StepElementProps';
import {Button} from '../../../components/Button/Button';
import {Label} from '../../../components/Label';

import styles from './PostSpeechTimeoutField.pcss';

const [DEFAULT_TIMEOUT, MIN_TIMEOUT, MAX_TIMEOUT, STEP] = [1500, 500, 3000, 100];

export const PostSpeechTimeoutField = ({onChange, question}: StepElementProps<QuestionWithAnswer>) => {
  const intl = useIntl();
  const setPostSpeechSilenceTimeout = (value: number) => {
    onChange({...question, postSpeechSilenceTimeout: value});
  }

  const postSpeechSilenceTimeout = question.postSpeechSilenceTimeout || DEFAULT_TIMEOUT;

  return (
    <div className={styles.container} data-test={'post-speech-silence-timeout'}>
      <div className={styles.label}>
        <Label labelId={'survey-creator.changePostSpeechTimeout'} />
      </div>

      <div className={styles.content}>
        <NumberInputWithStepper
          dataTest={'post-speech-silence-timeout-value'}
          step={STEP}
          minValue={MIN_TIMEOUT}
          maxValue={MAX_TIMEOUT}
          value={postSpeechSilenceTimeout}
          seconds
          onChange={setPostSpeechSilenceTimeout}
          triggerOnChangeOnMount={false}
        />

        <Button
          Icon={RefreshIcon}
          buttonType="link"
          disabled={postSpeechSilenceTimeout === DEFAULT_TIMEOUT}
          onClick={() => setPostSpeechSilenceTimeout(DEFAULT_TIMEOUT)} dataTest={'post-speech-silence-timeout-reset'}
        >
          {intl.messages['survey-creator.resetPostSpeechTimeout']}
        </Button>
      </div>
    </div>
  );
};
