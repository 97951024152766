import React from 'react';

import { AnswersList } from '../../../../components/AnswersList/AnswersList';
import { AddItemBar } from '../../AddItemBar/AddItemBar';
import { AddItemModal } from '../../AddItemModal/AddItemModal';
import { SpecialCharactersComponentProps } from '../types';

import styles from './SpecialCharacters.pcss';
import { SpecialCharactersAnswerComponent } from './SpecialCharactersAnswerComponent';

export const SpecialCharactersComponent: React.FC<SpecialCharactersComponentProps> = ({
  items,
  onConfirm,

  answers,
  onChange,
  globalWords
}) => <>
    <AddItemModal
      dataTest="special-characters-modal"
      placeholderLabelId="survey-creator.specialCharacters.addNewSpecialCharacter.placeholder"
      items={items}
      onSelect={onConfirm}
      renderTrigger={({ open }) => <AddItemBar
        dataTest="add-special-character"
        addButtonLabelId="survey-creator.specialCharacters.addNewSpecialCharacter.buttonLabel"
        descriptionId="survey-creator.specialCharacters.addNewSpecialCharacter.description"
        barVariant="step"
        onClick={open}
      />}
      forbidAddingItems
    />
    <AnswersList
      className={styles.answersList}
      answers={answers}
      onChange={onChange}
      globalWords={globalWords}
      AnswerComponent={SpecialCharactersAnswerComponent}
    />
  </>