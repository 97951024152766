import { RawEntity } from '../../model';
import {HttpClient} from '../../../utils/HttpClient';

class EntitiesHttpClient {
    botId: string;

    constructor(botId: string) {
        this.botId = botId;
    }

    async searchEntities(names: string[]): Promise<RawEntity[]> {
        const response = await HttpClient.post({
            path: `/bots/${this.botId}/entities/search`,
            body: { entities: names },
        });
        return response.data.map(entity => ({
            ...entity,
            values: entity.values.map(entityValue => ({
                ...entityValue,
                synonyms: entityValue.synonyms.map(valueSynonym => {
                    if (typeof valueSynonym === 'string') {
                        return { verbatim: valueSynonym };
                    }
                    return valueSynonym;
                })
            }))
        }));
    }
}

export default EntitiesHttpClient;
