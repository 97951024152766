import cx from 'classnames';
import React from 'react'
import { useIntl } from 'react-intl'

import { EditButtons } from '../../../surveyCreator/components/EditButtons'
import { useUnsavedChanges } from '../../../surveyCreator/components/useUnsavedChanges'
import { Box } from '../../Box/Box'
import { Loader } from '../../Loader'
import { Tab, TabsLayoutProps } from '../types'

import styles from './SavableLayout.pcss'
import { SavableLayoutTabs } from './SavableLayoutTabs'

type SavableLayoutClasses = Partial<{
  root: string;
  childrenContainer: string;
}>;

export type SavableLayoutProps = {
  hasChanges?: boolean;
  isLoading?: boolean;
  onSave: () => void;
  onCancel: () => void;
  noChildrenPadding?: boolean;
  dataTest?: string;
  classes?: SavableLayoutClasses;
} & Partial<TabsLayoutProps>;

export const SavableLayout: React.FC<SavableLayoutProps> = ({
  hasChanges,
  isLoading,
  onSave,
  onCancel,
  dataTest,
  classes,

  tabs,
  currentTab,
  onTabClick,
  noChildrenPadding = false,

  children
}) => {
  const intl = useIntl();
  const {
    containerRef,
    showModal,
    renderUnsavedChangesComponents
  } = useUnsavedChanges(hasChanges, intl.messages['unsavedChangesModal.text'] as string);

  return <div
    ref={containerRef}
    data-test={dataTest}
    className={cx(styles.savableLayout, classes?.root)}
  >
    <Box border className={styles.buttonsBox}>
      {
        Boolean(tabs) &&
        <SavableLayoutTabs
          tabs={tabs}
          currentTab={currentTab}
          onTabClick={onTabClick}
        />
      }
      <div className={styles.buttonsBar}>
        <EditButtons
          onClose={showModal}
          onSave={onSave}
          disabled={!hasChanges}
        />
      </div>
    </Box>
    <div className={cx(
      styles.childrenContainer,
      classes?.childrenContainer,
      { [styles.noPadding]: noChildrenPadding }
    )}>
      {
        isLoading
          ? <Box border className={styles.loaderBox}><Loader /></Box>
          : children
      }
    </div>
    {renderUnsavedChangesComponents(onCancel)}
  </div >
};