import classNames from 'classnames';
import _ from 'lodash';
import * as React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import {addNamesDictionaryAction, deleteNamesDictionaryAction} from '../actions/namesDictionaryActions';
import {DeleteIcon} from '../icons';
import {useCurrentBot} from '../utils/CurrentBot';
import {NamesDictionaryItemCategory} from '../utils/NamesDictionary';

import styles from './learning.pcss';
import {useState} from 'react';
import {getNamesDictionary} from '../selectors';
import ReactTable, {Column, RowInfo} from 'react-table';

const NamesDictionaryComponent = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const currentBot = useCurrentBot();
    const namesDictionary = useSelector(getNamesDictionary);

    const [key, setKey] = useState('');
    const [label, setLabel] = useState('');
    const [category, setCategory] = useState<NamesDictionaryItemCategory>('results');

    const columns: Column[] = [
        {
            Header: <FormattedMessage id={'names-dictionary.table.key'} />,
            Cell: (row: RowInfo) => (
              <div>
                  <div data-test="names-dictionary-key">
                      {row.original.key}
                      <span
                        data-test={`names-dictionary-remove-button-${row.original.key}`}
                        onClick={() => dispatch(deleteNamesDictionaryAction.request({ botId: currentBot.id, key: row.original.key }))}
                      >
                        <DeleteIcon fill={'#000'} />
                    </span>
                  </div>
              </div>
            ),
            accessor: 'key',
            maxWidth: 400
        },
        {
            Header: <FormattedMessage id={'names-dictionary.table.values'} />,
            Cell: (row: RowInfo) => (
              <div>
                  <div data-test="names-dictionary-label">{row.original.value.label}</div>
                  <div data-test="names-dictionary-category">{row.original.value.category}</div>
              </div>
            ),
            id: 'value'
        }
    ];

    const namesDictionaryItems = _.keys(namesDictionary).map((dictionaryKey: string) => ({
        key: dictionaryKey,
        value: namesDictionary[dictionaryKey]
    }));

    const handleAddNewNameDictionaryItem = () => {
        dispatch(addNamesDictionaryAction.request({
            botId: currentBot.id,
            key,
            namesDictionaryItem: { label, category },
        }));
        setKey('');
        setLabel('');
        setCategory('results');
    };

    return (
        <div className={classNames(styles.learning)}>
            <div>
                <FormattedMessage id={'names-dictionary.input.key'} />
                <input
                    data-test="names-dictionary-key-input"
                    value={key}
                    onChange={event => setKey(event.target.value)}
                />
                <FormattedMessage id={'names-dictionary.input.label'} />
                <input
                    data-test="names-dictionary-label-input"
                    value={label}
                    onChange={event => setLabel(event.target.value)}
                />
                <FormattedMessage id={'names-dictionary.input.category'} />
                <input
                    data-test="names-dictionary-category-input"
                    value={category}
                    onChange={event => setCategory(event.target.value as NamesDictionaryItemCategory)}
                />
                <button data-test="names-dictionary-save-button" onClick={handleAddNewNameDictionaryItem}>
                    <FormattedMessage id={'names-dictionary.add.button'} />
                </button>
            </div>

            <ReactTable
                data={namesDictionaryItems}
                columns={columns}
                minRows={1}
                pageSize={100}
                showPagination={namesDictionaryItems.length >= 100}
                noDataText=""
                pageText={intl.messages['table.page']}
                ofText={intl.messages['table.of']}
                rowsText={intl.messages['table.rows'] as string}
            />
        </div>
    );
}

export default NamesDictionaryComponent;
