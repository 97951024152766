import React from 'react';

import {IconProps} from './iconTypes';

export function HelpStepIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H24V24H0z"/>
                <path className={props.animationClass} d="M12.749 0l.303.005C17.697.12 21.805 3.09 23.36 7.492c1.587 4.497.162 9.506-3.556 12.493-3.548 2.851-8.446 3.266-12.406 1.107l-.271-.153-6.256 2.98c-.487.232-.995-.235-.843-.723l.034-.086 2.98-6.257-.187-.331C1.11 13.276 1.07 9.368 2.765 6.079l.17-.316C4.917 2.2 8.675-.005 12.749 0zm.002 1.215C9.114 1.21 5.763 3.177 3.996 6.353c-1.768 3.176-1.673 7.06.248 10.147.11.175.121.395.032.581l-2.384 5.006 5.006-2.384c.156-.074.334-.078.49-.014l.092.047c3.611 2.246 8.248 1.966 11.563-.698 3.315-2.663 4.587-7.13 3.171-11.141-1.416-4.01-5.21-6.69-9.463-6.682zm-.001 13.203h.033l.029.003.053.003c.49.057.871.474.871.98 0 .545-.442.986-.986.986-.545 0-.987-.441-.987-.986 0-.506.381-.923.872-.98l.053-.003.029-.002.033-.001zm0-8.347c2.011 0 3.642 1.63 3.642 3.642s-1.63 3.642-3.642 3.642c-.336 0-.607-.271-.607-.607 0-.335.271-.607.607-.607 1.34 0 2.428-1.087 2.428-2.428 0-1.34-1.087-2.428-2.428-2.428s-2.428 1.087-2.428 2.428c0 .336-.272.607-.607.607-.336 0-.607-.271-.607-.607 0-2.011 1.63-3.642 3.642-3.642z"/>
            </g>
        </svg>
    );
}
