export const nerEntities = {
  pl: [
    'district',
    'country',
    'person',
    'date',
    'building_number',
    'street',
    'time',
    'town',
    'company',
    'POI',
  ],
  en: ['organization', 'person', 'POI', 'location', 'gpe'],
};
