import _ from 'lodash';
import * as React from 'react';

import {GroupIcon} from '../../../icons/GroupIcon';
import {AddItemModal} from '../AddItemModal/AddItemModal';

import {MenuOption} from './OptionsMenu';

type GroupOptionProps = {
  onAssignGroup: (group: string) => void,
  items: string[]
}

export const GroupOption: React.FC<GroupOptionProps> = ({onAssignGroup, items}) => {
    return (
        <>
            <AddItemModal
                addLabelId={'survey-creator.addNewGroup'}
                placeholderLabelId={'survey-creator.assignToGroupPlaceholder'}
                onCreate={(group) => onAssignGroup(_.trim(group))}
                onSelect={(group) => onAssignGroup(_.trim(group))}
                dataTest={'assign-group'}
                items={items.map(item => ({key: item, name: `#${item}`}))}
                newItemFormatter={(text) => `#${text}`}
                inputPrefix={'#'}
                renderTrigger={({open}) => (
                        <MenuOption dataTest="group-option" onClick={open} icon={GroupIcon} hoverType={'regular'}
                        tooltipLabelId="menuOption.group.tooltip"/>)}
            />
        </>
  );
};
