import React from 'react';
import { IconProps } from '../iconTypes';

export function OutboundCall(props: IconProps) {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#vfkvmd2yya)" fill="#4A4A4A">
      <path d="M10.92 10.74a1.4 1.4 0 0 0-1.98 0 .6.6 0 0 1-.847 0L5.267 7.913a.6.6 0 0 1 0-.846 1.4 1.4 0 0 0 0-1.98L3.5 3.32a1.4 1.4 0 0 0-1.98 0l-.42.42C-.04 4.867-.233 6.607.64 7.98a27.372 27.372 0 0 0 7.38 7.38l.013.013c.567.36 1.2.54 1.834.54.873 0 1.74-.34 2.4-1l.42-.42c.266-.266.413-.613.413-.993s-.147-.727-.413-.993L10.92 10.74zm1.207 3.18-.42.42a2.587 2.587 0 0 1-3.22.367V14.7A26.632 26.632 0 0 1 1.32 7.54a2.584 2.584 0 0 1 .353-3.233l.42-.42a.594.594 0 0 1 .427-.174c.153 0 .307.06.427.174l1.766 1.766a.6.6 0 0 1 .174.427.6.6 0 0 1-.174.427 1.382 1.382 0 0 0 0 1.973l2.827 2.827a1.4 1.4 0 0 0 1.98 0 .6.6 0 0 1 .847 0l1.766 1.766a.6.6 0 0 1 .174.427.6.6 0 0 1-.174.427l-.006-.007zM15.867.347a.393.393 0 0 0-.214-.214A.395.395 0 0 0 15.5.1h-4c-.22 0-.4.18-.4.4 0 .22.18.4.4.4h3.033L8.72 6.72a.407.407 0 0 0 0 .567.384.384 0 0 0 .56 0l5.82-5.82V4.5c0 .22.18.4.4.4.22 0 .4-.18.4-.4v-4a.395.395 0 0 0-.034-.153z" />
    </g>
    <defs>
      <clipPath id="vfkvmd2yya">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>


}