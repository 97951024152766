export interface ReportFromAPI {
  id: string;
  callId: string;
  report: string;
  conversationStart: number;
  phoneNumber: string;
  status: ReportStatus;
  data: Record<string, string>[];
  createdAt: string;
  updatedAt: string;
}

export interface Report {
  callId: string;
  report: string;
  conversationStart: number;
  status: ReportStatus;
  data: Record<string, string[]>;
}

export enum ReportStatus {
  'DEFAULT' = 'DEFAULT',
  'COMPLETED' = 'COMPLETED',
}

export type ReportsResponse = {
  name: string; 
  uncompletedCount: number
  notificationEnabled: boolean;
}[]