import { useEffect, useState } from 'react';

import { Condition, CompoundConditions } from '../../model';

const newCondition: Condition = {
  variableId: null,
  operator: null,
  operand: null,
};

export const useCompoundConditions = (initConditions: CompoundConditions, onChange: (conditions: CompoundConditions) => void) => {
  const [conditions, setConditions] = useState<CompoundConditions>(initConditions);

  useEffect(() => {
    setConditions(initConditions);
  }, [initConditions]);

  useEffect(() => {
    onChange(conditions);
  }, [conditions]);

  const setRootConnective = (connective: 'AND' | 'OR') => {
    setConditions(state => ({ ...state, connective }));
  };

  const setGroupConnective = (connective: 'AND' | 'OR', groupIndex: number) => {
    setConditions(state => ({
      ...state,
      args: state.args.map((arg, index) => {
        if (groupIndex !== index) {
          return arg;
        }
        return {
          ...arg,
          connective,
        };
      }),
    }));
  };

  const addGroup = () => {
    setConditions(state => ({
      ...state,
      args: [...state.args, { connective: 'AND', args: [newCondition] }],
    }));
  };

  const addCondition = (groupIndex: number) => {
    setConditions(state => ({
      ...state,
      args: state.args.map((arg, index) => {
        if (index !== groupIndex) {
          return arg;
        }
        return {
          ...arg,
          args: [...arg.args, newCondition],
        };
      }),
    }));
  };

  const removeCondition = (groupIndex: number, conditionIndex: number) => {
    setConditions(state => ({
      ...state,
      args: state.args.map((arg, index) => {
        if (index !== groupIndex) {
          return arg;
        } else if (arg.args.length > 1) {
          return {
            ...arg,
            args: arg.args.filter((_, i) => i !== conditionIndex),
          }
        }
        return undefined;
      }).filter(arg => !!arg),
    }));
  };

  const updateCondition = (groupIndex: number, conditionIndex: number, condition: Condition) => {
    setConditions(state => ({
      ...state,
      args: state.args.map((arg, index) => {
        if (index !== groupIndex) {
          return arg;
        }
        return {
          ...arg,
          args: arg.args.map((a, i) => {
            if (i !== conditionIndex) {
              return a;
            }
            return condition;
          }),
        };
      }),
    }));
  };

  return {
    rootConnective: conditions.connective,
    groups: conditions.args,
    setRootConnective,
    setGroupConnective,
    addGroup,
    addCondition,
    removeCondition,
    updateCondition,
  };
}
