import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {modifiedEntityAnswersSelector, savedEntityAnswersSelector} from '../../../../selectors/entityDetailsSelectors';
import {useCurrentBot} from '../../../../utils/CurrentBot';
import {EntityAnswer} from '../../../model';
import {
  fetchEntityDetails,
  updateEntityDetails as updateEntityDetailsAction
} from '../../../store/entityDetails/actions';

import {useCancelChanges} from './useCancelChanges';
import {useSaveChanges} from './useSaveChanges';

export const useEntityDetails = (entity?: string) => {
  const currentBot = useCurrentBot();
  const dispatch = useDispatch();
  const savedEntityAnswers = useSelector(savedEntityAnswersSelector(entity))
  const modifiedEntityAnswers = useSelector(modifiedEntityAnswersSelector(entity))

  useEffect(() => {
    if (!entity) {
      return
    }
    dispatch(fetchEntityDetails.request({entity, id: currentBot.id}))
  }, [currentBot, entity]);

  const updateEntityDetails = (answers: EntityAnswer[]) => {
    dispatch(updateEntityDetailsAction({entity, answers}));
  }

  const hasChanges = Boolean(savedEntityAnswers) && !savedEntityAnswers?.isLoading && modifiedEntityAnswers?.dirty;

  const onCancel = useCancelChanges(entity);

  const onSave = useSaveChanges(entity, modifiedEntityAnswers?.answers);

  return {
    isLoading: savedEntityAnswers?.isLoading,
    isReady: savedEntityAnswers?.isReady,
    hasError: savedEntityAnswers?.hasError,
    hasChanges,
    entityAnswers: hasChanges ? modifiedEntityAnswers?.answers : savedEntityAnswers?.data,
    updateEntityDetails,
    onCancel,
    onSave
  }
}