import * as React from 'react';
import { useIntl } from 'react-intl';
import styles from './LoginDetailsAreIncorrectMessage.pcss';

export const LoginDetailsAreIncorrectMessage = () => {
  const intl = useIntl();
  return (
    <span>
      {intl.formatMessage(
        { id: 'auth.signIn.loginDetailsAreIncorrect' },
        {
          mail: (
            <a href="mailto:support@talkie.ai" className={styles.link}>
              {intl.formatMessage({
                id: 'auth.signIn.supportEmail',
              })}
            </a>
          ),
        }
      )}
    </span>
  );
};
