import * as React from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { QuestionWithAnswer } from '../../model';
import { StepElementProps } from '../StepElementProps';
import { SelectComponent, Value } from '../../../components/Select/Select';
import { ElementWithLabel } from '../../../components/ElementWithLabel/ElementWithLabel';
import { useCurrentBot } from '../../../utils/CurrentBot';
import { LanguageCode } from '../../../language/botLanguage';
import { useMemo } from 'react';
import { useRecognizers } from '../../useRecognizers';

const POLISH_LANGUAGE_CODES = ['pl-PL'];
const ENGLISH_LANGUAGE_CODES = ['en-US', 'en-GB', 'en-AU'];
const DEFAULT_RECOGNIZER_OPTION = (intl: IntlShape) => ({
  id: 'default',
  name: intl.formatMessage({ id: 'survey-creator.recognizer.default' })
});

export const RecognizerField = ({
  onChange,
  question,
}: StepElementProps<QuestionWithAnswer>) => {
  const intl = useIntl();
  const bot = useCurrentBot();
  const { recognizers } = useRecognizers();

  const shouldDisplayRecognizerSelect = [
    ...POLISH_LANGUAGE_CODES,
    ...ENGLISH_LANGUAGE_CODES,
  ].includes(bot.voiceConfig.languageCode);

  if (!shouldDisplayRecognizerSelect) {
    return null;
  }
  const defaultRecognizerOption = useMemo(() => DEFAULT_RECOGNIZER_OPTION(intl), [intl]);

  const options = [defaultRecognizerOption, ...(recognizers ?? []).map(
    ({ type, model }) => ({
      id: `${type}_${model}`,
      name: intl.formatMessage({ id: `survey-creator.recognizer.${type}_${model}` }),
    })
  )];

  const recognizersForPl = recognizers?.filter(recognizer => recognizer.languages.some(language => POLISH_LANGUAGE_CODES.includes(language)));
  const recognizersForEn = recognizers?.filter(recognizer => recognizer.languages.some(language => ENGLISH_LANGUAGE_CODES.includes(language)));

  function getOptionsForLanguageCode(languageCode: LanguageCode) {
    if (languageCode === 'pl-PL') {
      return [defaultRecognizerOption, ...options.filter((option) => recognizersForPl.some(recognizer => `${recognizer.type}_${recognizer.model}` === option.id))];
    }
    return [defaultRecognizerOption, ...options.filter((option) => recognizersForEn.some(recognizer => `${recognizer.type}_${recognizer.model}` === option.id))];
  }

  const setRecognizer = (value: Value) => {
    if (value.id === 'default') {
      const { recognizerOptions, ...questionData } = question;
      return onChange({ ...questionData });
    };

    const selectedRecognizerObject = recognizers?.find(recognizer => `${recognizer.type}_${recognizer.model}` === value.id);
    if (!selectedRecognizerObject) {
      return;
    }

    onChange({
      ...question,
      recognizerOptions: {
        type: selectedRecognizerObject.type,
        model: selectedRecognizerObject.model,
      }
    });
  };

  const selectedRecognizer = question.recognizerOptions;
  const selectedRecognizerOption = options.find(
    (option) => option.id === `${selectedRecognizer?.type}_${selectedRecognizer?.model}`
  ) ?? defaultRecognizerOption;

  return (
    <ElementWithLabel
      labelId={'survey-creator.changeRecognizer'}
      borderEnabled
      dataTest="recognizer-select"
    >
      <SelectComponent
        onChange={setRecognizer}
        value={selectedRecognizerOption}
        options={getOptionsForLanguageCode(bot.voiceConfig.languageCode)}
      />
    </ElementWithLabel>
  );
};
