import { uniq } from 'lodash';
import React, { ReactNode } from 'react';
import { InboundCall } from '../../icons/conversations/InboundCall';
import { OutboundCall } from '../../icons/conversations/OutboundCall';
import { UnansweredCall } from '../../icons/conversations/UnansweredCall';
import { Voicemail } from '../../icons/conversations/Voicemail';

import { NamesDictionary } from '../../reducers/namesDictionary';

import { ResultInformationItem } from './ResultInformationItem';

interface RawEventsData {
    readonly information?: ReadonlyArray<ResultInformationItem | string>;
    readonly results?: ReadonlyArray<ResultInformationItem | string>;
    readonly technicalInformation?: ReadonlyArray<ResultInformationItem | string>;
    readonly surveyQuestions?: ReadonlyArray<ResultInformationItem | string>;
    readonly recognizedIntents?: ReadonlyArray<ResultInformationItem>;
}

export const EventTypeList = ['results', 'information', 'technicalInformation', 'surveyQuestions', 'recognizedIntents', 'fallbacks', 'silenceFallbacks'] as const;
export type EventType = typeof EventTypeList[number];

export enum EventWithIcon {
    CallAnswered = 'call_answered',
    InboundCall = 'incoming_call',
    UnansweredCall = 'call_not_answered',
    VoicemailDetected = 'voice_mail_detected'
}

export const isEventWithIcon = (eventKey: string): eventKey is EventWithIcon => {
    return Object.values(EventWithIcon).includes(eventKey as EventWithIcon);
};

export type EventData = Readonly<{
    key: string;
    label: EventWithIcon | string;
    type: EventType;
    icon: ReactNode;
}>;

export type EventGroups = {
    events: ReadonlyArray<EventData>;
    stepEvents: ReadonlyArray<EventData>;
}

const iconForEvent = (eventKey: EventWithIcon | string
) => ({
    [EventWithIcon.CallAnswered]: <OutboundCall data-test="outbound-call-icon" />,
    [EventWithIcon.InboundCall]: <InboundCall data-test="inbound-call-icon" />,
    [EventWithIcon.UnansweredCall]: <UnansweredCall data-test="unanswered-call-icon" />,
    [EventWithIcon.VoicemailDetected]: <Voicemail data-test="voicemail-icon" />,
}[eventKey]);

const getEventKey = (item: ResultInformationItem | string) => (item as ResultInformationItem).value || item;

export function convertInformationItemToEventData(
    informationList: RawEventsData,
    namesDictionary: NamesDictionary,
): EventGroups {
    const keyToEvent = (type: EventType) => (eventKey: string) => {
        if (type !== 'surveyQuestions' || namesDictionary?.[eventKey]) {
            return ({
                label: eventKey,
                ...namesDictionary?.[eventKey],
                key: eventKey,
                type,
                icon: iconForEvent(eventKey)
            });
        } else {
            return undefined;
        }
    };

    return {
        stepEvents: [
            ...uniq((informationList.surveyQuestions || []).map(getEventKey)).map(keyToEvent('surveyQuestions')).filter(Boolean),
            ...uniq((informationList.recognizedIntents || []).map(getEventKey)).map(keyToEvent('recognizedIntents')).filter(Boolean),
        ],
        events: [
            ...uniq((informationList.results || []).map(getEventKey)).map(keyToEvent('results')).filter(Boolean),
            ...uniq((informationList.information || []).map(getEventKey)).map(keyToEvent('information')).filter(Boolean),
            ...uniq((informationList.technicalInformation || []).map(getEventKey)).map(keyToEvent('technicalInformation')).filter(Boolean)
        ]
    };
}
