import React from 'react';
import { FC } from 'react';

import TermsOfServiceModal from './TermsOfServiceModal';

interface Props {
  onClose: () => void;
}

const TermsOfServiceInfoModal: FC<Props> = ({ onClose }) => {
  return (
    <TermsOfServiceModal
      buttonTextKey="close"
      onClick={onClose}
      onClose={onClose}
    />
  );
};

export default TermsOfServiceInfoModal;
