import React, { useContext } from 'react';

import { AddButton } from '../../../../surveyCreator/components/AddButton';
import { Label } from '../../../../components/Label';
import { HeaderMappingComponent } from './HeaderMappingComponent';

import { SipHeaderMappingsContext } from '../AdminTabContentContainer';

import styles from './HeaderMappingsComponent.pcss'

export const HeaderMappingsComponent = () => {
  const {sipHeaderMappings, addSipHeaderMapping} = useContext(SipHeaderMappingsContext);
  return (
    <>
      <Label labelId={'settings.admin.sipHeaders.mappingsLabel'}/>
      <div>
        {
          sipHeaderMappings.map((mapping, index) => (
            <div key={index} data-test="http-mapping-container" className={styles.mappingContainer}>
              <HeaderMappingComponent mapping={mapping}/>
            </div>
          ))
        }
      </div>
      <div className={styles.addButton}>
        <AddButton
          onClick={addSipHeaderMapping}
          dataTest={'add-sip-header'}
          labelId={'settings.admin.sipHeaders.addMappingButton'}/>
      </div>
    </>
  );
};
