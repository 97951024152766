import classNames from 'classnames';
import * as React from 'react';
import {useContext, useState} from 'react';
import uuid from 'uuid';

import {StringChipsComponent} from '../components/chips/ChipsComponent';
import {Input} from '../components/Input';
import {Label} from '../components/Label';
import {SelectComponent} from '../components/Select/Select';
import {DeleteIcon} from '../icons';
import {AddButton} from '../surveyCreator/components/AddButton';
import {AddPopover} from '../surveyCreator/components/AddPopover';
import {HorizontalSeparator} from '../components/HorizontalSeparator/HorizontalSeparator';
import {typeOptions, VariableType} from '../surveyCreator/components/variables/Variables.utils';
import {isDictionaryVariable, StringVariable, SurveyDefinitionVariable} from '../surveyCreator/model';
import {VariablesState} from '../views/Settings/types';

import styles from './ContactVariablesComponent.pcss';
import {SipHeaderMappingsContext} from '../views/Settings/AdminTabContent/AdminTabContentContainer';

export type SettingsVariablesProps = {
    createVariable: (variable: SurveyDefinitionVariable) => void;
    editVariable: (id: string) => (variable: Partial<SurveyDefinitionVariable>) => void;
    removeVariable: (id: string) => void;
    variables: VariablesState;
    hasChanges: boolean;
    isReady: boolean;
}

const optionFor = (it: string) => ({ id: it, name: it });

interface VariableProps {
    variable: SurveyDefinitionVariable;
    onChange: (variable: SurveyDefinitionVariable) => void;
}

const ContactVariable = ({ variable, onChange }: VariableProps) => {
    const onNameChange = (name: string) => {
        onChange({ ...variable, name });
    };

    const onTypeChange = (value: any) => {
        onChange({
            ...variable,
            type: value.id,
            values: value.name === VariableType.DICTIONARY ? [] : undefined
        });
    };

    const onValuesChange = (event: any) => {
        onChange({
            ...variable,
            type: VariableType.DICTIONARY,
            values: (event as any) as string[]
        });
    };

    return (
        <div data-test={`contact-variable`}>
            <div className={styles.flexContainer}>
                <Input className={styles.variableInput} onChange={onNameChange} dataTest={`contact-variable-name`} value={variable.name} />
                <SelectComponent
                    data-test={`contact-variable-type`}
                    className={styles.variableTypeSelect}
                    options={typeOptions}
                    onChange={onTypeChange}
                    value={optionFor(variable.type)}
                />
            </div>
            <div className={styles.chips}>
                {isDictionaryVariable(variable) && (
                    <StringChipsComponent
                        data-test={`contact-variable-values`}
                        items={variable.values}
                        i18nKey={'survey.contactVariables.dictionary'}
                        onChange={onValuesChange}
                    />
                )}
            </div>
        </div>
    );
};

export const ContactVariablesComponent = () => {
  const {
    editVariable,
    createVariable,
    removeVariable,
    variables
  } = useContext(SipHeaderMappingsContext);
  const [newVariable, setNewVariable] = useState<SurveyDefinitionVariable>();

  const onAddVariable = (onClick: any) => (e: any) => {
    setNewVariable({
      id: uuid.v4(),
      name: '',
      type: VariableType.STRING,
      origin: 'contact',
    } as StringVariable);
    onClick(e);
  }

  const onConfirmChanges = () => {
    createVariable(newVariable);
    setNewVariable(undefined);
  }

  const toExternalVariable = (variable: SurveyDefinitionVariable, idx: number) => (
      <div key={idx}>
        <div className={styles.rowContainer}>
          <ContactVariable variable={variable}
                            onChange={editVariable(variable.id)}/>
          <div className={styles.remove} onClick={() => removeVariable(variable.id)}
               data-test={`contact-variable-remove`}>
            <DeleteIcon animationClass={classNames(styles.removeIcon)}/>
          </div>
        </div>
        {idx < variables.filter(v => v.origin === 'contact').length - 1 && <HorizontalSeparator/>}
      </div>
  );

  return (
        <div data-test={`contact-variables`}>
            <Label labelId={`survey.config.admin.addVariable.title.contact`}/>
            {variables.filter(v => v.origin === 'contact').map(toExternalVariable)}
            <AddPopover
                dataTest={`new-contact-variable`}
                onConfirm={() => onConfirmChanges()}
                onReject={() => setNewVariable(undefined)}
                renderButton={({ onClick }) => (
                    <AddButton
                        onClick={onAddVariable(onClick)}
                        className={styles.addButton}
                        labelId="survey.config.admin.addVariable"
                        dataTest={`contact-variable-add`}
                    />
                )}
                renderContent={() => (
                    <div>
                        <ContactVariable variable={newVariable} onChange={setNewVariable} />
                    </div>
                )}
            />
        </div>
    );
};
