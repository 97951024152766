import React from 'react';

import {IconProps} from './iconTypes';

export function ReportsIcon(props: IconProps) {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={props.animationClass} fillRule="evenodd" clipRule="evenodd" d="M2.027 0h12.946c.843 0 1.527.684 1.527 1.527v12.946c0 .843-.684 1.527-1.527 1.527H2.027A1.527 1.527 0 0 1 .5 14.473V1.527C.5.684 1.184 0 2.027 0zm12.946 1.063H2.027a.465.465 0 0 0-.465.464v12.946c0 .257.209.465.465.465h12.946a.465.465 0 0 0 .465-.465V1.527a.465.465 0 0 0-.465-.464zm-1.991 1.925a.531.531 0 0 1 .086 1.055l-.086.007H7.007a.531.531 0 0 1-.086-1.055l.086-.007h5.975zm.53 6.506a.531.531 0 0 0-.53-.531H4.019l-.086.007a.531.531 0 0 0 .086 1.055h8.963l.086-.007a.531.531 0 0 0 .445-.524zm-.53-3.52a.531.531 0 0 1 .086 1.056l-.086.007H4.019a.531.531 0 0 1-.086-1.055l.086-.007h8.963zm-3.453 6.508A.531.531 0 0 0 9 11.95h-4.98l-.086.006a.531.531 0 0 0 .086 1.056h4.98l.085-.007a.531.531 0 0 0 .445-.524z" fill="#3C38BC"/>
    </svg>
  );
}
