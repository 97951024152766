import React from 'react';

import { Popup } from '../../../components/Popup/Popup';
import { useHover } from '../../../hooks/useHover';
import { HelpPopupIcon } from '../../../icons/HelpPopupIcon';

import styles from './HelpPopup.pcss';

type HelpPopupProps = {
  messageId: string;
}

export const HelpPopup: React.FC<HelpPopupProps> = ({
  messageId
}) => {
  const [hoverRef, isHovered] = useHover();
  return <span ref={hoverRef} className={styles.helpPopup}>
    <HelpPopupIcon animationClass={styles.icon} />
    { isHovered && <Popup messageId={messageId} /> }
  </span>;
}