import React, { useCallback, useContext, useMemo } from 'react';
import _ from 'lodash';
import { MultiValueProps, OptionTypeBase } from 'react-select';
import { Synonym } from '../../surveyCreator/model';
import { isLemmatizationEnabled } from '../../utils/featureFlags';
import { ChipsComponent } from './ChipsComponent';

import { CurrentBot } from '../../utils/CurrentBot';
import { lemmatizeSynonym } from '../../utils/lemmatizeSynonym';
import { SynonymComponent } from './synonym';
import { useSynonymsClipboard } from './useSynonymsClipboard';
import styles from './SynonymsChipsComponent.pcss';

type SynonymsChipsComponentProps = {
  items: Synonym[];
  onChange: (items: Synonym[]) => void;
  i18nKey: string;
}

type AdapterProps = { onToggle: (synonym: Synonym) => void };
const buildSynonymComponentAdapter: ({ onToggle }: AdapterProps) => React.FC<MultiValueProps<OptionTypeBase>> = ({ onToggle }) => (props) => (
  <SynonymComponent
    synonym={props.data.value}
    mode={'editable'}
    onRemove={props.removeProps.onClick}
    onToggle={onToggle}
    className={styles.synonym}
  />
)

const createSynonymOption = (item: Synonym) => ({
  label: item.lemma ?? item.verbatim,
  value: item,
});
const isSynonymEqual = (a: Synonym, b: Synonym) => a.verbatim === b.verbatim;

export const SynonymsChipsComponent: React.FC<SynonymsChipsComponentProps> = ({
  items,
  onChange,
  i18nKey,
}) => {
  const currentBot = useContext(CurrentBot).getCurrentBot();
  const { onCopy, getDataFromPaste, mergePastedItems } = useSynonymsClipboard(currentBot.id);
  const onSynonymToggle = useCallback(async (synonymToToggle: Synonym) => {
    const synonyms = await Promise.all(items.map((synonym) => {
      if (_.isEqual(synonymToToggle, synonym)) {
        if (synonym.lemma) {
          return { verbatim: synonym.verbatim };
        }
        return lemmatizeSynonym(synonym.verbatim, currentBot.id);
      }
      return synonym;
    }));
    onChange(synonyms);
  }, [items, onChange, currentBot.id]);
  const SynonymComponentAdapter = useMemo(() => buildSynonymComponentAdapter({ onToggle: onSynonymToggle }), [onSynonymToggle]);

  return <ChipsComponent
    className={styles.container}
    items={items}
    onCopy={onCopy}
    onChange={onChange}
    getDataFromPaste={getDataFromPaste}
    mergePastedItems={mergePastedItems}
    i18nKey={i18nKey}
    MultiValueChipComponent={SynonymComponentAdapter}
    createOption={createSynonymOption}
    createItem={(newInputValue: string) => {
      if (isLemmatizationEnabled()) {
        return lemmatizeSynonym(newInputValue, currentBot.id);
      } else {
        return Promise.resolve({ verbatim: newInputValue });
      }
    }}
    isEqual={isSynonymEqual}
  />;
}