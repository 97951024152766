import React from 'react';

import {IconProps} from './iconTypes';

export function AddCondIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H16V16H0z"/>
                <g className={props.animationClass} stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.167">
                    <path d="M.292 4.667L9.042 4.667M4.667.292L4.667 9.042" transform="translate(3.333 3.333)"/>
                </g>
            </g>
        </svg>
    );
}
