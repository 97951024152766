import * as React from 'react';
import {useState} from 'react';

import {Scrollbar} from '../../../../components/Scrollbar/Scrollbar';
import {
  Block,
  BlockSurveyIntent,
  ConditionalMessage,
  EndSurveyIntent,
  Message,
  ReactionType,
  StatementSurveyIntent,
  SurveyIntent, WordQuestion, WordSurveyIntent
} from '../../../model';
import {EndQuestionComponent} from '../../EndQuestionComponent';
import {IntentQuestionComponent} from '../../IntentQuestionComponent';
import {questionFactory} from '../../questions-factory';
import {StatementComponent} from '../../StatementComponent';
import {BlockSurveyIntentComponent} from '../BlockSurveyIntentComponent';

import styles from './IntentReaction.pcss';
import {ReactionTypeSelect} from './ReactionTypeSelect';
import { MessageContext } from '../../StepDetail';
import {produce} from 'immer';
import _ from 'lodash';

type Props = {
  blocks: Block[];
  intent: SurveyIntent,
  onChange: (intent: SurveyIntent | ((i: SurveyIntent) => SurveyIntent)) => void
};

const ScrollWrapper = (props: { children: JSX.Element }) => {
  return (
    <div className={styles.scrollContainer}>
      <Scrollbar hideHorizontal>
        <div className={styles.intentTypeContainer}>
          {props.children}
        </div>
      </Scrollbar>
    </div>
  );
};

export const IntentReaction = ({onChange, blocks, intent}: Props) => {
  const [previousMessage, setPreviousMessage] = useState(!isBlock(intent) ? intent?.question?.message ?? emptyMessage : emptyMessage);
  const [previousRepeatMessages, setPreviousRepeatMessages] = useState(isWord(intent) ? intent?.question?.repeatMessages ?? [emptyMessage] : [emptyMessage]);
  const [previousFromIntentMessage, setPreviousFromIntentMessage] = useState(isWord(intent) ? intent?.question?.fromIntentMessage ?? emptyMessage : emptyMessage);

  const onReactionTypeChange = (reactionType: ReactionType) => {
    const reactionFactory = reactionsFactory(reactionType);
    const newIntent = reactionFactory({
      message: previousMessage,
      repeatMessages: previousRepeatMessages,
      fromIntentMessage: previousFromIntentMessage,
      blocks
    });
    onChange({
      name: intent.name, 
      entities: intent.entities, 
      ...newIntent
    } as SurveyIntent);
  };
  const onReactionWithMessageChange = <T extends IntentWithQuestion>(question: T['question']) => {
    setPreviousMessage({...question.message});
    onChange({...intent as T, question});
  };
  const onWordReactionChange = (question: WordQuestion) => {
    const {repeatMessages, fromIntentMessage} = question;
    setPreviousFromIntentMessage({...fromIntentMessage});
    setPreviousRepeatMessages(Array.isArray(repeatMessages) ? [...repeatMessages] : {...repeatMessages});
    onReactionWithMessageChange(question);
  };

  const onConditionalMessageChange = (changedMessage: Partial<Message>, path: string = 'message') => {
    setPreviousMessage(isMessage(changedMessage) ? changedMessage : emptyMessage);
    onChange(produce((draft) => {
      if (isConditionalQuestion(draft)) {
        _.set(draft, `question.${path}.values`, {
          ..._.get(draft, `question.${path}.values`),
          ...changedMessage,
        });
      } else {
        _.set(draft, `${path}.values`, {
          ..._.get(draft, `${path}.values`),
          ...changedMessage,
        });
      }
    }));
  };

  return (
    // MessageContext was introduced as an optimisation for UI performance issues - More details can be found in TLK-3116
    <MessageContext.Provider value={{ onConditionalMessageChange }}>
      <ReactionTypeSelect 
        selected={getSelectedReactionType(intent)}
        onChange={onReactionTypeChange}
        options={availableReactionTypes(!!blocks.length)}
      />
      {isStatement(intent) && (
        <ScrollWrapper>
          <StatementComponent
            question={intent.question}
            onChange={onReactionWithMessageChange}
          />
        </ScrollWrapper>
      )}
      {isWord(intent) && (
        <IntentQuestionComponent
          question={intent.question}
          onChange={onWordReactionChange}
        />
      )}
      {isEnd(intent) && (
        <ScrollWrapper>
          <EndQuestionComponent
            question={intent.question}
            onChange={onReactionWithMessageChange}
            asIntentQuestion
          />
        </ScrollWrapper>
      )}
      {isBlock(intent) && (
        <BlockSurveyIntentComponent
          intent={intent}
          blocks={blocks}
          onChange={onChange}
        />
      )}
    </MessageContext.Provider>
  );
};

const emptyMessage = {text: ''};
type IntentWithQuestion = Exclude<SurveyIntent, BlockSurveyIntent>;

const isBlock = (currentIntent: SurveyIntent): currentIntent is BlockSurveyIntent => !!currentIntent && 'block' in currentIntent;
const isIntentWithQuestion = (currentIntent: SurveyIntent): currentIntent is IntentWithQuestion => !!currentIntent && 'question' in currentIntent;
const isWord = (currentIntent: SurveyIntent): currentIntent is WordSurveyIntent => isIntentWithQuestion(currentIntent) && currentIntent.question.type === 'word';
const isEnd = (currentIntent: SurveyIntent): currentIntent is EndSurveyIntent => isIntentWithQuestion(currentIntent) && currentIntent.question.type === 'end';
const isStatement = (currentIntent: SurveyIntent): currentIntent is StatementSurveyIntent => isIntentWithQuestion(currentIntent) && currentIntent.question.type === 'statement';
const getSelectedReactionType = (intent: SurveyIntent) => isBlock(intent) ? 'block' : intent.question.type;
const availableReactionTypes = (hasBlocks: boolean): ReactionType[] => hasBlocks
  ? ['statement', 'block', 'word', 'end']
  : ['statement', 'word', 'end'];
const reactionsFactory = (reactionType: ReactionType) =>
  ({blocks, message, ...messages}) =>
    reactionType === 'block'
      ? ({block: blocks?.[0]?.id})
      : ({
        question: {
          ...questionFactory[reactionType](),
          message,
          ...(reactionType === 'word' ? messages : {}),
        }
      });
const isConditionalQuestion = (q): q is ConditionalMessage => !!q.question;
const isMessage = (q): q is Message => !!q.text || !!(q.name && q.values)