import * as React from 'react';
import { useIntl } from 'react-intl';
import { LanguageCode } from '../../language/botLanguage';
import { nowInTimezone } from '../../utils/DateService';
import { useIntervalUpdater } from '../hooks/useIntervalUpdater';
import styles from './BotClock.pcss';

type Props = {
  timeZone: string;
  languageCode: LanguageCode;
};

export function BotClock({ timeZone, languageCode }: Props) {
  useIntervalUpdater();

  const intl = useIntl();
  const date = nowInTimezone(timeZone);

  return (
    <div data-test="time-box" className={styles.wrapper}>
      {capitalize(
        date
          .locale(languageCode)
          .format(intl.messages['datetime.format'] as string)
      )}
    </div>
  );
}

function capitalize(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}
