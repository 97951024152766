import React from 'react';

import {IconProps} from './iconTypes';


export function StatisticsScenarioIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <g fill="none" fillRule="evenodd">
          <g>
            <g transform="translate(-28 -703) translate(16 688)">
              <g>
                <g transform="translate(12 14) translate(0 1)">
                  <path d="M0 0H18V18H0z"/>
                  <path className={props.animationClass} fill={'#919aaf'} style={{stroke: 'none'}} d="M6.724 14.74h-5.01C.77 14.74 0 13.972 0 13.019V1.723C0 .77.769 0 1.714 0h8.968c.945 0 1.714.77 1.714 1.723v2.259c0 .327-.266.593-.594.593-.327 0-.593-.266-.593-.593v-2.26c0-.294-.235-.536-.527-.536H1.714c-.292 0-.527.242-.527.537v11.295c0 .295.235.537.527.537h5.231c.143 0 .28.051.388.144l1.634 1.412v-.963c0-.328.266-.593.594-.593h1.12c.2 0 .383-.115.472-.295.145-.293.502-.413.795-.268.294.146.414.502.268.795-.29.585-.885.954-1.534.954h-.528v1.666c0 .232-.135.443-.346.54-.212.096-.46.06-.635-.091L6.724 14.74zm10.823-1.422c-.727.956-2.084 1.142-3.039.414L8.076 8.83c-.093-.07-.163-.168-.2-.278l-.94-2.72c-.066-.186-.032-.394.087-.551.12-.158.31-.246.509-.234l2.852.171c.118.007.23.05.324.12l6.431 4.903c.459.35.762.868.84 1.442.08.574-.071 1.155-.418 1.617l-.014.018zM10.132 6.39l-1.785-.107.591 1.712 6.29 4.793c.433.33 1.05.243 1.378-.194l.007-.009c.159-.212.227-.478.19-.741-.036-.263-.173-.5-.383-.661l-6.288-4.793zm-7.297 5.526H7.32c.327 0 .593-.266.593-.593 0-.327-.266-.593-.593-.593H2.835c-.327 0-.593.266-.593.593 0 .327.266.593.593.593zm0-2.26h2.803c.327 0 .593-.265.593-.592 0-.328-.266-.593-.593-.593H2.835c-.327 0-.593.265-.593.593 0 .327.266.593.593.593zm0-7.398h3.363c.327 0 .593.266.593.593 0 .327-.266.593-.593.593H5.11v2.796c0 .327-.266.593-.593.593-.328 0-.594-.266-.594-.593V3.445H2.835c-.327 0-.593-.266-.593-.593 0-.327.266-.593.593-.593z"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
}
