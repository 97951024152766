import * as React from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';

import { GoToConversationIcon } from '../../../../icons/GoToConversationIcon';
import { ToLearnMessage } from '../../../../inspector/Inspector';
import { IconButton } from '../../../IconButton/IconButton';
import { NavLink } from 'react-router-dom';

type Props = {
    message: ToLearnMessage;
};

export const GoToConversation = withRouter((props: Props & RouteComponentProps) => {
    const intl = useIntl();
    return <NavLink to={`${props.message.callId}`} target={'_blank'}><IconButton
        data-test="go-to-conversation-button"
        Icon={GoToConversationIcon}
        title={intl.messages['inspector.actions.goToConversation'] as string}
    /></NavLink>;
})
