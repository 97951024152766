import classNames from 'classnames';
import { padStart } from 'lodash';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useIntl } from 'react-intl';

import { ClearIcon } from '../../components/ClearIcon/ClearIcon';
import { ClockIcon } from '../../icons';

import filterStyles from './CallDurationFilter.pcss';

export type CallDuration = { min?: number; max?: number };

interface Props {
    readonly className?: string;
    readonly min?: number;
    readonly max?: number;

    onChange?(data: CallDuration): void;
}

const MAX_SECONDS_DEFAULT = 90 * 60;

export function CallDurationFilter({ min, max, className, onChange}: Props) {
    const intl = useIntl();
    const [value, setValue] = useState(
        formatTime(min || 0) + ' - ' + formatTime(max || MAX_SECONDS_DEFAULT)
    );

    const getMaxValue = (maxSeconds, minSeconds) => {
        if (maxSeconds === MAX_SECONDS_DEFAULT) {
            return undefined;
        }

        if (maxSeconds <= minSeconds) {
            return minSeconds + 1;
        }

        return maxSeconds;
    }

    const onBlur = useCallback(() => {
        const { parsedMin, parsedMax, minSeconds, maxSeconds } = parseDurationValue(value);

        setValue(parsedMin + '-' + parsedMax);
        if (onChange) {
            onChange({
                min: minSeconds || undefined,
                max: getMaxValue(maxSeconds, minSeconds) || undefined,
            });
        }
    }, [value]);
    const blurInput = useCallback(event => {
        if (event.key === 'Enter' && event.target) {
            (event.target as HTMLInputElement).blur();
        }
    }, []);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value), []);

    useEffect(() => {
        setValue(formatTime(min || 0) + ' - ' + formatTime(max || MAX_SECONDS_DEFAULT));
    }, [min, max]);

    return (
        <div className={classNames(filterStyles.item, className)}>
            <span className={filterStyles.icon}><ClockIcon /></span>
            <InputMask
                data-test="duration-filter-input"
                className={classNames(filterStyles.inputWrapper)}
                type="text"
                value={value === '00m 00s - 90m 00s' ? '' : value}
                mask="99m 99s - 99m 99s"
                maskChar="_"
                placeholder={intl.messages['conversations.filters.duration.label'] + ''}
                onChange={handleChange}
                onBlur={onBlur}
                onKeyDown={blurInput}
            />
            <ClearIcon
                className={filterStyles.clearIcon}
                enabled={!!max || !!min}
                onClear={() =>
                    onChange({
                        min: undefined,
                        max: undefined
                    })
                }
            />
        </div>
    );
}

function parseDurationValue(durationFilter: string) {
    const [minText, maxText] = durationFilter.split('-');
    const parsedMin = minText.replace(/_/g, '0');
    const parsedMax = maxText.trim()
        .replace(/[_0][_0]m ([_0][_0])s/, '90m $1s')
        .replace(/_(\d)m/, '0$1m')
        .replace(/_/g, '0');


    const minSeconds = getSecondsFromFormattedTime(parsedMin);
    const maxSeconds = getSecondsFromFormattedTime(parsedMax);

    return { parsedMin, parsedMax, minSeconds, maxSeconds };
}

function formatTime(seconds: number) {
    return `${padStart(Math.floor(seconds / 60) + '', 2, '0')}m ${padStart((seconds % 60) + '', 2, '0')}s`;
}

function getSecondsFromFormattedTime(time: string) {
    const [, minutesText, secondsText] = time.match(/(\d+)m\s+(\d+)s/);
    return parseInt(minutesText, 10) * 60 + parseInt(secondsText, 10);
}
