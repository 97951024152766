import classNames from 'classnames';
import * as FileSaver from 'file-saver';
import { padStart } from 'lodash';
import * as React from 'react';
import {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import { FormattedMessage } from 'react-intl';

import { HttpClient } from '../../utils/HttpClient';
import {
    AudioFileDownloadIcon,
    AudioFileDownloadDisabledIcon,
    ProgressIcon,
    ProgressDisabledIcon,
    PlayCircleIcon,
    PlayCircleDisabledIcon,
    PauseCircleIcon,
} from '../../icons';

import styles from './AudioPlayer.pcss';

type LoadingState = 'loading' | 'loaded' | 'error';

type State = {
    readonly playing: boolean;
    readonly loadState: LoadingState;
    readonly currentTime: number;
    readonly duration: number;
};

export type AudioState = State & {
    onLoaded(loadedDuration: number): void;
    onTick(currentTimeTick: number): void;
    onError(): void;
    play(): void;
    pause(): void;
    end(): void;
    reset(): void;
    playPart(start: number, partDuration: number): void;
};

type Props = {
    readonly audioState?: AudioState;
    readonly streamAudioUrl?: string;
    readonly downloadAudioUrl?: string;
    onLoaded?(loaded: boolean): void;
};

const REFRESH_INTERVAL_MS = 500;
export function AudioPlayer(props: Props) {
    const audioState = useAudioState();
    if (props.streamAudioUrl) {
        return <ActiveAudioPlayer {...props} audioState={props.audioState || audioState} />;
    }
    return <EmptyAudioPlayer label="audioPlayer.noRecording" />;
}

function isAtTheEnd(state: State) {
    return state.currentTime === state.duration;
}

export function useAudioState(): AudioState {
    const [{ playing, loadState, currentTime, duration }, setState] = useState<State>({
        playing: false,
        loadState: 'loading',
        currentTime: 0,
        duration: 0
    });
    const [partPlayingState, setPartPlayingState] = useState({ partPlay: false, partEnd: 0 });
    const pause = useCallback(() => setState(prevState => ({ ...prevState, playing: false })), [setState]);
    const play = useCallback(
        () =>
            setState(prevState => ({
                ...prevState,
                playing: true,
                currentTime: isAtTheEnd(prevState) ? 0 : prevState.currentTime
            })),
        [setState]
    );
    const end = useCallback(() => {
        setState(prevState => ({
            ...prevState,
            playing: false,
            currentTime: duration
        }));
    }, [setState, duration]);

    const onLoaded = useCallback(
        (loadedDuration: number) =>
            setState(prev => ({
                ...prev,
                duration: loadedDuration,
                loadState: 'loaded'
            })),
        [setState]
    );
    const onTick = useCallback(
        (currentTimeTick: number) => {
            setState(prev => ({
                ...prev,
                currentTime: currentTimeTick > prev.duration ? prev.duration : currentTimeTick
            }));
            if (partPlayingState.partPlay && partPlayingState.partEnd <= currentTimeTick) {
                pause();
                setPartPlayingState({ partPlay: false, partEnd: 0 });
            }
        },
        [setState, partPlayingState, pause, setPartPlayingState]
    );
    const onError = useCallback(() => setState(prev => ({ ...prev, loadState: 'error' })), [setState]);

    const playPart = useCallback(
        (start: number, partDuration: number) => {
            setPartPlayingState({ partPlay: true, partEnd: start + partDuration });
            setState(prev => ({ ...prev, currentTime: start > prev.duration ? prev.duration : start }));
            if (!playing) {
                play();
            }
        },
        [setState, play, playing, setPartPlayingState]
    );

    const reset = useCallback(() => {
        setState({
            playing: false,
            loadState: 'loading',
            currentTime: 0,
            duration: 0
        });
    }, []);

    return {
        playing,
        loadState,
        currentTime,
        duration,
        onLoaded,
        onTick,
        onError,
        play,
        pause,
        end,
        reset,
        playPart
    };
}

function ActiveAudioPlayer(props: Props & { readonly audioState: AudioState }) {
    const {
        playing,
        loadState,
        currentTime,
        duration,
        onLoaded,
        onTick,
        onError,
        play,
        pause,
        end: playerEnd
    } = props.audioState;
    const holdingRef = useRef(false);
    const [progressOffset, setProgressOffset] = useState(0);
    const playerRef = useRef<HTMLAudioElement>();
    const progressBarRef = useRef<HTMLDivElement>();
    const onMouseMoveFunctionRef = useRef<(m: MouseEvent) => void>();
    const onMouseUpFunctionRef = useRef<(m: MouseEvent) => void>();
    const intervalRef = useRef<number>();

    const startTimer = useCallback(() => {
        clearInterval(intervalRef.current);
        intervalRef.current = window.setInterval(() => {
            onTick(playerRef.current.currentTime);
        }, REFRESH_INTERVAL_MS);
    }, [intervalRef, playerRef, onTick]);

    const endedHandler = useCallback(() => {
        if (!holdingRef.current) {
            clearInterval(intervalRef.current);
            playerEnd();
        }
    }, [playerEnd, intervalRef, holdingRef]);

    const canPlayHandler = useCallback(() => {
        onLoaded(playerRef.current.duration);
    }, [onLoaded, playerRef]);

    const changeProgressOffset = useCallback(
        (newProgressOffset: number) => {
            const newCurrentTime = getTimeFromPosition(
                progressBarRef.current.getBoundingClientRect().width,
                newProgressOffset,
                duration
            );
            playerRef.current.currentTime = newCurrentTime;
            onTick(newCurrentTime);
        },
        [progressBarRef, duration, playerRef, onTick]
    );

    const onMouseDown = useCallback(
        (e: React.MouseEvent, newProgressOffset: number) => {
            e.stopPropagation();
            const pageX = e.pageX;

            holdingRef.current = true;
            playerRef.current.pause();
            clearInterval(intervalRef.current);

            onMouseMoveFunctionRef.current = (event: MouseEvent) =>
                changeProgressOffset(event.pageX - pageX + newProgressOffset);
            onMouseUpFunctionRef.current = () => {
                if (playing) {
                    if (playerRef.current.duration === playerRef.current.currentTime) {
                        playerEnd();
                    } else {
                        startTimer();
                        playerRef.current.play();
                    }
                }
                document.removeEventListener('mousemove', onMouseMoveFunctionRef.current);
                document.removeEventListener('mouseup', onMouseUpFunctionRef.current);
                holdingRef.current = false;
            };

            document.addEventListener('mousemove', onMouseMoveFunctionRef.current);
            document.addEventListener('mouseup', onMouseUpFunctionRef.current);
        },
        [
            playerRef,
            intervalRef,
            onMouseMoveFunctionRef,
            changeProgressOffset,
            onMouseUpFunctionRef,
            playing,
            startTimer,
            holdingRef,
            playerEnd
        ]
    );

    // go to clicked location
    const onMouseDownProgressBar = useCallback(
        (e: React.MouseEvent) => {
            // @ts-ignore
            const timelineDisToLeft = e.target.parentNode.getBoundingClientRect().left;
            const newProgressOffset = e.pageX - timelineDisToLeft;
            onMouseDown(e, newProgressOffset);
            changeProgressOffset(newProgressOffset);
        },
        [onMouseDown, changeProgressOffset]
    );

    const downloadAudio = useCallback(async () => {
        const { data } = await HttpClient.get({ path: props.downloadAudioUrl }, { responseType: 'blob' });
        FileSaver.saveAs(data, 'recording.wav');
    }, [props.downloadAudioUrl]);

    useEffect(() => {
        if (props.onLoaded) {
            props.onLoaded(loadState !== 'loading');
        }
    }, [loadState]);

    useEffect(() => {
        if (loadState === 'loaded') {
            setProgressOffset((currentTime / duration) * progressBarRef.current.getBoundingClientRect().width);
        }
    }, [currentTime, duration, loadState, progressBarRef]);
    useLayoutEffect(() => {
        if (loadState === 'loaded') {
            if (playing) {
                playerRef.current.currentTime = currentTime;
                playerRef.current.play();
                startTimer();
            } else {
                playerRef.current.pause();
                clearInterval(intervalRef.current);
            }
        }
    }, [playing, loadState, playerRef, intervalRef, startTimer]);

    useEffect(() => {
        playerRef.current.addEventListener('canplay', canPlayHandler);
        playerRef.current.addEventListener('loadedmetadata', canPlayHandler);
        playerRef.current.addEventListener('error', onError);
        playerRef.current.addEventListener('ended', endedHandler);

        return () => {
            clearInterval(intervalRef.current);
            document.removeEventListener('mousemove', onMouseMoveFunctionRef.current);
            document.removeEventListener('mouseup', onMouseUpFunctionRef.current);
            playerRef.current.removeEventListener('ended', endedHandler);
            playerRef.current.removeEventListener('canplay', canPlayHandler);
            playerRef.current.removeEventListener('loadedmetadata', canPlayHandler);
            playerRef.current.removeEventListener('error', onError);
        };
    }, [playerRef, onMouseMoveFunctionRef, endedHandler]);

    return (
        <React.Fragment>
            <audio  data-test="audio-player-element" src={props.streamAudioUrl} controls={false} preload="metadata" ref={playerRef} />
            {loadState === 'loaded' && (
                <div className={styles.container} data-test="audio-player">
                    {playing ? (
                      <div className={`${styles.button} ${styles.pause}`} data-test="pause-button" onClick={pause}>
                          <PauseCircleIcon />
                      </div>
                    ) : (
                        <div className={`${styles.button} ${styles.play}`} data-test="play-button" onClick={play}>
                            <PlayCircleIcon />
                        </div>
                    )}

                    <div className={styles.progressWrapper}>
                        <div className={styles.progressBar} ref={progressBarRef}>
                            <div
                                className={styles.progressBackground}
                                onMouseDown={onMouseDownProgressBar}
                                data-test="progress-bar"
                            />
                            <div
                                className={styles.progress}
                                data-test="active-progress-bar"
                                onMouseDown={onMouseDownProgressBar}
                                style={{ width: `${progressOffset.toFixed(1)}px` }}
                            />
                            <div
                                className={styles.progressIcon}
                                onMouseDown={e => onMouseDown(e, progressOffset)}
                                data-test="audio-handler"
                                style={{ transform: `translateX(${(progressOffset - 6).toFixed(1)}px)` }}
                            >
                                <ProgressIcon />
                            </div>
                        </div>
                        <div className={styles.time} data-test="audio-time">
                            {formatTime(currentTime)}/{formatTime(duration)}
                        </div>
                    </div>
                    <div className={styles.download} data-test="download-button" onClick={downloadAudio}>
                        <AudioFileDownloadIcon />
                    </div>
                </div>
            )}
            {loadState === 'error' && <EmptyAudioPlayer label="audioPlayer.recordingError" />}
            {loadState === 'loading' && <EmptyAudioPlayer />}
        </React.Fragment>
    );
}

function getTimeFromPosition(progressBarWidth: number, position: number, audioDuration: number) {
    const validatedOffset = Math.max(0, Math.min(position, progressBarWidth));
    return (validatedOffset / progressBarWidth) * audioDuration;
}

function formatTime(timeInSeconds: number) {
    const pad = (val: string) => padStart(val, 2, '0');
    const seconds = Math.floor(timeInSeconds % 60).toString();
    const minutes = (Math.floor(timeInSeconds / 60) % 60).toString();
    const hours = Math.floor(timeInSeconds / 60 / 60).toString();
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
}

export function EmptyAudioPlayer(props: { label?: string }) {
    return (
        <div className={styles.emptyContainer}>
            <div className={classNames(styles.button, styles.disabled)}>
                <PlayCircleDisabledIcon />
            </div>
            <div className={styles.progressWrapper}>
                <div className={styles.progressBar}>
                    <div className={styles.progressBackgroundDisabled} />
                    <div className={styles.progressIconDisabled} style={{ transform: `translateX(-6px)` }}>
                        <ProgressDisabledIcon />
                    </div>
                </div>
                <div className={styles.timeDisabled} />
            </div>
            <div className={styles.emptyDownload}>
                <AudioFileDownloadDisabledIcon />
            </div>
            {props.label && (
                <div className={styles.emptyStatMessage}>
                    <FormattedMessage id={props.label} />
                </div>
            )}
        </div>
    );
}
