import {useMutation, UseMutationResult, useQuery, UseQueryOptions, UseQueryResult, useQueryClient} from 'react-query';
import {AxiosResponse} from 'axios';
import {HttpClient} from '../utils/HttpClient';
import {ContactStatusStatistics} from '../contacts/model';
import {Status} from '../dialer/components/scheduler-status/SchedulerStatus';

export const getUseGetSchedulerStatusQueryKey = (botId: string) => {
    return ['useGetScheduleStatus', botId]
}

export const useGetScheduleStatus = <TData = Status>(
    botId: string,
    options?: UseQueryOptions<TData>,
): UseQueryResult<TData> =>
    useQuery(
        getUseGetSchedulerStatusQueryKey(botId),
        () => HttpClient.get({ path: `/bots/${botId}/schedule/status` }).then(resp => resp.data), {
      ...options,
    });

export const getUseGetContactStatisticsQueryKey = (botId: string) => {
    return ['useGetContactStatistics', botId]
}

export const useGetContactStatistics = <TData = { stats: ContactStatusStatistics }>(
    botId: string,
    options?: UseQueryOptions<TData>,
): UseQueryResult<TData> =>
    useQuery(
        getUseGetContactStatisticsQueryKey(botId),
        () => HttpClient.get({ path: `/bots/${botId}/contacts/statistics` }).then(resp => resp.data), {
            ...options,
        });

export const useSchedule = (botId: string): UseMutationResult<AxiosResponse<void>, unknown, {action: string}> => {
    const queryClient = useQueryClient();

    return useMutation((data: { action: string }) =>
            HttpClient.post({path: `/bots/${botId}/schedule/${data.action}`, body: {}}), {
            onSuccess: () => {
                void queryClient.invalidateQueries(getUseGetSchedulerStatusQueryKey(botId))
                void queryClient.invalidateQueries(getUseGetContactStatisticsQueryKey(botId))
            }
        }
    );
}
