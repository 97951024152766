import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Header } from '../layout/Header';
import { MainPage } from '../layout/MainPage';

import {GlobalSynonymsLearning} from './GlobalSynonymsLearning';
import IntentLearning from './IntentLearning';
import IntentsList from './IntentsList';
import styles from './LearningView.pcss';
import NamesDictionary from './NamesDictionary';

interface Props extends RouteComponentProps<any> {
    maxRasaStatusCheckTime?: number;
}

const LearningView = withRouter(({ history, ...props }: Props) => (
    <MainPage>
        <Header />
        <div>
            <div className={styles.entitiesIntentsContainer}>
                <IntentsList history={history} />
            </div>
            <div className={styles.learningContainer}>
                <IntentLearning {...props} />
            </div>
            <div className={styles.learningContainer}>
                <GlobalSynonymsLearning />
            </div>
            <div className={styles.learningContainer}>
                <NamesDictionary />
            </div>
        </div>
    </MainPage>
));

export default LearningView;
