import { useQuery } from 'react-query';
import { OrganizationSettings } from './SMSSettings';
import { HttpClient } from '../../utils/HttpClient';

export const getOrganizationSettingsCacheKey = (organizationName: string) => ['organizations', organizationName, 'settings'];

export function useGetOrganizationSettings(organizationName: string) {
    return useQuery<OrganizationSettings>(
        getOrganizationSettingsCacheKey(organizationName),
        () => HttpClient
            .get({ path: `/organizations/${organizationName}/settings` })
            .then(res => res.data)
    );
}