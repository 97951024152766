import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { IconProps } from '../../icons/iconTypes';
import styles from './StatisticsSectionsNavigation.pcss';

export function StatisticsSectionsNavigationContainer({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={classNames(styles.sectionsNavigation, className)}>
      {children}
    </div>
  );
}

type Props = {
  statisticsSections: {
    name: string;
    icon: (props: IconProps) => JSX.Element;
  }[];
  activeSection?: string;
  setActiveSection: (section: string) => void;
  actions?: React.ReactNode;
};

export function StatisticsSectionsNavigation({
  statisticsSections,
  activeSection,
  setActiveSection,
  actions,
}: Props) {
  return (
    <StatisticsSectionsNavigationContainer>
      <div className={styles.sections} data-test="statistics-sections-tabs">
        {statisticsSections.map((section) => {
          const isActive = activeSection === section.name;
          return (
            <div
              data-test="statistics-sections-tabs-item"
              key={section.name}
              className={classNames(
                styles.sectionItem,
                isActive && styles.active
              )}
              data-active={isActive}
              onClick={() => setActiveSection(section.name)}
            >
              <section.icon
                animationClass={isActive ? styles.activeIcon : undefined}
              />
              <FormattedMessage id={`statistics.${section.name}`} />
            </div>
          );
        })}
      </div>
      {actions}
    </StatisticsSectionsNavigationContainer>
  );
}
