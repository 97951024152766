import cx from 'classnames';
import React from 'react';

import { IconProps } from '../../icons/iconTypes';

import styles from './IconWithLabel.pcss';

export type IconWithLabelProps = {
  Icon: React.FC<IconProps>;
  label: string;
  dataTest?: string;
  color?: 'cyan' | 'orange';
}

export const IconWithLabel: React.FC<IconWithLabelProps> = ({ Icon, color, label, dataTest, children }) => {
  return (
    <div className={styles.iconWithLabel}>
      <div className={styles.left}>
        <span className={cx(
          styles.iconContainer,
          {
            [styles.cyan]: color === 'cyan',
            [styles.orange]: color === 'orange'
          }
        )}>
          <Icon animationClass={styles.icon} />
        </span>
        <span data-test={dataTest}>
          {label}
        </span>
      </div>
      {children}
    </div>
  )
}