import _ from 'lodash';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import { of } from 'rxjs';

import { Input } from '../../../components/Input';
import { useOutsideClick } from '../useOutsideClick';

import styles from './AddItemModal.pcss';

type Props = {
    addLabelId?: string;
    placeholderLabelId?: string;
    dataTest?: string;

    onSelect?: (value: string, event: any) => void;
    onCreate?: (value: string) => void;
    onClose?: () => void;

    items?: { key: string, name: string }[];
    renderTrigger?: (props: { open: React.MouseEventHandler }) => JSX.Element;
    forbidAddingItems?: boolean;
    newItemFormatter?: (text: string) => string;
    inputPrefix?: string;
};


export const AddItemModal = ({
    addLabelId,
    renderTrigger,
    dataTest,
    onSelect,
    onCreate,
    onClose,
    items,
    placeholderLabelId,
    forbidAddingItems = false,
    newItemFormatter,
    inputPrefix
}: Props) => {
    const intl = useIntl();
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [text, setText] = useState('');
    const close = useCallback(() => {
        setAddModalOpen(false);
    }, [setAddModalOpen]);
    const onReject = useCallback(() => {
        setText('');
        close();
        // tslint:disable-next-line:no-unused-expression
        !!onClose && onClose();
    }, [setText, close, onClose]);
    const onEscapeKeyUp = useCallback((event: any) => {
        if (event.key === 'Escape') {
            onReject();
        }
    }, [onReject]);

    const modalRef = useRef<HTMLDivElement>();
    const { addHandler, removeHandler } = useOutsideClick(modalRef, () => {
        close();
        // tslint:disable-next-line:no-unused-expression
        !!onClose && onClose();
    });

    useEffect(() => {
        if (addModalOpen) {
            addHandler();
        }
        return () => removeHandler();
    }, [addModalOpen, addHandler, removeHandler]);

    useEffect(() => {
        if (addModalOpen) {
            window.document.addEventListener('keyup', onEscapeKeyUp);
        }
        return () => window.document.removeEventListener('keyup', onEscapeKeyUp);
    }, [addModalOpen, onEscapeKeyUp]);

    const onConfirm = (value?: string) => (event: any) => {
        if (value) {
            onSelect(value, event);
        } else if (text) {
            onCreate(text);
        }

        const confirmValue = value || text;
        if (confirmValue !== '') {
            setText('');
            close();
        }
    };

    const onKeyPress = (event: any) => {
        if (event.key === 'Enter' && !forbidAddingItems) {
            onConfirm()(event);
        }
    };

    const itemMatches = (item: (typeof items[number])) => !text || item.name.toLowerCase().includes(text.toLowerCase());

    return (
        <>
            {!!renderTrigger && renderTrigger({ open: () => setAddModalOpen(true) })}
            {(!renderTrigger || addModalOpen) && createPortal(
                <div className={styles.modalContainer} onKeyUp={onEscapeKeyUp}>
                    <div data-test={dataTest} onKeyPress={onKeyPress} ref={modalRef} className={styles.modalWindow}>
                        <div className={styles.inputContainer}>
                            {inputPrefix && !_.isEmpty(text) ? <div className={styles.inputPrefix}>{inputPrefix}</div> : null}
                            <Input onChange={setText} dataTest={'item-key-input'}
                                placeholder={!!placeholderLabelId ? intl.messages[placeholderLabelId] as string : ''}
                                value={text}
                                className={styles.input}
                                autoFocus={true}
                                dataPrefix={inputPrefix}
                                onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    event.nativeEvent.stopImmediatePropagation();
                                }}
                            />
                        </div>
                        <div className={styles.items}>
                            {
                                !!items && items
                                    .filter(itemMatches)
                                    .map(item => {
                                        return (
                                            <div
                                                key={item.key}
                                                onClick={onConfirm(item.key)}
                                                data-test={`item-element`}
                                                className={styles.item}
                                            >
                                                {item.name}
                                            </div>
                                        )
                                    })
                            }
                            {!forbidAddingItems && !!text && (!items || !items.some(item => item.name === text)) && (
                                <div onClick={onConfirm()} data-test={'confirm-add'} className={styles.add}>
                                    {`${intl.messages[addLabelId]} "${newItemFormatter ? newItemFormatter(text) : text}"`}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.overlay} />
                </div>,
                document.getElementsByTagName('body')[0]
            )}
        </>
    );
};

export const SelectItemModal = AddItemModal;
