import React, { useCallback } from 'react';

import { MinusIcon } from '../../icons/MinusIcon';
import { PlusIcon } from '../../icons/PlusIcon';

import styles from './TimeInputWithStepper.pcss';

type Props = {
  dataTest?: string;
  value: number;
  onChange: (value: number) => void;
  step?: number;
  minValue?: number;
  maxValue?: number;
};

const getAsTime = (minutes: number) =>
  `${Math.floor(minutes / 60)
    .toString()
    .padStart(2, '0')}:${(minutes % 60).toString().padStart(2, '0')}`;

export const TimeInputWithStepper = ({
  dataTest,
  value,
  onChange,
  step = 1,
  minValue = 0,
  maxValue = 1440, // a number of minutes in a single day
}: Props) => {
  const increment = useCallback(
    () => onChange(value < maxValue ? value + step : value),
    [onChange, value, step, minValue, maxValue]
  );
  const decrement = useCallback(
    () => onChange(value > minValue ? value - step : value),
    [onChange, value, step, minValue, maxValue]
  );

  return (
    <div data-test={dataTest} className={styles.container}>
      <div
        className={value <= minValue ? styles.disabled : styles.stepper}
        onClick={decrement}
        data-test={'stepper-decrement'}
      >
        <MinusIcon animationClass={styles.stepperIcon} />
      </div>
      <div className={styles.value} data-test={'stepper-value'}>
        {getAsTime(value)}
      </div>
      <div
        className={value >= maxValue ? styles.disabled : styles.stepper}
        onClick={increment}
        data-test={'stepper-increment'}
      >
        <PlusIcon animationClass={styles.stepperIcon} />
      </div>
    </div>
  );
};
