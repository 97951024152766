import _ from 'lodash';
import * as React from 'react';
import { useIntl } from 'react-intl';

import { GuideBox } from '../../components/GuideBox/GuideBox';
import { Scrollbar } from '../../components/Scrollbar/Scrollbar';
import { AnyStep, Schedule } from '../schedule-model';

import { AddStep } from './AddStep';
import styles from './SchedulerSteps.pcss';
import { StepBar } from './StepBar';

interface Props {
    schedule: Schedule,
    setSchedule: (schedule: Schedule) => void;
    hasPendingContact?: boolean;
    currentBotId: string;
}

export const SchedulerSteps = ({
    schedule,
    setSchedule,
    hasPendingContact,
    currentBotId
}: Props) => {
    const intl = useIntl();
    const guideBoxSteps = [
        {
            header: intl.messages['scheduler.guideBox.stepOne.header'] as string,
            content: intl.messages['scheduler.guideBox.stepOne.content'] as string
        },
        {
            header: intl.messages['scheduler.guideBox.stepTwo.header'] as string,
            content: intl.messages['scheduler.guideBox.stepTwo.content'] as string
        },
        {
            header: intl.messages['scheduler.guideBox.stepThree.header'] as string,
            content: intl.messages['scheduler.guideBox.stepThree.content'] as string
        },
    ];

    const onStepChange = (newStepValue: AnyStep, changedIndex: number) => {
        setSchedule({
            ...schedule,
            interactionPlan: schedule.interactionPlan.map((step, index) => index === changedIndex ? newStepValue : step)
        });
    };

    const addStep = (newStep: AnyStep) => {
        setSchedule({
            ...schedule,
            interactionPlan: [...((schedule && schedule.interactionPlan) || []), newStep]
        });
    };

    const removeStep = (changedIndex: number) => {
        setSchedule({
            ...schedule,
            interactionPlan: schedule.interactionPlan.filter((item, index) => index !== changedIndex)
        });
    };

    return (
        <div className={styles.bg}>
            <AddStep onAddStep={addStep} />
            <Scrollbar className={styles.scrollContainer} >
            <GuideBox
                steps={guideBoxSteps}
                currentBotId={currentBotId}
            />
                {
                    schedule && schedule.interactionPlan &&
                    schedule.interactionPlan.map((step, index) => {
                        return (
                            <StepBar key={index} stepIndex={index} dataTest={`step-${index}`} step={step}
                            onStepRemove={() => !hasPendingContact && removeStep(index)} 
                            onStepChange={newStepValue => onStepChange(newStepValue, index)}
                            disableRemove={hasPendingContact}/>
                        );
                    })
                }
                
            </Scrollbar>
        </div>
    );
};
