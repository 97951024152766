import * as React from 'react';
import { ElementWithLabel } from '../../components/ElementWithLabel/ElementWithLabel';
import styles from './SetVariable.pcss';
import { StepElementProps } from './StepElementProps';
import { Variables } from './variables/Variables';
import { useVariableCreation } from './variables/useVariableCreation';
import { TextAreaWithVariables } from '../../components/TextArea/TextAreaWithVariables';
import { SurveyContext, SurveyContextType } from '../SurveyCreator';

type Props = {
    variable: string,
    value: string
};

export const SetVariable = (props: StepElementProps<Props>) => {
    const { variables } = React.useContext<SurveyContextType>(SurveyContext);

    const handleVariableChange = (value: string) => props.onChange({
        ...props.question,
        variable: value
    });
    const handleValueChange = (value: string) => props.onChange({
        ...props.question,
        value
    });
    const { selectedOption, options, handleSelect, handleRename, handleCreate } = useVariableCreation(handleVariableChange, props.question.variable)

    return (
        <div className={styles.container}>
            <ElementWithLabel
                labelId={`survey-creator.setVariableStep.variable`}
                borderEnabled={false}
                className={styles.input}
                dataTest={`set-variable-variable`}
                inner
            >
                <Variables
                    selectedOption={selectedOption}
                    options={options}
                    handleSelect={handleSelect}
                    handleRename={handleRename}
                    handleCreate={handleCreate}
                />
            </ElementWithLabel>
            <ElementWithLabel inner className={styles.input} labelId="survey-creator.setVariableStep.value">
                <TextAreaWithVariables
                    dataTest="set-variable-value"
                    value={props.question.value}
                    availableVariables={variables.get()}
                    onChange={handleValueChange}
                />
            </ElementWithLabel>
        </div>
    );
};
