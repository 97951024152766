import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from '../../../components/Box/Box';
import { Button } from '../../../components/Button/Button';
import { InputWithLabel } from '../../../components/InputWithLabel/InputWithLabel';
import { Row } from '../../../components/Row/Row';
import { FormProps } from '../../../forms/types';
import { HelpPopup } from '../components/HelpPopup';
import loginStyles from '../components/Login.pcss';

export type SetNewPasswordFormProps = FormProps & {
  setNewPasswordDescription?: string;
}

export const SetNewPasswordForm: React.FC<SetNewPasswordFormProps> = ({
  fieldsProps,
  onSubmit,
  onFormSubmit,
  hasErrors,
  setNewPasswordDescription,
  isSubmitting
}) => {
  const Popup = () => <HelpPopup messageId={'auth.goBack.signIn.setNewPasswordHelp'} />
  return <Box noPadding>
    <form onSubmit={onFormSubmit}>
      {
        setNewPasswordDescription &&
        <p className={loginStyles.loginDescription}>{setNewPasswordDescription}</p>
      }
      <InputWithLabel
        className={loginStyles.loginInput}
        iconClass={loginStyles.helpPopupIcon}
        errorClass={loginStyles.newPasswordError}
        type="password"
        Icon={Popup}
        {...fieldsProps.newPassword} />
      <InputWithLabel
        className={loginStyles.loginInput}
        type="password"
        {...fieldsProps.repeatNewPassword} />
      <Row className={loginStyles.row}>
        <Button
          buttonType={isSubmitting ? 'loading' : 'primary'}
          dataTest="setNewPasswordButton"
          disabled={!(Boolean(fieldsProps.newPassword.value) && Boolean(fieldsProps.repeatNewPassword.value)) ||
            hasErrors}
          type="submit"
        >
          {!isSubmitting && <FormattedMessage id="auth.newPassword.header" />}
        </Button>
      </Row>
    </form>
  </Box>;
};