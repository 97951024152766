import * as React from 'react';
import {useRef, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import uuid from 'uuid';

import addIcon from '../../images/add-icon.svg';

import styles from './AddStepButton.pcss';
import {AddStepMenu} from './AddStepMenu';
import {useOutsideClick} from './useOutsideClick';
import {questionFactory} from './questions-factory';
import {BlockQuestion} from '../model';

type Props = {
    onAdd: (questionToAdd: BlockQuestion) => void;
    disabled?: boolean;
    allowedQuestionTypes?: string[];
    buttonLabel?: string;
}

const preventDefault = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
};

export const AddStepButton = (props: Props) => {
    const [isOpened, changeOpen] = useState<boolean>(false);
    const optionsRef = useRef(null);
    const outsideClick = useOutsideClick(optionsRef, () => closeDropdown());
    const intl = useIntl();

    const handleClick = () => {
        if (!props.disabled) {
            changeOpen(true);
            outsideClick.addHandler();
        }
    };
    const closeDropdown = () => {
        changeOpen(false);
    };
    const handleAdd = (type: string, e: any) => {
        preventDefault(e);
        closeDropdown();
        props.onAdd({
            ...questionFactory[type](intl.messages),
            id: uuid.v4(),
            name: `${intl.messages[`survey-creator.defaultNamePrefix.${type}`]}`
        });
    };
    
    return (
        <div className={styles.addStepContainer} ref={optionsRef}>
            <div tabIndex={0} data-test="add-question-button" className={styles.addStepButton} onClick={handleClick}>
                <img src={addIcon} alt="add" className={styles.plusIcon}/>
                <FormattedMessage id={props.buttonLabel ? props.buttonLabel : `survey-creator.addQuestionButton`}/>
            </div>
            {isOpened && (
                <div className={styles.options}>
                    <AddStepMenu
                        onStepTypeClick={handleAdd} allowedQuestionTypes={props.allowedQuestionTypes}
                    />
                </div>
            )}
        </div>
    );
};
