import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';
import _ from 'lodash';

import { Input } from '../../components/Input';
import { PasswordInput } from '../../components/PasswordInput/PasswordInput';
import { OrganizationSMSSettings } from './SMSSettings';
import { getDataTestKey, getFormKey } from './SMSSettingsFormUtils';

import styles from './SMSSettingsForm.pcss';

type ParlanceSMSSettingsFormFieldsProps = {
  formik: ReturnType<typeof useFormik<OrganizationSMSSettings>>;
  keyPrefix?: string;
};

export function ParlanceSMSSettingsFormFields({
  formik,
  keyPrefix = '',
}: ParlanceSMSSettingsFormFieldsProps) {
  return (
    <>
      <div>
        <label
          htmlFor={getFormKey(
            'providerConfiguration.applicationGuid',
            keyPrefix
          )}
          className={styles.label}
        >
          <FormattedMessage id="organization.sms.parlance.applicationGuid" />
        </label>
        <Input
          className={styles.input}
          id={getFormKey('providerConfiguration.applicationGuid', keyPrefix)}
          name={getFormKey('providerConfiguration.applicationGuid', keyPrefix)}
          dataTest={getDataTestKey('application-guid-input', keyPrefix)}
          onChange={(value) =>
            formik.handleChange({
              target: {
                name: getFormKey(
                  'providerConfiguration.applicationGuid',
                  keyPrefix
                ),
                value,
              },
            })
          }
          value={_.get(
            formik.values,
            getFormKey('providerConfiguration.applicationGuid', keyPrefix)
          )}
        />
      </div>
      <div>
        <label
          htmlFor={getFormKey('apiKey', keyPrefix)}
          className={styles.label}
        >
          <FormattedMessage id="organization.sms.parlance.apiKey" />
        </label>
        <PasswordInput
          id={getFormKey('providerConfiguration.apiKey', keyPrefix)}
          name={getFormKey('providerConfiguration.apiKey', keyPrefix)}
          dataTest={getDataTestKey('api-key-input', keyPrefix)}
          onChange={(value) =>
            formik.handleChange({
              target: {
                name: getFormKey('providerConfiguration.apiKey', keyPrefix),
                value,
              },
            })
          }
          value={_.get(
            formik.values,
            getFormKey('providerConfiguration.apiKey', keyPrefix)
          )}
        />
      </div>
      <div>
        <label
          htmlFor={getFormKey('providerConfiguration.baseUrl', keyPrefix)}
          className={styles.label}
        >
          <FormattedMessage id="organization.sms.parlance.baseUrl" />
        </label>
        <Input
          className={styles.input}
          id={getFormKey('providerConfiguration.baseUrl', keyPrefix)}
          name={getFormKey('providerConfiguration.baseUrl', keyPrefix)}
          dataTest={getDataTestKey('base-url-input', keyPrefix)}
          onChange={(value) =>
            formik.handleChange({
              target: {
                name: getFormKey('providerConfiguration.baseUrl', keyPrefix),
                value,
              },
            })
          }
          value={_.get(
            formik.values,
            getFormKey('providerConfiguration.baseUrl', keyPrefix)
          )}
        />
      </div>
    </>
  );
}
