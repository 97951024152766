import _ from 'lodash';
import * as React from 'react';
import {useEffect} from 'react';

import {BasicMessage, ConditionalMessage, isConditionalMessage, Message, QuestionError} from '../../model';

import {findSource} from './conditional/conditional-messages-utils';
import {ConditionalMessageComponent} from './conditional/ConditionalMessages';
import {populateValue} from './messages-utils';
import {SingleMessageComponent} from './SingleMessage';
import {useAvailableConditionalMessage} from './useAvailableConditionalMessages';

type Props = { 
    message: Message; 
    dataTestPrefix: string; 
    labelId: string; 
    title?: string;
    onChange: (message: Message) => void;
    onDelete?: () => void;
    nested?: boolean;
    isLocalIntent?: boolean;
    errors?: QuestionError[];
    path?: string;
    bypassOnChangeFromContext?: boolean;
};

export const MessageComponent = ((props: Props) => {
    const sourcesForConditionalMessage = useAvailableConditionalMessage();
    const isConditional = _.has(props.message, 'values');

    useEffect(() => {
        if (needUpdate()) {
            props.onChange(conditionalMessageWithNewValues());
        }
    }, []);

    const needUpdate = () => {
        if (!isConditionalMessage(props.message)) {
            return false;
        }
        const message = props.message;
        const referencedSource = findSource(sourcesForConditionalMessage, message.name);
        return referencedSource?.possibleValues.some(value => !(value.id in message.values));
    }

    const disableConditional = () => props.onChange({text: Object.values<BasicMessage>(_.get(props.message, 'values', {}))[0]?.text});
    const enableConditionalForVariable = (variableName: { id: string, key: string }) => {
        const question = sourcesForConditionalMessage.find(s => s.variable.id === variableName.id);
        const text = _.get(props.message, 'text');
        const values = populateValue(question.defaultValues, text);
        props.onChange({
            name: question.variable.id,
            values
        });
    }

    const onMessageChange = (message: Message) => {
        const messageName = _.get(message, 'name');
        const variable = {
            id: messageName,
            key: messageName
        };
        const firstConditionSelection = !isConditional && _.get(message, 'values');
        if (variable.id) {
            firstConditionSelection ? enableConditionalForVariable(variable) : props.onChange(message);
        } else {
            disableConditional();
        }
    };

    const conditionalMessageWithNewValues = (): ConditionalMessage => {
        const message = props.message as ConditionalMessage;
        const referencedSource = findSource(sourcesForConditionalMessage, message.name);
        if (!referencedSource) {
            return message;
        }
        const added = referencedSource.possibleValues.filter(value => !(value.id in message.values));
        const toAdd = added.reduce((acc, curr) => ({
            ...acc,
            [curr.id]: {text: message.values?.default?.text || ''}
        }), {});
        return {
            ...message,
            values: {
                ...message.values,
                ...toAdd
            }
        };
    }
    
    return (
      <div>
          {isConditional ? (
            <ConditionalMessageComponent
              {...props}
              onChange={onMessageChange}
              message={conditionalMessageWithNewValues()}
              availableConditionalSources={sourcesForConditionalMessage}
              errors={props?.errors}
            />
          ) : (
            <SingleMessageComponent
              {...props}
              message={props.message as BasicMessage}
              onConditionChange={sourcesForConditionalMessage.length > 0 && onMessageChange}
              standalone={!props.nested}
              errorMessage={props?.errors && props?.errors[0]?.error}
            />
          )}
      </div>
    )
});
