import {useLayoutEffect, useRef, useState} from 'react';

export const useFixedPosition = (observed: any[], offset: {top: number, left: number}) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [top, setTop] = useState(0);
    const [left, setLeft] = useState(0);
    const [scroll, setScroll] = useState(null);

    const setPosition = (topPosition, leftPosition) => {
        setTop(topPosition);
        setLeft(leftPosition);
    }

    useLayoutEffect(() => {
        const calculatePosition = () => {
            if (!contentRef.current) {
                setPosition(0, 0);
                return;
            }
            if (!scroll) {
                setScroll(contentRef.current.closest('div[style*="overflow: scroll"]'));
            }
            if (scroll) {
                scroll.addEventListener('scroll', calculatePosition);
            }

            setPosition(
                contentRef.current.getBoundingClientRect().top + offset.top,
                contentRef.current.getBoundingClientRect().left + offset.left
            );
        };

        calculatePosition();
        window.addEventListener('resize', calculatePosition);


        return () => {
            window.removeEventListener('resize', calculatePosition);
            if (scroll) {
                scroll.removeEventListener('scroll', calculatePosition);
            }

        }
    }, [...observed, contentRef.current, contentRef.current != null && contentRef.current.getBoundingClientRect(), offset.left, offset.top]);

    return {
        contentRef,
        style: {top, left}
    };
};
