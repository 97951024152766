import {combineReducers} from 'redux';
import {StateType} from 'typesafe-actions';

import {surveyCreator} from '../surveyCreator/store/reducer';
import {surveyConfig} from '../views/Settings/store/reducer';

import {bots} from './bots';
import {currentUser} from './currentUser';
import {namesDictionary} from './namesDictionary';

export const reducers = combineReducers({
  namesDictionary,
  currentUser,
  bots,
  surveyConfig,
  surveyCreator
});

export type RootState = StateType<typeof reducers>;
