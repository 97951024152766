import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Value } from '../../../components/Select/Select';

import { GlobalReadOrderType } from '../types';

type GlobalReadOrderReturnType = [boolean, () => void, Value[], Value, (v: Value) => void];

function useGlobalReadOrder(order: GlobalReadOrderType, onChange: (order: GlobalReadOrderType) => void): GlobalReadOrderReturnType {
    const intl = useIntl();

    const allOptions: Record<GlobalReadOrderType.ORIGINAL | GlobalReadOrderType.RANDOM, string> = useMemo(() => ({
        // [GlobalReadOrderType.NONE]: '',
        // Note messages use modelValue (API) style keys
        [GlobalReadOrderType.ORIGINAL]: intl.messages['survey-creator.word.readAnswerType.SEQUENCE'] as string,
        [GlobalReadOrderType.RANDOM]: intl.messages['survey-creator.word.readAnswerType.PERMUTATE'] as string,
    }), []);

    const readOrderOptions: Value[] = useMemo(() => {
        return Object.keys(allOptions).map((key: GlobalReadOrderType) => ({ id: key, name: allOptions[key] }));
    }, [allOptions]);

    const readOrderOptionValue: Value = { id: order, name: allOptions[order] }

    const isEnabled = useMemo(() => {
        return order !== GlobalReadOrderType.NONE;
    }, [order]);

    const handleReadOrderToggle = useCallback(() => {
        if (isEnabled) {
            return onChange(GlobalReadOrderType.NONE);
        }
        return onChange(GlobalReadOrderType.ORIGINAL);
    }, [isEnabled, onChange]);

    const handleReadOrderChange = useCallback((value: Value) => onChange(value.id), [onChange]);

    return useMemo(() => [
        isEnabled,
        handleReadOrderToggle,
        readOrderOptions,
        readOrderOptionValue,
        handleReadOrderChange
    ], [isEnabled, handleReadOrderToggle, readOrderOptions, readOrderOptionValue, handleReadOrderChange]);
}

export default useGlobalReadOrder;
