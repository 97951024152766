import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useMemo } from 'react';

import { Box } from '../../components/Box/Box';
import { ElementWithLabel } from '../../components/ElementWithLabel/ElementWithLabel';
import { TooltipComponent } from '../../components/Tooltip/Tooltip';
import { CopyIcon } from '../../icons/CopyIcon';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { IconButton } from '../../components/IconButton/IconButton';

import styles from './SaveAs.pcss';
import { StepElementProps } from './StepElementProps';
import { Variables } from './variables/Variables';
import { useVariableCreation } from './variables/useVariableCreation';

type Props = { saveTo?: string };

export const SaveAs = ({ question, onChange }: StepElementProps<Props>) => {
  const [copied, setCopied] = React.useState(false);
  const intl = useIntl();

  const setSaveAs = (value?: string) => onChange({ ...question, saveTo: value });

  const { selectedOption, options, handleSelect, handleRename, handleCreate } = useVariableCreation(setSaveAs, question.saveTo);

  const onCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  }

  const selectedVariableName = useMemo(
    () => options.find(o => o.id === question.saveTo)?.name,
    [options, question.saveTo],
  );

  return (
    <ElementWithLabel
      borderEnabled
      labelId={'survey-creator.saveAs.label'}
      dataTest={'save-as-toggle'}
    >
      <ElementWithLabel labelId={'survey-creator.saveAs.inputDescription'} inner noPadding data-test={'variable-select'}>
        <Box className={styles.inputDescription}>
          {Boolean(question.saveTo) && (
            <FormattedMessage id={'survey-creator.saveAs.inputLabel'} />
          )}
        </Box>
        <div className={styles.variablesSelectorContainer}>
          <Variables
            className={styles.variables}
            isOnlyChild={!Boolean(question.saveTo)}
            selectedOption={selectedOption}
            options={options}
            handleSelect={handleSelect}
            handleRename={handleRename}
            handleCreate={handleCreate}
          />
          {Boolean(question.saveTo) && (
            <>
              <TooltipComponent
                hideArrow={false}
                tooltipText={intl.messages['survey.config.tabs.api.copy'] as string}
              >
                <CopyToClipboard text={selectedVariableName} onCopy={onCopy}>
                  <IconButton
                    dataTest="save-as-variable-copy-to-clipboard"
                    Icon={CopyIcon}
                  />
                </CopyToClipboard>
              </TooltipComponent>
              <TooltipComponent
                hideArrow={false}
                tooltipText={intl.messages['menuOption.delete.tooltip'] as string}
              >
                <IconButton
                  dataTest="save-as-variable-remove"
                  Icon={DeleteIcon}
                  onClick={() => handleSelect(undefined)}
                />
              </TooltipComponent>
            </>
          )}
          {copied && <div className={styles.copied}>{intl.messages['survey.config.tabs.api.copied']}</div>}
        </div>
      </ElementWithLabel>

    </ElementWithLabel>
  );
};