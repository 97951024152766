import React from 'react';

import {IconProps} from './iconTypes';

export function AddPurpleIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h20v20H0z"/>
                <g className={props.animationClass} stroke="#3C38BC" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.458">
                    <path d="M4.532 10h10.937M10 4.532v10.937"/>
                </g>
            </g>
        </svg>
    );
}
