import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import { TrainData } from '../../learning/DuplicateTrainDataPopup';
import { StoppableInterval } from '../../learning/StoppableInterval';
import { Intent } from '../../surveyCreator/SurveyCreator';
import { useCurrentBot } from '../../utils/CurrentBot';
import { HttpClient } from '../../utils/HttpClient';
import { processUserInputForIntent } from '../../utils/userInput';
import { TrainingStatus } from '../TrainingStatus/TrainingStatus';

const MAX_INTENT_TRAINING_STATUS_CHECK_TIME = 1000 * 60 * 60;

export const useTrainingStatusProps = () => {
  const [trainingStatus, setTrainingStatus] = useState<TrainingStatus>('IDLE');
  const currentBot = useCurrentBot();

  async function checkIntentTrainingStatus(): Promise<void> {
    await HttpClient.get({ path: `/bots/${currentBot.id}/train-status`, params: { stage: 'prod' } })
      .then(response => response.data)
      .then((status: { training: boolean }) =>
        setTrainingStatus(status.training ? 'TRAINING' : 'IDLE')
      )
      .catch(() => setTrainingStatus('ERROR'));
  }

  useEffect(() => {
    const interval = new StoppableInterval()
      .withFireInterval(2000)
      .withHandler(() => checkIntentTrainingStatus())
      .withStopTimeout(MAX_INTENT_TRAINING_STATUS_CHECK_TIME, () =>
        setTimeout(() => setTrainingStatus('DISABLED'), 2000)
      );
    interval.start();

    return () => {
      interval.stop();
    }
  }, [])

  return { trainingStatus };
}

export const useTrainingBarProps = () => {
  const currentBot = useCurrentBot();

  const [intents, setIntents] = useState<Intent[]>([]);
  const [intentSuggestions, setIntentSuggestions] = useState<Intent[]>([]);

  const [intentInput, setIntentInput] = useState('');
  const [recognizedIntent, setRecognizedIntent] = useState<string | undefined>();
  const [confidence, setConfidence] = useState<number | undefined>();

  const [selectedIntent, setSelectedIntent] = useState('');

  const [isAddMultipleModalOpen, setIsAddMultipleModalOpen] = useState(false);
  const onOpenAddMultipleModal = () => setIsAddMultipleModalOpen(true)
  const onCloseAddMultipleModal = () => setIsAddMultipleModalOpen(false)


  function fetchIntents() {
    return HttpClient.get({ path: `/bots/${currentBot?.id}/intents` })
      .then(response => response.data)
      .then(intentsFromResponse => {
        setIntents(intentsFromResponse);
        setIntentSuggestions(intentsFromResponse);
      });
  }

  useEffect(() => {
    fetchIntents();
  }, [currentBot.id]);

  async function showCurrentIntents(value: string) {
    const response = await HttpClient.get({
      path: `/bots/${currentBot.id}/parse-intent`,
      params: { sentence: value, stage: 'prod' }
    });

    const recognizedIntentKey = response.data.intent;
    const foundIntent = intents.find((intent) => intent.name === recognizedIntentKey);
    setRecognizedIntent(foundIntent?.displayName || recognizedIntentKey);
    setConfidence(response.data.confidence);
  }

  const debouncedIntentRecognition = useCallback(debounce(showCurrentIntents, 300), [intents]);

  const onChangeValue = (value: string) => {
    setIntentInput(value);
    if (value.length > 0) {
      debouncedIntentRecognition(value);
    } else {
      setRecognizedIntent(undefined);
      setConfidence(undefined);
    }
  }

  async function onTrain() {
    await HttpClient.post({
      path: `/bots/${currentBot.id}/train`,
      body: { stage: 'prod' }
    });
  }

  function processTextInput(userInput: string): string {
    return processUserInputForIntent(userInput).join('');
  }

  const addSentences = async (sentences: TrainData[]) => {
    await HttpClient.post({
      path: `/bots/${currentBot.id}/rasa-sentences`,
      body: {
        trainData: sentences.map(sentence => ({
          ...sentence,
          text: processTextInput(sentence.text)
        }))
      }
    });
    fetchIntents();
    setIntentInput('');
    setSelectedIntent('');
    setRecognizedIntent(undefined);
    setConfidence(undefined);
  }

  async function onAddUtterance() {
    const matchingIntent = intents.find(it => it.displayName === selectedIntent);
    const matchingIntentName = matchingIntent ? matchingIntent.name : selectedIntent;
    if (!matchingIntentName || !intentInput) {
      return;
    }

    addSentences([{ text: processTextInput(intentInput), intent: matchingIntentName }]);
  }

  return {
    intentSuggestions,
    onChangeValue,
    intentInput,
    confidence,
    recognizedIntent,
    onAddUtterance,
    onOpenAddMultipleModal,
    onTrain,
    setSelectedIntent,
    selectedIntent,
    addSentences,
    isAddMultipleModalOpen,
    onCloseAddMultipleModal
  }
}