import React, { useCallback, useMemo, useRef } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { FormattedMessage } from 'react-intl';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef, RowSelectionState,
} from '@tanstack/react-table';
import styles from './Table.pcss';
import { Scrollbar } from '../Scrollbar/Scrollbar';
import { Button } from '../Button/Button';
import { Loader } from '../Loader';
import cx from 'classnames';

type TableProps = {
  data: Record<string, any>[];
  columns: ColumnDef<any>[];
  isLoading?: boolean;
  isClickable?: boolean;
  hasMore?: boolean;
  loadMore?: (...args: any[]) => any
  selectedRows?: RowSelectionState;
  getRowId?: (row: any) => string;
  selectType?: 'EXCLUDED' | 'ACTIVE';
};

const getInnerWindowHeight = (horizontalScrollbar?: Scrollbars) => `${horizontalScrollbar ? horizontalScrollbar.getClientHeight() - 50 : 0}px`;

const getVerticalTrack = (scrollbar: any): HTMLDivElement => scrollbar && scrollbar.trackVertical ? scrollbar.trackVertical : undefined;

export const Table = ({
  data,
  columns,
  isLoading,
  isClickable,
  hasMore,
  loadMore,
  selectedRows,
  getRowId,
  selectType,
}: TableProps) => {
  const scrollTopPositionRef = useRef(0);
  const horizontalScrollbar = useRef<Scrollbars>();
  const verticalScrollbar = useRef<Scrollbars>();
  const verticalScrollbarContainer = useRef<HTMLDivElement>();
  const loadMoreContainer = useRef<HTMLDivElement>();

  const style = useMemo(() => ({ overflowY: 'hidden' }) as any, []);

  const table = useReactTable({
    data,
    columns,
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 200,
      minSize: 50,
      maxSize: 500,
    },
    ...(selectedRows && getRowId && {
      state: {
        rowSelection: selectedRows,
      },
      getRowId,
    }),
  });

  const onScrollVertical = (scrollTop) => {
    if (scrollTop !== scrollTopPositionRef.current) {
      scrollTopPositionRef.current = scrollTop;
    }
  };

  const onScroll = useCallback((scrollTop, scrollLeft) => {
    const verticalTrack = getVerticalTrack(verticalScrollbar.current);
    if (verticalTrack) {
      verticalTrack.style.left = `${scrollLeft + horizontalScrollbar.current.getClientWidth() - 15}px`;
    }
    if (verticalScrollbarContainer.current && horizontalScrollbar.current) {
      verticalScrollbarContainer.current.style.height = getInnerWindowHeight(horizontalScrollbar.current);
    }
    if (loadMoreContainer.current) {
      loadMoreContainer.current.style.marginLeft = `${scrollLeft}px`;
      loadMoreContainer.current.style.width = `${horizontalScrollbar.current.getClientWidth()}px`;
    }
  }, [verticalScrollbarContainer.current, loadMoreContainer.current, verticalScrollbar.current]);

  const verticalScrollRef = (ref) => {
    verticalScrollbar.current = ref;
    if (verticalScrollbar.current) {
      verticalScrollbar.current.scrollTop(scrollTopPositionRef.current);
    }
  };

  return (
    <Scrollbar ref={horizontalScrollbar} style={style} onScroll={onScroll} className={styles.container}>
      <table data-test="table" className={styles.table}>
        <thead className={styles.tableHead}>
          {table.getHeaderGroups().map(headerGroup => (
            <tr className={styles.headTr} key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th
                  className={styles.th}
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width: header.getSize(),
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}

                  <div
                    onDoubleClick={() => header.column.resetSize()}
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                    className={cx(styles.resizer, { [styles.isResizing]: header.column.getIsResizing() })}
                  />
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          <div
            ref={verticalScrollbarContainer}
            style={{ height: getInnerWindowHeight(horizontalScrollbar.current), width: '100%' }}
          >
            <Scrollbar ref={verticalScrollRef} hideHorizontal={true} onScroll={onScrollVertical}>
              {table.getRowModel().rows.map(row => (
                <tr
                  className={cx(styles.tr, {
                    [styles.isClickable]: isClickable,
                    [styles.isExcluded]: row.getIsSelected() && selectType === 'EXCLUDED',
                    [styles.isActive]: row.getIsSelected() && selectType === 'ACTIVE',
                  })}
                  key={row.id}
                >
                  {row.getVisibleCells().map(cell => (
                    <td
                      className={styles.td}
                      key={cell.id}
                      style={{
                        width: cell.column.getSize(),
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}

              {(hasMore || isLoading) && (
                <div ref={loadMoreContainer} className={styles.loadMoreContainer}>
                  {!isLoading && (
                    <Button onClick={loadMore} dataTest="load-more-button">
                      <FormattedMessage id="callsList.loadMore" />
                    </Button>
                  )}
                  {isLoading && <Loader dataTest="list-loader"/>}
                </div>
              )}
            </Scrollbar>
          </div>
        </tbody>
      </table>
    </Scrollbar>
  );
}
