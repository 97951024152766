import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import InputMask from 'react-input-mask';

import {TimeOfDay} from '../schedule-model';

import styles from './TimeOfDayInput.pcss';

type Props = {
    readonly timeOfDay?: TimeOfDay;
    onTimeOfDayChange: (hours: TimeOfDay) => void;
};

function printHour(timeOfDay: TimeOfDay) {
    return timeOfDay && timeOfDay.hours !== undefined
        ? `${timeOfDay.hours < 10 ? '0' : ''}${timeOfDay.hours}`
        : '--';
}

function printMinutes(timeOfDay: TimeOfDay) {
    return timeOfDay && timeOfDay.minutes !== undefined
        ? `${timeOfDay.minutes < 10 ? '0' : ''}${timeOfDay.minutes}`
        : '--';
}

const getPrintValue = (timeOfDay: TimeOfDay) => timeOfDay !== undefined ? `${printHour(timeOfDay)}:${printMinutes(timeOfDay)}` : '';

export const TimeOfDayInput = ({timeOfDay, onTimeOfDayChange}: Props) => {
    const [value, setValue] = useState(getPrintValue(timeOfDay));

    useEffect(() => {
        setValue(getPrintValue(timeOfDay));
    }, [timeOfDay]);

    const onBlur = useCallback(() => {
        const oldValue = getPrintValue(timeOfDay);
        const parsedValue = parseNewValue(oldValue, value);

        const newValue = parsedValue
            .replace(/(?<digit>[0-9])_/g, (substring, ...rest) => `0${rest[3].digit}`)
            .replace(/_(?<digit>[0-9])/g, (substring, ...rest) => `0${rest[3].digit}`)
            .replace(/_/g, '0');

        setValue(newValue);

        const change = extractTime(newValue);
        onTimeOfDayChange(change);
    }, [value, timeOfDay, onTimeOfDayChange]);

    return (
        <InputMask
            data-test="time-of-day"
            className={styles.input}
            type="text"
            value={value}
            mask="99:99"
            maskChar="_"
            placeholder={':'}
            onBlur={onBlur}
            onChange={event => {
                setValue(event.target.value);
            }}
        />
    );
};

const hourRegex = /(..):(..)$/;

function parseNewValue(oldValue: string, newValue: string) {
    const match = newValue.match(hourRegex);
    if (!match) {
        return oldValue;
    }
    return newValue;
}

const extractTime = (input: string): TimeOfDay => {
    const match = input.match(hourRegex);
    return {
        hours: parseInt(match[1], 10),
        minutes: parseInt(match[2], 10),
    };
};
