import moment, {Moment} from 'moment-timezone';

let nowMoment: Moment;

export const now = () => {
	return moment(nowMoment) || moment();
};

export const nowInTimezone = (timezone: string) => {
	return moment.tz(nowMoment, timezone) || moment.tz(timezone);
};

export const setNow = (givenMoment: Moment) => {
	nowMoment = givenMoment;
};
