import React from 'react';

import {IconProps} from './iconTypes';

export function GroupIcon(props: IconProps & { fill?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-296 -595) translate(256 587) translate(40 8)">
              <path d="M0 0H24V24H0z"/>
              <path className={props.animationClass}  fill={props.fill || '#919AAF'} d="M15.796 21.477c2.872 0 5.204-2.331 5.204-5.204 0-2.872-2.332-5.204-5.204-5.204-2.873 0-5.204 2.332-5.204 5.204 0 2.873 2.331 5.204 5.204 5.204zm0-1.186c-2.217 0-4.018-1.8-4.018-4.018 0-2.217 1.8-4.017 4.018-4.017 2.217 0 4.017 1.8 4.017 4.017 0 2.218-1.8 4.018-4.017 4.018zm-6.52-2.947c-.22.218-.573.218-.792 0L4.351 13.21c-.105-.105-.164-.248-.164-.396 0-.148.059-.29.164-.396L12.42 4.35c.105-.104.247-.163.396-.163h4.132c.31 0 .56.25.56.56V8.88c0 .148-.06.29-.164.395-.231.232-.231.608 0 .84.232.23.608.23.84-.001.327-.328.51-.771.51-1.234V4.746c0-.964-.781-1.746-1.745-1.746h-4.133c-.464 0-.908.184-1.235.511l-8.07 8.07c-.327.327-.511.77-.511 1.234 0 .463.184.908.512 1.235l4.133 4.133c.682.682 1.787.682 2.47 0 .231-.232.231-.608 0-.84-.232-.23-.608-.23-.84 0zm5.926-1.664v-1.136c0-.327.266-.593.594-.593.327 0 .593.266.593.593v1.136h1.136c.327 0 .593.266.593.593 0 .328-.266.594-.593.594h-1.136v1.135c0 .328-.266.594-.593.594-.328 0-.594-.266-.594-.594v-1.135h-1.135c-.328 0-.594-.266-.594-.594 0-.327.266-.593.594-.593h1.135zm.063-10.085c.466.023.836.409.836.88 0 .487-.395.882-.882.882-.486 0-.881-.395-.881-.882s.395-.881.881-.881h.046z"/>
            </g>
          </g>
        </g>
      </g>
    </svg>

  );
}
