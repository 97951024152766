import classNames from 'classnames';
import React, {useEffect, useRef, useState} from 'react';

import styles from './Loader.pcss';

export function Loader({
    className,
    delay,
    dataTest = 'loader',
    ...props
}: React.HTMLAttributes<HTMLDivElement> & { readonly delay?: number, readonly dataTest?: string}) {
    const delayTimeout = useRef(undefined);
    const [showSpinner, setShowSpinner] = useState(!delay);

    useEffect(() => {
        if (delay) {
            delayTimeout.current = setTimeout(() => {
                setShowSpinner(true);
            }, delay);
        }

        return () => {
            if (delayTimeout.current) {
                clearTimeout(delayTimeout.current);
            }
        };
    });

    if (!showSpinner) {
        return null;
    }

    return (
        <div className={classNames(styles.loader, className)} {...props} data-test={dataTest}>
            <span className={styles.dot} />
            <span className={styles.dot} />
            <span className={styles.dot} />
        </div>
    );
}
