import React from 'react';
import cx from 'classnames';

import styles from './SynonymMenu.pcss';
import { TooltipComponent } from '../../Tooltip/Tooltip';
import { useIntl } from 'react-intl';
import { Synonym } from '../../../surveyCreator/model';

interface Props {
  className?: string;
  onRemove?: React.MouseEventHandler;
  synonym: Synonym;
  onToggle?: (synonym: Synonym) => void;
}

export const SynonymMenu: React.FC<Props> = ({
                                               synonym,
                                               className,
                                               onToggle,
                                               onRemove,
}) => {
  const intl = useIntl();

  return <div className={cx(className, styles.menu)}>
    {onToggle && <TooltipComponent
        tooltipText={intl.formatMessage({
          id: synonym.lemma
            ? 'survey-creator.dictionary.toggleToVerbatim'
            : 'survey-creator.dictionary.toggleToLemma'
        })}>
      <button
          data-test="toggle-synonym"
          className={styles.menuItem}
          onClick={() => onToggle(synonym)}
      >
        <ToggleIcon className={styles.switchIcon}/>
      </button>
    </TooltipComponent>}
    <TooltipComponent tooltipText={intl.formatMessage({
      id: 'survey-creator.dictionary.deleteSynonym'
    })}>
      <button
        data-test="delete-synonym"
        className={styles.menuItem}
        onClick={onRemove}
      >
        <DeleteIcon className={styles.deleteIcon}/>
      </button>
    </TooltipComponent>
  </div>
}

type IconProps = { className?: string };

const ToggleIcon: React.FC<IconProps> = ({className}) => (
  <svg width="20" height="20" viewBox="-4 -4 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
       className={className}>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M4.469 1.766a4.559 4.559 0 0 1 2.73-.104c.9.245 1.701.76 2.295 1.475a.485.485 0 0 1-.066.685.492.492 0 0 1-.69-.066A3.553 3.553 0 0 0 6.94 2.6a3.573 3.573 0 0 0-2.14.082 3.548 3.548 0 0 0-1.704 1.29A3.513 3.513 0 0 0 2.45 5.92l.632-.629a.492.492 0 0 1 .693 0 .484.484 0 0 1 0 .689L2.32 7.425a.49.49 0 0 1-.723-.001L.143 5.98a.484.484 0 0 1 0-.689.492.492 0 0 1 .693 0l.634.63c.016-.899.303-1.773.825-2.51A4.527 4.527 0 0 1 4.47 1.766zm5.597 3.018a.505.505 0 0 0-.325.101.49.49 0 0 0-.05.045L8.224 6.386a.484.484 0 0 0 0 .688c.191.19.501.19.693 0l.6-.596a3.515 3.515 0 0 1-.58 1.503A3.547 3.547 0 0 1 7.31 9.278a3.573 3.573 0 0 1-3.894-.857.492.492 0 0 0-.692-.02.484.484 0 0 0-.02.688 4.558 4.558 0 0 0 4.968 1.094 4.525 4.525 0 0 0 2.075-1.655c.43-.628.69-1.352.762-2.103l.654.65c.191.19.501.19.693 0a.485.485 0 0 0 0-.689l-1.47-1.46a.49.49 0 0 0-.321-.142z"
          fill="#919AAF"/>
  </svg>
);

const DeleteIcon: React.FC<IconProps> = ({className}) => (
  <svg width="20" height="20" viewBox="-4 -4 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
       className={className}>
    <path opacity=".01" fill="#D8D8D8" d="M0 0h12v12H0z"/>
    <path
      d="M2.186 2.186a.635.635 0 0 1 .826-.062l.071.062L6 5.103l2.918-2.917a.635.635 0 0 1 .959.826l-.062.071L6.897 6l2.917 2.917a.634.634 0 0 1-.826.959l-.071-.062-2.918-2.917-2.916 2.917a.634.634 0 0 1-.959-.826l.062-.071L5.102 6 2.186 3.083a.635.635 0 0 1 0-.897z"
      fill="#919AAF"/>
  </svg>
);
