import * as React from 'react';

import {Label} from '../../components/Label';
import {Toggle} from '../../components/Toggle/Toggle';
import {Schedule} from '../schedule-model';

import styles from './AcceptIncomingCallsSettings.pcss'

type Props = {
    schedule: Schedule, setSchedule: (schedule: Schedule) => void
};

export const AcceptIncomingCallsSettings = ({schedule, setSchedule}: Props) => {

    const onToggled = (value: boolean) => setSchedule({
        ...schedule,
        acceptIncomingCallsOnSurveyIncomplete: value,
    })

    return (
        <div className={styles.container}>
            <div className={styles.toggleContainer}>
                <Toggle on={schedule && schedule.acceptIncomingCallsOnSurveyIncomplete}
                        onToggle={() => onToggled(!schedule.acceptIncomingCallsOnSurveyIncomplete)}
                        data-test={'accept-incoming-calls-toggle'}
                />
                <Label labelId={'scheduler.acceptIncomingCallsSettings.enabled'}/>
            </div>
        </div>
    )
};
