import * as React from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import { ClearIcon } from '../components/ClearIcon/ClearIcon';
import { DebouncedInput } from '../components/DebouncedInput';
import searchIcon from '../images/search-icon.svg';
import useBranding from '../hooks/useBranding/useBranding';
import { BotFilter } from './Dashboard';
import { HeaderContainer } from '../layout/header/HeaderContainer';
import { HeaderLogo } from '../layout/header/HeaderLogo';
import { UserSettingsIcon } from '../layout/header/UserSettingsIcon';

import styles from './DashboardHeader.pcss';
import { HeaderActionContainer } from '../layout/header/HeaderActionContainer';

type DashboardHeaderProps = {
  botFilter: BotFilter;
  onBotFilterChange: (
    value:
      | null
      | BotFilter
      | ((prevState: null | BotFilter) => null | BotFilter)
  ) => void;
};

export const DashboardHeader = ({
  botFilter,
  onBotFilterChange,
}: DashboardHeaderProps) => {
  const { favicon } = useBranding();
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <link
          rel="shortcut icon"
          id="favicon"
          type="image/x-icon"
          href={favicon}
        />
      </Helmet>
      <HeaderContainer>
        <div>
          <HeaderLogo />
        </div>
        <div className={styles.search}>
          <img
            src={searchIcon}
            alt={'search'}
            className={styles.search__icon}
          />

          <DebouncedInput
            width={216}
            debounced={128}
            dataTest="filter-bots-input"
            className={styles.searchInput}
            placeholder={
              intl.messages['dashboard.header.search.placeholder'] as string
            }
            value={botFilter.query}
            onChange={(value) => {
              onBotFilterChange((state) => ({
                ...state,
                query: value,
              }));
            }}
          />
          <ClearIcon
            enabled={!!botFilter}
            onClear={() =>
              onBotFilterChange((state) => ({
                ...state,
                query: '',
              }))
            }
          />
        </div>
        <HeaderActionContainer>
          <UserSettingsIcon />
        </HeaderActionContainer>
      </HeaderContainer>
    </>
  );
};
