import cx from 'classnames';
import React from 'react';

import styles from './IndentedStepContent.pcss';

type IndentedStepContentProps = {
  withBottomMargin?: boolean;
  withTopMargin?: boolean;
  withTopPadding?: boolean;
  forceNoTopMargin?: boolean;
};

export const IndentedStepContent: React.FC<IndentedStepContentProps> = ({
  children,
  withBottomMargin = true,
  withTopMargin = false,
  withTopPadding = false,
  forceNoTopMargin = false
}) => <div
  className={cx(
    styles.indentedStepContent, {
    [styles.withBottomMargin]: withBottomMargin,
    [styles.withTopMargin]: withTopMargin,
    [styles.withTopPadding]: withTopPadding,
    [styles.forceNoTopMargin]: forceNoTopMargin
  }
  )}
>
    {children}
  </div>