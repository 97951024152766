import _ from 'lodash';

import {NotPreciseAnswer, WordAnswerType, WordsDictionary} from '../../../../model';
import {AnswerItem, AnswerType, DisambiguationAnswer, DisambiguationItem} from '../../../types';

import {Model, View} from '../types';
import { BaseModelToViewMapper } from './';

class InitialModelToViewMapper extends BaseModelToViewMapper {
    static getViewValue(model: Model): View {
        return {
            answersList: InitialModelToViewMapper.getAnswersList(model),
            incomprehension: InitialModelToViewMapper.getIncomprehensionReaction(model),
            disambiguation: InitialModelToViewMapper.getDisambiguation(model),
            readOrder: BaseModelToViewMapper.getReadOrder(model),
        };
    }

    static getAnswersList(model: Model): AnswerItem[] {
        return model.dictionary.reduce((acc, dictionaryItem) => {
            if (dictionaryItem.type === WordAnswerType.ENTITY) {
                return [
                    ...acc,
                    {
                        id: dictionaryItem.id,
                        type: WordAnswerType.ENTITY,
                        values: [],
                    },
                ];
            }
            return [...acc, InitialModelToViewMapper.getDictionaryAnswerItem(dictionaryItem, model)];
        }, []);
    }

    static getDisambiguation(model: Model): DisambiguationItem[] {
        return model.notPreciseAnswer.map((notPreciseAnswer) => {
            const answers = InitialModelToViewMapper.getDisambiguationAnswers(notPreciseAnswer, model);
            return {
                message: notPreciseAnswer.message,
                repeatMessages: notPreciseAnswer.repeatMessages,
                answers
            };
        });
    }

    static getDisambiguationAnswers(notPreciseAnswer: NotPreciseAnswer, model: Model): DisambiguationAnswer[] {
        const elementsAnswers = notPreciseAnswer.elements?.map((answerId) => {
            return InitialModelToViewMapper.getDisambiguationAnswer(model.dictionary, answerId);
        }) || [];
        const originalAnswers = notPreciseAnswer.answers?.map((item) => {
            return InitialModelToViewMapper.getDisambiguationAnswer(model.dictionary, item.id);
        }) || [];

        return _.uniqBy([...elementsAnswers, ...originalAnswers], (answer) => answer.id + '-' + answer.key);
    }

    static getDisambiguationAnswer(dictionary: WordsDictionary, answerId: string): DisambiguationAnswer {
        const originalAnswer = dictionary.find(item => item.id === answerId);
        if (originalAnswer.type === WordAnswerType.ENTITY) {
            return { id: originalAnswer.id, key: originalAnswer.key, synonyms: [], type: AnswerType.ENTITY };
        }
        return { id: originalAnswer.id, key: originalAnswer.key, synonyms: originalAnswer.synonyms, type: AnswerType.DICTIONARY }
    }
}

export default InitialModelToViewMapper;
