import React from 'react';

import {IconProps} from './iconTypes';

export function ChangeVariableIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g transform="translate(-244 -831) translate(236 823) translate(8 8)">
                <path d="M0 0H24V24H0z"/>
                <path className={props.animationClass} d="M4.466 12.386v5.249C4.466 19.493 5.976 21 7.84 21h8.32c1.863 0 3.373-1.507 3.373-3.365v-.692c0-.33-.269-.599-.6-.599-.33 0-.6.269-.6.599v.692c0 1.197-.973 2.168-2.173 2.168H7.84c-1.2 0-2.174-.97-2.174-2.168v-5.249l2.443 2.437c.234.234.614.234.848 0 .235-.234.235-.613 0-.847l-3.466-3.458c-.235-.234-.614-.234-.849 0l-3.466 3.458c-.235.234-.235.613 0 .847.234.234.614.234.848 0l2.442-2.437zm13.867-.728L15.891 9.22c-.234-.234-.615-.234-.849 0-.234.234-.234.613 0 .846l3.467 3.459c.234.234.614.234.848 0l3.467-3.459c.234-.233.234-.612 0-.846-.234-.234-.614-.234-.848 0l-2.443 2.437V6.365C19.533 4.507 18.023 3 16.16 3H7.84C5.977 3 4.466 4.507 4.466 6.365v.692c0 .33.27.599.6.599.332 0 .6-.269.6-.599v-.692c0-1.197.974-2.168 2.174-2.168h8.32c1.2 0 2.173.97 2.173 2.168v5.293z"/>
              </g>
            </g>
          </g>
        </g>
      </svg>

    );
}
