import * as React from 'react';
import { useIntl } from 'react-intl';

import { NumberInputWithStepper } from '../../../components/NumberInputWithStepper/NumberInputWithStepper';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { QuestionWithAnswer } from '../../model';
import { StepElementProps } from '../StepElementProps';
import { Button } from '../../../components/Button/Button';
import { Label } from '../../../components/Label';

import styles from './PreSpeechTimeoutField.pcss';

const [DEFAULT_TIMEOUT, MIN_TIMEOUT, MAX_TIMEOUT, STEP] = [6500, 500, 10000, 100];

export const PreSpeechTimeoutField = ({onChange, question}: StepElementProps<QuestionWithAnswer>) => {
  const intl = useIntl();
  const setPreSpeechSilenceTimeout = (value: number) => {
    onChange({...question, preSpeechSilenceTimeout: value});
  }

  const preSpeechSilenceTimeout = question.preSpeechSilenceTimeout || DEFAULT_TIMEOUT;

  return (
    <div className={styles.container} data-test={'pre-speech-silence-timeout'}>
      <div className={styles.label}>
        <Label labelId={'survey-creator.changePreSpeechTimeout'} />
      </div>

      <div className={styles.content}>
        <NumberInputWithStepper
          dataTest={'pre-speech-silence-timeout-value'}
          step={STEP}
          minValue={MIN_TIMEOUT}
          maxValue={MAX_TIMEOUT}
          value={preSpeechSilenceTimeout}
          seconds
          onChange={setPreSpeechSilenceTimeout}
          triggerOnChangeOnMount={false}
        />

        <Button
          Icon={RefreshIcon}
          buttonType="link"
          disabled={preSpeechSilenceTimeout === DEFAULT_TIMEOUT}
          onClick={() => setPreSpeechSilenceTimeout(DEFAULT_TIMEOUT)} dataTest={'pre-speech-silence-timeout-reset'}
        >
          {intl.messages['survey-creator.resetPreSpeechTimeout']}
        </Button>
      </div>
    </div>
  );
};
