import uniq from 'lodash/uniq';
import { Synonym } from '../surveyCreator/model';

const nonSpecialCharacters = /[^\s\d\p{Letter}']/gu;
const duplicateWhiteSpaces = /(\s)\1*/g;

const stringToSynonym = (userInput: string): Synonym => ({
    verbatim: userInput,
})

const cleanupInputLine = line => line
    .replace(nonSpecialCharacters, '')
    .replace(duplicateWhiteSpaces, '$1')
    .trim();

const isBlankLine = line => /[^\s]/.test(line);

const sanitize = (input: string): string[] => input
    .split('\n')
    .map(cleanupInputLine)
    .filter(isBlankLine)
    .filter(it => it.length);

export const processUserInputForIntent = (userInput: string): string[] => {
    return uniq(sanitize(userInput));
}

export const processUserInputForStep = (userInput: string): Synonym[] => {
    return uniq(sanitize(userInput))
        .map(stringToSynonym);
}