import _ from 'lodash';
import * as React from 'react';
import {useContext} from 'react';

import {ElementContainer} from '../../components/ElementContainer/ElementContainer';
import {ElementWithLabel} from '../../components/ElementWithLabel/ElementWithLabel';
import {Input} from '../../components/Input';
import {Label} from '../../components/Label';
import {TextAreaWithVariables} from '../../components/TextArea/TextAreaWithVariables';
import {DeleteIcon} from '../../icons';
import {HttpHeader} from '../model';
import {SurveyContext} from '../SurveyCreator';

import {AddButton} from './AddButton';
import styles from './HttpHeadersComponent.pcss';
import {StepElementProps} from './StepElementProps';


const HttpHeaderComponent = (props: { header: HttpHeader, onChange: (header: HttpHeader) => void; }) => {
  const {variables} = useContext(SurveyContext);
  const availableVariables = variables.getVariablesForCreator();
  const handleChange = (key: string) => (value: string) => {
    props.onChange({
      ...props.header,
      [key]: value,
    });
  };

  return (
    <div data-test="http-header" className={styles.headerElement}>
      <ElementWithLabel labelId={'survey-creator.httpStep.headerName'} noPadding className={styles.headerElementInput}>
        <Input dataTest="name" value={_.isNil(props.header.name) ? '' : props.header.name}
               onChange={handleChange('name')} width={'100%'} />
      </ElementWithLabel>
      <ElementWithLabel labelId={'survey-creator.httpStep.headerValue'} noPadding className={styles.headerElementInput}>
        <TextAreaWithVariables data-test="value" value={_.isNil(props.header.value) ? '' : props.header.value}
               onChange={handleChange('value')} asInput availableVariables={availableVariables} />
      </ElementWithLabel>
    </div>
  );
};

type Props = { headers?: HttpHeader[] };
export const HttpHeadersComponent = ({question, onChange}: StepElementProps<Props>) => {
  const headers = question.headers || [];

  const onHeaderChange = (index: number) => (changeHeader: HttpHeader) => {
    const newHeaders = headers.map((header, id) => id === index ? changeHeader : header);
    onChange({...question, headers: newHeaders});
  };

  const addHeader = () => onChange({...question, headers: [...headers, {name: '', value: ''}]});

  const removeHeader = (index: number) => onChange({...question, headers: headers.filter((value, id) => id !== index)});

  return (
    <ElementContainer>
      <Label labelId={'survey-creator.httpStep.headersLabel'} />
      <div>
        {
          headers.map((header, index) => (
            <div key={index} data-test="http-header-container" className={styles.headerContainer}>
              <HttpHeaderComponent header={header} onChange={onHeaderChange(index)} />
              <div data-test="remove-header-button" onClick={() => removeHeader(index)} className={styles.remove}>
                <DeleteIcon />
              </div>
            </div>
          ))
        }
      </div>
      <div className={styles.addButton}>
        <AddButton onClick={addHeader} dataTest={'add-http-header-button'} labelId={'survey-creator.httpStep.addHeaderButton'} />
      </div>
    </ElementContainer>
  );
};
