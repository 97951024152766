import { useQuery } from 'react-query';
import { HttpClient } from '../utils/HttpClient';
import { ExecutableFunctionDefinition } from './model';

export function useExecutableFunctions() {
    return useQuery(
        'executable-functions',
        () => HttpClient.get({ path: '/executable-functions' })
            .then(result => result.data.executableFunctions as ExecutableFunctionDefinition<any, any>[])
    )
}