import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {allEntityAnswersSelector} from '../../../../selectors/entityDetailsSelectors';
import {useCurrentBot} from '../../../../utils/CurrentBot';
import {fetchEntitiesDetails} from '../../../store/entityDetails/actions';

import {useEntities} from './useEntities';

export const useAllEntitiesValues = (entities: string[]) => {
   const dispatch = useDispatch()
   const currentBot = useCurrentBot();
   const entityDetails = useSelector(allEntityAnswersSelector(entities))
   const { isReady } = useEntities()
   useEffect(() => {
       if (isReady && entities.length > 0) {
            dispatch(fetchEntitiesDetails.request({entities, id: currentBot.id}));
        }
    }, [currentBot.id, isReady, entities])

   return entityDetails
}