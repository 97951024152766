import classNames from 'classnames';
import * as React from 'react';
import {FormattedMessage} from 'react-intl';

import styles from './ToggleDescription.pcss';

export const ToggleDescription = ({descriptionId}: { descriptionId: string }) => {
    return (
        <div className={classNames(styles.description)}>
            <FormattedMessage id={descriptionId}/>
        </div>
    );
};
