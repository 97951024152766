import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { EmptyStateBird } from '../../icons/EmptyStateBird';
import { getBotUrlId, useCurrentBot } from '../../utils/CurrentBot';
import styles from './NoContactsNoSchedule.pcss';

export function NoContactsNoSchedule() {
  const currentBot = useCurrentBot();

  return (
    <div
      className={styles.wrapper}
      data-test="empty-state-no-schedule-no-filters"
    >
      <div className={styles.innerWrapper}>
        <EmptyStateBird animationClass={styles.icon} />
        <p className={styles.text}>
          <FormattedMessage
            id="contacts.noContactsNoSchedule.activateScheduler"
            values={{
              scheduler: (
                <Link to={`/${getBotUrlId(currentBot)}/planer`}>
                  <FormattedMessage id="contacts.noContactsNoSchedule.scheduler" />
                </Link>
              ),
            }}
          />
        </p>
        <p className={styles.text}>
          <FormattedMessage id="contacts.noContactsNoSchedule.nextSteps" />
        </p>
      </div>
    </div>
  );
}
