import React from 'react';
import cx from 'classnames';
import { CommandPaletteContainerComponent } from '@talkie/command-palette/components/types';
import styles from './AnalyticsPaletteContainerComponent.pcss';
import { Scrollbar } from '../../components/Scrollbar/Scrollbar';

export const AnalyticsPaletteContainerComponent: CommandPaletteContainerComponent = ({
  className,
  searchComponent,
  footerComponent,
  children,
  onClick,
}) => (
  <div
    data-test="analytics-palette-container"
    className={cx(
      className,
      styles.analyticsPaletteContainerComponent,
    )}
    onClick={onClick}
  >
    <div className={styles.inner}>
      {searchComponent}
      <Scrollbar className={styles.scroll}>
        {children}
      </Scrollbar>
      {footerComponent}
    </div>
  </div>
);
