import React from 'react';

import { Box } from '../../../components/Box/Box';
import { EmailConfirmIcon } from '../../../icons/EmailConfirmIcon';
import loginStyles from '../components/Login.pcss';

import styles from './ResetPasswordConfirmation.pcss';

export type ResetPasswordConfirmationProps = {
  resetPasswordConfirmationDescription: React.ReactNode;
}

export const ResetPasswordConfirmation: React.FC<ResetPasswordConfirmationProps> = ({
  resetPasswordConfirmationDescription,
}) => <Box noPadding className={styles.resetPasswordConfirmation}>
    <EmailConfirmIcon />
    <p className={loginStyles.loginDescription}>
      {resetPasswordConfirmationDescription}
    </p>
  </Box>