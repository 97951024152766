import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import { SettingsViewContainer } from './SettingsViewContainer';
import { useSelector } from 'react-redux';
import { currentUserRoles } from '../../selectors';
import { UserRole } from '../../utils/CurrentUser';

export const SettingsRouter: React.FC = () => {
  const match = useRouteMatch();

  const roles = useSelector(currentUserRoles);
  const isAdmin = roles.includes(UserRole.ADMIN);
  const hasSettingsPermission = roles.includes(UserRole.SETTINGS);

  const redirectTab = isAdmin || hasSettingsPermission ? 'general' : 'phone';

  return <Switch>
    <Route
      path={`${match.path}/:tabName`}
      component={SettingsViewContainer}
    />
    <Route path={match.path}>
      <Redirect to={`${match.url}/${redirectTab}`} />
    </Route>
  </Switch>;
}