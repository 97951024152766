import * as React from 'react';

import { DuplicateOption } from '../surveyCreator/components/optionsMenu/DuplicateOption';
import { EditOption } from '../surveyCreator/components/optionsMenu/EditOption';
import {OptionsComponent} from '../surveyCreator/components/optionsMenu/Options';

import styles from './BotComponentOptions.pcss';

type Props = {
    onEdit: (event: any) => any;
    onDuplicate: (event: any) => any;
}

export const BotComponentOptions = (props: Props) => {
    const options = [
        <EditOption key={'edit-option'} onClick={props.onEdit}/>,
        <DuplicateOption key={'duplicate-option'} onClick={props.onDuplicate}/>
    ];

    return (
        <OptionsComponent
            containerPositionClassName={styles.optionsPosition}
            options={options}
        />
    )
};
