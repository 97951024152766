import _ from 'lodash';
import * as React from 'react';
import {useIntl} from 'react-intl';

import styles from './NumberStatisticsSection.pcss'
import {ConfigurationItem} from './Statistics';
import {StatisticTile} from './tile/StatisticTile';

type Props = {
    statistics: ConfigurationItem[];
    calculateValue: (metricId: string) => number
}

export const NumberStatisticsSection = (props: Props) => {
    const intl = useIntl();
    if (props.statistics.length === 0) {
        return null;
    }
    return (
        <div data-test="statistics-section" className={styles.section}>
            <div className={styles.tiles} data-test={`statistics-section-tiles`}>
                {
                    props.statistics
                        .map(({id, metricId, metricName}) => (
                            <StatisticTile
                                key={id}
                                title={intl.messages[`conversations.filter.${metricId}`] as string || metricName}
                                value={props.calculateValue(metricId)}
                                data-test={`${_.kebabCase(metricId)}-statistic`}
                            />
                        ))
                }
            </div>
        </div>
    )
};
