import _ from 'lodash';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {SelectComponent} from '../../components/Select/Select';
import {FollowUpInGivenDaysAtScheduleStep, StepConfig} from '../schedule-model';

import styles from './FollowUpInGivenDaysComponent.pcss';
import {TimeOfDayInput} from './TimeOfDayInput';

type FollowUpInGivenDaysProps = {
    step: FollowUpInGivenDaysAtScheduleStep<StepConfig>;
    onStepChange: (step: FollowUpInGivenDaysAtScheduleStep<StepConfig>) => void;
};

export const FollowUpInGivenDays: React.FC<FollowUpInGivenDaysProps> = ({
    step,
    onStepChange
}) => {
    const intl = useIntl();
    const daysSelect = _.range(0, 15).map(days => ({
        id: days.toString(),
        name: days === 0
            ? `${intl.messages[`scheduler.steps.callStep.FollowUpInGivenDaysAt.currentDay`]}`
            : days === 1
                ? `${intl.messages[`scheduler.steps.callStep.FollowUpInGivenDaysAt.nextDay`]}`
                : `${intl.messages[`scheduler.steps.callStep.FollowUpInGivenDaysAt.before`]} ${days} ${intl.messages[`scheduler.steps.callStep.FollowUpInGivenDaysAt.multipleDays`]}`
    }));
    return (
        <div className={styles.daysAtDetailsContainer} data-test={'step-days-select'}>
            <SelectComponent
                value={daysSelect[step.days]}
                options={daysSelect}
                onChange={newValue => {
                    const newStep = {
                        ...step,
                        days: parseInt(newValue.id, 10)
                    };
                    onStepChange(newStep);
                }}
            />

            <FormattedMessage tagName="label" id={'scheduler.step.numberOfDaysLabel'} />

            <TimeOfDayInput
                timeOfDay={step.timeOfDay}
                onTimeOfDayChange={timeOfDay => {
                    onStepChange({
                        ...step,
                        timeOfDay
                    });
                }}
            />
        </div>
    );
}
