import React from 'react';

import {IconProps} from './iconTypes';

export function HttpStepIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H24V24H0z"/>
                <path className={props.animationClass} d="M21.765 2C23 2.004 23.996 2.955 24 4.132v15.73c-.004 1.18-1.005 2.134-2.243 2.138H2.127C.952 22 0 21.092 0 19.971V4.138C.004 2.958 1.005 2.004 2.243 2zm1.019 5.507H1.215v12.464c0 .443.348.81.797.863l.115.007h19.628c.568-.002 1.028-.441 1.03-.981l-.001-12.353zm-8.993 3.25c.27.129.395.424.308.69l-.037.088-3.038 5.797c-.15.286-.515.402-.815.259-.27-.129-.395-.424-.308-.69l.037-.088 3.038-5.797c.15-.286.515-.402.815-.26zm3.111.041l.085.067 3.038 2.899c.21.201.234.514.07.74l-.07.08-3.038 2.898c-.238.227-.622.227-.86 0-.21-.2-.234-.513-.07-.74l.07-.08 2.608-2.488-2.608-2.489c-.21-.201-.234-.513-.07-.74l.07-.08c.211-.2.539-.223.775-.067zm-9.03.067c.212.202.235.514.071.74l-.07.08-2.608 2.489 2.608 2.489c.21.2.234.513.07.74l-.07.08c-.211.2-.539.223-.775.066l-.085-.067-3.038-2.898c-.21-.201-.234-.514-.07-.74l.07-.08 3.038-2.899c.238-.226.622-.226.86 0zM21.766 3.16H2.245c-.568.002-1.028.441-1.03.981v2.207h21.569V4.134c0-.5-.394-.91-.9-.968l-.119-.007z"/>
            </g>
        </svg>

    );
}
