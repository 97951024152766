import classNames from 'classnames';
import * as React from 'react';
import {useEffect, useRef} from 'react';

import styles from './TextArea.pcss';

type Props = {
    dataTest?: string;
    value?: string;
    placeholder?: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
    resizable?: boolean;
    className?: string;
    errorMessage?: string;
}

export const TextArea = ({resizable = true, ...props}: Props) => {
    const ref = useRef(null);
    useEffect(() => {
        resizeOnKeystroke();
    }, [ref.current, props.value]);
    const resizeOnKeystroke = () => {
        if (resizable) {
            ref.current.style.height = '';
            ref.current.style.height = ref.current.scrollHeight + 4 + 'px'
        }
    };
    return (
        <div className={styles.input__wrapper}>
            <textarea
                ref={ref}
                onInput={resizeOnKeystroke}
                data-test={props.dataTest}
                value={props.value}
                onChange={(event) => props.onChange && props.onChange(event.target.value)}
                className={classNames(styles.input, props.className, {
                    [styles.error]: Boolean(props.errorMessage)
                })}
                disabled={props.disabled}
                placeholder={props.placeholder}
            />
            {Boolean(props.errorMessage) && <div className={styles.input__tooltip} role="tooltip">{props.errorMessage}</div> }
        </div>

    );
};

