import React from 'react';

import { IconProps } from '../iconTypes';

type Props = {
  onClick?: () => void;
} & IconProps;

export function StatisticsPinIcon({ onClick, animationClass }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m8.723 14.414-5.548 5.547a.6.6 0 1 0 .85.848l5.546-5.547 2.64 2.64a1.717 1.717 0 0 0 2.7-.344v-.003a6.207 6.207 0 0 0 .757-4.149l4.521-2.878a1.719 1.719 0 0 0 .294-2.666l-4.358-4.358a1.72 1.72 0 0 0-2.666.292L10.58 8.319a6.21 6.21 0 0 0-4.15.756l-.003.001a1.72 1.72 0 0 0-.343 2.7l2.638 2.638zm-1.688-4.303a5.01 5.01 0 0 1 3.69-.538.6.6 0 0 0 .646-.262l3.1-4.871a.522.522 0 0 1 .804-.088l4.36 4.359a.516.516 0 0 1 .147.423.515.515 0 0 1-.236.381l-4.87 3.1a.6.6 0 0 0-.262.647 5.01 5.01 0 0 1-.538 3.689.52.52 0 0 1-.816.103l-6.128-6.128a.519.519 0 0 1 .103-.815z"
        fill="#919AAF"
        className={animationClass}
      />
    </svg>
  );
}
