import {Dictionary} from 'lodash';
import * as React from 'react';
import {connect} from 'react-redux';

import { EventType } from '../conversations/models/Events';
import {RootState} from '../reducers/reducers';

export type NamesDictionaryItemCategory = 'summary' | 'redirects' | EventType;

export type NamesDictionaryItem = {
    label: string;
    category: NamesDictionaryItemCategory;
}

const mapStateToProps = (state: RootState) => ({
    namesDictionary: state.namesDictionary.data
});

export interface NamesDictionaryProps {
    namesDictionary: Dictionary<NamesDictionaryItem>;
}

export const withNamesDictionary = <CustomProps extends any>(WrappedComponent: React.ComponentType<CustomProps>): React.ComponentType =>
    connect(mapStateToProps)(WrappedComponent as any);
