import React from 'react';
import styles from './PaletteStepSubtitle.pcss';

type PaletteStepSubtitleProps = {
  moduleName: string;
  stepName: string;
}

export const PaletteStepSubtitle: React.FC<PaletteStepSubtitleProps> = ({ moduleName, stepName }) => {
  return (
    <span className={styles.subtitle}>
      <span className={styles.moduleName}>
        {moduleName}
      </span>
      {`➔`}
      <span className={styles.stepName}>
        {stepName}
      </span>
    </span>
  );
}