import React from 'react';
import {useIntl} from 'react-intl';

import {Box} from '../../../components/Box/Box';
import {Button} from '../../../components/Button/Button';
import {ReadOnlyField} from '../../../components/ReadOnlyField/ReadOnlyField';
import {ApiTabContentComponentProps} from '../types';

import styles from './ApiTabContentComponent.pcss';


export const ApiTabContentComponent: React.FC<ApiTabContentComponentProps> = ({apiCredentials, botId}) => {
  const intl = useIntl();

  const openDocsInNewTab = () => {
    const newWindow = window.open('https://docs.talkie.ai/', '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  }

  return <Box border className={styles.apiCredentials} dataTest="api-tab-content">

      <ReadOnlyField labelId={'survey.config.api.botId'} value={botId}
                   dataTest={'api-botId'} dataTestCopyButton={'copy-api'} withCopyButton />

    <ReadOnlyField labelId={'survey.config.api.username'} value={apiCredentials.username}
                   dataTest={'api-username'} withCopyButton
    />
    <ReadOnlyField labelId={'survey.config.api.password'} value={apiCredentials.password}
                   dataTest={'api-password'} dataSensitive="true" withCopyButton hidden/>
    <div>
      <Button
        buttonType="normal"
        onClick={openDocsInNewTab}>{intl.messages['survey.config.tabs.api.docsButtonLabel']}
      </Button>
    </div>
  </Box>
}
