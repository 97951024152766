import React from 'react';

import { IgnorableIntentsContainerProps } from '../types';

import { useIgnorableIntentsProps } from './IgnorableIntents.hooks';
import { IgnorableIntentsComponent } from './IgnorableIntentsComponent';

export const IgnorableIntentsContainer: React.FC<IgnorableIntentsContainerProps> = (props) => {
  const ignorableIntentsProps = useIgnorableIntentsProps(props)
  return <IgnorableIntentsComponent 
  {...ignorableIntentsProps}
  />;
}