import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Button } from '../components/Button/Button';
import { ElementWithLabel } from '../components/ElementWithLabel/ElementWithLabel';
import { Modal } from '../components/Modal/Modal';
import { SelectComponent, Value } from '../components/Select/Select';
import { TextArea } from '../components/TextArea/TextArea';
import { currentUserRoles } from '../selectors';
import { BlockQuestion, ConditionalQuestion, WordQuestion } from '../surveyCreator/model';
import { getIntentDisplayName, Intent } from '../surveyCreator/SurveyCreator';
import { UserRole } from '../utils/CurrentUser';

import { ToLearnMessage } from './Inspector';
import styles from './TrainingModal.pcss';

export type TrainingScope = 'local' | 'global'

export type TrainingModalProps = {
  isOpen?: boolean;
  onCancel: React.MouseEventHandler;
  onTrainQuestion: (questionId: string, word: string, trainingInput: string, scope: TrainingScope) => void;
  onTrainIntent: (intent: string, trainingInput: string, scope: TrainingScope) => void;
  questions?: WordQuestion[];
  messages?: ToLearnMessage[];

  localIntents?: Intent[];
  globalIntents?: Intent[];
}

const getActualQuestionType = (question: BlockQuestion) => question.type === 'conditional' ? (question as ConditionalQuestion).question.type : question.type;

export const TrainingModal: React.FC<TrainingModalProps> = ({
  isOpen,
  onCancel,
  onTrainQuestion,
  onTrainIntent,
  questions,
  messages,
  localIntents,
  globalIntents
}) => {
  const intl = useIntl();
  const [trainingInput, setTrainingInput] = useState('');
  useEffect(() => setTrainingInput(messages.map(message => message.text).join('\n')), [messages]);

  const addToOptions = [{
    id: 'intent',
    name: intl.messages['inspector.train.addToIntent'] as string
  }];
  let answersInOptions: Value[] = [];

  const isLocalOnlyIntent = useCallback((intent: string) => !globalIntents.find(global => global.name === intent), [globalIntents]);

  const allIntents = useMemo(
    () => _.uniqBy([...localIntents, ...globalIntents], 'name'),
    [localIntents, globalIntents]
  );
  const intentsOptions = _.sortBy(
    allIntents.map(i => ({id: i.name, name: getIntentDisplayName(i)})),
    item => _.toLower(item.name),
    'asc'
  );

  const isAllSelectedHaveSameQuestion = _.uniq(questions.map(question => question.id)).length === 1;
  const selectedQuestionsAreAllWord = questions.filter(question => getActualQuestionType(question) === 'word').length > 0;
  const selectedWordQuestionHasAnyAnswer = () => questions.find(question => getActualQuestionType(question) === 'word')?.dictionary?.length >= 1;
  if (isAllSelectedHaveSameQuestion && selectedQuestionsAreAllWord && selectedWordQuestionHasAnyAnswer()) {
    addToOptions.push({
      id: 'step',
      name: intl.messages['inspector.train.addToStep'] as string
    });

    answersInOptions = _.head(questions).dictionary.map(item => ({
      id: item.id,
      name: item.key
    }));
  }

  const [selectedAddToOption, setSelectedAddToOption] = useState(_.last(addToOptions));
  useEffect(() => setSelectedAddToOption(_.last(addToOptions)), [questions]);

  const isTrainingIntent = selectedAddToOption.id === 'intent';

  const [selectedAnswerInOption, setSelectedAnswerInOption] = useState(_.head(answersInOptions));
  useEffect(() => {
    setSelectedAnswerInOption(_.head(isTrainingIntent ? intentsOptions : answersInOptions));
  }, [questions, selectedAddToOption]);

  const scopeOptions = [
    { id: 'local', name: intl.messages['inspector.train.scopeLocalOption'] as string },
    { id: 'global', name: intl.messages['inspector.train.scopeGlobalOption'] as string }
  ];
  const [selectedScope, setSelectedScope] = useState(_.head(scopeOptions));
  useEffect(() => {
    if (isLocalOnlyIntent(selectedAnswerInOption?.id)) {
      setSelectedScope(scopeOptions[0]);
    }
  }, [selectedAnswerInOption]);

  const handleTrainQuestion = useCallback(() => onTrainQuestion(
    questions[0]?.id,
    selectedScope.id === 'local' ? selectedAnswerInOption.id : selectedAnswerInOption.name,
    trainingInput,
    selectedScope.id as TrainingScope
  ), [questions, selectedAnswerInOption, trainingInput, selectedScope]);

  const handleTrainIntent = useCallback(() => onTrainIntent(
    selectedAnswerInOption?.id,
    trainingInput,
    selectedScope?.id as TrainingScope
  ), [selectedAnswerInOption, trainingInput, selectedScope]);
  const isAdminUser = useSelector(currentUserRoles).includes(UserRole.ADMIN);

  const isDisabledScopeSelect = useMemo(
    () => isAdminUser && isTrainingIntent && isLocalOnlyIntent(selectedAnswerInOption?.id),
    [isAdminUser, isTrainingIntent, selectedAnswerInOption, isLocalOnlyIntent]
  );
  return isOpen && <Modal className={styles.trainingModal}>
    <div className={styles.trainingModalContent}>
      <h1 className={styles.trainingModalHeader}>
        <FormattedMessage id={'inspector.train.header'} />
      </h1>

      <div className={styles.trainingModalBody}>
        <div className={styles.trainingModalLeft}>
          <ElementWithLabel
            noPadding
            inner
            labelId="inspector.train.addToLabel"
            disabled={addToOptions.length < 2}
          >
            <SelectComponent
              data-test="add-to-select"
              options={addToOptions}
              value={selectedAddToOption}
              onChange={setSelectedAddToOption}
              isDisabled={addToOptions.length < 2}
            />
          </ElementWithLabel>
          <ElementWithLabel
            noPadding
            inner
            labelId={isTrainingIntent ? 'inspector.train.intentLabel' : 'inspector.train.answerInLabel'}
            labelSuffix={isAllSelectedHaveSameQuestion && !isTrainingIntent ? questions[0]?.name : undefined}
          >
            <SelectComponent
              data-test="training-answer-select"
              options={isTrainingIntent ? intentsOptions : answersInOptions}
              value={selectedAnswerInOption}
              isSearchable={isTrainingIntent}
              onChange={setSelectedAnswerInOption}
            />
          </ElementWithLabel>
          {
            isAdminUser &&
            <ElementWithLabel
              noPadding
              inner
              labelId={'inspector.train.scopeLabel'}
              disabled={isDisabledScopeSelect}
            >
              <SelectComponent
                data-test="training-scope-select"
                options={scopeOptions}
                value={selectedScope}
                onChange={setSelectedScope}
                isDisabled={isDisabledScopeSelect}
              />
            </ElementWithLabel>
          }
        </div>
        <ElementWithLabel
          className={styles.trainingModalRight}
          noPadding
          inner
          labelId={'inspector.train.sentencesLabel'}
        >
          <TextArea
            dataTest={'training-input'}
            className={styles.textArea}
            value={trainingInput}
            onChange={setTrainingInput}
            resizable={false}
          />
        </ElementWithLabel>
      </div>

      <div className={styles.trainingModalButtons}>
        <Button onClick={onCancel} buttonType={'normal'}>
          <FormattedMessage id={'cancelLabel'} />
        </Button>
        <Button onClick={isTrainingIntent ? handleTrainIntent : handleTrainQuestion}>
          <FormattedMessage id={'inspector.train'} />
        </Button>
      </div>
    </div>
  </Modal>;
}
