import {Dictionary} from 'lodash';
import * as React from 'react';
import {Component} from 'react';
import {from, Observable, of, Subscriber, Subscription} from 'rxjs';
import {catchError, groupBy, map, mergeMap, switchMap} from 'rxjs/operators';

import {HttpClient} from './HttpClient';

interface State<T> {
    data: T;
    error?: Error;
}

type FetchParams = {
    from?: number;
    to?: number;
} | {
    [prop: string]: any;
};

type FetchFunctionParams = {
    path: string;
    params?: FetchParams;
    body?: any;
    dataKey: string;
    type?: FetchRequestType;
};

export type DataFetchProps<T> = {
    error?: Error;
    data: T;
    fetchData: (params: FetchFunctionParams) => void
};

type Props = {};

type FetchResult<T> = {
    data: T;
    dataKey: string;
}

type FetchRequestType = 'GET' | 'POST';

export const withDataFetch = <T extends {}>(dataKeys: string[]) => (WrappedComponent: React.ComponentType<DataFetchProps<T>>) => {
    return class DataFetch extends Component<Props, State<T>> {

        private subscriber: Subscriber<any>;
        private subscription: Subscription;

        constructor(props: Props) {
            super(props);

            this.state = {
                data: dataKeys.reduce((acc, key) => ({...acc, [key]: []}), {}) as T
            };

            this.createSubscription();
        }

        componentWillUnmount() {
            this.subscriber.complete();
            this.subscription.unsubscribe();
        }

        render() {
            return (
                <WrappedComponent data={this.state.data} error={this.state.error} fetchData={this.loadData} {...this.props}/>
            );
        }

        private createSubscription() {
            const request$ = new Observable((obs: any) => this.subscriber = obs)
                .pipe(
                    groupBy(({dataKey}) => dataKey),
                    mergeMap((group: Observable<any>) =>
                        group.pipe(
                            switchMap(({path, params, body, dataKey, type}) =>
                                from(this.performRequest(path, {body, params}, type))
                                    .pipe(
                                        map(({data}) => ({data, dataKey})),
                                        catchError(this.onError)
                                    )
                            )
                        )
                    )
                );
            this.subscription = request$.subscribe(this.updateData);

        }

        private performRequest(path: string, data: { params: Dictionary<string>, body: any }, type: FetchRequestType = 'GET') {
            if (type === 'GET') {
                return HttpClient.get({path, params: data.params})
            } else {
                return HttpClient.post({path, body: data.body})
            }
        }

        private onError = (error: Error) => {
            this.setState({error});
            return of({} as any);
        };

        private loadData = ({path, params, body, dataKey, type}: FetchFunctionParams) => {
            this.setState({error: undefined});
            this.subscriber.next({path, params, body, dataKey, type});
        };

        private updateData = ({data, dataKey}: FetchResult<T>) => {
            this.setState((prevState) => ({
                data: {
                    ...(prevState.data as any),
                    [dataKey]: data
                }
            }));
        };
    };
};
