import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import { Box } from '../../components/Box/Box';
import { Button } from '../../components/Button/Button';
import styles from './TrainingStatus.pcss';

export type TrainingStatus = 'TRAINING' | 'IDLE' | 'ERROR' | 'DISABLED';

type TrainingStatusProps = {
  status: TrainingStatus;
  onTrain: () => void;
}

export const TrainingStatusComponent: React.FC<TrainingStatusProps> = ({
  status,
  onTrain
}) => <div className={styles.trainingStatusRow}>
    <Box border noPadding className={styles.trainingStatusBox}>
      <div className={styles.trainingStatusDescription}>
        <label className={styles.trainingStatusLabel}>
          <FormattedMessage id="learning.intentTraining.statusLabel" />
        </label>
        <div
          data-test="training-status"
          className={cx(
            styles.trainingStatusValue, {
            [styles.error]: status === 'ERROR',
          }
          )}>
          <FormattedMessage id={`learning.trainingStatus.${status}`} />
        </div>
      </div>
      <Button
        dataTest="train-button"
        buttonType={status === 'TRAINING' ? 'loading' : 'normal'}
        className={styles.trainingStatusButton}
        onClick={onTrain}
      >
        {
          status !== 'TRAINING' &&
          <FormattedMessage id="learning.intentTraining.trainingBotButton" />
        }
      </Button>
    </Box>
  </div> 
