import * as React from 'react';
import {useLayoutEffect, useRef, useState} from 'react';
import {animateScroll} from 'react-scroll';
import uuid from 'uuid';

import cancelIcon from '../../images/cross-dark.svg';
import confirmIcon from '../../images/tick.svg';

import styles from './AddPopover.pcss';

type RenderButtonProps = {
    onClick: (event: any) => void
}

type Props = {
    dataTest: string;
    onConfirm: () => void
    onReject: () => void
    renderButton: (props: RenderButtonProps) => JSX.Element
    renderContent: () => JSX.Element
    scrollContainerId?: string
}

export const AddPopover = (props: Props) => {
    const [isOpened, changeOpen] = useState<boolean>(false);
    const idRef = useRef<string>(uuid.v4());
    const popoverRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (isOpened) {
            const scrollContainer = document.getElementById(props.scrollContainerId || '');
            if (!popoverRef.current || !scrollContainer) {
                return;
            }
            if ((scrollContainer.clientHeight) < (popoverRef.current.getBoundingClientRect().bottom)) {
                animateScroll.scrollMore(popoverRef.current.getBoundingClientRect().height, {
                    duration: 1000,
                    containerId: props.scrollContainerId,
                    smooth: true
                });
            }
        }
    }, [isOpened, props.scrollContainerId]);

    const handleClick = () => {
        changeOpen(true);
    };

    const close = () => {
        changeOpen(false);
    };
    const onConfirm = () => {
        props.onConfirm();
        close();
    };

    const onReject = () => {
        props.onReject();
        close();
    };

    const onKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            onConfirm();
        }
    };

    return (
        <>
            <div className={styles.container} id={idRef.current} onKeyPress={onKeyPress}>
                {props.renderButton({onClick: handleClick})}
                {isOpened && (
                    <div data-test={props.dataTest} className={styles.popover} ref={popoverRef}>
                        <div>
                            {props.renderContent()}
                        </div>
                        <div className={styles.buttons}>
                            <button data-test="reject" className={`${styles.reject} ${styles.button}`} onClick={onReject}><img src={cancelIcon} alt={'cancel'}/></button>
                            <button data-test="confirm" className={`${styles.confirm} ${styles.button}`} onClick={onConfirm}><img src={confirmIcon} alt={'confirm'}/></button>
                        </div>
                    </div>
                )}
            </div>
            {isOpened && <div className={styles.overlay}/>}
        </>
    );
};
