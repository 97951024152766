import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ElementDescription } from '../../../../components/ElementDescription/ElementDescription';
import { ElementWithLabel } from '../../../../components/ElementWithLabel/ElementWithLabel';
import { IconButton } from '../../../../components/IconButton/IconButton';
import { SelectComponent } from '../../../../components/Select/Select';
import { Tag } from '../../../../components/Tag/Tag';
import { DeleteIcon } from '../../../../icons';
import { IgnorableIntentsComponentProps } from '../types';

import styles from './IgnorableIntents.pcss'
import { sortByNameComparator } from '../../../../utils/SortUtils';

export const IgnorableIntentsComponent: React.FC<IgnorableIntentsComponentProps> = ({
    intents,
    selectedIntents,
    addIgnorableIntent,
    removeIgnorableIntent,
    findIntent
}) => <ElementWithLabel
        labelId={'survey-creator.ignorableIntentsLabel'}
        borderEnabled
    >
        <ElementDescription>
            <FormattedMessage id={'survey-creator.ignorableIntentsDescription'} />
        </ElementDescription>
        <SelectComponent
            onChange={addIgnorableIntent}
            value={null}
            isSearchable
            options={intents.get().map(intent => ({ id: intent.name, name: findIntent(intent.name) })).sort(sortByNameComparator)}
            isDisabled={intents.get().length === 0}
        />
        <div className={styles.tags} data-test="ignored-intents-list">
            {
                selectedIntents.map(intent =>
                    <div
                      key={intent.id}
                      className={styles.tagContainer}
                    >
                        <IconButton
                           data-test="delete-intent-icon-button"
                           Icon={DeleteIcon}
                           hoverType={'warning'}
                           onClick={() => removeIgnorableIntent(intent)}
                         />
                        <Tag data-test="ignored-intent-tag">
                            {intent.name}
                        </Tag>
                    </div>)
            }
        </div>
    </ElementWithLabel>
