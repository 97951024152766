import classNames from 'classnames';
import React from 'react';
import {
  toast,
  ToastContainer,
  ToastOptions,
  ToastContent,
  Slide,
  IconProps,
  TypeOptions,
  CloseButtonProps,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastCloseIcon } from '../../icons/ToastCloseIcon';
import { NotificationSuccessIcon } from '../../icons/notifications/NotificationSuccessIcon';
import { NotificationWarningIcon } from '../../icons/notifications/NotificationWarningIcon';
import { NotificationErrorIcon } from '../../icons/notifications/NotificationErrorIcon';
import styles from './Toast.pcss';

const toastIcons = {
  success: <NotificationSuccessIcon />,
  warning: <NotificationWarningIcon />,
  error: <NotificationErrorIcon />,
} as const;

const defaultToastOptions: ToastOptions = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  transition: Slide,
  icon(props: IconProps) {
    return toastIcons[props.type];
  },
  className(context?: { type?: TypeOptions }) {
    return classNames(styles.body, styles[context.type]);
  },
  bodyClassName() {
    return styles.content;
  },
  closeButton({ closeToast }: CloseButtonProps) {
    return (
      <button className={styles.closeIconWrapper} onClick={closeToast}>
        <ToastCloseIcon animationClass={styles.closeIcon} />
      </button>
    );
  },
};

export function ToastProvider() {
  return <ToastContainer />;
}

export function showErrorToast(content: ToastContent, options: ToastOptions = {}) {
  toast.error(content, {
    ...defaultToastOptions,
    role: 'error-toast',
    ...options
  });
}

export function showWarningToast(content: ToastContent, options: ToastOptions = {}) {
  toast.warning(content, {
    ...defaultToastOptions,
    role: 'warning-toast',
    ...options
  });
}

export function showSuccessToast(content: ToastContent, options: ToastOptions = {}) {
  toast.success(content, {
    ...defaultToastOptions,
    role: 'success-toast',
    ...options
  });
}
