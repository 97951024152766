import React from 'react';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal } from '../../components/Modal/Modal';
import { ModalContent } from '../../components/ModalContent/ModalContent';
import { Button } from '../../components/Button/Button';
import { useGetTermsOfServiceMarkdown } from './terms-of-service-api';
import ReactMarkdown from 'react-markdown';

import { useShowErrorToast } from '../../hooks/useShowErrorToast';
import { Loader } from '../../components/Loader';
import { DownloadIcon } from '../../icons';
import styles from './TermsOfServiceModal.pcss';

interface Props {
  buttonTextKey: string;
  onClick: () => void;
  isSubmitting?: boolean;
  onClose?: () => void;
}

const TermsOfServiceModal: FC<Props> = ({
  buttonTextKey,
  onClick,
  isSubmitting,
  onClose,
}) => {
  const intl = useIntl();
  const filename = intl.messages['termsOfServiceModal.filename'] as string;
  const { data: markdownContent, isError: mdError } =
    useGetTermsOfServiceMarkdown(
      intl.messages[`termsOfServiceModal.markdownFilename.${process.env.REACT_APP_AWS_REGION}.${process.env.REACT_APP_STAGE}`] as string
    );

  useShowErrorToast(mdError);

  return (
    <Modal
      dataTest="terms-of-service-modal"
      position="center"
      noPadding
      modalSize="medium"
      className={styles.modal}
    >
      <ModalContent
        headerContent={<FormattedMessage id="termsOfServiceModal.title" />}
        footerContent={
          <>
            <div className={styles.downloadWrapper}>
              <span className={styles.downloadText}>
                <FormattedMessage id="termsOfServiceModal.download" />:
              </span>
              <a
                className={styles.link}
                href={intl.messages[`termsOfServiceModal.filePath.${process.env.REACT_APP_AWS_REGION}.${process.env.REACT_APP_STAGE}`] as string}
                target="talkie-terms-of-service"
                download={filename}
                data-test="terms-of-service-modal-download-link"
              >
                <Button buttonType="link" Icon={DownloadIcon}>
                  {filename}
                </Button>
              </a>
            </div>
            <Button
              buttonType={isSubmitting ? 'loading' : 'primary'}
              dataTest="terms-of-service-modal-button"
              onClick={onClick}
              disabled={!markdownContent}
            >
              {!isSubmitting && <FormattedMessage id={buttonTextKey} />}
            </Button>
          </>
        }
        onClose={onClose}
        withScrollBarMargin
      >
        {markdownContent ? (
          <div data-test="terms-of-service-modal-content">
            <ReactMarkdown className={styles.mdContent}>
              {markdownContent}
            </ReactMarkdown>
          </div>
        ) : (
          <Loader />
        )}
      </ModalContent>
    </Modal>
  );
};

export default TermsOfServiceModal;
