import { useEffect } from 'react';

export const isMacOs = () => window.navigator.platform.toLowerCase().includes('mac');
export const useShortcut = ({ keys, meta = false }: { keys: string[], meta?: boolean }, callback: () => void) => {
    useEffect(() => {
        const handler = (event: KeyboardEvent) => {
            const keyCharacter = event.key.toLowerCase();
            const isMeta = isMacOs() ? event.metaKey : event.ctrlKey;

            if (keys.includes(keyCharacter) && meta === isMeta) {
                callback();
                event.preventDefault();
            }
        };

        window.addEventListener('keydown', handler);
        return () => {
            window.removeEventListener('keydown', handler);
        };
    }, [keys, meta, callback]);
}