import React from 'react';

export function NotificationWarningIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#i2rnkb5r1a)">
        <path
          d="M23 10.586 13.414 1a2 2 0 0 0-2.828 0L1 10.587a2 2 0 0 0 0 2.828L10.586 23a2 2 0 0 0 2.828 0L23 13.415a2 2 0 0 0 0-2.83zm-11.932-4.17a1 1 0 0 1 2 0v6a1 1 0 1 1-2 0v-6zm1.05 11.51h-.027a1.528 1.528 0 0 1-1.522-1.47 1.475 1.475 0 0 1 1.448-1.53h.028a1.527 1.527 0 0 1 1.522 1.47 1.473 1.473 0 0 1-1.448 1.53z"
          fill="#FF8C2F"
        />
      </g>
      <defs>
        <clipPath id="i2rnkb5r1a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
