import classNames from 'classnames';
import _ from 'lodash';
import * as React from 'react';
import { useCallback, useMemo, useRef } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { FormattedMessage, useIntl } from 'react-intl';
import Select, { components } from 'react-select';
import { InputProps } from 'react-select/src/components/Input';

import { useBots } from '../../selectors';
import { Bot } from '../../surveyCreator/model';
import { useCurrentBot } from '../../utils/CurrentBot';
import { Scrollbar } from '../Scrollbar/Scrollbar';

import styles from './BotPicker.pcss';
import {TooltipComponent} from '../Tooltip/Tooltip';

type Props = {
    changeCurrentBot(bot: Bot): void;
};

const getOptions = (
    bots: Bot[],
    currentBotId: string
): ReadonlyArray<{ readonly label: string; readonly options: ReadonlyArray<Bot> }> => {
    const sortedBots = _.cloneDeep(bots).sort((bot1, bot2) => {
        if (bot1.id === currentBotId) {
            return -1;
        } else if (bot2.id === currentBotId) {
            return 1;
        }
        return 1;
    });
    const botsByOrganization = _.groupBy(sortedBots, 'organization');
    if (_.keys(botsByOrganization).length === 1) {
        return [{ label: 'all', options: sortedBots }];
    }
    return _.entries(botsByOrganization)
        .map(([organization, botsForOrganization]) => ({
            label: _.lowerCase(organization),
            options: botsForOrganization
        }))
        .sort((group1, group2) => {
            if (group1.options.map(el => el.id).includes(currentBotId)) {
                return -1;
            } else if (group2.options.map(el => el.id).includes(currentBotId)) {
                return 1;
            }
            return group1.label < group2.label ? -1 : 1;
        });
};

export function BotPicker({ changeCurrentBot }: Props) {
    const selectRef = useRef(null);
    const scrollWrapperRef = useRef(null);
    const scrollBarRef = useRef<Scrollbars>(null);
    const intl = useIntl();

    const bots = useBots();
    const currentBot = useCurrentBot();
    const currentBotId = currentBot && currentBot.id;
    const botsOptions = useMemo(() => bots ? getOptions(bots, currentBotId).flatMap(organization => organization.options) : [], [
        bots,
        currentBotId
    ]);
    const showSearch = botsOptions.length > 6;
    const isDisabled = botsOptions.length < 2;

    const onSelect = useCallback(
        (bot: Bot) => {
            changeCurrentBot(bot);
        },
        [changeCurrentBot]
    );

    const computeMenuHeight = useCallback(options => Math.min(5, options.length || 1) * 29 + 13, []);

    const alignScrollPosition = useCallback(() => {
        setTimeout(() => {
            if (scrollWrapperRef.current && scrollBarRef.current) {
                const focusedOptionRef = selectRef.current.select.focusedOptionRef;
                if (focusedOptionRef) {
                    scrollIntoView(scrollWrapperRef.current, focusedOptionRef, scrollBarRef.current);
                }
            }
        }, 0);
    }, [scrollWrapperRef, scrollBarRef, selectRef]);

    return (
        <>
            <Select
                ref={selectRef}
                data-test="bot-picker"
                data-test-searchable={showSearch}
                classNamePrefix="bot-picker"
                className={classNames(styles.botPicker, showSearch && styles.searchable)}
                value={currentBot}
                options={botsOptions}
                isSearchable={showSearch}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                onKeyDown={alignScrollPosition}
                onMenuOpen={alignScrollPosition}
                onChange={onSelect}
                isDisabled={isDisabled}
                captureMenuScroll={false}
                components={{
                    MenuList: menuProps => {
                        return (
                            <div
                                ref={scrollWrapperRef}
                                className={styles.botPickerMenu}
                                style={{ height: computeMenuHeight(menuProps.children) }}
                            >
                                <Scrollbar hideHorizontal={true} ref={scrollBarRef}>{menuProps.children}</Scrollbar>
                            </div>
                        );
                    },
                    NoOptionsMessage: () => (
                        <div className={styles.noResult}>
                            <FormattedMessage id="select.noResult" />
                        </div>
                    ),
                    Placeholder: () => null,
                    IndicatorsContainer: () => null,
                    SingleValue: valueProps => {
                        if (isMenuOpen(valueProps)) {
                            return <></>;
                        }
                        return (
                            <div className={styles.botNameWrapper}>
                                <TooltipComponent tooltipText={valueProps.children as string}>
                                   <span className={styles.botName} data-test="selected-bot">{valueProps.children}</span>
                                </TooltipComponent>
                                {!isDisabled && <i className={styles.arrow} />}
                            </div>
                        );
                    },
                    Input: (inputProps: InputProps) => {
                        const menuOpen = isMenuOpen(inputProps);
                        const input = (
                            // @ts-ignore - TODO - react-select typing wrongly omits html attributes
                            <components.Input
                                {...inputProps}
                                className={styles.botInput}
                                data-test="bot-picker-search"
                                // @ts-ignore - TODO - react-select typing wrongly omits html attributes
                                placeholder={menuOpen ? intl.formatMessage({ id: 'select.search' }) : ''}
                            />
                        );
                        return (
                            <>
                                {menuOpen && <i className={styles.searchIcon} />}
                                {input}
                            </>
                        );
                    }
                }}
            />
        </>
    );
}

// TODO - Typings for react-select are wrongly missing selectProps on Components
function isMenuOpen(props: any) {
    return props.selectProps && props.selectProps.menuIsOpen;
}

function scrollIntoView(menuEl: any, focusedEl: HTMLElement, scrollBar: Scrollbars): void {
    const menuRect = menuEl.getBoundingClientRect();
    const focusedRect = focusedEl.getBoundingClientRect();
    const overScroll = focusedEl.offsetHeight / 3;

    if (focusedRect.bottom + overScroll > menuRect.bottom) {
        scrollBar.scrollTop(
            Math.min(
                focusedEl.offsetTop + focusedEl.clientHeight - menuEl.offsetHeight + overScroll,
                scrollBar.getScrollHeight()
            )
        );
    } else if (focusedRect.top - overScroll < menuRect.top) {
        scrollBar.scrollTop(Math.max(focusedEl.offsetTop - overScroll, 0));
    }
}
