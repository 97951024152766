import cx from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { HorizontalSeparator } from '../../HorizontalSeparator/HorizontalSeparator';
import { Box } from '../../Box/Box';
import { Scrollbar } from '../../Scrollbar/Scrollbar';
import { NotificationBadge } from '../../NotificationBadge/NotificationBadge';
import { DropdownMenu } from '../../DropdownMenu/DropdownMenu';
import { MenuIcon } from '../../../icons';
import { IconButton } from '../../IconButton/IconButton';
import { Tab, TabsLayoutProps } from '../types';

import styles from './HorizontalTabsLayout.pcss';

type WrapableHorizontalTabsLayoutClasses = Partial<{
    root: string;
    tab: string;
    currentTabContent: string;
    tabsHeader: string;
}>;

type WrapableHorizontalTabsLayoutProps = TabsLayoutProps & {
    noSeparator?: boolean;
    classes?: WrapableHorizontalTabsLayoutClasses;
    fadePadding?: boolean;
    disabled?: boolean;
};

const shouldShowBadge = (tab: Tab) => {
    return !!tab.badgeContent && tab.badgeContent !== '0';
}

export const WrapableHorizontalTabsLayout: React.FC<WrapableHorizontalTabsLayoutProps> = ({
    currentTab,
    tabs,
    onTabClick,
    getComponentForTab,
    noSeparator = false,
    classes,
    fadePadding = false,
    disabled = false
}) => {
    const [tabWidths, setTabWidths] = useState(tabs
      .map(tab => (tab.label.length * 7)
        + 32
        + (tab.badgeContent
            ? ((typeof tab.badgeContent === 'string' ? tab.badgeContent : tab.badgeContent?.toString()).length * 6 + 16)
            : 0
        )
      )
      .map(tabWidth => Math.max(tabWidth, 150)));

    const [lastVisibleTabIndex, setLastVisibleTabIndex] = useState(tabs.length);

    const [isMenuOpen, setMenuOpen] = useState(false);
    const openMenu = useCallback(() => setMenuOpen(true), [setMenuOpen]);
    const closeMenu = useCallback(() => setMenuOpen(false), [setMenuOpen]);

    const tabsContainerRef = useRef(null);
    useEffect(() => {
        if (tabsContainerRef.current?.offsetWidth) {
            let tabIndex = null;
            let processedTabsWidthCombined = 0;
            tabWidths.forEach((tabWidth, index) => {
                processedTabsWidthCombined = processedTabsWidthCombined + tabWidth;
                if (processedTabsWidthCombined < (tabsContainerRef.current.offsetWidth - 44)) {
                    tabIndex = index;
                }
            });
            setLastVisibleTabIndex(tabIndex);
        }
    }, [tabsContainerRef.current]);

    return (
        <div className={cx(styles.horizontalTabsLayout, classes?.root)} ref={tabsContainerRef}>
            {
              disabled &&
              <div className={styles.disabledOverlay} />
            }
            <Box className={cx(styles.tabsHeader, classes.tabsHeader, { [styles.tabsHeaderWithMore]: lastVisibleTabIndex < tabs.length - 1 })}>
                <Box className={cx(styles.tabsContainer, { [styles.tabsContainerWithMore]: lastVisibleTabIndex < tabs.length - 1 })} border>
                    {tabs.map((tab, index) => {
                        if ((index > lastVisibleTabIndex)) {
                            return null;
                        }
                        const isSelected = currentTab === tab.id;
                        return <Box
                          key={tab.id}
                          className={cx(styles.tab, { [styles.selected]: isSelected }, classes?.tab)}
                          onClick={() => onTabClick(tab.id)}
                          dataTest={tab.dataTest}
                        >
                            {tab.Icon && <tab.Icon
                              animationClass={cx(styles.icon, { [styles.selected]: isSelected })} />}
                            {tab.label}
                            {shouldShowBadge(tab) && <NotificationBadge dataTest={`report-tab-badge-${tab.label}`}>{tab.badgeContent}</NotificationBadge>}
                        </Box>;
                    })}
                    {lastVisibleTabIndex < tabs.length - 1 && (
                      <Box
                        data-test="see-more-tabs-button-container"
                        className={styles.moreTabsContainer}
                      >
                          <OutsideClickHandler
                            onOutsideClick={closeMenu}
                          >
                              <DropdownMenu
                                classes={{
                                    menu: styles.moreTabsDropdownMenu,
                                    options: styles.moreTabsDropdownMenuOptions,
                                    option: styles.moreTabsDropdownMenuOption,
                                    name: styles.moreTabsDropdownMenuOptionName,
                                }}
                                isOpen={isMenuOpen}
                                options={tabs.slice(lastVisibleTabIndex + 1).map(tab => ({
                                    id: tab.id,
                                    name: tab.label,
                                    badgeContent: shouldShowBadge(tab) ? tab.badgeContent : undefined,
                                    onClick: () => {
                                        onTabClick(tab.id);
                                        closeMenu();
                                    }
                                }))}
                                activeOptionId={currentTab}
                              />
                          </OutsideClickHandler>
                          <IconButton
                            dataTest="see-more-tabs-button"
                            Icon={MenuIcon}
                            hoverType="regular"
                            onClick={openMenu}
                            withBadge={tabs.slice(lastVisibleTabIndex + 1).some(tab => !!tab.badgeContent)}
                          />
                      </Box>
                    )}
                </Box>
                {
                  !noSeparator &&
                  <HorizontalSeparator />
                }
            </Box>
            <div className={cx(styles.currentTabContent, classes?.currentTabContent)}>
                <Scrollbar className={styles.scrollbar}>
                    {getComponentForTab(currentTab)}
                    {fadePadding && <div className={styles.fadePadding} />
                    }
                </Scrollbar>
            </div>
        </div>
    );
}
