import React from 'react';

import { AnswerType } from '../../types';
import { AnswerProps } from './types';

import { AnswerDictionary } from './AnswerDictionary';
import { AnswerEntity } from './AnswerEntity';

const Answer: React.FC<AnswerProps> = ({
    answer,
    onChange,
    onRemove,
}) => {
    if (answer.type === AnswerType.ENTITY) {
        return <AnswerEntity
            data-test="entity-answer"
            answer={answer}
            onChange={onChange}
            onRemove={onRemove}
        />;
    }

    return <AnswerDictionary
        data-test="dictionary-answer"
        answer={answer}
        onChange={onChange}
        onRemove={onRemove}
    />;
};

export default Answer;
