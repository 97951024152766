import React from 'react';

import {IconProps} from './iconTypes';

export function PowerIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path className={props.animationClass} fillRule="evenodd" clipRule="evenodd" d="M12.0004 2.3999C12.3318 2.3999 12.6004 2.66853 12.6004 2.9999V12.9999C12.6004 13.3313 12.3318 13.5999 12.0004 13.5999C11.669 13.5999 11.4004 13.3313 11.4004 12.9999V2.9999C11.4004 2.66853 11.669 2.3999 12.0004 2.3999Z" fill="#919AAF"/>
      <path className={props.animationClass} fillRule="evenodd" clipRule="evenodd" d="M9.02869 4.72861C9.17859 5.02414 9.06053 5.38523 8.765 5.53513C5.10453 7.39184 3.52774 12.1003 5.37691 16.0022C7.22718 19.9064 11.5941 21.5049 15.2265 19.5887C18.902 17.6497 20.4693 13.0128 18.6231 9.11724C17.9094 7.61123 16.8467 6.41919 15.434 5.59941C15.1473 5.4331 15.0498 5.06593 15.2161 4.77932C15.3825 4.4927 15.7496 4.39518 16.0362 4.5615C17.6725 5.511 18.8967 6.89247 19.7075 8.60333C21.8251 13.0716 20.0384 18.4071 15.7864 20.6501C11.4912 22.9158 6.40603 20.9757 4.29252 16.5161C2.17792 12.0541 3.9551 6.62932 8.22217 4.46493C8.51769 4.31503 8.87878 4.43308 9.02869 4.72861Z" fill="#919AAF"/>
    </svg>
  );
}
