import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { EmptyStateAstronaut } from '../../icons/EmptyStateAstronaut';
import styles from './NoContactsForFilters.pcss';

export function NoContactsForFilters() {
  return (
    <div
      className={styles.wrapper}
      data-test="empty-state-no-contacts-with-filters"
    >
      <div className={styles.innerWrapper}>
        <EmptyStateAstronaut animationClass={styles.icon} />
        <p className={styles.text}>
          <FormattedMessage
            id="contacts.noContactsForFilters.contactsNotFound"
            values={{
              changeValue: (
                <span className={styles.highlighted}>
                  <FormattedMessage id="contacts.noContactsForFilters.changeValue" />
                </span>
              ),
            }}
          />
        </p>
      </div>
    </div>
  );
}
