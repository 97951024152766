import React from 'react';
import styles from './ScenarioStepsDashboardTileContainer.pcss';

type Props = {
  children: React.ReactNode;
  dataTest?: string;
};

export function ScenarioStepsDashboardTileContainer({
  children,
  dataTest,
}: Props) {
  return (
    <div className={styles.container} data-test={dataTest}>
      {children}
    </div>
  );
}
