import classNames from 'classnames';
import React, { useCallback } from 'react';
import { RowInfo } from 'react-table';

import { InspectorTrainIcon } from '../../../../icons/InspectorTrainIcon';
import { selectedStyles, ToLearnMessage } from '../../../../inspector/Inspector';
import { WithSelectableRowsProps } from '../../../../inspector/Inspector.hoc';
import inspectorStyles from '../../../../inspector/Inspector.pcss';
import { BlockQuestion, Bot, ConditionalQuestion, WordQuestion } from '../../../../surveyCreator/model';
import { IconButton } from '../../../IconButton/IconButton';
import { ColumnFactory } from '../ColumnFactory';

import styles from './ActionsColumn.pcss';
import { DoneButton } from './DoneButton';
import { GoToConversation } from './GoToConversationButton';

export type AfterActionCallback = (id: string) => void;

export type ActionsColumnProps = {
    currentBot: Bot,
    callback: AfterActionCallback,
    questions: BlockQuestion[],
    selectedRows: WithSelectableRowsProps['selectedRows'],
    onTrainClick: (question: WordQuestion, row: number) => void;
}

const renderActionsColumn = ({
    currentBot,
    callback,
    questions,
    selectedRows,
    onTrainClick: onTrainClickProp
}: ActionsColumnProps) => (row: RowInfo) => {
    const question = questions.find(q => q.id === row.original.questionId) as WordQuestion;
    const onTrainClick = useCallback(
        () => onTrainClickProp(question, row.index),
        [onTrainClickProp, question]
    );

    return (
        <div
            className={classNames(
                'actions',
                styles.actions,
                inspectorStyles.cell,
                selectedStyles(selectedRows, row.index)
            )}
        >
            <DoneButton message={row.original as ToLearnMessage} onMark={callback} currentBot={currentBot} />
            <GoToConversation message={row.original as ToLearnMessage} />
            {
                question && <IconButton
                    dataTest="train-row-button"
                    onClick={onTrainClick}
                    Icon={InspectorTrainIcon}
                />
            }
        </div>
    );
};

export const actionsColumn: (props: ActionsColumnProps) => ColumnFactory = (props) => () => ({
    id: 'done',
    Cell: renderActionsColumn(props),
    sortable: false,
    maxWidth: 112,
    minWidth: 112
});
