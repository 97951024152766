import React, {
  FC,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import Select, {
  components,
  ControlProps,
  MultiValueProps,
  ValueContainerProps,
} from 'react-select';
import classnames from 'classnames';
import { useIntl } from 'react-intl';

import { TextLabel } from '../Label';
import { CrossIcon } from '../../icons';

import styles from './EmailMultiSelect.pcss';

const createOption = (label: string) => ({
  label,
  value: label,
});

interface Props {
  emails: string[];
  onChange: (emails: string[]) => void;
  showErrorMessage?: boolean;
  classNames?: string;
}

const emailRegex = /(([^\s@]+@[^\s@]+\.[^\s@]+))$/;

const ValueContainer = ({
  children,
  ...props
}: ValueContainerProps<any, any>) => (
  <components.ValueContainer {...props} className={styles.valueContainer}>
    {children}
  </components.ValueContainer>
);
const Control = ({ children, ...props }: ControlProps<any, any>) => (
  <components.Control {...props} className={classnames(styles.control)}>
    {children}
  </components.Control>
);

const EmailMultiSelect: FC<Props> = ({
  emails,
  onChange,
  showErrorMessage,
  classNames,
}) => {
  const intl = useIntl();
  const ref = useRef(null);

  const [inputValue, setInputValue] = useState('');

  const handleKeyDown: KeyboardEventHandler = (event) => {
    const trimmedInputValue = inputValue.trim();

    if (!trimmedInputValue) {
      return;
    }
    if (['Enter', 'Tab', ' ', ',', ':', ';'].includes(event.key)) {
      setInputValue('');

      if (emails.includes(trimmedInputValue)) {
        return;
      }

      onChange([...emails, trimmedInputValue]);
    }
  };

  const handleDelete = (email: string) => {
    const index = emails.indexOf(email);
    if (index > -1) {
      onChange([...emails.slice(0, index), ...emails.slice(index + 1)]);
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return (
    <div
      className={classnames(styles.wrapper, classNames, {
        [styles.error]: !!showErrorMessage,
      })}
      data-test="email-multi-select"
    >
      {Boolean(showErrorMessage) && (
        <div className={styles.input__tooltip} role="tooltip">
          {intl.messages['reportConfig.modal.scheduledReports.emailValidationMessage']}
        </div>
      )}
      <TextLabel
        label={intl.formatMessage({
          id: 'reportConfig.modal.scheduledReports.emailLabel',
        })}
      />
      <Select
        ref={ref}
        inputValue={inputValue.replace(/[,:;]/g, '')}
        isClearable
        isMulti
        menuIsOpen={false}
        onInputChange={(newValue) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        placeholder=""
        value={emails?.map(createOption)}
        components={{
          Control,
          ValueContainer,
          DropdownIndicator: null,
          ClearIndicator: null,
          MultiValue: ({ children, ...props }: MultiValueProps<any>) => (
            <components.MultiValue
              {...props}
              className={classnames(styles.option, {
                [styles.isValid]: children?.toString().match(emailRegex),
              })}
            >
              {children}
            </components.MultiValue>
          ),
          MultiValueRemove: ({ innerProps, data }) => {
            return (
              <span
                {...innerProps}
                data-test="remove-email-button"
                className={styles.removeIcon}
                onClick={() => handleDelete(data?.value)}
              >
                <CrossIcon />
              </span>
            );
          },
        }}
      />
    </div>
  );
};

export default EmailMultiSelect;
