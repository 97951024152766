import * as React from 'react';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';

import {Schedule} from '../schedule-model';

import {HourDurationInput, TimeDuration} from './HourDurationInput';
import styles from './TimeSettings.pcss';

type Props = {
    schedule: Schedule;
    setSchedule: (schedule: Schedule) => void;
};

const TimeSettingsComponent = ({schedule, setSchedule}: Props & WrappedComponentProps) => {

    return (
        <div className={styles.timeSettings}>
            <div className={styles.callingHours}>
                <div className={styles.timeRangeSettings}>
                    <div className={styles.label}>
                        <FormattedMessage id={'scheduler.timeSettings.callingHours'}/>
                    </div>

                    <HourDurationInput
                        hourDuration={schedule && schedule.timeOfDayRule}
                        onHourDurationChange={(newHourDuration: TimeDuration) => {
                            setSchedule({
                                ...schedule,
                                timeOfDayRule: newHourDuration
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export const TimeSettings = injectIntl(TimeSettingsComponent);
