import { Dictionary } from 'lodash';
import { createAsyncAction } from 'typesafe-actions';

import { Bot } from '../surveyCreator/model';
import { NamesDictionaryItem } from '../utils/NamesDictionary';

export type AddNameDictionaryType = {
    botId: string;
    key: string;
    namesDictionaryItem: NamesDictionaryItem;
};

export type DeleteNameDictionaryType = {
    botId: string;
    key: string;
};

export const fetchNamesDictionary = createAsyncAction(
    'FETCH_NAMES_DICTIONARY',
    'FETCH_NAMES_DICTIONARY_SUCCESS',
    'FETCH_NAMES_DICTIONARY_FAILURE'
)<Bot, Dictionary<NamesDictionaryItem>, any>();

export const addNamesDictionaryAction = createAsyncAction(
    'ADD_NAME_DICTIONARY',
    'ADD_NAME_DICTIONARY_SUCCESS',
    'ADD_NAME_DICTIONARY_FAILURE'
)<AddNameDictionaryType, AddNameDictionaryType, {}>();

export const deleteNamesDictionaryAction = createAsyncAction(
    'DELETE_NAME_DICTIONARY',
    'DELETE_NAME_DICTIONARY_SUCCESS',
    'DELETE_NAME_DICTIONARY_FAILURE'
)<DeleteNameDictionaryType, DeleteNameDictionaryType, {}>();
