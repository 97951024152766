import * as React from 'react';
import {useContext} from 'react';

import {ElementWithLabel} from '../../components/ElementWithLabel/ElementWithLabel';
import {TextAreaWithVariables} from '../../components/TextArea/TextAreaWithVariables';
import {SurveyContext} from '../SurveyCreator';

import styles from './EmailConfigTab.pcss';
import {StepElementProps} from './StepElementProps';

type Props = { message: string, to: string, subject: string };

export const EmailConfigTab = (props: StepElementProps<Props>) => {
  const {variables} = useContext(SurveyContext)
  const handleMessageChange = (value: string) => props.onChange({
    ...props.question,
    message: value
  });
  const handleSubjectChange = (value: string) => props.onChange({
    ...props.question,
    subject: value
  });
  const handleToAddressChange = (value: string) => props.onChange({
    ...props.question,
    to: value
  });
  const availableVariables = variables.getVariablesForCreator();
  return (
    <div className={styles.container}>
      <ElementWithLabel labelId={'survey-creator.emailStep.to'}>
        <TextAreaWithVariables
          dataTest={'to-email-address'}
          value={props.question.to}
          onChange={handleToAddressChange}
          asInput
          availableVariables={availableVariables}
        />
      </ElementWithLabel>
      <ElementWithLabel labelId={'survey-creator.emailStep.subject'}>
        <TextAreaWithVariables
          dataTest={'subject-text'}
          value={props.question.subject}
          onChange={handleSubjectChange}
          availableVariables={availableVariables}
          asInput
        />
      </ElementWithLabel>
      <ElementWithLabel labelId={'survey-creator.emailStep.message'} className={styles.messageBox}>
        <TextAreaWithVariables
          dataTest={'message-text'}
          value={props.question.message}
          onChange={handleMessageChange}
          availableVariables={availableVariables}
        />
      </ElementWithLabel>
    </div>
  );
};
