import React from 'react';

import {IconProps} from './iconTypes';

export function ModuleIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H24V24H0z"/>
                <path className={props.animationClass} d="M13.5.148c.746 0 1.35.604 1.35 1.35v3c0 .746-.604 1.35-1.35 1.35l-6.151-.001.001.901c0 .459.343.837.787.893l.113.007h4.5c1.11 0 2.018.86 2.095 1.95l.005.15-.001.899 7.651.001c.702 0 1.278.535 1.344 1.22l.006.13v3c0 .746-.604 1.35-1.35 1.35l-4.651-.001v1.8l4.651.001c.702 0 1.278.535 1.344 1.22l.006.13v3c0 .746-.604 1.35-1.35 1.35h-12c-.746 0-1.35-.604-1.35-1.35v-3c0-.746.604-1.35 1.35-1.35l6.149-.001v-1.8l-6.149.001c-.702 0-1.278-.535-1.344-1.22l-.006-.13v-3c0-.746.604-1.35 1.35-1.35l3.149-.001.001-.899c0-.459-.343-.837-.787-.893l-.113-.007h-4.5c-1.11 0-2.018-.86-2.095-1.95l-.005-.15-.001-.901-4.649.001c-.702 0-1.278-.535-1.344-1.22l-.006-.13v-3C.15.752.754.148 1.5.148zm9 19.2h-12c-.083 0-.15.067-.15.15v3c0 .083.067.15.15.15h12c.083 0 .15-.067.15-.15v-3c0-.083-.067-.15-.15-.15zm0-7.5h-12c-.083 0-.15.067-.15.15v3c0 .083.067.15.15.15h12c.083 0 .15-.067.15-.15v-3c0-.083-.067-.15-.15-.15zm-9-10.5h-12c-.083 0-.15.067-.15.15v3c0 .083.067.15.15.15h12c.083 0 .15-.067.15-.15v-3c0-.083-.067-.15-.15-.15z"/>
            </g>
        </svg>
    );
}
