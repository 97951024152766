import _ from 'lodash';
import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import InputMask from 'react-input-mask';

import styles from './HourDurationInput.pcss';

type Time = {
    hours?: number;
    minutes?: number;
}

export type TimeDuration = {
    from?: Time;
    to?: Time;
}

type Props = {
    readonly hourDuration: TimeDuration;
    readonly onHourDurationChange: (hourDuration: TimeDuration) => void;
};

const getString = (hourDuration: TimeDuration) => {
    if (!hourDuration || (!hourDuration.from && !hourDuration.to)) {
        return '';
    }

    return `${printTime(hourDuration.from)} - ${printTime(hourDuration.to)}`;

    function printTime(time: Time) {
        return !!time
            ? `${printHourOrMinute(time.hours)}:${printHourOrMinute(time.minutes)}`
            : '';
    }

    function printHourOrMinute(value?: number) {
        return _.isUndefined(value)
            ? ''
            : `${value < 10 ? `0${value}` : value}`;
    }
};

export const HourDurationInput = ({hourDuration, onHourDurationChange}: Props) => {
    const [value, setValue] = useState(getString(hourDuration));

    useEffect(() => {
        setValue(getString(hourDuration));
    }, [hourDuration]);

    const onBlur = useCallback(() => {
        const oldValue = getString(hourDuration);
        const parsedValue = parseNewValue(oldValue, value);

        const newValue = parsedValue
            .replace(/(?<digit>[0-9])_/g, (substring, ...rest) => `0${rest[3].digit}`)
            .replace(/_(?<digit>[0-9])/g, (substring, ...rest) => `0${rest[3].digit}`)
            .replace(/_/g, '0');

        setValue(newValue);

        const change = extractHourDuration(newValue);
        onHourDurationChange(change);
    }, [value, hourDuration, onHourDurationChange]);

    return (
        <InputMask
            data-test="duration-filter"
            className={styles.input}
            type="text"
            value={value}
            mask="99:99 - 99:99"
            maskChar="_"
            placeholder={'--:-- - --:--'}
            onBlur={onBlur}
            onChange={event => {
                setValue(event.target.value)
            }}
        />
    );
};

const hourDurationRegex = /(..):(..) - (..):(..)$/;
const hourDurationRegexMaybe = /(...?):(...?) - (...?):(...?)$/;

function parseNewValue(oldValue: string, newValue: string) {
    const match = newValue.match(hourDurationRegexMaybe);
    if (!match) {
        return oldValue;
    }
    return newValue;
}

const extractHourDuration = (inputValue: string): TimeDuration => {
    const match = inputValue.match(hourDurationRegex);
    if (!!match) {
        return {
            from: {
                hours: getHours(match[1]),
                minutes: getHours(match[2])
            }, to: {
                hours: getHours(match[3]),
                minutes: getHours(match[4])
            }
        };
    }
    return {};

    function getHours(text: string) {
        return parseInt(text.replace('_', '0'), 10);
    }
};
