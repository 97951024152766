import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { EntityAnswer, RawEntity } from '../../model';
import { SurveyCreator } from '../actionTypes';

export const fetchEntityDetails = createAsyncAction(
  SurveyCreator.FETCH_ENTITY_DETAILS,
  SurveyCreator.FETCH_ENTITY_DETAILS_SUCCESS,
  SurveyCreator.FETCH_ENTITY_DETAILS_FAILURE,
)<
  { entity: string; id: string; },
  { name: string, values: EntityAnswer[] },
  { entity: string, error: string }
>();

export const fetchEntitiesDetails = createAsyncAction(
  SurveyCreator.FETCH_ENTITIES_DETAILS,
  SurveyCreator.FETCH_ENTITIES_DETAILS_SUCCESS,
  SurveyCreator.FETCH_ENTITIES_DETAILS_FAILURE,
)<
  {entities: string[]; id: string; },
  {entities: RawEntity[]},
  {entities: string[], error: string}
>();

export const updateEntityDetails = createStandardAction(
  SurveyCreator.UPDATE_ENTITY_DETAILS
)<{ entity: string, answers: EntityAnswer[] }>();

export const deleteModifiedEntityDetails = createStandardAction(
  SurveyCreator.DELETE_MODIFIED_ENTITY_DETAILS
)<{ entity: string }>();

export const saveEntityDetails = createAsyncAction(
  SurveyCreator.SAVE_ENTITY_DETAILS,
  SurveyCreator.SAVE_ENTITY_DETAILS_SUCCESS,
  SurveyCreator.SAVE_ENTITY_DETAILS_FAILURE,
)<{ entity: string, id: string, body: { values: EntityAnswer[] } }, { name: string, values: EntityAnswer[] }, { name: string; error: string }>();