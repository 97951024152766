import React from 'react';

import { IconProps } from './iconTypes';

export function HidePasswordIcon(props: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 7.6c.3 0 .7-.1 1-.1 4.5 0 7.5 4.5 7.5 4.5s-.7 1.1-1.9 2.1M8.5 8.4C6 9.7 4.5 12 4.5 12s3 4.5 7.5 4.5c1.3 0 2.6-.4 3.6-1"
        stroke="#919AAF"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4 13.3c-.3.4-.8.6-1.4.6-1 0-1.9-.8-1.9-1.9 0-.5.2-1 .6-1.4M8.5 8.4l-3-3M15.6 15.5l2.8 2.8M13.4 13.3l2.2 2.2M13.4 13.3l-2.7-2.7M8.5 8.4l2.2 2.2"
        stroke="#919AAF"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
