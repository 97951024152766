import { CommandPaletteItemComponent } from '@talkie/command-palette/components/types';
import cx from 'classnames';
import React, { RefObject, useEffect } from 'react';
import styles from './AnalyticsItemComponent.pcss';
import { AnalyticsItem } from '../hooks/types';

export function PaletteItemComponent({
    className,
    Icon,
    name,
    subtitle,
    onAction,
    onHover,
    isSelected,
    data
}: React.ComponentProps<CommandPaletteItemComponent<AnalyticsItem>>) {
    const ref = React.useRef<HTMLButtonElement | HTMLAnchorElement | null>(null);
    useEffect(() => {
        if (isSelected) {
            ref.current?.scrollIntoView!({
                behavior: 'auto',
                block: 'nearest',
            });
        }
    }, [isSelected]);

    const { href } = data;

    if (href) {
        return <a
            data-test="analytics-command-palette-item"
            ref={ref as RefObject<HTMLAnchorElement>}
            className={cx(
                className,
                styles.analyticsItemComponent,
                {
                    [styles.selected]: isSelected,
                }
            )}
            onMouseEnter={onHover}
            href={href}
            onClick={(e) => {
                e.preventDefault();
                onAction();
            }}
        >
            <div className={styles.text}>
                <div className={styles.name}>
                    {Icon && <Icon className={styles.icon} />}
                    {name}
                </div>
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
            </div>
        </a>;
    }

    return <button
        data-test="analytics-command-palette-item"
        ref={ref as RefObject<HTMLButtonElement>}
        className={cx(
            className,
            styles.analyticsItemComponent,
            {
                [styles.selected]: isSelected,
            }
        )}
        onMouseEnter={onHover}
        onClick={onAction}
    >
        {Icon && <Icon style={{ marginRight: '0.5em' }} />}
        <div className={styles.text}>
            <div className={styles.name}>{name}</div>
            {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
    </button>;
};