import * as React from 'react';
import {FormattedMessage} from 'react-intl';

import {Button} from '../../../../components/Button/Button';
import {BorderedElementWithLabel} from '../../../../components/ElementWithLabel/BorderedElementWithLabel';

import styles from './IntentTraining.pcss';
import {SentenceList} from './SentenceList';

type Props = {
    onRemove?: (item: string) => void,
    onStartTraining?: () => void
    sentences: string[]
};

export const TrainingQueue = ({onStartTraining, onRemove, sentences}: Props) =>
    <BorderedElementWithLabel labelId={'survey-creator.intentDetails.enqueuedSentences'}>
        <>
            <SentenceList isRemovable={true}
                          onRemove={onRemove}
                          type={'queue'}
                          sentences={sentences}
            />
             <div className={styles.trainingContainer}>
                <Button className={styles.trainingButton} buttonType={'primary'}
                        type="button"
                        dataTest="start-training-button"
                        onClick={onStartTraining}>

                    <FormattedMessage id={'survey-creator.intentDetails.startTraining'}/>
                </Button>
            </div>
        </>
    </BorderedElementWithLabel>