import * as React from 'react';

import { RecordingAction } from '../../model';

import { StepElementProps } from '../StepElementProps';
import { ToggleWithLabel } from '../ToggleWithLabel';

export const StopRecordingToggle = ({ question, onChange }: StepElementProps<{ recordingAction?: RecordingAction }>) => {
    const setRecordingAction = () => onChange({ ...question, recordingAction: question.recordingAction === 'stop' ? undefined : 'stop' });
    return (
        <ToggleWithLabel dataTest={'stop-recording'} labelId={'survey-creator.redirectStep.stopRecording'} value={question.recordingAction === 'stop'} onToggle={setRecordingAction} />
    );
};
