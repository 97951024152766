import React from 'react';

import { IndentedStepContent } from '../../../components/layouts/IndentedStepContent/IndentedStepContent';
import { DateQuestion } from '../../model';
import {AnonymizeAnswerToggle} from '../AnonymizeAnswerToggle';
import {DateQuestionValidationsComponent} from '../DateQuestionValidationsComponent';
import {DuplexToggle} from '../DuplexToggle';
import {GoToComponent} from '../GoToComponent';
import { IntentsTab } from '../IntentsTab/IntentsTab';
import {BasicMessages} from '../messages/BasicMessages';
import {RequiredAnswer} from '../RequiredAnswer';
import {SaveAs} from '../SaveAs';
import {PostSpeechTimeoutField} from '../PostSpeechTimeoutField/PostSpeechTimeoutField';
import { PreSpeechTimeoutField } from '../PreSpeechTimeoutField/PreSpeechTimeoutField';
import { RecognizerField } from '../RecognizerField/RecognizerField';

import {TabFactory} from './question-component-factory-model';
import { StepTabType } from './types';

export const dateQuestionFactory: TabFactory<DateQuestion> = {
    [StepTabType.Question]: ({question, onChange, isConditional}) => {
        return (
            <>
                <BasicMessages question={question} onChange={onChange} isConditional={isConditional}/>
            </>
        );
    },
    [StepTabType.General]: ({question, onChange}) => {
        return (
            <>
                <RecognizerField question={question} onChange={onChange} />
                <SaveAs question={question} onChange={onChange}/>
                <RequiredAnswer question={question} onChange={onChange}/>
                <DuplexToggle question={question} onChange={onChange}/>
                <AnonymizeAnswerToggle question={question} onChange={onChange} />
                <PostSpeechTimeoutField question={question} onChange={onChange} />
                <PreSpeechTimeoutField question={question} onChange={onChange} />
            </>
        );
    },
    [StepTabType.Answer]: ({question, onChange}) => {
        return (
            <>
                <DateQuestionValidationsComponent question={question} onChange={onChange} />
                <IndentedStepContent forceNoTopMargin>
                    <GoToComponent question={question} availableOptions={['repeated']} onChange={onChange} />
                </IndentedStepContent>
            </>
        );
    },
    [StepTabType.Intents]: (props) => <IntentsTab {...props} />
};
