import cx from 'classnames';
import React, { useCallback } from 'react';

import { Loadable } from '../../reducers/types'
import { Synonym } from '../../surveyCreator/model';
import { GlobalSynonymsStore, GlobalWords } from '../../surveyCreator/store/globalSynonyms/reducer';
import { DraggableItems } from '../DraggableItems/DraggableItems';
import { Scrollbar } from '../Scrollbar/Scrollbar';
import { StandardInitialProps } from '../types';

import styles from './AnswersList.pcss';

export type Answer = {
  key: string;
  synonyms?: Synonym[];
  id?: string;
}

export type AnswerComponentProps = {
  answer: Answer;
  globalWords: AnswersListProps['globalWords'];
  onChange: (newAnswer: Answer) => void;
  onDelete: React.MouseEventHandler;
}

export type AnswersListProps = StandardInitialProps & {
  answers: Answer[];
  onChange: (answers: Answer[]) => void;
  globalWords?: GlobalWords;
  draggableDataTest?: string;

  AnswerComponent: React.FC<AnswerComponentProps>;
}

export const AnswersList: React.FC<AnswersListProps> = ({
  answers,
  onChange,
  globalWords,
  className,
  dataTest,
  draggableDataTest,
  AnswerComponent
}) => {

  const onChangeSingleAnswer = useCallback((modifiedAnswer: Answer) =>
    onChange(answers.map(answer => answer.id === modifiedAnswer.id ? modifiedAnswer : answer)), [answers, onChange]);

  const onAnswerDelete = (answerId: string) => () => onChange(answers.filter(answer => answer.id !== answerId));

  return <div
    data-test={dataTest}
    className={cx(styles.answersList, styles.specificityLevel, className)}
  >
    <Scrollbar>
      <DraggableItems
        dataTest={draggableDataTest}
        onDragEnd={onChange}
        items={answers}
        renderItem={(answer: Answer) => (
          <AnswerComponent
            answer={answer}
            onChange={onChangeSingleAnswer}
            onDelete={onAnswerDelete(answer.id)}
            globalWords={globalWords} />
        )}
      />
    </Scrollbar>
  </div>
}