import React from 'react';
import { useIntl } from 'react-intl';
import { TranscriptItemDataCollection } from '../models/Transcript';
import { Modal } from '../../components/Modal/Modal';
import { ModalContent } from '../../components/ModalContent/ModalContent';
import { TextLabel } from '../../components/Label';
import { Highlighter, prettyPrintJson } from '../../components/Highlighter';
import styles from './StepLineDetails.pcss';
import { Link } from 'react-router-dom';
import { StepLinkExpired } from './StepLinkExpired';

type Props = {
  onClose: () => void;
  line: TranscriptItemDataCollection;
  stepLink: string;
  title: string;
  isStepLinkExpired: boolean | undefined;
};

export function DataCollectionLineDetails({
  title,
  line,
  onClose,
  stepLink,
  isStepLinkExpired
}: Props) {
  const intl = useIntl();

  return (
    <Modal
      modalSize="medium"
      dataTest="data-collection-step-details"
      noPadding
      noFixedHeight
    >
      <ModalContent
        headerContent={
          <span data-test="data-collection-step-details-header">{title}</span>
        }
        onClose={onClose}
        closeButtonDataTest="data-collection-step-details-close"
        displayBottomSeparator={false}
      >
        <div className={styles.content}>
          <div className={styles.entry}>
            <TextLabel
              className={styles.stepEntry}
              label={intl.formatMessage({
                id: 'conversations.http.modal.stepLink',
              })}
            />
            {
              isStepLinkExpired
                ? <StepLinkExpired />
                : <Link
                  to={stepLink}
                  target="_blank"
                  data-test="data-collection-step-details-step-link"
                  className={styles.stepLink}
                >
                  {line.stepName}
                </Link>
            }
          </div>
          <div className={styles.entry}>
            <TextLabel
              className={styles.stepEntry}
              label={intl.formatMessage({
                id: 'conversations.dataCollection.modal.collectedData',
              })}
            />
            <Highlighter
              dataTest="data-collection-step-details-body"
              dataSensitive="true"
            >
              {prettyPrintJson(line.data)}
            </Highlighter>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
