import { Action } from 'redux';
import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getType } from 'typesafe-actions';

import { fetchCurrentUser } from '../actions/actions';
import { CurrentUserAction } from '../reducers/currentUser';
import { RootState } from '../reducers/reducers';
import { CurrentUser } from '../utils/CurrentUser';

export const fetchCurrentUserEpic: Epic<Action, CurrentUserAction, RootState> = (
    action$: ActionsObservable<CurrentUserAction>,
    _,
    dependencies
): Observable<CurrentUserAction> =>
    action$.pipe(
        ofType(getType(fetchCurrentUser.request)),
        mergeMap(() =>
            from(CurrentUser.get())
                .pipe(
                    map(fetchCurrentUser.success),
                    catchError((error: string) => {
                        return of(fetchCurrentUser.failure(error))
                    })
                ))
    );
