import { useQuery } from 'react-query';

import { HttpClient } from '../utils/HttpClient';

export type ReportsNotifications = {
  notificationsEnabled: boolean,
  showNotificationIcon: boolean,
}

const minuteInMiliseconds = 60 * 1000;

export const useReportsNotifications = (botId: string) => {
  const { data } = useQuery<ReportsNotifications>(
    [botId, 'data-collection', 'notifications'],
    () =>
      HttpClient
        .get({ path: `/bots/${botId}/data-collection/notifications` })
        .then(res => res.data),
    { 
      initialData: {
        notificationsEnabled: true,
        showNotificationIcon: false,
      },
      cacheTime: 5 * minuteInMiliseconds,
      refetchInterval: (notificationsData) => {
        if (notificationsData?.notificationsEnabled) {
          return minuteInMiliseconds;
        }
        return false;
      },
      refetchIntervalInBackground: true,
    }
  );

  return {
    ...data,
  };
};
