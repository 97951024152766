import {Auth} from 'aws-amplify'
import qs from 'qs';
import React from 'react'
import {useIntl} from 'react-intl'
import {useHistory, useLocation} from 'react-router'
import * as Yup from 'yup'

import {FormProps} from '../../forms/types';
import {useForm} from '../../forms/useForm'
import {eraseErrorsOnFocus} from '../../forms/utils';

import {LoginBox} from './components/LoginBox'
import {PageContainer} from '../components/PageContainer'
import {SetNewPasswordForm} from './forms/SetNewPasswordForm'
import {passwordPolicyValidationRegexp} from '../../utils/passwordPolicy';

const initialFields = {
  newPassword: {
    name: 'newPassword',
    labelId: 'auth.signIn.password'
  },
  repeatNewPassword: {
    name: 'repeatNewPassword',
    labelId: 'auth.resetPassword.repeatNewPassword'
  },
}

export const SetNewPasswordFormContainer: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const { search } = useLocation();

  const query = qs.parse(search, { ignoreQueryPrefix: true });
  const validationSchema = Yup.object().shape({
    newPassword: Yup.string().matches(passwordPolicyValidationRegexp, 'auth.errors.complexity'),
    repeatNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'auth.errors.repeat')
  });

  let formProps: FormProps = useForm({
    initialFields,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await Auth.forgotPasswordSubmit(query.username as string, query.code as string, values.newPassword);
        history.push('/login?changedPassword=true')
      } catch (error) {
        if (error.code === 'ExpiredCodeException') {
          history.push('/login/reset-password?changePasswordLinkExpired=true')
        }
      } finally {
        setSubmitting(false);
      }
    }
  });

  formProps = eraseErrorsOnFocus(formProps);

  return <PageContainer>
    <LoginBox header={intl.messages['auth.signIn.setNewPasswordHeader'] as string}>
      <SetNewPasswordForm
        {...formProps}
      />
    </LoginBox>
  </PageContainer>
}
