import classNames from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { Loader } from '../Loader';

import styles from './Button.pcss';
import {IconProps} from '../../icons/iconTypes';

export type ButtonType = 'normal' | 'primary' | 'link' | 'warning' | 'ready' | 'loading' | 'linkLoading';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    dataTest?: string;
    buttonType?: ButtonType;
    translateText?: string;
    Icon?: React.FC<IconProps>;
};

export function Button({
    dataTest,
    buttonType,
    className,
    children,
    translateText,
    Icon,
    disabled,
    ...props
}: ButtonProps) {
    const isLoading = buttonType === 'loading' || buttonType === 'linkLoading';

    return (
        <button
            data-test={dataTest}
            className={classNames(Icon ? styles.buttonWithIcon : styles.button, styles[buttonType || 'primary'], className)}
            disabled={disabled || isLoading}
            {...props}
        >
            {Icon &&  <Icon animationClass={styles.buttonIcon} />}
            {translateText && <FormattedMessage id={translateText} />}
            {isLoading &&
                 <div className={styles.loaderWrapper}>
                    <Loader className={styles.loader} />
                </div>
            }
            {children}
        </button>
    );
}
