import { ActionsObservable, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ActionType, getType, PayloadAction } from 'typesafe-actions';

import { createPutEpic } from '../../../epics/epicUtils';
import { HttpClient } from '../../../utils/HttpClient';
import { isSynonymsArray, RawEntity } from '../../model';
import { SurveyCreator } from '../actionTypes';

import { fetchEntitiesDetails, fetchEntityDetails, saveEntityDetails } from './actions';

export const fetchEntityDetailsEpic = (action$: ActionsObservable<ActionType<typeof fetchEntityDetails.request>>): Observable<PayloadAction<SurveyCreator.FETCH_ENTITY_DETAILS_SUCCESS | SurveyCreator.FETCH_ENTITY_DETAILS_FAILURE, any>> =>
  action$.pipe(
    ofType(getType(fetchEntityDetails.request)),
    mergeMap(({ payload }) =>
      from(HttpClient.get({ path: `/bots/${payload.id}/entities/${payload.entity}` }))
        .pipe(
          map(({ data }: { data: any }) =>
            fetchEntityDetails.success({
              name: payload.entity,
              values: data?.values
                ? data.values.map(value => ({
                  ...value,
                  synonyms: isSynonymsArray(value.synonyms)
                    ? value.synonyms
                    : value.synonyms.map(synonym => ({ verbatim: synonym }))
                }))
                : []
            })),
          catchError((error: string) => of(fetchEntityDetails.failure({ entity: payload.entity, error })))
        )
    )
  );

export const fetchEntitiesDetailsEpic = (action$: ActionsObservable<ActionType<typeof fetchEntitiesDetails.request>>): Observable<PayloadAction<SurveyCreator.FETCH_ENTITIES_DETAILS_SUCCESS | SurveyCreator.FETCH_ENTITIES_DETAILS_FAILURE, any>> =>
  action$.pipe(
    ofType(getType(fetchEntitiesDetails.request)),
    mergeMap(({ payload }) =>
      from(HttpClient.post({ path: `/bots/${payload.id}/entities/search`, body: { entities: payload.entities } }))
        .pipe(
          map(({ data }: { data: RawEntity[]; }) => fetchEntitiesDetails.success({
            entities: data
          })),
          catchError((error: string) => of(fetchEntitiesDetails.failure({ entities: payload.entities, error })))
        )
    )
  );

export const putEntityDetailsEpic = createPutEpic(
  saveEntityDetails,
  ({ id, entity }) => `/bots/${id}/entities/${entity}/values`
)