import axios, { AxiosError, AxiosResponse } from 'axios';
import _, { has } from 'lodash';

import { CancellablePromise, cancellablePromise } from './cancellablePromise';
import { CurrentUser } from './CurrentUser';

interface BaseParams {
    headers?: Record<string, string>;
    path: string;
}

interface GetParams extends BaseParams {
    params?: object;
}

interface PutParams extends BaseParams {
    body: any;
}

interface PostParams extends BaseParams {
    body?: any;
}

axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
        if (error.response && error.response.status === 403 && (error.response.data as { message: string }).message === 'TERMS_OF_SERVICE_NOT_ACCEPTED') {
            return window.location.replace('/terms-of-service');
        }
        return Promise.reject(error);
    }
);

export class HttpClient {
    static get({ path, params }: GetParams, config?: any): CancellablePromise<AxiosResponse> {
        const source = axios.CancelToken.source();
        return cancellablePromise(
            this.verifyToken().then(() =>
                axios.get(HttpClient.getUrl(path), {
                    withCredentials: true,
                    cancelToken: source.token,
                    params,
                    ...config
                })
            ),
            () => source.cancel()
        );
    }

    static async put({ path, body, headers }: PutParams) {
        await this.verifyToken();
        return axios.put(HttpClient.getUrl(path), body, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                ...(headers ?? {})
            }
        });
    }

    static async patch({ path, body }: PutParams) {
        await this.verifyToken();
        return axios.patch(HttpClient.getUrl(path), body, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static post({ path, body }: PostParams): CancellablePromise<AxiosResponse> {
        const source = axios.CancelToken.source();
        return cancellablePromise(
            this.verifyToken().then(() =>
                axios.post(HttpClient.getUrl(path), body, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    cancelToken: source.token
                })
            ),
            () => source.cancel()
        );
    }

    static async delete({ path, params }: GetParams): Promise<AxiosResponse> {
        await this.verifyToken();
        return axios.delete(HttpClient.getUrl(path), {
            withCredentials: true,
            params,
        });
    }

    static async verifyToken() {
        await CurrentUser.token();
    }

    static getUrl(path: string, params?: object) {
        return `/api${path}${HttpClient.prepareParams(params)}`;
    }

    static prepareParams(params?: object) {
        const stringParams = _.entries(params)
            .filter(entry => entry[1] !== undefined)
            .map(entry => `${entry[0]}=${entry[1]}`)
            .join('&');

        return _.isEmpty(stringParams) ? '' : '?' + stringParams;
    }
}
