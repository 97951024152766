import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { ConversationsIcon, HeadsetIcon } from '../icons';
import { getBotUrlId, useCurrentBot } from '../utils/CurrentBot';

import styles from './ConversationViews.pcss';

export function ConversationViews() {
    const currentBot = useCurrentBot();
    return (
        <div className={styles.tabs}>
            <NavLink
                to={`/${getBotUrlId(currentBot)}`}
                activeClassName={styles.active}
                className={styles.link}
                isActive={(match, location) =>
                    match && !location.pathname.startsWith(`/${getBotUrlId(currentBot)}/live`)
                }
            >
                <ConversationsIcon animationClass={styles.svgIcon} />
                <div className={styles.linkText}>
                    <FormattedMessage id={'header.done-conversations'} />
                </div>
            </NavLink>
            <NavLink to={`/${getBotUrlId(currentBot)}/live`}
                     className={styles.link}
                     activeClassName={styles.active}>
                <HeadsetIcon animationClass={styles.svgIcon} />
                <div className={styles.linkText}>
                    <FormattedMessage id={'header.live-conversations'} />
                </div>
            </NavLink>
        </div>
    );
}
