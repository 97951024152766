import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from '../../../components/Box/Box';
import { Button } from '../../../components/Button/Button';
import { InputWithLabel } from '../../../components/InputWithLabel/InputWithLabel';
import { Row } from '../../../components/Row/Row';
import { FormProps } from '../../../forms/types'
import loginStyles from '../components/Login.pcss';

export type LoginFormProps = FormProps & {
  onForgotPasswordClick?: React.MouseEventHandler;
}

export const LoginForm: React.FC<LoginFormProps> = ({
  fieldsProps,
  onSubmit,
  onFormSubmit,
  isSubmitting,
  onForgotPasswordClick
}) => <Box noPadding>
    <form onSubmit={onFormSubmit}>
      <InputWithLabel
        className={loginStyles.loginInput}
        type="text"
        {...fieldsProps.email} />
      <InputWithLabel
        className={loginStyles.loginInput}
        type="password"
        {...fieldsProps.password} />
      <Row className={loginStyles.row}>
        <Button
          dataTest="signInForgotButton"
          type="button"
          onClick={onForgotPasswordClick}
          buttonType="link" translateText="auth.signIn.forgot"
        />
        <Button
          buttonType={isSubmitting ? 'loading' : 'primary'}
          dataTest="signInButton"
          type="submit"
        >
          {!isSubmitting && <FormattedMessage id="auth.signIn.signIn" />}
        </Button>
      </Row>
    </form>
  </Box>;