import _ from 'lodash';
import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import Autosuggest from 'react-autosuggest';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory} from 'react-router';

import {Button} from '../components/Button/Button';
import {ConfirmationModal} from '../components/ConfirmationModal/ConfirmationModal';
import {Input} from '../components/Input';
import {SelectComponent} from '../components/Select/Select';
import {HttpClient} from '../utils/HttpClient';

import styles from './GlobalSynonymsLearning.pcss';

type WordsPerLanguage = { language: string, words: string[] };

export const GlobalSynonymsLearning = () => {
    const history = useHistory();
    const intl = useIntl();
    const [globalWords, setGlobalWords] = useState<WordsPerLanguage[]>();
    const [filteredWords, setFilteredWords] = useState<string[]>([]);
    const [wordInputValue, setWordInputValue] = useState<string>('');
    const [wordSynonym, setWordSynonym] = useState<string>('');
    const [selectedLanguage, setLanguage] = useState<string>('pl');
    const [modalOpened, setModalOpened] = useState<boolean>(false);


    useEffect(() => {
        fetchGlobalSynonyms();
    }, []);
    const wordsInputProps = {
        placeholder: intl.messages['learning.globalSynonyms.selectPlaceholder'] as string,
        value: wordInputValue,
        onChange: (e, {newValue}: any) => setWordInputValue(newValue),
        'data-test': 'global-word-select'
    };

    const wordsForLanguage = (language: string) => _.get(globalWords.find(word => word.language === language), 'words', []);

    const filterWords = (value: { value: string }) => {
        const inputValue = _.trim(value.value).toLowerCase();
        setFilteredWords(() => {
                return inputValue.length === 0
                    ? wordsForLanguage(selectedLanguage)
                    : wordsForLanguage(selectedLanguage).filter(el => el.toLowerCase().includes(inputValue));
            }
        );
    };

    const confirmAddingSynonym = () =>
        HttpClient.post({path: `/admin/global-synonyms`, body: {word: wordInputValue, language: selectedLanguage, synonyms: [{verbatim: wordSynonym}]}})
            .then(() => {
                setModalOpened(false);
                setWordSynonym('');
                setWordInputValue('');
            })
            .then(fetchGlobalSynonyms);

    const addSynonym = () => {
        if (wordInputValue && wordSynonym) {
            if (!wordsForLanguage(selectedLanguage).includes(wordInputValue)) {
                setModalOpened(true);
            } else {
                confirmAddingSynonym();
            }
        }
    };

    const confirmNewSynonym = () => {
        confirmAddingSynonym();
    };

    const cancelNewSynonym = () => {
        setModalOpened(false);
    };

    const fetchGlobalSynonyms = () =>
        HttpClient.get({path: `/admin/global-synonyms`})
            .then(response => {
                const words = _.get(response, 'data', []);
                const groupedWords = Object.entries(_.groupBy(words, 'language'))
                    .map(([language, element]) => ({language, words: element.map(el => el.word)}));
                setGlobalWords(groupedWords);
                setFilteredWords(words.filter(entity => entity.language === selectedLanguage).map(entity => entity.word));
            });

    const availableLanguages = useMemo(() =>
            globalWords ? globalWords.map(el => ({id: el.language, name: el.language})) : [],
        [globalWords]);

    const handleLanguageChange = (value) => {
        setLanguage(value.id);
        setWordInputValue('');
        setFilteredWords(wordsForLanguage(value.id));
    };
    return (
        <div data-test={'global-synonyms'} className={styles.container}>
            <div className={styles.message}>
                <FormattedMessage id={'learning.view.globalSynonyms'} />
            </div>
            <div className={styles.globalWordsContainer} data-test={'global-words-list'}>
                {globalWords && globalWords.map(elements => (
                    <div key={elements.language} data-test={`global-words-list-${elements.language}`} className={styles.wordsList}>
                        <div className={styles.language}>
                            {elements.language}
                        </div>
                        <div className={styles.wordsForLanguage}>
                            {elements.words.map(word => (
                                <div data-test="global-word-entry"
                                     key={word}
                                     className={styles.globalWordItem}
                                     onClick={() => history.push(`global-synonyms/${elements.language}/${word}`)}
                                >
                                    {word}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.globalSynonymsWarning}><FormattedMessage id={'learning.view.globalSynonymsWarning'} /></div>
            <div className={styles.globalSynonymsLearning}>
                <Input value={wordSynonym} width={400} onChange={setWordSynonym} dataTest="global-synonym-input"
                       placeholder={intl.messages['learning.globalSynonyms.synonymPlaceholder'] as string} />
                <div data-test={'global-synonym-language-select'} className={styles.languageSelect}>
                    <SelectComponent
                        value={{id: selectedLanguage, name: selectedLanguage}}
                        onChange={handleLanguageChange}
                        options={availableLanguages}
                    />
                </div>
                <Autosuggest
                    suggestions={filteredWords}
                    onSuggestionsFetchRequested={filterWords}
                    onSuggestionsClearRequested={_.noop}
                    shouldRenderSuggestions={() => true}
                    getSuggestionValue={suggestion => suggestion}
                    renderSuggestion={(word: string) => <div data-test="suggested-global-word">{word}</div>}
                    inputProps={wordsInputProps}
                />
                <Button buttonType={'normal'} dataTest="add-global-synonym-button" className={styles.button} onClick={addSynonym}
                        disabled={!wordInputValue || !wordSynonym}>
                    <FormattedMessage id={'learning.view.addGlobalSynonym'} />
                </Button>
            </div>
            {modalOpened && (
                <ConfirmationModal
                    onConfirm={confirmNewSynonym}
                    onCancel={cancelNewSynonym}
                    renderText={() => <FormattedMessage id={'learning.globalSynonyms.newSynonymConfirmationText'} />} />
            )}
        </div>
    );
};
