import moment from 'moment'
import React, { useCallback } from 'react'

import { DatePicker } from '../components/Dates/DatePicker/DatePicker';

export type UpdateDateFilterType = ({ startDate, endDate }: {
  startDate?: number,
  endDate?: number
}) => void;

export type DateFilterProps = {
  startDate?: number;
  endDate?: number;
  updateFilters: (filters: Partial<UpdateDateFilterType>) => void;
  timezone?: string;
}

export const DateFilter: React.FC<DateFilterProps> = ({
  startDate,
  endDate,
  updateFilters,
  timezone
}) => {
  const onChange = useCallback((
    start: string,
    end: string
  ) => {
    updateFilters({
      startDate: start ? moment.tz(start, timezone).startOf('day').valueOf() : undefined,
      endDate: end ? moment.tz(end, timezone).endOf('day').valueOf() : undefined,
      period: undefined
    });
  },
    [updateFilters]);

  return <div>
    <DatePicker
      startDate={startDate ? moment.tz(startDate, timezone).format('YYYY-MM-DD') : undefined}
      endDate={endDate ? moment.tz(endDate, timezone).format('YYYY-MM-DD') : undefined}
      onChange={onChange}
    />
  </div>
}
