import { AxiosResponse } from 'axios';
import { Action } from 'redux';
import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getType } from 'typesafe-actions';

import { fetchBots } from '../actions/actions';
import { fetchNamesDictionary } from '../actions/namesDictionaryActions';
import { BotsAction } from '../reducers/bots';
import { NamesDictionary, NamesDictionaryAction } from '../reducers/namesDictionary';
import { RootState } from '../reducers/reducers';
import { Bot } from '../surveyCreator/model';
import { HttpClient } from '../utils/HttpClient';

export const fetchBotsEpic: Epic<Action, BotsAction, RootState> = (
    action$: ActionsObservable<BotsAction>
): Observable<BotsAction> =>
    action$.pipe(
        ofType(getType(fetchBots.request)),
        mergeMap(() =>
            from(HttpClient.get({ path: '/me/bots' })).pipe(
                map(({ data }: AxiosResponse<Bot[]>) => fetchBots.success(data)),
                catchError((error: string) => of(fetchBots.failure(error)))
            )
        )
    );

export const fetchNamesDictionaryEpic: Epic<Action, NamesDictionaryAction, RootState> = (
    action$: ActionsObservable<NamesDictionaryAction>
): Observable<NamesDictionaryAction> => {
    return action$.pipe(
        ofType(getType(fetchNamesDictionary.request)),
        mergeMap(action => {
            return from(HttpClient.get({ path: `/bots/${action.payload.id}/names-dictionary` })).pipe(
                map(({ data }: AxiosResponse<NamesDictionary>) => fetchNamesDictionary.success(data))
            );
        })
    );
};
