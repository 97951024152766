import { Auth } from 'aws-amplify';
import qs from 'qs';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import {useHistory, useLocation} from 'react-router';

import { useForm } from '../../forms/useForm';
import { AlertIcon } from '../../icons';

import { LoginBox } from './components/LoginBox';
import { PageContainer } from '../components/PageContainer';
import { ResetPasswordForm } from './forms/ResetPasswordForm';

const initialFields = {
  email: {
    name: 'email',
    labelId: 'auth.signIn.email'
  }
};

export const ResetPasswordFormContainer: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const { search } = useLocation();
  const query = qs.parse(search, { ignoreQueryPrefix: true });

  const {
    onSubmit,
    onFormSubmit,
    fieldsProps,
    isSubmitting
  } = useForm({
    initialFields,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await Auth.forgotPassword(values.email);
        history.push('/login/reset-password-confirmation');
      } catch (e) {
        // tslint:disable-next-line: no-console
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    }
  });

  const onBackToLoginClick = useCallback(() => history.push('/login'), []);

  return <PageContainer>
    <LoginBox header={intl.messages['auth.signIn.header'] as string}
              message={query.changePasswordLinkExpired &&  intl.formatMessage({
                id: 'auth.newPassword.changePasswordLinkExpired'
              })} MessageIcon={query.changePasswordLinkExpired && AlertIcon}
    >
      <ResetPasswordForm
        isSubmitting={isSubmitting}
        fieldsProps={fieldsProps}
        onSubmit={onSubmit}
        onFormSubmit={onFormSubmit}
        onBackToLoginClick={onBackToLoginClick}
        resetPasswordDescription={intl.messages['auth.signIn.resetPasswordDescription'] as string}
      />
    </LoginBox>
  </PageContainer>;
}