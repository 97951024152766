import _ from 'lodash';
import Papa, { ParseResult } from 'papaparse';
import {default as React, useCallback, useState} from 'react';
import { FormattedMessage } from 'react-intl';
import * as XLSX from 'xlsx';
import moment from 'moment';

import { Button } from '../components/Button/Button';
import { MarkdownContent } from '../components/MarkdownContent/MarkdownContent';
import { Modal } from '../components/Modal/Modal';
import { ModalContent } from '../components/ModalContent/ModalContent';
import { Bot } from '../surveyCreator/model';
import { HttpClient } from '../utils/HttpClient';
import { toString } from '../utils/utils';
import styles from './AddContactsModal.pcss';
import { Alert } from '../components/Alert/Alert';
import { Messages } from '../i18n/i18n';
import {useVariables} from '../views/Settings/AdminTabContent/Variables.hook';
import FileSaver from 'file-saver';
import {DownloadIcon} from '../icons/DownloadIcon';

const supportedDialerErrors = [
    'SHOULD_HAVE_AT_LEAST_ONE_CONTACT',
    'SHOULD_HAVE_A_CORRECT_NUMBER_OF_DIGITS',
    'PHONE_NUMBER_FORMAT_IS_INVALID',
    'PHONE_NUMBER_COLUMN_REQUIRED',
    'PHONE_NUMBER_REQUIRED',
    'PHONE_NUMBER_COUNTRY_CALLING_CODE_MISMATCH',
    'UNSUPPORTED_FILE_EXTENSION',
]

function allEntriesEmpty(entries: Record<string, string>) {
    return Object.values(entries).every((value) => value === '');
}

const dayInMiliseconds = 24 * 60 * 60 * 1000;
const timeSince1904InMiliseconds = 1462 * dayInMiliseconds;

// adjust base offset based on excel worksheet formatting option - 1904 or 1900
function adjustExcelEpochTime(epoch: number, isDate1904: boolean = false) {
    if (isDate1904) {
        return epoch - timeSince1904InMiliseconds;
    }

    return epoch
}

// address rounding issues when miliseconds are missing
// for example for 7:10:00.000 excel will return 7:09:59.999    
function roundExcelEpochTime(epoch: number) {
    return Math.round(epoch / 1000) * 1000
}

function getExcelDateFormat(timezone: string) {
    if (timezone.includes('America')) {
        return 'MM/DD/YYYY';
    } 
    return 'DD/MM/YYYY';
}

function parseExcelDateToString(date: Date, timezone: string, isDate1904: boolean = false) {    
    const utcTimezone = 'UTC'
    const dateWithoutTimezone = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS')
    const dateWithoutTimezoneAsDate = moment.tz(dateWithoutTimezone, utcTimezone).toDate();

    const epoch = _.flow([
        data => adjustExcelEpochTime(data, isDate1904),
        roundExcelEpochTime
    ])(dateWithoutTimezoneAsDate.getTime())
    
    const dateFromEpoch = moment.tz(epoch, utcTimezone)
    const year = dateFromEpoch.year();
    const hours = dateFromEpoch.hours();
    const minutes = dateFromEpoch.minutes();
    const seconds = dateFromEpoch.seconds();

    const hasDate = year > 1900;
    const hasTime = !(hours === 0 && minutes === 0 && seconds === 0);

    const dateFormat = getExcelDateFormat(timezone);
    if (hasDate && hasTime) {
        return dateFromEpoch.format(`${dateFormat} HH:mm`);
    } else if (hasDate) {
        return dateFromEpoch.format(dateFormat);
    } else {
        return dateFromEpoch.format('HH:mm');
    }
}

interface AddContactsModalProps {
    currentBot: Bot;
    afterAddCallback: () => void;
    timezone: string;
    modalOpened: boolean
    closeModal: () => void;
}

export const AddContactsModal = ({ currentBot, afterAddCallback, modalOpened, closeModal, timezone }: AddContactsModalProps) => {
    const [addContactsSuccessModalOpened, toggleAddContactsSuccessModal] = useState(false);
    const [partiallyFailedStatistics, setPartiallyFailedStatistics] = useState<{
        created: number,
        failed: number
    } | null>(null);
    const [errorCode, setErrorCode] = useState<string | undefined>();
    const [lastUploadedFilename, setLastUploadedFilename] = useState('');

    const stringifyXlsxValue = (value, isDate1904: boolean) => {
        if (value instanceof Date) {
            return parseExcelDateToString(value, timezone, isDate1904);
        }

        return toString(value);
    }

    const transformXlsxData = (rows: any[], isDate1904: boolean) => rows.map((entry) => {
        return _.mapValues(entry, cell => stringifyXlsxValue(cell, isDate1904));
    });

    const setPhoneNumberColumnMissingError = (filename: string) => {
        setLastUploadedFilename(filename);
        setErrorCode('PHONE_NUMBER_COLUMN_REQUIRED')
    }

    const handleFileUpload = async (event: any) => {
        const file = event.target.files[0] as File;

        if (file.name.endsWith('.csv')) {
            Papa.parse(file, {
                header: true,
                worker: true,
                skipEmptyLines: true,
                complete(results: ParseResult<any>) {
                    const hasPhoneNumberColumn = (results.meta.fields ?? []).includes('phoneNumber')

                    if (!hasPhoneNumberColumn) {
                        return setPhoneNumberColumnMissingError(file.name);
                    }
                    
                    sendContacts(results.data.filter(entry => !allEntriesEmpty(entry)));
                },
            });
        } else if (file.name.endsWith('.xlsx')) {
            const reader = new FileReader();
            reader.onload = (evt) => {
                const dataStream = evt.target.result;
                const workbook = XLSX.read(dataStream, { type: 'binary', cellDates: true });
                const isDate1904 = workbook.Workbook.WBProps.date1904;
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];

                const header = XLSX.utils.sheet_to_json<string[]>(worksheet, {raw: true, header: 1, range: 'A1:ZZ1'})[0];
                const hasPhoneNumberColumn = header.includes('phoneNumber')

                if (!hasPhoneNumberColumn) {
                    return setPhoneNumberColumnMissingError(file.name);
                } 
                
                const data = XLSX.utils.sheet_to_json(worksheet);
                const transformed = transformXlsxData(data, isDate1904)
                sendContacts(transformed);
            };
            reader.readAsBinaryString(file);
        } else {
            setLastUploadedFilename(file.name);
            setErrorCode('UNSUPPORTED_FILE_EXTENSION')
        }

        function sendContacts(data: any[]) {
            const dataToUpload = data.map((item: any) => {
                const addedVariables = _.omit(item, ['phoneNumber', 'interactionTime']);
                return {
                    phoneNumber: item.phoneNumber,
                    interactionTime: item.interactionTime,
                    ...(_.isEmpty(addedVariables) ? {} : { variables: addedVariables }),
                };
            });

            HttpClient
                .post({
                    path: `/bots/${currentBot.id}/contacts`,
                    body: dataToUpload,
                })
                .then((resp) => {
                    closeModal()
                    if (resp.status === 207) {
                        setPartiallyFailedStatistics({ created: resp.data.contactsCreated, failed: resp.data.responses.length });
                    }
                    setErrorCode(undefined);
                    toggleAddContactsSuccessModal(true);
                })
                .catch((error) => {
                    if (error.response.data.type === 'validation') {
                        const code = 
                            supportedDialerErrors.includes(error.response.data.errorCode)
                                ? error.response.data.errorCode 
                                : 'UNKNOWN_ERROR';

                        setErrorCode(code);
                    } else {
                        setErrorCode('UNKNOWN_ERROR');
                    }
                }).finally(() => {
                    setLastUploadedFilename(file.name);
                })
        }
    };

    const handleCloseModal = () => {
        setErrorCode(undefined);
        closeModal();
    }

    const {variables} = useVariables();

    const downloadCsvTemplate = useCallback(async () => {
        const contactVariables = (variables ?? []).filter(variable => variable.origin === 'contact');
        const variableNames = contactVariables.map(variable => variable.name);

        const csv = Papa.unparse([['phoneNumber', ...variableNames]]);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        FileSaver.saveAs(blob, 'template.csv');

    }, [variables]);

    const downloadExcelTemplate = useCallback(async () => {
        const contactVariables = (variables ?? []).filter(variable => variable.origin === 'contact');
        const variableNames = contactVariables.map(variable => variable.name);

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet([{}]);
        const header = ['phoneNumber', ...variableNames];
        XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: 'A1' });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'template.xlsx');
    }, [variables]);



    return (
        <>
            {addContactsSuccessModalOpened && (
                <Modal
                    className={styles.newModalContent}
                    position="top"
                    dataTest="add-contacts-success-modal"
                    noPadding
                    noFixedHeight
                >
                    <ModalContent
                        headerContent={<FormattedMessage id={'contacts.add.title'} />}
                        footerContent={
                            <Button
                                className={styles.newModalButton}
                                dataTest={'add-contacts-success-modal-close'}
                                translateText="contacts.add.finish"
                                onClick={() => {
                                    toggleAddContactsSuccessModal(false);
                                    afterAddCallback();
                                }}
                            />
                        }
                    >
                        {
                            partiallyFailedStatistics ? (
                                <MarkdownContent
                                    data-test="add-contacts-success-modal-content"
                                    contentKey="contacts.add.partiallyFailedBody"
                                    className={styles.content}
                                    extraValues={{
                                        filename: lastUploadedFilename,
                                        contactsCreated: partiallyFailedStatistics.created.toString(),
                                        contactsFailed: partiallyFailedStatistics.failed.toString(),
                                    }}
                                />
                            ) : (
                                <MarkdownContent
                                    data-test="add-contacts-success-modal-content"
                                    contentKey="contacts.add.successBody"
                                    className={styles.content}
                                    extraValues={{
                                        filename: lastUploadedFilename,
                                    }}
                                />
                            )
                        }
                    </ModalContent>
                </Modal>
            )}
            {modalOpened && !Boolean(errorCode) && 
                <Modal
                    position="top"
                    dataTest="add-contacts-modal"
                    noPadding
                    noFixedHeight
                    modalSize="medium"
                >
                    <ModalContent
                        headerContent={<FormattedMessage id="contacts.add.title" />}
                        footerContent={
                            <div className={styles.footer}>
                                <span>
                                    <FormattedMessage id="contacts.add.footer.text"/>
                                </span>
                                <div>
                                    <Button
                                        buttonType="link"
                                        onClick={downloadExcelTemplate}
                                        dataTest="download-xlsx-template-button"
                                    >
                                        <DownloadIcon/> {'Format .xls (Excel)'}
                                    </Button>
                                    <Button
                                        style={{width: 'fit-content'}}
                                        buttonType="link"
                                        onClick={downloadCsvTemplate}
                                        dataTest="download-csv-template-button"
                                    >
                                        <DownloadIcon/> {'Format .csv'}
                                    </Button>
                                </div>
                                <Button
                                    // TODO: rename to .modalButton when replacing the old modal
                                    className={styles.newModalButton}
                                >
                                    <FormattedMessage id="contacts.add.confirm"/>
                                    <input
                                        type="file"
                                        data-test="upload-input"
                                        name="file-upload"
                                        multiple={false}
                                        className={styles.fileInput}
                                        title=""
                                        onChange={(event: any) => handleFileUpload(event)}
                                    />
                                </Button>
                            </div>
                        }
                        onClose={handleCloseModal}
                        closeButtonDataTest={'add-contacts-modal-close'}
                    >
                        <MarkdownContent
                            contentKey="contacts.add.guidelines"
                            className={styles.content}
                        />
                        <MarkdownContent contentKey="contacts.add.body" />
                    </ModalContent>
                </Modal>
            }
            {
                modalOpened && Boolean(errorCode) && (
                    <Modal
                        position="top"
                        dataTest="add-contacts-modal"
                        noPadding
                        noFixedHeight
                        modalSize="medium"
                    >
                        <ModalContent
                            headerContent={<FormattedMessage id="contacts.add.title" />}
                            onClose={handleCloseModal}
                            footerContent={
                                <Button
                                    // TODO: rename to .modalButton when replacing the old modal
                                    className={styles.newModalButton}
                                >
                                    <FormattedMessage id="contacts.add.reload" />
                                    <input
                                        type="file"
                                        data-test="reload-input"
                                        name="file-upload"
                                        multiple={false}
                                        className={styles.fileInput}
                                        title=""
                                        onChange={(event: any) => handleFileUpload(event)}
                                    />
                                </Button>
                            }
                            closeButtonDataTest="add-contacts-modal-close"
                        >
                            <Alert
                                variant="error"
                                className={styles.alert}
                                dataTest="add-contacts-error"
                            >
                                <MarkdownContent contentKey="contacts.add.error.validation" extraValues={{
                                    filename: lastUploadedFilename
                                }} />
                                <MarkdownContent contentKey={`contacts.add.error.${errorCode}` as keyof Messages} />
                            </Alert>
                            <MarkdownContent contentKey="contacts.add.body" />

                        </ModalContent>
                    </Modal>
                )}
        </>
    );
};
