import * as React from 'react';
import {useContext} from 'react';

import {ElementContainer} from '../../components/ElementContainer/ElementContainer';
import {ElementWithLabel} from '../../components/ElementWithLabel/ElementWithLabel';
import {SelectComponent} from '../../components/Select/Select';
import {TextAreaWithVariables} from '../../components/TextArea/TextAreaWithVariables';
import {SurveyContext} from '../SurveyCreator';

import styles from './HttpStepComponent.pcss';
import {StepElementProps} from './StepElementProps';

type Props = {
  method: string,
  body?: string,
  url?: string
}

export const HttpStepDefinition = ({question, onChange}: StepElementProps<Props>) => {
  const {variables} = useContext(SurveyContext);

  const handleUrlChange = (value: string) =>
    onChange({
      ...question,
      url: value
    });

  const handleMethodChange = (value: any) =>
    onChange({
      ...question,
      body: '',
      method: value.id
    });

  const handleBodyChange = (body: string) =>
    onChange({
      ...question,
      body
    });

  const isBodyAvailable = () => question.method !== 'get';
  const availableVariables = variables.getVariablesForCreator();
  return (
    <ElementContainer>
      <ElementWithLabel className={styles.group} noPadding labelId={'survey-creator.httpStep.url'}>
        <TextAreaWithVariables
          dataTest={'http-request-url'}
          value={question.url}
          onChange={handleUrlChange}
          availableVariables={availableVariables}
          asInput
        />
      </ElementWithLabel>
      <ElementWithLabel className={styles.group} noPadding labelId={'survey-creator.httpStep.method'}>
        <div data-test={'http-request-method'}>
          <SelectComponent
            options={[
              {id: 'get', name: 'get'},
              {id: 'put', name: 'put'},
              {id: 'post', name: 'post'},
              {id: 'delete', name: 'delete'},
              {id: 'patch', name: 'patch'},
            ]}
            value={{id: question.method, name: question.method}}
            onChange={handleMethodChange}
          />
        </div>
      </ElementWithLabel>
      {isBodyAvailable() && (
        <ElementWithLabel className={styles.group} labelId={'survey-creator.httpStep.body'} noPadding>
          <TextAreaWithVariables
            dataTest={'http-request-body'}
            onChange={handleBodyChange}
            value={question.body}
            availableVariables={availableVariables}
          />
        </ElementWithLabel>
      )}
    </ElementContainer>
  );
};
