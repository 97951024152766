import cx from 'classnames';
import React, { ReactNode } from 'react';

import styles from './Table.pcss';

/** @deprecated prefer src/components/Table/Table.ts */
export const Header = ({ children, className }: { children?: ReactNode | ReactNode[], className?: string }) => (
  <div className={cx(styles.header, className)}>
    {children}
  </div>
);
