import cx from 'classnames';
import * as React from 'react';

import styles from './VerticalSeparator.pcss';

export type VerticalSeparatorProps = {
  noMargin?: boolean;
}
export const VerticalSeparator: React.FC<VerticalSeparatorProps> = ({
  noMargin
}) => <div className={
  cx(
    styles.verticalSeparator,
    {[styles.noMargin]: noMargin}
    )
}/>
