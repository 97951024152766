import {default as React} from 'react';
import {useIntl} from 'react-intl';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import styles from './ReadOnlyField.pcss';
import {CopyIcon} from '../../icons/CopyIcon';
import {Button} from '../Button/Button';
import {TooltipComponent} from '../Tooltip/Tooltip';

export type ReadOnlyFieldProps = {
  labelId: string,
  value: string,
  dataTest?: string
  dataTestCopyButton?: string
  withCopyButton?: boolean
  hidden?: boolean
  dataSensitive?: string
}

export const ReadOnlyField: React.FC<ReadOnlyFieldProps> = ({labelId, value, dataTest, dataTestCopyButton, withCopyButton = false, hidden = false, dataSensitive }) => {
  const intl = useIntl();
  const [isHidden, setIsHidden] = React.useState(hidden);
  const [copied, setCopied] = React.useState(false);

  const onCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  }

  return <div>
    <div className={styles.label}>
      {intl.messages[labelId] as string}
    </div>
      {<div className={styles.value} data-test={dataTest} data-sensitive={dataSensitive}>
          { isHidden
              ? <Button buttonType={'link'} onClick={() => setIsHidden(false)}>{intl.messages['survey.config.tabs.api.show']}</Button>
              : value
          }
          {withCopyButton && <>
              <TooltipComponent
                  hideArrow={false}
                  tooltipText={intl.messages['survey.config.tabs.api.copy'] as string}
              >
                  <CopyToClipboard text={value} onCopy={onCopy}>
                      <div style={{cursor: 'pointer'}} data-test={dataTestCopyButton}>
                          <CopyIcon/>
                      </div>
                  </CopyToClipboard>
              </TooltipComponent>
            {copied && <div className={styles.copied}>{intl.messages['survey.config.tabs.api.copied']}</div>}
          </>}
      </div>}
  </div>
}