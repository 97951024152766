import React from 'react';

import { IconProps } from './iconTypes';

export function EntityButtonIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-221 -77) translate(12 69) translate(209 8)">
              <path d="M0 0H24V24H0z" />
              <path className={props.animationClass} d="M15.64 5.79C15.32 4.214 13.824 3 12 3S8.679 4.213 8.36 5.79h-2.5C4.818 5.79 4 6.588 4 7.536v11.72C4 20.204 4.818 21 5.86 21h12.28c1.042 0 1.86-.797 1.86-1.744V7.535c0-.948-.818-1.744-1.86-1.744h-2.5zm-1.203.629c0 .346.283.628.633.628h3.07c.314 0 .595.202.595.488v11.72c0 .287-.28.49-.595.49H5.86c-.314 0-.595-.203-.595-.49V7.536c0-.286.28-.488.595-.488h3.07c.35 0 .633-.282.633-.628 0-1.21 1.105-2.163 2.437-2.163 1.332 0 2.437.952 2.437 2.163zm-5.81 10.674h6.747c.349 0 .632-.281.632-.628 0-.346-.283-.628-.632-.628H8.626c-.349 0-.632.282-.632.628 0 .347.283.628.632.628zm0-2.79h6.747c.349 0 .632-.282.632-.629 0-.346-.283-.628-.632-.628H8.626c-.349 0-.632.282-.632.628 0 .347.283.628.632.628zm0-2.791h6.747c.349 0 .632-.282.632-.628 0-.347-.283-.628-.632-.628H8.626c-.349 0-.632.281-.632.628 0 .346.283.628.632.628zm3.42-6.122L12 5.389c-.505 0-.914.406-.914.907 0 .5.41.907.914.907.505 0 .914-.406.914-.907 0-.486-.384-.882-.867-.906z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}