import {CommandPaletteSearchComponent} from '@talkie/command-palette/components/types';
import React from 'react';
import styles from './AnalyticsSearchComponent.pcss';
import cx from 'classnames';

export const AnalyticsSearchComponent: CommandPaletteSearchComponent = ({className, value, onChange}) =>
    (
        <input
            data-test="analytics-command-palette-search"
            className={cx(
                className,
                styles.search,
            )}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Search..."
            autoFocus
        />
    )