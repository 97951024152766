import * as React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Store } from 'redux';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { PostHogProvider } from 'posthog-js/react';
import { Helmet } from 'react-helmet';

import App from './App';
import { CustomIntlProvider } from './i18n/CustomIntlProvider';
import { LoginRouter } from './views/Login/router';
import { buildQueryClient } from './QueryClient';
import { ToastProvider } from './components/Toast/Toast';
import TermsOfServicePage from './views/TermsOfService/TermsOfServicePage';
import { Register } from './views/Login/Register';
import { postHog } from './utils/postHog';
import useBranding from './hooks/useBranding/useBranding';

type Props = {
  store: Store;
};

export const queryClient = buildQueryClient();

const renderWithIntlAndToastProviders = (Component: React.ComponentType) => (
  <CustomIntlProvider>
    <Component />
    <ToastProvider />
  </CustomIntlProvider>
);

export const AppWrapper = ({ store }: Props) => {
  const { title, favicon } = useBranding();

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="shortcut icon" href={favicon} />
      </Helmet>
      <PostHogProvider client={postHog}>
        <Provider store={store}>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <Switch>
                <Route
                  path="/register"
                  render={() => renderWithIntlAndToastProviders(Register)}
                />
                <Route
                  path="/login"
                  render={() => renderWithIntlAndToastProviders(LoginRouter)}
                />
                <Route
                  path="/terms-of-service"
                  render={() => renderWithIntlAndToastProviders(TermsOfServicePage)}
                />
                <Route
                  path="/:orgId?/:botId?"
                  render={() => renderWithIntlAndToastProviders(App)}
                />
              </Switch>
              <ReactQueryDevtools />
            </QueryClientProvider>
          </BrowserRouter>
        </Provider>
      </PostHogProvider>
    </>
  );
};
