import Slider from 'rc-slider'
import { SliderProps } from 'rc-slider/lib/Slider';
import React from 'react'

import styles from './Range.pcss';

export type RangeProps = {
  min: number;
  max: number;
  step?: number;
  value?: number;
  onChange?: SliderProps['onChange'];
}

export const Range: React.FC<RangeProps> = ({
  min,
  max,
  step,
  value,
  onChange
}) => <div
  className={styles.range}
>
    <Slider
      className={styles.slider}
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={onChange}
    />
  </div>