import cx from 'classnames';
import * as React from 'react';

import { FormValue } from '../../forms/types';
import { ElementWithLabel } from '../ElementWithLabel/ElementWithLabel';
import { ErrorPopup } from '../ErrorPopup/ErrorPopup';
import { Input } from '../Input';

import styles from './InputWithLabel.pcss';

type InputWithLabelProps = Partial<Record<'borderEnabled' | 'noPadding', boolean>> & {
    value: FormValue;
    onChange: (value: FormValue) => void;
    onBlur?: React.FocusEventHandler;
    onFocus?: React.FocusEventHandler;
    dataTest?: string;
    labelId: string;
    inputWidth?: number;
    className?: string;
    disabled?: boolean;
    error?: string;
    Icon?: React.FC;
    iconClass?: string;
    errorClass?: string;
    type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
    name?: React.InputHTMLAttributes<HTMLInputElement>['name'];
}

export const InputWithLabel: React.FC<InputWithLabelProps> = ({
    value,
    onChange,
    onBlur,
    onFocus,
    name,
    dataTest,
    labelId,
    inputWidth,
    className,
    disabled,
    borderEnabled,
    noPadding,
    type,
    error,
    Icon,
    iconClass,
    errorClass
}) => {
    return (
        <ElementWithLabel
            labelId={labelId}
            borderEnabled={borderEnabled}
            noPadding={noPadding}
            className={cx(styles.box, className)}
            inner
        >
            {
                Boolean(error) && <ErrorPopup
                    dataTest="input-error"
                    className={cx(styles.error, errorClass)}
                    messageId={error}
                />
            }
            {
                Icon
                    ? <div className={styles.inputWithIcon}>
                        <Input
                            disabled={disabled}
                            type={type}
                            onChange={onChange}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            name={name}
                            value={value as string}
                            width={inputWidth}
                            dataTest={dataTest}
                            className={cx(
                                styles.input,
                                { [styles.inputError]: Boolean(error) }
                            )}
                        />
                        <div className={cx(styles.icon, iconClass)}>
                            <Icon />
                        </div>
                    </div>
                    : <Input
                        disabled={disabled}
                        type={type}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        name={name}
                        value={value as string}
                        width={inputWidth}
                        dataTest={dataTest}
                        className={cx(
                            styles.input,
                            { [styles.inputError]: Boolean(error) }
                        )}
                    />
            }

        </ElementWithLabel>
    );
}
