import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Loader } from '../../components/Loader';
import { useGetStatisticsSettings } from './hooks/useGetStatisticsSettings';
import { ScenarioStepsDashboardEmptyState } from './ScenarioStepsDashboardEmptyState';
import { ScenarioStepsDashboardTile } from './ScenarioStepsDashboardTile';
import { StatisticsSectionsNavigation } from '../StatisticsSectionsNavigation/StatisticsSectionsNavigation';
import { Button } from '../../components/Button/Button';
import { IconType } from '../../icons/IconType';
import { ScenarioStepsDashboardEditView } from './ScenarioStepsDashboardEditView';
import { ScenarioStepMetric } from '../types';
import { ScenarioStepsDashboardTileContainer } from './ScenarioStepsDashboardTileContainer';
import { ScenarioStepsDashboardUserHelpTile } from './ScenarioStepsDashboardUserHelpTile';
import { Dictionary } from 'lodash';

type StatisticSectionConfig = {
  name: string;
  icon: IconType;
};

function filterPinnedScenarioSteps(
  scenarioSteps: Dictionary<ScenarioStepMetric>,
  pinnedSteps: string[]
) {
  return pinnedSteps.map((stepId) => scenarioSteps[stepId]).filter(Boolean);
}

type Props = {
  botId?: string;
  scenarioStepMetrics: Dictionary<ScenarioStepMetric>;
  statisticsSections: StatisticSectionConfig[];
  activeSection?: string;
  setActiveSection: (section: string) => void;
};

export function ScenarioStepsDashboard({
  scenarioStepMetrics,
  statisticsSections,
  activeSection,
  setActiveSection,
}: Props) {
  const [editMode, setEditMode] = useState(false);

  const { statisticsSettings, isLoading } = useGetStatisticsSettings();

  if (isLoading) {
    return <Loader />;
  }

  function closeEditMode() {
    setEditMode(false);
  }

  if (editMode) {
    return (
      <ScenarioStepsDashboardEditView
        {...{ closeEditMode, scenarioStepMetrics, statisticsSettings }}
      />
    );
  }

  if (statisticsSettings?.pinnedSteps?.length === 0) {
    return (
      <>
        <StatisticsSectionsNavigation
          {...{ statisticsSections, activeSection, setActiveSection }}
        />
        <ScenarioStepsDashboardEmptyState
          openEditView={() => setEditMode(true)}
        />
      </>
    );
  }

  const allStepsPinned =
    Object.values(scenarioStepMetrics).length ===
    statisticsSettings?.pinnedSteps?.length;

  return (
    <>
      <StatisticsSectionsNavigation
        {...{ statisticsSections, activeSection, setActiveSection }}
        actions={
          <Button
            buttonType="normal"
            onClick={() => setEditMode(true)}
            dataTest="scenario-steps-dashboard-edit-button"
          >
            <FormattedMessage id="statistics.scenarioStepsDashboard.navbar.editView" />
          </Button>
        }
      />
      <ScenarioStepsDashboardTileContainer 
        dataTest="scenario-steps-dashboard"
      >
        {filterPinnedScenarioSteps(
          scenarioStepMetrics,
          statisticsSettings?.pinnedSteps || []
        ).map(({ id, name, value }) => (
          <React.Fragment key={id}>
            <ScenarioStepsDashboardTile title={name} value={value} />
          </React.Fragment>
        ))}
        <ScenarioStepsDashboardUserHelpTile allStepsPinned={allStepsPinned} />
      </ScenarioStepsDashboardTileContainer>
    </>
  );
}
