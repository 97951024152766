import React from 'react';

import { Message } from '../../../model';
import { AddItemBar } from '../../AddItemBar/AddItemBar';
import { AddItemModal } from '../../AddItemModal/AddItemModal';
import { MessageComponent } from '../../messages/Message';
import { LocalIntentsComponentProps } from '../types';
import { sortByNameComparator } from '../../../../utils/SortUtils';

export const LocalIntentsComponent: React.FC<LocalIntentsComponentProps> = ({
  intents,
  items,
  onConfirm,
  onDelete,
  setMessage,
  possibleQuestions
}) => <>
    <AddItemModal
      addLabelId="survey-creator.intent.addNewIntentLabel"
      placeholderLabelId="survey-creator.possibleQuestionsDescription"
      dataTest="local-intents-modal"
      items={items.sort(sortByNameComparator)}
      onSelect={onConfirm}
      renderTrigger={({ open }) => <AddItemBar
        dataTest="add-possible-question-button"
        addButtonLabelId="survey-creator.possibleQuestionsLabel"
        descriptionId="survey-creator.possibleQuestionsDescription"
        barVariant="step"
        onClick={open}
      />}
      forbidAddingItems
    />
    {
      possibleQuestions.map((possibleQuestion, index) => {
        const selectedIntent = intents.find(intent => intent.name === possibleQuestion.intent);
        return selectedIntent && <MessageComponent
          key={possibleQuestion.id}
          dataTestPrefix="possible-question"
          labelId="survey-creator.messageLabel"
          title={selectedIntent.displayName || selectedIntent.name}
          message={possibleQuestion.message}
          onChange={(message: Message) => setMessage(
            selectedIntent.name,
            message
          )}
          onDelete={() => onDelete(selectedIntent.name)}
          isLocalIntent
          path={`possibleQuestions[${index}].message`}
        />
      })
    }
  </>
