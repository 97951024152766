import { useCallback, useContext } from 'react';
import uuid from 'uuid';

import { Message, PossibleQuestion } from '../../../model';
import { Intent, IntentsContext } from '../../../SurveyCreator';
import { LocalIntentsContainerProps } from '../types';

export const useLocalIntentsProps = ({question, onChange}: LocalIntentsContainerProps) => {
  const intents = useContext<Intent[]>(IntentsContext);
  const usedIntents = question.possibleQuestions?.map(q => q.intent) || [];

  const items = intents.filter(intent => !usedIntents.includes(intent.name) ).map(intent => ({
    key: intent.name,
    name: intent.displayName || intent.name
  }))
  
  const setPossibleQuestions = useCallback((possibleQuestions: PossibleQuestion[]) => onChange({
    ...question,
    possibleQuestions
  }),
  [question, onChange]);

  const addPossibleQuestion = useCallback((possibleQuestion: PossibleQuestion) => setPossibleQuestions([
    ...(question.possibleQuestions || []),
    possibleQuestion
  ]),
  [setPossibleQuestions, question]);

  const removePossibleQuestion = useCallback((possibleQuestion: PossibleQuestion) => setPossibleQuestions(
    question.possibleQuestions.filter(q => q.id !== possibleQuestion.id)
  ),
  [setPossibleQuestions, question]);

  const setMessage = useCallback(
    (intentKey: string, message: Message) => {
      setPossibleQuestions(
        question.possibleQuestions.map(possibleQuestion => {
        if (possibleQuestion.intent === intentKey) {
          const clonedQuestion = {...possibleQuestion};
          clonedQuestion.message = message;
          return clonedQuestion;
        }

        return possibleQuestion;
      }));
    },
  [question, setPossibleQuestions]);

  const onConfirm = useCallback((intentKey: string) => {
      addPossibleQuestion({
        id: uuid.v4(),
        intent: intentKey,
        message: { text: '' }
      });
  },
  [addPossibleQuestion])

  const onDelete = useCallback((intentKey: string) => {
    const deletedQuestion = question.possibleQuestions.find(q => q.intent === intentKey);
    removePossibleQuestion(deletedQuestion);
  }, 
  [question, removePossibleQuestion])

  return {
    intents,
    items,
    onConfirm,
    onDelete,
    setMessage
  }
}
