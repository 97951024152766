import classNames from 'classnames';
import {default as React, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';

import {Button} from '../components/Button/Button';
import {Input} from '../components/Input';
import {Modal} from '../components/Modal/Modal';
import {SelectComponent} from '../components/Select/Select';
import {currentUserRoles} from '../selectors';
import {ToggleWithLabel} from '../surveyCreator/components/ToggleWithLabel';
import {Bot} from '../surveyCreator/model';
import {UserRole} from '../utils/CurrentUser';

import styles from './BotClonePopup.pcss';
import _ from 'lodash';

type Props = {
    originalBot: Bot;
    organizations: { name: string, id: string }[],
    onSave: (request: CloneBotRequest) => void;
    onCancel: () => void;
};

export type CloneBotRequest = {
    name: string;
    organization: string;
    organizationId?: string;
    aiModelPolicy: AiModelPolicy;
    visibleForClient: boolean;
}

const aiModelOptions = ['shareModel', 'copyModel', 'createNew'] as const;
type AiModelPolicy = typeof aiModelOptions[number];

export const BotClonePopup = ({originalBot, onSave, onCancel, organizations}: Props) => {
    const isAdmin = useSelector(currentUserRoles).includes(UserRole.ADMIN);
    const intl = useIntl();
    const [aiModelOption, setaiModelOption] = useState<typeof aiModelOptions[number]>(undefined)
    const [botName, setBotName] = useState(undefined);
    const [organization, setOrganization] = useState<{ name: string, id: string }>(undefined);
    const [visibleForClient, toggleVisibleForClient] = useState(false);

    const organizationOptions = _.sortBy(organizations, el => el.name.toLowerCase());

    const performCloneRequest = () => onSave({
        name: botName,
        aiModelPolicy: aiModelOption,
        organization: !isAdmin ? originalBot.organization : organization.name,
        organizationId: !isAdmin ? originalBot.organizationId : (organization.id !== 'NEW_ORGANIZATION' ? organization.id : undefined),
        visibleForClient: !isAdmin ? true : visibleForClient
    });

    return (
        <Modal position={'top'} dataTest={'clone-bot-popup'}
               className={isAdmin ? styles.modal__admin : styles.modal__notAdmin}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <FormattedMessage id={'dashboard.clonePopup.title'}/>
                </div>
                <div className={styles.body}>
                    <div className={styles.bodyRow}>
                        <div className={styles.baseBotName}>
                            <div className={styles.baseBotNameLabel}>
                                {intl.messages['dashboard.clonePopup.baseBotName'] as string}
                            </div>
                            <div className={styles.baseBotNameValue} data-test={'original-bot-name'}>
                                {originalBot.name}
                            </div>
                        </div>
                        <div className={styles.botLanguage}>
                            <div className={styles.botLanguageLabel}>
                                {intl.messages['dashboard.clonePopup.baseBotLanguage'] as string}
                            </div>
                            <div className={styles.botLanguageValue} data-test={'original-bot-language'}>
                                {originalBot.voiceConfig.languageCode}
                            </div>
                        </div>
                    </div>
                    <div className={styles.bodyRow}>
                        <div className={styles.botName}>
                            <div className={styles.botNameLabel}>
                                <FormattedMessage id={'dashboard.clonePopup.newBotName'}/>
                            </div>
                            <Input
                                dataTest="bot-name-input"
                                className={styles.botNameInput}
                                value={botName}
                                onChange={setBotName}
                            />
                        </div>

                        <div className={styles.clientProperty}>
                            <div className={styles.clientPropertyLabel}>
                                <FormattedMessage id={'dashboard.clonePopup.client'}/>
                            </div>
                            {
                                isAdmin ?
                                    (
                                        <div className={styles.organizationSelect}>
                                            <SelectComponent
                                                isCreatable
                                                options={organizationOptions}
                                                value={organization}
                                                createOptionMessageId={'dashboard.clonePopup.addNewClient'}
                                                onChange={newValue => setOrganization(newValue)}
                                                onCreate={newValue => setOrganization({ name: newValue, id: 'NEW_ORGANIZATION' })}
                                                data-test={'organization-select'}
                                                placeholderMessage={'dashboard.clonePopup.client.placeholder'}
                                            />
                                        </div>
                                    )
                                    : (
                                        <div className={styles.organizationValue}>
                                            {originalBot.organization}
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                    <div className={styles.aiModel}>
                        <div className={styles.aiModelLabel}>
                            <FormattedMessage id={'dashboard.clonePopup.aiModel'}/>
                        </div>
                        <div className={styles.selectaiModel}>
                            <SelectComponent
                                isClearable={false}
                                options={aiModelOptions.map(option => ({
                                    id: option,
                                    name: intl.messages[`dashboard.clonePopup.aiModelOptions.${option}`] as string
                                }))}
                                value={aiModelOption && {
                                    id: aiModelOption,
                                    name: intl.messages[`dashboard.clonePopup.aiModelOptions.${aiModelOption}`] as string
                                }}
                                placeholderMessage={'dashboard.clonePopup.aiModelOptions.placeholder'}
                                onChange={newValue => setaiModelOption(newValue.id)}
                                data-test={'ai-model-policy-select'}
                            />
                        </div>
                    </div>
                    {
                        isAdmin && (
                            <div className={styles.toggle}>
                                <ToggleWithLabel value={visibleForClient}
                                                 onToggle={() => toggleVisibleForClient(!visibleForClient)}
                                                 labelId={'dashboard.clonePopup.visibleForClient'}
                                                 dataTest={'visible-for-client-toggle'}
                                />
                            </div>
                        )
                    }
                </div>
                <div className={styles.bottom}>
                    <div className={classNames(styles.buttons, isAdmin ? undefined : styles.buttons__notAdmin)}>
                        <Button buttonType="normal" onClick={onCancel} translateText="dashboard.clonePopup.cancel"
                                dataTest={'cancel-clone-button'}/>
                        <Button
                            onClick={performCloneRequest}
                            dataTest={'send-clone-request-button'}
                            translateText="dashboard.clonePopup.save"
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};
