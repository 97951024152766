import classNames from 'classnames';
import * as React from 'react';
import {Ref} from 'react';
import {FormattedMessage} from 'react-intl';

import {Button, ButtonType} from '../../components/Button/Button';
import {AddCondIcon, AddIcon} from '../../icons';

import styles from './AddButton.pcss';

export type Props = { 
  onClick: (e: any) => void; 
  dataTest?: string;
  disabled?: boolean; 
  labelId?: string;
  buttonType?: ButtonType;
  className?: string;
};

export const AddButton = React.forwardRef((props: Props, ref: Ref<any>) => (
  <div className={classNames(styles.addButtonContainer, props.className)} ref={ref}>
    <Button tabIndex={0} dataTest={props.dataTest} className={classNames(styles.addButton, styles[props.buttonType || 'primary'])} onClick={props.onClick} disabled={props.disabled}
            buttonType={props.buttonType}>
      {
        props.buttonType === 'link' ? (
          <div className={classNames(styles.plusContainer, styles[props.buttonType || 'primary'])}>
            <AddCondIcon animationClass={styles.plusIcon} />
          </div>
        ) : (
          <div className={styles.plusContainer}>
            <AddIcon animationClass={styles.plusIcon} />
          </div>
        )
      }

      {props.labelId && <FormattedMessage id={props.labelId} />}
    </Button>
  </div>
));
