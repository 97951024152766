import * as React from 'react';

import {StartpointIcon} from '../../../icons';

import {MenuOption} from './OptionsMenu';

export const StartOption = (props: { onClick: (event: any) => void }) => {
    return (
        <MenuOption dataTest="start-option" onClick={props.onClick} icon={StartpointIcon}
                    tooltipLabelId="menuOption.start.tooltip"/>
    );
}
