import React from 'react';

import { Box } from '../../../components/Box/Box';
import { CheckBigIcon } from '../../../icons/CheckBigIcon';
import { IconProps } from '../../../icons/iconTypes';

import styles from './LoginMessage.pcss';

export type LoginMessageProps = {
  message: React.ReactNode;
  Icon?: React.FC<IconProps>;
}

export const LoginMessage: React.FC<LoginMessageProps> = ({
  message,
  Icon = CheckBigIcon
}) => <Box className={styles.loginMessage} border>
    <Icon />
    {message}
  </Box>;