import React, { useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import capitalize from 'lodash/capitalize';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { StatusCompletedIcon } from '../icons/reports/StatusCompletedIcon';
import { ConversationPreviewIcon } from '../icons/reports/ConversationPreviewIcon';
import { Header } from '../layout/Header';
import { MainPage } from '../layout/MainPage';
import { WrapableHorizontalTabsLayout } from '../components/layouts/HorizontalTabsLayout/WrapableHorizontalTabsLayout';
import { convertToDateTimeString } from '../language/botLanguage';
import { Table } from '../components/TableDeprecated/Table';
import { useSurveyConfig } from '../views/Settings/Settings.hooks';
import { showSuccessToast, showErrorToast } from '../components/Toast/Toast';
import { MarkdownContent } from '../components/MarkdownContent/MarkdownContent';
import { EmptyState } from '../components/EmptyState/EmptyState';
import { getBotUrlId, useCurrentBot } from '../utils/CurrentBot';
import { EmptyStateBagIcon } from '../icons/EmptyStateBagIcon';
import { EmptyStateFridge } from '../icons/EmptyStateFridge';
import { EmptyStateAstronaut } from '../icons/EmptyStateAstronaut';
import { TooltipComponent } from '../components/Tooltip/Tooltip';
import { IconButton } from '../components/IconButton/IconButton';
import { Header as TableHeader } from '../components/TableDeprecated/Header';
import { Cell as TableCell } from '../components/TableDeprecated/Cell';
import { currentUserRoles } from '../selectors';
import { UserRole } from '../utils/CurrentUser';
import { Loader } from '../components/Loader';

import styles from './Reports.pcss';
import { useReports } from './useReports';
import { ReportStatus } from './model';
import { ReportsFiltersBar } from './ReportsFiltersBar';
import { useGetReportsReportDownload } from './reports-api';
import { ConversationPreview } from './ConversationPreview';

type StatusChangeHandler = ReturnType<typeof useReports>['changeReportItemStatus'];

const onStatusChange = async (
  handler: StatusChangeHandler,
  id: string,
  status: ReportStatus
) => await handler({
  id,
  status: (status === ReportStatus.COMPLETED ? ReportStatus.DEFAULT : ReportStatus.COMPLETED),
  onSuccess: () => showSuccessToast(<MarkdownContent contentKey="reports.changeStatus.successToast" />),
  onError: () => showErrorToast(<MarkdownContent contentKey="reports.changeStatus.errorToast" />),
});

const getCellForStatus = (
  { status, callId, id },
  changeReportItemStatus: StatusChangeHandler,
  intl: IntlShape,
  openConversationPreview: (callId: string) => void
) => (
  <TableCell className={styles.rightAlignTableCell}>
    <span data-test="report-column-status" className={styles.statusDisplay}>
      {status === ReportStatus.COMPLETED
        ? <StatusCompletedIcon
          dataTest={`report-status-display-icon-${callId}`}
          // This hardcoded class is used for the :has selector, which will not work with dynamic classes
          animationClass="statusActive"
        />
        : null}
    </span>
    <span className={styles.statusActions} data-test="report-status-actions">
      {
        callId &&
        <TooltipComponent
          tooltipText={intl.formatMessage({ id: 'reports.actions.preview' })}
          className={styles.actionTooltip}
        >
          <IconButton
            dataTest="report-conversation-preview-icon"
            className={styles.iconButton}
            Icon={ConversationPreviewIcon}
            onClick={() => openConversationPreview(callId)}
          />
        </TooltipComponent>
      }
      <TooltipComponent
        tooltipText={intl.formatMessage({ id: 'reports.actions.status.COMPLETED' })}
        className={styles.actionTooltip}
      >
        <IconButton
          dataTest="report-status-icon"
          className={styles.iconButton}
          Icon={StatusCompletedIcon}
          hoverType="confirm"
          onClick={() => onStatusChange(changeReportItemStatus, id, status)}
        />
      </TooltipComponent>
    </span>
  </TableCell>
);

const isTimestamp = (value: any) => /^[0-9]{13}$/.test(value);

const getColumnsForTable = (columns, intl, timeZone) => columns.map(column => ({
  Header: (
    <TableHeader>
      <FormattedMessage id={`reports.table.${column}`} defaultMessage={capitalize(column)} />
    </TableHeader>
  ),
  accessor: ['updatedAt', 'phoneNumber', 'smsText'].includes(column) ? column : `data.${column}`,
  Cell: ({ value: values }) => {
    return (
      <TableCell>
        {(Array.isArray(values) ? values : [values])?.map((value, i) => (
          <span key={i} data-test={`report-column-${column}-${i}`} data-sensitive="true" className={cx({ [styles.emptyReportValue]: !value })}>
            {isTimestamp(value) || column === 'updatedAt'
              ? convertToDateTimeString(intl.messages['timestamp.format'], timeZone, value)
              : value}
          </span>
        ))}
      </TableCell>
    )
  }
}));

export const Reports = () => {
  const [selectedReportItem, setSelectedReportItem] = useState<string>(null);
  const roles = useSelector(currentUserRoles);
  const { displayedConfig } = useSurveyConfig();
  const currentBot = useCurrentBot();
  const {
    data: {
      reportList,
      selectedReportName,
      selectedReportColumns,
      selectedReportItems,
    },
    isLoadingReportList,
    isLoadingReportItems,
    hasMoreItems,
    loadMoreItems,
    selectReport,
    changeReportItemStatus,
    filters,
    setFilters,
  } = useReports(currentBot.id);
  const {
    refetch: downloadReport,
    isRefetching: isDownloading
  } = useGetReportsReportDownload(currentBot.id, selectedReportName, filters, { enabled: false });
  const intl = useIntl();

  const tableColumnsWithActions = [...selectedReportColumns];
  const hasUserCreatorAccess = roles.includes(UserRole.ADMIN) || roles.includes(UserRole.CREATOR);

  return (
    <MainPage>
      <Header />

      <div className={styles.container}>
        {(reportList.length === 0 && isLoadingReportList) && (
          <div className={styles.loaderContainer}>
            <Loader dataTest="reports-tab-loader" />
          </div>
        )}

        {(reportList.length === 0 && !isLoadingReportList && hasUserCreatorAccess) && (
          <EmptyState
            content={
              <MarkdownContent
                contentKey="reports.emptyState.noData"
                extraValues={{ creatorLink: `/${getBotUrlId(currentBot)}/survey-creator/scenario` }}
              />
            }
            Icon={EmptyStateBagIcon}
          />
        )}

        {(reportList.length === 0 && !isLoadingReportList && !hasUserCreatorAccess) && (
          <EmptyState
            content={
              <MarkdownContent
                contentKey="reports.emptyState.noDataNoCreator"
              />
            }
            Icon={EmptyStateFridge}
          />
        )}

        {(reportList.length > 0) && (
          <WrapableHorizontalTabsLayout
            currentTab={selectedReportName}
            tabs={reportList.map(report => ({ id: report.name, label: report.name, badgeContent: report.uncompletedCount, dataTest: `${report.name.replace(' ', '')}-tab` }))}
            onTabClick={selectReport}
            getComponentForTab={() => (
              <>
                <ReportsFiltersBar
                  filters={filters}
                  setFilters={setFilters}
                  downloadReport={downloadReport}
                  isDownloading={isDownloading}
                  selectedReportName={selectedReportName}
                  timezone={displayedConfig?.timeZone}
                />
                {(selectedReportItems?.length === 0 && !isLoadingReportItems) ? (
                  <div className={styles.tableContainer}>
                    <EmptyState content={<MarkdownContent
                      contentKey="reports.emptyState.noDataForFilters"
                    />} Icon={EmptyStateAstronaut} />
                  </div>
                ) : (
                  <div className={styles.tableContainer}>
                    <Table
                      data={selectedReportItems}
                      columns={[
                        ...getColumnsForTable(tableColumnsWithActions, intl, displayedConfig?.timeZone),
                        {
                          Header: (
                            <TableHeader className={styles.rightAlignTableHeader}>
                              <FormattedMessage id={`reports.table.actions`} defaultMessage={capitalize('actions')} />
                            </TableHeader>
                          ),
                          Cell: ({ original }) => getCellForStatus(original, changeReportItemStatus, intl, setSelectedReportItem)
                        }
                      ]}
                      isLoading={isLoadingReportItems}
                      hasMore={hasMoreItems}
                      loadMore={loadMoreItems}
                    />
                  </div>
                )}
              </>
            )}
            noSeparator
            classes={{
              root: styles.horizontalTabsLayout,
              tab: styles.tab,
              currentTabContent: styles.currentTabContent,
              tabsHeader: styles.tabsHeader,
            }}
          />
        )}

        <ConversationPreview
          callId={selectedReportItem}
          onClose={() => setSelectedReportItem(null)}
          currentBotId={currentBot.id}
          timeZone={displayedConfig?.timeZone}
        />
      </div>
    </MainPage>
  );
};
