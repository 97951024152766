import classNames from 'classnames';
import * as React from 'react';

import {DeleteIcon} from '../../../../icons';
import {IntentSentence} from '../../../model';

import styles from './SentenceList.pcss';

type Props = {
    sentences: IntentSentence[],
    onRemove?: (sentence: IntentSentence) => void,
    isRemovable: boolean,
    type: SentenceType
};

export const SentenceList = ({sentences, onRemove, type, isRemovable}: Props) =>
    <div className={styles.sentenceList}>
        {sentences.map((item, index) => (
            <SentenceItem key={`${item + index}`}
                          sentence={item}
                          type={type}
                          onRemove={onRemove}
                          isRemovable={isRemovable}
            />
        ))}
    </div>

type SentenceItemProps = {
    sentence: IntentSentence,
    type: SentenceType,
    isRemovable: boolean,
    onRemove?: (sentence: IntentSentence) => void
};

const SentenceItem = ({isRemovable, onRemove, sentence, type}: SentenceItemProps) =>
    <div className={classNames(styles.sentenceItem, styles[type])}>
        {isRemovable &&
        <div className={styles.deleteButton} data-test="remove-button"
             onClick={() => onRemove(sentence)}>
            <DeleteIcon animationClass={styles.removeIcon}/>
        </div>}
        <div data-test="sentence" className={styles.sentence}>{sentence}</div>
    </div>

type SentenceType = 'talkie' | 'local' | 'queue';
