import * as React from 'react';

import styles from './BlockOptions.pcss';
import {DeleteOption} from './optionsMenu/DeleteOption';
import {DuplicateOption} from './optionsMenu/DuplicateOption';
import {EditOption} from './optionsMenu/EditOption';
import {OptionsComponent} from './optionsMenu/Options';
import {StartOption} from './optionsMenu/StartOption';
import {RenderOptionsParams} from './pill/Pill';

type Props = {
  onStart?: (event: any) => any;
  onClone?: (event: any) => any;
  onDelete?: (event: any) => any;
  isFirst: boolean;
} & RenderOptionsParams

export const BlockOptionsComponent = (props: Props) => {
  const options = props.isFirst
    ? [
      <DeleteOption key={'delete-option'} onDelete={props.onDelete} />,
      <DuplicateOption key={'clone-option'} onClick={props.onClone} />,
      <EditOption key={'edit-option'} onClick={props.onEditStart} />,
    ]
    : [
      <DeleteOption key={'delete-option'} onDelete={props.onDelete} />,
      <StartOption key={'start-option'} onClick={props.onStart} />,
      <DuplicateOption key={'clone-option'} onClick={props.onClone} />,
      <EditOption key={'edit-option'} onClick={props.onEditStart} />,
    ];

  return (
    <OptionsComponent
      iconContainerClass={styles.options}
      options={options} />
  );
};

