import React from 'react';

import {IconProps} from './iconTypes';

export function DictStepIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H24V24H0z"/>
                <path className={props.animationClass} d="M2.85 18c1.574 0 2.85 1.276 2.85 2.85S4.424 23.7 2.85 23.7 0 22.424 0 20.85 1.276 18 2.85 18zm0 1.2c-.911 0-1.65.739-1.65 1.65 0 .911.739 1.65 1.65 1.65.911 0 1.65-.739 1.65-1.65 0-.911-.739-1.65-1.65-1.65zM23.1 21c.331 0 .6.269.6.6 0 .298-.218.546-.503.592l-.097.008h-15c-.331 0-.6-.269-.6-.6 0-.298.218-.546.503-.592L8.1 21h15zM2.85 9c1.574 0 2.85 1.276 2.85 2.85S4.424 14.7 2.85 14.7 0 13.424 0 11.85 1.276 9 2.85 9zm0 1.2c-.911 0-1.65.739-1.65 1.65 0 .911.739 1.65 1.65 1.65.911 0 1.65-.739 1.65-1.65 0-.911-.739-1.65-1.65-1.65zM23.1 12c.331 0 .6.269.6.6 0 .298-.218.546-.503.592l-.097.008h-15c-.331 0-.6-.269-.6-.6 0-.298.218-.546.503-.592L8.1 12h15zM2.85 0C4.424 0 5.7 1.276 5.7 2.85S4.424 5.7 2.85 5.7 0 4.424 0 2.85 1.276 0 2.85 0zm0 1.2c-.911 0-1.65.739-1.65 1.65 0 .911.739 1.65 1.65 1.65.911 0 1.65-.739 1.65-1.65 0-.911-.739-1.65-1.65-1.65zM23.1 3c.331 0 .6.269.6.6 0 .298-.218.546-.503.592L23.1 4.2h-15c-.331 0-.6-.269-.6-.6 0-.298.218-.546.503-.592L8.1 3h15z"/>
            </g>
        </svg>
    );
}
