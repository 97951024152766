import {MessageFormatElement} from 'intl-messageformat-parser';
import _ from 'lodash';

import {
    BlockQuestion,
    ConditionalMessage,
    HttpStep,
    MultipleConditionalMessages, QuestionError,
    QuestionType,
    SetVariableStep,
    WordQuestion
} from '../../../model';
import { sortByNameComparator } from '../../../../utils/SortUtils';

export type PossibleValue = { key: string; id: string };
export type ConditionalMessageSource = {
    variable: { id: string, key: string };
    possibleValues: PossibleValue[];
    defaultValues: any;
};
export type ConditionalMessageProps = {
    dataTestPrefix: string;
    labelId: string;
    title?: string;
    message: ConditionalMessage;
    onChange: (message: ConditionalMessage) => void;
    onDelete?: () => void;
    availableConditionalSources: ConditionalMessageSource[];
    nested?: boolean;
    isLocalIntent?: boolean;
    errors?: QuestionError[];
    path?: string;
    bypassOnChangeFromContext?: boolean;
};
export type MultipleConditionalMessageProps = {
    dataTestPrefix: string;
    message: MultipleConditionalMessages;
    onChange: (message: MultipleConditionalMessages) => void;
    availableConditionalSources: ConditionalMessageSource[];
    noRepeatedQuestionCount?: boolean
};

export const findSource = (availableConditionalSources: ConditionalMessageSource[], selected: string): ConditionalMessageSource => {
    return availableConditionalSources.filter(q => q.variable.id === selected)[0];
};

export function hasSameKeys(question: ConditionalMessageSource, previousQuestion: ConditionalMessageSource) {
    return _.xor(Object.keys(question.defaultValues), Object.keys(previousQuestion.defaultValues)).length === 0;
}

export const handleConditionalVariableChange = (props: MultipleConditionalMessageProps | ConditionalMessageProps, valueMapper: (value: any) => any = _.identity) => (selected: { id: string, key: string }) => {
    if (!selected) {
        props.onChange({name: undefined, values: {}});
        return;
    }
    if (!props.onChange || selected.id === props.message.name) {
        return;
    }
    const question = findSource(props.availableConditionalSources, selected.id);
    const previousQuestion = findSource(props.availableConditionalSources, props.message.name);
    const previousTexts = props.message.values;
    const sameKeys = previousQuestion && hasSameKeys(question, previousQuestion);
    props.onChange({
        name: selected.id,
        values: sameKeys ? previousTexts : _.mapValues(question.defaultValues, valueMapper)
    });
};

export const mapToSelectFormat = (value: { id: string, key: string }) => ({id: value.id, name: value.key});

export function availableOptions(conditionalMessageSources: ConditionalMessageSource[]) {
    return conditionalMessageSources.map(q => mapToSelectFormat(q.variable)).sort(sortByNameComparator);
}

export const withDefaultAtEnd = (item: [string, any]) => (item[0] === 'default' ? 1 : 0);

export type ConditionalMessageQuestion = WordQuestion | HttpStep | SetVariableStep;

export const isConditionalMessageQuestion = (question: BlockQuestion): question is ConditionalMessageQuestion =>
    [QuestionType.HTTP, QuestionType.WORD, QuestionType.SET_VARIABLE].includes(question.type);

export const getConditionalAnswerLabel = (messages: Record<string, string> | Record<string, MessageFormatElement[]>, possibleValues: PossibleValue[], answerId: string): any => {
    const intlMessage = messages[`survey-creator.conditionalQuestion.${answerId}`];
    if (intlMessage || answerId === 'default') {
        return intlMessage || 'default';
    }
    const found = possibleValues.find((el: PossibleValue) => el.id === answerId);
    return found?.key || answerId;
};
