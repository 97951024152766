import { BasicMessage, Message, MultipleMessages, Synonym } from '../model';

export type AnswerItem = AnswerItemDictionary | AnswerItemEntity;

export interface AnswerItemDictionary extends BaseAnswerItem, AnswerValue {
  type: AnswerType.DICTIONARY;
  order: {
    item: ItemReadOrderType,
    global: GlobalReadOrderType,
  };
  // available if answer should be read (order.item !== GlobalReadOrderType.NONE && order.item !== ItemReadOrderType.NONE
  readMessage?: BasicMessage,
}

export interface AnswerItemEntity extends BaseAnswerItem {
  type: AnswerType.ENTITY;
  values: AnswerValue[];
}

export interface BaseAnswerItem {
  id: string;
}

export interface AnswerValue {
  key: string;
  synonyms: Synonym[];
  reaction: Reaction;
}

export enum AnswerType {
  DICTIONARY = 'DICTIONARY',
  ENTITY = 'ENTITY',
}

export enum ItemReadOrderType {
  // read as first
  FIRST = 'FIRST',
  // read as last
  LAST = 'LAST',
  // do not read
  NONE = 'NONE',
  // answer order equal to the general Question.readOrder property
  INHERIT = 'INHERIT',
}

export type Reaction = GoToModuleReaction | ContinueReaction;

export type GoToModuleReaction = {
  moduleId: string;
  type: ReactionType.GOTO;
}

export type ContinueReaction = {
  type: ReactionType.CONTINUE;
}

export enum ReactionType {
  GOTO = 'goto',
  CONTINUE = 'continue'
}

export enum GlobalReadOrderType {
  RANDOM = 'RANDOM',
  ORIGINAL = 'ORIGINAL',
  NONE = 'NONE',
}

interface BaseDisambiguationAnswer {
  // note: for entities, id is the entity name
  id: string;
  // note: for entities, key is the entity value
  key: string;
  synonyms: Synonym[];
}

export interface DisambiguationAnswerDictionary extends BaseDisambiguationAnswer {
  type: AnswerType.DICTIONARY;
}

export interface DisambiguationAnswerEntity extends BaseDisambiguationAnswer  {
  type: AnswerType.ENTITY;
}

export type DisambiguationAnswer = DisambiguationAnswerEntity | DisambiguationAnswerDictionary;

export interface DisambiguationItem {
  message: Message;
  repeatMessages: MultipleMessages;
  answers: DisambiguationAnswer[];
}

export interface Question {
  answersList: AnswerItem[];
  incomprehension: Reaction;
  readOrder: GlobalReadOrderType;
  disambiguation: DisambiguationItem[];
}
