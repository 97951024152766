import * as React from 'react';

import {IconProps} from './iconTypes';

export function MoveStepIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H24V24H0z"/>
                <path className={props.animationClass} fillRule="nonzero" d="M18.282 3c.899 0 1.637.715 1.712 1.626l.006.153v14.442c0 .93-.69 1.695-1.57 1.772l-.148.007H10.2c-.899 0-1.636-.715-1.711-1.626l-.007-.153v-4.48c0-.342.268-.62.598-.62.297 0 .543.225.59.519l.007.1v4.481c0 .266.185.487.43.533l.093.008h8.083c.257 0 .47-.191.515-.444l.008-.097V4.779c0-.266-.185-.487-.429-.533l-.094-.008H10.2c-.256 0-.47.191-.514.444l-.009.097c0 .342-.267.619-.597.619-.33 0-.598-.277-.598-.619 0-.93.69-1.695 1.57-1.772L10.199 3h8.083zM5.73 5.282c.207.215.23.548.07.79l-.07.085L3.098 8.64h6.901c1.877 0 3.399 1.577 3.399 3.52 0 .342-.268.619-.598.619-.33 0-.597-.277-.597-.619 0-1.205-.903-2.192-2.046-2.276l-.158-.006H3.09l2.641 2.524c.207.215.23.548.07.79l-.07.085c-.207.215-.53.239-.762.072l-.083-.072-3.71-3.58-.06-.072-.004-.007.064.08c-.033-.035-.061-.072-.085-.11l-.032-.062-.012-.027c-.009-.022-.016-.045-.023-.068l-.008-.034c-.005-.022-.009-.044-.011-.066L1 9.289 1 9.259v-.028l.004-.042-.004.07c0-.047.005-.094.015-.138l.01-.036c.005-.022.013-.043.02-.064l.013-.027c.009-.02.019-.039.03-.057l.018-.03.007-.01c.02-.027.04-.053.064-.077l3.708-3.538c.233-.241.612-.241.845 0z"/>
            </g>
        </svg>

    );
}
