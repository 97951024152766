import produce from 'immer';

import { LoadableMeta } from './types';

export type KeyCreator<P> = (payload: P) => string;

export const startingStateMeta: LoadableMeta = {
  isLoading: false,
  isReady: false,
  hasError: false
};

export const loadingStateMeta: LoadableMeta = {
  isLoading: true,
  isReady: false,
  hasError: false
};

export const successStateMeta: LoadableMeta = {
  isLoading: false,
  isReady: true,
  hasError: false
};

export const errorStateMeta: LoadableMeta = {
  isLoading: false,
  isReady: true,
  hasError: true
};

export const handleFetchStart = <P, S>(keyCreator: KeyCreator<P>) => (state: S, { payload }: { payload: P }) =>
  produce(state, draft => {
    draft[keyCreator(payload)] = loadingStateMeta;
  });

export const handleFetchSuccess = <P, S>(keyCreator: KeyCreator<P>) => (state: S, { payload }: { payload: P }) =>
  produce(state, draft => {
    draft[keyCreator(payload)] = {
      ...successStateMeta,
      data: payload
    };
  })

export const handleFetchFailure = <P, S>(keyCreator: KeyCreator<P>, data = []) => (state: S, { payload }: { payload: P }) =>
  produce(state, draft => {
    draft[keyCreator(payload)] = {
      ...errorStateMeta,
      error: payload,
      data
    };
  })
