import * as React from 'react';
import { useState } from 'react';

import { MoveStepIcon } from '../../../icons';
import { Block } from '../../model';

import { MenuOption } from './OptionsMenu';
import { SelectItemModal } from '../AddItemModal/AddItemModal';
import { sortByNameComparator } from '../../../utils/SortUtils';

type Props = {
    onMove: (event: any, blockId: string) => void;
    blocks?: Block[];
    currentBlock?: Block;
}

export const MoveQuestionOption = (props: Props) => {
    const [showModal, toggleModal] = useState<boolean>(false);

    const items = props.blocks
        .filter(block => block.id !== props.currentBlock.id)
        .map(block => ({
            key: block.id,
            name: block.name
        }))
        .sort(sortByNameComparator);

    return (
        <>
            <MenuOption dataTest="move-question-option" onClick={() => toggleModal(true)} icon={MoveStepIcon}
                        tooltipLabelId="menuOption.move.tooltip"/>
            {
                showModal && (
                    <SelectItemModal
                        placeholderLabelId={'questionMoveModal.text'}
                        dataTest={'move-question-modal'}
                        forbidAddingItems={true}
                        onSelect={(blockId: string, event) => {
                            props.onMove(event, blockId);
                            toggleModal(false);
                        }}
                        onClose={() => {
                            toggleModal(false);
                        }}
                        items={items}
                    />
                )
            }
        </>
    );
}
