import head from 'lodash/head'
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Box } from '../../../../components/Box/Box'
import { Button } from '../../../../components/Button/Button'
import { ElementWithLabel } from '../../../../components/ElementWithLabel/ElementWithLabel'
import { RangeWithLabel } from '../../../../components/RangeWithLabel/RangeWithLabel'
import { Row } from '../../../../components/Row/Row'
import { SelectWithLabel } from '../../../../components/SelectWithLabel/SelectWithLabel'
import { TextArea } from '../../../../components/TextArea/TextArea'
import { VoiceProps } from '../../types'

import { useVoiceCallbacks } from './Voice.hooks'
import styles from './Voice.pcss';
import voices from './voices.data'
import {PlayIcon} from '../../../../icons/PlayIcon';
import { sortByNameComparator } from '../../../../utils/SortUtils';


export const Voice: React.FC<VoiceProps> = ({
  voiceLanguage,
  voiceName,
  tempo,
  pitch,
  updateVoiceConfigProperty,

  voiceSampleText,
  setVoiceSampleText,
  handleSynthesize
}) => {
  const intl = useIntl();
  const languageCodes = uniq(sortBy(voices, 'name').map(voice => head(voice.languageCodes)))
  const languageOptions = languageCodes.map(code => ({ id: code, name: code }))
  const voiceNameOptions = voiceLanguage
    ? voices.filter(el => el.languageCodes.includes(voiceLanguage)).map(el => ({
      id: el.name,
      name: el.name
    })).sort(sortByNameComparator)
    : []

  const {
    onLanguageChange,
    onVoiceNameChange,
    onVoicePitchChange,
    onVoiceTempoChange
  } = useVoiceCallbacks(updateVoiceConfigProperty)

  return <Box border className={styles.voice}>
    <Row>
      <SelectWithLabel
        dataTest="voice-language-select"
        labelId="survey.config.voiceConfig.language"
        isSearchable
        options={languageOptions}
        onChange={onLanguageChange}
        value={languageOptions.find(opt => opt.id === voiceLanguage)}
        noPadding
      />
      <SelectWithLabel
        dataTest="voice-name-select"
        labelId="survey.config.voiceConfig.voiceName"
        isSearchable
        options={voiceNameOptions}
        value={voiceNameOptions.find(opt => opt.id === voiceName)}
        onChange={onVoiceNameChange}
        noPadding
      />
    </Row>
    <Row>
      <RangeWithLabel
        dataTest="voice-tempo-slider"
        labelId="survey.config.voiceConfig.speed"
        noPadding
        min={0.25}
        max={4.0}
        step={0.05}
        value={tempo}
        onChange={onVoiceTempoChange}
      />
      <RangeWithLabel
        dataTest="voice-pitch-slider"
        labelId="survey.config.voiceConfig.pitch"
        noPadding
        min={-20.0}
        max={20.0}
        step={0.1}
        value={pitch}
        onChange={onVoicePitchChange}
      />
    </Row>
    <Row>
      <ElementWithLabel
        dataTest="voice-sample"
        labelId="survey.config.voiceConfig.voiceTestContent"
        noPadding
        inner
      >
        <TextArea
          dataTest="voice-sample-textarea"
          value={voiceSampleText}
          onChange={setVoiceSampleText}
        />
        <Row className={styles.buttonRow}>
          <a
            href={intl.messages['survey.config.voiceConfig.googleLink'] as string}
            target="__blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="survey.config.voiceConfig.googleLinkText" />
          </a>
          <Button
            Icon={PlayIcon}
            dataTest="voice-sample-button"
            translateText="survey.config.voiceConfig.voiceTest"
            className={styles.playButton}
            onClick={handleSynthesize}
          />
        </Row>
      </ElementWithLabel>
    </Row>
  </Box>
}
