import React from 'react';

import {IconProps} from './iconTypes';

export function GoToConversationIcon({ animationClass, title }: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      {title && <title>{title}</title>}
        <g fill="none" fillRule="evenodd">
            <path d="M0 0H24V24H0z"/>
            <path className={animationClass} fill="#919AAF" d="M5.224 15.597c-1.453-2.551-1.476-5.686-.04-8.267C6.674 4.654 9.498 2.996 12.56 3c.33 0 .597.269.597.598 0 .33-.269.598-.599.597-2.629-.004-5.053 1.42-6.331 3.716-1.278 2.298-1.21 5.108.18 7.34.107.173.12.388.032.572L4.86 19.14l3.317-1.58c.184-.087.4-.075.572.033 2.232 1.39 5.042 1.458 7.34.18 2.297-1.278 3.72-3.702 3.716-6.331 0-.33.267-.598.597-.599.33 0 .598.267.598.597.005 3.063-1.653 5.888-4.33 7.377-2.58 1.436-5.715 1.413-8.267-.04l-4.548 2.166c-.229.109-.501.062-.68-.117-.179-.179-.226-.451-.117-.68l2.166-4.548zM18.959 5.241L17.74 4.02c-.234-.233-.234-.612 0-.845.233-.233.611-.233.845 0l2.24 2.24c.234.234.234.612 0 .846l-2.24 2.24c-.234.234-.612.234-.845 0-.234-.233-.234-.611 0-.845l1.22-1.22H17.04c-2.145 0-3.884 1.739-3.884 3.884 0 .33-.268.597-.597.597-.33 0-.598-.268-.598-.597 0-2.805 2.274-5.08 5.079-5.08h1.92z"/>
        </g>
    </svg>
  );
}
