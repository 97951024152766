import React from 'react';

import styles from './icons.pcss';

export function SearchPhraseIcon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <path className={styles.iconFillColor} fillRule="nonzero" d="M7.5 14.899a.6.6 0 0 1 .6.6l-.001.4h1.38a.6.6 0 0 1 .592.502l.008.098a.6.6 0 0 1-.6.6h-4a.6.6 0 0 1 0-1.2H6.9l.001-.4a.6.6 0 0 1 .503-.592zm0-14a6.6 6.6 0 0 1 5.071 10.824l4.353 4.351a.6.6 0 0 1-.848.849l-4.352-4.353A6.6 6.6 0 1 1 7.5.899zm0 1.2a5.4 5.4 0 1 0 0 10.8 5.4 5.4 0 0 0 0-10.8zm2.5 3.8c1.16 0 2.1.94 2.1 2.1a.6.6 0 1 1-1.2 0l-.007-.113A.9.9 0 0 0 10 7.099H8.099l.001 4.4a.6.6 0 0 1-.503.592l-.097.008a.6.6 0 0 1-.6-.6l-.001-4.4H5a.9.9 0 0 0-.547.185l-.09.079a.9.9 0 0 0-.263.636l-.008.098A.6.6 0 0 1 2.9 8 2.1 2.1 0 0 1 5 5.899z"/>
      </svg>
    );
}
