import React, { useEffect, useState } from 'react';

import { Box } from '../../../components/Box/Box';
import { CollapsiblePanel } from '../../../components/CollapsiblePanel/CollapsiblePanel';
import { EntitySymbol } from '../../../components/EntitySymbol/EntitySymbol';
import { IconButton } from '../../../components/IconButton/IconButton';
import { TooltipComponent } from '../../../components/Tooltip/Tooltip';
import { CancelIcon } from '../../../icons';
import styles from './EntityCollapsiblePanel.pcss';

export type EntityCollapsiblePanelProps = {
  entity: string;
  isInitiallyCollapsed?: boolean;
  onRemoveClick?: (e: React.MouseEvent) => void;
}

type InnerContentProps = {
  isCollapsed: boolean;
  entity: string;
  setInnerCollapsed: (state: boolean) => void;
  onRemoveClick?: (e: React.MouseEvent) => void;
}

const InnerContent: React.FC<InnerContentProps> = ({
  isCollapsed,
  entity,
  setInnerCollapsed,
  onRemoveClick,
}) => {
  useEffect(() => {
    setInnerCollapsed(isCollapsed);
  }, [isCollapsed]);
  return <div
    className={styles.entityPanelContent}
    style={{
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center'
    }}
  >
    <EntitySymbol />
    <span className={styles.entityName}>
      {entity}
    </span>

    <TooltipComponent
      tooltipText="test tooltip"
    >
      <IconButton
        Icon={CancelIcon}
        hoverType="warning"
        dataTest="remove-entity-button"
        onClick={onRemoveClick}
      />
    </TooltipComponent>
  </div>;
}

export const EntityCollapsiblePanel: React.FC<EntityCollapsiblePanelProps> = ({
  entity,
  onRemoveClick,
  isInitiallyCollapsed,
  children
}) => {
  // @TODO: CollapsiblePanel already has state for isCollapsed. can we do it better?
  const [isInnerCollapsed, setInnerCollapsed] = useState(isInitiallyCollapsed || false);
  return <div className={styles.collapsibleWrapper}>
    <Box
      border
    >
      <CollapsiblePanel borderPrimary isInitiallyCollapsed={isInitiallyCollapsed}>
        {
          (isCollapsed) => <InnerContent
            isCollapsed={isCollapsed}
            setInnerCollapsed={setInnerCollapsed}
            entity={entity}
            onRemoveClick={onRemoveClick}
          />
        }
      </CollapsiblePanel>
    </Box>
    {!isInnerCollapsed && children}
  </div>;
}