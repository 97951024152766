import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ElementWithLabel } from '../../../../components/ElementWithLabel/ElementWithLabel';
import { Input } from '../../../../components/Input';
import { Row } from '../../../../components/Row/Row';
import { CountryCallingCodeProps } from '../../types';

import styles from './CountryCallingCode.pcss';

export const CountryCallingCode: React.FC<CountryCallingCodeProps> = ({
  countryCallingCode,
  setCountryCallingCode,
  isAdmin
}) => <ElementWithLabel
  inner
  noPadding
  labelId="survey.config.countryCallingCode"
>
    <Row verticallyCentered>
      <Input
        type="tel"
        dataTest="country-calling-code"
        maxLength={5}
        className={styles.input}
        value={countryCallingCode || ''}
        onChange={setCountryCallingCode}
        disabled={!isAdmin}
      />
      <span className={styles.inputLabel}>
        <FormattedMessage id="survey.config.countryCallingCodeNote" />
      </span>
    </Row>
  </ElementWithLabel>
