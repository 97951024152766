import * as React from 'react';
import {FormattedMessage} from 'react-intl';

import {Button} from '../../components/Button/Button';

import styles from './EditButtons.pcss';
import {useShortcut} from './useShortcut';

type Props = { onClose: () => void, onSave: () => void, disabled: boolean };

export const EditButtons = (props: Props) => {
    useShortcut({keys: ['escape']}, props.onClose);
    useShortcut({keys: ['s'], meta: true}, props.onSave);

    return (
        !props.disabled &&
        <div className={styles.buttons}>
            <Button buttonType={'normal'} type="button" dataTest="back-button" onClick={props.onClose}
                    className={styles.back}>
                <FormattedMessage id={'survey-creator.cancel'}/>
            </Button>
            <Button buttonType={'primary'} type="button" dataTest="save-button" onClick={props.onSave}
                    className={styles.save}>
                <FormattedMessage id={'survey-creator.save'}/>
            </Button>
        </div>
    );
};
