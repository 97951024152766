import _ from 'lodash';
import * as React from 'react';
import { useEffect } from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { Helmet } from 'react-helmet';

import { Conversations } from './conversations/Conversations';
import { LiveConversations } from './conversations/LiveConversations';
import { Dashboard } from './dashboard/Dashboard';
import { GlobalWord } from './entity/GlobalWord';
import { Inspector } from './inspector/Inspector';
import { Intent } from './intent/Intent';
import { MainPage } from './layout/MainPage';
import Learning from './learning/LearningView';
import { Statistics } from './statistics/Statistics';
import { Reports } from './reports/Reports';
import { Bot } from './surveyCreator/model';
import { SurveyCreatorRouter } from './surveyCreator/router';
import { useCurrentBot } from './utils/CurrentBot';
import { SettingsRouter } from './views/Settings/router';
import { Dialer } from './dialer/Dialer';
import useBranding from './hooks/useBranding/useBranding';

type Props = {
    admin: boolean;
    hasCreatorRole: boolean;
    hasSettingsRole: boolean;
    hasPlannerRole: boolean;
    hasInspectorRole: boolean;
    bot: Bot;
    onBotsUpdate: () => void;
};

class RedirectComponent extends React.Component<RouteComponentProps<any>> {
    componentDidMount() {
        this.props.history.push(`/${_.get(this.props, 'match.params.orgId')}/${_.get(this.props, 'match.params.botId', '')}`);
    }

    render() {
        return null;
    }
}

const RedirectHome = withRouter(RedirectComponent);

const componentOrRedirectHome = (condition: boolean, Component: any) => {
    if (condition) {
        return Component;
    }
    return RedirectHome;
};

export function AppContent(props: Props) {
    const { title } = useBranding();
    const isAdmin = props.admin;
    const isDataCollectionAvailable = props.bot && props.bot.type === 'survey';
    const isSurveyCreatorAvailable = (isAdmin || props.hasCreatorRole) && props.bot && props.bot.type === 'survey';
    const isSettingsAvailable = props.bot && props.bot.type === 'survey';
    const isPlannerAvailable = (isAdmin || props.hasPlannerRole) && props.bot && props.bot.type === 'survey';
    const isInspectorAvailable = (isAdmin || props.hasInspectorRole) && !!props.bot;

    const currentBot = useCurrentBot();

    return (
      <>
        <Helmet>
          <title>{currentBot?.name ? `${currentBot.name}, ${title}` : title}</title>
        </Helmet>
        <Switch>
            <Route
                render={({ match }) => {
                    const { path } = match;

                    if (!match.params.botId || !match.params.orgId) {
                        return (
                            <MainPage>
                                <Dashboard onBotsUpdate={props.onBotsUpdate} />
                            </MainPage>
                        );
                    }
                    return (
                        <Switch>
                            <Route path={`${path}/live/:callId?`} component={LiveConversations} />
                            <Route path={`${path}/reports/:reportName?`} component={componentOrRedirectHome(isDataCollectionAvailable, Reports)} />
                            <Route path={`${path}/statistics`} component={Statistics} />
                            <Route path={`${path}/learning`} component={componentOrRedirectHome(isAdmin, Learning)} />
                            <Route
                                path={`${path}/global-synonyms/:language/:word`}
                                component={componentOrRedirectHome(isAdmin, GlobalWord)}
                            />
                            <Route
                                path={`${path}/intents/:intent`}
                                component={componentOrRedirectHome(isAdmin, Intent)}
                            />
                            <Route path={`${path}/inspector`} component={componentOrRedirectHome(isInspectorAvailable, Inspector)} />
                            <Route
                                path={`${path}/survey-creator`}
                                component={componentOrRedirectHome(isSurveyCreatorAvailable, SurveyCreatorRouter)}
                            />
                            <Route
                                path={`${path}/settings`}
                                component={componentOrRedirectHome(isSettingsAvailable, SettingsRouter)}
                            />
                            <Route
                                path={[`${path}/contacts`, `${path}/planer`]}
                                component={componentOrRedirectHome(isPlannerAvailable, Dialer)}
                            />
                            <Route path={`${path}/:callId?`} component={Conversations} />
                        </Switch>
                    );
                }}
            />
        </Switch>
      </>
    );
}
