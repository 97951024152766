import React from 'react';
import { IconProps } from '../iconTypes';

export function InboundCall(props: IconProps) {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#7x22idajga)" fill="#4A4A4A">
      <path d="M10.92 10.74a1.386 1.386 0 0 0-.994-.413c-.38 0-.726.146-.993.413a.6.6 0 0 1-.847 0L5.26 7.913a.6.6 0 0 1-.174-.426.6.6 0 0 1 .174-.427 1.4 1.4 0 0 0 0-1.98L3.493 3.313a1.4 1.4 0 0 0-1.98 0l-.42.42A3.388 3.388 0 0 0 .64 7.98a27.372 27.372 0 0 0 7.38 7.38s.013.007.013.013c.567.36 1.2.54 1.833.54.874 0 1.74-.34 2.4-1l.42-.42c.267-.266.414-.613.414-.993s-.147-.727-.414-.993L10.92 10.74zm1.2 3.18-.42.42a2.587 2.587 0 0 1-3.22.367V14.7a26.632 26.632 0 0 1-7.167-7.16 2.584 2.584 0 0 1 .353-3.233l.42-.42a.594.594 0 0 1 .427-.174c.153 0 .307.06.427.174l1.766 1.766a.6.6 0 0 1 .174.427.6.6 0 0 1-.174.427A1.392 1.392 0 0 0 4.7 8.48l2.827 2.827a1.4 1.4 0 0 0 1.98 0 .6.6 0 0 1 .427-.174.6.6 0 0 1 .427.174l1.766 1.766a.6.6 0 0 1 .174.427.6.6 0 0 1-.174.427l-.006-.007zM15.78.22a.407.407 0 0 0-.566 0L9.4 6.033V3c0-.22-.18-.4-.4-.4-.22 0-.4.18-.4.4v4c0 .053.014.107.034.153.04.1.12.174.213.214.047.02.1.033.153.033h4c.22 0 .4-.18.4-.4 0-.22-.18-.4-.4-.4H9.967L15.78.78a.407.407 0 0 0 0-.567V.22z" />
    </g>
    <defs>
      <clipPath id="7x22idajga">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
}