import * as React from 'react';

import {BorderedElementWithLabel} from '../../../components/ElementWithLabel/BorderedElementWithLabel';
import {SelectComponent} from '../../../components/Select/Select';
import {Block, BlockSurveyIntent} from '../../model';
import { sortByNameComparator } from '../../../utils/SortUtils';

type Props = {
    intent: BlockSurveyIntent,
    blocks: Block[],
    onChange: (intent: BlockSurveyIntent) => void
};

export const BlockSurveyIntentComponent = ({intent, blocks, onChange}: Props) => {
    const sortedBlocks = blocks.sort(sortByNameComparator);
    return <BorderedElementWithLabel
        labelId={'survey-creator.intentDetails.goToModule'}>
        <SelectComponent data-test={'block-select'}
                         value={{
                             id: intent.block,
                             name: blocks.find(it => it.id === intent.block)?.name ?? intent.block
                         }}
                         options={sortedBlocks}
                         isSearchable
                         onChange={(value: any) => onChange({...intent, block: value.id})}
        />
    </BorderedElementWithLabel>;
};
