import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './ActivationLinkExpiredMessage.pcss';

export const ActivationLinkExpiredMessage = () => {
  return (
    <span>
      <FormattedMessage
        id="auth.signIn.activationLinkExpired"
        values={{
          mail: (
            <a href="mailto:support@talkie.ai" className={styles.link}>
              <FormattedMessage id="auth.signIn.supportEmail" />
            </a>
          ),
        }}
      />
    </span>
  );
};
