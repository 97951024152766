import cx from 'classnames'
import React from 'react'

import { StandardInitialProps } from '../types'

import styles from './Badge.pcss'

export const Badge: React.FC<StandardInitialProps> = ({
  className,
  children,
  dataTest,
}) => {
  return (
    <div
      data-test={dataTest}
      className={cx(
        styles.badge,
        className
      )}>
      {children}
    </div>
  );
}
