import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import styles from './ItemReadOrder.pcss';

import { SelectComponent, Value } from '../../../../../../components/Select/Select';

import { InOrderIcon } from '../../../../../../icons/InOrderIcon';
import { RandomIcon } from '../../../../../../icons/RandomIcon';
import { ToTopIcon } from '../../../../../../icons/ToTopIcon';
import { ToBottomIcon } from '../../../../../../icons/ToBottomIcon';
import { DontReadIcon } from '../../../../../../icons/DontReadIcon';

import { GlobalReadOrderType, ItemReadOrderType } from '../../../../types';
import { ItemReadOrderProps } from './types';

const icons = {
    ORIGINAL: InOrderIcon,
    RANDOM: RandomIcon,
    FIRST: ToTopIcon,
    LAST: ToBottomIcon,
    NONE: DontReadIcon,
}

const ItemReadOrder: React.FC<ItemReadOrderProps> = ({ value, onChange }) => {
    const intl = useIntl();

    const labels = useMemo(() => {
        const base = {
            [ItemReadOrderType.NONE]: intl.messages[`survey-creator.readOptionDONT_READ`] as string,
            [ItemReadOrderType.FIRST]: intl.messages[`survey-creator.readOptionFIRST`] as string,
            [ItemReadOrderType.LAST]: intl.messages[`survey-creator.readOptionLAST`] as string,
        };
        if (value?.global === GlobalReadOrderType.RANDOM) {
            return {
                ...base,
                [GlobalReadOrderType.RANDOM]: intl.messages[`survey-creator.readOptionPERMUTATE`] as string,
            };
        }
        if (value?.global === GlobalReadOrderType.ORIGINAL) {
            return {
                ...base,
                [GlobalReadOrderType.ORIGINAL]: intl.messages[`survey-creator.readOptionSEQUENCE`] as string,
            };
        }
        return base;
    }, [value?.global]);
    
    const availableOptions: Value[] = Object.keys(labels).reduce((acu, option: ItemReadOrderType | GlobalReadOrderType) => {
        if (option === GlobalReadOrderType.ORIGINAL || option === GlobalReadOrderType.RANDOM) {
            return [
                ...acu,
                { id: ItemReadOrderType.INHERIT, name: labels[option], icon: icons[option] },
            ]
        }
        return [
            ...acu,
            { id: option, name: labels[option], icon: icons[option] },
        ];
    }, []);
    const currentValue = availableOptions.find(v => v.id === value.item)

    const handleChange = (v: Value) => onChange(v.id)

    return <div data-test={'read-type'} className={styles.container}>
        <div className={styles.select}>
            <SelectComponent
                onChange={handleChange}
                options={availableOptions}
                value={currentValue}
                type={'nonBordered'}
            />
        </div>
    </div>;
}

export default ItemReadOrder;