import { useMutation, useQuery, UseQueryResult } from 'react-query';

import { HttpClient } from '../../utils/HttpClient';

export const useAccept = () => {
  const mutation = useMutation(async () => {
    return HttpClient.put({
      path: `/terms-of-service/accept`,
      body: {},
    });
  });

  // to use mutationAsync without arguments
  const wrappedMutateAsync = async () => {
    return mutation.mutateAsync();
  };

  return {
    ...mutation,
    mutateAsync: wrappedMutateAsync,
  };
};

export const useGetTermsOfServiceMarkdown = (
  filename: string
): UseQueryResult<string> =>
  useQuery('useGetTermsOfServiceMarkdown', () =>
    fetch(filename).then(
      (response) => response.text()
    )
  );
