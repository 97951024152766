import { Auth } from 'aws-amplify';
import _ from 'lodash';
import { HttpClient } from './HttpClient';

const jwtDecode = require('jwt-decode');

export enum UserRole {
    ADMIN = 'admin',
    DEMO = 'demo',
    CREATOR = 'creator',
    PLANNER = 'planner',
    SETTINGS = 'settings',
    INSPECTOR = 'inspector',
    ISSUES = 'issues',
    CONVERSATION_DEBUGGER = 'conversation-debugger',
}

export interface User {
    'cognito:groups': ReadonlyArray<UserRole>;
    id: string;
    cognitoSub: string;
    email: string;
    organizations: string[];
    groups: string[];
    hasAcceptedRecentTerms: boolean;
    termsOfServiceAcceptedAt?: string;
}

export function getRolesFromUser(user: User) {
    return user['cognito:groups'] || [];
}

export class CurrentUser {
    static async get(): Promise<User> {
        const userResponse = await HttpClient.get({ path: '/me' })
        return {
            ...jwtDecode(await CurrentUser.token()),
            ...userResponse.data
        };
    }

    static async token() {
        const user = await Auth.currentAuthenticatedUser();

        // TODO: remove this check when issue fix in amplify (https://github.com/aws-amplify/amplify-js/issues/1353)
        // problem is that currentAuthenticatedUser returns undefined session if expired, there is refresh, but it is parallel to main flow, so not returned with previous call
        // if we need user with roles, we might have to use different flow (wait and get current user once again?)
        return !_.has(user, 'signInUserSession.accessToken.jwtToken')
            ? await this.getRefreshedToken(user)
            : user.signInUserSession.accessToken.jwtToken;
    }

    private static async getRefreshedToken(user: any) {
        const session = (await new Promise((res, rej) =>
            user.getSession((err: any, result: any) => (err ? rej(err) : res(result)))
        )) as any;
        return session.accessToken.jwtToken;
    }
}
