import React from 'react';

import {IconProps} from './iconTypes';

export function OpenStepIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H24V24H0z"/>
                <path className={props.animationClass} d="M21.75.148c.331 0 .6.269.6.6 0 .331-.269.6-.6.6-2.087 0-3.79 1.639-3.895 3.7l-.005.2-.001.899 3.901.001c1.11 0 2.018.86 2.095 1.95l.005.15v7.5c0 1.16-.94 2.1-2.1 2.1l-3.901-.001.001.901c0 2.154 1.746 3.9 3.9 3.9.331 0 .6.269.6.6 0 .331-.269.6-.6.6-1.878 0-3.519-1.015-4.404-2.526l-.096-.173-.096.173c-.851 1.453-2.401 2.447-4.188 2.522l-.216.004c-.331 0-.6-.269-.6-.6 0-.331.269-.6.6-.6 2.154 0 3.9-1.746 3.9-3.9l-.001-.901-14.399.001c-1.11 0-2.018-.86-2.095-1.95l-.005-.15v-7.5c0-1.16.94-2.1 2.1-2.1l14.399-.001.001-.899-.005-.2c-.105-2.061-1.808-3.7-3.895-3.7-.331 0-.6-.269-.6-.6 0-.331.269-.6.6-.6 1.878 0 3.519 1.015 4.404 2.526l.096.172.096-.172C18.197 1.22 19.747.227 21.534.152l.216-.004zm-5.101 7.199L2.25 7.348c-.497 0-.9.403-.9.9v7.5c0 .497.403.9.9.9l14.399-.001v-9.3zm5.101.001l-3.901-.001v9.3l3.901.001c.459 0 .837-.343.893-.787l.007-.113v-7.5c0-.497-.403-.9-.9-.9z"/>
            </g>
        </svg>
    );
}
