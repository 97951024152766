import React from 'react';

import { DisambiguationCard } from './DisambiguationCard';

import { DisambiguationProps } from './types';

import styles from './Disambiguation.pcss';

const Disambiguation: React.FC<DisambiguationProps> = ({ onChange, value }) => {
    // @TODO: leftover for debugging purposes (scrolling does not work)
    return <div
        // ref={containerRef}
    >
        {value.map((item, index) => <DisambiguationCard
            key={index}
            itemIndex={index}
            value={item}
            onChange={onChange}
            className={styles.notPreciseAnswer}
            shouldRenderDescription={index === 0}
        />)}
    </div>
}

export default Disambiguation;
