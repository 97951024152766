import classNames from 'classnames';
import * as React from 'react';

import styles from './HeaderActionContainer.pcss'

export type HeaderActionContainerProps = {
    children: React.ReactNode;
    className?: string;
};

export function HeaderActionContainer({ children, className }: HeaderActionContainerProps) {
    return (
        <div className={classNames(styles.container, className)}>
            {children}
        </div>
    );
}