import React from 'react';

import {IconProps} from './iconTypes';

export function HeadsetIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h18v18H0z" />
                <path
                    className={props.animationClass}
                    fill="#3C38BC"
                    d="M9.234.9a6.272 6.272 0 0 1 6.25 5.736 2.66 2.66 0 0 1 2.085 2.598v2.063c0 1.264-.88 2.322-2.062 2.594a2.664 2.664 0 0 1-2.495 2.641l-.168.006h-1.061a1.632 1.632 0 0 1-1.517 1.03H9.234a1.631 1.631 0 1 1 0-3.262h1.032c.688 0 1.278.427 1.517 1.031h1.06c.761 0 1.386-.58 1.457-1.321l.002-.062-.033-.001a1.116 1.116 0 0 1-.994-1.11V7.689c0-.576.436-1.05.995-1.11a5.072 5.072 0 0 0-10.043-.22l-.031.22c.563.061.998.534.998 1.11v5.156c0 .616-.5 1.115-1.116 1.115h-.516A2.663 2.663 0 0 1 .9 11.297V9.234c0-1.272.892-2.336 2.085-2.6A6.272 6.272 0 0 1 9.235.9zm1.032 14.606H9.234a.431.431 0 1 0 0 .863h1.032a.431.431 0 1 0 0-.863zM3.994 7.772h-.432c-.807 0-1.462.655-1.462 1.462v2.063c0 .808.655 1.462 1.462 1.462h.432V7.772zm10.912 0h-.431v4.987h.431c.808 0 1.463-.654 1.463-1.462V9.234c0-.807-.655-1.462-1.463-1.462z"
                />
            </g>
        </svg>
    );
}
