import _ from 'lodash';
import React from 'react';

import { SpecialCharactersContainerProps } from '../types';

import { useSpecialCharactersProps } from './SpecialCharacters.hooks';
import { SpecialCharactersComponent } from './SpecialCharactersComponent';

export const SpecialCharactersContainer: React.FC<SpecialCharactersContainerProps> = (props) => {

  const specialCharactersProps = useSpecialCharactersProps(props);

  return <SpecialCharactersComponent
    {...specialCharactersProps}
    answers={props.question.dictionary}
  />;
}