import React from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { IntentsContext, SurveyContext } from '../../surveyCreator/SurveyCreator';
import { getBotUrlId, useCurrentBot } from '../../utils/CurrentBot';
import { PaletteItemsHook } from './types';
import { useHistory } from 'react-router';
import { useContext, useMemo } from 'react';
import { linkElementAltAction } from './utils';
import { slugifyIntentName } from '../../surveyCreator/model';
import { IntentIcon } from '../../icons/IntentIcon';
import { useCreatorRouter } from '../../surveyCreator/useCreatorRouter';

export const useIntentItems: PaletteItemsHook = () => {
  const intl = useIntl();
  const { intents } = useContext(SurveyContext);
  const allIntents = useContext(IntentsContext);
  const history = useHistory();
  const category = intl.formatMessage({ id: `survey-creator.command-palette.categories.intents` });
  const { getUrlForIntent } = useCreatorRouter();

  const intentObjects = useMemo(() => intents.get().map((intent) => {
    const intentName = _.get(allIntents.find(item => item.name === intent.name), 'displayName', intent.name);
    return {
      id: intent.name,
      name: intentName,
      href: getUrlForIntent(slugifyIntentName(intent.name)),
    }
  }), [intents, allIntents]);

  return useMemo(() => intentObjects?.map(intent => ({
    id: intent.id,
    name: intent.name,
    category,
    onAction: () => history.push(intent.href),
    onAltAction: () => linkElementAltAction(intent.href),
    data: intent,
    Icon: ({ className }) => <IntentIcon animationClass={className} />,
  })), [intents]);
}