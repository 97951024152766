import React from 'react';

import { SelectComponent } from '../../../../../components/Select/Select';
import { Toggle } from '../../../../../components/Toggle/Toggle';
import { Label } from '../../../../../components/Label';

import { useGlobalReadOrder } from '../../../hooks';

import { GlobalReadOrderProps } from './types';
import styles from './GlobalReadOrder.pcss';

const GlobalReadOrder: React.FC<GlobalReadOrderProps> = ({ order, onChange }) => {
    const [
        // toggle
        isEnabled,
        onReadOrderToggle,

        // select
        readOrderOptions,
        readOrderOptionValue,
        onReadOrderChange,
    ] = useGlobalReadOrder(order, onChange);

    const renderReadOrderOptions = () => {
        if (!isEnabled) {
            return null;
        }

        return <div className={styles.select} data-test={'default-read-answer-type'}>
            <SelectComponent
                options={readOrderOptions}
                value={readOrderOptionValue}
                onChange={onReadOrderChange}
            />
        </div>;
    }

    return (
        <div className={styles.container}>
            <div className={styles.toggle} data-test={'read-answers-toggle'}>
                <Toggle on={isEnabled} onToggle={onReadOrderToggle} />
                <Label labelId={'survey-creator.word.readingAnswersToggle'} />
            </div>
            {renderReadOrderOptions()}
        </div>
    );
}

export default GlobalReadOrder;
