import React, { ReactNode } from 'react';
import cx from 'classnames';

import styles from './EmptyState.pcss';

export const EmptyState = ({ content, Icon, size }: {
    content: ReactNode | ReactNode[],
    Icon: React.ComponentType<{animationClass: string}>
    size?: 'small' | 'regular',
  }) => (
  <div
    className={styles.wrapper}
    data-test="empty-state"
  >
    <div className={styles.contentWrapper}>
      <Icon animationClass={cx(styles.icon, { [styles.small]: size === 'small' })} />
      <div className={styles.text}>
        {content}
      </div>
    </div>
  </div>
);
