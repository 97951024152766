import React from 'react';

export function NotificationErrorIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.119 20 13.772 2.15a2 2 0 0 0-3.543 0L.88 20a2 2 0 0 0 1.772 2.928h18.694A2 2 0 0 0 23.119 20zM10.999 8.423a1 1 0 1 1 2 0v6a1 1 0 0 1-2 0v-6zm1.05 11.51h-.027a1.528 1.528 0 0 1-1.522-1.47 1.476 1.476 0 0 1 1.448-1.53h.028A1.527 1.527 0 0 1 13.5 18.4a1.478 1.478 0 0 1-1.45 1.533z"
        fill="#F94242"
      />
    </svg>
  );
}
