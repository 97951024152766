import React from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { PhraseFilter } from '../filters/PhraseFilter/PhraseFilter';
import { ReportsFilters } from './useReports';
import { DownloadButton } from '../components/DownloadButton/DownloadButton';
import { SelectComponent } from '../components/Select/Select';

import styles from './ReportsFiltersBar.pcss';
import { ReportConfig } from '../reportConfig/ReportConfig';
import { DatePicker } from '../components/Dates/DatePicker/DatePicker';

type ReportsFiltersBarProps = {
  filters: {
    search?: string;
    startDate?: string;
    endDate?: string;
    status?: string;
  };
  setFilters: (newFilters: Partial<ReportsFilters>) => void;
  downloadReport: () => void;
  isDownloading: boolean;
  selectedReportName: string;
  timezone?: string;
};

const REPORT_STATUSES = ['DEFAULT', 'COMPLETED'];

export const ReportsFiltersBar = ({
  filters,
  setFilters,
  downloadReport,
  isDownloading,
  selectedReportName,
  timezone,
}: ReportsFiltersBarProps) => {
  const intl = useIntl();

  const mapStatutsToValue = (status: string) => ({
    id: status,
    name: intl.formatMessage({
      id: `reports.actions.status.${status}`,
    }),
  });

  return (
    <div className={styles.filters}>
      <div className={styles.datePicker} data-test={'reports-filter-date'}>
        <DatePicker
          startDate={filters.startDate ? moment.tz(Number(filters.startDate), timezone).format('YYYY-MM-DD') : undefined}
          endDate={filters.endDate ? moment.tz(Number(filters.endDate), timezone).format('YYYY-MM-DD') : undefined}
          onChange={(start: string, end: string) => {
            setFilters({
              startDate: start ? moment.tz(start, timezone).startOf('day').valueOf().toString() : undefined,
              endDate: end ? moment.tz(end, timezone).endOf('day').valueOf().toString() : undefined,
            });
          }}
        />
      </div>
      <div className={styles.callStatus} data-test={'reports-filter-status'}>
        <SelectComponent
          placeholderMessage="reports.actions.status.selectPlaceholder"
          options={REPORT_STATUSES.map(mapStatutsToValue)}
          value={filters.status && mapStatutsToValue(filters.status)}
          onChange={(value) => setFilters({ status: value ? value.id : null })}
          isClearable
        />
      </div>
      <PhraseFilter
        className={styles.searchFilter}
        onChange={(value) => setFilters({ search: value })}
        phrase={filters.search}
        withDebounce
        placeholderId="reports.filters.search"
      />
      <ReportConfig report={selectedReportName} />
      <DownloadButton
        buttonType="primary"
        data-test="download-report-button"
        textId="statistics.button.report.download"
        onClick={downloadReport}
        disabled={isDownloading}
      />
    </div>
  );
};
