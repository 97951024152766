import _, { Dictionary } from 'lodash';
import { ActionType, getType } from 'typesafe-actions';

import { addNamesDictionaryAction, deleteNamesDictionaryAction } from '../actions/actions';
import { fetchNamesDictionary } from '../actions/namesDictionaryActions';
import * as actions from '../actions/namesDictionaryActions';
import { NamesDictionaryItem } from '../utils/NamesDictionary';

import { BotsAction } from './bots';

export type NamesDictionaryAction = ActionType<typeof actions>;
export type NamesDictionary = Dictionary<NamesDictionaryItem>;

export interface NamesDictionaryState {
    data: NamesDictionary;
    fetched: boolean;
}

const defaultState: NamesDictionaryState = { fetched: false, data: {} };

export const namesDictionary = (state = defaultState, action: BotsAction | NamesDictionaryAction) => {
    switch (action.type) {
        case getType(fetchNamesDictionary.request):
            return {
                fetched: false,
                data: undefined
            };
        case getType(fetchNamesDictionary.success):
            return {
                fetched: true,
                data: action.payload
            };
        case getType(addNamesDictionaryAction.success):
            const data = {
                ...state.data,
                [action.payload.key]: action.payload.namesDictionaryItem
            };
            return {
                fetched: true,
                data
            };
        case getType(deleteNamesDictionaryAction.success):
            return {
                fetched: true,
                data: _.omit(state.data, action.payload.key)
            };
        default:
            return state;
    }
};
