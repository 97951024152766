import _ from 'lodash';

import {LanguageCode} from '../language/botLanguage';

import {i18nConfig} from './i18n';

const browserLanguageMap = {
    'pl': 'pl-PL',
    'pl-PL': 'pl-PL',
    'en-US': 'en-US',
    'en-GB': 'en-GB'
};

const supportedLanguageCodes = _.keys(i18nConfig);
const DEFAULT_LANGUAGE = 'en-US';
const DEFAULT_MESSAGES_LANGUAGE = 'default';

export const getBrowserLanguage = () => {
    // @ts-ignore
    const browserLanguage = window.navigator.userLanguage || window.navigator.language;
    return browserLanguageMap[browserLanguage] ?? DEFAULT_LANGUAGE;
};

export const mapBotMessagesLanguage = (languageCode: LanguageCode) => (supportedLanguageCodes.includes(languageCode) ? languageCode : undefined) ?? DEFAULT_MESSAGES_LANGUAGE;

export const mapBotLanguage = (languageCode: LanguageCode) => (supportedLanguageCodes.includes(languageCode) ? languageCode : undefined) ?? DEFAULT_LANGUAGE;
