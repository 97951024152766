import {default as React, useCallback, useMemo} from 'react';
import { useIntl } from 'react-intl';

import { IconButton } from '../../../components/IconButton/IconButton';
import { DeleteIcon } from '../../../icons';
import { Condition as ConditionType, ConditionError, Variable } from '../../model';
import { VerticalSeparator } from '../VerticalSeparator';
import { systemVariableEntryName } from '../variables/Variables.utils';
import { ConditionSource } from './ConditionSource';
import { ConditionVariableSelector } from './ConditionVariableSelector';
import { ConditionOperatorSelector } from './ConditionOperatorSelector';
import { ConditionOperandSelector } from './ConditionOperandSelector';

import styles from './ConditionalQuestionForm.pcss';

type ConditionProps = {
  condition: ConditionType;
  selectableVariables: ConditionSource[];
  onChange: (condition: ConditionType) => void;
  onRemove: () => void;
  questionSaveToVariableId: string;
  variables: Variable[];
  variablesForCreator: { id: string; name: string; }[];
  errors?: ConditionError;
}

export function Condition({
  selectableVariables,
  condition,
  onChange,
  onRemove,
  questionSaveToVariableId,
  variables,
  variablesForCreator,
  errors,
}: ConditionProps) {
  const intl = useIntl();

  const referencedVariable = variables.find((v) => v.id === condition.variableId);
  const selectedVariable = useMemo(() => selectableVariables.find(v => v.variable.id === condition.variableId), [selectableVariables, condition.variableId]);

  function preparePossibleValues() {
    const isSystemVariable = referencedVariable?.origin === 'system';

    if (isSystemVariable) {
      return selectedVariable.possibleValues.map((value) => ({
        id: value.id,
        key: systemVariableEntryName(intl, referencedVariable.id, value.key),
      }))
    }

    return selectedVariable.possibleValues;
  }

  const translatedSelectedVariable = selectedVariable ? {...selectedVariable, possibleValues: preparePossibleValues() }  : null;

  const onVariableChange = useCallback(variableId => onChange({variableId, operator: null, operand: null}), [onChange]);

  return <div data-test="condition" className={styles.condition}>
    <div className={styles.conditionFields}>
        <ConditionVariableSelector
          selectedVariable={selectedVariable}
          selectableVariables={selectableVariables}
          onChange={onVariableChange}
          error={errors?.variableId}
        />
        <ConditionOperatorSelector
          condition={condition}
          type={selectedVariable?.type}
          questionSaveToVariableId={questionSaveToVariableId}
          onChange={onChange}
          error={errors?.operator}
        />
      {translatedSelectedVariable && (
        <ConditionOperandSelector
          condition={condition}
          variable={translatedSelectedVariable}
          availableVariables={variablesForCreator}
          onChange={operand => onChange({...condition, operand})}
          error={errors?.operand}
        />
      )}
    </div>
    <VerticalSeparator noMargin />
    <IconButton
      data-test="remove-condition"
      Icon={DeleteIcon}
      onClick={onRemove}
    />
  </div>
}
