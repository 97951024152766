import * as React from 'react';
import { useContext } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { reorder } from '../../utils/drag-drop';
import { Block } from '../model';
import { SurveyContext, SurveyContextType } from '../SurveyCreator';

import styles from './BlockPreview.pcss';
import {BlockQuestionComponent, QuestionScopeType} from './BlockQuestionComponent';

type Props = {
    currentlySelectedBlock: Block,
    onBlockUpdated: (block: Block) => any
    blockId: string;
    scope: QuestionScopeType;
};

export const BlockPreview = (props: Props) => {
    const { questions } = useContext<SurveyContextType>(SurveyContext);


    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        props.onBlockUpdated({
            ...props.currentlySelectedBlock,
            questions: reorder(props.currentlySelectedBlock.questions, result.source.index, result.destination.index)
        });
    };

    return (
        <>
            <div data-test="details-name" className={styles.blockName}>{props.currentlySelectedBlock.name}</div>
            <div className={styles.questions}>
                <DragDropContext data-test={'drag-drop-container'} onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {
                                    questions.get().map((question, index) => (
                                        <BlockQuestionComponent
                                            blockId={props.blockId}
                                            scope={props.scope}
                                            question={question}
                                            key={question.id}
                                            index={index}
                                            selected={questions.current && questions.current.id === question.id}
                                            disabledDrag={!!questions.new || questions.get().length < 2}
                                        />
                                    ))
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </>
    );
};
