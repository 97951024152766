import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';
import _ from 'lodash';

import { Input } from '../../components/Input';
import { PasswordInput } from '../../components/PasswordInput/PasswordInput';
import { OrganizationSMSSettings } from './SMSSettings';
import { getDataTestKey, getFormKey } from './SMSSettingsFormUtils';

import styles from './SMSSettingsForm.pcss';

type TwilioSMSSettingsFormFieldsProps = {
  formik: ReturnType<typeof useFormik<OrganizationSMSSettings>>;
  keyPrefix?: string;
};

export function TwilioSMSSettingsFormFields({
  formik,
  keyPrefix = '',
}: TwilioSMSSettingsFormFieldsProps) {
  return (
    <>
      <div>
        <label
          htmlFor={getFormKey('providerConfiguration.accountSid', keyPrefix)}
          className={styles.label}
        >
          <FormattedMessage id="organization.sms.twilio.accountSid" />
        </label>
        <Input
          className={styles.input}
          id={getFormKey('providerConfiguration.accountSid', keyPrefix)}
          name={getFormKey('providerConfiguration.accountSid', keyPrefix)}
          dataTest={getDataTestKey('account-sid-input', keyPrefix)}
          onChange={(value) =>
            formik.handleChange({
              target: {
                name: getFormKey('providerConfiguration.accountSid', keyPrefix),
                value,
              },
            })
          }
          value={_.get(
            formik.values,
            getFormKey('providerConfiguration.accountSid', keyPrefix)
          )}
        />
      </div>
      <div>
        <label
          htmlFor={getFormKey('providerConfiguration.authToken', keyPrefix)}
          className={styles.label}
        >
          <FormattedMessage id="organization.sms.twilio.authToken" />
        </label>
        <PasswordInput
          id={getFormKey('providerConfiguration.authToken', keyPrefix)}
          name={getFormKey('providerConfiguration.authToken', keyPrefix)}
          dataTest={getDataTestKey('auth-token-input', keyPrefix)}
          onChange={(value) =>
            formik.handleChange({
              target: {
                name: getFormKey('providerConfiguration.authToken', keyPrefix),
                value,
              },
            })
          }
          value={_.get(
            formik.values,
            getFormKey('providerConfiguration.authToken', keyPrefix)
          )}
        />
      </div>
    </>
  );
}
