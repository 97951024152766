import cx from 'classnames';
import React from 'react';

import { HorizontalSeparator } from '../../HorizontalSeparator/HorizontalSeparator';
import { Box } from '../../Box/Box';
import { Scrollbar } from '../../Scrollbar/Scrollbar';
import { TabsLayoutProps } from '../types';

import styles from './HorizontalTabsLayout.pcss';

type HorizontalTabsLayoutClasses = Partial<{
    root: string;
    tab: string;
    currentTabContent: string;
    tabsHeader: string;
}>;

type HorizontalTabsLayoutProps = TabsLayoutProps & {
    noSeparator?: boolean;
    classes?: HorizontalTabsLayoutClasses;
    fadePadding?: boolean;
    disabled?: boolean;
};

export const HorizontalTabsLayout: React.FC<HorizontalTabsLayoutProps> = ({
  currentTab,
  tabs,
  onTabClick,
  getComponentForTab,
  noSeparator = false,
  classes,
  fadePadding = false,
  disabled = false
}) => <div className={cx(styles.horizontalTabsLayout, classes?.root)}>
  {
      disabled &&
      <div className={styles.disabledOverlay} />
  }
  <Box className={cx(styles.tabsHeader, classes.tabsHeader)}>
      <Box className={styles.tabsContainer} border>
          {tabs.map(tab => {
              const isSelected = currentTab === tab.id;
              return <Box
                  key={tab.id}
                  className={cx(styles.tab, { [styles.selected]: isSelected }, classes?.tab)}
                  onClick={() => onTabClick(tab.id)}
                  dataTest={tab.dataTest}
              >
                  {tab.Icon && <tab.Icon
                      animationClass={cx(styles.icon, { [styles.selected]: isSelected })} />}
                  {tab.label}
              </Box>;
          })}
      </Box>
      {
          !noSeparator &&
          <HorizontalSeparator />
      }
  </Box>
  <div className={cx(styles.currentTabContent, classes?.currentTabContent)}>
      <Scrollbar className={styles.scrollbar}>
          {getComponentForTab(currentTab)}
          {fadePadding && <div className={styles.fadePadding} />
          }
      </Scrollbar>
  </div>
</div>;