import classNames from 'classnames';
import React, { CSSProperties, forwardRef, ReactNode, useCallback } from 'react';
import Scrollbars, { positionValues } from 'react-custom-scrollbars';

import styles from './Scrollbar.pcss';

interface Props {
    readonly children?: ReactNode;
    readonly style?: CSSProperties;
    readonly verticalTrackClassName?: string;
    readonly hideHorizontal?: boolean;
    className?: string;
    renderView?: React.FunctionComponent<any>;
    onScroll?(scrollTop: number, scrollLeft: number): void;
}

export const Scrollbar = forwardRef<Scrollbars, Props>((props, ref) => {
    const {onScroll} = props;
    const onUpdate = useCallback(
        (data: positionValues) => {
            return props.onScroll && props.onScroll(data.scrollTop, data.scrollLeft)
        },
        [onScroll]
    );
    return (
        <Scrollbars
            ref={ref}
            onUpdate={onUpdate}
            autoHide={true}
            autoHideDuration={500}
            autoHideTimeout={500}
            className={props.className}
            style={props.style}
            renderView={props.renderView}
            renderTrackVertical={({ style, ...trackProps }) => (
                <div {...trackProps} className={classNames(styles.trackVertical, styles.track, props.verticalTrackClassName)} style={style} />
            )}
            renderThumbVertical={({ style, ...thumbProps }) => (
                <div {...thumbProps} className={classNames(styles.thumbVertical, styles.thumb)} style={{ ...style }} />
            )}
            renderTrackHorizontal={
                props.hideHorizontal
                    ? () => <span/>
                    : ({ style, ...trackProps }) => (<div {...trackProps} className={classNames(styles.trackHorizontal)} style={style} />)
            }
            renderThumbHorizontal={
                props.hideHorizontal
                    ? () => <span/>
                    : ({ style, ...thumbProps }) => (<div {...thumbProps} className={classNames(styles.thumbHorizontal, styles.thumb)} style={{ ...style }} />)
            }
        >
            {props.children}
        </Scrollbars>
    );
});
