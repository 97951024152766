import cx from 'classnames';
import React, { useCallback, useEffect } from 'react';

import { MinusIcon } from '../../icons/MinusIcon';
import { PlusIcon } from '../../icons/PlusIcon';

import styles from './NumberInputWithStepper.pcss';

type Props = {
    dataTest?: string;
    value: number;
    onChange: (value: number) => void,
    step?: number,
    minValue?: number,
    maxValue?: number,
    seconds ?: boolean
    triggerOnChangeOnMount?: boolean,
};

const getAsSeconds = (milliseconds: number) => `${(milliseconds / 1000).toFixed(1)}s`

export const NumberInputWithStepper = ({
    dataTest,
    value,
    onChange,
    seconds,
    step = 1,
    minValue = 0,
    maxValue = 99,
    triggerOnChangeOnMount = true,
}: Props) => {
    const increment = useCallback(() =>
        onChange(value < maxValue ? value + step : value),
        [onChange, value, step, minValue, maxValue]);
    const decrement = useCallback(() =>
        onChange(value > minValue ? value - step : value),
        [onChange, value, step, minValue, maxValue]);

    useEffect(() => {
      if (triggerOnChangeOnMount) {
        onChange(value ?? 0);
      }
    }, []);

    return (
        <div data-test={dataTest} className={styles.container}>
            <div className={value <= minValue ? styles.disabled : styles.stepper} onClick={decrement} data-test={'stepper-decrement'}>
                <MinusIcon animationClass={styles.stepperIcon} />
            </div>
            <div className={cx(styles.value, {[styles.secondsValue]: seconds})} data-test={'stepper-value'}>
                {seconds ? getAsSeconds(value) : value}
            </div>
            <div className={value >= maxValue ? styles.disabled : styles.stepper} onClick={increment} data-test={'stepper-increment'}>
                <PlusIcon animationClass={styles.stepperIcon} />
            </div>
        </div>
    )
}

