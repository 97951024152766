import { noop } from 'lodash';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MarkdownContent } from '../components/MarkdownContent/MarkdownContent';

import { Scrollbar } from '../components/Scrollbar/Scrollbar';
import { showErrorToast, showSuccessToast } from '../components/Toast/Toast';
import { useCurrentBot } from '../utils/CurrentBot';
import { HttpClient } from '../utils/HttpClient';
import { CancelContact } from './CancelContact';
import { CancelContactModal } from './CancelContactModal';

import styles from './ContactDetails.pcss';
import { ContactStatus, Interaction } from './model';

type Props = {
    interactions: Interaction[];
    contactId?: string;
    timezone?: string;
    status?: ContactStatus;
    onRefreshContacts?: () => void;
};

export const ContactDetails = (props: Props) => {
    const intl = useIntl();
    const currentBot = useCurrentBot();
    const [isCancelContactModalOpen, setIsCancelContactModalOpen] = useState(false);
    const openCancelContactModal = () => setIsCancelContactModalOpen(true);
    const closeCancelContactModal = () => setIsCancelContactModalOpen(false);

    const cancelContact = () => HttpClient.put({
        path: `/bots/${currentBot.id}/contacts/${props.contactId}/cancel`,
        body: {}
    })
        .then(() => {
            props.onRefreshContacts();
            showSuccessToast(
                <MarkdownContent contentKey="contacts.cancel.successToast" />
            );
        })
        .catch(() => {
            showErrorToast(
                <MarkdownContent contentKey="contacts.cancel.errorToast" />
            );
        })
        .finally(() => {
            closeCancelContactModal();
        });

    const hasActiveInteraction = !!props.interactions.find(interaction => interaction.status === 'active')
    const hasCancelableStatus = ['created', 'in_progress'].includes(props.status)
    const showCancelContactAction = hasCancelableStatus && !hasActiveInteraction

    return (
        <div className={styles.container}>
            {
                showCancelContactAction && (
                    <CancelContact onCancel={openCancelContactModal} />
                )
            }
            {
                isCancelContactModalOpen && (
                    <CancelContactModal
                        onConfirm={cancelContact}
                        onClose={closeCancelContactModal}
                    />
                )
            }
            <Scrollbar>
                <div className={styles.interactions}>
                    {props.interactions.length === 0 && (
                        <div className={styles.emptyState}>
                            <FormattedMessage id={'contacts.interaction.empty'} />
                        </div>
                    )}
                    {props.interactions.length > 0 &&
                        props.interactions
                            .sort((a, b) => moment(a.scheduledAt).valueOf() - moment(b.scheduledAt).valueOf())
                            .map((interaction) => {
                                return (
                                    <div
                                        key={props.contactId + interaction.scheduledAt + interaction.status + interaction.result}
                                        className={styles.interaction}
                                        data-test={'contact-interaction'}>
                                        <div data-test={'interaction-type'}>
                                            <FormattedMessage id={`contacts.interaction.typeLabel`} />
                                            <FormattedMessage
                                                id={`contacts.interaction.type.${interaction.transport + '-' + interaction.direction}`} />
                                        </div>
                                        <div data-test={'interaction-date'}>
                                            <FormattedMessage id={`contacts.interaction.dateLabel`} />
                                            {moment.tz(interaction.scheduledAt, props.timezone).format(intl.messages['timestamp.format'] as string)}
                                        </div>
                                        <div data-test={'interaction-status'}>
                                            <FormattedMessage id={`contacts.interaction.statusLabel`} />
                                            <FormattedMessage id={`contacts.interaction.status.${interaction.status}`} />
                                        </div>
                                        {interaction.result && <div data-test={'interaction-result'}>
                                            <FormattedMessage id={`contacts.interaction.resultLabel`} />
                                            <FormattedMessage id={`contacts.interaction.result.${interaction.result}`} />
                                        </div>}
                                        {interaction.message && <div data-test={'interaction-message'}>
                                            <FormattedMessage id={`contacts.interaction.messageLabel`} />
                                            {interaction.message}
                                        </div>}
                                    </div>
                                );
                            })
                    }

                </div>
            </Scrollbar>
        </div>
    );
};

