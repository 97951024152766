import * as React from 'react';

import {EndQuestion} from '../model';

import {CustomStatus} from './CustomStatus';
import styles from './EndQuestionComponent.pcss';
import {EndQuestionMessage} from './EndQuestionMessage';

type EndQuestionProps = { question: EndQuestion; onChange: (question: EndQuestion) => void, asIntentQuestion?: boolean };

export const EndQuestionComponent = ({question, onChange, asIntentQuestion = false}: EndQuestionProps) => {
  const setStatus = (endQuestion: EndQuestion) => {
    onChange({
      ...endQuestion
    });
  };

  return (
    <div data-test="end-reaction" className={styles.blockSelect}>
      <EndQuestionMessage question={question} onChange={onChange}/>
      {asIntentQuestion && <div className={styles.customStatus}><CustomStatus question={question} onChange={setStatus} /></div>}
    </div>
  );
};
