import * as React from 'react';
import { useContext, useLayoutEffect, useRef } from 'react';
import { animateScroll, scroller } from 'react-scroll';

import { Scrollbar } from '../../components/Scrollbar/Scrollbar';
import { SurveyContext, SurveyContextType } from '../SurveyCreator';

import { AddStepButton } from './AddStepButton';
import styles from './BlockDetails.pcss';
import { BlockPreview } from './BlockPreview';
import { VerticalSeparator } from './VerticalSeparator';
import { BlockQuestion } from '../model';
import { addOrdinalNumberToBlockQuestionName } from './utils';

const SCROLL_CONTAINER_ID = 'block-preview-scroll-container';

export const BlockDetails = () => {
  const { blocks, questions, fallback, silenceFallback } =
    useContext<SurveyContextType>(SurveyContext);
  const currentBlock = blocks.current;
  const newQuestionRef = useRef<string>();

  const currentQuestion = questions.get();
  useLayoutEffect(() => {
    if (newQuestionRef.current) {
      scroller.scrollTo(newQuestionRef.current, {
        duration: 1000,
        containerId: SCROLL_CONTAINER_ID,
        smooth: true,
      });
      newQuestionRef.current = undefined;
    }
  }, [currentQuestion]);

  useLayoutEffect(() => {
    animateScroll.scrollToTop({
      duration: 0,
      containerId: SCROLL_CONTAINER_ID,
      smooth: true,
    });
  }, [currentBlock?.id]);

  if (!currentBlock) {
    return null;
  }

  const handleAdd = (questionToAdd: BlockQuestion) => {
    newQuestionRef.current = questionToAdd.id;

    questions.add(
      addOrdinalNumberToBlockQuestionName(
        questionToAdd,
        currentBlock && currentBlock.questions.length + 1
      )
    );
  };

  const onBlockUpdated = () => {
    if (fallback.selected) {
      return fallback.update;
    } else if (silenceFallback.selected) {
      return silenceFallback.update;
    } else {
      return blocks.update;
    }
  };

  function getScope() {
    if (fallback.selected) {
      return 'fallback';
    } else if (silenceFallback.selected) {
      return 'silenceFallback';
    } else {
      return 'block';
    }
  }

  return (
    <div data-test="survey-block-details" className={styles.container}>
      <VerticalSeparator />
      <Scrollbar
        renderView={(props: any) => <div {...props} id={SCROLL_CONTAINER_ID} />}
      >
        <div className={styles.details}>
          <BlockPreview
            blockId={currentBlock.id}
            scope={getScope()}
            currentlySelectedBlock={currentBlock}
            onBlockUpdated={onBlockUpdated()}
          />
        </div>
      </Scrollbar>
      <div className={styles.buttonContainer}>
        <AddStepButton onAdd={handleAdd} disabled={!!questions.new} />
      </div>
      <VerticalSeparator />
    </div>
  );
};
