import { DependencyList, useCallback, useEffect, useRef, useState } from 'react';

import { cancellablePromise, CancellablePromise, isCancellablePromise } from './cancellablePromise';

export function cancellableStore() {
    const cancellables: Array<{ cancel(): void }> = [];
    return {
        add<T extends { cancel(): void }>(cancelable: T) {
            cancellables.push(cancelable);
        },
        cancel() {
            cancellables.forEach(c => c.cancel());
            cancellables.splice(0, cancellables.length);
        }
    };
}

export function cancellableDelay<Args extends any[], R>(
    func: (...args: Args) => R,
    time: number
): (...args: Args) => CancellablePromise<R> {
    let timeout;
    return (...args: Args) =>
        cancellablePromise(
            new Promise(resolve => {
                timeout = setTimeout(() => resolve(func(...args)), time);
            }),
            () => {
                if (timeout) {
                    clearTimeout(timeout);
                }
            }
        );
}

interface AsyncState<T> {
    readonly loading?: boolean;
    readonly error?: Error;
    readonly value?: T;
}

interface AsyncOptions {
    readonly minimumQueryLoadingTime?: number;
}

export function useAsyncFn<Result = any, Args extends any[] = any[]>(
    fn: (...args: Args | []) => Promise<Result>,
    deps: DependencyList = [],
    options: AsyncOptions = {}
) {
    const pendingTasks = useRef(cancellableStore());

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error>();
    const [value, setValue] = useState<Result>();

    const callback = useCallback((...args: Args | []) => {
        pendingTasks.current.cancel();
        setLoading(true);
        setError(undefined);
        const loadingStartTime = options.minimumQueryLoadingTime ? performance.now() : 0;

        const promise = fn(...args);
        if (isCancellablePromise(promise)) {
            pendingTasks.current.add(promise);
        }
        return promise
            .then(data => {
                if (options.minimumQueryLoadingTime) {
                    const elapsedTime = performance.now() - loadingStartTime;
                    if (elapsedTime < options.minimumQueryLoadingTime) {
                        const delayedResolve = cancellableDelay(
                            () => data,
                            options.minimumQueryLoadingTime - elapsedTime
                        )();
                        pendingTasks.current.add(delayedResolve);
                        return delayedResolve;
                    }
                }
                return data;
            })
            .then(data => {
                setValue(data);
                setLoading(false);
                return data;
            })
            .catch(e => {
                setError(e);
                setLoading(false);
                return e;
            });
    }, deps);

    return {
        state: {
            loading,
            error,
            value
        },
        cancelPendingTasks: pendingTasks.current.cancel,
        callback
    };
}
