import * as React from 'react';
import {Draggable} from 'react-beautiful-dnd';

import {Block} from '../model';
import { useCreatorRouter } from '../useCreatorRouter';

import styles from './Block.pcss';
import {BlockOptionsComponent} from './BlockOptions';
import {PillComponent} from './pill/Pill';

type Props = {
    block: Block;
    index: number;
    selected?: boolean;
    someBlockEdit?: boolean;
    onEditToggle?: (value: boolean) => void;
    onBlockNameUpdate?: (name: string, block: Block) => void;
    setBlockAsFirst: (block: Block) => void;
    removeBlock: (block: Block) => void;
    cloneBlock: (block: Block) => void;
    dataTest?: string;
}


export const BlockComponent = (props: Props) => {
    const {block, index} = props;
    const {navigateToBlock} = useCreatorRouter();
    return (
      <Draggable draggableId={block.id} index={index}>
        {provided => (
          <PillComponent
            draggable={provided}
            key={block.id}
            isFirst={index === 0}
            onSelect={() => navigateToBlock(block.id)}
            item={block}
            itemName={block.name}
            selected={props.selected}
            dataTest={'survey-creator-block'}
            onItemNameChange={props.onBlockNameUpdate}
            isEditable
            onEditToggle={props.onEditToggle}
            someBlockEdit={props.someBlockEdit}
            renderOptions={params => (
              <BlockOptionsComponent
                {...params}
                isFirst={index === 0}
                onDelete={() => props.removeBlock(block)}
                onStart={() => props.setBlockAsFirst(block)}
                onClone={() => props.cloneBlock(block)}
              />
            )}
            classes={{ editableContainer: styles.editableContainer }}
          />
        )}
      </Draggable>
    );
};

