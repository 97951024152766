import React from 'react';

import { IconProps } from './iconTypes';
import styles from './icons.pcss';

export function ConditionalQuestionIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" className={props.animationClass}>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g transform="translate(-347 -35) translate(24 24) translate(323 11)">
                            <path d="M0 0H18V18H0z"/>
                            <path className={styles.iconFillColor} d="M1.718 0C.769 0 0 .77 0 1.718v14.564C0 17.231.77 18 1.718 18h14.564c.949 0 1.718-.77 1.718-1.718V1.718C18 .769 17.23 0 16.282 0H1.718zm0 1.195h14.564c.289 0 .523.234.523.523v14.564c0 .289-.234.523-.523.523H1.718c-.289 0-.523-.234-.523-.523V1.718c0-.289.234-.523.523-.523zm3.856 12.497l2.948-3.93c.198-.264.573-.318.836-.12.264.198.318.573.12.837L6.117 14.96c-.104.139-.263.225-.436.238-.172.012-.342-.051-.465-.174l-2.24-2.24c-.233-.234-.233-.612 0-.846.233-.233.612-.233.845 0l1.753 1.754zm5.107-.173h3.36c.33 0 .598-.268.598-.598 0-.33-.268-.597-.598-.597h-3.36c-.33 0-.598.267-.598.597 0 .33.268.598.598.598zM5.574 6.97l2.948-3.93c.198-.264.573-.317.836-.12.264.198.318.573.12.837L6.117 8.238c-.104.139-.263.225-.436.238-.172.012-.342-.051-.465-.174l-2.24-2.24c-.233-.234-.233-.612 0-.846.233-.233.612-.233.845 0L5.574 6.97zm5.107-.173h3.36c.33 0 .598-.268.598-.598 0-.33-.268-.597-.598-.597h-3.36c-.33 0-.598.267-.598.597 0 .33.268.598.598.598z"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
