import React from 'react';

import {IconProps} from './iconTypes';

export function ReplaceAudioIcon(props: IconProps) {
    return (

    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className={props.animationClass} fillRule="evenodd" clipRule="evenodd"
              d="M13.745 3.389A1.718 1.718 0 0 0 12.657 3h-7.94l-.147.006C3.69 3.081 3 3.82 3 4.718V17.04l.006.149c.075.88.813 1.57 1.712 1.57h4.481l.097-.009a.598.598 0 0 0-.097-1.187H4.718l-.094-.008a.523.523 0 0 1-.429-.515V4.718l.008-.094a.523.523 0 0 1 .515-.429h7.938l.083.007a.523.523 0 0 1 .287.146l2.145 2.145.053.063c.064.088.1.195.1.306V9.2l.008.097a.598.598 0 0 0 1.187-.097V6.862l-.009-.17a1.718 1.718 0 0 0-.494-1.044L13.87 3.503l-.126-.114zm2.392 7.464a4.76 4.76 0 0 1 3.668 2.156V12c0-.297.217-.543.5-.59l.098-.008c.297 0 .543.217.59.501L21 12v2.8a.598.598 0 0 1-.5.59l-.098.008h-2.8a.598.598 0 0 1-.097-1.187l.097-.008h1.49a3.565 3.565 0 0 0-6.651.244.598.598 0 0 1-1.131-.387 4.76 4.76 0 0 1 4.605-3.217l.222.01zm-4.1 8.99v-1.01a4.76 4.76 0 0 0 8.495-1.05.598.598 0 0 0-1.13-.388 3.564 3.564 0 0 1-6.652.244h1.49l.098-.008a.598.598 0 0 0-.097-1.187H11.44l-.097.008a.598.598 0 0 0-.5.59v2.8l.007.097c.046.284.293.5.59.5l.097-.007a.598.598 0 0 0 .5-.59zM5.241 12.56c0-1.012 1.059-1.718 2.278-1.718.356 0 .698.06 1.005.171l-.142-2.851a.598.598 0 0 1 .442-.607l.092-.017.793-.085a2.603 2.603 0 0 1 2.504 1.243.597.597 0 1 1-1.023.618 1.408 1.408 0 0 0-1.22-.68l-.135.008-.232.024.188 3.768a1.42 1.42 0 0 1 .006.134c-.005 1.008-1.062 1.71-2.278 1.71-1.22 0-2.278-.706-2.278-1.718zm3.359-.027.001.037c-.012.224-.454.513-1.082.513-.637 0-1.083-.297-1.083-.523 0-.225.446-.523 1.083-.523.611 0 1.047.274 1.08.496z"
              fill="#919AAF"/>
    </svg>
    );
}
