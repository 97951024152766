import cx from 'classnames'
import React from 'react'

import { StandardInitialProps } from '../types'

import styles from './NotificationBadge.pcss'

export type BoxProps = StandardInitialProps & {
  dataTest?: string;
};

const MAX_NUMBER = 500;

const getContentToDisplay = (content: React.ReactNode) => {
  const typeOfContent = typeof content;

  if (!['string', 'number'].includes(typeOfContent)) {
    return content;
  }

  let stringParsedToNumber;

  if (typeOfContent === 'string') {
    stringParsedToNumber = parseInt(content as string, 10);

    if (Number.isNaN(stringParsedToNumber)) {
      return content;
    }
  }

  const isMoreThanMaxNumber = (stringParsedToNumber || content) > MAX_NUMBER;
  const numberContent = Math.min((stringParsedToNumber || content), MAX_NUMBER);

  return isMoreThanMaxNumber ? `+${numberContent}` : numberContent;
};

export const NotificationBadge: React.FC<BoxProps> = ({
  className,
  children,
  dataTest,
}) => {
  return (
    <div
      data-test={dataTest}
      className={cx(
        styles.badge,
        { [styles.withChildren]: !!children },
        className
      )}>
      {getContentToDisplay(children)}
    </div>
  );
}
