import * as React from 'react';
import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';

import { ClearIcon } from '../components/ClearIcon/ClearIcon';
import { DebouncedInput } from '../components/DebouncedInput';
import { SelectComponent } from '../components/Select/Select';
import { isNullOrUndefined } from '../utils/utils';
import { PhoneIcon } from '../icons';

import { ContactsFilterType } from './Contacts';
import { parseContactsFilters, stringifyContactsFilters } from './contactsUrlFiltersState';
import { DateFilter } from './DateFilter';

import styles from './ContactsFilter.pcss';

interface ContactsFilterProps {
    contactsCount: number;
    onFilterChanged: () => void;
    timezone: string;
}

export const ContactsFilter = withRouter(({history, contactsCount, onFilterChanged, location, timezone}: ContactsFilterProps & RouteComponentProps) => {
    const intl = useIntl();

    const statusOptions = ['created', 'in_progress', 'survey_complete', 'survey_incomplete', 'cancelled'].map(value => ({
        id: value,
        name: intl.messages[`contacts.status.${value}`] as string,
        className: styles[value]
    }));

    const filters = parseContactsFilters(location.search);
    const updateFilters = useCallback(
        (newFilters: Partial<ContactsFilterType>) => {
            onFilterChanged();
            const newLocation = stringifyContactsFilters(

                { ...parseContactsFilters(history.location.search), ...newFilters },
                history.location
            );
            if (newLocation !== history.location) {
                history.push(newLocation);
            }
        },
        [history]
    );

    const onPhoneNumberFilterChange = (value: string) => {
        updateFilters({ ...filters, phoneNumber: value });
    };

    const onStatusFilterChange = (event?: any) => {
        const status = !!event ? event.id : null;
        updateFilters({ ...filters, status: status });
    };


    const selectedValue = !!filters.status
        ? {
            id: filters.status,
            name: isNullOrUndefined(contactsCount)
                ? intl.messages[`contacts.status.${filters.status}`] as string
                : `${intl.messages[`contacts.status.${filters.status}`]} (${contactsCount})`
        }
        : null;

    return (
        <div className={styles.container}>
            <DateFilter
                data-test="contacts-date-range-filter"
                startDate={filters.startDate}
                endDate={filters.endDate}
                updateFilters={updateFilters}
                timezone={timezone}
            />
            <div className={styles.callStatus} data-test={'contacts-status-filter'}>
                <SelectComponent
                    options={statusOptions}
                    onChange={onStatusFilterChange}
                    value={selectedValue}
                    isClearable={true}
                    components={{
                        Placeholder: () => (
                            <div className={styles.placeholder}>
                                <span>
                                    <FormattedMessage id="contacts.statusFilter.status" />
                                </span>
                            </div>
                        )
                    }}
                />
            </div>
            <div className={styles.phoneNumber} data-test={'contacts-phone-number-filter-container'}>
                <span className={styles.phoneNumber__icon}><PhoneIcon /></span>

                <DebouncedInput
                    width={140}
                    debounced={500}
                    dataTest="contacts-phone-number-filter"
                    className={styles.phoneNumberInput}
                    placeholder={intl.messages['contacts.phoneNumberFilterLabel'] as string}
                    value={filters.phoneNumber}
                    onChange={onPhoneNumberFilterChange}
                />
                <ClearIcon enabled={!!filters.phoneNumber} onClear={() => onPhoneNumberFilterChange(undefined)} />
            </div>
        </div>
    );
});
