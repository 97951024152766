import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '../../components/Button/Button';
import { Loader } from '../../components/Loader';
import { ConversationsReport } from '../reports/ConversationsReport';
import { Report } from '../reports/model';
import { GenerateReport } from '../reports/useReports';

import styles from './ConversationsReportDropdownContent.pcss';
import { TooltipComponent } from '../../components/Tooltip/Tooltip';

export type ConversationsReportDropdownContentProps = {
  isLoading?: boolean;
  reports: readonly Report[];
  downloadReport: (report: Report) => void;
  generateReport: GenerateReport;
  hasSelectedDateFilters?: boolean;
}

export const ConversationsReportDropdownContent: React.FC<ConversationsReportDropdownContentProps> = ({
  isLoading = false,
  reports = [],
  downloadReport,
  generateReport,
  hasSelectedDateFilters,
}) => {
  const intl = useIntl();
  const isReportRequestDisabled = isLoading || !hasSelectedDateFilters;

  return <div className={styles.conversationsReportDropdownContent}>
    <TooltipComponent
      hidden={hasSelectedDateFilters}
      tooltipText={intl.formatMessage({ id: 'conversations.reports.reportRequestDisabled' })}
      className={styles.tooltipWrapper}
    >
      <Button
        dataTest={'send-report-request-btn'}
        onClick={generateReport}
        disabled={isReportRequestDisabled}
        className={styles.sendReportRequestButton}

      >
        <FormattedMessage id="conversations.reports.generate" />
      </Button>
    </TooltipComponent>
    <div className={styles.conversationsReportDropdownContentWrapper}>
      {isLoading && <Loader className={styles.loader} />}
      {!isLoading &&
        reports.map(report => (
          <ConversationsReport
            key={report.id}
            report={report}
            onClick={downloadReport}
          />
        ))}

    </div>
  </div>;
}