import React from 'react';
import { useIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { Messages } from '../../i18n/i18n';
import { markdownContentI18nHelper, MarkdownContentI18nHelperArgs } from './MarkdownContentI18nHelper';
import styles from './MarkdownContent.pcss';
import classNames from 'classnames';

type MarkdownContentProps = {
  contentKey: keyof Messages;
  extraValues?: MarkdownContentI18nHelperArgs['extraValues'];
  className?: string;
}

export const MarkdownContent: React.FC<MarkdownContentProps> = ({
  contentKey,
  extraValues = {},
  className
}) => {
  const intl = useIntl();
  return <ReactMarkdown 
  className={classNames(styles.markdownContent, className)} 
  rehypePlugins={[rehypeRaw]}
  components={{
    a: (props: any) => <a {...props} target="_blank" rel="noopener noreferrer" />,
  }}
  >
    {markdownContentI18nHelper({contentKey, extraValues, intl})}
  </ReactMarkdown>;
}