import React from 'react';

import {IconProps} from './iconTypes';

export function AddScriptStepIcon(props: IconProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g transform="translate(-425 -662) translate(365 654) translate(60 8)">
                <path d="M0 0H24V24H0z"/>
                <path className={props.animationClass} fillRule="nonzero" d="M16.391 10.294l.225.006c1.72.076 3.26 1.11 3.975 2.687.682 1.502.498 3.242-.452 4.563l-.104.136.455 2.614c.082.475-.398.832-.82.653l-.082-.043-2.415-1.487-.203.03c-1.505.192-3.016-.372-4.028-1.515l-.147-.176c-1.104-1.38-1.319-3.27-.552-4.862.767-1.592 2.38-2.605 4.148-2.606zm.005 1.195c-1.315 0-2.508.75-3.076 1.928-.568 1.179-.41 2.578.408 3.6.818 1.023 2.15 1.486 3.426 1.194.153-.035.313-.009.447.073l1.484.914-.274-1.576c-.024-.143.004-.289.078-.411l.064-.087c.886-1 1.102-2.428.55-3.645s-1.769-1.996-3.107-1.99zm-4.24-8.446c2.814.305 5.207 2.188 6.163 4.849.111.31-.05.651-.361.763-.31.11-.653-.05-.764-.36-.801-2.232-2.807-3.81-5.166-4.065-2.36-.256-4.658.856-5.919 2.864-1.261 2.007-1.264 4.558-.007 6.568.09.144.113.318.068.479l-.036.094-1.271 2.667 2.67-1.27c.154-.072.33-.076.484-.013l.09.046c.703.438 1.488.73 2.307.859.326.051.548.357.497.682-.052.326-.357.548-.683.497-.76-.12-1.496-.357-2.18-.703l-.288-.154-3.903 1.857c-.48.228-.98-.232-.83-.71l.033-.085 1.859-3.9-.12-.218c-1.157-2.224-1.108-4.895.148-7.08l.15-.25c1.504-2.395 4.246-3.722 7.06-3.417zm5.446 12.287c.33 0 .597.267.597.597 0 .296-.216.542-.5.589l-.097.007h-2.24c-.33 0-.598-.267-.598-.596 0-.297.217-.543.5-.59l.097-.007h2.24zm0-2.238c.33 0 .597.268.597.597 0 .297-.216.543-.5.589l-.097.008h-2.24c-.33 0-.598-.267-.598-.597 0-.296.217-.543.5-.589l.097-.008h2.24zm-7.281-2.237c.33 0 .597.267.597.597 0 .296-.217.542-.5.588l-.097.008H8.08c-.33 0-.598-.267-.598-.596 0-.297.217-.543.5-.59l.097-.007h2.24zm3.36-3.356c.33 0 .598.267.598.596 0 .297-.217.543-.5.59l-.098.007h-5.6c-.33 0-.598-.267-.598-.597 0-.296.217-.542.5-.589L8.08 7.5h5.601z"/>
              </g>
            </g>
          </g>
        </g>
      </svg>


    );
}
