import * as React from 'react';

import {ToggleWithLabel} from './ToggleWithLabel';

type QuestionProps = { isPostponeRequest?: boolean };
type Props = { question: QuestionProps, onChange: (question: QuestionProps) => void };

export const IsPostponeRequestToggle = ({question, onChange}: Props) => {
    const setPostponeRequest = () => onChange({...question, isPostponeRequest: !question.isPostponeRequest});
    return (
        <ToggleWithLabel dataTest={'is-postpone-request-toggle'} labelId={'survey-creator.isPostponeRequest'}
                         value={!!question.isPostponeRequest} onToggle={setPostponeRequest}/>
    );
};
