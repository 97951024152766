import React from 'react';

import styles from './icons.pcss';

export function ClockIcon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <path className={styles.iconStrokeColor} fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" d="M9 16.636A7.636 7.636 0 1 0 9 1.364a7.636 7.636 0 0 0 0 15.272zM9 9V6.273M9 9l3.409 3.41"/>
      </svg>
    );
}
