import React, { useState } from 'react';
import { TranscriptItemRedirect } from '../models/Transcript';
import { RedirectStep } from '../../icons/conversations/RedirectStep';
import { RedirectStepLineDetails } from './RedirectStepLineDetails';
import styles from './StepLine.pcss';

type Props = {
  line: TranscriptItemRedirect;
  moduleLink: string;
  isStepLinkExpired: boolean | undefined;
};

export function RedirectStepLine({ line, moduleLink, isStepLinkExpired }: Props) {
  const [isModalOpened, setIsModalOpened] = useState(false);

  return (
    <div className={styles.wrapper}>
      <button
        data-test="redirect-step-line"
        className={styles.button}
        onClick={() => setIsModalOpened(true)}
      >
        <RedirectStep animationClass={styles.icon} />
        <span className={styles.text}>{line.stepName}</span>
      </button>
      {isModalOpened && (
        <RedirectStepLineDetails
          {...{ line, moduleLink }}
          onClose={() => setIsModalOpened(false)}
          isStepLinkExpired={isStepLinkExpired}
        />
      )}
    </div>
  );
}
