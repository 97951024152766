import React from 'react';

import { IconProps } from './iconTypes';

export function EmailConfirmIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <path fill="#48BE4D" d="M46.394 28.787C56.117 28.787 64 36.67 64 46.394 64 56.117 56.117 64 46.394 64c-9.724 0-17.607-7.883-17.607-17.606 0-9.724 7.883-17.607 17.607-17.607zm0 2.313c-8.447 0-15.294 6.847-15.294 15.294 0 8.446 6.847 15.293 15.294 15.293 8.446 0 15.293-6.847 15.293-15.293 0-8.447-6.847-15.294-15.293-15.294zm8.025 9.587c.477.358.599 1.013.301 1.514l-.07.105-7.966 10.62c-.56.743-1.413 1.208-2.341 1.275-.862.061-1.709-.226-2.356-.794l-.145-.137-4.113-4.112c-.452-.452-.452-1.184 0-1.636.422-.421 1.088-.45 1.542-.084l.094.084 4.11 4.11c.186.185.442.28.703.261.223-.016.432-.114.585-.272l.072-.085 7.964-10.618c.384-.51 1.109-.614 1.62-.231z" transform="translate(-628.000000, -311.000000) translate(628.000000, 311.000000)" />
            <path fill="#3C38BC" d="M54.618 0c1.651 0 3.124.759 4.09 1.947l.01.012c.692.859 1.12 1.93 1.165 3.098l.005.212v18.506c0 .639-.518 1.157-1.157 1.157-.599 0-1.092-.455-1.15-1.039l-.007-.118V5.27c0-.303-.045-.595-.13-.87L36.18 20.755c-3.594 2.765-8.568 2.826-12.223.184l-.247-.184L2.443 4.399c-.068.222-.11.456-.125.696l-.005.174v32.9c0 1.574 1.23 2.86 2.782 2.95l.174.005h16.45c.639 0 1.157.518 1.157 1.157 0 .599-.456 1.092-1.039 1.15l-.118.007H5.269c-2.839 0-5.154-2.246-5.265-5.057L0 38.169v-32.9C0 2.43 2.245.115 5.057.004L5.27 0h49.35zm0 2.313H5.27c-.479 0-.931.114-1.331.316l21.181 16.292c2.768 2.13 6.592 2.187 9.416.173l.233-.173L55.95 2.63c-.352-.177-.743-.287-1.158-.31l-.174-.006z" transform="translate(-628.000000, -311.000000) translate(628.000000, 311.000000)" />
          </g>
        </g>
      </g>
    </svg>

  )
};