import {ConditionalSourceDictionary} from './useAvailableConditionalMessages';

export const populateValue = (dict: ConditionalSourceDictionary, text: string) =>
  Object.keys(dict).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: {text}
    }),
    {}
  );
