import React from 'react';
import {useIntl} from 'react-intl';
import _ from 'lodash';

import {TextAreaWithVariables} from '../../../components/TextArea/TextAreaWithVariables';
import {Input} from '../../../components/Input';
import {StringChipsComponent} from '../../../components/chips/ChipsComponent';
import {Condition, ConditionOperator} from '../../model';
import {VariableType} from '../variables/Variables.utils';

import {DictionaryConditionSource, DictionaryConditionValue} from './DictionaryConditionValue';
import {ExistsConditionValue} from './ExistsConditionValue';
import {ConditionSource} from './ConditionSource';
import styles from './ConditionalQuestionForm.pcss';

interface Props {
  condition: Condition;
  variable: ConditionSource;
  availableVariables: { id: string; name: string; }[];
  onChange: (operand: string | string[] | number | boolean) => void;
  error?: string;
}

export const ConditionOperandSelector = ({ condition, variable, availableVariables, onChange, error }: Props) => {
  const intl = useIntl();

  const getInputComponent = () => {
    if (condition.operator === ConditionOperator.Exists) {
      return (
        <ExistsConditionValue onChange={onChange} value={condition.operand as boolean} error={error} />
      );
    }

    if (condition.operator === ConditionOperator.Includes && variable.type !== VariableType.DICTIONARY) {
      return (
        <StringChipsComponent
          data-test="chips-component"
          items={condition.operand as string[] || []}
          i18nKey={'survey-creator.condition.includes.chipsPlaceholder'}
          onChange={onChange}
          error={error}
        />
      );
    }

    if (variable.type === 'number') {
      return (
        <Input
          pattern="[0-9]*"
          dataTest="numerical-value"
          value={(_.isNaN(condition.operand) || _.isUndefined(condition.operand)) ? '' : (condition.operand as number)}
          onChange={(value) => onChange(parseInt(value, 10))}
          error={error}
        />
      );
    }

    if (
      (
        condition.operator === ConditionOperator.Equal ||
        condition.operator === ConditionOperator.NotEqual
      ) && variable.type === VariableType.STRING
    ) {
      return (
        <TextAreaWithVariables
          asInput
          dataTest="string-value"
          availableVariables={availableVariables}
          value={(condition.operand ?? '') as string}
          onChange={onChange}
          errorMessage={error}
        />
      );
    }

    return (
      <DictionaryConditionValue
        conditionKey={condition.operator}
        value={condition.operand}
        referencedSource={variable as DictionaryConditionSource}
        onChange={onChange}
        error={error}
      />
    );
  }

  if (!condition.operator
    || condition.operator === ConditionOperator.Truthy
    || condition.operator === ConditionOperator.Falsy
    || condition.operator === ConditionOperator.Invalid) {
    return null;
  }

  return (
    <div data-test="condition-operand" className={styles.conditionOperand}>
      <span>{intl.messages['survey-creator.conditional.selectOperandPlaceholder']}</span>
      {getInputComponent()}
    </div>
  );
}
