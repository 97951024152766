import React from 'react';
import { CommandPalette } from '@talkie/command-palette';
import { useCommandPalette } from './AnalyticsCommandPaletteProvider';
import { AnalyticsPaletteContainerComponent } from './components/AnalyticsPaletteContainerComponent';
import { PaletteItemComponent } from './components/AnalyticsItemComponent';
import { AnalyticsSearchComponent } from './components/AnalyticsSearchComponent';
import { AnalyticsCategoryComponent } from './components/AnalyticsCategoryComponent';
import { PaletteFooterComponent } from './components/PaletteFooterComponent';
import styles from './AnalyticsCommandPalette.pcss';

export function AnalyticsCommandPalette() {
    const { items, categories } = useCommandPalette();

    return <div
        data-test="analytics-command-palette">
        <CommandPalette
            container={document.body}
            classes={{
                open: 'open',
                opening: styles.opening,
                closing: styles.closing,
                selectedItem: 'selectedItem',
            }}
            items={items}
            categories={categories}
            ContainerComponent={AnalyticsPaletteContainerComponent}
            ItemComponent={PaletteItemComponent}
            SearchComponent={AnalyticsSearchComponent}
            CategoryComponent={AnalyticsCategoryComponent}
            FooterComponent={PaletteFooterComponent}
        />
    </div>
}

