import classNames from 'classnames';
import _ from 'lodash';
import * as React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {BasicMessage, Message, QuestionError} from '../../../model';
import {AddButton} from '../../AddButton';
import {RepeatQuestionsLimit} from '../../RepeatQuestionsLimit';
import {useFixedPosition} from '../../useFixedPosition';
import styles from '../Message.pcss';
import {SingleMessageComponent} from '../SingleMessage';

import {
    ConditionalMessageSource,
    findSource,
    getConditionalAnswerLabel,
    MultipleConditionalMessageProps,
    withDefaultAtEnd
} from './conditional-messages-utils';
import {Condition} from './ConditionalMessages';
import conditionalStyles from './ConditionalMessages.pcss';

type ReferencedSourceProps = {
    referencedSource: ConditionalMessageSource;
}

type Props =
    MultipleConditionalMessageProps
    & ReferencedSourceProps
    & MultipleConditionalMessagesProps
    & {}

const MultipleConditionalMessagesComponent = (props: Props) => {
    const intl = useIntl();
    const handleChange = (id: string, indexToChange: number) => (changedMessage: BasicMessage) => {
        props.onChange({
            ...props.message,
            values: {
                ...props.message.values,
                [id]: props.message.values[id].map((value, index) => index === indexToChange ? changedMessage : value)
            }
        });
    }

    const handleAddVersion = (id: string) => () => {
        props.onChange({
            ...props.message,
            values: {
                ...props.message.values,
                [id]: [...props.message.values[id], {text: ''}]
            }
        });
    };
    const handleRemoveVersion = (id: string, indexToRemove: number) => () => {
        props.onChange({
            ...props.message,
            values: {
                ...props.message.values,
                [id]: props.message.values[id].filter((message, index) => indexToRemove !== index)
            }
        });
    };

    const isFirstMessage = (index: number) => index === 0;
    const canAddMoreMessages = (values) => values.length < 2;
    const alternativeLabel = (conditionText: string) => intl.messages[props.alternativeLabelId || 'survey-creator.repeatMessageLabelAlternative'] + ': ' + conditionText;
    const labelByIndex = (answerId, index) => index === 0 ? getConditionalAnswerLabel(intl.messages, props.referencedSource.possibleValues, answerId) : alternativeLabel(getConditionalAnswerLabel(intl.messages, props.referencedSource.possibleValues, answerId));
    const errorFor = (answerId: string, index: number) => {
        const errors = props.errors && props.errors
            .filter(({field}) => field.includes(`repeatMessages.values.${answerId}[${index}].text`))
            .map((({error}) => error));
        return errors?.length > 0 ? errors[0] : undefined;
    };
    return (
        <>
            {_.orderBy<any>(Object.entries(props.message.values), withDefaultAtEnd).map(([answerId, messageValues], answerIndex) => (
                <div data-test="multiple-conditional-messages" key={answerId} className={styles.messagesContainer}>
                    {messageValues.map((messageValue, index) => {
                        const isLastVersion = index === messageValues.length - 1;
                        const isLastAnswer = answerIndex === Object.entries(props.message.values).length - 1;
                        const showRepeatQuestionsLimit = !props.noRepeatedQuestionCount && isLastAnswer && isLastVersion;
                        const showBottomContainer = canAddMoreMessages(messageValues) || showRepeatQuestionsLimit;
                        return (
                            <React.Fragment key={index}>
                                <div data-test={'multiple-conditional-message-element'}
                                     className={styles.messageElement}>
                                    <SingleMessageComponent
                                        label={labelByIndex(answerId, index)}
                                        message={messageValue}
                                        dataTestPrefix={props.dataTestPrefix}
                                        onChange={handleChange(answerId, index)}
                                        standalone={false}
                                        onDelete={!isFirstMessage(index) && handleRemoveVersion(answerId, index)}
                                        onConditionChange={isFirstMessage(index) && props.onConditionChange}
                                        errorMessage={errorFor(answerId, index)}
                                    />
                                </div>
                                {
                                    showBottomContainer &&
                                    <div
                                        className={classNames(styles.bottomContainer, {[styles.forceHeight]: canAddMoreMessages(messageValues) && !showRepeatQuestionsLimit})}>
                                        <div>
                                            {
                                                canAddMoreMessages(messageValues) &&
                                                <AddButton onClick={handleAddVersion(answerId)}
                                                           dataTest={'add-message-version'}
                                                           labelId={'survey-creator.addRepeatMessageButton'}
                                                           buttonType={'link'}/>
                                            }
                                        </div>
                                        {showRepeatQuestionsLimit &&
                                        <RepeatQuestionsLimit onChange={props.onMaxRepeatQuestionCountChange}
                                                                      maxRepeatQuestionCount={props.maxRepeatQuestionCount}/>}
                                    </div>
                                }

                            </React.Fragment>
                        );
                    })}
                </div>
            ))}
        </>
    );
};

type MultipleConditionalMessagesProps = MultipleConditionalMessageProps & {
    maxRepeatQuestionCount?: number,
    onMaxRepeatQuestionCountChange?: (value: number) => void,
    onConditionChange?: (message: Message) => void;
    nested?: boolean,
    alternativeLabelId?: string;
    mainLabelId?: string;
    errors?: QuestionError[];
};

export const MultipleConditionalMessageComponent = (props: MultipleConditionalMessagesProps) => {
    const referencedSource = findSource(props.availableConditionalSources, props.message.name);
    const {contentRef, style} = useFixedPosition([], {top: -46, left: 231});
    return (
        <div data-test={`multiple-conditional-${props.dataTestPrefix}-message`}
             className={classNames(conditionalStyles.messageContainer, conditionalStyles.multi, {[conditionalStyles.nested]: props.nested})}>
            <div className={conditionalStyles.header} ref={contentRef}>
                <div className={conditionalStyles.label}>
                    <FormattedMessage id={props.mainLabelId || 'survey-creator.repeatMessageLabel'}/>
                </div>
                <div className={conditionalStyles.condition}>
                    <Condition  {...props} valueMapper={value => [value]} selectorPositionStyle={style} referencedSource={referencedSource}/>
                </div>
            </div>
            {referencedSource && <MultipleConditionalMessagesComponent {...props} referencedSource={referencedSource}/>}
        </div>
    );
}
