import React from 'react';
import { useIntl } from 'react-intl';
import { SelectComponent } from '../../../components/Select/Select';
import { ConditionSource } from './ConditionSource';
import styles from './ConditionalQuestionForm.pcss';

type ConditionVariableProps = {
  selectedVariable?: ConditionSource;
  selectableVariables: ConditionSource[];
  onChange: (variableId: string) => void;
  error?: string;
};

export const ConditionVariableSelector = ({
  selectedVariable,
  selectableVariables,
  onChange,
  error,
}: ConditionVariableProps) => {
  const intl = useIntl();
  return (
    <div data-test="condition-variable" className={styles.conditionVariable}>
      <span>{intl.messages['survey-creator.conditional.selectVariablePlaceholder']}</span>
      <SelectComponent
        value={
          selectedVariable
            ? {
              id: selectedVariable.variable.id,
              name: selectedVariable.variable.key,
            }
            : undefined
        }
        options={selectableVariables.map((v) => ({
          id: v.variable.id,
          name: v.variable.key,
        }))}
        onChange={(value) => onChange(value?.id)}
        placeholderMessage={
          'survey-creator.conditional.selectVariablePlaceholder'
        }
        isClearable
        isSearchable
        invalid={!!error}
        error={error}
      />
    </div>
  );
}
  