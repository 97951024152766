import * as React from 'react';

import {Message} from '../../model';
import {StepElementProps} from '../StepElementProps';

import {MessageComponent} from './Message';
import {useContext} from 'react';
import {SurveyContext, SurveyContextType} from '../../SurveyCreator';

type Props = {
    id: string;
    message: Message,
    followupMessage: Message,
    helpNeededMessage: Message,
    fromIntentMessage: Message,
}

export const HelpProposeMessages = ({onChange, question}: StepElementProps<Props>) => {
    const { questions: { withError } } = useContext<SurveyContextType>(SurveyContext);

    const setMessage = (message: Message) => {
        onChange({
            ...question,
            message
        });
    };

    const setFollowupMessage = (followupMessage: Message) => {
        onChange({
            ...question,
            followupMessage
        });
    };

    const setHelpNeededMessage = (helpNeededMessage: Message) => {
        onChange({
            ...question,
            helpNeededMessage
        });
    };

    const setFromIntentMessage = (fromIntentMessage: Message) => {
        onChange({
            ...question,
            fromIntentMessage
        });
    };
    return (
        <>
            <MessageComponent message={question.message} onChange={setMessage}
                              dataTestPrefix={'message'}
                              labelId={'survey-creator.messageLabel'}
                              errors={withError(question.id, 'message')}
            />
            <MessageComponent message={question.followupMessage} onChange={setFollowupMessage}
                              dataTestPrefix={'followupMessage'}
                              labelId={'survey-creator.followupMessageLabel'}
                              errors={withError(question.id, 'followupMessage')}
                              path="followupMessage"
            />
            <MessageComponent message={question.helpNeededMessage} onChange={setHelpNeededMessage}
                              dataTestPrefix={'helpNeededMessage'}
                              labelId={'survey-creator.helpNeededMessageLabel'}
                              errors={withError(question.id, 'helpNeededMessage')}
                              path="helpNeededMessage"
            />
            <MessageComponent message={question.fromIntentMessage} onChange={setFromIntentMessage}
                              dataTestPrefix={'fromIntentMessage'}
                              labelId={'survey-creator.fromIntentMessageLabel'}
                              path="fromIntentMessage"
            />
        </>
    );
};
