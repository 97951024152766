import React from 'react';

import {IconProps} from './iconTypes';

export function DateTimeStepIcon(props: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H24V24H0z"/>
                <path className={props.animationClass} d="M17.316 10.633c3.692 0 6.684 2.992 6.684 6.683C24 21.008 21.008 24 17.316 24c-3.69 0-6.683-2.992-6.683-6.684 0-3.69 2.992-6.683 6.683-6.683zm0 1.215c-3.02 0-5.468 2.448-5.468 5.468s2.448 5.469 5.468 5.469 5.469-2.448 5.469-5.469c0-3.02-2.448-5.468-5.469-5.468zM12.76 0c.303 0 .553.22.6.509l.008.099v.91l2.43.001c1.124 0 2.044.871 2.122 1.975l.005.152v4.557c0 .335-.272.607-.608.607-.302 0-.552-.22-.6-.509l-.007-.098-.001-.912H1.215v8.506c0 .465.348.849.797.905l.115.007h6.076c.335 0 .607.272.607.607 0 .302-.22.553-.509.6l-.098.008H2.127c-1.124 0-2.044-.871-2.122-1.975L0 15.797V3.646c0-1.124.871-2.044 1.975-2.122l.152-.005 2.429-.001.001-.91c0-.336.272-.608.608-.608.302 0 .552.22.6.509l.007.099v.91h6.379v-.91c0-.336.273-.608.608-.608zm4.557 14.024c.302 0 .553.22.6.51l.008.098v2.077h2.078c.302 0 .553.22.6.509l.008.098c0 .302-.22.553-.51.6l-.098.008h-2.686c-.302 0-.552-.22-.6-.509l-.007-.099v-2.684c0-.336.272-.608.607-.608zM4.556 2.734h-2.43c-.464 0-.847.348-.904.797l-.007.115v2.429h15.493v-2.43c0-.464-.347-.847-.796-.904l-.115-.007h-2.43v.912c0 .335-.272.607-.608.607-.302 0-.552-.22-.6-.509l-.007-.098-.001-.912H5.772v.912c0 .335-.272.607-.607.607-.302 0-.553-.22-.6-.509l-.008-.098-.001-.912z"/>
            </g>
        </svg>

    );
}
