import React from 'react';

import styles from './icons.pcss';

export function CalendarIcon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h18v18H0z" />
          <path className={styles.iconFillColor} fillRule="nonzero" d="M12.938-.037a.6.6 0 0 1 .6.6l-.001 1.649h2.775c.953 0 1.726.773 1.726 1.725v12.376c0 .952-.773 1.725-1.726 1.725H1.688a1.725 1.725 0 0 1-1.725-1.726V3.938c0-.952.773-1.724 1.726-1.724l2.774-.001V.562a.6.6 0 0 1 .503-.592l.098-.007a.6.6 0 0 1 .592.502l.007.097v1.65h6.675V.562a.6.6 0 0 1 .503-.592l.098-.007zm3.899 7.949H1.162v8.4c0 .29.236.525.526.525h14.624c.29 0 .525-.235.525-.524V7.912zM5.625 8.962c.6 0 1.093.454 1.156 1.036l.006.127v3.337h.526a.6.6 0 0 1 .592.503l.007.098a.6.6 0 0 1-.502.592l-.098.008h-2.25a.6.6 0 0 1-.097-1.193l.098-.008h.524v-3.3h-.524a.6.6 0 0 1-.098-1.192l.098-.008h.562zm6.19 0a1.163 1.163 0 0 1 1.126 1.436l-.042.136-1.402 3.74a.6.6 0 0 1-1.15-.328l.026-.094 1.384-3.69h-1.383a.6.6 0 0 1-.097-1.192l.097-.008h1.441zm-7.353-5.55H1.688a.525.525 0 0 0-.525.526l-.001 2.774h15.675V3.938a.525.525 0 0 0-.524-.526h-2.776V4.5a.6.6 0 0 1-.502.592l-.098.008a.6.6 0 0 1-.6-.6V3.412H5.662V4.5a.6.6 0 0 1-.502.592l-.098.008a.6.6 0 0 1-.592-.503L4.463 4.5l-.001-1.088z"/>
        </g>
      </svg>
    );
}
