import classNames from 'classnames';
import { Moment } from 'moment';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { useIntl } from 'react-intl';
import uuid from 'uuid';
import moment from 'moment';

import { CalendarIcon } from '../../../icons';
import arrowLeft from '../../../images/datepicker-arrow-icon-left.svg';
import arrowRight from '../../../images/datepicker-arrow-icon-right.svg';
import { ClearIcon } from '../../ClearIcon/ClearIcon';
import styles from '../themes.pcss';
import { now } from '../../../utils/DateService';

type Props = {
    startDate: string | undefined; // date in format 'YYYY-MM-DD'
    endDate: string | undefined; // date in format 'YYYY-MM-DD'
    className?: string;
    clearable?: boolean;
    allowFutureDates?: boolean;
    onChange(startDate: string | undefined, endDate: string | undefined): void; // dates in format 'YYYY-MM-DD'
};

const NavPreviousIcon = () => <img className={styles.previousMonthIcon} src={arrowLeft} alt="previous month" />;
const NavNextIcon = () => <img className={styles.nextMonthIcon} src={arrowRight} alt="next month" />;
// TODO fix wrong locale usage
// TODO need to do custom rendering of month title (after that moment locale setting in IntlProvider won't be needed any more
const DayContent = day => <div className="calendarDay" data-test={day.format('YYYY-MM-DD')}>{day.format('D')}</div>;

function getDisplayValue(startDate: string | undefined, endDate: string | undefined, dateFormat: string, focused: boolean) {
    if (focused && startDate) {
        return `${moment(startDate).format(dateFormat)} -`
    }
    if (startDate && endDate) {
        return `${moment(startDate).format(dateFormat)} - ${moment(endDate).format(dateFormat)}`
    }
    return '';
}

export function DatePicker({startDate, endDate, className, onChange, clearable = true, allowFutureDates = false}: Props) {
    const uniqueId = useMemo(() => uuid(), []);
    
    const [range, setRange] = useState({ startDate, endDate });
    useEffect(() => {
        setRange({ startDate, endDate });
    }, [startDate, endDate]);
    
    const [focused, setFocused] = useState(null);
    const intl = useIntl();
    
    const [transitionContainerStylesheet, setTransitionContainerStylesheet] = useState<string>();

    // https://github.com/airbnb/react-dates/issues/1641
    function changeMonthHandler(date: Moment) {
        setTransitionContainerStylesheet(
            `.${styles.blueTheme} .DayPicker_transitionContainer { height: ${28 * calcWeeksInMonth(date) +
                80}px !important; }`
        );
    }
    useEffect(() => {
        changeMonthHandler(startDate ? moment(startDate) : moment());
    }, []);

    const dateFormat = `${intl.messages['date.format']}`;
    const displayValue =  getDisplayValue(range.startDate, range.endDate, dateFormat, focused)    

    return (
        <>
            <style>{transitionContainerStylesheet}</style>
            <div className={classNames(styles.blueTheme, focused && styles.focused, className)}>
                <div className={styles.inputWrapper}>
                    <CalendarIcon />
                    <input
                        placeholder={`${intl.messages['date.placeholder']}`}
                        readOnly={true}
                        value={displayValue}
                        className={styles.input}
                        size={9}
                        onClick={() => setFocused('startDate')}
                    />
                    <ClearIcon
                        className={styles.clearIcon}
                        enabled={clearable && (!!startDate || !!endDate)}
                        onClear={() => {
                            setRange(() => ({
                                endDate: undefined,
                                startDate: undefined
                            }));
                            onChange(undefined, undefined);
                        }}
                    />
                </div>
                <div data-test="datepicker" className={classNames(styles.pickerContainer, !focused ? styles.hidden : undefined)}>
                    <DateRangePicker
                        verticalSpacing={10}
                        enableOutsideDays={true}
                        startDate={range.startDate ? moment(range.startDate) : undefined}
                        endDate={range.endDate ? moment(range.endDate) : undefined}
                        // TODO: startDateId and endDateId - this will not be needed in future release of @types/react-dates - clean it up after update
                        startDateId={`${uniqueId}-date-start`}
                        endDateId={`${uniqueId}-date-end`}
                        focusedInput={focused}
                        onNextMonthClick={changeMonthHandler}
                        onPrevMonthClick={changeMonthHandler}
                        onDatesChange={(change) => {
                            const start = change.startDate ? change.startDate.format('YYYY-MM-DD') : undefined;
                            const end = change.endDate ? change.endDate.format('YYYY-MM-DD') : undefined;

                            setRange({ startDate: start, endDate: end });
                            if (start && end) {
                                onChange(start, end);
                            }
                        }}
                        isOutsideRange={allowFutureDates ? () => false : day =>
                            now()
                                .startOf('day')
                                .isBefore(day.clone().startOf('day'))
                        }
                        onFocusChange={f => setFocused(f)}
                        minimumNights={0}
                        daySize={25}
                        noBorder
                        hideKeyboardShortcutsPanel
                        navPrev={<NavPreviousIcon />}
                        navNext={<NavNextIcon />}
                        renderDayContents={DayContent}
                        small
                        numberOfMonths={1}
                        horizontalMonthPadding={13}
                        initialVisibleMonth={() => now().startOf('month')}
                    />
                </div>
            </div>
        </>
    );
}

function calcWeeksInMonth(date: Moment) {
    const dateFirst = moment(date).date(1);
    const dateLast = moment(date).date(date.daysInMonth());
    const startWeek = dateFirst.week();
    const endWeek = dateLast.week();

    if (endWeek < startWeek) {
        return dateFirst.subtract(1, 'day').weeksInYear() - startWeek + 1 + endWeek;
    } else {
        return endWeek - startWeek + 1;
    }
}
