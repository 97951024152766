import React from 'react';
import Checkbox from 'react-custom-checkbox';
import {useIntl} from 'react-intl';

import {CheckMarkIcon} from '../../icons/CheckMarkIcon';

import styles from './CheckBoxComponent.pcss'

export type CheckboxComponentProps = {
    labelId?: string
    value: boolean
    onChange?: (value: boolean) => void
    dataTest?: string
}

export const CheckboxComponent = (props: CheckboxComponentProps) => {
    const intl = useIntl();

    return (
        <div data-test={props.dataTest}>
            <Checkbox
                icon={CheckMarkIcon()}
                checked={props.value}
                style={{width: 24, cursor: 'pointer'}}
                borderColor={props.value ? '#3c38bc' : '#919aaf'}
                borderWidth={1}
                borderRadius={2}
                labelClassName={props.value ? styles.labelChecked : styles.labelUnchecked}
                size={18}
                onChange={(value) => props.onChange(value)}
                label={props.labelId && intl.formatMessage({id: props.labelId})}
            />
        </div>
    );
}
