import _ from 'lodash';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import { SynonymsChipsComponent } from '../../components/chips/SynonymsChipsComponent';
import {ElementWithLabel} from '../../components/ElementWithLabel/ElementWithLabel';
import {Input} from '../../components/Input';
import {InputWithLabel} from '../../components/InputWithLabel/InputWithLabel';
import {Label} from '../../components/Label';
import {SelectComponent} from '../../components/Select/Select';
import {TextArea} from '../../components/TextArea/TextArea';
import {GenericOrderQuestion, Product, Property} from '../model';

import styles from './GenericOrderStep.pcss';
import {MessageComponent} from './messages/Message';
import {MultipleMessagesComponent} from './messages/MultipleMessages';
import {MultiSelectComponent} from './MultiSelect';
import {ToggleWithLabel} from './ToggleWithLabel';

type Props = {
  question: GenericOrderQuestion,
  onChange: (question: GenericOrderQuestion) => void
}

export const GenericOrderStep = (props: Props) => {
  const setInitialMessage = (message) => props.onChange({...props.question, initialMessage: message});
  const setProductAddedMessage = (message) => props.onChange({...props.question, productAddedMessage: message});
  const setResignationMessage = (message) => props.onChange({...props.question, resignationMessage: message});
  const setRepeatMessages = (message) => props.onChange({...props.question, repeatMessages: message});

  const findProperty = (propertyId: string) => props.question.properties.find(el => el.id === propertyId);
  const findPropertyValue = (propertyId: string, valueId: string) =>
    findProperty(propertyId).values.find(el => el.id === valueId);

  const changeProperty = (id) => (changeProvider: (property: Property) => Partial<Property>) =>
    props.onChange({...props.question, properties: props.question.properties.map(el => el.id === id ? {...el, ...changeProvider(el)} : el)});

  // TODO: change messages in text formatting!!!! anything else?
  const setPropertyType = (id) => (value) => changeProperty(id)(() => ({type: value.id}));
  const setPropertyName = (id) => (name) => changeProperty(id)(() => ({name}));
  const setPropertyMessage = (id) => (message) => changeProperty(id)(() => ({message}));
  const setPropertyTextFormattingOrder = (id) => (order) =>
    changeProperty(id)((property) => ({textFormatting: {...property.textFormatting, order: parseInt(order, 10)}}));
  const setPropertyTextFormattingPosition = (id) => (value) =>
    changeProperty(id)((property) => ({textFormatting: {...property.textFormatting, position: value.id}}));
  const setPropertyTextFormattingMessageSingular = (id) => (messageSingular) =>
    changeProperty(id)((property) => ({textFormatting: {...property.textFormatting, messageSingular}}));
  const setPropertyTextFormattingMessagePlural = (id) => (messagePlural) =>
    changeProperty(id)((property) => ({textFormatting: {...property.textFormatting, messagePlural}}));
  const setPropertyTextFormattingMessagePluralGenitive = (id) => (messagePluralGenitive) =>
    changeProperty(id)((property) => ({textFormatting: {...property.textFormatting, messagePluralGenitive}}));
  const setPropertyTextFormattingMessage = (id) => (message) =>
    changeProperty(id)((property) => ({textFormatting: {...property.textFormatting, message}}));

  const changePropertyValue = (propertyId, valueId) => (changeProvider) => {
    props.onChange({
      ...props.question,
      properties: props.question.properties.map(el => el.id === propertyId
        ? {...el, values: el.values.map(value => value.id === valueId ? changeProvider(value) : value)}
        : el
      )
    });
  };

  const setPropertyValueName = (propertyId, valueId) => (name) =>
    changePropertyValue(propertyId, valueId)((value) => ({...value, name}));
  const togglePropertyValueNumeric = (propertyId, valueId) => () =>
    changePropertyValue(propertyId, valueId)((value) => ({...value, type: value.type === 'number' ? undefined : 'number'}));
  const setPropertyValueMessageText = (propertyId, valueId) => (messageText) =>
    changePropertyValue(propertyId, valueId)((value) => ({...value, messageText}));
  const setPropertyValueSynonyms = (propertyId, valueId) => (synonyms) =>
    changePropertyValue(propertyId, valueId)((value) => ({...value, synonyms}));


  const changeProduct = (name) => (changeProvider: (property: Product) => Partial<Product>) =>
    props.onChange({...props.question, products: props.question.products.map(el => el.name === name ? {...el, ...changeProvider(el)} : el)});

  const setProductName = (name) => (newName) => changeProduct(name)(() => ({name: newName}));
  const setProductSynonyms = (name) => (synonyms) => changeProduct(name)(() => ({synonyms}));

  const setProductProperty = (name, propertyId) => (property) =>
    changeProduct(name)((product) => ({
      properties: product.properties.map(el => el.property === propertyId ? {...el, property} : el)
    }));
  const setProductPropertyValues = (name, propertyId) => (values) =>
    changeProduct(name)((product) => ({
      properties: product.properties.map(el => el.property === propertyId ? {...el, values: values.map(item => item.id)} : el)
    }));
  const setProductPropertyCustomMessage = (name, propertyId) => (message) =>
    changeProduct(name)((product) => ({
      properties: product.properties.map(el => el.property === propertyId ? {...el, message} : el)
    }));
  const toggleProductPropertyOptional = (name, propertyId) => () =>
    changeProduct(name)((product) => ({
      properties: product.properties.map(el => el.property === propertyId ? {...el, optionalProperty: !el.optionalProperty} : el)
    }));
  const toggleProductPropertyCustomMessage = (name, propertyId) => () =>
    changeProduct(name)((product) => ({
      properties: product.properties.map(el => el.property === propertyId ? {...el, message: !!el.message ? undefined : {text: ''}} : el)
    }));
  const setProductMetadataKey = (name, key) => (newKey) =>
    changeProduct(name)((product) => ({
      metadata: {
        ...product.metadata,
        [key]: undefined,
        [newKey]: product.metadata[key]
      }
    }));
  const setProductMetadataValue = (name, key) => (value) =>
    changeProduct(name)((product) => ({
      metadata: {
        ...product.metadata,
        [key]: value
      }
    }));

  return (
    <div>
      <div>
        <MessageComponent dataTestPrefix={'initial'} message={props.question.initialMessage} onChange={setInitialMessage}
                          labelId={'survey-creator.genericOrder.initialMessage'} path="initialMessage" />
        <MessageComponent dataTestPrefix={'product-added'} message={props.question.productAddedMessage} onChange={setProductAddedMessage}
                          labelId={'survey-creator.genericOrder.productAddedMessage'} path="productAddedMessage" />
        <MessageComponent dataTestPrefix={'resignation'} message={props.question.resignationMessage} onChange={setResignationMessage}
                          labelId={'survey-creator.genericOrder.resignationMessage'} path="resignationMessage" />
        <MultipleMessagesComponent dataTestPrefix={'initial'} messages={props.question.repeatMessages} onChange={setRepeatMessages} />
      </div>
      <div className={styles.outerContainer}>
        <div className={styles.customLabel}>
          <FormattedMessage id={'survey-creator.genericOrder.propertiesLabel'} />
        </div>
        {props.question.properties.map((property, index) => {
          return (
            <div key={property.id} className={styles.container}>
              <InputWithLabel onChange={setPropertyName(property.id)} value={property.name} labelId={'survey-creator.genericOrder.nameLabel'} />
              <ElementWithLabel labelId={'survey-creator.genericOrder.typeLabel'}>
                <SelectComponent options={[{id: 'values', name: 'values'}, {id: 'count', name: 'count'}]}
                                 value={{id: property.type, name: property.type}}
                                 onChange={setPropertyType(property.id)} />
              </ElementWithLabel>
              {property.type === 'values' && (
                <div className={styles.outerContainer}>
                  <Label labelId={'survey-creator.genericOrder.propertiesValuesLabel'} />
                  {property.values.map(propertyValue => {
                    return (
                      <div key={propertyValue.id} className={styles.container}>
                        <InputWithLabel onChange={setPropertyValueName(property.id, propertyValue.id)} value={propertyValue.name}
                                        labelId={'survey-creator.genericOrder.nameLabel'} />
                        <ToggleWithLabel onToggle={togglePropertyValueNumeric(property.id, propertyValue.id)} value={propertyValue.type === 'number'}
                                         labelId={'survey-creator.genericOrder.numericPropertyValue'} />
                        <ElementWithLabel labelId={'survey-creator.genericOrder.propertyValueMessageText'}>
                          <TextArea value={propertyValue.messageText} onChange={setPropertyValueMessageText(property.id, propertyValue.id)} />
                        </ElementWithLabel>
                        <ElementWithLabel labelId={'survey-creator.genericOrder.synonyms'}>
                          <SynonymsChipsComponent onChange={setPropertyValueSynonyms(property.id, propertyValue.id)} items={propertyValue.synonyms}
                                          i18nKey={'anything'} />
                        </ElementWithLabel>
                      </div>
                    );
                  })}
                </div>
              )}
              <MessageComponent dataTestPrefix={`${property.name}-message`} labelId={'survey-creator.genericOrder.propertyMessage'} message={property.message}
                                onChange={setPropertyMessage(property.id)} path={`properties[${index}].message`} />
              <div className={styles.outerContainer}>
                <Label labelId={'survey-creator.genericOrder.textFormattingLabel'} />
                <InputWithLabel onChange={setPropertyTextFormattingOrder(property.id)} value={property.textFormatting.order.toString()}
                                labelId={'survey-creator.genericOrder.orderLabel'} />
                <ElementWithLabel labelId={'survey-creator.genericOrder.positionLabel'}>
                  <SelectComponent options={[{id: 'before', name: 'before'}, {id: 'after', name: 'after'}]}
                                   value={{id: property.textFormatting.position, name: property.textFormatting.position}}
                                   onChange={setPropertyTextFormattingPosition(property.id)}
                  />
                </ElementWithLabel>
                {property.type === 'count'
                  ? (
                    <div>
                      <InputWithLabel onChange={setPropertyTextFormattingMessageSingular(property.id)} value={property.textFormatting.messageSingular}
                                      labelId={'survey-creator.genericOrder.textFormattingMessageSingularLabel'} />
                      <InputWithLabel onChange={setPropertyTextFormattingMessagePlural(property.id)} value={property.textFormatting.messagePlural}
                                      labelId={'survey-creator.genericOrder.textFormattingMessagePluralLabel'} />
                      <InputWithLabel onChange={setPropertyTextFormattingMessagePluralGenitive(property.id)} value={property.textFormatting.messagePluralGenitive}
                                      labelId={'survey-creator.genericOrder.textFormattingMessagePluralGenitiveLabel'} />
                    </div>
                  )
                  : (
                    <InputWithLabel onChange={setPropertyTextFormattingMessage(property.id)} value={property.textFormatting.message}
                                    labelId={'survey-creator.genericOrder.textFormattingMessageLabel'} />
                  )
                }
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.outerContainer}>
        <div className={styles.customLabel}>
          <FormattedMessage id={'survey-creator.genericOrder.productsLabel'} />
        </div>
        {props.question.products.map((product, productIndex) => {
          return (
            <div key={product.name} className={styles.container}>
              <InputWithLabel onChange={setProductName(product.name)} value={product.name} labelId={'survey-creator.genericOrder.nameLabel'} />
              <ElementWithLabel labelId={'survey-creator.genericOrder.synonyms'}>
                <SynonymsChipsComponent onChange={setProductSynonyms(product.name)} items={product.synonyms} i18nKey={'anything'} />
              </ElementWithLabel>
              <div className={styles.outerContainer}>
                <Label labelId={'survey-creator.genericOrder.propertiesLabel'} />
                {product.properties.map((property, propertyIndex) => {
                  return (
                    <div key={property.property} className={styles.container}>
                      <ElementWithLabel labelId={'survey-creator.genericOrder.propertyLabel'}>
                        <SelectComponent options={props.question.properties.map(el => ({id: el.id, name: el.name}))}
                                         value={property.property && {id: property.property, name: findProperty(property.property).name}}
                                         onChange={setProductProperty(product.name, property.property)}
                        />
                      </ElementWithLabel>
                      {property.property && findProperty(property.property).type === 'values' && (
                        <ElementWithLabel labelId={'survey-creator.genericOrder.propertyValuesLabel'}>
                          <MultiSelectComponent
                            options={findProperty(property.property).values.map(el => ({id: el.id, name: el.name}))}
                            value={property.values.map(el => ({id: el, name: findPropertyValue(property.property, el).name}))}
                            onChange={setProductPropertyValues(product.name, property.property)}
                          />
                        </ElementWithLabel>
                      )}
                      <ToggleWithLabel onToggle={toggleProductPropertyOptional(product.name, property.property)} value={property.optionalProperty}
                                       labelId={'survey-creator.genericOrder.optionalProperty'} />
                      <ToggleWithLabel onToggle={toggleProductPropertyCustomMessage(product.name, property.property)} value={!!property.message}
                                       labelId={'survey-creator.genericOrder.customMessageProperty'} />
                      {property.message &&
                      <MessageComponent dataTestPrefix={`${property.property}-message`} labelId={'survey-creator.genericOrder.propertyMessage'}
                                        message={property.message}
                                        onChange={setProductPropertyCustomMessage(product.name, property.property)}
                                        path={`products[${productIndex}].properties[${propertyIndex}].message`}
                      />}
                    </div>
                  );
                })}
              </div>
              <div className={styles.outerContainer}>
                <Label labelId={'survey-creator.genericOrder.metadataLabel'} />
                {_.entries(product.metadata).map(([key, value]) => {
                  return (
                    <div key={key}>
                      <Input onChange={setProductMetadataKey(product.name, key)} value={key} />
                      <Input onChange={setProductMetadataValue(product.name, key)} value={value} />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
