import { createAsyncAction } from 'typesafe-actions';
import { Synonym } from '../../model';

import { SurveyCreator } from '../actionTypes';

export const fetchGlobalWords = createAsyncAction(
  SurveyCreator.FETCH_GLOBAL_WORDS,
  SurveyCreator.FETCH_GLOBAL_WORDS_SUCCESS,
  SurveyCreator.FETCH_GLOBAL_WORDS_FAILURE,
)<
  undefined,
  { word: string; language: string; }[],
  { error: string }
>();

export const fetchSynonymsForWord = createAsyncAction(
  SurveyCreator.FETCH_SYNONYMS_FOR_WORD,
  SurveyCreator.FETCH_SYNONYMS_FOR_WORD_SUCCESS,
  SurveyCreator.FETCH_SYNONYMS_FOR_WORD_FAILURE
)<
  { word: string; language: string; },
  { word: string; language: string; synonyms: Synonym[]; },
  { error: string }
>();